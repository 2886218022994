import React from 'react';
import OrderRequest from 'components/request/housing/OrderRequest';
import ProtectionRequest from 'components/request/housing/ProtectionRequest';
import ETCCertificationRequest from 'components/request/etc/CertificationRequest';
import HousingCertificationRequest from 'components/request/housing/CertificationRequest';
import DrunkDrivingRequest from 'components/request/crime/DrunkDrivingRequest';
import DrugRequest from 'components/request/crime/DrugRequest';
import MediaObscenityRequest from 'components/request/crime/MediaObscenityRequest';
import ComplaintRequest from 'components/request/sexual_crime/ComplaintRequest';
import SeizureRequest from 'components/request/housing/SeizureRequest';
import HousingPublicConveyanceRequest from 'components/request/housing/PublicConveyanceRequest';
import InsultRequest from 'components/request/plaint/InsultRequest';
import LendMoneyRequest from 'components/request/LendMoneyRequest';
import LendCertificationRequest from "components/request/LendCertificationRequest";
import NoiseCertificationRequest from "components/request/noise/CertificationRequest";
import PilatesCertificationRequest from "components/request/pilates/CertificationRequest";
import PilatesOrderRequest from "components/request/pilates/OrderRequest";
import HostCancelCertificationRequest from "components/request/housing/HostCancelCertificationRequest";
import HostChargeCertificationRequest from "components/request/housing/HostChargeCertificationRequest";
import HostNotallowedCertificationRequest from "components/request/housing/HostNotallowedCertificationRequest";
import CivilDefendantRequest from 'components/request/civil/CivilDefendantRequest';
import CivilPlaintiffRequest from 'components/request/civil/CivilPlaintiffRequest';
import InheritGiveupRequest from 'components/request/inherit/InheritGiveupRequest';
import InheritLimitedRequest from 'components/request/inherit/InheritLimitedRequest';

import VacatehouseRequest from 'components/request/vacatehouse/VacatehouseRequest';
import InjunctionRequest from 'components/request/vacatehouse/InjunctionRequest';

import { DetailDataModalContainer } from './DetailDataModal.style';
import { ServiceType } from './ServiceType';
import CommonCriminalDriveRequest from 'components/request/criminal/CommonCriminalDriveRequest';
import CommonCriminalSexualRequest from 'components/request/criminal/CommonCriminalSexualRequest';
import CommonCriminalRepresentRequest from 'components/request/criminal/CommonCriminalRepresentRequest';
import CommonCriminalEtcRequest from 'components/request/criminal/CommonCriminalEtcRequest';
import CommonEtcRequest from 'components/request/etc/CommonEtcRequest';
import GolfTennisCertificationRequest from 'components/request/golfTennis/CertificationRequest';
import GolfTennisOrderRequest from 'components/request/golfTennis/OrderRequest';
import To3Request from 'components/request/to3/To3Request';
import CommonOrderRequest from 'components/request/new/CommonOrderRequest';
import CommonCertificationRequest from 'components/request/new/CommonCertificationRequest';
import CommonComplaintRequest from 'components/request/new/ComplaintRequest';
import ContractRequest from 'components/request/new/ContractRequest';
import AgreementRequest from 'components/request/new/AgreementRequest';
import OpinionRequest from 'components/request/new/OpinionRequest';

const DetailDataAdaptor = ({ serviceData, serviceType }) => {
    switch (serviceType) {
        case ServiceType.DrunkDriving:
            return <DetailDataModalContainer>
                <DrunkDrivingRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.Drug:
            return <DetailDataModalContainer>
                <DrugRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.ETCCertification:
            return <DetailDataModalContainer>
                <ETCCertificationRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.HousingCertification:
            return <DetailDataModalContainer>
                <HousingCertificationRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.HousingPublicConveyance:
            return <DetailDataModalContainer>
                <HousingPublicConveyanceRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.HousingSeizure:
            return <DetailDataModalContainer>
                <SeizureRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.HousingProtection:
            return <DetailDataModalContainer>
                <ProtectionRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.propertyDeposit:
            return <DetailDataModalContainer>
                <OrderRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.LendMoney:
            return <DetailDataModalContainer>
                <LendMoneyRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.LendCertification:
            return <DetailDataModalContainer>
                <LendCertificationRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.PlaintInsult:
            return <DetailDataModalContainer>
                <InsultRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.MediaObscenity:
            return <DetailDataModalContainer>
                <MediaObscenityRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.SexualCrimeComplaint:
            return <DetailDataModalContainer>
                <ComplaintRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.NoiseCertification:
            return <DetailDataModalContainer>
                <NoiseCertificationRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.PilatesCertification:
            return <DetailDataModalContainer>
                <PilatesCertificationRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.PilatesOrder:
            return <DetailDataModalContainer>
                <PilatesOrderRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.HostCancelCertification:
            return <DetailDataModalContainer>
                <HostCancelCertificationRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.HostChargeCertification:
            return <DetailDataModalContainer>
                <HostChargeCertificationRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.HostNotallowedCertification:
            return <DetailDataModalContainer>
                <HostNotallowedCertificationRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.CivilDefendant:
            return <DetailDataModalContainer>
                <CivilDefendantRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.CivilPlaintiff:
            return <DetailDataModalContainer>
                <CivilPlaintiffRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.CommonCriminalDrive:
            return <DetailDataModalContainer>
                <CommonCriminalDriveRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.CommonCriminalSexual:
            return <DetailDataModalContainer>
                <CommonCriminalSexualRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.CommonCriminalRepresant:
            return <DetailDataModalContainer>
                <CommonCriminalRepresentRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.CommonCriminalEtc:
            return <DetailDataModalContainer>
                <CommonCriminalEtcRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.CommonEtc:
            return <DetailDataModalContainer>
                <CommonEtcRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.GolfTennisCertification:
            return <DetailDataModalContainer>
                <GolfTennisCertificationRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.GolfTennisOrder:
            return <DetailDataModalContainer>
                <GolfTennisOrderRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.InheritGiveup:
            return <DetailDataModalContainer>
                <InheritGiveupRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.InheritLimited:
            return <DetailDataModalContainer>
                <InheritLimitedRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.Vacatehouse:
            return <DetailDataModalContainer>
                <VacatehouseRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.Injunction:
            return <DetailDataModalContainer>
                <InjunctionRequest serviceData={serviceData} />
            </DetailDataModalContainer>
        case ServiceType.To3Estate:
            return <DetailDataModalContainer>
                <To3Request serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.To3Etc:
            return <DetailDataModalContainer>
                <To3Request serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.To3GolfTennis:
            return <DetailDataModalContainer>
                <To3Request serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.To3Loan:
            return <DetailDataModalContainer>
                <To3Request serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.To3Noise:
            return <DetailDataModalContainer>
                <To3Request serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.To3Pilates:
            return <DetailDataModalContainer>
                <To3Request serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.To3Pay:
            return <DetailDataModalContainer>
                <To3Request serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.CommonOrder:
            return <DetailDataModalContainer>
                <CommonOrderRequest serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.CommonCertification:
            return <DetailDataModalContainer>
                <CommonCertificationRequest serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.Complaint:
            return <DetailDataModalContainer>
                <CommonComplaintRequest serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.Contract:
            return <DetailDataModalContainer>
                <ContractRequest serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.Agreement:
            return <DetailDataModalContainer>
                <AgreementRequest serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>
        case ServiceType.Opinion:
            return <DetailDataModalContainer>
                <OpinionRequest serviceData={serviceData} serviceType={serviceType} />
            </DetailDataModalContainer>

        default:
            return <></>
    }
}

export default DetailDataAdaptor;
