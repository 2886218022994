import React, { useEffect, useState } from "react";
import backButtonBlack from '../../assets/img/backButtonBlack.png';
import { 
    PopoverContainer,
    PopoverContentContainer,
    PopoverHeader,
    PopoverHeaderEmtpy,
    PopoverHeaderTitle,
    PopoverHeaderBottomBorder,
    PopoverHeaderBackBtn,
    PopoverHeaderBackBtnWrapper,
    ClientOpinionListWrapper,
    ClientOpinionList,
    ClientOpinionListContent,
    ClientOpinionListCreateAt,
    ClientOpinionListDivider
} from './ConfirmMyDocOpinion.style';
import { getFormattedDate } from 'util/util';
import { useRecoilState } from "recoil";
import { currentLawyerCaseState } from "states/atoms";

function ConfirmMyDocOpinion({ onClikcCloseButton }) {
  const [lawyerCase, setLaywerCase] = useRecoilState(currentLawyerCaseState);
  const [contentDataList, setContentDataList] = useState(lawyerCase.lawyer_document_opinion_list);
    
  useEffect(() => {
  }, []);

  const handleGoBack = () => {
    onClikcCloseButton();
  };

  if (contentDataList === undefined) return null;

  return (
      <>
      <PopoverContainer>
        <PopoverContentContainer>
          <PopoverHeader>
            <PopoverHeaderBackBtnWrapper>
            <PopoverHeaderBackBtn onClick={handleGoBack} className="popover-back" src={backButtonBlack} />
            </PopoverHeaderBackBtnWrapper>
            <PopoverHeaderTitle>내가 작성한 의견 확인하기</PopoverHeaderTitle>
            <PopoverHeaderEmtpy/>
          </PopoverHeader>
          <PopoverHeaderBottomBorder/>
          <ClientOpinionListWrapper>
            {contentDataList.map((content, index) => {
                return (
                    <> 
                        <ClientOpinionList>
                            <ClientOpinionListCreateAt>
                              작성일: {getFormattedDate(content.createAt)}
                            </ClientOpinionListCreateAt>
                            <ClientOpinionListContent>
                            {content.content}
                            </ClientOpinionListContent>                              
                        </ClientOpinionList>
                        <> {(index == contentDataList.length - 1) &&
                            <></>
                          } { (index != contentDataList.length - 1) &&
                            <ClientOpinionListDivider/>
                          }
                        </>                          
                    </>)
            })}
          </ClientOpinionListWrapper>
        </PopoverContentContainer>
      </PopoverContainer>
      </>
  );
}
export default ConfirmMyDocOpinion;