import styled from "@emotion/styled";

export const LoginFirstSection = styled.section`
  display: flex;
  width: 100%;
  padding: 100px 0px 50px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  align-self: stretch;
`;

export const LoginFirstSectionTitle = styled.div`
  color: #1F2E3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`

export const LoginFirstSectionDesc = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
`

export const LoginSecondSection = styled.section`
  display: flex;
  width: 100%;
  padding: 50px 0px;
  justify-content: center;
  align-items: center;
`;

export const KakaoLoginImg = styled.img`
  cursor: pointer;
`