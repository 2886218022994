import { useHistory } from "react-router-dom";

import IcHealthPilatesServiceMain from "../../assets/svg/IcHealthPilatesServiceMain.svg";
import IcHealthPilatesServiceMainMobile from "../../assets/svg/IcHealthPilatesServiceMainMobile.svg";
import IcHealthPilatesServiceBody from "../../assets/svg/IcHealthPilatesServiceBody.svg";
import IcHealthPilatesServiceBodyMobile from "../../assets/svg/IcHealthPilatesServiceBodyMobile.svg";
import IcHealthPilatesServiceBody2 from "../../assets/svg/IcHealthPilatesServiceBody2.svg";
import IcHealthPilatesServiceBody2Mobile from "../../assets/svg/IcHealthPilatesServiceBody2Mobile.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
  ServiceCenterFooterBtn,
  ServiceCenterFooterBtnContainer,
  ServiceCenterFooterContainer,
  ServiceCenterFooterDesc,
  ServiceCenterFooterSection,
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle,
  ServiceCenterSecondContainer,
  ServiceCenterSecondDesc,
  ServiceCenterSecondImg,
  ServiceCenterSecondSection,
  ServiceCenterSecondSubTitle,
  ServiceCenterSecondTextContainer,
  ServiceCenterSecondTitle,
  ServiceCenterThirdContainer,
  ServiceCenterThirdDesc,
  ServiceCenterThirdDescContainer,
  ServiceCenterThirdDescSpan,
  ServiceCenterThirdDescTitle,
  ServiceCenterThirdImg,
  ServiceCenterThirdImgMobile,
  ServiceCenterThirdSection,
  ServiceCenterThirdSubTitle,
  ServiceCenterThirdTextContainer,
  ServiceCenterThirdTitle,
  ServiceCenterThirdTwoTextContainer
} from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const HealthPilatesServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = "헬스･필라테스\n환불 받기";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = isMobile
      ? "불공정 계약으로\n환불이 어렵나요?"
      : "불공정 계약으로 환불이 어렵나요?";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = "계약서에 작성 되었어도 무효화 가능";
    return removeBrTag(text);
  };

  const body2Title = () => {
    const text = isMobile
      ? "헬스･필라테스\n환불은 법대리에서!"
      : "헬스･필라테스 환불은 법대리에서!";
    return removeBrTag(text);
  };

  const footerTitle = () => {
    const text = isMobile
      ? "환불 관련 도움이\n필요하신가요?"
      : "환불 관련 도움이 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcHealthPilatesServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcHealthPilatesServiceMain}
            />
            <ServiceCenterFirstImg src={IcHealthPilatesServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
              {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              합의서는 서로 합의하에 법률 사건을 마무리하는 문서이기 때문에 구체적인 내용이 작성되어야 하며 추후 문제가 발생하지 않도록 빈틈이 없어야 합니다.
              하지만 일반인 입장에서는 어떤 내용이 구체적으로 들어가야 하는지, 어떤 내용이 있어야 추후 문제가 발생하지 않고 내가 보호받을 수 있는지 알기 어려운 경우가 많습니다.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcHealthPilatesServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcHealthPilatesServiceBody}
            />
            <ServiceCenterSecondImg src={IcHealthPilatesServiceBody} />
          </picture>
        </ServiceCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterThirdSection>
        <ServiceCenterThirdContainer>
          <ServiceCenterThirdImg src={IcHealthPilatesServiceBody2} />
          <ServiceCenterThirdTwoTextContainer>
            <ServiceCenterThirdTitle>
              {body2Title()}
            </ServiceCenterThirdTitle>
            <ServiceCenterThirdSubTitle>
              상황에 따른 두가지 방법
            </ServiceCenterThirdSubTitle>
            <ServiceCenterThirdDescContainer>
              <ServiceCenterThirdDescTitle>
                1. 내용 증명 보내기
              </ServiceCenterThirdDescTitle>
              <ServiceCenterThirdDesc style={{ paddingTop: 0, paddingLeft: 20 }}>
                법대리 환불 전문 변호사가 직접 작성해서 법무법인 명의로 발송하는 내용증명을 통해 손쉽게 환불을 요구할 수 있습니다.
              </ServiceCenterThirdDesc>
            </ServiceCenterThirdDescContainer>
            <ServiceCenterThirdDescContainer>
              <ServiceCenterThirdDescTitle>
                2. 지급 명령 보내기
              </ServiceCenterThirdDescTitle>
              <ServiceCenterThirdDesc style={{ paddingTop: 0, paddingLeft: 20 }}>
                내용증명 발송 이후에도 환불금을 안 돌려주는 업체에게 변호사가 직접 작성한 지급명령 소송서를 통해
                법적으로 해결할 수도 있습니다. 모든 소송 관련 비용은 업체에게 청구할 수 있습니다.
              </ServiceCenterThirdDesc>
            </ServiceCenterThirdDescContainer>
          </ServiceCenterThirdTwoTextContainer>
          <ServiceCenterThirdImgMobile src={IcHealthPilatesServiceBody2Mobile} />
        </ServiceCenterThirdContainer>
      </ServiceCenterThirdSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
              {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면
              분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtnContainer>
            <ServiceCenterFooterBtn onClick={() => history.push("/apply/healthPilatesCertService")}>
              내용 증명 보내기
            </ServiceCenterFooterBtn>
            <ServiceCenterFooterBtn onClick={() => history.push("/apply/healthPilatesOrderService")}>
              지급 명령 보내기
            </ServiceCenterFooterBtn>
          </ServiceCenterFooterBtnContainer>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default HealthPilatesServiceCenter;
