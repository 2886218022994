import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const InsultRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const offenderInfoList = serviceData.offender.info.map((item) => {
    if (item === "name") return <li>이름</li>;
    else if (item === "id") return <li>ID(캐릭터명)</li>;
    else if (item === "address") return <li>주소</li>;
    else if (item === "phone") return <li>연락처</li>;
  });

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        모욕죄 고소장
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="사건 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="사건 발생일" labelStyle={labelStyle}>
            {serviceData.accident.date}
          </Descriptions.Item>
          <Descriptions.Item label="사건 발생 시각" labelStyle={labelStyle}>
            {serviceData.accident.time}
          </Descriptions.Item>
          <Descriptions.Item label="사건 발생 장소" labelStyle={labelStyle}>
            {serviceData.accident.place}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="가해자 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="가해자 정보" labelStyle={labelStyle}>
            <ul style={{ paddingInlineStart: 5 }}>{offenderInfoList}</ul>
          </Descriptions.Item>
          <Descriptions.Item label="가해자의 모욕 횟수" labelStyle={labelStyle}>
            {serviceData.offender.insultCount === "one" ? "1회" : "2회 이상"}
          </Descriptions.Item>
          <Descriptions.Item label="모욕 발언 상세" labelStyle={labelStyle}>
            {serviceData.offender.insult
              ? serviceData.offender.insult.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })
              : ""}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="제3자 및 증거 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="제3자 목격 여부" labelStyle={labelStyle}>
            {serviceData.thirdparty.exist === "y" ? "있음" : "없음"}
          </Descriptions.Item>
          <Descriptions.Item label="목격자의 닉네임/이름" labelStyle={labelStyle}>
            {serviceData.thirdparty.name === "y" ? "알고있음" : "모름"}
          </Descriptions.Item>
          <Descriptions.Item label="증거 확보 유무" labelStyle={labelStyle}>
            {serviceData.evidence.exist === "y" ? "있음" : "없음"}
          </Descriptions.Item>
        </Descriptions>

        {/* <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">첨부 파일</div>
          </div>
          {serviceData.attachments.map((attachment) => (
            <Image
              key={attachment}
              width={75}
              height={75}
              src={attachment}
              preview={{ zIndex: 10000 }}
            />
          ))}
        </div> */}
      </div>
    </>
  );
};

export default InsultRequest;
