import React from "react";
import IcConfrimPopupSuccess from "../../assets/svg/IcConfrimPopupSuccess.svg";
import { 
    PopoverContentContainer,
    PopoverContentCenterIcon,
    PopoverContentBodyText,
    PopoverContentBottomButton,
    PopoverContentBottomButtonWrapper,
    PopoverContentBottomWrapper
 } from "./ConfirmMyDocAccept.style";

function SuccessUploadDocModal({ onClikcConfirmButton }) {
    const handleConfirmButton = () => {
        onClikcConfirmButton();
      };

    return (
        <>
          <PopoverContentContainer>
            <PopoverContentCenterIcon  src={IcConfrimPopupSuccess}/>
            <PopoverContentBodyText>
                사건 자료 업로드가 완료되었습니다.
            </PopoverContentBodyText>
            <PopoverContentBottomWrapper>
                <PopoverContentBottomButtonWrapper isConfirm={true} onClick={handleConfirmButton}>
                    <PopoverContentBottomButton>확인</PopoverContentBottomButton>
                </PopoverContentBottomButtonWrapper>
            </PopoverContentBottomWrapper>
          </PopoverContentContainer>
        </>
    );
}

export default SuccessUploadDocModal;