import { useEffect, useState } from "react";
import IcMain from "../../../../assets/svg/IcComplaintServiceMain.svg";
import IcMainMobile from "../../../../assets/svg/IcComplaintServiceMainMobile.svg";


import { useRecoilValue } from "recoil";
import { userData, redirectEtcUri } from "../../../../states/atoms";

import {
  Container,
} from "../../../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
} from "../../../home/ServiceCenter.style";

import { RadioGroup, Radio } from "../../../home/CommonCriminal/RadioBox";
import { CheckboxGroup, Checkbox } from "../../../home/CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { ApplyServiceInputText, ApplyServiceSubmitDataContainer, ApplyServiceSubmitDataInfoContainer, ApplyServiceSubmitDataInfoDetailContainer, ApplyServiceSubmitDataInfoDetailTitle, ApplyServiceSubmitDataSection } from "./ApplyService.style";
import { EventFormEachDesc, EventInputTextArea, EventSubmitFooterSection, EventSubmitFooterText, EventSubmitFooterTextContainer } from "components/event/3o3/order/EventSubmit.style";

import { useRecoilState } from 'recoil';

import {
  Input,
} from "antd";

import { removeBrTag } from "routes/Landing";
import { ApplyServiceSubmit } from "../ApplyServiceSubmit";

const { useHistory } = require("react-router-dom");

const ApplyComplaintService = ({ loggedInUser }) => {
  // 고소장이 필요한 분야
  const [field, setField] = useState("");
  const [etcField, setEtcField] = useState("");

  function handleChange(checkedValues) {
    setField(checkedValues);
  }

  const handleEtcFieldChange = (e) => {
    const inputValue = e.target.value;
    setEtcField(inputValue)
  }

  const fieldList = [
    "모욕죄 (SNS, 온라인, 메신저 등)", "금융 투자 사기 (주식, 코인 리딩방)", "다단계 (유사 수신,P2P)", "통매음", "성추행 / 성폭행", "저작권 침해", "그 외"]

  // 현재 상황에 대한 상세 설명
  const [caseDesc, setCaseDesc] = useState("");

  const handleDescChange = (e) => {
    const inputValue = e.target.value;
    setCaseDesc(inputValue)
  }

  // 의뢰인이 원하는 결과
  const [desiredResult, setDesiredResult] = useState([]);
  const [etcDesiredResult, setEtcDesiredResult] = useState("");

  const handleEtcDesiredResultChange = (e) => {
    const inputValue = e.target.value;
    setEtcDesiredResult(inputValue);
  }

  const [additionalHelp, setAdditionalHelp] = useState("");


  const footerText = () => {
    const text = isSubmitReady()
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄져요. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "모든 항목에 답변해 주세요.\n답변이 완료되면 제출할 수 있어요.";
    return removeBrTag(text);
  }

  const serviceData = () => {
    const serviceData = {
      // 전화번호
      contact: "",
      // 첨부파일
      attachments: [],
      field: field,
      etcField: field === "그 외" ? etcField : null,
      desiredResult: desiredResult,
      etcDesiredResult: desiredResult.includes("직접 입력") ? etcDesiredResult : "",
      additionalHelp: additionalHelp,
      caseDesc: caseDesc,
      userId: loggedInUser.uid
    };
    return serviceData;
  }

  const isSubmitReady = () => {
    if (field !== "" && caseDesc !== "" && additionalHelp !== "" && desiredResult.length !== 0) {
      return true
    }
    return false
  }

  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  const title = () => {
    const text = isMobile
      ? "고소장 작성하기"
      : "고소장 작성하기";
    return removeBrTag(text);
  };

  const applyServiceDescTitle = () => {
    const text = isMobile
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄집니다. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "신청서 제출 후, 전문 변호사의 검토가 이뤄집니다.\n카카오 알림톡으로 검토 결과를 알려드릴게요.";
    return removeBrTag(text);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (checkedValues) => {
    console.log(checkedValues)
  };

  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcMain}
            />
            <ServiceCenterFirstImg src={IcMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ApplyServiceSubmitDataSection>
        <ApplyServiceSubmitDataContainer>
          <ApplyServiceSubmitDataInfoContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>고소장이 필요한 분야를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup label="" value={field} style={{ width: "100%" }} onChange={handleChange}>
                {fieldList.map((value) =>
                  <Radio value={value} >{value}</Radio>
                )}
                {field.includes("그 외") && <>
                  <ApplyServiceInputText style={{ width: "100%" }} value={etcField} onChange={handleEtcFieldChange} placeholder="내용을 입력해주세요." />
                </>}
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>현재 상황에 대한 상세 설명을 작성해주세요</ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>상세 설명은 육하원칙에 따라 간결하게 작성해 주셔야 변호사 검토가 빠르게 이뤄집니다.</EventFormEachDesc>
              <EventInputTextArea value={caseDesc} onChange={handleDescChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                원하시는 결과를 선택해 주세요.
                <br />(복수 선택 가능)
              </ApplyServiceSubmitDataInfoDetailTitle>
              <CheckboxGroup
                label=""
                values={desiredResult}
                onChange={setDesiredResult}
              >
                <Checkbox value="진정한 사과, 또는 처벌">진정한 사과, 또는 처벌</Checkbox>
                <Checkbox value="피해입은 부분의 금전적 보상">피해입은 부분의 금전적 보상</Checkbox>
                <Checkbox value="직접 입력">
                  직접입력
                </Checkbox>
                {desiredResult.includes("직접 입력") && <>
                  <ApplyServiceInputText
                    style={{ width: "100%" }}
                    value={etcDesiredResult} onChange={handleEtcDesiredResultChange} placeholder="내용을 입력해주세요." />
                </>}
              </CheckboxGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                고소장 작성 이후, 추가적인 도움이 필요하신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={additionalHelp}
                onChange={setAdditionalHelp}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
          </ApplyServiceSubmitDataInfoContainer>
        </ApplyServiceSubmitDataContainer>
      </ApplyServiceSubmitDataSection>
      <EventSubmitFooterSection active={isSubmitReady()}>
        <EventSubmitFooterTextContainer>
          <EventSubmitFooterText active={isSubmitReady()}>
            {footerText()}
          </EventSubmitFooterText>
        </EventSubmitFooterTextContainer>
      </EventSubmitFooterSection>
      <ApplyServiceSubmit
        active={isSubmitReady()}
        serviceData={serviceData()}
        serviceType={"complaint"}
        uid={loggedInUser.uid}
      />
    </Container>
  )
};

export default ApplyComplaintService;
