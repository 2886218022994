import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Footer from "components/Footer";
import IcLivingLawBanner from "../../assets/img/banner_pc.png"
import IcLivingLawBannerMobile from "../../assets/img/banner_mo.png"
import IcHeadset from "../../assets/svg/IcHeadset.svg"
import IcGobackList from "../../assets/svg/IcGobackList.svg"
import IcArrowUp from "../../assets/svg/IcArrowUp.svg"
import IcArrowDown from "../../assets/svg/IcArrowDown.svg"
import {
  Container,
  ContentContainer,
} from "../Service.style";
import { 
  LivingLawContentSection,
  LivingLawDetailContainer,
  LivingLawDetailBackListWrapper,
  LivingLawDetailBackListButtonWrapper,
  LivingLawDetailBackListButtonTitle,
  LivingLawDetailBackListButtonImg,
  LivingLawDetailWrapper,
  LivingLawFooterBannerImg,
  LivingLawFooterBannerWrapper,
  LivingLawHelpTitle,
  LivingLawHelpDesc,
  LivingLawHelpConsult,
  LivingLawDetailDateTitleWrapper,
  LivingLawDetailDate,
  LivingLawDetailTitle,
  LivingLawDetailContent,
  LivingLawDetailContentDesc,
  LivingLawDetailContentHeader,
  LivingLawDetailContentImg,
  LivingLawDetailMoveWrapper,
  LivingLawDetailMoveTopBorder,
  LivingLawDetailMoveContainer,
  LivingLawDetailMove,
  LivingLawDetailMovePrevWrapper,
  LivingLawDetailMoveImg,
  LivingLawDetailMoveHead,
  LivingLawDetailMoveTitle,
  LivingLawDetailMainTitle
} from "./LivingLaw.style";

import { removeBrTag } from "routes/Landing";
import { LandingToChannelTalk } from "util/Handler";
import { LivingLawData } from "./LivingLawData";
import { getFormattedCaseDate } from "util/util";
import ChannelService from "../ChannelService";

import { useRecoilState } from "recoil";
import { livingLawDetailIdxState } from "states/atoms";
import { Helmet } from 'react-helmet-async';

const LivingLawDetail = ({match, loggedInUser}) => {
  const { detailId } = useParams();
  const history = useHistory();
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const [livingLawDetailIdx, setLivingLawDetailIdx] = useRecoilState(livingLawDetailIdxState);

  const [data, setData] = useState(null);

  const onClickGoback = () => {
    history.push('/livingLaw');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const idx = LivingLawData.findIndex(item => item.detailId === detailId);
    setData(LivingLawData[idx]);
    setLivingLawDetailIdx(idx);
  }, [match.params.detailId]);

  const onClickPrev = () => {
    if (livingLawDetailIdx === 0) {
      return;      
    }
    setLivingLawDetailIdx(livingLawDetailIdx - 1);
    const detailId = LivingLawData[livingLawDetailIdx - 1].detailId;
    history.push('/livingLawDetail/' + detailId);
  }

  const onClickNext = () => {
    if (livingLawDetailIdx >= (LivingLawData.length - 1)) {
      return;
    }
    setLivingLawDetailIdx(livingLawDetailIdx + 1);
    const detailId = LivingLawData[livingLawDetailIdx + 1].detailId;
    history.push('/livingLawDetail/' + detailId);
  }

  if (data === null) {
    return;
  }

  function renderContentItem(item) {
    switch(item.type) {
        case 'text':
            return <LivingLawDetailContentDesc>{removeBrTag(item.content)}</LivingLawDetailContentDesc>;
        case 'image':
            return (
                <a href="https://by-law.in/">
                    <LivingLawDetailContentImg src={item.content} alt={item.alt || ""} />
                </a>
            );
        case 'header':
            return <LivingLawDetailContentHeader>{removeBrTag(item.content)}</LivingLawDetailContentHeader>;
        case 'link':
          return <a href={item.content}>{removeBrTag(item.text? item.text:item.content)}</a>;
        case 'tag':
          return <a href="https://by-law.in/livingLaw" rel="tag">{removeBrTag(item.content)}</a>;
        default:
            return null;
    }
}

  return (
    <Container>
     <Helmet>
        <title>{data.title}</title>
        <meta property="og:title" content={data.title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={data.metaDescription} />
        <meta property="og:url" content={"https://by-law.in/livingLawDetail/"+data.detailId} />
        <meta name="description" content={data.metaDescription} />       
      </Helmet> 
      <LivingLawContentSection>
        <ContentContainer style={
        { width: 674 }
        }>
          <LivingLawDetailWrapper>
            <LivingLawDetailContainer>
              <LivingLawDetailMainTitle>
                <h1>
                  {data.title}
                </h1>
              </LivingLawDetailMainTitle>
              <LivingLawDetailBackListWrapper>
                <LivingLawDetailBackListButtonWrapper onClick={() => onClickGoback()}>
                  <LivingLawDetailBackListButtonImg src={IcGobackList}/>
                  <LivingLawDetailBackListButtonTitle>
                    목록으로 돌아가기
                  </LivingLawDetailBackListButtonTitle>
                </LivingLawDetailBackListButtonWrapper>
              </LivingLawDetailBackListWrapper>      
              <LivingLawDetailDateTitleWrapper>
                <LivingLawDetailDate>
                  {getFormattedCaseDate(data.date)}
                </LivingLawDetailDate>
                <LivingLawDetailTitle>
                  {data.title}
                </LivingLawDetailTitle>
              </LivingLawDetailDateTitleWrapper>
              <LivingLawDetailContent>
                {data.contents.map((item, index) => (
                    <React.Fragment key={index}>
                        {renderContentItem(item)}
                    </React.Fragment>
                ))}
              </LivingLawDetailContent>
              <LivingLawDetailMoveWrapper>
                <LivingLawDetailMoveTopBorder />
                <LivingLawDetailMoveContainer>
                  {livingLawDetailIdx > 0 && 
                    <LivingLawDetailMove onClick={() => onClickPrev()}>
                    <LivingLawDetailMovePrevWrapper>
                      <LivingLawDetailMoveImg src={IcArrowUp}/>
                      <LivingLawDetailMoveHead>
                        이전
                      </LivingLawDetailMoveHead>                      
                    </LivingLawDetailMovePrevWrapper>
                    <LivingLawDetailMoveTitle>
                      {LivingLawData[livingLawDetailIdx - 1].title}
                    </LivingLawDetailMoveTitle>
                  </LivingLawDetailMove>
                  }
                  {livingLawDetailIdx < (LivingLawData.length - 1) && 
                    <LivingLawDetailMove onClick={() => onClickNext()}>
                      <LivingLawDetailMovePrevWrapper>
                        <LivingLawDetailMoveImg src={IcArrowDown}/>
                        <LivingLawDetailMoveHead>
                          다음
                        </LivingLawDetailMoveHead>                      
                      </LivingLawDetailMovePrevWrapper>
                      <LivingLawDetailMoveTitle>
                          {LivingLawData[livingLawDetailIdx + 1].title}
                      </LivingLawDetailMoveTitle>
                    </LivingLawDetailMove>
                  }
                </LivingLawDetailMoveContainer>
              </LivingLawDetailMoveWrapper>
            </LivingLawDetailContainer>
            <LivingLawFooterBannerWrapper onClick={() => history.push("/")}>
              <picture>
                  <source media="(max-width: 768px)" srcSet={IcLivingLawBannerMobile} />
                  <source media="(min-width: 768px)" srcSet={IcLivingLawBanner} />
                  <LivingLawFooterBannerImg src={IcLivingLawBanner}/>
              </picture>          
            </LivingLawFooterBannerWrapper>
          </LivingLawDetailWrapper>
        </ContentContainer>
      </LivingLawContentSection>
      <section
        style={{
          width: "100%",
          background: "#BDE9FF",
          paddingTop: 63,
          paddingBottom: 63,
        }}
      >
        <ContentContainer>
          <LivingLawHelpTitle style={{ marginBottom: 20, marginTop: 0 }}>
          법대리 법률 상담 
          </LivingLawHelpTitle>
          <LivingLawHelpDesc>
            복잡한 법률 용어, 지식은 모르셔도 됩니다.
            <br />
            법대리가 알려드리는 항목들만 작성 및 첨부해 주시면 법대리의 전문
            변호사단이 검토 후 의견을 드립니다.
          </LivingLawHelpDesc>
          <LivingLawHelpConsult onClick={()=> {
            //  LandingToChannelTalk();
            if(!loggedInUser){
              ChannelService.loadScript();
              ChannelService.boot({
                "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
              })
            }
            ChannelService.showMessenger();
          }}>
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </LivingLawHelpConsult>
        </ContentContainer>
      </section>
      <Footer/>
    </Container>    
  );
};

export default LivingLawDetail;