import { useHistory } from "react-router-dom";

import IcEtcServiceMain from "../../assets/svg/IcEtcServiceMain.svg";
import IcEtcServiceMainMobile from "../../assets/svg/IcEtcServiceMainMobile.svg";
import IcEtcServiceBody from "../../assets/svg/IcEtcServiceBody.svg";
import IcEtcServiceBodyMobile from "../../assets/svg/IcEtcServiceBodyMobile.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
  ServiceCenterFooterBtn,
  ServiceCenterFooterContainer,
  ServiceCenterFooterDesc,
  ServiceCenterFooterSection,
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle,
  ServiceCenterSecondContainer,
  ServiceCenterSecondDesc,
  ServiceCenterSecondImg,
  ServiceCenterSecondSection,
  ServiceCenterSecondSubTitle,
  ServiceCenterSecondTextContainer,
  ServiceCenterSecondTitle,
} from "./ServiceCenter.style";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const EtcNewServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = "기타 법률 문의하기";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = isMobile
      ? "원하시는\n항목이 없나요?"
      : "원하시는 항목이 없나요?";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = isMobile
      ? "괜찮아요. 법대리에선\n모든 법률 상담이 가능합니다."
      : "괜찮아요. 법대리에선 모든 법률 상담이 가능합니다.";
    return removeBrTag(text);
  };

  const footerTitle = () => {
    const text = isMobile
      ? "기타 법률 상담이\n필요하신가요?"
      : "기타 법률 상담이 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcEtcServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcEtcServiceMain}
            />
            <ServiceCenterFirstImg src={IcEtcServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
              {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              기타 법률 문의하기 서비스는 법대리 간편 서비스에서 원하시는 항목을 찾지 못하신
              고객님들을 위한 페이지 입니다. 고객님들의 다양한 사건을 처리하기 위해 법대리가 도와드릴게요.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcEtcServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcEtcServiceBody}
            />
            <ServiceCenterSecondImg src={IcEtcServiceBody} />
          </picture>
        </ServiceCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
              {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면
              분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtn onClick={() => history.push("/apply/etcService")}>
            기타 법률 문의하기
          </ServiceCenterFooterBtn>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default EtcNewServiceCenter;
