import { useHistory } from "react-router-dom";

import Footer from "components/Footer";
import IcLivingLawBanner from "../../assets/img/banner_pc.png"
import IcLivingLawBannerMobile from "../../assets/img/banner_mo.png"
import IcHeadset from "../../assets/svg/IcHeadset.svg"
import {
  Container,
  ContentContainer,
} from "../Service.style";
import { 
  LivingLawContentSection,
  LivingLawTitleWrapper,
  LivingLawDesc,
  LivingLawTitle,
  LivingLawWrapper,
  CarouselSlider,
  LivingLawCardList,
  LivingLawCardRowWrapper,
  LivingLawCardListWrapper,
  LivingLawCard,
  LivingLawCardContentWrapper,
  LivingLawCardContentDate,
  LivingLawCardContentDetail,
  LivingLawCardContentTitle,
  LivingLawFooterBannerImg,
  LivingLawFooterBannerWrapper,
  LivingLawHelpTitle,
  LivingLawHelpDesc,
  LivingLawHelpConsult
} from "./LivingLaw.style";

import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";
import { LivingLawData } from "./LivingLawData";
import { getFormattedCaseDate } from "util/util";
import { useRecoilState } from "recoil";
import { livingLawDetailIdxState } from "states/atoms";

const LivingLaw = ({loggedInUser}) => {
  const [livingLawDetailIdx, setLivingLawDetailIdx] = useRecoilState(livingLawDetailIdxState);
  const history = useHistory();
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const settings = {
    infinite: false,
    slidesToShow: 1,
    arrows: false,
    dots: true,
    slidesToScroll: 1,
    variableWidth: isMobile ? false : true
  };

  const sectionDesc = () => {
    const text = isMobile
      ? "내 일상을 보호하는 생활 법률 상식,\n법대리에서 모두 알려드려요."
      : "내 일상을 보호하는 생활 법률 상식, 법대리에서 모두 알려드려요.";
    return removeBrTag(text);
  };

  const renderCardUI = () => {
    const chunkedData = chunkArray(LivingLawData, 6);
    return chunkedData.map((chunk, index) => {
      return LivingLawCardListUI(chunk, index)
    })
  }

  const onClickLivingLawCard = (item, idx) => {
    setLivingLawDetailIdx(idx);
    const detailId = LivingLawData[idx].detailId;
    history.push('/livingLawDetail/' + detailId);
  }

  // 6개짜리 List
  const LivingLawCardListUI = (list, index) => {
    // 
    const subChunks = chunkArray(list, 2); // 각 조각을 2개씩 자름 3개씩 존재.
    return <LivingLawCardListWrapper>
              <LivingLawCardList>      
                {subChunks.map((chunk, subChunkIdx) => {           
                  return <LivingLawCardRowWrapper>
                    {chunk.map((item, subsubchunkIdx) => {
                      const realIdx = index * 6 + subChunkIdx * 2 + subsubchunkIdx
                    return <LivingLawCard onClick={() => onClickLivingLawCard(item, realIdx)}>
                            <LivingLawCardContentWrapper>
                              <LivingLawCardContentDate>
                                {getFormattedCaseDate(item.date)}
                              </LivingLawCardContentDate>
                              <LivingLawCardContentTitle>
                                {item.title}
                              </LivingLawCardContentTitle>                        
                            </LivingLawCardContentWrapper>
                            <LivingLawCardContentDetail>
                                자세히 보기 >
                            </LivingLawCardContentDetail>
                          </LivingLawCard>
                  })}
                  </LivingLawCardRowWrapper>
                })}
              </LivingLawCardList>
            </LivingLawCardListWrapper>
  }

  function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  return (
    <Container>
      <LivingLawContentSection>
        <ContentContainer style={{
              width: 674
            }}>
          <LivingLawWrapper>
            <LivingLawTitleWrapper>
              <LivingLawTitle>
                생활법률 이야기
              </LivingLawTitle>
              <LivingLawDesc>
              {sectionDesc()}
              </LivingLawDesc>
            </LivingLawTitleWrapper>
            <CarouselSlider {...settings}>
              {renderCardUI()}
            </CarouselSlider>
            <LivingLawFooterBannerWrapper onClick={() => history.push("/")}>
              <picture>
                  <source media="(max-width: 768px)" srcSet={IcLivingLawBannerMobile} />
                  <source media="(min-width: 768px)" srcSet={IcLivingLawBanner} />
                  <LivingLawFooterBannerImg src={IcLivingLawBanner}/>
              </picture>              
            </LivingLawFooterBannerWrapper>
          </LivingLawWrapper>
        </ContentContainer>
      </LivingLawContentSection>
      <section
        style={{
          width: "100%",
          background: "#BDE9FF",
          paddingTop: 63,
          paddingBottom: 63,
        }}
      >
        <ContentContainer>
          <LivingLawHelpTitle style={{ marginBottom: 20, marginTop: 0 }}>
          법대리 법률 상담 
          </LivingLawHelpTitle>
          <LivingLawHelpDesc>
            복잡한 법률 용어, 지식은 모르셔도 됩니다.
            <br />
            법대리가 알려드리는 항목들만 작성 및 첨부해 주시면 법대리의 전문
            변호사단이 검토 후 의견을 드립니다.
          </LivingLawHelpDesc>
          <LivingLawHelpConsult onClick={()=> {
            //  LandingToChannelTalk();
            if(!loggedInUser){
              ChannelService.loadScript();
              ChannelService.boot({
                "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
              })
            }
            ChannelService.showMessenger();
          }}>
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </LivingLawHelpConsult>
        </ContentContainer>
      </section>
      <Footer/>
    </Container>    
  );
};
export default LivingLaw;  