import styled from "@emotion/styled";

export const CertEtcCenterSectionFirst = styled.section`
  width: 100%;
  display: flex;
  padding: 80px 23px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: #3D3D3D;
`;

export const CertEtcCenterOtherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

export const CertEtcCenterOtherDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

export const SerivceCenterConsultingSection = styled.section`
  width: 100%;
  background: #BDE9FF;
  padding-top: 60px;
  padding-bottom: 60px;
`

export const CertEtcCenterOtherNoticeText = styled.div`
  color: #A1A1A1;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 164.286% */
`

export const EtcSelectDescWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const SelectDescSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

export const SelectDescSubText = styled.div`
  color: #E6E6E6;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
`

export const ServiceCenterFirstSection = styled.section`
  display: flex;
  width: 100%;
  padding: 100px 200px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 86px 0px;
  }
`;

export const ServiceCenterFirstContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 30px;
  }
`;

export const ServiceCenterFirstImg = styled.img`
`

export const ServiceCenterFirstTitle = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`

export const ServiceCenterSecondSection = styled.section`
  display: flex;
  width: 100%;
  padding: 80px 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  background: #F5F5F5;
  @media screen and (max-width: 768px) {
    padding: 50px 21.5px;
  }
`;

export const ServiceCenterSecondContainer = styled.div`
  display: flex;
  width: auto;
  /* flex-direction: column; */
  justify-content: center;
  gap: 50px;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 39px;
  }
`;

export const ServiceEstateAllCenterSecondContainer = styled.div`
  display: flex;
  width: auto;
  /* flex-direction: column; */
  justify-content: center;
  gap: 130px;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 39px;
  }
`;

export const ServiceCenterSecondTextContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex-shrink: 0;
`

export const ServiceCenterSecondTitle = styled.div`
  color: #1F2E3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 139.13% */
  @media screen and (max-width: 768px) {
    font-size: 32px;
    width: 100%;
    line-height: 43px;
  }
`

export const ServiceCenterSecondSubTitle = styled.div`
  color: #1F2E3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 20px;
  }
`

export const ServiceCenterSecondDesc = styled.div`
  width: 620px;
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  padding-top: 30px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ServiceCenterSecondImg = styled.img`
`

export const ServiceCenterThirdSection = styled.section`
  display: flex;
  width: 100%;
  padding: 80px 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 50px 21.5px;
  }
`;

export const ServiceCenterThirdContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  gap: 50px;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 47px;
  }
`;

export const ServiceCenterThirdTextContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex-shrink: 0;
`

export const ServiceCenterThirdTwoTextContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 0px;
  gap: 15px;
  flex-shrink: 0;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
  }
`

export const ServiceCenterThirdTitle = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 139.13% */
  @media screen and (max-width: 768px) {
    font-size: 32px;
    width: 100%;
    text-align: center;
    line-height: 42px;
  }
`

export const ServiceCenterThirdSubTitle = styled.div`
  color: #1F2E3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  @media screen and (max-width: 768px) {
    font-size: 20px;
    width: 100%;
    text-align: center;
    line-height: 30px;
  }
`

export const ServiceCenterThirdDescContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  flex-shrink: 0;
`

export const ServiceCenterThirdDescTitle = styled.div`
  width: 658px;
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  padding-top: 30px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ServiceCenterThirdDesc = styled.div`
  width: 658px;
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  padding-top: 30px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ServiceCenterThirdDescSpan = styled.span`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`;

export const ServiceCenterThirdImg = styled.img`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const ServiceCenterThirdImgMobile = styled.img`
  display: none;
  @media screen and (max-width: 768px) {    
    display: block;
  }
`

export const ServiceCenterFooterSection = styled.section`
  display: flex;
  width: 100%;
  padding: 80px 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #3A7EFC;

  @media screen and (max-width: 768px) {
    padding: 50px 21.5px;
  }
`;

export const ServiceCenterFooterContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ServiceCenterFooterTextContainer = styled.div`
  display: flex;
  width: 710px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ServiceCenterFooterTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 139.13% */
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 42px;
  }
`

export const ServiceCenterFooterDesc = styled.div`
  width: 615px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
  }
`

export const ServiceCenterFooterBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 33px 52px;
  margin: 0 auto;
  border-radius: 20px;
  background: #fff;
  color: #3A7EFC;
  text-align: center;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 100% */
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 768px) {
    padding: 23px 21.5px;
    font-size: 24px;
    line-height: 34px;
  }
`

export const ServiceCenterFooterBtnContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 25px;
  }
`

// Apply Service

export const ApplyServiceDescSection = styled.section`
  display: flex;
  width: 100%;
  padding: 50px 0px;
  justify-content: center;
  align-items: center;
  background: #F5F5F5;

  @media screen and (max-width: 768px) {
    padding: 50px 0px;
  }
`;

export const ApplyServiceDescContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ApplyServiceDescText = styled.div`
  color: #1F2E3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
`

export const ApplyServiceSubmitSection = styled.section`
  display: flex;
  width: 100%;
  padding: 80px 0px;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.active ? "#3A7EFC" : "#B9B9B9")};

  @media screen and (max-width: 768px) {
    padding: 50px 37.5px;
  }
`;

export const ApplyServiceSubmitContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

export const ApplyServiceSubmitTextContainer = styled.div`
  display: flex;
  width: 710px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ApplyServiceSubmitTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 139.13% */
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 42px;
  }
`

export const ApplyServiceSubmitDescContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 768px) {
    gap: 10px;
    flex-direction: column;
  }
`

export const ApplyServiceSubmitDesc = styled.div`
  width: 615px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
  }
`

export const ApplyServiceSubmitBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 98px;
  margin: 0 auto;
  border-radius: 20px;
  background: ${(props) => (props.active ? "#ffffff" : "#D9D9D9")};
  color: ${(props) => (props.active ? "#3A7EFC" : "#B9B9B9")};
  text-align: center;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 100% */
  cursor: pointer;
  border-radius: 20px;
  box-shadow: ${(props) => (props.active ? "0px 10px 10px 0px rgba(0, 0, 0, 0.25)" : "none")};
  border: none;

  /* :disabled {
    background: gray;
  } */

  @media screen and (max-width: 768px) {
    padding: 23px 58.5px;
    font-size: 24px;
    line-height: 34px;
  }
`

export const ServiceCenterEstateSection = styled.section`
  display: flex;
  width: 100%;
  padding: 80px 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 50px 21.5px;
  }
`;

export const ServiceCenterEstateContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    gap: 80px;
  }
`;

export const ServiceCenterEstateTitle = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 46px;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 139.13% */
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 32px;
    text-align: center;
    line-height: 40px;
  }
`

export const ServiceCenterEstateStepContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 25px;
  }
`;

export const ServiceCenterEstateStepTextContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ServiceCenterEstateStepTextStep = styled.div`
  color: #3A7EFC;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
`;

export const ServiceCenterEstateStepTextTitle = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  padding-top: 10px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 40px;
  }
`;

export const ServiceCenterEstateStepTextDesc = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const ServiceCenterEstateStepButton = styled.div`
  display: flex;
  width: 350px;
  height: 100px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 20px;
  background: #3A7EFC;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 100%;
    /* padding: 14px 41px; */
  }
`

export const ServiceCenterEstateStepButtonTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 100% */
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`

export const ServiceCenterEstateStepButtonImg = styled.img`
  width: 50px;
  height: auto;
  @media screen and (max-width: 768px) {
    width: 40px;
  }
`

export const ServiceCenterDescSection = styled.section`
display: flex;
width: 100%;
padding: 50px 200px;
justify-content: center;
background: #F5F5F5;
@media screen and (max-width: 768px) {
  padding: 50px 37.5px;
  margin-bottom: 50px;
}
`;

export const ServiceCenterDescContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: center;;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    gap: 80px;
  }
`;

export const ServiceCenterDescText = styled.div`
  color: #1F2E3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
`;