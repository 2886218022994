import { useEffect, useState } from "react";
import { dbService } from "fbase";
import { message } from "antd";
import { getFormattedDate } from "util/util";
import IcNoCase from "assets/svg/IcNoCase.svg";
import EventBannerMobile from "assets/svg/EventBannerMobile.svg";
import ProfileAcceptedDefault from "assets/svg/ProfileAcceptedDefault.svg";
import ProfileDefault from "assets/svg/ProfileDefault.svg";
import CaseSuggestIcon from "assets/svg/CaseSuggestIcon.svg";
import CaseNoSuggestIcon from "assets/svg/CaseNoSuggestIcon.svg";
import IcBadgeSubmitted from "assets/svg/IcBadgeSubmitted.svg";
import IcBadgeRequested from "assets/svg/IcBadgeRequested.svg";
import IcBadgeReplied from "assets/svg/IcBadgeReplied.svg";
import IcBadgePaid from "assets/svg/IcBadgePaid.svg";
import IcBadgeDone from "assets/svg/IcBadgeDone.svg";
import IcBadgeProcessing from "assets/svg/IcBadgeProcessing.svg";
import * as Sentry from "@sentry/react";

import { collection, query, where, getDocs, getDoc, deleteDoc, doc } from "firebase/firestore";

import {
  Container,
  ContentContainer,
  EventBannerMobileImg,
  ListCard,
  ListCardBadge,
  ListCardBadgeIcon,
  ListCardBadgeWrapper,
  ListCardDate,
  ListCardTopLeftWrapper,
  ListCardTopWrapper,
  ListCardTtile,
  ListLawyerDesc,
  ListLawyerImg,
  ListLawyerTextBtnWrapper,
  ListLawyerSelectBtn,
  ListLawyerSelectBtnMobile,
  ListLawyerSelectBtnWrapper,
  ListLawyerSelectBtnWrapperMobile,
  ListLawyerName,
  ListLawyerNameSuggestIcon,
  ListLawyerSuggestTitle,
  ListLawyerNameSuggestWrapper,
  ListLawyerTextWrapper,
  ListLawyerWrapper,
  ListTitle,
  MyCaseListSection,
  NoCaseDesc,
  NoCaseDescBold,
  NoCaseTitle,
} from "./MyCaseList.style";
import { useHistory } from "react-router-dom";
import Footer from "components/Footer";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentMyCaseState, userData } from "states/atoms";
import { ServiceType } from "util/ServiceType";
import axios from "axios";

const MyCaseList = ({ loggedInUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [cases, setCases] = useState([]);
  const userInfo = useRecoilValue(userData);
  const [currentMyCase, setCurrentMyCase] = useRecoilState(currentMyCaseState);
  // fetch my cases
  useEffect(() => {
    try {
      // update3o3UserData("+82 10-5189-5181", "cU6IeoRSu6MesmoMz4CdnlKdY0u2")
      fetchData();
      if (userInfo.phoneNumber && loggedInUser.uid) {
        update3o3UserData(userInfo.phoneNumber, loggedInUser.uid);
      } else {
        fetchData();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = async () => {
    await dbService
      .collection("requests_temp")
      .where("userId", "==", loggedInUser.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          const requests = snapshot.docs.map((doc) => {
            return {
              requestId: doc.id,
              id: doc.id,
              ...doc.data(),
            };
          });
          setCases([...requests].sort((a, b) => b.createdAt - a.createdAt));
        }
      })
      .catch((error) => {
        message.error("잘못된 요청입니다.");
        history.push("/");
      });
  };

  const update3o3UserData = async (phoneNumber, firebaseAuthId) => {
    const q = query(collection(dbService, "to3o3_requests"), where("phone", "==", phoneNumber));
    const querySnapshot = await getDocs(q);
    const dataList = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    })
    if (dataList.length === 0) {
      fetchData();
      return;
    }
    console.log("dataListdataList", dataList);
    for (const data of dataList) {
      const serviceId = data.serviceId
      const tempDataQuery = query(collection(dbService, "requests_temp"), where("serviceId", "==", serviceId));
      const docSnaps = await getDocs(tempDataQuery);
      if (docSnaps.empty) {
        try {
          const lawyerProposalList = data.lawyer_proposal_id_list ?? []
          console.log("lawyerProposalList", lawyerProposalList, "lawyerProposalList.length", lawyerProposalList.length);
          // 데이터 추가.
          await dbService.collection("requests_temp").add({
            serviceType: data.serviceType,
            serviceId: data.serviceId,
            createdAt: Date.now(),
            service_data: data.service_data,
            userId: firebaseAuthId,
            clientName: data.name,
            lawyer_proposal_id_list: lawyerProposalList,
            status: data.status,
          })
            .then(async docRef => {
              // 변호사의 제안이 있다면 변호사 
              if (lawyerProposalList.length !== 0) {
                for (const lawyerProposal of lawyerProposalList) {
                  await updateProposal(lawyerProposal.id, docRef.id);
                }
              }
              await fetchData();
              try {
                // 저장된 데이터 제거.
                await deleteDoc(doc(dbService, "to3o3_requests", data.id));
              } catch (err) {
                Sentry.captureException(`[3o3][MyCaseList][sync]  name : ${data.name} phone: ${data.phone} delete doc error : `, err);
              }
              await sendWebhook(data.serviceType, docRef.id, data.name, data.phone);
            })
        } catch (err) {
          Sentry.captureException(`[3o3][MyCaseList][sync] ${phoneNumber} error : `, err);
        }
      } else {
        fetchData();
      }
    }
  };

  async function updateProposal(proposalId, client_request_id) {
    console.log("[PROPOSAL] proposalId ", proposalId, "client_request_id", client_request_id);
    if (proposalId === undefined) {
      return;
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_UPDATE_PROPOSAL_CLIENT_ID_URL
    const body = {
      proposalId: proposalId,
      client_request_id: client_request_id
    };
    const url = host;
    try {
      const { data } = await axios.post(url, body);
      // setLawyer(data);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async function sendWebhook(serviceType, client_req_id, name, phone) {
    const webhookUrl = process.env.REACT_APP_DISCORD_TO3_SYNC_WEBHOOK_DOMAIN;
    const serviceTypeTitle = getServiceTypeTitle(serviceType)
    console.log("serviceType", serviceType);
    const message = {
      content: `🔥삼쩜삼 법대리 싱크 완료🔥
      사건 타입: ${serviceTypeTitle}
      유저: ${name} 
      전화번호: ${phone}
      client_req_id(법대리 사건id): ${client_req_id}`
    };

    try {
      const { response } = await axios.post(webhookUrl, message);
    } catch (e) {
    }
  }

  const getDesc = (status) => {
    switch (status) {
      case "submitted":
        return "현재 변호사가 사건을 검토 중입니다.";
      case "replied":
        return "변호사가 사건에 대한 답변을 완료했습니다.";
      case "paid":
        return "변호사가 사건을 수임하였습니다.";
      case "requested":
        return "변호사가 서류를 요청하였습니다";
      case "processing":
        return "변호사가 사건을 처리 중입니다.";
      case "done":
        return "사건이 해결되었습니다.";
      default:
        return "";
    }
  };

  const getSelectBtnDesc = (status) => {
    switch (status) {
      case "replied":
        return "의견서 확인하기";
      case "submitted":
        return "의견서 확인하기";
      default:
        return "사건 상세보기";
    }
  };

  const getLawyerName = (_case) => {
    if (_case.accepted == null || _case.accepted == undefined) {
      return "변호사";
    }
    return _case.accepted.lawyer_name;
  };

  const handleCardClick = (caseData) => {
    switch (caseData.status) {
      case "paid":
      case "processing":
      case "requested":
      case "done":
        setCurrentMyCase(caseData);
        history.push({
          pathname: "/case_detail",
        });
        return;
      default:
        setCurrentMyCase(caseData);
        history.push({
          pathname: "/my_case",
        });
    }
  };

  if (isLoading) return null;

  if (cases.length === 0) {
    return (
      <Container>
        <MyCaseListSection>
          <ContentContainer>
            <NoCaseTitle>아직 제출된 요청문서가 없습니다.</NoCaseTitle>
            <img src={IcNoCase} style={{ marginBottom: 32 }} />

            <NoCaseDesc>
              <NoCaseDescBold>법대리 법률 서비스 센터</NoCaseDescBold>
              를 통해서 <br />
              무료로 법률 검토를 받아보세요.
            </NoCaseDesc>
          </ContentContainer>
        </MyCaseListSection>
      </Container>
    );
  }

  const isPropsalExist = (_case) => {
    if (_case.lawyer_proposal_id_list === undefined) {
      return false;
    } else {
      return _case.lawyer_proposal_id_list.length !== 0;
    }
  };

  const getSuggestedLawyerCount = (_case) => {
    if (_case.lawyer_proposal_id_list === undefined) {
      return "제안 0";
    } else {
      return "제안 " + _case.lawyer_proposal_id_list.length;
    }
  };

  const getBadgeIcon = (status) => {
    switch (status) {
      case "submitted":
        return IcBadgeSubmitted;
      case "replied":
        return IcBadgeReplied;
      case "paid":
        return IcBadgePaid;
      case "requested":
        return IcBadgeRequested;
      case "processing":
        return IcBadgeProcessing;
      case "done":
        return IcBadgeDone;
      default:
        return {};
    }
  };

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseListSection>
        <ContentContainer>
          <ListTitle>나의 제출 목록</ListTitle>
          {cases.map((_case) => (
            <ListCard
              key={`case-${_case.requestId}`}
              onClick={() => handleCardClick(_case)}
            >
              <ListCardTopWrapper>
                <ListCardTopLeftWrapper>
                  <ListCardBadgeWrapper>
                    <ListCardBadgeIcon src={getBadgeIcon(_case.status)} />
                    <ListCardBadge status={_case.status}>
                      {getStatus(_case.status)}
                    </ListCardBadge>
                  </ListCardBadgeWrapper>
                </ListCardTopLeftWrapper>
                <ListCardDate>
                  신청일 | {getFormattedDate(_case.createdAt)}
                </ListCardDate>
              </ListCardTopWrapper>
              <ListCardTtile>
                {getServiceTypeTitle(_case.serviceType)}
              </ListCardTtile>
              <ListLawyerWrapper>
                <ListLawyerTextBtnWrapper>
                  {_case.lawyer_img ? (
                    <ListLawyerImg
                      src={_case.lawyer_img}
                      alt={
                        _case.accepted != null
                          ? ProfileAcceptedDefault
                          : ProfileDefault
                      }
                    />
                  ) : (
                    <ListLawyerImg
                      src={
                        _case.accepted != null
                          ? ProfileAcceptedDefault
                          : ProfileDefault
                      }
                    />
                  )}
                  <ListLawyerTextWrapper>
                    <ListLawyerNameSuggestWrapper>
                      <ListLawyerName>{getLawyerName(_case)}</ListLawyerName>
                      {(_case.status === "submitted" ||
                        _case.status === "replied") && (
                          <>
                            <ListLawyerNameSuggestIcon
                              src={
                                isPropsalExist(_case)
                                  ? CaseSuggestIcon
                                  : CaseNoSuggestIcon
                              }
                            />
                            <ListLawyerSuggestTitle
                              status={
                                isPropsalExist(_case) ? "suggested" : "none"
                              }
                            >
                              {getSuggestedLawyerCount(_case)}
                            </ListLawyerSuggestTitle>
                          </>
                        )}
                    </ListLawyerNameSuggestWrapper>
                    <ListLawyerDesc>{getDesc(_case.status)}</ListLawyerDesc>
                  </ListLawyerTextWrapper>
                </ListLawyerTextBtnWrapper>
                <ListLawyerSelectBtnWrapper>
                  <ListLawyerSelectBtn>
                    {getSelectBtnDesc(_case.status)}
                  </ListLawyerSelectBtn>
                </ListLawyerSelectBtnWrapper>
              </ListLawyerWrapper>
              <ListLawyerSelectBtnWrapperMobile>
                <ListLawyerSelectBtnMobile>
                  {getSelectBtnDesc(_case.status)}
                </ListLawyerSelectBtnMobile>
              </ListLawyerSelectBtnWrapperMobile>
            </ListCard>
          ))}
          <EventBannerMobileImg src={EventBannerMobile} />
        </ContentContainer>
      </MyCaseListSection>
      <Footer />
    </Container>
  );
};

export default MyCaseList;

export function getServiceTypeTitle(serviceType) {
  switch (serviceType) {
    case ServiceType.All:
      return "전체"
    // RegisterCaseDocumentForm
    case ServiceType.Drug:
      return "마약사건 양형자료"
    // RegisterCaseDocumentForm
    case ServiceType.DrunkDriving:
      return "음주운전 양형자료"
    // RegisterCaseDocumentForm
    case ServiceType.HousingCertification:
      return "전세금 반환 내용증명"
    case ServiceType.HousingProtection:
      return "임차권 등기명령"
    // RegisterCaseDocumentForm
    case ServiceType.HousingPublicConveyance:
      return "전세금 반환 공시송달"
    case ServiceType.HousingSeizure:
      return "부동산 가압류"
    // RegisterCaseDocume
    case ServiceType.LendMoney:
      return "대여금 지급명령"
    case ServiceType.LendCertification:
      return "대여금 내용증명"
    // RegisterCaseDocumentForm
    case ServiceType.propertyDeposit:
      return "전세금 반환 지급명령"
    case ServiceType.PlaintInsult:
      return "모욕죄 고소장"
    case ServiceType.MediaObscenity:
      return "통매음 사건 양형자료"
    case ServiceType.SexualCrimeComplaint:
      return "성범죄 고소장 작성"
    case ServiceType.NoiseCertification:
      return "층간 소음 내용증명";
    case ServiceType.PilatesCertification:
      return "헬스장/필라테스 환불 내용증명";
    case ServiceType.PilatesOrder:
      return "헬스장/필라테스 환불 지급명령";
    case ServiceType.HostCancelCertification:
      return "부동산 계약해지 내용증명";
    case ServiceType.HostChargeCertification:
      return "월세 청구 내용증명";
    case ServiceType.HostNotallowedCertification:
      return "임대차 계약연장 불허 통지 내용증명";
    case ServiceType.CivilDefendant:
      return "민사소송 원고"
    case ServiceType.CivilPlaintiff:
      return "민사소송 피고"
    case ServiceType.ETCCertification:
      return "기타 내용증명"
    case ServiceType.CommonCriminalDrive:
      return "형사 사건 운전사고"
    case ServiceType.CommonCriminalSexual:
      return "형사 사건 성범죄"
    case ServiceType.CommonCriminalRepresant:
      return "형사 사건 고소대리"
    case ServiceType.CommonCriminalEtc:
      return "형사 사건 기타"
    case ServiceType.CommonEtc:
      return "기타 법률"
    case ServiceType.GolfTennisCertification:
      return "골프/테니스 환불 내용증명"
    case ServiceType.GolfTennisOrder:
      return "골프/테니스 환불 지급명령"
    case ServiceType.InheritGiveup:
      return "상속포기"
    case ServiceType.InheritLimited:
      return "한정승인"
    case ServiceType.Vacatehouse:
      return "명도소송"
    case ServiceType.Injunction:
      return "가처분"
    case ServiceType.To3Estate:
      return "(삼쩜삼)전세금 반환 내용증명"
    case ServiceType.To3Etc:
      return "(삼쩜삼)기타 내용증명"
    case ServiceType.To3GolfTennis:
      return "(삼쩜삼)골프/테니스 환불 내용증명"
    case ServiceType.To3Loan:
      return "(삼쩜삼)대여금 반환 내용증명"
    case ServiceType.To3Noise:
      return "(삼쩜삼)층간소음 내용증명"
    case ServiceType.To3Pilates:
      return "(삼쩜삼)헬스/필라테스 환불 내용증명"
    case ServiceType.To3Pay:
      return "(삼쩜삼)임금체불 내용증명"
    case ServiceType.CommonOrder:
      return "못받은 돈 돌려받기(지급명령)"
    case ServiceType.CommonCertification:
      return "내용증명 보내기"
    case ServiceType.Complaint:
      return "고소장 작성하기"
    case ServiceType.Contract:
      return "계약서 검토하기"
    case ServiceType.Agreement:
      return "합의서 작성하기"
    case ServiceType.Opinion:
      return "의견서 작성하기"
    default:
      return ""
  }
}

function getStatus(status) {
  switch (status) {
    case "submitted":
      return "신청 완료";
    case "replied":
      return "답변 완료";
    case "paid":
      return "결제완료";
    case "requested":
      return "서류요청";
    case "processing":
      return "요청처리중";
    case "done":
      return "처리 완료";
    default:
      return "";
  }
}
