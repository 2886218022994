import Collapse from "components/common/Collapse";
import { SERVICE_QNA } from "../../service-constants";
import IcPlaint from "../../assets/svg/IcPlaint.svg";
import IcHeadset from "../../assets/svg/IcHeadset.svg";
import IcDriveAlarm from "../../assets/svg/IcDriveAlarm.svg";
import IcDriveSearch from "../../assets/svg/IcDriveSearch.svg";
import IcDriveHour from "../../assets/svg/IcDriveHour.svg";
import IcDrivePrice from "../../assets/svg/IcDrivePrice.svg";

import { 
  CriminalTopWrapper,
  CriminalDriveServiceWrapper,
  CriminalDriveServiceFirstWrapper,
  CriminalDriveServiceTitle,
  CriminalDriveServiceDesc,
  CriminalDriveServiceSubTitle,
  CriminalDriveServiceBtn,
  CriminalDriveServiceBorder,
  CriminalDriveServiceSecondWrapper,
  CriminalDriveServiceSecondTitleWrapper,
  CriminalDriveServiceSecondContentWrapper,
  CriminalDriveServiceSecondContentImg,
  CriminalDriveServiceSecondContentCardDescWrapper,
  CriminalDriveServiceThirdWrapper,
  CriminalDriveServiceThirdTitleContentWrapper,
  CriminalDriveServiceThirdContentWrapper,
  CriminalDriveServiceThirdContentRow,
  CriminalDriveServiceThirdContentRowDescWrapper,
  CriminalDriveServiceThirdHelpWrapper,
  CriminalDriveServiceThirdHelpContainer,
  CriminalDriveServiceThirdHelpContentContainer,
  CriminalDriveServiceCenterDesc,
  CriminaServiceSecondContentOneCardWrapper,
  CriminalDriveServiceDesc1,
  CriminalDriveServiceDesc1Wrapper
 } from "./CommonCriminal/CommonCriminal.style";
import { removeBrTag } from "routes/Landing";
import {
  AccusationServiceCenterSection,
  BtnConsult,
  CollapseWrapper,
  Container,
  ContentContainer,
  SectionTop,
  SubTitle,
  Title,
  TopTitle,
} from "../Service.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { SerivceCenterConsultingSection } from "./ServiceCenter.style";
import { useHistory } from "react-router-dom";

const AccusationServiceCenter = ({loggedInUser}) => {
  
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title1 = () => {
    const text = isMobile
      ? "모욕 · 명예훼손 사건을\n가장 잘 아는 전문 변호사에게\n법률 서비스를 신청하세요."
      : "모욕 · 명예훼손 사건을 가장 잘 아는 전문 변호사에게\n법률 서비스를 신청하세요.";
    return removeBrTag(text);
  };

  const titleDesc1 = () => {
    const text = isMobile
      ? "법대리에서는 모욕 · 명예훼손 사건을 전문적으로 해결한 변호사들에게 24시간 법률 서비스를 받을 수 있는 시스템을 구축하고 있습니다. 지금 바로 신청하세요!"
      : "법대리에서는 모욕 · 명예훼손 사건을 전문적으로 해결한 변호사들에게 24시간 법률 서비스를 받을 수 있는\n시스템을 구축하고 있습니다. 지금 바로 신청하세요!";
    return removeBrTag(text);    
  }

  const desc2 = () => {
    const text = isMobile
      ? "모욕죄 · 명예훼손죄 고소장은 정확한 사건 내용과\n확보되는 증거들을 토대로 고소장을 작성해야 합니다.\n고소장 반려를 당하지 않도록\n형사 고소 전문 변호사가 직접 검토 후\n작성하는 고소장을 통해 상대방을 고소하세요"
      : "모욕죄 · 명예훼손죄 고소장은 정확한 사건 내용과\n확보되는 증거들을 토대로 고소장을 작성해야 합니다.\n고소장 반려를 당하지 않도록 형사 고소 전문 변호사가 직접 검토 후\n작성하는 고소장을 통해 상대방을 고소하세요";
    return removeBrTag(text);
  };

  const title2 = () => {
    const text = isMobile
      ? "법대리에서\n전문 변호사를 선임해서\n쉽고 빠르게 해결하세요"
      : "법대리에서 전문 변호사를 선임해서\n쉽고 빠르게 해결하세요";
    return removeBrTag(text);
  };

  const content1 = () => {
    const text = isMobile
      ? "법대리 소속 전문 변호사가 제공하는 무료 법률 진단서를 받아보세요. 문서화 된 진단서를 통해 정확한 상황 진단 및 해결 방법까지 얻을 수 있습니다."
      : "법대리 소속 전문 변호사가 제공하는 무료 법률 진단서를 받아보세요.\n문서화 된 진단서를 통해 정확한 상황 진단 및 해결 방법까지 얻을 수 있습니다.";
    return removeBrTag(text);
  };

  const content2 = () => {
    const text = isMobile
      ? "법대리는 불필요한 변호사 선임 유도를 통해\n이윤을 남기지 않으며\n쉽고 빠르게 문제를 해결 할 수 있도록\n도와드립니다.\n전문 변호사에게 사건을 의뢰하세요."
      : "법대리는 불필요한 변호사 선임 유도를 통해 이윤을 남기지 않으며\n쉽고 빠르게 문제를 해결 할 수 있도록 도와드립니다.\n전문 변호사에게 사건을 의뢰하세요.";
    return removeBrTag(text);
  };
  
  const content3 = () => {
    const text = isMobile
      ? "특정 법인에서 한정된 변호사 풀이 아닌\n각 사건에 특성화된 전문 변호사를\n선임할 수 있습니다.\n사건 해결 경험이 풍부한 전문 변호사의\n법률 서비스 및 각종 지원을 받을 수 있습니다."
      : "특정 법인에서 한정된 변호사 풀이 아닌\n각 사건에 특성화된 전문 변호사를 선임할 수 있습니다.\n사건 해결 경험이 풍부한 전문 변호사의 법률 서비스 및 각종 지원을 받을 수 있습니다.";
    return removeBrTag(text);
  };

  const content4 = () => {
    const text = isMobile
      ? "법대리 사이트에서 사건 현황을 실시간으로\n확인가능하며\n사건 프로세스 별로 알림톡을 전송하여\n수시로 확인하지 않아도 됩니다."
      : "법대리 사이트에서 사건 현황을 실시간으로 확인가능하며\n사건 프로세스 별로 알림톡을 전송하여 수시로 확인하지 않아도 됩니다.";
    return removeBrTag(text);
  };

  const helper1 = () => {
    const text = isMobile
      ? "혼자 진행하다가 실수하지 마세요!\n법대리가 법률문제를 저렴한 가격에\n쉽고 빠르게 해결해드려요."
      : "혼자 진행하다가 실수하지 마세요!\n법대리가 법률문제를 저렴한 가격에 쉽고 빠르게 해결해드려요.";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/plaint/insult");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <SectionTop style={{ height: 170 }}>
        <ContentContainer style={{ width: "768px" }}>
          <CriminalTopWrapper>
            <TopTitle>모욕 · 명예훼손 고소장 작성</TopTitle>
          </CriminalTopWrapper>
        </ContentContainer>
      </SectionTop>
      <AccusationServiceCenterSection>
        <ContentContainer>
          <CriminalDriveServiceWrapper>
          <CriminalDriveServiceFirstWrapper>
            <CriminalDriveServiceTitle>
            {title1()}
            </CriminalDriveServiceTitle>
            <CriminalDriveServiceDesc>
            {titleDesc1()}
            </CriminalDriveServiceDesc>
            <CriminalDriveServiceBtn onClick={() => onClickBtn()}>
              지금 바로 무료 진단받기
            </CriminalDriveServiceBtn>
          </CriminalDriveServiceFirstWrapper>
          <CriminalDriveServiceBorder />
          <CriminalDriveServiceSecondWrapper>
            <CriminalDriveServiceSecondTitleWrapper>
              <CriminalDriveServiceTitle>
              {title2()}
              </CriminalDriveServiceTitle>
            </CriminalDriveServiceSecondTitleWrapper>
            <CriminalDriveServiceSecondContentWrapper>
              <CriminaServiceSecondContentOneCardWrapper>
                <CriminalDriveServiceSecondContentImg src={IcPlaint} alt=""/>
                <CriminalDriveServiceSecondContentCardDescWrapper>
                  <CriminalDriveServiceSubTitle>
                  모욕죄 · 명예훼손죄 고소장 작성
                  </CriminalDriveServiceSubTitle>
                  <CriminalDriveServiceDesc1Wrapper>
                    <CriminalDriveServiceDesc1>
                    · 명예훼손 고소장 작성
                    </CriminalDriveServiceDesc1>
                    <CriminalDriveServiceDesc>                  
                    {desc2()}
                    </CriminalDriveServiceDesc>
                  </CriminalDriveServiceDesc1Wrapper>
                </CriminalDriveServiceSecondContentCardDescWrapper>
              </CriminaServiceSecondContentOneCardWrapper>            
            </CriminalDriveServiceSecondContentWrapper>
          </CriminalDriveServiceSecondWrapper>
          <CriminalDriveServiceBorder />
          <CriminalDriveServiceThirdWrapper>
            <CriminalDriveServiceThirdTitleContentWrapper>
              <CriminalDriveServiceTitle>
                왜 법대리에 사건 의뢰를 맡겨야 할까요?
              </CriminalDriveServiceTitle>
              <CriminalDriveServiceThirdContentWrapper>
                <CriminalDriveServiceThirdContentRow>
                  <CriminalDriveServiceSecondContentImg src={IcDriveSearch}/>
                  <CriminalDriveServiceThirdContentRowDescWrapper>
                    <CriminalDriveServiceSubTitle>
                    내 상황에 딱 맞는 무료 법률 진단
                    </CriminalDriveServiceSubTitle>
                    <CriminalDriveServiceDesc>
                    {content1()}
                    </CriminalDriveServiceDesc>
                  </CriminalDriveServiceThirdContentRowDescWrapper>
                </CriminalDriveServiceThirdContentRow>
                <CriminalDriveServiceThirdContentRow>
                  <CriminalDriveServiceSecondContentImg src={IcDrivePrice}/>
                  <CriminalDriveServiceThirdContentRowDescWrapper>
                    <CriminalDriveServiceSubTitle>
                    거품 없는 최소비용으로 변호사 선임
                    </CriminalDriveServiceSubTitle>
                    <CriminalDriveServiceDesc>
                    {content2()}
                    </CriminalDriveServiceDesc>
                  </CriminalDriveServiceThirdContentRowDescWrapper>
                </CriminalDriveServiceThirdContentRow>
                <CriminalDriveServiceThirdContentRow>
                  <CriminalDriveServiceSecondContentImg src={IcDriveHour}/>
                  <CriminalDriveServiceThirdContentRowDescWrapper>
                    <CriminalDriveServiceSubTitle>
                    사건 분야별 전문 고경력 변호사 24시간 선임 가능
                    </CriminalDriveServiceSubTitle>
                    <CriminalDriveServiceDesc>
                    {content3()}
                    </CriminalDriveServiceDesc>
                  </CriminalDriveServiceThirdContentRowDescWrapper>
                </CriminalDriveServiceThirdContentRow>
                <CriminalDriveServiceThirdContentRow>
                  <CriminalDriveServiceSecondContentImg src={IcDriveAlarm}/>
                  <CriminalDriveServiceThirdContentRowDescWrapper>
                    <CriminalDriveServiceSubTitle>
                    알림톡으로 정확한 일정 안내
                    </CriminalDriveServiceSubTitle>
                    <CriminalDriveServiceDesc>
                    {content4()}
                    </CriminalDriveServiceDesc>
                  </CriminalDriveServiceThirdContentRowDescWrapper>
                </CriminalDriveServiceThirdContentRow>
              </CriminalDriveServiceThirdContentWrapper>
            </CriminalDriveServiceThirdTitleContentWrapper>
            <CriminalDriveServiceThirdHelpWrapper>
              <CriminalDriveServiceThirdHelpContainer>
                <CriminalDriveServiceThirdHelpContentContainer>
                  <CriminalDriveServiceCenterDesc>
                  {helper1()}
                  </CriminalDriveServiceCenterDesc>
                  <CriminalDriveServiceCenterDesc>
                  법대리에서 내 상황을 무료 진단 받고,<br/>온라인으로 쉽고 빠르게 변호사를 선임하세요.
                  </CriminalDriveServiceCenterDesc>
                </CriminalDriveServiceThirdHelpContentContainer>
                <CriminalDriveServiceBtn onClick={() => onClickBtn()}>
                  지금 바로 무료 진단받기
                </CriminalDriveServiceBtn>
                </CriminalDriveServiceThirdHelpContainer>
              </CriminalDriveServiceThirdHelpWrapper>
            </CriminalDriveServiceThirdWrapper>
          </CriminalDriveServiceWrapper>
          <CollapseWrapper>
            {SERVICE_QNA.accusation.map(({ title, desc }, idx) => (
              <Collapse
                key={`accusation-service$-${idx}`}
                title={title}
                desc={desc}
              />
            ))}
          </CollapseWrapper>
        </ContentContainer>
      </AccusationServiceCenterSection>
      <SerivceCenterConsultingSection>
        <ContentContainer>
          <Title style={{ marginBottom: 10, marginTop: 0 }}>
            기타 고소 상담
          </Title>
          <SubTitle style={{ marginBottom: 0 }}>
            복잡한 법률 용어, 지식은 모르셔도 됩니다.
            <br />
            법대리가 알려드리는 항목들만 작성 및 첨부해 주시면 법대리의 전문
            변호사단이 검토 후 의견을 드립니다.
          </SubTitle>
          <BtnConsult onClick={()=> {
            //  LandingToChannelTalk();
            if(!loggedInUser){
              ChannelService.loadScript();
              ChannelService.boot({
                "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
              })
            }
            ChannelService.showMessenger();
          }}>
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </BtnConsult>
        </ContentContainer>
      </SerivceCenterConsultingSection>
      <Footer />
    </Container>
  );
};

export default AccusationServiceCenter;
