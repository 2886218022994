import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Radio,
  Popover,
  Divider,
  Row,
  Col,
} from "antd";
import TermsOfService from "components/form/commons/TermsOfService";
import React, { useEffect, useState } from "react";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper,
} from "../../Form.style";

const Step3 = ({ data, setData, onPrev, setDbUpdate, itemLayout }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async (values) => {
    setSubmitting(true);
    setData({ ...values });
    setDbUpdate(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        고소장 작성 및 상세내용
      </Title>

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          입력한 내용을 바탕으로 고소가 가능한지
          <br />
          여부 확인 및 변호사가 고소장 작성을 진행합니다
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper style={{height:`90%`}}>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          form={form}
          style={{
            marginTop: 10,
            marginBottom: 20,
            width:"100%",
            textAlign:"left"
          }}
          requiredMark={false}
        >
          <Form.Item
            label="제3자 목격 여부"
            name={["thirdparty", "exist"]}
            rules={[
              {
                required: true,
                message: "제3자 목격 여부를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">있음</Radio.Button>
              <Radio.Button value="n">없음</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return (
                prevValues.thirdparty.exist !== currentValues.thirdparty.exist
              );
            }}
          >
            {() => (
              <>
                <Form.Item
                  label="목격자의 닉네임/이름"
                  rules={
                    form.getFieldValue(["thirdparty", "exist"]) === "n"
                      ? []
                      : [
                          {
                            required: true,
                            message: "목격자 닉네임을 알고있는지 선택해주세요",
                          },
                        ]
                  }
                  hidden={form.getFieldValue(["thirdparty", "exist"]) === "n"}
                  name={["thirdparty", "name"]}
                >
                  <Radio.Group>
                    <Radio.Button value="y">알고있음</Radio.Button>
                    <Radio.Button value="n">모름</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
          </Form.Item>

          <Form.Item
            label={
              <>
                증거 확보 유무
                <Popover
                  content={
                    <div>
                      가해자가 모욕을 했다는 사실을 입증할 자료
                      <br />
                      예) 스크린샷, PDF, 녹음파일 등
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["evidence", "exist"]}
            rules={[
              {
                required: true,
                message: "증거 확보 유무를 선택해주세요",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">있음</Radio.Button>
              <Radio.Button value="n">없음</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <TermsOfService />
          <Row justify="space-between" style={{ marginTop: 20 }}>
            <Col span={6} offset={0}>
              <Button
                type="primary"
                onClick={onPrev}
                style={{ width: "100%", maxWidth: "100px" }}
              >
                이전
              </Button>
            </Col>
            <Col span={6} offset={12} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                {submitting ? "제출 중" : "제출"}
              </Button>
            </Col>
          </Row>
        </Form>
        </CaseDetailWrapper>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step3;
