import { QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Upload,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Radio,
  message,
  Row,
  Modal,
} from "antd";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,
  CaseDetailWrapper,
} from "../../Form.style";
import { storageService } from "fbase";
import { v4 as uuidv4 } from "uuid";
import TermsOfService from "components/form/commons/TermsOfService";

import React, { useEffect, useState } from "react";
const { TextArea } = Input;

const Step2 = ({ data, setData, onPrev, onNext, itemLayout }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewTitle, setPreviewTitle] = useState(false);
  const key = "updatable";

  useEffect(() => {
    if (data.attachments) {
      message.success({
        content: "제출 완료!",
        key,
        duration: 2,
      });
      onNext();
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onFinish = async (values) => {
    setSubmitting(true);
    message.loading({
      content: "제출 중입니다.",
      key,
      duration: 0,
    });

    let attachments = [];
    await Promise.all(
      fileList.map(async (file) => {
        const fileRef = storageService
          .ref()
          .child(`pilates_certification/images/${uuidv4()}`);
        const response = await fileRef.put(file.originFileObj);
        const imgUrl = await response.ref.getDownloadURL();
        attachments.push(imgUrl);
      })
    ).then(() => {
      setData({ attachments: attachments, ...values });
    });
  };


  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onChange = ({ fileList }) => {
    setFileList(fileList.filter((file) => file.status !== "error"));
  };

  const onRemove = async (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };

  const onCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
        <Title>헬스/필라테스 환불 지급명령</Title>
          <div style={{ marginTop: 10 }}>
            <SubText style={{ textAlign: "left" }} type="secondary">
            헬스/필라테스 계약에 관련하여 아래 내용을 작성하세요.
            </SubText>
          </div>

          <Divider style={{ marginTop: 10 }} />
          <CaseDetailWrapper style={{ height: "90%" }}>
            <Form
              {...itemLayout.form}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={data}
              form={form}
              style={{
                marginTop: 10,
                marginBottom: 20,
                width: "100%",
                textAlign: "left",
              }}
              requiredMark={false}
            >
              <Form.Item
                label={"계약 유형"}
                name={["contract", "type"]}
                rules={[{ required: true, message: "" }]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Radio.Group>
                  <Radio.Button value="pt">PT</Radio.Button>
                  <Radio.Button value="membership">회원권</Radio.Button>
                </Radio.Group>
              </Form.Item>
              
              <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => {
                        return (
                          prevValues.contract.type !==
                          currentValues.contract.type
                        );
                      }}
                    >
                      {() => (
                        <>
                          <Form.Item
                             label={
                              <>
                                PT계약 횟수
                                <Popover
                                  content={
                                    <div>
                                      양도 계약의 경우 양도 전 
                                      <br />
                                      최초 계약 횟수를 작성해주세요.
                                    </div>
                                  }
                                  placement="bottom"
                                  trigger="click"
                                >
                                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                                </Popover>
                              </>
                            }
                            style={{ marginBottom: 0 }}
                            hidden={
                              form.getFieldValue([
                                "contract",
                                "type",
                              ]) === "membership"
                            }
                          >
                            <Form.Item
                              name={["contract", "count"]}
                              rules={
                                form.getFieldValue([
                                  "contract",
                                  "type",
                                ]) === "membership"
                                  ? []
                                  : [
                                      {
                                        required: true,
                                        message: "PT 계약 횟수를 입력해주세요.",
                                      },
                                    ]
                              }
                              // style={{ width: "calc(85% - 8px)" }}
                            >
                              <InputNumber
                                placeholder=""
                                style={{ width: "100%" }}
                                controls={false}
                              />
                            </Form.Item>
                          </Form.Item>

                          <Form.Item
                             label={
                              <>
                                PT사용(차감) 횟수
                                <Popover
                                  content={
                                    <div>
                                      양도 계약의 경우 양도 전 이용
                                      <br />
                                      횟수를 포함해서 작성해주세요.
                                    </div>
                                  }
                                  placement="bottom"
                                  trigger="click"
                                >
                                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                                </Popover>
                              </>
                            }
                            style={{ marginBottom: 0 }}
                            hidden={
                              form.getFieldValue([
                                "contract",
                                "type",
                              ]) === "membership"
                            }
                          >
                            <Form.Item
                              name={["contract", "usedCount"]}
                              rules={
                                form.getFieldValue([
                                  "contract",
                                  "type",
                                ]) === "membership"
                                  ? []
                                  : [
                                      {
                                        required: true,
                                        message: "PT 이용(차감) 횟수를 입력해주세요.",
                                      },
                                    ]
                              }
                              // style={{ width: "calc(85% - 8px)" }}
                            >
                              <InputNumber
                                placeholder=""
                                style={{ width: "100%" }}
                                controls={false}
                              />
                            </Form.Item>
                          </Form.Item>
                          
                        </>
                      )}
                    </Form.Item>


              <Form.Item
                label={
                  <>
                    계약 해지 통보 일자
                    <Popover
                      content={
                        <div>
                          환불을 최초 요청한 날짜를 입력해주세요.
                        </div>
                      }
                      placement="bottom"
                      trigger="click"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  </>
                }
                name={["contract", "notiDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>



              <Form.Item
            label={
              <>
                상세내용
                <Popover
                  content={
                    <div>
                      기타 입력하신 내용 외 특이사항이 있을 경우 작성해 주시면
                      변호사가 검토시 참고할 예정입니다.
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={"description"}
          >
            <TextArea rows={10} />
          </Form.Item>
          <Form.Item
            label="연락처"
            name={["contact"]}
            rules={[
              {
                required: true,
                message: "연락처를 입력해주세요",
              },
            ]}
          >
            <Input placeholder="검토 결과 알림을 받으실 연락처를 입력해주세요" />
          </Form.Item>
          <Form.Item label="프로모션 코드" name={["promotionCode"]}>
            <Input />
          </Form.Item>

          <div>
            <label>
              <>
                계약서
                <Popover
                  content={
                    <div>
                      계약서를 사진으로 첨부하시면
                      <br />
                      더 구체적인 사건 검토가 가능합니다.
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            </label>
            <div style={{ marginTop: 5, marginBottom: 15 }}>
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                onPreview={onPreview}
                onChange={onChange}
                onRemove={onRemove}
                fileList={fileList}
                maxCount={10}
              >
                <Button icon={<UploadOutlined />}>증거자료 첨부</Button>
              </Upload>
            </div>
          </div>
          <TermsOfService />
              <Row justify="space-between">
                <Col span={6}>
                  <Button
                    type="primary"
                    onClick={onPrev}
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    이전
                  </Button>
                </Col>
                <Col span={6} offset={10} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    {submitting ? "제출 중" : "제출"}
                  </Button>
                </Col>
              </Row>
            </Form>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={onCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </CaseDetailWrapper>
        </ContentContainer>
      </MyCaseSection>
    </Container>
  );
};

export default Step2;
