import React from "react";
import IcConfirmPopupQuestion from "../../assets/svg/IcConfirmPopupQuestion.svg";
import { 
    PopoverContentContainer,
    PopoverContentCenterIcon,
    PopoverContentBodyText,
    PopoverContentBottomButton,
    PopoverContentBottomButtonWrapper,
    PopoverContentBottomDivider,
    PopoverContentBottomDividerWrapper,
    PopoverContentBottomWrapper
 } from "./ConfirmMyDocAccept.style";



function ConfirmDeleteMyDoc({ onClikcConfirmButton, onClickCloseButton }) {

    const handleConfirmButton = () => {
        onClikcConfirmButton();
      };

    const handleCloseButton = () => {
        onClickCloseButton();
    };

    return (
        <>
        {/* <PopoverContainer> */}
          <PopoverContentContainer>
            <PopoverContentCenterIcon style={{marginTop: 17.5}} src={IcConfirmPopupQuestion}/>
            <PopoverContentBodyText>
            사건 자료를 삭제하시겠습니까?
            <br/>
            삭제한 후에는 제출 미완료 영역에서
            <br/>
            다시 업로드 할 수 있어요.
            </PopoverContentBodyText>
            <PopoverContentBottomWrapper style={{marginTop: 14.5}}>
                <PopoverContentBottomButtonWrapper onClick={handleConfirmButton}>
                    <PopoverContentBottomButton>확인</PopoverContentBottomButton>
                </PopoverContentBottomButtonWrapper>
                <PopoverContentBottomDividerWrapper>
                    <PopoverContentBottomDivider />
                </PopoverContentBottomDividerWrapper>
                <PopoverContentBottomButtonWrapper onClick={handleCloseButton}>
                    <PopoverContentBottomButton>취소</PopoverContentBottomButton>
                </PopoverContentBottomButtonWrapper>
            </PopoverContentBottomWrapper>
          </PopoverContentContainer>
        {/* </PopoverContainer> */}
        </>
    );
}

export default ConfirmDeleteMyDoc;