import { dbService } from "fbase";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Divider, List, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
const Title = Typography.Title;

export const getTitleFromRequestType = (type) => {
  if (type === "lend_money") {
    return "대여금 지급명령";
  } else if (type === "lend_certification") {
    return "대여금 내용증명";
  } else if (type === "property_deposit") {
    return "전세금 반환 지급명령";
  } else if (type === "housing_protection") {
    return "임차권 등기명령";
  } else if (type === "housing_seizure") {
    return "부동산 가압류";
  } else if (type === "housing_certification") {
    return "전세금 반환 내용증명";
  } else if (type === "housing_public_conveyance") {
    return "전세금 반환 공시송달";
  } else if (type === "drunk_driving") {
    return "음주운전 양형자료";
  } else if (type === "drug") {
    return "마약사건 양형자료";
  } else if (type === "media_obscenity") {
    return "통매음 양형자료";
  } else if (type === "plaint_insult") {
    return "모욕죄 고소장";
  } else if (type === "noise_certification") {
    return "층간 소음 내용증명";
  } else if (type === "pilates_certification") {
    return "헬스/필라테스 환불 내용증명";
  } else if (type === "pilates_order") {
    return "헬스/필라테스 환불 지급명령";
  } else if (type === "host_cancel_certification") {
    return "부동산 계약해지(임대인) 내용증명";
  } else if (type === "host_charge_certification") {
    return "월세 청구 내용증명";
  } else if (type === "host_notallowed_certification") {
    return "임대차 계약연장 불허 통지내용증명";
  } else if (type === "civil_defendant") {
    return "민사 소송 원고"
  } else if (type === "civil_plaintiff") {
    return "민사 소송 피고"
  } else if (type === "etc_certification") {
    return "민사 소송 피고"
  } else if (type === "common_criminal_drive") {
    return "형사 사건 운전사고"
  } else if (type === "common_criminal_sexual") {
    return "형사 사건 성범죄"
  } else if (type === "common_criminal_represent") {
    return "형사 사건 고소대리"
  } else if (type === "common_criminal_etc") {
    return "형사 사건 기타"
  } else if (type === "golfTennis_certification") {
    return "골프/테니스 환불 내용증명";
  } else if (type === "golfTennis_order") {
    return "골프/테니스 환불 지급명령";
  } else if (type === "inherit_giveup") {
    return "상속포기";
  } else if (type === "inherit_limited") {
    return "한정승인";
  } else if (type === "vacatehouse") {
    return "명도소송";
  } else if (type === "injunction") {
    return "가처분";
  }
  return "기타";
};

const Admin = ({ loggedInUser }) => {
  const { status } = useParams();
  const history = useHistory();
  const [myRequests, setMyRequests] = useState([]);

  useEffect(() => {
    let query = dbService.collection("requests_temp");
    if (status !== "all") {
      query = query.where("status", "==", status);
    }
    query
      .get()
      .then((snapshot) => {
        const requests = snapshot.docs.map((doc) => {
          return {
            requestId: doc.id,
            ...doc.data(),
          };
        });

        setMyRequests([...requests].sort((a, b) => b.createdAt - a.createdAt));
      })
      .catch(() => {
        history.push("/");
      });
  }, []);

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        제출된 요청
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <List
        itemLayout="horizontal"
        dataSource={myRequests}
        renderItem={(request) => (
          <div
            style={{
              backgroundColor: "white",
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "5px",
              paddingBottom: "5px",
              marginBottom: 10,
              borderRadius: 5,
            }}
          >
            <List.Item>
              <List.Item.Meta
                title={
                  <Link to={"/admin/request/" + request.requestId}>
                    {getTitleFromRequestType(request.serviceType)}
                  </Link>
                }
                description={new Date(request.createdAt).toLocaleDateString(
                  "ko-KR"
                )}
              />
              <div style={{ paddingRight: 5 }}>
                <Link to={"/admin/user/" + request.userId}>
                  <UserOutlined />
                </Link>
              </div>
              <div>
                {request.status === "submitted"
                  ? "제출완료"
                  : request.status === "replied"
                  ? "답변완료"
                  : request.status === "paid"
                  ? "결제완료"
                  : request.status === "requested"
                  ? "서류요청"
                  : request.status === "processing"
                  ? "요청 처리중"
                  : request.status === "done"
                  ? "처리완료"
                  : "제출완료"}
              </div>
            </List.Item>
          </div>
        )}
      />
    </>
  );
};

export default Admin;
