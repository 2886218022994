import { useHistory } from "react-router-dom";
import IcDriveAlarm from "../../assets/svg/IcDriveAlarm.svg";
import IcDriveHour from "../../assets/svg/IcDriveHour.svg";
import IcDrivePrice from "../../assets/svg/IcDrivePrice.svg";
import IcDriveSearch from "../../assets/svg/IcDriveSearch.svg";
import VacateHouseCerti1 from "../../assets/svg/VacateHouseCerti1.svg";
import VacateHouseCerti2 from "../../assets/svg/VacateHouseCerti2.svg";
import VacateHouseCerti3 from "../../assets/svg/VacateHouseCerti3.svg";
import IcVacateHouse1 from "../../assets/svg/IcVacateHouse1.svg";
import IcVacateHouse2 from "../../assets/svg/IcVacateHouse2.svg";
import IcVacateHouse3 from "../../assets/svg/IcVacateHouse3.svg";


import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { 
  CriminalDriveServiceWrapper,
  CriminalDriveServiceFirstWrapper,
  CriminalDriveServiceTitle,
  CriminalDriveServiceDesc,
  CriminalDriveServiceSubTitle,
  CriminalDriveServiceBorder,
  CriminalDriveServiceSecondWrapper,
  CriminalDriveServiceSecondTitleWrapper,
  CriminalDriveServiceSecondContentWrapper,
  CriminalDriveServiceSecondContentCard,
  CriminalDriveServiceSecondContentImg,
  CriminalDriveServiceSecondContentCardDescWrapper,
  CriminalDriveServiceThirdWrapper,
  CriminalDriveServiceThirdTitleContentWrapper,
  CriminalDriveServiceThirdContentWrapper,
  CriminalDriveServiceThirdContentRow,
  CriminalDriveServiceThirdContentRowDescWrapper,
  CriminalDriveServiceContentSection,
  CriminalDriveServiceSection,
  CriminalDriveServiceDivider,
  CriminalDriveServiceThirdHelpWrapper,
  DetailRightBtn,
  Strong,
  Blue
 } from "./CommonCriminal/CommonVacateHouse.style";
import { removeBrTag } from "routes/Landing";

const VacateHouseServiceDetail = ({loggedInUser}) =>  {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title1 = () => {
    const text = isMobile
      ? "명도소송 · 가처분 사건을\n가장 잘 아는 전문 변호사에게\n법률 서비스를 신청하세요."
      : "명도소송 · 가처분 사건을 가장 잘 아는 전문 변호사에게\n법률 서비스를 신청하세요.";
    return removeBrTag(text);
  };

  const titleDesc1 = () => {
    const text = isMobile
      ? "법대리에서는 명도소송 · 가처분 사건을\n전문적으로 해결한 변호사들에게\n24시간 법률 의견서를 받을 수 있는\n시스템을 구축하고 있습니다. 지금 바로 신청하세요!"
      : "법대리에서는 명도소송 · 가처분 사건을 전문적으로 해결한 변호사들에게 24시간 법률 의견서를 받을 수 있는\n시스템을 구축하고 있습니다. 지금 바로 신청하세요!";
    return removeBrTag(text);    
  }

  const title2 = () => {
    const text = isMobile
      ? "차임 손실로 인한 스트레스!\n법대리가 문제 없이 빠르게\n해결해드릴게요."
      : "차임 손실로 인한 스트레스!\n법대리가 문제 없이 빠르게 해결해드릴게요.";
    return removeBrTag(text);
  };

  const content1 = () => {
    const text = isMobile
      ? "법대리 소속 전문 변호사가 제공하는\n무료 법률 진단서를 받아보세요.\n문서화 된 진단서를 통해 정확한 상황 진단 및\n해결 방법까지 얻을 수 있습니다."
      : "법대리 소속 전문 변호사가 제공하는 무료 법률 진단서를 받아보세요.\n문서화 된 진단서를 통해 정확한 상황 진단 및 해결 방법까지 얻을 수 있습니다.";
    return removeBrTag(text);
  };

  const content2 = () => {
    const text = isMobile
      ? "법대리는 불필요한 변호사 선임 유도를 통해\n이윤을 남기지 않으며\n쉽고 빠르게 문제를 해결 할 수 있도록\n도와드립니다.\n전문 변호사에게 사건을 의뢰하세요."
      : "법대리는 불필요한 변호사 선임 유도를 통해 이윤을 남기지 않으며\n쉽고 빠르게 문제를 해결 할 수 있도록 도와드립니다.\n전문 변호사에게 사건을 의뢰하세요.";
    return removeBrTag(text);
  };
  
  const content3 = () => {
    const text = isMobile
      ? "특정 법인에서 한정된 변호사 풀이 아닌\n각 사건에 특성화된 전문 변호사를\n선임할 수 있습니다.\n사건 해결 경험이 풍부한 전문 변호사의\n법률 서비스 및 각종 지원을 받을 수 있습니다."
      : "특정 법인에서 한정된 변호사 풀이 아닌\n각 사건에 특성화된 전문 변호사를 선임할 수 있습니다.\n사건 해결 경험이 풍부한 전문 변호사의 법률 서비스 및 각종 지원을 받을 수 있습니다.";
    return removeBrTag(text);
  };

  const content4 = () => {
    const text = isMobile
      ? "법대리 사이트에서 사건 현황을 실시간으로\n확인가능하며\n사건 프로세스 별로 알림톡을 전송하여\n수시로 확인하지 않아도 됩니다."
      : "법대리 사이트에서 사건 현황을 실시간으로 확인가능하며\n사건 프로세스 별로 알림톡을 전송하여 수시로 확인하지 않아도 됩니다.";
    return removeBrTag(text);
  };

  return (
      <CriminalDriveServiceWrapper>
        <CriminalDriveServiceFirstWrapper>
          <CriminalDriveServiceTitle style={{paddingTop:"6.25rem"}}>
          {title1()}
          </CriminalDriveServiceTitle>
          <CriminalDriveServiceDesc>
          {titleDesc1()}
          </CriminalDriveServiceDesc>
        </CriminalDriveServiceFirstWrapper>
        <CriminalDriveServiceBorder />
        <CriminalDriveServiceSecondWrapper>
          <CriminalDriveServiceSecondTitleWrapper>
            <CriminalDriveServiceTitle>
            {title2()}
            </CriminalDriveServiceTitle>
          </CriminalDriveServiceSecondTitleWrapper>
          <CriminalDriveServiceSecondContentWrapper>
            <CriminalDriveServiceSecondContentCard>
              <CriminalDriveServiceSecondContentImg src={IcVacateHouse1} alt=""/>
              <CriminalDriveServiceSecondContentCardDescWrapper>
                <CriminalDriveServiceSubTitle>
                점유이전금지 가처분
                </CriminalDriveServiceSubTitle>
                <CriminalDriveServiceDesc>
                  {isMobile?
                  <>
                  <Strong>· 법적으로 어떤 의미인가요?</Strong><br/>
                  임차인이 타인에게 점유를 이전하지 못하도록<br/>법원에 신청하는 보전처분입니다.<br/>
                  <Strong>· 어느 상황일 때 신청하나요?</Strong><br/>
                  명도소송 진행 중에 점유자가 변경될 경우,<br/>
                  <Blue>승소하여도 강제집행이 불가능할 수 있습니다.</Blue><br/>
                  이와 같은 상황을 방지하기 위해서<br/>
                  ‘부동산점유이전가처분’ 신청을 함께<br/>진행하는 것이 좋으며,
                  사실상 명도 소송에<br/>필수적인 절차로 간주되고 있습니다.<br/>
                  <Strong>· 언제 신청하나요?</Strong><br/>
                  점유이전금지 가처분은 일반적으로<br/>
                  명도소송과 동시에 또는 명도소송 이전에 진행<br/>합니다.<br/>
                  </>:
                  <>
                  <Strong>· 법적으로 어떤 의미인가요?</Strong><br/>
                  임차인이 타인에게 점유를 이전하지 못하도록 법원에 신청하는 보전처분입니다.<br/>
                  <Strong>· 어느 상황일 때 신청하나요?</Strong><br/>
                  명도소송 진행 중에 점유자가 변경될 경우, <Blue>승소하여도<br/> 
                  강제집행이 불가능할 수 있습니다.</Blue> 이와 같은 상황을 방지하기 위해서<br/>
                  ‘부동산점유이전가처분’ 신청을 함께 진행하는 것이 좋으며,<br/>
                  사실상 명도 소송에 필수적인 절차로 간주되고 있습니다.<br/>
                  <Strong>· 언제 신청하나요?</Strong><br/>
                  점유이전금지 가처분은 일반적으로<br/>
                  명도소송과 동시에 또는 명도소송 이전에 진행합니다.<br/>
                  </>}
                  
                <Link to="/housing/host/injunction">
                <DetailRightBtn>가처분 신청하기</DetailRightBtn>
              </Link>
                </CriminalDriveServiceDesc>
              </CriminalDriveServiceSecondContentCardDescWrapper>
            </CriminalDriveServiceSecondContentCard>
            <CriminalDriveServiceSecondContentCard>
              <CriminalDriveServiceSecondContentImg src={IcVacateHouse2} alt=""/>
              <CriminalDriveServiceSecondContentCardDescWrapper>
                <CriminalDriveServiceSubTitle>
                명도 소송
                </CriminalDriveServiceSubTitle>
                <CriminalDriveServiceDesc>
                {isMobile?
                  <>
                  <Strong>· 법적으로 어떤 의미인가요?</Strong><br/>
                  부동산을 점유할 권리가 없는 자가<br/>
                  부동산을 비워주지 않고, 계속 점유하고 있을 때<br/>
                  제기하는 민사소송입니다.<br/>
                  <Strong>· 어느 상황일 때 신청하나요?</Strong><br/>
                  1) 임대차계약 종료 후 임차인이 부동산을<br/>
                  비워주지 않는 경우<br/>
                  2) 임차인이 2회 이상 계속해서 월세를<br/>
                  밀리는 경우<br/>
                  3) 경매 낙찰 후 해당 경매지 점유자를<br/>
                  내보내야 하는 경우<br/>
                  <Strong>· 언제 신청하나요?</Strong><br/>
                  월세가 연체되어 보증금을 다 소진하고 난 후에<br/>
                  명도소송을 시작하는 경우, 소송이 진행되는<br/>
                  <Blue>5-12개월간 추가적인 차임 손실의 경제적인<br/>
                  피해</Blue>를 입게 되므로 최대한 빠르게 명도소송을<br/>
                  진행하는 것이 좋습니다.<br/>
                  </>:
                  <>
                  <Strong>· 법적으로 어떤 의미인가요?</Strong><br/>
                  부동산을 점유할 권리가 없는 자가 부동산을 비워주지 않고, 계속 점유하고 있을 때<br/>
                  제기하는 민사소송입니다.<br/>
                  <Strong>· 어느 상황일 때 신청하나요?</Strong><br/>
                  1) 임대차계약 종료 후 임차인이 부동산을 비워주지 않는 경우<br/>
                  2) 임차인이 2회 이상 계속해서 월세를 밀리는 경우<br/>
                  3) 경매 낙찰 후 해당 경매지 점유자를 내보내야 하는 경우<br/>
                  <Strong>· 언제 신청하나요?</Strong><br/>
                  월세가 연체되어 보증금을 다 소진하고 난 후에 명도소송을 시작하는 경우,<br/>
                  소송이 진행되는 <Blue>5-12개월간 추가적인 차임 손실의 경제적인 피해</Blue>를 입게 되므로<br/>
                  최대한 빠르게 명도소송을 진행하는 것이 좋습니다.<br/>
                  </>}
                <Link to="/housing/host/vacatehouse">
                  <DetailRightBtn>명도소송 신청하기</DetailRightBtn>
                </Link>
                </CriminalDriveServiceDesc>
              </CriminalDriveServiceSecondContentCardDescWrapper>
            </CriminalDriveServiceSecondContentCard>
            <CriminalDriveServiceSecondContentCard>
              <CriminalDriveServiceSecondContentImg src={IcVacateHouse3} alt=""/>
              <CriminalDriveServiceSecondContentCardDescWrapper>
                <CriminalDriveServiceSubTitle>
                {isMobile? <>임대차 내용증명 (계약연장불허<br/> · 계약해지 · 월세미납청구)</>:<>임대차 내용증명 (계약연장불허 · 계약해지 · 월세미납청구)</>}
                </CriminalDriveServiceSubTitle>
                <CriminalDriveServiceDesc>
                {isMobile?
                  <>
                  <Strong>· 법적으로 어떤 의미인가요?</Strong><br/>
                  명도소송 즉, 명도를 구하는 소장을<br/>
                  법원에 제출할 경우, 계약해지가 되었음을<br/>
                  입증하여야 합니다.<br/>
                  계약해지 통보는 다른 방법도 가능하지만,<br/>
                  내용증명의 도달이 가장 확실합니다.<br/>
                  <Strong>· 어느 상황일 때 신청하나요?</Strong><br/>
                  문자나 카카오톡을 통해서 전달 할 수도 있으나,<br/>
                  확실히 읽었는지 의문이 드신다면<br/>
                  내용증명을 발송하시는 것이 가장 확실한<br/>
                  방법일 것입니다.<br/>
                  <Strong>· 차인이 내용증명을 받지 않는다면?</Strong><br/>
                  임차인이 주소에 살지 않거나 고의적으로<br/>
                  내용증명을 수령하지 않는 경우<br/>
                  계속 송부하여도 도달하지 않아서 고생하는<br/>
                  임대인들도 종종 보게 됩니다.<br/>
                  내용증명으로 인해 시간과 노력을<br/>
                  많이 소요하지 마시고,<br/>
                  지체없이 소송 진행을 시작하시기 바랍니다.<br/>
                  </>:
                  <>
                  <Strong>· 법적으로 어떤 의미인가요?</Strong><br/>
                  명도소송 즉, 명도를 구하는 소장을 법원에 제출할 경우, 계약해지가 되었음을<br/>
                  입증하여야 합니다. 계약해지 통보는 다른 방법도 가능하지만,<br/>
                  내용증명의 도달이 가장 확실합니다.<br/>
                  <Strong>· 어느 상황일 때 신청하나요?</Strong><br/>
                  문자나 카카오톡을 통해서 전달 할 수도 있으나, 확실히 읽었는지 의문이 드신다면<br/>
                  내용증명을 발송하시는 것이 가장 확실한 방법일 것입니다.<br/>
                  <Strong>· 차인이 내용증명을 받지 않는다면?</Strong><br/>
                  임차인이 주소에 살지 않거나 고의적으로 내용증명을 수령하지 않는 경우<br/>
                  계속 송부하여도 도달하지 않아서 고생하는 임대인들도 종종 보게 됩니다.<br/>
                  내용증명으로 인해 시간과 노력을 많이 소요하지 마시고,<br/>
                  지체없이 소송 진행을 시작하시기 바랍니다.<br/>
                  </>}
                </CriminalDriveServiceDesc>
              </CriminalDriveServiceSecondContentCardDescWrapper>
            </CriminalDriveServiceSecondContentCard>
          </CriminalDriveServiceSecondContentWrapper>
        </CriminalDriveServiceSecondWrapper>
        <CriminalDriveServiceThirdHelpWrapper>
          <CriminalDriveServiceSubTitle>
          종류별 임대차 내용증명 신청하기
          </CriminalDriveServiceSubTitle>
          <CriminalDriveServiceContentSection>
            <CriminalDriveServiceSection>
              <CriminalDriveServiceSecondContentImg src={VacateHouseCerti1}/>
              <CriminalDriveServiceDesc>
                임차인의 계약 연장을<br/>거절하고 싶을 때
              </CriminalDriveServiceDesc>
              <Link to="/housing/host/notallowedcertification">
                <DetailRightBtn>계약연장불허</DetailRightBtn>
              </Link>
            </CriminalDriveServiceSection>
            {!isMobile?<CriminalDriveServiceDivider/>:<></>}
            <CriminalDriveServiceSection>
              <CriminalDriveServiceSecondContentImg src={VacateHouseCerti2}/>
              <CriminalDriveServiceDesc>
              임차인과 계약을<br/>해지하고 싶을 때
              </CriminalDriveServiceDesc>
              <Link to="/housing/host/cancelcertification">
                <DetailRightBtn>계약해지</DetailRightBtn>
              </Link>
            </CriminalDriveServiceSection>
            {!isMobile?<CriminalDriveServiceDivider/>:<></>}
            <CriminalDriveServiceSection>
              <CriminalDriveServiceSecondContentImg src={VacateHouseCerti3}/>
                <CriminalDriveServiceDesc>
                임차인이 월세를<br/>미납했을 때
                </CriminalDriveServiceDesc>
                <Link to="/housing/host/chargecertification">
                  <DetailRightBtn>월세미납청구</DetailRightBtn>
                </Link>
            </CriminalDriveServiceSection>
          </CriminalDriveServiceContentSection>
         
        </CriminalDriveServiceThirdHelpWrapper>
        <CriminalDriveServiceBorder />
        <CriminalDriveServiceThirdWrapper>
          <CriminalDriveServiceThirdTitleContentWrapper>
            <CriminalDriveServiceTitle>
              {isMobile?
              (<>왜 법대리에 사건 의뢰를<br/>맡겨야 할까요?</>)
              :"왜 법대리에 사건 의뢰를 맡겨야 할까요?"}
              
          </CriminalDriveServiceTitle>
          <CriminalDriveServiceThirdContentWrapper>
            <CriminalDriveServiceThirdContentRow>
                <CriminalDriveServiceSecondContentImg src={IcDriveSearch}/>
                <CriminalDriveServiceThirdContentRowDescWrapper>
                  <CriminalDriveServiceSubTitle>
                  내 상황에 딱 맞는 무료 법률 진단
                  </CriminalDriveServiceSubTitle>
                  <CriminalDriveServiceDesc>
                  {content1()}
                  </CriminalDriveServiceDesc>
                </CriminalDriveServiceThirdContentRowDescWrapper>
              </CriminalDriveServiceThirdContentRow>
              <CriminalDriveServiceThirdContentRow>
                <CriminalDriveServiceSecondContentImg src={IcDrivePrice}/>
                <CriminalDriveServiceThirdContentRowDescWrapper>
                  <CriminalDriveServiceSubTitle>
                  거품 없는 최소비용으로 변호사 선임
                  </CriminalDriveServiceSubTitle>
                  <CriminalDriveServiceDesc>
                  {content2()}
                  </CriminalDriveServiceDesc>
                </CriminalDriveServiceThirdContentRowDescWrapper>
              </CriminalDriveServiceThirdContentRow>
              <CriminalDriveServiceThirdContentRow>
                <CriminalDriveServiceSecondContentImg src={IcDriveHour}/>
                <CriminalDriveServiceThirdContentRowDescWrapper>
                  <CriminalDriveServiceSubTitle>
                  사건 분야별 전문 고경력 변호사 24시간 선임 가능
                  </CriminalDriveServiceSubTitle>
                  <CriminalDriveServiceDesc>
                  {content3()}
                  </CriminalDriveServiceDesc>
                </CriminalDriveServiceThirdContentRowDescWrapper>
              </CriminalDriveServiceThirdContentRow>
              <CriminalDriveServiceThirdContentRow>
                <CriminalDriveServiceSecondContentImg src={IcDriveAlarm}/>
                <CriminalDriveServiceThirdContentRowDescWrapper>
                  <CriminalDriveServiceSubTitle>
                  알림톡으로 정확한 일정 안내
                  </CriminalDriveServiceSubTitle>
                  <CriminalDriveServiceDesc>
                  {content4()}
                  </CriminalDriveServiceDesc>
                </CriminalDriveServiceThirdContentRowDescWrapper>
              </CriminalDriveServiceThirdContentRow>
            </CriminalDriveServiceThirdContentWrapper>
          </CriminalDriveServiceThirdTitleContentWrapper>
        </CriminalDriveServiceThirdWrapper>
      </CriminalDriveServiceWrapper>
  )
}

export default VacateHouseServiceDetail;