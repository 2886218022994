import LendMoneyRequest from "components/request/LendMoneyRequest";
import LendCertificationRequest from "components/request/LendCertificationRequest";
import { dbService } from "fbase";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { message } from "antd";
import RequestAdmin from "./RequestAdmin";
import OrderRequest from "./housing/OrderRequest";
import ProtectionRequest from "./housing/ProtectionRequest";
import ETCCertificationRequest from "./etc/CertificationRequest";
import HousingCertificationRequest from "./housing/CertificationRequest";
import RequestCommon from "./RequestCommon";
import DrunkDrivingRequest from "./crime/DrunkDrivingRequest";
import DrugRequest from "./crime/DrugRequest";
import MediaObscenityRequest from "./crime/MediaObscenityRequest";
import ComplaintRequest from "./sexual_crime/ComplaintRequest";
import HousingSeizureRequest from "./housing/SeizureRequest";
import HousingPublicConveyanceRequest from "./housing/PublicConveyanceRequest";
import NoiseCertificationRequest from "components/request/noise/CertificationRequest";
import PilatesCertificationRequest from "components/request/pilates/CertificationRequest";
import PilatesOrderRequest from "components/request/pilates/OrderRequest";
import HostCancelCertificationRequest from "components/request/housing/HostCancelCertificationRequest";
import HostChargeCertificationRequest from "components/request/housing/HostChargeCertificationRequest";
import HostNotallowedCertificationRequest from "components/request/housing/HostNotallowedCertificationRequest";
import CivilDefendantRequest from "./civil/CivilDefendantRequest";
import CivilPlaintiffRequest from "./civil/CivilPlaintiffRequest";
import InheritGiveupRequest from "./inherit/InheritGiveupRequest"
import InheritLimitedRequest from "./inherit/InheritLimitedRequest"

import VacatehouseRequest from "./vacatehouse/VacatehouseRequest"
import InjunctionRequest from "./vacatehouse/InjunctionRequest"

import InsultRequest from "./plaint/InsultRequest";
import CommonCriminalDriveRequest from "./criminal/CommonCriminalDriveRequest";
import CommonCriminalSexualRequest from "./criminal/CommonCriminalSexualRequest";
import CommonCriminalRepresentRequest from "./criminal/CommonCriminalRepresentRequest";
import CommonCriminalEtcRequest from "./criminal/CommonCriminalEtcRequest";
import CommonEtcRequest from "./etc/CommonEtcRequest";
import GolfTennisCertificationRequest from "./golfTennis/CertificationRequest";
import GolfTennisOrderRequest from "./golfTennis/OrderRequest";
import To3Request from "./to3/To3Request";
import CommonOrderRequest from "./new/CommonOrderRequest";
import CommonCertificationRequest from "./new/CommonCertificationRequest";
import CommonComplaintRequest from './new/ComplaintRequest';
import ContractRequest from './new/ContractRequest';
import AgreementRequest from './new/AgreementRequest';
import OpinionRequest from './new/OpinionRequest';

const Request = ({ loggedInUser, isAdminRequest }) => {
  const { requestId } = useParams();
  const [requestData, setRequestData] = useState();
  const [serviceData, setServiceData] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const history = useHistory();

  useEffect(() => {
    dbService
      .collection("requests_temp")
      .where("__name__", "==", requestId)
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          const requestData = snapshot.docs[0].data();
          setRequestData({ id: requestId, ...requestData });
          setServiceData(requestData.service_data);          
        } else {
          message.error("잘못된 요청입니다.");
          history.push("/");
        }
      })
      .catch((error) => {
        message.error("잘못된 요청입니다.");
        history.push("/");
      });

    if (isAdminRequest) {
      dbService
        .collection("admins")
        .doc(loggedInUser.uid)
        .get()
        .then(() => {
          setIsAdmin(true);
        })
        .catch(() => {
          setIsAdmin(false);
        });
    }
  }, []);
  
  return (
    <>
      {serviceData && requestData ? (
        requestData.serviceType === "lend_money" ? (
          <>
            <LendMoneyRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) :
        requestData.serviceType === "lend_certification" ? (
          <>
            <LendCertificationRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) :
        requestData.serviceType === "noise_certification" ? (
          <>
            <NoiseCertificationRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) :
        requestData.serviceType === "pilates_certification" ? (
          <>
            <PilatesCertificationRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "pilates_order" ? (
          <>
            <PilatesOrderRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) :
        requestData.serviceType === "host_cancel_certification" ? (
          <>
            <HostCancelCertificationRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) :
        requestData.serviceType === "host_charge_certification" ? (
          <>
            <HostChargeCertificationRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) :
        requestData.serviceType === "host_notallowed_certification" ? (
          <>
            <HostNotallowedCertificationRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "property_deposit" ? (
          <>
            <OrderRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "housing_protection" ? (
          <>
            <ProtectionRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "housing_certification" ? (
          <>
            <HousingCertificationRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "etc_certification" ? (
          <>
            <ETCCertificationRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "housing_seizure" ? (
          <>
            <HousingSeizureRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "housing_public_conveyance" ? (
          <>
            <HousingPublicConveyanceRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "drunk_driving" ? (
          <>
            <DrunkDrivingRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "drug" ? (
          <>
            <DrugRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "media_obscenity" ? (
          <>
            <MediaObscenityRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "sexual_crime_complaint" ? (
          <>
            <ComplaintRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "civil_defendant" ? (
            <>
              <CivilDefendantRequest
                serviceData={serviceData}
                requestData={requestData}
              />
              <RequestCommon requestData={requestData} isAdmin={isAdmin} />
              {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
            </>
        ) : requestData.serviceType === "civil_plaintiff" ? (
            <>
              <CivilPlaintiffRequest
                serviceData={serviceData}
                requestData={requestData}
              />
              <RequestCommon requestData={requestData} isAdmin={isAdmin} />
              {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
            </>
        ) : requestData.serviceType === "plaint_insult" ? (
          <>
            <InsultRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "common_criminal_drive" ? (
          <>
            <CommonCriminalDriveRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "common_criminal_sexual" ? (
          <>
            <CommonCriminalSexualRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "common_criminal_represent" ? (
          <>
            <CommonCriminalRepresentRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "common_criminal_etc" ? (
          <>
            <CommonCriminalEtcRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "common_etc" ? (
          <>
            <CommonEtcRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "golfTennis_certification" ? (
          <>
            <GolfTennisCertificationRequest
              serviceData={serviceData}
              requestData={requestData}
            />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "golfTennis_order" ? (
          <>
            <GolfTennisOrderRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "inherit_giveup" ? (
          <>
            <InheritGiveupRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ) : requestData.serviceType === "vacatehouse" ? (
          <>
            <VacatehouseRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "injunction" ? (
          <>
            <InjunctionRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "inherit_limited" ? (
          <>
            <InheritLimitedRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "3o3_pilates" ? (
          <>
            <To3Request serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "3o3_noise" ? (
          <>
            <To3Request serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "3o3_loan" ? (
          <>
            <To3Request serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "3o3_golfTennis" ? (
          <>
            <To3Request serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "3o3_etc" ? (
          <>
            <To3Request serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "3o3_estate" ? (
          <>
            <To3Request serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "3o3_pay" ? (
          <>
            <To3Request serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "common_order" ? (
          <>
            <CommonOrderRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "common_certification" ? (
          <>
            <CommonCertificationRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "complaint" ? (
          <>
            <CommonComplaintRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "contract" ? (
          <>
            <ContractRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "agreement" ? (
          <>
            <AgreementRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): requestData.serviceType === "opinion" ? (
          <>
            <OpinionRequest serviceData={serviceData} requestData={requestData} />
            <RequestCommon requestData={requestData} isAdmin={isAdmin} />
            {isAdmin ? <RequestAdmin requestData={requestData} /> : null}
          </>
        ): null
      ) : null}
    </>
  );
};

export default Request;
