import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Row,
  Col,
  Modal,
  Divider,
  Input,
  message,
  Radio,
  Popover,
} from "antd";
import { storageService } from "fbase";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import TermsOfService from "components/form/commons/TermsOfService";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper2,
} from "../../Form.style";
const { TextArea } = Input;

const Step4 = ({ data, setData, onPrev, onNext, itemLayout }) => {
  const [fileList, setFileList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewTitle, setPreviewTitle] = useState(false);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const key = "updatable";

  useEffect(() => {
    if (data.attachments) {
      message.success({
        content: "제출 완료!",
        key,
        duration: 2,
      });
      onNext();
    }
  }, [data]);

  const onFinish = async (values) => {
    setSubmitting(true);
    message.loading({
      content: "제출 중입니다.",
      key,
      duration: 0,
    });

    let attachments = [];
    await Promise.all(
      fileList.map(async (file) => {
        const fileRef = storageService
          .ref()
          .child(`property_deposit/images/${uuidv4()}`);
        const response = await fileRef.put(file.originFileObj);
        const imgUrl = await response.ref.getDownloadURL();
        attachments.push(imgUrl);
      })
    ).then(() => {
      setData({ attachments: attachments, ...values });
    });
  };

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onChange = ({ fileList }) => {
    setFileList(fileList.filter((file) => file.status !== "error"));
  };

  const onRemove = async (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };

  const onCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        기타 및 상세 내용
      </Title>
      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          기타 사항 및 변호사 검토시 <br />
          참고해야할 내용이 있다면 아래에 작성 및 파일을 첨부하세요
        </SubText>
      </div>
      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper2>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          style={{
            marginTop: 10,
            marginBottom: 20,
          }}
          requiredMark={false}
        >
          <Form.Item
            label="전세보증보험 가입여부"
            name={["insurance", "registYn"]}
            rules={[
              {
                required: true,
                message: "전세보증보험 가입여부를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="장기수선충당금 반환요구"
            name={["longTermRepairReserve", "requestYn"]}
            rules={[
              {
                required: true,
                message: "장기수선충당금 반환요구 여부를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="보증금을 돌려받지 못해 발생한 손해 여부"
            name={["lossDamage", "exist"]}
            rules={[{ required: true, message: "손해 여부를 선택해주세요." }]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                연체 이자 청구
                <Popover
                  content={
                    <div>
                      연체이자를 청구할 경우 지급명령 결정이 된 이후부터
                      청구금액에 연 12%의 지연이자가 붙습니다. <br />
                      (지급명령 결정 이전의 이자와 별도)
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["overDueInterests", "requestYn"]}
            rules={[
              { required: true, message: "연체이자 청구 유무를 선택해주세요." },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                보증금 지급 기록 유무
                <Popover
                  content={
                    <div>
                      집주인에게 보증금을 지급했음을
                      <br />
                      증명할 수 있는 자료가 있는지 여부를 확인해주세요
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["depositHistory", "exist"]}
            rules={[
              {
                required: true,
                message: "보증금 지급 기록 유무를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="집주인의 주소를 알고 있나요?"
            name={["lendlord", "addressYn"]}
            rules={[
              {
                required: true,
                message: "집주인 주소 유무를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="집주인의 주민등록번호를 알고 있나요?"
            name={["lendlord", "ssnYn"]}
            rules={[
              {
                required: true,
                message: "집주인 주민등록번호 유무를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                기타내용
                <Popover
                  content={
                    <div>
                      검토 시 필요한 기타 사항을 500자 내로 작성해주세요
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={"description"}
          >
            <TextArea rows={10} maxLength={500} />
          </Form.Item>

          <Form.Item
            label="연락처"
            name={["contact"]}
            rules={[
              {
                required: true,
                message: "연락처를 입력해주세요",
              },
            ]}
          >
            <Input placeholder="검토 결과 알림을 받으실 연락처를 입력해주세요" />
          </Form.Item>
          <Form.Item label="프로모션 코드" name={["promotionCode"]}>
            <Input />
          </Form.Item>
          <TermsOfService />
          {/* <div>
            <label>
              <>
                첨부파일
                <Popover
                  content={
                    <div>
                      집주인에게 보증금을 지급했음을 증명할 수 있는 자료
                      <br />
                      (임대차 계약서 사본, 임대차 보증금 입금내역의 계좌 사본,
                      <br />
                      임대차 보증금 이체 확인증, 계약 해지 증명 서류 등)를 전부
                      첨부하세요
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            </label>
            <div style={{ marginTop: 5 }}>
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                onPreview={onPreview}
                onChange={onChange}
                onRemove={onRemove}
                fileList={fileList}
                maxCount={10}
              >
                <Button icon={<UploadOutlined />}>증거자료 첨부</Button>
              </Upload>
            </div>
          </div> */}
          <Row justify="space-between" style={{ marginTop: 20 }}>
            <Col span={6}>
              <Button
                type="primary"
                onClick={onPrev}
                style={{ width: "100%", maxWidth: "100px" }}
              >
                이전
              </Button>
            </Col>
            <Col span={6} offset={10} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                {submitting ? "제출 중" : "제출"}
              </Button>
            </Col>
          </Row>
        </Form>
      </CaseDetailWrapper2>

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={onCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step4;
