export const ModalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 10,
    },
    content: {
      background: "#ffffff",
      width: '700px',
      height: '815px',
      overflow: "auto",
      margin: 'auto', // 가운데 정렬을 위한 margin
      display: 'flex',
      WebkitOverflowScrolling: "touch",
      outline: "none",
      zIndex: 10,
    },
  };

  export const OptionModalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '138px',
      height: '104px',
      border: '1px solid #ccc',
      background: '#fff',
      padding: '16px',
      overflow: "auto",
      margin: 'auto', // 가운데 정렬을 위한 margin
      display: 'flex',
      WebkitOverflowScrolling: "touch",
      outline: "none",
      zIndex: 10,
    },
  };

  export const LawyerOpinionModalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 10,
    },
    content: {
      background: "#E7EAEF",
      width: '700px',
      height: '1200px',
      overflow: "auto",
      margin: 'auto', // 가운데 정렬을 위한 margin
      display: 'flex',
      WebkitOverflowScrolling: "touch",
      outline: "none",
      zIndex: 10,
      borderRadius: "3px"
    },
  };

  export const CaseDocPreviewModalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      background: "#fff",
      width: '700px',
      height: '824px',
      overflow: "auto",
      margin: 'auto', // 가운데 정렬을 위한 margin
      display: 'flex',
      WebkitOverflowScrolling: "touch",
      outline: "none",
      zIndex: 10,
      borderRadius: "3px"
    },
  };

  export const CaseDocSubmitPreviewModalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      background: "#000",
      width: '700px',
      height: '920px',
      overflow: "auto",
      margin: 'auto', // 가운데 정렬을 위한 margin
      display: 'flex',
      WebkitOverflowScrolling: "touch",
      outline: "none",
      zIndex: 10,
      borderRadius: "3px"
    },
  };

  export const ConfrimLawyerDocModalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 10,
    },
    content: {
      background: "#FFF",
      width: '310px',
      height: '190px',
      overflow: "auto",
      margin: 'auto', // 가운데 정렬을 위한 margin
      display: 'flex',
      padding: '0',
      WebkitOverflowScrolling: "touch",
      outline: "none",
      zIndex: 10,
      border: 0,
      borderRadius: "16px"
    },
  };

  export const ConfrimLawyerDocCertificationModalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 10,
    },
    content: {
      background: "#FFF",
      width: '310px',
      height: '280px',
      overflow: "auto",
      margin: 'auto', // 가운데 정렬을 위한 margin
      display: 'flex',
      padding: '0',
      WebkitOverflowScrolling: "touch",
      outline: "none",
      zIndex: 10,
      border: 0,
      borderRadius: "16px"
    },
  };

  export const NotiModalStyle = {
    overlay: {    
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
    content: {
      border: 0,
      position: 'absolute',
      top: '64px', // 원하는 위치로 조정합니다.
      // left: '67%', // 원하는 위치로 조정합니다.
      right: '100px',
      width: '300px',
      height: '100px'
    },
  };