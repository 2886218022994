import { useHistory } from "react-router-dom";

 import IcServiceMain from "assets/svg/Ic3.3Estate.svg";

import {
  Container,
} from "components/Service.style";

import { useEffect } from "react";
import { removeBrTag } from "routes/Landing";
import { EventHeaderContainer, EventHeaderDesc, EventHeaderDescSpan, EventHeaderImg, EventHeaderSection, EventHeaderSubTitle, EventHeaderTitle } from "../EventStyle.style";
import { EventDesc } from "../EventDesc";
import { EventDesc2 } from "../EventDesc2";
import { EventDetailSubmit } from "../EventDetailSubmit";
import { EventDetailFooter } from "../EventDetailFooter";

const EventEstateServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <EventHeaderSection>
        <EventHeaderContainer>
          <EventHeaderImg src={IcServiceMain}/>
          <EventHeaderTitle>
            전세 보증금 반환
          </EventHeaderTitle>
          <EventHeaderSubTitle>
            내용 증명으로 해결하기
          </EventHeaderSubTitle>
          <EventHeaderDesc>
          임대차 계약 종료일에 임대인의 보증금 반환 여부가 불투명한 경우, 세입자가 가장 먼저 취해야 할 행동은 계약해지 통보 및 보증금 반환을 촉구하는 내용증명을 보내는 것 이에요.<br/>
          <EventHeaderDescSpan>내용증명</EventHeaderDescSpan>은 보증금을 돌려받지 못했을 경우 법적 조치를 위한 증빙자료로 사용되며, 보증보험을 가입한 상태라면 보증보험사에 필수적으로 제출을 해야 하는 서류에요.
          </EventHeaderDesc>
        </EventHeaderContainer>
      </EventHeaderSection>
      <EventDesc />
      <EventDesc2 />
      <EventDetailSubmit landingPath="/3o3/estate/certificate"/>
      <EventDetailFooter />
    </Container>
  )
};

export default EventEstateServiceCenter;