import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Popover,
  Radio,
  Row,
} from "antd";
import { useEffect } from "react";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper,
} from "../../Form.style";

const Step2 = ({ data, setData, onPrev, onNext, itemLayout }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log("values:", values);
    setData(values);
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        임대차 계약 종료 정보
      </Title>

      {/* <Popover
          content={
            <div>
              상대에게 돈을 빌려준 내용에 대하여 작성하세요.
              <br />
              여러번에 걸쳐 빌려준 경우 아래의 빌려준 돈 정보
              <br /> 추가하기 버튼을 사용하세요.
            </div>
          }
          placement="bottom"
          trigger="click"
        >
          <QuestionCircleTwoTone />
        </Popover> */}

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          임대차 계약이 종료된 일자와 계약종료 사유를 선택해주세요
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          form={form}
          style={{
            marginTop: 10,
            marginBottom: 20,
            width:"100%",
            textAlign:"left"
          }}
          requiredMark={false}
        >
          <Form.Item
            label={<>계약 종료 일자</>}
            name={["termination", "date"]}
            rules={[
              {
                required: true,
                message: "날짜를 선택해주세요",
              },
            ]}
            // style={{ width: "calc(85% - 8px)" }}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="날짜를 선택하세요"
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                계약 종료 사유
                <Popover
                  content={
                    <div>
                      계약 해지의 경우, 맨 마지막 페이지의 상세내용에
                      <br /> 해지 사유를 작성해주세요
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["termination", "reason"]}
            rules={[
              { required: true, message: "계약 종료 사유를 선택해주세요." },
            ]}
            // style={{ width: "calc(85% - 8px)" }}
          >
            <Radio.Group>
              <Radio.Button value="expire">기간만료</Radio.Button>
              <Radio.Button value="cancel">계약해지</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Row justify="space-between">
            <Col span={6}>
              <Button
                type="primary"
                onClick={onPrev}
                style={{ width: "100%", maxWidth: "100px" }}
              >
                이전
              </Button>
            </Col>
            <Col span={6} offset={10} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                다음
              </Button>
            </Col>
          </Row>
        </Form>
        </CaseDetailWrapper>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step2;
