import { useHistory } from "react-router-dom";
import IcInheritContent1 from "../../assets/svg/IcInheritContent1.svg";
import IcInheritContent2 from "../../assets/svg/IcInheritContent2.svg";
import IcDriveAlarm from "../../assets/svg/IcDriveAlarm.svg";
import IcArrowDownGray from "../../assets/svg/IcArrowDownGray.svg";
import IcArrowRightGray from "../../assets/svg/IcArrowRightGray.svg";
import IcArrowLeftGray from "../../assets/svg/IcArrowLeftGray.svg";
import IcDriveHour from "../../assets/svg/IcDriveHour.svg";
import IcDrivePrice from "../../assets/svg/IcDrivePrice.svg";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { 
  CriminalDriveServiceWrapper,
  CriminalDriveServiceTitle,
  CriminalDriveServiceDesc,
  CriminalDriveServiceSubTitle,
  CriminalDriveServiceBorder,
  CriminalDriveServiceSecondWrapper,
  CriminalDriveServiceSecondTitleWrapper,
  CriminalDriveServiceSecondContentWrapper,
  CriminalDriveServiceSecondContentCard,
  CriminalDriveServiceSecondContentImg,
  CriminalDriveServiceSecondContentCardDescWrapper,
  CriminalDriveServiceThirdWrapper,
  CriminalDriveServiceThirdTitleContentWrapper,
  CriminalDriveServiceThirdContentWrapper,
  CriminalDriveServiceThirdContentRow,
  CriminalDriveServiceThirdContentRowDescWrapper,
  ProcessDescibeWrapper,
  ProcessDescibeTitleWrapper,
  ProcessDescibeTitleText,
  ProcessDescibeTitleDesc,
  ProcessDescibeCardWrapper,
  ProcessDescibeCardRowWrapper,
  ProcessDescibeCard,
  ProcessDescibeCardBorder,
  ProcessDescibeCardDesc,
  ProcessDescibeCardTitleText,
  ProcessDescibeCardTitleWrapper,
  ProcessDescibeCardInterRowWrapper,
  ProcessDescibeCardInterRowEachWrapper,
  ProcessDescibeCardInterRowIcon,
  ProcessDescibeCardInterRowMobileWrapper,
  ProcessDescibeCardArrowIconMobile,
  DetailRightBtn,
  Strong,
  Blue
 } from "./CommonCriminal/CommonInherit.style";
import { removeBrTag } from "routes/Landing";

const InheritServiceDetail = ({loggedInUser}) =>  {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;


function convertToJSX(text) {
  const elements = [];
  let buffer = '';
  let isStrong = false;
  let isBlue = false;

  for (let i = 0; i < text.length; i++) {
    if (text.substr(i, 8) === '{strong}') {
      if (buffer) elements.push(buffer);
      buffer = '';
      isStrong = true;
      i += 7; // skip the tag
    } else if (text.substr(i, 9) === '{/strong}') {
      if (buffer) elements.push(<Strong key={i}>{buffer}</Strong>);
      buffer = '';
      isStrong = false;
      i += 8;
    } else if (text.substr(i, 6) === '{blue}') {
      if (buffer) elements.push(buffer);
      buffer = '';
      isBlue = true;
      i += 5;
    } else if (text.substr(i, 7) === '{/blue}') {
      if (buffer) elements.push(<Blue key={i}>{buffer}</Blue>);
      buffer = '';
      isBlue = false;
      i += 6;
    } else {
      buffer += text[i];
    }
  }

  if (buffer) elements.push(buffer);
  return elements;
}

  const title1 = () => {
    const text = isMobile
      ? "음주운전 사건을 가장 잘 아는\n전문 변호사에게\n무료 법률 진단 받고,\n해결법을 확인하세요."
      : "음주운전 사건을 가장 잘 아는 전문 변호사에게\n무료 법률 진단 받고, 해결법을 확인하세요.";
    return removeBrTag(text);
  };

  const titleDesc1 = () => {
    const text = isMobile
      ? "법대리에서는 음주운전 사건을 전문적으로 해결한 변호사들에게서 24시간 법률 의견서를 받을 수 있는 시스템을 구축하고 있습니다. 무료 진단 한 번만으로도 구체적인 해결방안을 곧바로 받을 수 있습니다."
      : "법대리에서는 음주운전 사건을 전문적으로 해결한 변호사들에게서 24시간 법률 의견서를 받을 수 있는 시스템을\n구축하고 있습니다. 무료 진단 한 번만으로도 구체적인 해결방안을 곧바로 받을 수 있습니다.";
    return removeBrTag(text);    
  }

  const desc1 = () => {
    const text = isMobile
      ? "음주운전 사고는 아래 두 가지 분야에서 변호사가 작성한 의견서가 반드시 필요합니다. 개인이 처한 상황을 변호사가 정확하게 분석해서 유리한 양형자료를 제출하는 것이 선처 확률을 높이는데 기여할 수 있습니다. 벌금을 줄이는 것은 물론 감형까지 가능합니다."
      : "음주운전 사고는 아래 두 가지 분야에서 변호사가 작성한 의견서가 반드시 필요합니다.\n개인이 처한 상황을 변호사가 정확하게 분석해서 유리한 양형자료를 제출하는 것이 선처 확률을 높이는데\n기여할 수 있습니다. 벌금을 줄이는 것은 물론 감형까지 가능합니다.";
    return removeBrTag(text);
  }; 

  const desc2 = () => {
    const text = isMobile
      ? "·법적으로 어떤 의미인가요?\n상속포기는 빚과 재산 모두 상속을 받지 않겠다고 선언하는 것입니다.\n고인에게 빚이 많아서 빨리 벗어나고 싶을 때 유용한 방법입니다.\n단, 해당 절차는 후순위 상속인에게 빚이 상속됩니다. "
      : "<Strong>·법적으로 어떤 의미인가요?<Strong>\n상속포기는 빚과 재산 모두 상속을 받지 않겠다고 선언하는 것입니다.\n<Blue고인에게 빚이 많아서 빨리 벗어나고 싶을 때</Blue> 유용한 방법입니다.\n단, 해당 절차는 후순위 상속인에게 빚이 상속됩니다.\n<Strong>·어느 상황일 때 신청하나요?<Strong>\n다른 가족이나 친척이 없고, 나만 빚 상속을 피하면 될 때\n<Strong>·언제 신청하나요?<Strong>\n고인의 사망을 안 날부터 3개월 안에 법원에 신고를 해야 합니다.";
    
    return removeBrTag(text);
  };

  const desc3 = () => {
    const text = isMobile
      ? "행정처분을 받으면 면허취소 및\n정지 처벌이 이루어집니다.\n혈중알콜농도가 0.1% 미만이거나 생계에 있어\n면허가 필수인 경우, 변호사가 직접 작성한\n의견서를 통해 행정심판을 진행하면\n선처를 받을 수 있습니다."
      : "행정처분을 받으면 면허취소 및 정지 처벌이 이루어집니다.\n혈중알콜농도가 0.1% 미만이거나 생계에 있어\n면허가 필수인 경우, 변호사가 직접 작성한 의견서를 통해\n행정심판을 진행하면 선처를 받을 수 있습니다.";
    return removeBrTag(text);
  };

  const title2 = () => {
    const text = isMobile
      ? "빚 상속 걱정 마세요!\n법대리가 문제 없이\n빠르게 해결해드릴게요."
      : "빚 상속 걱정 마세요!\n법대리가 문제 없이 빠르게 해결해드릴게요.";
    return removeBrTag(text);
  };

  const content1 = () => {
    const text = isMobile
      ? "법대리 소속 전문 변호사가 제공하는 무료 법률 진단서를 받아보세요. 문서화 된 진단서를 통해 정확한 상황 진단 및 해결 방법까지 얻을 수 있습니다."
      : "법대리 소속 전문 변호사가 제공하는 무료 법률 진단서를 받아보세요.\n문서화 된 진단서를 통해 정확한 상황 진단 및 해결 방법까지 얻을 수 있습니다.";
    return removeBrTag(text);
  };

  const content2 = () => {
    const text = isMobile
      ? "법대리는 불필요한 변호사 선임 유도를 통해\n이윤을 남기지 않으며\n쉽고 빠르게 문제를 해결 할 수 있도록\n도와드립니다.\n전문 변호사에게 사건을 의뢰하세요."
      : "법대리는 불필요한 변호사 선임 유도를 통해 이윤을 남기지 않으며\n쉽고 빠르게 문제를 해결 할 수 있도록 도와드립니다.\n전문 변호사에게 사건을 의뢰하세요.";
    return removeBrTag(text);
  };
  
  const content3 = () => {
    const text = isMobile
      ? "특정 법인에서 한정된 변호사 풀이 아닌\n각 사건에 특성화된 전문 변호사를\n선임할 수 있습니다.\n사건 해결 경험이 풍부한 전문 변호사의\n법률 서비스 및 각종 지원을 받을 수 있습니다."
      : "특정 법인에서 한정된 변호사 풀이 아닌\n각 사건에 특성화된 전문 변호사를 선임할 수 있습니다.\n사건 해결 경험이 풍부한 전문 변호사의 법률 서비스 및 각종 지원을 받을 수 있습니다.";
    return removeBrTag(text);
  };

  const content4 = () => {
    const text = isMobile
      ? "법대리 사이트에서 사건 현황을 실시간으로\n확인가능하며\n사건 프로세스 별로 알림톡을 전송하여\n수시로 확인하지 않아도 됩니다."
      : "법대리 사이트에서 사건 현황을 실시간으로 확인가능하며\n사건 프로세스 별로 알림톡을 전송하여 수시로 확인하지 않아도 됩니다.";
    return removeBrTag(text);
  };

  const helper1 = () => {
    const text = isMobile
      ? "혼자 진행하다가 실수하지 마세요!\n법대리가 법률문제를 저렴한 가격에\n쉽고 빠르게 해결해드려요."
      : "혼자 진행하다가 실수하지 마세요!\n법대리가 법률문제를 저렴한 가격에 쉽고 빠르게 해결해드려요.";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/crime/drunk_driving");
  }

  return (
      <CriminalDriveServiceWrapper>
        <CriminalDriveServiceBorder />
        <CriminalDriveServiceSecondWrapper>
          <CriminalDriveServiceSecondTitleWrapper>
            <CriminalDriveServiceTitle>
            {title2()}
            </CriminalDriveServiceTitle>
          </CriminalDriveServiceSecondTitleWrapper>
          <CriminalDriveServiceSecondContentWrapper>
            <CriminalDriveServiceSecondContentCard>
              <CriminalDriveServiceSecondContentImg src={IcInheritContent1} alt=""/>
              <CriminalDriveServiceSecondContentCardDescWrapper>
                <CriminalDriveServiceSubTitle>
                상속포기
                </CriminalDriveServiceSubTitle>
                <CriminalDriveServiceDesc>
                  {isMobile?
                  <>
                  <Strong>· 법적으로 어떤 의미인가요?</Strong><br/>
                  상속포기는 빚과 재산 모두 상속을 받지 않겠다고<br/> 선언하는 것입니다.<br/>
                  <Blue>고인에게 빚이 많아서 빨리 벗어나고 싶을 때</Blue><br/> 유용한 방법입니다.<br/>
                  단, 해당 절차는 후순위 상속인에게 빚이 상속됩니다.<br/>
                  <Strong>· 어느 상황일 때 신청하나요?</Strong><br/>
                  다른 가족이나 친척이 없고, 나만 빚 상속을 피하면 될 때<br/>
                  <Strong>· 언제 신청하나요?</Strong><br/>
                    고인의 사망을 안 날부터 3개월 안에<br/> 법원에 신고를 해야 합니다.<br/>
                  </>:
                  <>
                  <Strong>· 법적으로 어떤 의미인가요?</Strong><br/>
                  상속포기는 빚과 재산 모두 상속을 받지 않겠다고 선언하는 것입니다.<br/>
                  <Blue>고인에게 빚이 많아서 빨리 벗어나고 싶을 때</Blue> 유용한 방법입니다.<br/>
                  단, 해당 절차는 후순위 상속인에게 빚이 상속됩니다.<br/>
                  <Strong>· 어느 상황일 때 신청하나요?</Strong><br/>
                  다른 가족이나 친척이 없고, 나만 빚 상속을 피하면 될 때<br/>
                  <Strong>· 언제 신청하나요?</Strong><br/>
                    고인의 사망을 안 날부터 3개월 안에 법원에 신고를 해야 합니다.<br/>
                  </>}
                  
                <Link to="/inherit/giveup">
                <DetailRightBtn>상속포기 신청하기</DetailRightBtn>
              </Link>
                </CriminalDriveServiceDesc>
              </CriminalDriveServiceSecondContentCardDescWrapper>
            </CriminalDriveServiceSecondContentCard>
            <CriminalDriveServiceSecondContentCard>
              <CriminalDriveServiceSecondContentImg src={IcInheritContent2} alt=""/>
              <CriminalDriveServiceSecondContentCardDescWrapper>
                <CriminalDriveServiceSubTitle>
                한정 승인
                </CriminalDriveServiceSubTitle>
                <CriminalDriveServiceDesc>
                {isMobile?
                  <>
                  <Strong>· 법적으로 어떤 의미인가요?</Strong><br/>
                  한정승인은 고인의 재산을 상속은 받지만,<br/>
                   상속받은 재산을 한도로만 <br/>
                  고인의 빚을 갚겠다고 선언하는 것입니다.<br/>
                  <Blue>고인에게 재산은 있는데 빚도 있어서<br/>
                  내가 손해를 볼 수도 있는 상황</Blue>에 적절한 방법입니다.<br/>
                  해당 절차는 후순위 상속인에게 빚이 상속되지 않습니다.<br/>
                  <Strong>· 어느 상황일 때 신청하나요?</Strong><br/>
                  고인의 재산이 더 많은지, 빚이 더 많은지 불확실할 때<br/>
                  <Strong>· 언제 신청하나요?</Strong><br/>
                    고인의 사망을 안 날부터 3개월 안에<br/> 법원에 신고를 해야 합니다.<br/>
                  </>:
                  <>
                  <Strong>· 법적으로 어떤 의미인가요?</Strong><br/>
                  한정승인은 고인의 재산을 상속은 받지만, 상속받은 재산을 한도로만 <br/>
                  고인의 빚을 갚겠다고 선언하는 것입니다. <Blue>고인에게 재산은 있는데 빚도 있어서<br/>
                  내가 손해를 볼 수도 있는 상황</Blue>에 적절한 방법입니다.<br/>
                  해당 절차는 후순위 상속인에게 빚이 상속되지 않습니다.<br/>
                  <Strong>· 어느 상황일 때 신청하나요?</Strong><br/>
                  고인의 재산이 더 많은지, 빚이 더 많은지 불확실할 때<br/>
                  <Strong>· 언제 신청하나요?</Strong><br/>
                    고인의 사망을 안 날부터 3개월 안에 법원에 신고를 해야 합니다.<br/>
                  </>}
                <Link to="/inherit/limited">
                <DetailRightBtn>한정승인 신청하기</DetailRightBtn>
              </Link>
                </CriminalDriveServiceDesc>
              </CriminalDriveServiceSecondContentCardDescWrapper>
            </CriminalDriveServiceSecondContentCard>
          </CriminalDriveServiceSecondContentWrapper>
        </CriminalDriveServiceSecondWrapper>
        <CriminalDriveServiceBorder />
        {/* <CriminalDriveServiceThirdWrapper> */}
        <ProcessDescibeWrapper>
            <ProcessDescibeTitleWrapper>
              <ProcessDescibeTitleText>
              클릭 몇 번으로 간단히 끝내는<br/>법대리 프로세스
              </ProcessDescibeTitleText>
              <ProcessDescibeTitleDesc>
              직접 발로 뛰지 마세요! 모바일에서 해결하세요.<br/>직접 법원에 갈 필요 없이 모든 절차를 변호사가 대신해 드려요.
              </ProcessDescibeTitleDesc>
            </ProcessDescibeTitleWrapper>
            <ProcessDescibeCardWrapper>
              <ProcessDescibeCardRowWrapper>
                <ProcessDescibeCard>
                  <ProcessDescibeCardTitleWrapper>
                    <ProcessDescibeCardTitleText>STEP 1</ProcessDescibeCardTitleText>
                    <ProcessDescibeCardTitleText>법대리에 서비스 신청</ProcessDescibeCardTitleText>
                  </ProcessDescibeCardTitleWrapper>
                  <ProcessDescibeCardBorder />
                  <ProcessDescibeCardDesc>
                      필요한 서류는 법대리가 다 알려드려요
                  </ProcessDescibeCardDesc>
                </ProcessDescibeCard>
                <ProcessDescibeCardInterRowIcon src={IcArrowRightGray}/>
                <ProcessDescibeCardInterRowMobileWrapper>
                  <ProcessDescibeCardArrowIconMobile src={IcArrowDownGray}/>
                </ProcessDescibeCardInterRowMobileWrapper>
                <ProcessDescibeCard>
                  <ProcessDescibeCardTitleWrapper>
                    <ProcessDescibeCardTitleText>STEP 2</ProcessDescibeCardTitleText>
                    <ProcessDescibeCardTitleText>법대리가 신청서 작성 및 제출 (신고)</ProcessDescibeCardTitleText>
                  </ProcessDescibeCardTitleWrapper>
                  <ProcessDescibeCardBorder />
                  <ProcessDescibeCardDesc>
                    복잡한 법원서류를 직접 작성하지 않아도 돼요
                  </ProcessDescibeCardDesc>
                </ProcessDescibeCard>
              </ProcessDescibeCardRowWrapper>
              <ProcessDescibeCardInterRowWrapper> 
                <ProcessDescibeCardInterRowEachWrapper />
                <ProcessDescibeCardInterRowEachWrapper>
                    <ProcessDescibeCardInterRowIcon src={IcArrowDownGray}/>
                </ProcessDescibeCardInterRowEachWrapper>
              </ProcessDescibeCardInterRowWrapper>
              <ProcessDescibeCardInterRowMobileWrapper>
                  <ProcessDescibeCardArrowIconMobile src={IcArrowDownGray}/>
                </ProcessDescibeCardInterRowMobileWrapper>
              <ProcessDescibeCardRowWrapper>
                <ProcessDescibeCard>
                  <ProcessDescibeCardTitleWrapper>
                    <ProcessDescibeCardTitleText>STEP 4</ProcessDescibeCardTitleText>
                    <ProcessDescibeCardTitleText>알림톡으로 법원 결과 통지</ProcessDescibeCardTitleText>
                  </ProcessDescibeCardTitleWrapper>
                  <ProcessDescibeCardBorder />
                  <ProcessDescibeCardDesc>
                    알림톡으로 법원 결과 통지
                  </ProcessDescibeCardDesc>
                </ProcessDescibeCard>
                <ProcessDescibeCardInterRowIcon src={IcArrowLeftGray}/>
                <ProcessDescibeCardInterRowMobileWrapper>
                  <ProcessDescibeCardArrowIconMobile src={IcArrowDownGray}/>
                </ProcessDescibeCardInterRowMobileWrapper>
                <ProcessDescibeCard>
                  <ProcessDescibeCardTitleWrapper>
                    <ProcessDescibeCardTitleText>STEP 3</ProcessDescibeCardTitleText>
                    <ProcessDescibeCardTitleText>법대리가 법원 절차 진행</ProcessDescibeCardTitleText>
                  </ProcessDescibeCardTitleWrapper>
                  <ProcessDescibeCardBorder />
                  <ProcessDescibeCardDesc>
                  법원에 직접 출석할 필요 없어요
                  </ProcessDescibeCardDesc>
                </ProcessDescibeCard>
              </ProcessDescibeCardRowWrapper>
            </ProcessDescibeCardWrapper>
        </ProcessDescibeWrapper>
        {/* </CriminalDriveServiceThirdWrapper> */}
        <CriminalDriveServiceBorder />
        <CriminalDriveServiceThirdWrapper>
          <CriminalDriveServiceThirdTitleContentWrapper>
            <CriminalDriveServiceTitle>
              {isMobile?
              (<>왜 법대리에 사건 의뢰를<br/>맡겨야 할까요?</>)
              :"왜 법대리에 사건 의뢰를 맡겨야 할까요?"}
              
            </CriminalDriveServiceTitle>
            <CriminalDriveServiceThirdContentWrapper>
              <CriminalDriveServiceThirdContentRow>
                <CriminalDriveServiceSecondContentImg src={IcDrivePrice}/>
                <CriminalDriveServiceThirdContentRowDescWrapper>
                  <CriminalDriveServiceSubTitle>
                  거품 없는 최소비용으로 변호사 선임
                  </CriminalDriveServiceSubTitle>
                  <CriminalDriveServiceDesc>
                  {content2()}
                  </CriminalDriveServiceDesc>
                </CriminalDriveServiceThirdContentRowDescWrapper>
              </CriminalDriveServiceThirdContentRow>
              <CriminalDriveServiceThirdContentRow>
                <CriminalDriveServiceSecondContentImg src={IcDriveHour}/>
                <CriminalDriveServiceThirdContentRowDescWrapper>
                  <CriminalDriveServiceSubTitle>
                  사건 분야별 전문 고경력 변호사 24시간 선임 가능
                  </CriminalDriveServiceSubTitle>
                  <CriminalDriveServiceDesc>
                  {content3()}
                  </CriminalDriveServiceDesc>
                </CriminalDriveServiceThirdContentRowDescWrapper>
              </CriminalDriveServiceThirdContentRow>
              <CriminalDriveServiceThirdContentRow>
                <CriminalDriveServiceSecondContentImg src={IcDriveAlarm}/>
                <CriminalDriveServiceThirdContentRowDescWrapper>
                  <CriminalDriveServiceSubTitle>
                  알림톡으로 정확한 일정 안내
                  </CriminalDriveServiceSubTitle>
                  <CriminalDriveServiceDesc>
                  {content4()}
                  </CriminalDriveServiceDesc>
                </CriminalDriveServiceThirdContentRowDescWrapper>
              </CriminalDriveServiceThirdContentRow>
            </CriminalDriveServiceThirdContentWrapper>
          </CriminalDriveServiceThirdTitleContentWrapper>
          {/* <CriminalDriveServiceThirdHelpWrapper>
            <CriminalDriveServiceThirdHelpContainer>
              <CriminalDriveServiceThirdHelpContentContainer>
                <CriminalDriveServiceCenterDesc>
                {helper1()}
                </CriminalDriveServiceCenterDesc>
                <CriminalDriveServiceCenterDesc>
                법대리에서 내 상황을 무료 진단 받고,<br/>온라인으로 쉽고 빠르게 변호사를 선임하세요.
                </CriminalDriveServiceCenterDesc>
              </CriminalDriveServiceThirdHelpContentContainer>
              <CriminalDriveServiceBtn onClick={() => onClickBtn()}>
                지금 바로 무료 진단받기
              </CriminalDriveServiceBtn>
            </CriminalDriveServiceThirdHelpContainer>
          </CriminalDriveServiceThirdHelpWrapper> */}
        </CriminalDriveServiceThirdWrapper>
      </CriminalDriveServiceWrapper>
  )
}

export default InheritServiceDetail;