import { useEffect, useState } from "react";
import IcMonthlyPayCertServiceMain from "assets/svg/IcMonthlyPayCertServiceMain.svg";
import IcMonthlyPayCertServiceMainMobile from "assets/svg/IcMonthlyPayCertServiceMainMobile.svg";


import { useRecoilValue } from "recoil";
import { userData, redirectEtcUri } from "../../../../states/atoms";
import moment from "moment";

import {
  Container,
} from "../../../Service.style";
import {
  ApplyServiceDescContainer,
  ApplyServiceDescSection,
  ApplyServiceDescText,
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
} from "../../../home/ServiceCenter.style";

import {
  DatePicker,
} from "antd";

import { RadioGroup, Radio } from "../../../home/CommonCriminal/RadioBox";
// import { CheckboxGroup, Checkbox } from "../../../home/CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { useRecoilState } from 'recoil';

import {
  Input,
  Row,
  // Radio
} from "antd";

import { removeBrTag } from "routes/Landing";
import { ApplyServiceSubmit } from "../ApplyServiceSubmit";
import { ApplyServiceInputText, ApplyServiceDatePicker, ApplyServiceSubmitDataContainer, ApplyServiceSubmitDataInfoContainer, ApplyServiceSubmitDataInfoDetailContainer, ApplyServiceSubmitDataInfoDetailTitle, ApplyServiceSubmitDataSection } from "./ApplyService.style";
import { EventFormEachDesc, EventInputTextArea, EventSubmitFooterSection, EventSubmitFooterText, EventSubmitFooterTextContainer } from "components/event/3o3/order/EventSubmit.style";

const { useHistory } = require("react-router-dom");

const ApplyMonthlyPayCertService = ({ loggedInUser }) => {

  // 계약 체결한 날짜 
  const [contarctDate, setContarctDate] = useState("");

  // 보증금 금액
  const [amount, setAmount] = useState(null);

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    setAmount(inputValue)
  }

  // 계약 시작한 날짜
  const [startDate, setStartDate] = useState("");

  // 월세가 있으신가요?
  const [monthPay, setMonthPay] = useState("");

  const handleMonthPay = (e) => {
    // const inputValue = e.target.value;
    setMonthPay(e)
  }

  // 월세 금액
  const [mothlyAmount, setMothlyAmount] = useState(null);

  const handleMothlyAmountChange = (e) => {
    const inputValue = e.target.value;
    setMothlyAmount(inputValue)
  }
  // 월세 납부일
  const [monthlyPayDate, setMonthlyPayDate] = useState("");

  const handlMonthlyPayDateChange = (e) => {
    const inputValue = e.target.value;
    setMonthlyPayDate(inputValue)
  }

  // 계약 종료 날짜 
  const [endDate, setEndDate] = useState("");

  //계약 종료 사유
  const [endReason, setEndReason] = useState("");
  const [etcEndReason, setEtcEndReason] = useState("");

  const handleEtcEndReasonChange = (e) => {
    const inputValue = e.target.value;
    setEtcEndReason(inputValue)
  }

  // 보증금에서 제외할 금액
  const [exceptAmount, setExceptAmount] = useState("");
  const [exceptAmountDesc, setExceptAmountDesc] = useState("");

  const handleExceptAmountDescChange = (e) => {
    const inputValue = e.target.value;
    setExceptAmountDesc(inputValue)
  }

  // 장기 수선 충당금
  const [longTermRepair, setLongTermRepair] = useState("");

  // 전세보증 보험 가입 여부
  const [insurance, setInsurance] = useState("");

  // 보증금 받지 못한 손해
  const [damageNotReceive, setDamageNotReceive] = useState("");

  // 집주인의 주민번호
  const [returnCopy, setReturnCopy] = useState("");

  // 현재 상황에 대한 상세 설명
  const [extraDesc, setExtraDesc] = useState("");

  const handleExtraDescChange = (e) => {
    const inputValue = e.target.value;
    setExtraDesc(inputValue)
  }

  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  const title = () => {
    const text = isMobile
      ? "전･월세 보증금\n내용증명 신청하기"
      : "전･월세 보증금\n내용증명 신청하기";
    return removeBrTag(text);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const serviceData = () => {
    const serviceData = {
      // 전화번호
      contact: "",
      // 첨부파일
      attachments: [],
      contract: {
        date: moment(contarctDate).format("YYYY-MM-DD"),
        deposit: {
          amount: amount,
          date: moment(startDate).format("YYYY-MM-DD")
        },
        monthlyRent: {
          amount: mothlyAmount,
          date: monthlyPayDate,
          exist: monthPay === "네" ? "y" : "n"
        },
      },
      description: extraDesc,
      exclusions: exceptAmount === "네" ? [exceptAmountDesc] : [],
      insurance: {
        registYn: insurance === "네" ? "y" : "n"
      },
      longTermRepairReserve: {
        requestYn: longTermRepair === "네" ? "y" : "n"
      },
      lossDamage: {
        exist: damageNotReceive === "네" ? "y" : "n"
      },
      returnDocument: {
        exist: returnCopy === "네" ? "y" : "n"
      },
      termination: {
        date: moment(endDate).format("YYYY-MM-DD"),
        reason: endReason === "기간 만료" ? "expire" : "cancel",
        reasonDesc: etcEndReason !== "" ? etcEndReason : null,
      },
      termsOfService: true,
      userId: loggedInUser.uid
    };
    return serviceData;
  }


  const isSubmitReady = () => {
    if (contarctDate !== "" && amount !== null, startDate !== "" && monthPay !== "" &&
      (monthPay === "네" ? (mothlyAmount !== null && monthlyPayDate !== "") : true) &&
      endDate !== "" && endReason !== "" && exceptAmount !== "" && longTermRepair !== "" &&
      insurance !== "" && damageNotReceive !== "" && returnCopy !== "") {
      return true;
    }
    return false;
  }

  const footerText = () => {
    const text = isSubmitReady()
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄져요. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "모든 항목에 답변해 주세요.\n답변이 완료되면 제출할 수 있어요.";
    return removeBrTag(text);
  }

  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcMonthlyPayCertServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcMonthlyPayCertServiceMain}
            />
            <ServiceCenterFirstImg src={IcMonthlyPayCertServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ApplyServiceSubmitDataSection>
        <ApplyServiceSubmitDataContainer>
          <ApplyServiceSubmitDataInfoContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약을 체결한 날짜를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={contarctDate}
                onChange={setContarctDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>보증금 금액을 적어주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceInputText
                value={amount}
                type="number"
                inputMode="numeric"
                onChange={handleAmountChange}
                placeholder="₩" />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약이 시작된 날짜를 선택해 주세요.(잔금일)</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={startDate}
                onChange={setStartDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>월세가 있으셨나요?</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup label="" value={monthPay} style={{ width: "100%" }} onChange={handleMonthPay}>
                <Radio value="네" >네</Radio>
                <Radio value="아니오" >아니오</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            {monthPay.includes("네") && <>
              <ApplyServiceSubmitDataInfoDetailContainer>
                <ApplyServiceSubmitDataInfoDetailTitle>월세 금액을 적어주세요.</ApplyServiceSubmitDataInfoDetailTitle>
                <ApplyServiceInputText
                  value={mothlyAmount}
                  type="number"
                  inputMode="numeric"
                  onChange={handleMothlyAmountChange}
                  placeholder="₩" />
              </ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailContainer>
                <ApplyServiceSubmitDataInfoDetailTitle>월세 납부일을 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
                <ApplyServiceInputText
                  value={monthlyPayDate}
                  type="number"
                  inputMode="numeric"
                  onChange={handlMonthlyPayDateChange}
                  placeholder="매월 몇일인지 적어주세요." />
              </ApplyServiceSubmitDataInfoDetailContainer>
            </>}
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약이 종료된 날짜를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={endDate}
                onChange={setEndDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약 종료 사유를 알려주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={endReason}
                onChange={setEndReason}
              >
                <Radio value="기간 만료">기간 만료</Radio>
                <Radio value="계약 해지">계약 해지</Radio>
              </RadioGroup>
              <ApplyServiceInputText value={etcEndReason} onChange={handleEtcEndReasonChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                보증금에서 제외할 금액이 있나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>
                전세 계약금 중 돌려받은 일부 금액 또는 월세를 밀려 지불하지 않은<br />금액을 포함하여,
                청구시 제외할 금액을 작성해주세요.
              </EventFormEachDesc>
              <RadioGroup
                label=""
                value={exceptAmount}
                onChange={setExceptAmount}
              >
                <Radio value="네">네</Radio>
                {exceptAmount.includes("네") && <>
                  <ApplyServiceInputText value={exceptAmountDesc} onChange={handleExceptAmountDescChange} placeholder="금액과 사유를 모두 적어주세요." />
                </>}
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                전세보증 보험에 가입이 되어 있으신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={insurance}
                onChange={setInsurance}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                장기 수선 충당금 반환을 요구하시나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={longTermRepair}
                onChange={setLongTermRepair}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                보증금을 받지 못해 발생하는 손해가 있으신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={damageNotReceive}
                onChange={setDamageNotReceive}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                반송본이 필요하신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>
                공시 송달 등 후속 조치를 하기 위해서는 임대인 초본을 발급<br />
                받으셔야 하며, 임대인 초본 발급을 위해서는 반송본 원본이 필요합니다.<br />
                "네"를 선택하실 경우 발송 주소지를 고객님 주소로 작성하여<br />
                진행해 드리고 있습니다. (명의는 변호사 및 법무법인 명의로 발송)
                <br />"아니오"를 선택하실 경우 내용증명이 반송 되어도 반송본을 받으실 수 없습니다.
              </EventFormEachDesc>
              <RadioGroup
                label=""
                value={returnCopy}
                onChange={setReturnCopy}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>그 외 특이사항이 있나요?</ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>기타 입력하신 내용 외 특이사항이 있을 경우 작성해 주세요.</EventFormEachDesc>
              <EventInputTextArea value={extraDesc} onChange={handleExtraDescChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
          </ApplyServiceSubmitDataInfoContainer>
        </ApplyServiceSubmitDataContainer>
      </ApplyServiceSubmitDataSection>
      <EventSubmitFooterSection active={isSubmitReady()}>
        <EventSubmitFooterTextContainer>
          <EventSubmitFooterText active={isSubmitReady()}>
            {footerText()}
          </EventSubmitFooterText>
        </EventSubmitFooterTextContainer>
      </EventSubmitFooterSection>
      <ApplyServiceSubmit
        active={isSubmitReady()}
        serviceData={serviceData()}
        serviceType={"housing_certification"}
        uid={loggedInUser.uid}
      />
    </Container>
  )
};

export default ApplyMonthlyPayCertService;
