import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const CommonCertificationRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        내용증명 보내기
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="내용증명 요청 관련 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="필요한 분야" labelStyle={labelStyle}>
            {serviceData.field}
          </Descriptions.Item>
          {serviceData.etcField ? (
            <>
              <Descriptions.Item label="그 외 필요한 분야" labelStyle={labelStyle}>
                {serviceData.etcField}
              </Descriptions.Item>
            </>
          ) : null}
          <Descriptions.Item
             label="의뢰인이 원하는 해결방향"
             labelStyle={labelStyle}
           >
             {serviceData.solutionType}
           </Descriptions.Item>

           {serviceData.solutionType.includes("직접 입력") ? (
             <Descriptions.Item label="기타 이유" labelStyle={labelStyle}>
               {serviceData.etcSolution}
             </Descriptions.Item>
           ) : null}

          <Descriptions.Item
             label="의뢰인이 원하는 결과"
             labelStyle={labelStyle}
           >
             {serviceData.desiredResult.join(", ")}
           </Descriptions.Item>

           {serviceData.desiredResult.includes("직접 입력") ? (
             <Descriptions.Item label="기타 이유" labelStyle={labelStyle}>
               {serviceData.etcDesiredResult}
             </Descriptions.Item>
           ) : null}
        </Descriptions>
        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.caseDesc
              ? serviceData.caseDesc.split("\n").map((line, idx) => {
                return (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                );
              })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
        </Descriptions>

        {/* <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">첨부 파일</div>
          </div>
          {serviceData.attachments.map((attachment) => (
            <Image
              key={attachment}
              width={75}
              height={75}
              src={attachment}
              preview={{ zIndex: 10000 }}
            />
          ))}
        </div> */}
      </div>
    </>
  );
};

export default CommonCertificationRequest;
