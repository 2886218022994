import {
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Typography,
} from "antd";
import { dbService } from "fbase";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
const { TextArea } = Input;
const { Title } = Typography;
const qs = require("qs");

const RequestAdmin = ({ requestData }) => {
  const [reply, setReply] = useState(requestData.reply);
  const [price, setPrice] = useState(requestData.price);
  const [memo, setMemo] = useState(requestData.memo);
  const [requestContent, setRequestContent] = useState(
    requestData.requestContent
  );
  const [updating, setUpdating] = useState(false);
  const [status, setStatus] = useState(requestData.status);
  const key = "updatable";
  const history = useHistory();

  const onReplyClick = async () => {
    message.loading({
      content: "업데이트 중입니다.",
      key,
      duration: 2,
    });
    setUpdating(true);
    try {
      await dbService
        .collection("requests_temp")
        .doc(requestData.id)
        .update({ reply: reply });
      message.success({
        content: "업데이트 완료!",
        key,
        duration: 2,
      });
    } catch (error) {
      message.error({
        content: "업데이트 실패",
        key,
        duration: 2,
      });
    }
    setUpdating(false);
    window.alert("상태 변경을 확인해주세요. (수동)");
    window.location.reload();
  };

  const onPriceClick = async () => {
    message.loading({
      content: "업데이트 중입니다.",
      key,
      duration: 0,
    });
    setUpdating(true);
    try {
      await dbService.collection("requests_temp").doc(requestData.id).update({
        price: price,
      });
      message.success({
        content: "업데이트 완료!",
        key,
        duration: 2,
      });
    } catch (error) {
      message.error({
        content: "업데이트 실패",
        key,
        duration: 2,
      });
    }

    setUpdating(false);
    window.location.reload();
  };

  const onDeleteClick = async () => {
    if (!window.confirm("삭제 하시겠습니까?")) {
      return;
    }
    message.loading({
      content: "삭제 중입니다.",
      key,
      duration: 2,
    });
    setUpdating(true);
    try {
      await dbService
        .collection(requestData.serviceType)
        .doc(requestData.serviceId)
        .delete();
      await dbService.collection("requests_temp").doc(requestData.id).delete();

      message.success({
        content: "삭제 완료!",
        key,
        duration: 2,
      });
    } catch (error) {
      // console.log(error);
      message.error({
        content: "삭제 실패",
        key,
        duration: 2,
      });
    }

    setUpdating(false);
    history.push("/admin");
  };

  const onMemoClick = async () => {
    message.loading({
      content: "업데이트 중입니다.",
      key,
      duration: 0,
    });
    setUpdating(true);
    try {
      await dbService.collection("requests_temp").doc(requestData.id).update({
        memo: memo,
      });
      message.success({
        content: "업데이트 완료!",
        key,
        duration: 2,
      });
    } catch (error) {
      message.error({
        content: "업데이트 실패",
        key,
        duration: 2,
      });
    }

    setUpdating(false);
    window.location.reload();
  };

  const onStatusUpdateClick = async () => {
    message.loading({
      content: "업데이트 중입니다.",
      key,
      duration: 0,
    });
    setUpdating(true);
    try {
      await dbService.collection("requests_temp").doc(requestData.id).update({
        status: status,
      });
      message.success({
        content: "업데이트 완료!",
        key,
        duration: 2,
      });
    } catch (error) {
      message.error({
        content: "업데이트 실패",
        key,
        duration: 2,
      });
    }

    setUpdating(false);
    window.location.reload();
  };

  const sendNotiTalk = async (notiType) => {
    try {
      const userSnapshot = await dbService
        .collection("users")
        .doc(requestData.userId)
        .get();
      const userData = userSnapshot.data();

      if (
        !userData ||
        !userData.kakao_account ||
        !userData.kakao_account.phone_number
      ) {
        message.error({
          content: "유저 연락처가 없습니다.",
          key,
          duration: 2,
        });
        return;
      }
      let requestName = "";
      if (requestData.serviceType === "housing_certification") {
        requestName = "전세금 반환 내용증명";
      } else if (requestData.serviceType === "housing_protection") {
        requestName = "임차권 등기명령";
      } else if (requestData.serviceType === "property_deposit") {
        requestName = "전세금 반환 지급명령";
      } else if (requestData.serviceType === "housing_seizure") {
        requestName = "부동산 가압류 신청";
      } else if (requestData.serviceType === "housing_public_conveyance") {
        requestName = "전세금 반환 공시송달";
      } else if (requestData.serviceType === "drunk_driving") {
        requestName = "음주운전 양형자료";
      } else if (requestData.serviceType === "drug") {
        requestName = "마약사건 양형자료";
      } else if (requestData.serviceType === "media_obscenity") {
        requestName = "통매음 양형자료";
      } else if (requestData.serviceType === "lend_money") {
        requestName = "대여금 지급명령";
      } else if (requestData.serviceType === "lend_certification") {
        requestName = "대여금 내용증명";
      } else if (requestData.serviceType === "noise_certification") {
        requestName = "층간 소음 내용증명";
      } else if (requestData.serviceType === "pilates_certification") {
        requestName = "헬스/필라테스 환불 내용증명";
      } else if (requestData.serviceType === "pilates_order") {
        requestName = "헬스/필라테스 환불 지급명령";
      } else if (requestData.serviceType === "host_cancel_certification") {
        requestName = "부동산 계약해지 내용증명";
      } else if (requestData.serviceType === "host_charge_certification") {
        requestName = "월세 청구 내용증명";
      } else if (requestData.serviceType === "host_notallowed_certification") {
        requestName = "임대차 계약연장 불허 통지 내용증명";
      } else if (requestData.serviceType === "civil_defendant") {
        requestName = "민사소송 원고";
      } else if (requestData.serviceType === "civil_plaintiff") {
        requestName = "민사소송 피고";
      } else if (requestData.serviceType === "etc_certification") {
        requestName = "기타 내용증명";
      } else if (requestData.serviceType === "common_criminal_drive") {
        requestName = "형사 사건 운전사고";
      } else if (requestData.serviceType === "common_criminal_sexual") {
        requestName = "형사 사건 성범죄";
      } else if (requestData.serviceType === "common_criminal_represent") {
        requestName = "형사 사건 고소대리";
      } else if (requestData.serviceType === "common_criminal_etc") {
        requestName = "형사 사건 기타";
      } else if (requestData.serviceType === "common_etc") {
        requestName = "기타 법률";
      } else if (requestData.serviceType === "golfTennis_certification") {
        requestName = "골프/테니스 환불 내용증명";
      } else if (requestData.serviceType === "golfTennis_order") {
        requestName = "골프/테니스 환불 지급명령";
      } else if (requestData.serviceType === "inherit_giveup") {
        requestName = "상속포기";
      } else if (requestData.serviceType === "inherit_limited") {
        requestName = "한정승인";
      } else if (requestData.serviceType === "vacatehouse") {
        requestName = "명도소송";
      } else if (requestData.serviceType === "injunction") {
        requestName = "가처분";
      }

      let title = "";
      let msg = "";
      let template_id;
      if (notiType === "answer") {
        msg =
          "안녕하세요. 법대리입니다.\n" +
          "고객님이 문의하신 " +
          requestName +
          " 내용에 변호사 답변이 등록되었습니다.\n" +
          "문의ID: " +
          requestData.id +
          "\n\n" +
          "추가 문의 사항은 아래 카카오톡 채팅으로 문의 부탁드립니다.";
        template_id = 50036;
      } else if (notiType === "request") {
        title = requestName;
        msg =
          "안녕하세요. 법대리입니다.\n" +
          requestName +
          "에 필요한 사항들을 요청드립니다.\n" +
          "문의ID: " +
          requestData.id +
          "\n\n[요청사항]\n" +
          requestContent +
          "\n\n" +
          "요청 사항들을 아래 카카오톡 채팅으로 제출 부탁드리겠습니다.";
        template_id = 50037;
      }

      if (!window.confirm("알림톡을 발송하시겠습니까?\n" + msg)) {
        return;
      }

      const res = await fetch(
        process.env.REACT_APP_FIREBASE_FUNCTION_NOTITALK_URL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            template_id: template_id,
            title: title,
            messages: msg,
            phone: userData.kakao_account.phone_number.replace("+82", ""),
            btn_pc_url: "https://by-law.in/mypage",
            btn_mobile_url: "https://by-law.in/mypage",
          }),
        }
      );

      const result = await res.json();
      if (res.status === 400) {
        message.error({
          content: "알림톡 요청 실패",
          key,
          duration: 2,
        });
      } else {
        message.success({
          content: "알림톡 요청 완료!",
          key,
          duration: 2,
        });

        if (notiType === "request") {
          window.alert("상태 변경을 확인해주세요. (수동)");
        }
      }
    } catch (error) {
      message.error({
        content: "알림톡 요청 실패",
        key,
        duration: 2,
      });
    }
  };

  const onAnswerNotiClick = async () => {
    await sendNotiTalk("answer");
  };

  const onRequestNotiClick = async () => {
    await sendNotiTalk("request");
  };
  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        관리자 항목
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 5,
          marginBottom: 10,
          borderRadius: 5,
        }}
      >
        <Collapse defaultActiveKey={["3"]} accordion>
          <Collapse.Panel header="변호사 의견 등록" key="1">
            <TextArea
              placeholder="의견을 입력해주세요."
              value={reply}
              onChange={(e) => setReply(e.target.value)}
              autoSize
            />
            <Row justify="space-between" style={{ marginTop: 20 }}>
              <Col span={6} offset={18} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={onReplyClick}
                  style={{ width: "100%", maxWidth: "100px" }}
                  disabled={updating}
                >
                  {updating ? "처리중" : "업데이트"}
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header="결제 등록" key="2">
            <InputNumber
              addonBefore="₩"
              addonAfter="원"
              controls={false}
              value={price}
              onChange={(value) => setPrice(value)}
              style={{ width: "100%" }}
            />
            <Row justify="space-between" style={{ marginTop: 20 }}>
              <Col span={8} offset={16} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={onPriceClick}
                  style={{ width: "100%", maxWidth: "100px" }}
                  disabled={
                    updating ||
                    !requestData.reply ||
                    requestData.status === "paid" ||
                    requestData.status === "done"
                  }
                >
                  {updating ? "처리중" : "업데이트"}
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header="메모" key="3">
            <TextArea
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
              autoSize
            />
            <Row justify="space-between" style={{ marginTop: 20 }}>
              <Col span={6} offset={18} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={onMemoClick}
                  style={{ width: "100%", maxWidth: "100px" }}
                  disabled={updating}
                >
                  {updating ? "처리중" : "업데이트"}
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>

          <Collapse.Panel header="상태 변경" key="4">
            <Select
              defaultValue="lucy"
              style={{
                width: "100%",
              }}
              onChange={(value) => setStatus(value)}
              value={status}
              options={[
                {
                  value: "submitted",
                  label: "제출완료",
                },
                {
                  value: "replied",
                  label: "답변완료",
                },
                {
                  value: "paid",
                  label: "결제완료",
                },
                {
                  value: "requested",
                  label: "서류요청",
                },
                {
                  value: "processing",
                  label: "요청 처리중",
                },
                {
                  value: "done",
                  label: "처리완료",
                },
              ]}
            />
            <Row justify="space-between" style={{ marginTop: 20 }}>
              <Col span={6} offset={18} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={onStatusUpdateClick}
                  style={{ width: "100%", maxWidth: "100px" }}
                  disabled={updating}
                >
                  {updating ? "처리중" : "업데이트"}
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <Link to={"/admin/user/" + requestData.userId}>유저 정보</Link>
            }
            key="5"
            collapsible="disabled"
          ></Collapse.Panel>

          <Collapse.Panel header="알림톡 발송" key="6">
            <Row justify="space-between">
              <Col>
                <Button
                  type="primary"
                  onClick={onAnswerNotiClick}
                  // style={{ width: "100%", maxWidth: "100px" }}
                  // disabled={requestData.status !== "replied"}
                >
                  변호사 답변 알림
                </Button>
              </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: 20 }}>
              <TextArea
                value={requestContent}
                onChange={(e) => setRequestContent(e.target.value)}
              />

              <Col>
                <Button
                  type="primary"
                  onClick={onRequestNotiClick}
                  // style={{ width: "100%", maxWidth: "100px" }}
                  // disabled={requestData.status !== "requested"}
                >
                  서류 제출 요청 알림
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>

        <Row justify="space-between" style={{ marginTop: 20 }}>
          <Col span={6} offset={18} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={onDeleteClick}
              style={{ width: "100%", maxWidth: "100px" }}
              disabled={updating}
            >
              {updating ? "처리중" : "삭제"}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RequestAdmin;
