import Collapse from "components/common/Collapse";
import { useState } from "react";
import { SERVICE_QNA } from "../../service-constants";
import IcHeadset from "../../assets/svg/IcHeadset.svg";
import ImgServiceLoanStep1 from "../../assets/svg/ImgServiceLoanStep1.svg";
import IcNoise from "../../assets/svg/IcNoise.svg";

import {
  Container,
  BtnConsult,
  CollapseWrapper,
  ContentContainer,
  NoiseServiceCenterSection,
  DetailImg,
  DetailRightBtn,
  DetailRightDesc,
  DetailRightTitle,
  DetailRightWrapper,
  DetailWrapper,
  SubTitle,
  Title,
  TopWrapper,
  SectionTop,
  TopTitle,
  TopDesc,
  TopImg,
  DetailRightLabel,
  DetailRightLabelMobile,
  DetailRightTitleMobile,
} from "../Service.style";
import { SerivceCenterConsultingSection } from "./ServiceCenter.style";
import Footer from "components/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import ChannelService from "../ChannelService";

const NoiseServiceCenter = ({loggedInUser}) => {
  const [tab, setTab] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <SectionTop style={{ height: 380 }}>
        <ContentContainer style={{ width: "768px" }}>
          <TopWrapper>
            <TopTitle>층간 소음</TopTitle>
            <TopDesc>
              층간 소음으로 인해 어려움을 겪고 있다면
              <br />
              법대리 층간 소음 전문 변호사를 통해 해결해보세요.
            </TopDesc>
          </TopWrapper>
          <TopImg src={IcNoise} />
        </ContentContainer>
      </SectionTop>
      <NoiseServiceCenterSection>
        <ContentContainer>
          <DetailWrapper>
            <DetailRightLabelMobile>STEP 1</DetailRightLabelMobile>
            <DetailRightTitleMobile>
              층간소음 내용증명 발송하기
            </DetailRightTitleMobile>
            <DetailImg src={ImgServiceLoanStep1} />
            <DetailRightWrapper>
              <DetailRightLabel>STEP 1</DetailRightLabel>
              <DetailRightTitle>층간소음 내용증명 발송하기</DetailRightTitle>
              <DetailRightDesc>
                층간 소음을 일으키는 가해자에게 소음을 중단하고
                <br />
                손해를 배상할 것을 청구하는 작업입니다.
                <br />
                층간 소음 전문 변호사가 직접 작성해서 법무법인 명의로 발송하는
                <br />
                내용증명을 통해 손쉽게 문제를 해결해 보세요.
              </DetailRightDesc>
              <Link to="/noise/certification">
                <DetailRightBtn>신청하기</DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          <CollapseWrapper>
            {SERVICE_QNA.pilates.map(({ title, desc }, idx) => (
              <Collapse
                key={`pilates-service$-${idx}`}
                title={title}
                desc={desc}
              />
            ))}
          </CollapseWrapper>
        </ContentContainer>
      </NoiseServiceCenterSection>
      <SerivceCenterConsultingSection>
        <ContentContainer>
          <Title style={{ marginBottom: 10, marginTop: 0 }}>
            기타 층간 소음 상담
          </Title>
          <SubTitle style={{ marginBottom: 0 }}>
            복잡한 법률 용어, 지식은 모르셔도 됩니다.
            <br />
            법대리가 알려드리는 항목들만 작성 및 첨부해 주시면 법대리의 전문
            변호사단이 검토 후 의견을 드립니다.
          </SubTitle>
          <BtnConsult
            onClick={() => {
              // LandingToChannelTalk();
              if(!loggedInUser){
                ChannelService.loadScript();
                ChannelService.boot({
                  "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
                })
              }
              ChannelService.showMessenger();
            }}
          >
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </BtnConsult>
        </ContentContainer>
      </SerivceCenterConsultingSection>
      <Footer />
    </Container>
  );
};

export default NoiseServiceCenter;
