const { Result, Button } = require("antd");
const { Link } = require("react-router-dom");

const PayFail = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginBottom: 10,
        borderRadius: 5,
        minHeight: "100%",
      }}
    >
      <Result
        status="error"
        title="결제에 실패하였습니다."
        subTitle="불편을 드려 죄송합니다. 상담 채널로 문의부탁드립니다."
        extra={[
          <Button type="primary" key="console">
            <Link to="/">홈으로</Link>
          </Button>,
        ]}
      ></Result>
    </div>
  );
};
export default PayFail;
