import { MoreOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import {
  BtnSend,
  ConversationWrapper,
  MessageContent,
  MessageForm,
  MessageFormWrapper,
  MessageHeader,
  MessageImg,
  MessagePdf,
  MessageWrapper,
  Name,
  PayDesc,
  Title,
  ConversationNoticeWrapper,
  SubmitNoticeTitleWrapper,
  SubmitNoticeTitleImg,
  SubmitNoticeTitle,
  ConversationNoticeDescWrapper,
  ConversationNoticeDesc1,
  ConversationNoticeDesc2,
  DocxPreviewDownloadWrapper,
  DocxPreviewDownloadImg,
  DocxPreviewDownloadTitle,
  LoadingWrapper
} from "./CaseDetailComp.style";

import axios from "axios";
import _ from 'lodash';
import { dbService } from "fbase";
import { getServiceTypeTitle } from "components/mypage/MyCaseList";
import { message } from "antd";
import IcSubmitNotice from "../../assets/svg/IcSubmitNotice.svg";
import downLoadButton from "../../assets/img/downLoadButton.png";
import { v4 as uuidv4 } from "uuid";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentMyCaseState, selectOpinionState } from "states/atoms";
import { updateUserNoti } from "./repository/CaseSubmissionRepository";
import ReactLoading from 'react-loading';

const CaseConversation = ({ lawyerName, loggedInUser, fromAdmin }) => {
  const lastMessageEl = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [content, setContent] = useState("");
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const currentMyCase = useRecoilValue(currentMyCaseState);
  const selectedOpinion = useRecoilValue(selectOpinionState);

  useEffect(() => {
    getChat(currentMyCase.accepted.chat_id);
    if (lastMessageEl && lastMessageEl.current) {
      lastMessageEl.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  async function getChat(id) {
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_CHAT_URL
    const query = "?requestId=" + id;
    const url = host + query;
    try {
      const { data } = await axios.get(url);
      setMessages(data.chat_list);
    } catch (e) {
      console.error(e);
    }
  }

  async function updateChat(id, content) {
    if (id === undefined) {
      return;
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_UPDATE_CHAT_URL
    const body = {
      requestId: id,
      message: content
    };
    const url = host;
    try {
      const { data } = await axios.post(url, body);
      // setLawyer(data);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  const handleMessageChange = (e) => {
    const inputValue = e.target.value;
    setContent(inputValue);
  }

  const sendMessage = async (e) => {
    if (content.trim() === '') {
      message.error("채팅 내용을 입력해주세요.");
      return;
    }

    setIsLoading(true);
    setSendButtonDisabled(true);
    const messageData = {
      userType: "client",
      content: content,
      sendAt: new Date(),
    }
    await updateChat(currentMyCase.accepted.chat_id, messageData);

    let userName = "";
    await dbService
      .collection("users")
      .where("__name__", "==", loggedInUser.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          const userData = snapshot.docs[0].data();
          userName = userData.properties.nickname;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    const caseName = getServiceTypeTitle(currentMyCase.serviceType);
    getChat(currentMyCase.accepted.chat_id);
    setContent("");
    await updateUserNoti("chat", loggedInUser.uid, currentMyCase.mandator, userName, currentMyCase.accepted.lawyer_id, currentMyCase.id, currentMyCase.accepted.lawyer_case_id)
    await sendWebhook(currentMyCase.accepted.lawyer_case_id, loggedInUser.uid, userName, caseName, content);
    setSendButtonDisabled(false);
    setIsLoading(false);
  };

  async function sendWebhook(caseId, userId, userName, caseName, content) {
    const webhookUrl = process.env.REACT_APP_DISCORD_CHAT_WEBHOOK_DOMAIN;
    const lawyerName = selectedOpinion.lawyer_name ?? "";
    const message = {
      content: `고객이 채팅을 전송하였습니다. 
      사건 이름: ${caseName} 
      유저: ${userName} 
      변호사 이름 : ${lawyerName}
      내용: ${content}
      변호사 페이지 사건ID: ${caseId} userId: ${userId}
      `
    };

    try {
      const { response } = await axios.post(webhookUrl, message);
    } catch (e) {
    }
  }

  return (
    <>
      <ConversationNoticeWrapper>
        <SubmitNoticeTitleWrapper>
          <SubmitNoticeTitleImg src={IcSubmitNotice} />
          <SubmitNoticeTitle>꼭 확인하세요!</SubmitNoticeTitle>
        </SubmitNoticeTitleWrapper>
        <ConversationNoticeDescWrapper>
          <ConversationNoticeDesc1>
            법대리 서비스는 제출된 고객 사건 정보를 바탕으로 법대리가 사건 진행에 따라 순차적으로 사건을 처리하고 있습니다.
            <br />
            해당 채널은 사건 처리를 위해 의뢰인에게 확인이 필요한 내용을 확인하는 용도의 창구이며,
            <br />
            변호사 상담 채널이 아니기 때문에 신청하신 서비스 진행을 위해 필수적인 내용 외 상담 내용은 답변을 받으실 수 없습니다.
            <br />
            이용 서비스 외 기타 법률 상담에 대한 답변은 제한될 수 있는 점 양해 부탁드립니다.
          </ConversationNoticeDesc1>
          <ConversationNoticeDesc2>
            * 사건 진행 현황은 알림톡으로 안내 드리고 있으니, 안심하고 기다려 주시기 바랍니다.
            </ConversationNoticeDesc2>
        </ConversationNoticeDescWrapper>
      </ConversationNoticeWrapper>
      <Title style={{ marginBottom: 50 }}>1:1 문의 채팅</Title>
      {isLoading && <LoadingWrapper> <ReactLoading type={"spin"} color={"#3a7efc"} height={'10%'} width={'10%'} /> </LoadingWrapper>}
      {!isLoading &&
        <>
          <ConversationWrapper>
            {messages.map((message, idx) => {
              return (
                <MessageWrapper
                  key={`conversation-${idx}`}
                  style={{
                    background: message.userType !== "lawyer" ? "#eceef4" : "#d6e4ff",
                    alignSelf: message.userType !== "lawyer" ? "flex-end" : "flex-start",
                  }}
                >
                  <MessageHeader>
                    <Name>
                      {message.userType !== "lawyer" ? "의뢰인" : `법대리`}
                    </Name>
                    <MoreOutlined />
                  </MessageHeader>
                  <MessageContent>{message.content}</MessageContent>
                  {message.file &&
                    renderFile(message.file, message.fileExtension, message.fileName)
                  }
                  {message.fileList &&
                    message.fileList.map(data => {
                      return renderFile(data.file, data.extension, data.name)
                    })
                  }
                </MessageWrapper>
              );
            })}
            <div ref={lastMessageEl} />
          </ConversationWrapper>
          <MessageFormWrapper>
            <MessageForm placeholder="내용을 입력해 주세요." rows={3} onChange={handleMessageChange} value={content} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 30,
                marginRight: 30,
              }}
            >
              {!fromAdmin &&
                <BtnSend disabled={sendButtonDisabled} onClick={sendMessage}>전송하기</BtnSend>
              }
            </div>
          </MessageFormWrapper>
        </>
      }
      <PayDesc>* 5회 이상 질문하려면 추가 결제가 필요합니다. </PayDesc>
    </>
  );
};

export default CaseConversation;

const renderFile = (fileUrl, extension, fileName) => {
  if (extension !== undefined) {
    switch (extension.toLowerCase()) {
      // img
      case "jpg":
      case "png":
      case "jpeg":
      case "svg":
        return <>
          <MessageImg src={fileUrl} />
          <DocxPreviewDownloadWrapper onClick={() => handleDownloadFile(fileUrl, fileName)}>
            <DocxPreviewDownloadTitle>
              다운로드
            </DocxPreviewDownloadTitle>
            <DocxPreviewDownloadImg src={downLoadButton} />
          </DocxPreviewDownloadWrapper>
        </>
      case "mov":
      case "mp4":
      case "wmv":
        return <video className='chatbox-img' src={fileUrl} />;
      case "pdf":
        return <>
          <MessagePdf src={fileUrl} />
          <DocxPreviewDownloadWrapper onClick={() => handleDownloadFile(fileUrl, fileName)}>
            <DocxPreviewDownloadTitle>
              다운로드
            </DocxPreviewDownloadTitle>
            <DocxPreviewDownloadImg src={downLoadButton} />
          </DocxPreviewDownloadWrapper>
        </>
      default:
        return "";
    }
  } else {
    return "";
  }
};

const handleDownloadFile = async (url, name) => {
  try {
    let realName = "";
    if (name === undefined) {
      realName = uuidv4();
    } else {
      realName = name
    }
    const response = await fetch(url);
    const blob = await response.blob();
    const filename = realName; // 저장할 파일명

    // 파일 다운로드
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE 및 Microsoft Edge의 경우
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
    }
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};
