import { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";

const Container = styled.div`
  border-bottom: 1px solid #d1d1d1;
  padding: 22px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    min-width: auto !important;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  flex: 1;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  @media screen and (max-width: 768px) {
    min-width: auto !important;
    font-size: 16px;
  }
`;

const Desc = styled.div`
  margin-top: 30px;
  flex: 1;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1f2e3d;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const Collapse = ({ title, desc, minWidth = 768 }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container style={{ minWidth }} onClick={() => setIsOpen((v) => !v)}>
      <TopWrapper>
        <Title>{title}</Title>
        {isOpen ? <UpOutlined /> : <DownOutlined />}
      </TopWrapper>
      {isOpen && <Desc>{desc}</Desc>}
    </Container>
  );
};

export default Collapse;
