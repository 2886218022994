import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import backButtonBlack from "../../assets/img/backButtonBlack.png";
import downloadButton from "../../assets/img/downLoadButton.png";
import "./CaseDataFilePreview.css";
import Slider from "react-slick";
import { 
  DocxPreviewWrapper,
  DocxPreviewDownloadWrapper,
  DocxPreviewDownloadTitle,
  DocxPreviewDownloadImg,
  DocxPreviewDesc,
  DocxPreviewContainer
 } from "./CaseDetailComp.style";
import { useRecoilState } from "recoil";
import { currentLawyerCaseState } from "states/atoms";

function CaseDataFilePreview({ onClickCloseButton }) {
    const [idx, setIdx] = useState(1);
    const [lawyerCase, setLaywerCase] = useRecoilState(currentLawyerCaseState);
    const [fileUrlList, setFileUrlList] = useState(null);

    useEffect(() => {
      setFileUrlList(lawyerCase.lawyer_document_list)
    }, [lawyerCase]);
  
    const handleGoBack = () => {
      onClickCloseButton();
    };

    const renderFile = (fileObj, index) => {
      return <DocxPreviewContainer>
              <DocxPreviewContainer>
                <DocxPreviewWrapper>
                <DocxPreviewDownloadWrapper onClick={() => handleDownloadFile()}>
                  <DocxPreviewDownloadTitle>
                    다운로드
                  </DocxPreviewDownloadTitle>
                  <DocxPreviewDownloadImg src={downloadButton}/>
                </DocxPreviewDownloadWrapper>
                <DocxPreviewDesc>
                해당 파일은 다운로드하여<br/>확인하시길 바랍니다.
                </DocxPreviewDesc>
                </DocxPreviewWrapper>
              </DocxPreviewContainer>
            </DocxPreviewContainer>
    };
  
    const settings = {
      speed: 500,
      slidesToShow: 1,
      infnite: true,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (oldIndex, newIndex) => {
        switch (newIndex) {
          default:
            setIdx(newIndex + 1);
            break;
        }
      },
    };

    const handleDownloadFile = async () => {
      console.log("idx",idx);
      try {
        const fileObj = fileUrlList[idx - 1];
        const response = await fetch(fileObj.url);
        const blob = await response.blob();
        const filename = fileObj.name; // 저장할 파일명
  
        // 파일 다운로드
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE 및 Microsoft Edge의 경우
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          link.click();
        }
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    }; 

    if (!fileUrlList) return null;
  
    return (
      <div className="preview-modal-wrapper">
        <div className="doc-modal-header-wrapper">
          <header
            className="doc-modal-header"
          > 
            <div className="doc-modal-back-wrapper" onClick={handleGoBack}>
              <img              
                className="doc-modal-back"
                style={{ marginLeft: 0 }}
                src={backButtonBlack}
              />
            </div>
            <div className="doc-modal-title-wrapper">
              <p className="doc-modal-title">사건자료</p>
            </div>            
            <div className="doc-modal-download-wrapper" onClick={() => handleDownloadFile()}>
              다운로드
              <img className="doc-modal-download-icon" src={downloadButton} />
            </div>
          </header>
          <div className="doc-modal-bottom-border"></div>
        </div>
        <div className="body-wrapper">
          <Slider {...settings}>
            {fileUrlList.map((file, index) => renderFile(file, index))}
          </Slider>
          <div
            style={{
              position: "absolute",
              bottom: 50,
              width: "100%",
              textAlign: "center",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            {idx} / {fileUrlList.length}
          </div>
        </div>
      </div>
    );
  }
  
export default CaseDataFilePreview;