import Collapse from "components/common/Collapse";
import { useState } from "react";
import { SERVICE_QNA } from "../../service-constants";
import IcHeadset from "../../assets/svg/IcHeadset.svg";
import IcCommonCriminalCenterPC from "../../assets/svg/icon_criminial_suit_pc.svg";
import {
  Container,
  BtnConsult,
  CollapseWrapper,
  ContentContainer,
  CriminalServiceCenterSection,
  SubTitle,
  Title,
  TopWrapper,
  SectionTop,
  TopTitle,
  TopDesc,
  CriminalTopImg,
} from "../Service.style";
import { SerivceCenterConsultingSection } from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import CriminialDriveCenter from "./CommonCriminal/CriminialDriveCenter";
import { removeBrTag } from "routes/Landing";
import { 
  TabWrapper,
  Tab
 } from "./CommonCriminal/CommonCriminal.style";
import CriminalSexualCenter from "./CommonCriminal/CriminalSexualCenter";
import CriminalRepresentCenter from "./CommonCriminal/CriminalRepresentCenter";
import CriminalEtcCenter from "./CommonCriminal/CriminalEtcCenter";
import ChannelService from "../ChannelService";

const CommonCriminalServiceCenter = ({loggedInUser}) => {
  const [tab, setTab] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderUI = () => {
    switch (tab) {
      case 0:
        return <CriminialDriveCenter loggedInUser={loggedInUser} />
      case 1:
        return <CriminalSexualCenter loggedInUser={loggedInUser} />
      case 2:
        return <CriminalRepresentCenter loggedInUser={loggedInUser} />
      case 3:
        return <CriminalEtcCenter loggedInUser={loggedInUser} />
    }
  }

  return (
    <Container>
      <SectionTop style={{ height: 380 }}>
        <ContentContainer style={{ width: "740px" }}>
          <TopWrapper>
            <TopTitle>형사소송</TopTitle>
            <TopDesc>
            타 법률사무소 대비 1/3 수준의 저렴한 금액으로 <br/>전문 변호사의 조력을 받아 형사 소송을 해결하세요
            </TopDesc>
          </TopWrapper>
          <CriminalTopImg src={IcCommonCriminalCenterPC} />
        </ContentContainer>
      </SectionTop>
      <CriminalServiceCenterSection>
        <ContentContainer>
        <TabWrapper>
            <Tab isActive={tab === 0} onClick={() => setTab(0)}>
              운전 사고
            </Tab>
            <Tab isActive={tab === 1} onClick={() => setTab(1)}>
              성범죄
            </Tab>
            <Tab isActive={tab === 2} onClick={() => setTab(2)}>
              고소 대리
            </Tab>
            <Tab isActive={tab === 3} onClick={() => setTab(3)}>
              기타 형사사건
            </Tab>
          </TabWrapper>
          {renderUI()}
          <CollapseWrapper>
            {SERVICE_QNA.commonCriminal.map(({ title, desc }, idx) => (
              <Collapse
                key={`criminal-service$-${idx}`}
                title={title}
                desc={removeBrTag(desc)}
              />
            ))}
          </CollapseWrapper>
        </ContentContainer>
      </CriminalServiceCenterSection>
      <SerivceCenterConsultingSection>
        <ContentContainer>
          <Title style={{ marginBottom: 10, marginTop: 0 }}>
            기타 형사 사건 상담
          </Title>
          <SubTitle style={{ marginBottom: 0 }}>
            복잡한 법률 용어, 지식은 모르셔도 됩니다.
            <br />
            법대리가 알려드리는 항목들만 작성 및 첨부해 주시면 법대리의 전문
            변호사단이 검토 후 의견을 드립니다.
          </SubTitle>
          <BtnConsult onClick={()=> {
            //  LandingToChannelTalk();
            if(!loggedInUser){
              ChannelService.loadScript();
              ChannelService.boot({
                "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
              })
            }
            ChannelService.showMessenger();
          }}>
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </BtnConsult>
        </ContentContainer>
      </SerivceCenterConsultingSection>
      <Footer/>
    </Container>
  );
};

export default CommonCriminalServiceCenter;
