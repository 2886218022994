import styled from "@emotion/styled";

export const FooterWrapper = styled.div`
  height: 123px;
  padding-top: 53px;
  padding-bottom: 53px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FooterTitleImgWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FooterFirstTitleImgWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FooterBorder = styled.div`
  width: 100%;
  background-color: #283644;
  height: 1px;
  margin-top: 38px;
  margin-bottom: 38px;
`;

export const FooterFirstTitle = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
`;

export const FooterTitle = styled.div`
  color: #eaecee;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

export const FooterDesc = styled.div`
  width: 100%;
  color: #eaecee;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-top: 13px;
`;

export const FooterImg = styled.img`
  width: 28px;
  height: auto;
`;

export const FooterSection = styled.section`
  width: 100%;
  background: #061727;
  height: 385px;
  // display: flex;
  margin: 0 auto;
  // justify-content: center;
  @media screen and (max-width: 768px) {
    height: 366px;
    display: block;
    justify-content: none;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
`;
