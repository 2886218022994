import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Step2 from "./Step2";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

const { useState } = require("react");
const { useHistory } = require("react-router-dom");
const { default: Step1 } = require("./Step1");

const Certification = ({ loggedInUser }) => {
  const [page, setPage] = useState(0);
  const history = useHistory();

  const itemLayout = {
    form: {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    },
    button: {
      wrapperCol: { span: 24, offset: 0 },
    },
  };

  const [step1Data, setStep1Data] = useState({
    golfTennis: {
      hangover:"n",
      contarctDate: null,
      startDate: null,
      endDate:null,
      abount:null,
    },
  });

  const [step2Data, setStep2Data] = useState({
    contract: {
      type:'lesson',
      count:null,
      usedCount:null,
      notiDate:null,
      attachments: null,
      promotionCode: null,
    },
    description: null,
    contact: null,
    promotionCode: null,
  });

  const onPrev = () => {
    setPage(page - 1);
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const onSubmit = async () => {
    const formattedStep1Data = {
      golfTennis: {
        ...step1Data.golfTennis,
        contarctDate: step1Data.golfTennis.contarctDate
          ? moment(step1Data.golfTennis.contarctDate).format("YYYY-MM-DD")
          : null,
          startDate: step1Data.golfTennis.startDate
          ? moment(step1Data.golfTennis.startDate).format("YYYY-MM-DD")
          : null,
          endDate: step1Data.golfTennis.endDate
          ? moment(step1Data.golfTennis.endDate).format("YYYY-MM-DD")
          : null,
      },
    };

    const formattedStep2Data = {
      ...step2Data,
      contract: {
        ...step2Data.contract,
        notiDate: step2Data.contract.notiDate
          ? moment(step2Data.contract.notiDate).format("YYYY-MM-DD")
          : null,
      },
    };

    const propertyDepositData = {
      ...formattedStep1Data,
      ...formattedStep2Data,
      userId: loggedInUser.uid,
    };

    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "golfTennis_certification",
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: propertyDepositData,
        userId: loggedInUser.uid,
        status: "submitted",
      });
      history.push("/submit/success");
    } catch (err) {
      history.push("/submit/fail");
    }
  };

  const steps = [
    {
      title: "골프/테니스 계약 정보",
      content: (
        <Step1
          data={step1Data}
          setData={setStep1Data}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "골프/테니스 계약 정보",
      content: (
        <Step2
          data={step2Data}
          setData={setStep2Data}
          onPrev={onPrev}
          onNext={onSubmit}
          itemLayout={itemLayout}
        />
      ),
    },
  ];

  return (
    <>
      <div className="steps-content">{steps[page].content}</div>
    </>
  );
};

export default Certification;
