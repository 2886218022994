export const ServiceType = {
  All: 'all',
  Drug: 'drug',
  DrunkDriving: 'drunk_driving',
  ETCCertification: 'etc_certification',
  HousingCertification: 'housing_certification',
  InheritGiveup: 'inherit_giveup',
  InheritLimited: 'inherit_limited',
  HousingProtection: 'housing_protection',
  HousingPublicConveyance: 'housing_public_conveyance',
  HousingSeizure: 'housing_seizure',
  LendMoney: 'lend_money',
  LendCertification:'lend_certification',
  propertyDeposit: 'property_deposit',
  PlaintInsult: 'plaint_insult',
  MediaObscenity: 'media_obscenity',
  SexualCrimeComplaint: 'sexual_crime_complaint',
  NoiseCertification: "noise_certification",
  PilatesCertification: "pilates_certification",
  PilatesOrder: "pilates_order",
  HostCancelCertification: "host_cancel_certification",
  HostChargeCertification: "host_charge_certification",
  HostNotallowedCertification: "host_notallowed_certification",  
  CivilDefendant: "civil_defendant",
  CivilPlaintiff: "civil_plaintiff",
  CommonCriminalDrive: "common_criminal_drive",
  CommonCriminalSexual: "common_criminal_sexual",
  CommonCriminalRepresant: "common_criminal_represent",
  CommonCriminalEtc: "common_criminal_etc",
  CommonEtc: "common_etc",
  GolfTennisCertification: "golfTennis_certification",
  GolfTennisOrder: "golfTennis_order",
  Vacatehouse:"vacatehouse",
  Injunction:"injunction",
  To3Pilates:"3o3_pilates",
  To3Noise:"3o3_noise",
  To3Loan:"3o3_loan",
  To3GolfTennis:"3o3_golfTennis",
  To3Etc:"3o3_etc",
  To3Estate:"3o3_estate",
  To3Pay:"3o3_pay",
  CommonOrder: "common_order",
  CommonCertification: "common_certification",
  Complaint: "complaint",
  Contract: "contract",
  Agreement: "agreement",
  Opinion: "opinion"
}

// 서류만 보내는 경우.
export function isCaseDocument(serviceType) {
    switch (serviceType) {        
        // case ServiceType.HousingPublicConveyance:
        // case ServiceType.HousingProtection:
        // case ServiceType.HousingSeizure:
        // case ServiceType.propertyDeposit:
        // case ServiceType.LendMoney:
        // case ServiceType.PilatesOrder:
        //     return false
        default:
            return true
    }
}

export function getStatusType(serviceType) {
    if (isCaseDocument(serviceType)) {
        return [
            '수임완료', '사건처리 중', '사건처리 완료'
          ]
    } else {
        return [
            '수임완료','작업시작', '사건처리 중', '사건처리 완료'
          ]
    }
}

export function getStatusValue(serviceType) {
    if (isCaseDocument(serviceType)) {
        return [
            'accept_case', 'processing', 'done'
          ]
    } else {
        return [
            'accept_case', 'processing', 'requested', 'done'
          ]
    }
}

// 내용증명인 것들.
export const isCertification = (serviceType) => {
    if ((serviceType === "lend_certification") ||  // 대여금 내용증명
    (serviceType === "housing_certification") || // 전세금 반환 내용증명
    (serviceType === "noise_certification") ||  // 층간 소음 내용증명
    (serviceType === "pilates_certification") || // 헬스/필라테스 환불 내용증명
    (serviceType === "host_cancel_certification") || // 부동산 계약해지 내용증명
    (serviceType === "host_charge_certification") || // 월세 청구 내용증명
    (serviceType === "host_notallowed_certification") || // 임대차 계약연장 불허 통지 내용증명
    (serviceType === "etc_certification") || // 기타 내용증명
    (serviceType === "golfTennis_certification") // 골프/테니스 환불 내용증명
    ) {
        return true
    } else {
        return false
    }
  }