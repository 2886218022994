export const SERVICE_QNA = {
  estate: [
    {
      title: "내용증명이란?",
      desc: "내용증명이란 '발송인이 수취인에게 어떤 내용의 문서를 언제 발송하였다'는 사실을 우체국이 증명하는 제도를 말합니다. 이를 통해, 임대인에게 계약해지 사실을 통보했음을 증명할 수 있습니다.",
    },
    {
      title: "임차권등기명령이란?",
      desc: "임대차 계약이 종료되었을 때, 보증금을 돌려받지 못하고 이사를 가게 되면 우선변제권을 상실하게 됩니다. 임차권 등기명령은 보증금을 돌려받지 못하는 임차인에게 대항력 및 우선변제권을 유지하면서 자유롭게 이사할 수 있도록 하기 위한 제도입니다.",
    },
    {
      title: "지급명령이란?",
      desc: "지급명령이란 전세금 반환을 목적으로 하는 임차인의 청구에 대해 변론을 거치지 않고 임대인에게 보증금 지급을 명하는 재판을 말합니다. 지급명령결정은 확정판결과 같은 효력이 인정됩니다.",
    },    
  ],
  estateHost: [
    {
      title: "내용증명이란?",
      desc: "내용증명이란 '발송인이 수취인에게 어떤 내용의 문서를 언제 발송하였다'는 사실을 우체국이 증명하는 제도를 말합니다. 이를 통해, 임대인에게 계약해지 사실을 통보했음을 증명할 수 있습니다.",
    },
  ],
  pilates: [
    {
      title: "내용증명이란?",
      desc: "내용증명이란 '발송인이 수취인에게 어떤 내용의 문서를 언제 발송하였다'는 사실을 우체국이 증명하는 제도를 말합니다. 이를 통해, 임대인에게 계약해지 사실을 통보했음을 증명할 수 있습니다.",
    }
  ],
  criminal: [
    {
      title: "양형자료란?",
      desc: "양형자료란 형벌을 결정하는데 참고하는 자료를 뜻하며 일반적으로 감형을 위한 자료를 의미합니다. 피의자마다 사건 정황과 상황이 다르므로, 개개인에 꼭 맞는 양형자료 준비가 필수적입니다.",
    },
    {
      title: "행정심판이란?",
      desc: "행정심판이란 행정청의 위법·부당한 처분 그밖에 공권력의 행사·불행사 등으로 권리나 이익을 침해받은 국민이 행정기관에 청구하는 권리구제 절차를 말합니다.",
    },    
  ],
  commonCriminal: [
    {
      title: "형사소송이란?",
      desc: "형사소송은 형법이나 형사특별법 등에 규정된 범죄(폭행죄, 절도죄, 사기죄, 살인죄 등)를 저지른 사람을 재판하는 절차를 뜻합니다.\n검사가 범죄혐의자(피의자)를 상대로 법원에 공소제기를 함으로써 형사 소송이 시작되는데, 크게 두 가지로 분류 가능합니다.\n1) 범죄를 저지른 사람이 다른 사람으로부터 고소 고발을 당하거나 수사기관의 인지에 의하여 사건화가 된 경우\n2) 피해자가 가해자에 대한 형사처분을 요구하며 고소 고발하는 경우\n\n심리한 결과 죄를 범하였다고 인정되면 사형, 징역형, 금고형 등의 형벌이 부과됩니다.",
    }, 
  ],
  accusation: [
    {
      title: "모욕죄란?",
      desc: "모욕죄는 공연히 사람을 모욕하여 사회적 평가를 해할 때 성립하는 범죄를 말합니다. 모욕죄는 공연성, 피해자 특정성, 모욕성이 성립되어야 합니다.",
    },
    {
      title: "명예훼손이란?",
      desc: "명예훼손은 개인, 회사, 상품, 단체 등에게 부정적인 인상을 줄 수 있는 거짓 주장, 특별히 언명되거나 사실임을 암시한 진술을 전달하는 것 혹은 남에 대한 허위사실을 유포하는 것을 말합니다.",
    },
  ],
  loan: [
    {
      title: "내용증명이란?",
      desc: "내용증명이란 '발송인이 수취인에게 어떤 내용의 문서를 언제 발송하였다'는 사실을 우체국이 증명하는 제도를 말합니다. 이를 통해, 채무자에게 대여금 반환을 통보했음을 증명할 수 있습니다.",
    },
    {
      title: "지급명령이란?",
      desc: "지급명령이란 대여금 반환을 목적으로 하는 채권자의 청구에 대해 변론을 거치지 않고 채무자에게 보증금 지급을 명하는 재판을 말합니다. 지급명령 결정은 확정판결과 같은 효력이 인정됩니다.",
    },
  ],
  sexual: [
    {
      title: "양형자료란?",
      desc: "양형자료란 형벌을 결정하는데 참고하는 자료를 뜻하며 일반적으로 감형을 위한 자료를 의미합니다. 피의자마다 사건 정황과 상황이 다르므로, 개개인에 꼭 맞는 양형자료 준비가 필수적입니다.",
    },
  ],
  civil: [
    {
      title: "민사소송이란??",
      desc: "민사소송이란 사법상의 권리 또는 법률관계에 대한 다툼을 법원이 국가의 재판권에 의해 법률적․강제적으로 해결․조정하기 위한 일련의 절차를 말합니다.",
    },
  ],
};
