import { EventDescContainer, EventDescDesc, EventDescSection, EventDescTitle } from "./EventStyle.style"

export const EventDesc = () => {
  return (
    <EventDescSection>
      <EventDescContainer>
        <EventDescTitle>
          내용 증명이란?
        </EventDescTitle>
        <EventDescDesc>
          법적 문제 해결을 위한<br />가장 간단하고 확실한 방법
        </EventDescDesc>
      </EventDescContainer>
    </EventDescSection>
  )
}