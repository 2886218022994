import styled from "@emotion/styled";

export const Container = styled.div`
  padding-bottom: 0px;  
  font-family: "Pretendard";
  font-style: normal;
  text-align: center;  
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding-top: 0px; 
  }
`;

export const MyCaseSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
  }
`;

export const ContentContainer = styled.div`
  padding-top: 44px;
  padding-bottom: 0px;
  font-family: "Pretendard";
  font-style: normal;
  width: 80%;
  text-align: center;  
  margin: 20px;
  margin-bottom: 120px;
  @media screen and (max-width: 768px) {
    padding-top: 12px;
    margin: 16px;
    width: 100%;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 17px;
  text-align: left;
`;


export const SubText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #767C87;
  margin-bottom: 35px;
  text-align: left;
`;

export const CaseDetailWrapper = styled.div`
  padding: 20px 30px;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 0px #D3DAE4;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  cursor: pointer;
  margin-bottom: 64px;
`;


export const CaseDetailWrapper2 = styled.div`
  padding: 20px 30px;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 0px #D3DAE4;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  cursor: pointer;
  margin-bottom: 64px;
`;
