import { useEffect, useState } from "react";
import IcMonthlyPaySeizureServiceMain from "assets/svg/IcMonthlyPaySeizureServiceMain.svg";
import IcMonthlyPaySeizureServiceMainMobile from "assets/svg/IcMonthlyPaySeizureServiceMainMobile.svg";


import { useRecoilValue } from "recoil";
import { userData, redirectEtcUri } from "../../../../states/atoms";
import moment from "moment";

import {
  Container,
} from "../../../Service.style";
import {
  ApplyServiceDescContainer,
  ApplyServiceDescSection,
  ApplyServiceDescText,
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
} from "../../../home/ServiceCenter.style";

import {
  DatePicker,
} from "antd";

import { RadioGroup, Radio } from "../../../home/CommonCriminal/RadioBox";
// import { CheckboxGroup, Checkbox } from "../../../home/CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { useRecoilState } from 'recoil';

import {
  Input,
  Row,
  // Radio
} from "antd";

import { removeBrTag } from "routes/Landing";
import { ApplyServiceSubmit } from "../ApplyServiceSubmit";
import { ApplyServiceInputText, ApplyServiceDatePicker, ApplyServiceSubmitDataContainer, ApplyServiceSubmitDataInfoContainer, ApplyServiceSubmitDataInfoDetailContainer, ApplyServiceSubmitDataInfoDetailTitle, ApplyServiceSubmitDataSection } from "./ApplyService.style";
import { EventFormEachDesc, EventInputTextArea, EventSubmitFooterSection, EventSubmitFooterText, EventSubmitFooterTextContainer } from "components/event/3o3/order/EventSubmit.style";

const { useHistory } = require("react-router-dom");

const ApplyMonthlyPaySeizureService = ({ loggedInUser }) => {

  // 계약 체결한 날짜 
  const [contarctDate, setContarctDate] = useState("");

  // 보증금 금액
  const [amount, setAmount] = useState(null);

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    setAmount(inputValue)
  }

  // 계약 시작한 날짜
  const [startDate, setStartDate] = useState("");

  // 계약 종료 날짜 
  const [endDate, setEndDate] = useState("");

  //계약 종료 사유
  const [endReason, setEndReason] = useState("");
  const [etcEndReason, setEtcEndReason] = useState("");

  // 계약 종료를 집주인에게 통보하였는지
  const [endNoticeHost, setEndNoticeHost] = useState("");
  // 추가적인 답.
  const [endNoticeHostDate, setEndNoticeHostDate] = useState("");

  // 계약 종료 통보 기록
  const [recordEnd, setRecordEnd] = useState("");

  const handleEtcEndReasonChange = (e) => {
    const inputValue = e.target.value;
    setEtcEndReason(inputValue)
  }


  // 보증금을 지급한 기록
  const [payRecord, setPayRecord] = useState("");

  // 전세보증 보험 가입 여부
  const [insurance, setInsurance] = useState("");

  // 집주인의 주소
  const [hostAddress, setHostAddress] = useState("");

  // 집주인의 주민번호
  const [hostId, setHostId] = useState("");


  // 현재 상황에 대한 상세 설명
  const [extraDesc, setExtraDesc] = useState("");

  const handleExtraDescChange = (e) => {
    const inputValue = e.target.value;
    setExtraDesc(inputValue)
  }

  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  const title = () => {
    const text = isMobile
      ? "전･월세 보증금\n가압류 신청하기"
      : "전･월세 보증금\n가압류 신청하기";
    return removeBrTag(text);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const serviceData = () => {
    const serviceData = {
      // 전화번호
      contact: "",
      // 첨부파일
      attachments: [],
      contract: {
        date: moment(contarctDate).format("YYYY-MM-DD"),
        deposit: {
          amount: amount,
        },
        startDate: moment(startDate).format("YYYY-MM-DD"),
      },
      depositHistory: {
        exist: payRecord === "네" ? "y" : "n"
      },
      description: extraDesc,
      insurance: {
        registYn: insurance === "네" ? "y" : "n"
      },
      lendlord: {
        addressYn: hostAddress === "네" ? "y" : "n",
        ssnYn: hostId === "네" ? "y" : "n"
      },
      termination: {
        date: moment(endDate).format("YYYY-MM-DD"),
        notification: {
          date: moment(endNoticeHostDate).format("YYYY-MM-DD"),
          exist: endNoticeHost === "네" ? "y" : "n",
          recordYn: recordEnd === "네" ? "y" : "n"
        },
        reason: endReason === "기간 만료" ? "expire" : "cancel",
        reasonDesc: etcEndReason !== "" ? etcEndReason : null,
      },
      termsOfService: true,
      userId: loggedInUser.uid
    };
    return serviceData;
  }


  const isSubmitReady = () => {
    if (contarctDate !== "" && amount !== null && startDate !== "" &&
      endDate !== "" && endReason !== "" && etcEndReason !== "" &&
      payRecord !== "" && insurance !== "" && hostAddress !== "" &&
      endNoticeHost !== "" && (endNoticeHost === "네" ? (recordEnd !== "") : true) &&
      hostId !== "") {
      return true;
    }
    return false;
  }

  const footerText = () => {
    const text = isSubmitReady()
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄져요. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "모든 항목에 답변해 주세요.\n답변이 완료되면 제출할 수 있어요.";
    return removeBrTag(text);
  }

  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcMonthlyPaySeizureServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcMonthlyPaySeizureServiceMain}
            />
            <ServiceCenterFirstImg src={IcMonthlyPaySeizureServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ApplyServiceSubmitDataSection>
        <ApplyServiceSubmitDataContainer>
          <ApplyServiceSubmitDataInfoContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약을 체결한 날짜를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={contarctDate}
                onChange={setContarctDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>보증금 금액을 적어주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceInputText
                value={amount}
                type="number"
                inputMode="numeric"
                onChange={handleAmountChange}
                placeholder="₩" />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약이 시작된 날짜를 선택해 주세요.(잔금일)</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={startDate}
                onChange={setStartDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약이 종료된 날짜를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={endDate}
                onChange={setEndDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약 종료 사유를 알려주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={endReason}
                onChange={setEndReason}
              >
                <Radio value="기간 만료">기간 만료</Radio>
                <Radio value="계약 해지">계약 해지</Radio>
              </RadioGroup>
              <ApplyServiceInputText value={etcEndReason} onChange={handleEtcEndReasonChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                계약 종료를 집주인에게 통보 하셨나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>통보 하셨다면, 통보하신 날짜를 선택해 주세요.</EventFormEachDesc>
              <RadioGroup
                label=""
                value={endNoticeHost}
                onChange={setEndNoticeHost}
              >
                <Radio value="네">네</Radio>
                {endNoticeHost.includes("네") && <>
                  <ApplyServiceDatePicker
                    style={{ width: "100%" }}
                    placeholder="년-월-일"
                    value={endNoticeHostDate}
                    onChange={setEndNoticeHostDate}
                  />
                </>}
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            {endNoticeHost === "네" && <>
              <ApplyServiceSubmitDataInfoDetailContainer>
                <ApplyServiceSubmitDataInfoDetailTitle>
                  계약 종료를 통보한 기록이 있으신가요?
                </ApplyServiceSubmitDataInfoDetailTitle>
                <EventFormEachDesc>계약종료 통보 내용과 임대인의 확인내용이<br />문자나 녹취 등의 형태로 있어야 합니다.</EventFormEachDesc>
                <RadioGroup
                  label=""
                  value={recordEnd}
                  onChange={setRecordEnd}
                >
                  <Radio value="네">네</Radio>
                  <Radio value="아니요">아니요</Radio>
                </RadioGroup>
              </ApplyServiceSubmitDataInfoDetailContainer>
            </>}

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                보증금을 지급한 기록이 있나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>집주인에게 보증금을 지급 했음을 증명할 수 있는<br />자료가 있는지 여부를 확인해주세요</EventFormEachDesc>
              <RadioGroup
                label=""
                value={payRecord}
                onChange={setPayRecord}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                전세보증 보험에 가입이 되어 있으신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={insurance}
                onChange={setInsurance}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                집주인의 주소를 알고 계신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={hostAddress}
                onChange={setHostAddress}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                집주인의 주민번호를 알고 계신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={hostId}
                onChange={setHostId}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>그 외 특이사항이 있나요?</ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>기타 입력하신 내용 외 특이사항이 있을 경우 작성해 주세요.</EventFormEachDesc>
              <EventInputTextArea value={extraDesc} onChange={handleExtraDescChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
          </ApplyServiceSubmitDataInfoContainer>
        </ApplyServiceSubmitDataContainer>
      </ApplyServiceSubmitDataSection>
      <EventSubmitFooterSection active={isSubmitReady()}>
        <EventSubmitFooterTextContainer>
          <EventSubmitFooterText active={isSubmitReady()}>
            {footerText()}
          </EventSubmitFooterText>
        </EventSubmitFooterTextContainer>
      </EventSubmitFooterSection>
      <ApplyServiceSubmit
        active={isSubmitReady()}
        serviceData={serviceData()}
        serviceType={"housing_seizure"}
        uid={loggedInUser.uid}
      />
    </Container>
  )
};

export default ApplyMonthlyPaySeizureService;
