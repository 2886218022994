import Step1 from "components/form/crime/drunkDriving/Step1";
import Step2 from "components/form/crime/drunkDriving/Step2";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "fbase";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

const DrunkDriving = ({ loggedInUser }) => {
  const [page, setPage] = useState(0);
  const userInfo = useRecoilValue(userData);
  const history = useHistory();
  const key = "updatable";

  const step1DefaultValues = {
    date: null,
    history: {
      isFirst: "y",
      cnt: null,
      lastCrimeYear: null,
    },
    casualties: {
      exist: "n",
      agreement: null,
    },
    propertyDamage: {
      exist: "n",
      agreement: null,
    },
  };

  const step2DefaultValues = {
    description: null,
    promotionCode: null,
    dept: null,
    drinkReason: null,
  };

  const itemLayout = {
    form: {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 16,
      },
    },
    button: {
      wrapperCol: { span: 24, offset: 0 },
    },
  };

  const [step1Data, setStep1Data] = useState(step1DefaultValues);
  const [step2Data, setStep2Data] = useState(step2DefaultValues);

  const [dbUpdate, setDbUpdate] = useState(false);

  const onPrev = () => {
    setPage(page - 1);
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const onSubmit = async () => {
    const drunkDrivingData = {
      ...step1Data,
      ...step2Data,
      userId: loggedInUser.uid,
      date: step1Data.date ? moment(step1Data.date).format("YYYY-MM-DD") : null,
    };

    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "drunk_driving",
        serviceId: uuid,
        service_data: drunkDrivingData,
        createdAt: Date.now(),
        userId: loggedInUser.uid,
        clientName: userInfo.name,
        status: "submitted",
      });
      history.push("/submit/success");
      message.success({
        content: "제출 완료!",
        key,
        duration: 2,
      });
    } catch (err) {
      history.push("/submit/fail");
      message.error({
        content: "제출 실패!",
        key,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    if (dbUpdate) {
      onSubmit();
    }
  }, [dbUpdate]);

  const steps = [
    {
      title: "음주운전",
      content: (
        <Step1
          data={step1Data}
          setData={setStep1Data}
          onNext={onNext}
          // setDbUpdate={setDbUpdate}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "음주운전",
      content: (
        <Step2
          data={step2Data}
          setData={setStep2Data}
          // onNext={onSubmit}
          onPrev={onPrev}
          setDbUpdate={setDbUpdate}
          itemLayout={itemLayout}
        />
      ),
    },
  ];

  return (
    <>
      <div className="steps-content">{steps[page].content}</div>
    </>
  );
};

export default DrunkDriving;
