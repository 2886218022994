import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Radio,
  InputNumber,
  Popover,
  Divider,
  Row,
  Col,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper2,
} from "../../Form.style";


const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setData(values);
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        음주운전 사건정보
      </Title>

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          음주운전 사건에 대한 기본 정보를 작성해주세요.
          <br />
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper2>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          form={form}
          style={{
            marginTop: 10,
            marginBottom: 20,
            width:"100%",
            textAlign:"left"
          }}
          requiredMark={false}
        >
          <Form.Item
            label="사건 발생일"
            name={["date"]}
            rules={[
              {
                required: true,
                message: "사건 발생일을 선택해주세요",
              },
            ]}
          >
            <DatePicker
              style={{ width: "70%" }}
              placeholder="날짜를 선택하세요"
            />
          </Form.Item>

          <Form.Item
            label="혈중 알콜 농도"
            name={["bloodAlcholLevel"]}
            rules={[
              {
                required: true,
                message: "혈중알콜농도 수치를 입력해주세요",
              },
            ]}
          >
            <InputNumber
              style={{
                width: "40%",
                maxWidth: 120,
              }}
              addonAfter="%"
            />
          </Form.Item>

          <Form.Item
            label="인명피해"
            name={["casualties", "exist"]}
            rules={[
              {
                required: true,
                message: "인명피해 유무를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">있음</Radio.Button>
              <Radio.Button value="n">없음</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return (
                prevValues.casualties.exist !== currentValues.casualties.exist
              );
            }}
          >
            {() => (
              <>
                <Form.Item
                  label="인명피해 합의여부"
                  rules={
                    form.getFieldValue(["casualties", "exist"]) === "n"
                      ? []
                      : [
                          {
                            required: true,
                            message: "합의여부를 입력해주세요",
                          },
                        ]
                  }
                  hidden={form.getFieldValue(["casualties", "exist"]) === "n"}
                  name={["casualties", "agreement"]}
                >
                  <Radio.Group>
                    <Radio.Button value="y">합의</Radio.Button>
                    <Radio.Button value="n">합의안됨</Radio.Button>
                    <Radio.Button value="etc">기타</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
          </Form.Item>

          <Form.Item
            label="대물피해"
            name={["propertyDamage", "exist"]}
            rules={[
              {
                required: true,
                message: "대물피해 유무를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">있음</Radio.Button>
              <Radio.Button value="n">없음</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return (
                prevValues.propertyDamage.exist !==
                currentValues.propertyDamage.exist
              );
            }}
          >
            {() => (
              <>
                <Form.Item
                  label="대물피해 합의여부"
                  rules={
                    form.getFieldValue(["propertyDamage", "exist"]) === "n"
                      ? []
                      : [
                          {
                            required: true,
                            message: "합의여부를 입력해주세요",
                          },
                        ]
                  }
                  hidden={
                    form.getFieldValue(["propertyDamage", "exist"]) === "n"
                  }
                  name={["propertyDamage", "agreement"]}
                >
                  <Radio.Group>
                    <Radio.Button value="y">합의</Radio.Button>
                    <Radio.Button value="n">합의안됨</Radio.Button>
                    <Radio.Button value="etc">기타</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
          </Form.Item>

          <Form.Item
            label={
              <>
                수사/재판 단계
                <Popover
                  content={
                    <div>현재 의뢰인의 수사/재판 단계 상태를 선택해주세요</div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["currentStep"]}
            rules={[
              {
                required: true,
                message: "현재 수사/재판 단계를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="police">경찰조사</Radio.Button>
              <Radio.Button value="prosecution">검찰조사</Radio.Button>
              <Radio.Button value="trial">공판준비</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="음주운전 이력"
            name={["history", "isFirst"]}
            rules={[
              {
                required: true,
                message: "과거 음주운전 이력 유무를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">초범</Radio.Button>
              <Radio.Button value="n">재범</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return (
                prevValues.history.isFirst !== currentValues.history.isFirst
              );
            }}
          >
            {() => (
              <>
                <Form.Item
                  label="과거 이력"
                  rules={
                    form.getFieldValue(["history", "isFirst"]) === "y"
                      ? []
                      : [
                          {
                            required: true,
                            message: "음주운전 횟수를 입력해주세요",
                          },
                        ]
                  }
                  hidden={form.getFieldValue(["history", "isFirst"]) === "y"}
                  name={["history", "cnt"]}
                >
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    style={{
                      width: "40%",
                      maxWidth: 120,
                    }}
                    addonAfter="회"
                    controls={false}
                  />
                </Form.Item>

                <Form.Item
                  label="마지막 처벌 년도"
                  rules={
                    form.getFieldValue(["history", "isFirst"]) === "y"
                      ? []
                      : [
                          {
                            required: true,
                            message: "마지막 처벌 년도를 입력해주세요",
                          },
                        ]
                  }
                  hidden={form.getFieldValue(["history", "isFirst"]) === "y"}
                  name={["history", "lastCrimeYear"]}
                >
                  <InputNumber
                    style={{
                      width: "40%",
                      maxWidth: 120,
                    }}
                    addonAfter="년"
                    controls={false}
                  />
                </Form.Item>
              </>
            )}
          </Form.Item>

          <Row justify="space-between" style={{ marginTop: 20 }}>
            <Col span={6} offset={18} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                다음
              </Button>
            </Col>
          </Row>
        </Form>
        </CaseDetailWrapper2>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step1;
