import styled from "@emotion/styled";

import ReactModal from 'react-modal';
import Slider from "react-slick";
import { Page } from 'react-pdf';

export const CustomModal = styled(ReactModal)``;

export const PreviewSlider = styled(Slider)`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // .slick-track {
  //   display: flex;
  //   width: auto;
  // }
  // .slick-track .slick-slide {
  //   display: flex;
  //   height: auto;    
  // }
`;


export const ModalContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #red;
`;

export const ModalContentContainer = styled.div`
  margin: 0 auto;
  background: #E9E9E9;
  height: 751px;
`;

export const ModalHeader = styled.header`
    height: 73px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ModalHeaderBackWrapper = styled.div`
    // margin-left: 6px;
    width: 48px;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const ModalHeaderBack = styled.img`
    width: 7px;
    height: 17px;
`

export const ModalHeaderTitle = styled.div`
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`

export const DownModalHeaderBackWrapper = styled.div`
    padding-left: 25px;
    width: 128px;
    height: 73px;
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const ModalHeaderDownloadWrapper = styled.div`
  padding-right: 43px;
  display: flex;
  align-items: center;
  gap: 11px;
  width: 128px;
  height: 100%;
  cursor: pointer;
`

export const ModalHeaderDownloadTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const ModalHeaderDownloadImg = styled.img`
  width: 18px;
  height: 17px;
`

export const NotSubMittedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 751px;
`

export const NotSubMittedContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 751px;
`

export const SubMittedContentDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #D9D9D9;
`

export const SubMittedContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 796px;
  background: #000;
`

export const SubMittedContentScrollableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const SubMittedContentScrollableImg = styled.img`
  width: 100%;
  height: auto;
`

export const SubmitPage = styled(Page)`
  width: 100%;
  height: auto;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  background: #fff;
`
export const SubmittedButton = styled.button`
  color: #3A7EFC;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  
  width: 100%;
  height: 45px;
  background: #fff;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const SubmittedButtonDivider = styled.div`
  width: 1px;
  height: 48px;
  background: #CACFD7;
`

export const SubmittedButtonWrapperDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #D9D9D9;
`

export const NotSubMittedContentContainer = styled.div`
  display: flex;
  width: 448px;
  padding: 28px 34px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 29px;
  border-radius: 16px;
  background: #FFF;
`

export const NotSubMittedTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
`

export const NotSubMittedSuppertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
`

export const NotSubMittedSuppertImg = styled.img`
  width: 50px;
  height: 50px;
`

export const NotSubMittedSuppertText = styled.div`
  color: #A9A9A9;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; /* 153.333% */
`

export const NotSubMittedDataContentDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #D9D9D9;
`

export const NotSubMittedDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 380px;
  gap: 10px;
`

export const NotSubMittedDataTitle = styled.div`
  color: #747474;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const NotSubMittedDataDesc = styled.div`
  color: #747474;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const NotSubmittedUploadWrapper = styled.button`
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: 100%;
  height: 45px;
  border-radius: 4px;
  background: #000;
  outline: none;
  border: none;
  cursor: pointer;
`