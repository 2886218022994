import { useEffect, useState } from "react";
import IcMain from "../../../../assets/svg/IcEtcServiceMain.svg";
import IcMainMobile from "../../../../assets/svg/IcEtcServiceMainMobile.svg";


import { useRecoilValue } from "recoil";
import { userData, redirectEtcUri } from "../../../../states/atoms";

import {
  Container,
} from "../../../Service.style";
import {
  ApplyServiceDescContainer,
  ApplyServiceDescSection,
  ApplyServiceDescText,
  ServiceCenterDescContainer,
  ServiceCenterDescSection,
  ServiceCenterDescText,
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
} from "../../../home/ServiceCenter.style";

import { RadioGroup, Radio } from "../../../home/CommonCriminal/RadioBox";
import { CheckboxGroup, Checkbox } from "../../../home/CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { ApplyServiceInputText, ApplyServiceSubmitDataContainer, ApplyServiceSubmitDataInfoContainer, ApplyServiceSubmitDataInfoDetailContainer, ApplyServiceSubmitDataInfoDetailTitle, ApplyServiceSubmitDataSection } from "./ApplyService.style";
import { EventFormEachDesc, EventInputTextArea, EventSubmitFooterSection, EventSubmitFooterText, EventSubmitFooterTextContainer } from "components/event/3o3/order/EventSubmit.style";

import { useRecoilState } from 'recoil';

import {
  Input,
} from "antd";

import { removeBrTag } from "routes/Landing";
import { ApplyServiceSubmit } from "../ApplyServiceSubmit";

const { useHistory } = require("react-router-dom");

const ApplyEtcService = ({ loggedInUser }) => {

  // 현재 상황에 대한 상세 설명
  const [caseDesc, setCaseDesc] = useState("");

  const handleDescChange = (e) => {
    const inputValue = e.target.value;
    setCaseDesc(inputValue)
  }

  // 의뢰인이 원하는 해결방향
  const [solutionType, setSolutionType] = useState("");
  const [etcSolution, setEtcSolution] = useState("");

  const handleEtcSolutionChange = (e) => {
    const inputValue = e.target.value;
    setEtcSolution(inputValue)
  }

  // 의뢰인이 원하는 결과
  const [desiredResult, setDesiredResult] = useState([]);
  const [etcDesiredResult, setEtcDesiredResult] = useState("");

  const handleEtcDesiredResultChange = (e) => {
    const inputValue = e.target.value;
    setEtcDesiredResult(inputValue);
  }

  // 변호사에게 도움 받고 싶은 내용
  const [desiredHelp, setDesiredHelp] = useState("");
  const [etcDesiredHelp, setEtcDesiredHelp] = useState("");

  const handleEtcDesiredHelpChange = (e) => {
    const inputValue = e.target.value;
    setEtcDesiredHelp(inputValue);
  }


  const footerText = () => {
    const text = isSubmitReady()
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄져요. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "모든 항목에 답변해 주세요.\n답변이 완료되면 제출할 수 있어요.";
    return removeBrTag(text);
  }

  const serviceData = () => {
    const serviceData = {
      // 전화번호
      contact: "",
      // 첨부파일
      attachments: [],
      solutionType: solutionType,
      etcSolution: solutionType.includes("직접 입력") ? etcSolution : "",
      desiredResult: desiredResult,
      etcDesiredResult: desiredResult.includes("직접 입력") ? etcDesiredResult : "",
      desiredHelp: desiredHelp,
      etcDesiredHelp: desiredHelp.includes("직접 입력") ? etcDesiredHelp : "",
      desc: caseDesc,
      userId: loggedInUser.uid
    };
    return serviceData;
  }

  const isSubmitReady = () => {
    if (caseDesc !== "" && solutionType !== "" && desiredResult.length !== 0 && desiredHelp !== "") {
      return true
    }
    return false
  }

  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  const title = () => {
    const text = isMobile
      ? "기타 법률 문의하기"
      : "기타 법률 문의하기";
    return removeBrTag(text);
  };

  const title1 = () => {
    const text = isMobile
      ? "원하시는 해결 방안을\n선택해주세요."
      : "원하시는 해결 방안을 선택해주세요.";
    return removeBrTag(text);
  };

  const desc = () => {
    const text = isMobile
      ? "기타 법률 문의하기 서비스는 법대리 간편 서비스에서 원하시는 항목을 찾지 못하신 고객님들을 위한 페이지 입니다."
      : "기타 법률 문의하기 서비스는 법대리 간편 서비스에서 원하시는\n항목을 찾지 못하신 고객님들을 위한 페이지 입니다.";
    return removeBrTag(text);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (checkedValues) => {
    console.log(checkedValues)
  };

  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcMain}
            />
            <ServiceCenterFirstImg src={IcMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterDescSection>
        <ServiceCenterDescContainer>
          <ServiceCenterDescText>
            {desc()}
          </ServiceCenterDescText>
        </ServiceCenterDescContainer>
      </ServiceCenterDescSection>
      <ApplyServiceSubmitDataSection>
        <ApplyServiceSubmitDataContainer>
          <ApplyServiceSubmitDataInfoContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>문의하고 싶은 상황을 구체적으로 적어주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>상세 설명은 육하원칙에 따라 간결하게 작성해 주셔야 변호사 검토가 빠르게 이뤄집니다.</EventFormEachDesc>
              <EventInputTextArea value={caseDesc} onChange={handleDescChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                {title1()}
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={solutionType}
                onChange={setSolutionType}
              >
                <Radio value="소송 없이 합의">소송 없이 합의</Radio>
                <Radio value="소송 진행">소송 진행</Radio>
                <Radio value="직접 입력">직접 입력</Radio>
                {solutionType.includes("직접 입력") && <>
                  <ApplyServiceInputText value={etcSolution} onChange={handleEtcSolutionChange} placeholder="내용을 입력해주세요." />
                </>}
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                원하시는 결과를 선택해 주세요.
                <br />(복수 선택 가능)
              </ApplyServiceSubmitDataInfoDetailTitle>
              <CheckboxGroup
                label=""
                values={desiredResult}
                onChange={setDesiredResult}
              >
                <Checkbox value="진정한 사과, 또는 처벌">진정한 사과, 또는 처벌</Checkbox>
                <Checkbox value="피해입은 부분의 금전적 보상">피해입은 부분의 금전적 보상</Checkbox>
                <Checkbox value="직접 입력">
                  직접입력
                </Checkbox>
                {desiredResult.includes("직접 입력") && <>
                  <ApplyServiceInputText
                    style={{ width: "100%" }}
                    value={etcDesiredResult} onChange={handleEtcDesiredResultChange} placeholder="내용을 입력해주세요." />
                </>}
              </CheckboxGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                변호사에게 어떤 도움을 받고 싶으신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={desiredHelp}
                onChange={setDesiredHelp}
              >
                <Radio value="서류(내용증명 등) 작성">서류(내용증명 등) 작성</Radio>
                <Radio value="서류 작성 외 추가적인 상담">서류 작성 외 추가적인 상담</Radio>
                <Radio value="직접 입력">직접 입력</Radio>
                {desiredHelp.includes("직접 입력") && <>
                  <ApplyServiceInputText style={{ width: "100%" }} value={etcDesiredHelp} onChange={handleEtcDesiredHelpChange} placeholder="내용을 입력해주세요." />
                </>}
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
          </ApplyServiceSubmitDataInfoContainer>
        </ApplyServiceSubmitDataContainer>
      </ApplyServiceSubmitDataSection>
      <EventSubmitFooterSection active={isSubmitReady()}>
        <EventSubmitFooterTextContainer>
          <EventSubmitFooterText active={isSubmitReady()}>
            {footerText()}
          </EventSubmitFooterText>
        </EventSubmitFooterTextContainer>
      </EventSubmitFooterSection>
      <ApplyServiceSubmit
        active={isSubmitReady()}
        serviceData={serviceData()}
        serviceType={"common_etc"}
        uid={loggedInUser.uid}
      />
    </Container>
  )
};

export default ApplyEtcService;
