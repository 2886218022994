import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Radio,
  Checkbox,
  DatePicker,
  InputNumber,
  Popover,
  Divider,
  Row,
  Col,
} from "antd";
import React, { useEffect } from "react";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper,
} from "../../Form.style";

const defaultLendInfo = {
  lendDate: {
    value: null,
    unknown: false,
  },
  dueDate: {
    value: null,
    unknown: false,
  },
  amount: null,
  interests: {
    exist: "n",
    unit: "year",
    value: null,
  },
};

const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log("values:", values);
    setData(values);
    onNext();
  };

  const onDueDateUnknownChanged = (name) => {
    const { loans } = form.getFieldsValue();
    loans[name].dueDate.value = null;
    form.setFieldsValue({ loans });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        대여금 정보
      </Title>

      {/* <Popover
        content={
          <div>
            상대에게 돈을 빌려준 내용에 대하여 작성하세요.
            <br />
            여러번에 걸쳐 빌려준 경우 아래의 빌려준 돈 정보
            <br /> 추가하기 버튼을 사용하세요.
          </div>
        }
        placement="bottom"
        trigger="click"
      >
        <QuestionCircleTwoTone />
      </Popover> */}

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          상대에게 돈을 빌려준 내용에 대하여 작성하세요.
          <br /> 여러번에 걸쳐 빌려준 경우, <br />
          아래의 빌려준 돈 정보 추가하기 버튼을 사용하세요.
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper>
      <Form
        {...itemLayout.form}
        layout="horizontal"
        form={form}
        initialValues={data}
        onFinish={onFinish}
        style={{
          marginTop: 20,
          marginBottom: 20,
          width:"100%",
          textAlign:"left"
        }}
        requiredMark={false}
      >
        <Form.List name="loans">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      backgroundColor: "white",
                      padding: "15px",
                      marginBottom: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    {name === 0 ? null : (
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    )}
                    <Form.Item
                      label="빌려준 날짜"
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Form.Item
                        style={{
                          display: "inline-block",
                          width: "calc(85% - 8px)",
                          marginBottom: 0,
                        }}
                        shouldUpdate={(prevValues, curValues) => {
                          if (prevValues.loans[name] && curValues.loans[name]) {
                            return (
                              prevValues.loans[name].lendDate.unknown !==
                              curValues.loans[name].lendDate.unknown
                            );
                          }
                          return false;
                        }}
                      >
                        {() => (
                          <Form.Item
                            name={[name, "lendDate", "value"]}
                            rules={
                              !form.getFieldValue([
                                "loans",
                                name,
                                "lendDate",
                                "unknown",
                              ])
                                ? [
                                    {
                                      required: true,
                                      message: "날짜를 선택해주세요",
                                    },
                                  ]
                                : []
                            }
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              disabled={form.getFieldValue([
                                "loans",
                                name,
                                "lendDate",
                                "unknown",
                              ])}
                              placeholder="날짜를 선택하세요"
                            />
                          </Form.Item>
                        )}
                      </Form.Item>

                      {/* <Form.Item
                        name={[name, "lendDate", "unknown"]}
                        style={{
                          display: "inline-block",
                          width: "calc(30% - 8px)",
                          margin: "0 8px",
                        }}
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={() => onLendDateUnknownChanged(name)}
                        >
                          모름
                        </Checkbox>
                      </Form.Item> */}
                    </Form.Item>

                    <Form.Item
                      label="빌려준 금액"
                      name={[name, "amount"]}
                      rules={[{ required: true, message: "금액을 입력하세요" }]}
                    >
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        addonBefore="금"
                        addonAfter="원"
                        controls={false}
                      />
                    </Form.Item>

                    <Form.Item
                      label={
                        <>
                          이자 청구
                          <Popover
                            content={
                              <div>
                                돈을 빌려줄때 이자를 받기로 한 경우, '예'를 눌러
                                이율을 입력하세요.
                                <br />
                                (이율은 연 20%내에서 유효하고, 일반적으로 이자에
                                대한 얘기가 없었던 경우도 5%의 이자를 청구할 수
                                있습니다)
                              </div>
                            }
                            placement="bottom"
                            trigger="click"
                          >
                            <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                          </Popover>
                        </>
                      }
                      name={[name, "interests", "exist"]}
                      rules={[{ required: true, message: "" }]}
                    >
                      <Radio.Group>
                        <Radio.Button value="y">예</Radio.Button>
                        <Radio.Button value="n">아니오</Radio.Button>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => {
                        if (
                          prevValues.loans[name] &&
                          currentValues.loans[name]
                        ) {
                          return (
                            prevValues.loans[name].interests.exist !==
                            currentValues.loans[name].interests.exist
                          );
                        }
                        return false;
                      }}
                    >
                      {() => (
                        <>
                          <Form.Item
                            label="이율"
                            style={{ marginBottom: 0 }}
                            hidden={
                              form.getFieldValue([
                                "loans",
                                name,
                                "interests",
                                "exist",
                              ]) === "n"
                            }
                          >
                            {/* <Form.Item
                              name={[name, "interests", "unit"]}
                              style={{
                                width: "20%",
                                maxWidth: 80,
                                display: "inline-block",
                              }}
                              rules={
                                form.getFieldValue([
                                  "loans",
                                  name,
                                  "interests",
                                  "exist",
                                ]) === "n"
                                  ? []
                                  : [{ required: true, message: "" }]
                              }
                            >
                              <Select>
                                <Select.Option value="year">연</Select.Option>
                                <Select.Option value="month">월</Select.Option>
                              </Select>
                            </Form.Item> */}
                            <Form.Item
                              name={[name, "interests", "value"]}
                              style={{
                                width: "80%",
                                // maxWidth: 150,
                                display: "inline-block",
                              }}
                              rules={
                                form.getFieldValue([
                                  "loans",
                                  name,
                                  "interests",
                                  "exist",
                                ]) === "n"
                                  ? []
                                  : [
                                      {
                                        required: true,
                                        message: "이율을 입력해주세요",
                                      },
                                    ]
                              }
                            >
                              <InputNumber
                                addonBefore="연"
                                addonAfter="%"
                                controls={false}
                              />
                            </Form.Item>
                          </Form.Item>
                        </>
                      )}
                    </Form.Item>

                    <Form.Item
                      label="갚기로 한 날짜"
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Form.Item
                        style={{
                          display: "inline-block",
                          width: "calc(85% - 8px)",
                          marginBottom: 0,
                        }}
                        shouldUpdate={(prevValues, curValues) => {
                          if (prevValues.loans[name] && curValues.loans[name]) {
                            return (
                              prevValues.loans[name].dueDate.unknown !==
                              curValues.loans[name].dueDate.unknown
                            );
                          }
                          return false;
                        }}
                      >
                        {() => (
                          <Form.Item
                            name={[name, "dueDate", "value"]}
                            rules={
                              !form.getFieldValue([
                                "loans",
                                name,
                                "dueDate",
                                "unknown",
                              ])
                                ? [
                                    {
                                      required: true,
                                      message: "날짜를 선택해주세요",
                                    },
                                  ]
                                : []
                            }
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              disabled={form.getFieldValue([
                                "loans",
                                name,
                                "dueDate",
                                "unknown",
                              ])}
                              placeholder="날짜를 선택하세요"
                            />
                          </Form.Item>
                        )}
                      </Form.Item>

                      <Form.Item
                        name={[name, "dueDate", "unknown"]}
                        style={{
                          display: "inline-block",
                          width: "calc(30% - 8px)",
                          margin: "0 8px",
                        }}
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={() => onDueDateUnknownChanged(name)}
                        >
                          없음
                        </Checkbox>
                      </Form.Item>
                    </Form.Item>
                  </div>
                ))}

                <Form.Item {...itemLayout.button}>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add(defaultLendInfo);
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    빌려준 돈 정보 추가하기
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>

        <Row justify="space-between">
          <Col span={6} offset={18} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", maxWidth: "100px" }}
            >
              다음
            </Button>
          </Col>
        </Row>
      </Form>
      </CaseDetailWrapper>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step1;
