import { dbService } from "fbase";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Divider, Typography, Button } from "antd";
import * as Sentry from "@sentry/react";
const Title = Typography.Title;

const AdminHome = ({ loggedInUser }) => {
  const history = useHistory();

  useEffect(() => {
    let query = dbService
      .collection("admins")
      .where("__name__", "==", loggedInUser.uid);

    // console.log(loggedInUser.uid);
    query
      .get()
      .then((snapshot) => {
        return null;
      })
      .catch(() => {
        history.push("/");
      });
  }, []);

  const onClickSentryError = () => {
    Sentry.captureException(`Admin Sentry Exception Text`);
  }

  const onClickSentryMessage = () => {
    Sentry.captureMessage(`Admin Sentry Message Text`);
  }

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        법대리 짱짱맨
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <Button type="primary">
        <Link to={"/admin/submitted"}>제출완료</Link>
      </Button>
      <Button type="primary">
        <Link to={"/admin/replied"}>답변완료</Link>
      </Button>
      <Button type="primary">
        <Link to={"/admin/paid"}>결제완료</Link>
      </Button>
      <Button type="primary">
        <Link to={"/admin/requested"}>서류요청</Link>
      </Button>
      <Button type="primary">
        <Link to={"/admin/processing"}>요청 처리중</Link>
      </Button>
      <Button type="primary">
        <Link to={"/admin/done"}>처리완료</Link>
      </Button>
      <div>
        <Button onClick={() => {onClickSentryMessage()}}>
          Sentry 메세지 테스트
        </Button>
        <Button onClick={() => {onClickSentryError()}}>
          Sentry 익셉션 테스트
        </Button>
      </div>
    </>
  );
};

export default AdminHome;
