import { useEffect, useState } from "react";
import { message } from "antd";
import ProfileAcceptedDefault from "assets/svg/ProfileAcceptedDefault.svg";
import {
  BtnDelegate,
  BtnPay,
  BtnWrapper,
  Container,
  ContentContainer,
  ContentDivier,
  ContentTitle,
  DateText,
  DelegateContentTitle,
  DelegateDesc,
  DelegateMandateWrapper,
  DelegateMandateTitle,
  DelegateMandateDesc,
  DelegateMandateNameInput,
  DelegateSection,
  DelegateWrapper,
  LawyerAffiliation,
  LawyerInfoWrapper,
  LawyerName,
  LawyerOpinionSection,
  LawyerProfile,
  OpinioContent,
  OpinioHeader,
  OpinioWrapper,
  PayBaseWrapper,
  PaySection,
  PayLabel,
  PayValue,
  PayWrapper,
  RefundWrapper,
  RefundText,
  Title,
  DelegateBase,
} from "./LawyerOpinion.style";

import { dbService } from "fbase";
import axios from "axios";
import { getFormattedCaseDate } from "util/util";
import RefundCollapse from "components/common/RefundCollapse";
import { useRecoilState } from "recoil";
import { currentMyCaseState, selectOpinionState } from "states/atoms";

const qs = require("qs");

const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) || // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const LawyerOpinion = ({ loggedInUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [lawyer, setLawyer] = useState(null);
  const [mandator, setMandator] = useState(undefined);
  const [isMandatorDone, setIsMandatorDone] = useState(false);
  const [isInputClicked, setIsInputClicked] = useState(false);
  const [currentMyCase, setCurrentMyCase] = useRecoilState(currentMyCaseState);
  const [selectedOpinion, setSelectedOpinion] = useRecoilState(selectOpinionState);

  useEffect(() => {
    setMandator(currentMyCase.mandator);    
    setIsLoading(false);
  }, [currentMyCase, lawyer]);

  useEffect(() => {
    getLawyer(selectedOpinion.lawyer_id);
  }, []);

  async function getLawyer(id) {
    if (id === undefined) {
      return;
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_LAYWER_URL
    const query = "?requestId=" + id;
    const url = host + query;
    try {
      const { data } = await axios.get(url);
      setLawyer(data);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  const kakaoPayConfig = {
    adminKey: process.env.REACT_APP_KAKAO_ADMIN_KEY,
    cid: process.env.REACT_APP_KAKAO_PAY_CID,
    approval_url: process.env.REACT_APP_KAKAO_PAY_APPROVAL_URL,
    fail_url: process.env.REACT_APP_KAKAO_PAY_FAIL_URL,
    cancel_url: process.env.REACT_APP_KAKAO_PAY_CANCEL_URL,
  };

  const onPayClick = async () => {
    // console.log("selectedOpinion",selectedOpinion);
    const res = await fetch("https://kapi.kakao.com/v1/payment/ready", {
      method: "POST",
      cache: "no-cache",
      headers: {
        Authorization: "KakaoAK " + kakaoPayConfig.adminKey,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: qs.stringify({
        cid: kakaoPayConfig.cid,
        partner_order_id: currentMyCase.requestId,
        partner_user_id: currentMyCase.userId,
        item_name: "법대리",
        quantity: 1,
        total_amount: Number(selectedOpinion.suggested_amount),
        tax_free_amount: 0,
        approval_url: kakaoPayConfig.approval_url + "/" + currentMyCase.requestId,
        fail_url: kakaoPayConfig.fail_url + "/" + currentMyCase.requestId,
        cancel_url: kakaoPayConfig.cancel_url + "/" + currentMyCase.requestId,
      }),
    });

    const payReadyResponse = await res.json();
    if (res.status === 200) {
      try {
        await dbService
          .collection("requests_temp")
          .doc(currentMyCase.requestId)
          .update({ tid: payReadyResponse.tid, pendingLawyer: {
              id: selectedOpinion.lawyer_id, 
              name: selectedOpinion.lawyer_name,
              proposalId: selectedOpinion.id
            } 
          });
      } catch (error) {
        console.log("errorerror????????", error);
        message.error({
          content: "결제 요청 실패",
          duration: 2,
        });
      }

      if (isMobile()) {
        window.location.replace(payReadyResponse.next_redirect_mobile_url);
      } else {
        window.location.replace(payReadyResponse.next_redirect_pc_url);
      }
    } else {
      message.error({
        content: "결제 요청 실패",
        duration: 2,
      });
    }
  };

  const onDelegateClick = async () => {
    const newDate = new Date();
      const date = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      await dbService
      .collection("requests_temp")
      .doc(currentMyCase.requestId)
      .update({ 
        mandator: mandator,
          mandateDate: {
            year: year,
            month: month,
            date: date,
          }
       });      
    setIsMandatorDone(true);
  }

  const handleMandatorChange = (e) => {
    const inputValue = e.target.value;
    setMandator(inputValue)
  }

  const getMandatorButtonState = () => {
    // 위임장 서명 작성 안한경우
    if ((mandator !== "") || (mandator !== undefined)) {
      // 서명 후 버튼까지 클릭한 경우
      if (isMandatorDone) {
        return "done"
      } else {
        if (currentMyCase.mandateDate !== undefined) {
          return "done"
        }
        return "active"
      }
    } else {
      return "empty"
    }
  }

  const setMandatorButtonTitle = () => {
    if (getMandatorButtonState() === "done") {
      return "위임이 완료되었습니다!"
    } else {
      return "위임하기"
    }    
  }

  const setPayButtonStatus = () => {
    if (getMandatorButtonState() === "done") {
      return "active"
    } else {
      return "disabled"
    }    
  }

  const setLayerNameAff = () => {
    if (lawyer) {
    const affiliation = lawyer.lawyer_affiliation ?? ""
    const name = lawyer.name ?? ""
    return affiliation + " " + name + " 변호사"
    } else {
      return "심앤이 법률사무소 이지훈 변호사"
    }
  }

  const setLayerAddress1 = () => {
    if (lawyer) {
      if (lawyer.address1) {
      const address1 = lawyer.address1 ?? ""
      return address1
      } else {
        return "서울특별시 강남구 테헤란로 447"
      }
    } else {
      return "서울특별시 강남구 테헤란로 447"
    }
  }

  const setLayerAddress2 = () => {
    if (lawyer) {
      if (lawyer.address2) {
      const address2 = lawyer.address2 ?? ""
      return address2
      } else {
        return "(삼성동, KB 우준타워)"
      }
    } else {
      return "(삼성동, KB 우준타워)"
    }    
  }


  if (isLoading || !selectedOpinion) return null;
  if (currentMyCase === undefined) return null;

  const { title, proposal_date } = selectedOpinion;
  const {
    lawyer_name,
    lawyer_affiliation,
    lawyer_profile,
    reply,
    suggested_amount,
    pay_state,
  } = selectedOpinion;

  const getPaymentSate = () => {
    if (currentMyCase.status == "paid" || currentMyCase.status == "requested" || 
    currentMyCase.status == "processing" || currentMyCase.status == "done") {
      return "결제 완료"
    } else {
      return "결제 요청"
    }
  }
  
  return (
    <Container style={{ textAlign: "left" }}>
      <LawyerOpinionSection>
      <ContentContainer>
        <Title>변호사 검토 의견</Title>
        <DateText>사건 답변일자 : {getFormattedCaseDate(proposal_date)}</DateText>
        <OpinioWrapper>
          <OpinioHeader>
            {lawyer_profile ? (
              <LawyerProfile src={ProfileAcceptedDefault} />
            ) : (
              <LawyerProfile src={ProfileAcceptedDefault} />
            )}
            <LawyerInfoWrapper>
              <LawyerName>{lawyer_name}</LawyerName>
              <LawyerAffiliation>{lawyer ? lawyer.lawyer_affiliation : ""}</LawyerAffiliation>
            </LawyerInfoWrapper>
          </OpinioHeader>
          <ContentTitle>의견 상세</ContentTitle>
          <ContentDivier />
          <OpinioContent>{
          reply.split('\n').map((line, index) => (
            <>
              {line}
              <br />
            </>
            ))
            }</OpinioContent>          
        </OpinioWrapper>
      </ContentContainer>
      </LawyerOpinionSection>
      <DelegateSection>
        <ContentContainer>
          <Title>위임하기</Title>
          <DelegateDesc>
            법대리에서는 안전하게 법률 문제를 해결하기 위해 고객님의 사건에 관한 일체의 법률 행위를
            <br />
            변호사에게 위임하는 동의 과정을 필수로 거치고 있습니다. 아래 위임장에 성함을 기재해주세요.
          </DelegateDesc>
          <DelegateWrapper>
            <DelegateBase>
              <DelegateContentTitle>위임장</DelegateContentTitle>
              <DelegateMandateWrapper>
                <DelegateMandateTitle>수임인</DelegateMandateTitle>
                <DelegateMandateDesc>
                {setLayerNameAff()}
                <br />
                {setLayerAddress1()}
                <br />
                {setLayerAddress2()}
                </DelegateMandateDesc>
              </DelegateMandateWrapper>
              <DelegateMandateDesc style={{marginBottom: 32, lineHeight: "normal"}}>
                위임인은 수임인에게 다음과 같은 권한을 위임합니다.
              </DelegateMandateDesc>
              <DelegateContentTitle style={{fontSize: "16px"}}>
                다음
              </DelegateContentTitle>
              <DelegateMandateDesc>
                1.위 사건에 관련한 일체의 법률 행위.
                <br />
                2. 기타 부수된 일체의 행위.
              </DelegateMandateDesc>
              <DelegateMandateNameInput 
                value={mandator}
                onChange={handleMandatorChange}
                onFocus={() => {
                  setIsInputClicked(true);
                }}
                // 클릭되어 있지 않을 때 작동input 이외의 영역이 클릭되었을 때)
                onBlur={() => {
                  setIsInputClicked(false);
                }}
                placeholder={isInputClicked === true ? "" : "위임인의 성함을 입력해주세요."}
                />
            </DelegateBase>            
          </DelegateWrapper>
          <BtnWrapper>
              <BtnDelegate onClick={() => onDelegateClick()} status={getMandatorButtonState()}>{setMandatorButtonTitle()}</BtnDelegate>
            </BtnWrapper>
        </ContentContainer>
      </DelegateSection>
      <PaySection>
        <ContentContainer>
          <Title>서비스 결제</Title>
          <PayBaseWrapper>
            <PayWrapper>
              <PayLabel>이용금액</PayLabel>
              <PayValue>{Number(suggested_amount).toLocaleString()}원</PayValue>
            </PayWrapper>
            <PayWrapper>
              <PayLabel>상태</PayLabel>
              <PayValue>{getPaymentSate()}</PayValue>
            </PayWrapper>            
            <RefundWrapper>
            <RefundCollapse
                title={"· 환불규정"}
                desc={
                  `서비스를 신청하면 입력 내용을 기초로 당일 즉시 또는 1일
                  이내에 신청서를 작성합니다. 신청서가 즉시 작성되므로
                  서비스 신청 후에는 취소 및 환불이 되지 않습니다.
                  정보입력에 오류가 없는지 확인하시고 결제하시기 바랍니다.`
                }
              />
              <RefundText>
              · 이용금액은 법대리 수임료 금액이며, 인지세, 송달료, 등록면허세 등은<br/>별도로 납부해주셔야 합니다.
              </RefundText>
            </RefundWrapper>            
          </PayBaseWrapper>
          <BtnWrapper>
          {currentMyCase.accepted == null && <BtnPay status={setPayButtonStatus()} onClick={onPayClick} disabled={getMandatorButtonState() == "done" ? false  : true} >결제하기</BtnPay>}
          </BtnWrapper>
        </ContentContainer>
      </PaySection>
    </Container>
  );
};

export default LawyerOpinion;


