import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const SeizureRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        부동산 가압류
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="임대차 계약 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="계약 일자" labelStyle={labelStyle}>
            {serviceData.contract.date}
          </Descriptions.Item>
          {serviceData.contract.startDate ? (
            <>
            <Descriptions.Item label="계약이 시작된 날짜(잔금일)" labelStyle={labelStyle}>
            {serviceData.contract.startDate}
          </Descriptions.Item>
            </>
          ) : null}
          <Descriptions.Item label="보증금" labelStyle={labelStyle}>
            {serviceData.contract.deposit.amount.toLocaleString()}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="임대차 계약 종료 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="계약 종료 일자" labelStyle={labelStyle}>
            {serviceData.termination.date}
          </Descriptions.Item>
          <Descriptions.Item label="계약 종료 사유" labelStyle={labelStyle}>
            {serviceData.termination.reason === "expire"
              ? "계약 만료"
              : "계약 취소"}
          </Descriptions.Item>
          {serviceData.termination.reasonDesc ? (
            <>
              <Descriptions.Item label="계약 종료 사유 설명" labelStyle={labelStyle}>
                {serviceData.termination.reasonDesc}
              </Descriptions.Item>
            </>
          ) : null}
          {serviceData.termination.notification ? (
            <>
              <Descriptions.Item label="계약 종료 통보 여부" labelStyle={labelStyle}>
                {serviceData.termination.notification.exist === "y"
                  ? "예"
                  : "아니오"}
              </Descriptions.Item>
              {serviceData.termination.notification.exist === "y" ? (
                <>
                  <Descriptions.Item label="계약 종료 통보 일자" labelStyle={labelStyle}>
                    {serviceData.termination.notification.date}
                  </Descriptions.Item>
                  <Descriptions.Item label="계약 종료 기록 유무" labelStyle={labelStyle}>
                    {serviceData.termination.notification.recordYn === "y"
                      ? "예"
                      : "아니오"}
                  </Descriptions.Item>
                </>
              ) : null}
            </>
          ) : null}
        </Descriptions>

        <Descriptions
          title="기타 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          {serviceData.insurance && serviceData.insurance.registYn ? (
            <Descriptions.Item label="전세보증가입 여부" labelStyle={labelStyle}>
              {serviceData.insurance.registYn === "y" ? "예" : "아니오"}
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item label="보증금 지급 기록 유무" labelStyle={labelStyle}>
            {serviceData.depositHistory.exist === "y" ? "예" : "아니오"}
          </Descriptions.Item>
          <Descriptions.Item label="집주인의 주소" labelStyle={labelStyle}>
            {serviceData.lendlord.addressYn === "y" ? "예" : "아니오"}
          </Descriptions.Item>
          <Descriptions.Item label="집주인의 주민등록번호" labelStyle={labelStyle}>
            {serviceData.lendlord.ssnYn === "y" ? "예" : "아니오"}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.description
              ? serviceData.description.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
        </Descriptions>

        {/* <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">첨부 파일</div>
          </div>
          {serviceData.attachments.map((attachment) => (
            <Image
              key={attachment}
              width={75}
              height={75}
              src={attachment}
              preview={{ zIndex: 10000 }}
            />
          ))}
        </div> */}
      </div>
    </>
  );
};

export default SeizureRequest;
