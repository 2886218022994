import { useHistory } from "react-router-dom";

import IcCertificationServiceMain from "../../assets/svg/IcCertificationServiceMain.svg";
import IcCertificationServiceMainMobile from "../../assets/svg/IcCertificationServiceMainMobile.svg";
import IcCertificationServiceBody from "../../assets/svg/IcCertificationServiceBody.svg";
import IcCertificationServiceBodyMobile from "../../assets/svg/IcCertificationServiceBodyMobile.svg";
import IcCertificationServiceBody2 from "../../assets/svg/IcCertificationServiceBody2.svg";
import IcCertificationServiceBody2Mobile from "../../assets/svg/IcCertificationServiceBody2Mobile.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
  ServiceCenterFooterBtn,
  ServiceCenterFooterContainer,
  ServiceCenterFooterDesc,
  ServiceCenterFooterSection,
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle,
  ServiceCenterSecondContainer,
  ServiceCenterSecondDesc,
  ServiceCenterSecondImg,
  ServiceCenterSecondSection,
  ServiceCenterSecondSubTitle,
  ServiceCenterSecondTextContainer,
  ServiceCenterSecondTitle,
  ServiceCenterThirdContainer,
  ServiceCenterThirdDesc,
  ServiceCenterThirdDescSpan,
  ServiceCenterThirdImg,
  ServiceCenterThirdImgMobile,
  ServiceCenterThirdSection,
  ServiceCenterThirdTextContainer,
  ServiceCenterThirdTitle
} from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const CertificationServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = "내용 증명 보내기";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = "내용 증명이란?";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = isMobile
      ? "법적 문제 해결을 위한\n가장 간단하고 확실한 방법"
      : "법적 문제 해결을 위한 가장 간단하고 확실한 방법";
    return removeBrTag(text);
  };

  const body2Title = () => {
    const text = isMobile
      ? "내용 증명은\n법대리에게!"
      : "내용 증명은 법대리에게!";
    return removeBrTag(text);
  };

  const footerTitle = () => {
    const text = isMobile
      ? "내용 증명이\n필요하신가요?"
      : "내용 증명이 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcCertificationServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcCertificationServiceMain}
            />
            <ServiceCenterFirstImg src={IcCertificationServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
              {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              우리는 일상 생활 속에서 원치 않는 어쩔 수 없이 크고 작은 분쟁을 겪게 됩니다.
              임금체불, 용역대금, 전세보증금, 손해배상 등의 문제가 여기에 해당합니다.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcCertificationServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcCertificationServiceBody}
            />
            <ServiceCenterSecondImg src={IcCertificationServiceBody} />
          </picture>
        </ServiceCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterThirdSection>
        <ServiceCenterThirdContainer>
          <ServiceCenterThirdImg src={IcCertificationServiceBody2} />
          <ServiceCenterThirdTextContainer>
            <ServiceCenterThirdTitle>
              {body2Title()}
            </ServiceCenterThirdTitle>
            <ServiceCenterThirdDesc>
              이런 문제를 가장 빠르고 확실하게 해결할 수 있는 수단이 바로 내용 증명입니다. 언제, 누가, 무슨 내용을 보냈는지 국가가 확인해주는 매우 강력한 수단이죠.
              소송까지 가지 않고 <ServiceCenterThirdDescSpan> 내용증명 만으로 상대를 압박</ServiceCenterThirdDescSpan>할 수도 있고,
              소송에서 <ServiceCenterThirdDescSpan>유리한 지위를 갖출 수</ServiceCenterThirdDescSpan>도 있게 합니다.
            </ServiceCenterThirdDesc>
            <ServiceCenterThirdDesc style={{ paddingTop: 0 }}>
              내용 증명 보내기 서비스는 법대리에서 <ServiceCenterThirdDescSpan>가장 많이 찾으시는 서비스</ServiceCenterThirdDescSpan> 중 하나이며,
              의뢰인들의 <ServiceCenterThirdDescSpan>만족도가 가장 높은 서비스</ServiceCenterThirdDescSpan>입니다.
            </ServiceCenterThirdDesc>
          </ServiceCenterThirdTextContainer>
          <ServiceCenterThirdImgMobile src={IcCertificationServiceBody2Mobile} />
        </ServiceCenterThirdContainer>
      </ServiceCenterThirdSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
              {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면
              분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtn onClick={() => history.push("/apply/certificationService")}>
            내용 증명 보내기
          </ServiceCenterFooterBtn>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default CertificationServiceCenter;
