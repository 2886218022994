import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Radio,
  Row,
} from "antd";
import { useEffect } from "react";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper,
} from "../../Form.style";

const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log("values:", values);
    setData(values);
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        임대차 계약 정보
      </Title>

      {/* <Popover
          content={
            <div>
              상대에게 돈을 빌려준 내용에 대하여 작성하세요.
              <br />
              여러번에 걸쳐 빌려준 경우 아래의 빌려준 돈 정보
              <br /> 추가하기 버튼을 사용하세요.
            </div>
          }
          placement="bottom"
          trigger="click"
        >
          <QuestionCircleTwoTone />
        </Popover> */}

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          임대차 계약서의 내용을 참고하여 아래 내용을 작성하세요.
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          form={form}
          style={{
            marginTop: 10,
            marginBottom: 20,
            width:"100%",
            textAlign:"left"
          }}
          requiredMark={false}
        >
          <Form.Item
            label="계약일자"
            name={["contract", "date"]}
            rules={[
              {
                required: true,
                message: "날짜를 선택해주세요",
              },
            ]}
            // style={{ width: "calc(85% - 8px)" }}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="날짜를 선택하세요"
            />
          </Form.Item>

          <Form.Item
            label="전세금(보증금)"
            name={["contract", "deposit", "amount"]}
            rules={[
              {
                required: true,
                message: "전세금(보증금)을 입력해주세요",
              },
            ]}
            // style={{ width: "calc(85% - 8px)" }}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              addonBefore="금"
              addonAfter="원"
              controls={false}
            />
          </Form.Item>

          <Form.Item
            label="계약 시작 일자(잔금일)"
            name={["contract", "deposit", "date"]}
            rules={[
              {
                required: true,
                message: "날짜를 선택해주세요",
              },
            ]}
            // style={{ width: "calc(85% - 8px)" }}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="날짜를 선택하세요"
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                월세 유무
                <Popover
                  content={
                    <div>
                      전세금 외 별도의 월세가 있었을 경우
                      <br />
                      '예' 를 눌러 작성해주세요.
                      <br />
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["contract", "monthlyRent", "exist"]}
            rules={[{ required: true, message: "" }]}
            // style={{ width: "calc(85% - 8px)" }}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return (
                prevValues.contract.monthlyRent.exist !==
                currentValues.contract.monthlyRent.exist
              );
            }}
          >
            {() => (
              <>
                <Form.Item
                  label="월세 금액"
                  style={{ marginBottom: 0 }}
                  hidden={
                    form.getFieldValue(["contract", "monthlyRent", "exist"]) ===
                    "n"
                  }
                >
                  <Form.Item
                    name={["contract", "monthlyRent", "amount"]}
                    style={{
                      width: "80%",
                      // maxWidth: 150,
                      display: "inline-block",
                    }}
                    rules={
                      form.getFieldValue([
                        "contract",
                        "monthlyRent",
                        "exist",
                      ]) === "n"
                        ? []
                        : [
                            {
                              required: true,
                              message: "월세 금액을 입력해주세요",
                            },
                          ]
                    }
                  >
                    <InputNumber
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      addonBefore="금"
                      addonAfter="원"
                      controls={false}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label="월세 지급일"
                  style={{ marginBottom: 0 }}
                  hidden={
                    form.getFieldValue(["contract", "monthlyRent", "exist"]) ===
                    "n"
                  }
                >
                  <Form.Item
                    name={["contract", "monthlyRent", "date"]}
                    style={{
                      width: "50%",
                      // maxWidth: 150,
                      display: "inline-block",
                    }}
                    rules={
                      form.getFieldValue([
                        "contract",
                        "monthlyRent",
                        "exist",
                      ]) === "n"
                        ? []
                        : [
                            {
                              required: true,
                              message: "월세 지급일을 입력해주세요",
                            },
                          ]
                    }
                  >
                    <InputNumber
                      addonBefore="매월"
                      addonAfter="일"
                      controls={false}
                    />
                  </Form.Item>
                </Form.Item>
              </>
            )}
          </Form.Item>

          <Row justify="space-between">
            <Col span={6} offset={18} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                다음
              </Button>
            </Col>
          </Row>
        </Form>
      </CaseDetailWrapper>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step1;
