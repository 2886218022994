import { useEffect, useState } from "react";
import IcLoanServiceMain from "../../../../assets/svg/IcLoanServiceMain.svg";
import IcLoanServiceMainMobile from "../../../../assets/svg/IcLoanServiceMainMobile.svg";


import { useRecoilValue } from "recoil";
import { userData, redirectEtcUri } from "../../../../states/atoms";

import {
  Container,
} from "../../../Service.style";
import {
  ApplyServiceDescContainer,
  ApplyServiceDescSection,
  ApplyServiceDescText,
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
} from "../../../home/ServiceCenter.style";

import { RadioGroup, Radio } from "../../../home/CommonCriminal/RadioBox";
// import { CheckboxGroup, Checkbox } from "../../../home/CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { useRecoilState } from 'recoil';

import {
  Input,
  Row,
  // Radio
} from "antd";

import { removeBrTag } from "routes/Landing";
import { ApplyServiceSubmit } from "../ApplyServiceSubmit";
import { ApplyServiceInputText, ApplyServiceSubmitDataContainer, ApplyServiceSubmitDataInfoContainer, ApplyServiceSubmitDataInfoDetailContainer, ApplyServiceSubmitDataInfoDetailTitle, ApplyServiceSubmitDataSection } from "./ApplyService.style";
import { EventFormEachDesc, EventInputTextArea, EventSubmitFooterSection, EventSubmitFooterText, EventSubmitFooterTextContainer } from "components/event/3o3/order/EventSubmit.style";

const { useHistory } = require("react-router-dom");

const ApplyLoanService = ({ loggedInUser }) => {
  // 지급명령이 필요한 분야
  const [field, setField] = useState("");
  const [etcField, setEtcField] = useState("");

  // 상대방 주민번호 혹은 주소 아는지. 
  const [knowPrivateInfo, setKnowPrivateInfo] = useState("");

  // 받아야할 금액의 일부를 반환 받은 여부
  const [partialReturn, setPartialReturn] = useState("");
  const [partialReturnAmount, setPartialReturnAmount] = useState("");

  // 앞으로 받아야할 금액
  const [returnAmount, setReturnAmount] = useState("");

  // 받아야 할 날로부터 며칠이 지났는지.
  const [dayAfterNotReturn, setDayAfterNotReturn] = useState("");

  // 받야아할 금액을 증빙할 자료
  const [proof, setProof] = useState("");

  // 현재 상황에 대한 상세 설명
  const [caseDesc, setCaseDesc] = useState("");


  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  const title = () => {
    const text = isMobile
      ? "못받은 돈 받기"
      : "못받은 돈 돌려받기";
    return removeBrTag(text);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("selected filed???", field);
  }, []);

  const handleCheckboxChange = (checkedValues) => {
    console.log(checkedValues)
  };

  const handleEtcFieldChange = (e) => {
    const inputValue = e.target.value;
    setEtcField(inputValue)
  }

  const fieldList = [
    "헬스･필라테스 환불", "임금 체불", "용역 대금",
    "못받은 돈", "매매 대금", "연체 임대로", "투자금 반환", "부당 이득 반환", "양육비", "그 외"]
  const knowPrivateInfoList = ["네", "아니오"]
  const partialReturnList = ["네", "아니오"]

  function handleChange(checkedValues) {
    setField(checkedValues);
  }

  function handleKnowPrivateInfoChange(checkedValues) {
    setKnowPrivateInfo(checkedValues)
  }

  function handlePartialReturnChange(checkedValues) {
    setPartialReturn(checkedValues)
  }

  function handlePartialReturnAmountChange(e) {
    const inputValue = e.target.value;
    setPartialReturnAmount(inputValue)
  }

  function handleReturnAmountChange(e) {
    const inputValue = e.target.value;
    setReturnAmount(inputValue)
  }

  function handleDayAfterNotReturn(e) {
    const inputValue = e.target.value;
    setDayAfterNotReturn(inputValue)
  }

  function handleSetProof(checkedValues) {
    setProof(checkedValues)
  }

  const handleDescChange = (e) => {
    const inputValue = e.target.value;
    setCaseDesc(inputValue)
  }

  const serviceData = () => {
    const serviceData = {
      // 전화번호
      contact: "",
      // 첨부파일
      attachments: [],
      field: field,
      etcField: field === "그 외" ? etcField : null,
      knowPrivateInfo: knowPrivateInfo === "네" ? "y" : "n",
      partialReturn: partialReturn === "네" ? "y" : "n",
      partialReturnAmount: partialReturn === "네" ? partialReturnAmount : null,
      returnAmount: returnAmount,
      dayAfterNotReturn: dayAfterNotReturn,
      proof: proof,
      caseDesc: caseDesc,
      userId: loggedInUser.uid
    };
    return serviceData;
  }


  const isSubmitReady = () => {
    if (caseDesc !== "" && field !== ""
      && knowPrivateInfo !== "" && partialReturn !== "" && returnAmount !== ""
      && dayAfterNotReturn !== "" && proof !== "") {
      return true
    }
    return false
  }

  const footerText = () => {
    const text = isSubmitReady()
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄져요. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "모든 항목에 답변해 주세요.\n답변이 완료되면 제출할 수 있어요.";
    return removeBrTag(text);
  }

  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcLoanServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcLoanServiceMain}
            />
            <ServiceCenterFirstImg src={IcLoanServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ApplyServiceSubmitDataSection>
        <ApplyServiceSubmitDataContainer>
          <ApplyServiceSubmitDataInfoContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>지급명령이 필요한 분야를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup label="" value={field} style={{ width: "100%" }} onChange={handleChange}>
                {fieldList.map((value) =>
                  <Radio value={value} >{value}</Radio>
                )}
                {field.includes("그 외") && <>
                  <ApplyServiceInputText style={{ width: "100%" }} value={etcField} onChange={handleEtcFieldChange} placeholder="내용을 입력해주세요." />
                </>}
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>상대의 주민등록 번호, 또는 주소를 알고 계신가요?</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup label="" value={knowPrivateInfo} style={{ width: "100%" }} onChange={handleKnowPrivateInfoChange}>
                {knowPrivateInfoList.map((value) =>
                  <Radio value={value} >{value}</Radio>
                )}
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>받아야 할 금액의 일부를 반환 받은 적이 있나요?</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup label="" value={partialReturn} style={{ width: "100%" }} onChange={handlePartialReturnChange}>
                <Radio value="네" >네</Radio>
                {partialReturn.includes("네") && <>
                  <ApplyServiceInputText style={{ width: "100%" }} value={partialReturnAmount} onChange={handlePartialReturnAmountChange} placeholder="얼마를 반환 받으셨나요?" />
                </>}
                <Radio value="아니오" >아니오</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>앞으로 받아야 할 금액은 얼마인가요?</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceInputText
                style={{ width: "100%" }}
                value={returnAmount}
                type="number"
                inputMode="numeric"
                onChange={handleReturnAmountChange}
                placeholder="금액을 원 단위로 입력해 주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>받아야 할 날로부터 며칠이 지났나요?</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceInputText
                style={{ width: "100%" }}
                value={dayAfterNotReturn}
                type="number"
                inputMode="numeric"
                onChange={handleDayAfterNotReturn}
                placeholder="일 단위로 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>받아야 할 금액을 증빙할 자료가 있나요?</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup label="" value={proof} style={{ width: "100%" }} onChange={handleSetProof}>
                <EventFormEachDesc>이체기록, 차용증, 대화내역 등</EventFormEachDesc>
                <Radio value="네" >네</Radio>
                <Radio value="아니오" >아니오</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>현재 상황에 대한 상세 설명을 작성해주세요</ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>상세 설명은 육하원칙에 따라 간결하게 작성해 주셔야 변호사 검토가 빠르게 이뤄집니다.</EventFormEachDesc>
              <EventInputTextArea value={caseDesc} onChange={handleDescChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
          </ApplyServiceSubmitDataInfoContainer>
        </ApplyServiceSubmitDataContainer>
      </ApplyServiceSubmitDataSection>
      <EventSubmitFooterSection active={isSubmitReady()}>
        <EventSubmitFooterTextContainer>
          <EventSubmitFooterText active={isSubmitReady()}>
            {footerText()}
          </EventSubmitFooterText>
        </EventSubmitFooterTextContainer>
      </EventSubmitFooterSection>
      <ApplyServiceSubmit
        active={isSubmitReady()}
        serviceData={serviceData()}
        serviceType={"common_order"}
        uid={loggedInUser.uid}
      />
    </Container>
  )
};

export default ApplyLoanService;
