import { Descriptions, Divider, Image } from "antd";
import { Typography } from "antd";
import { labelStyle } from "./RequestUtil";

const { Title } = Typography;

const LendCertificationRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        대여금 상환 청구
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        {serviceData.loans.map((loan, idx) => (
          <Descriptions
            key={idx}
            title={idx === 0 ? "대여금 정보" : null}
            bordered
            column={reactiveColumn}
            size={"small"}
            style={{ marginBottom: 15 }}
          >
            <Descriptions.Item label="빌려준 날짜" labelStyle={labelStyle}>
              {loan.lendDate ? loan.lendDate : "없음"}
            </Descriptions.Item>
            <Descriptions.Item label="갚기로 한 날짜" labelStyle={labelStyle}>
              {loan.dueDate ? loan.dueDate : "없음"}
            </Descriptions.Item>
            <Descriptions.Item label="금액" labelStyle={labelStyle}>
              {loan.amount.toLocaleString()}
            </Descriptions.Item>
            {loan.interests.exist === "y" ? (
              <Descriptions.Item label="연체 이자" labelStyle={labelStyle}>
                {loan.interests.unit === "year" ? "연 " : "월 "}
                {loan.interests.value} %
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        ))}

        {serviceData.repayments.map((repayment, idx) => (
          <Descriptions
            key={idx}
            title={idx === 0 ? "상환금 정보" : null}
            bordered
            column={reactiveColumn}
            size={"small"}
            style={{ marginBottom: 15 }}
          >
            <Descriptions.Item label="갚은 날짜" labelStyle={labelStyle}>
              {repayment.date ? repayment.date : "없음"}
            </Descriptions.Item>
            <Descriptions.Item label="금액" labelStyle={labelStyle}>
              {repayment.amount.toLocaleString()}
            </Descriptions.Item>
          </Descriptions>
        ))}

        <Descriptions
          title="상세 내용"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginBottom: 15 }}
        >
          <Descriptions.Item label="빌려준 사람 정보" labelStyle={labelStyle}>
            {serviceData.loaner.type === "indv" ? "개인/개인사업자" : "법인"}
          </Descriptions.Item>
          <Descriptions.Item label="빌린 사람 정보" labelStyle={labelStyle}>
            {serviceData.lender.type === "indv" ? "개인/개인사업자" : "법인"}
          </Descriptions.Item>
          <Descriptions.Item label="상대방 주소" labelStyle={labelStyle}>
            {serviceData.lender.addressYn === "y" ? "알고 있음" : "모름"}
          </Descriptions.Item>
          <Descriptions.Item label="상대방 주민등록번호" labelStyle={labelStyle}>
            {serviceData.lender.ssnYn === "y" ? "알고 있음" : "모름"}
          </Descriptions.Item>
          <Descriptions.Item label="연체 이자 청구" labelStyle={labelStyle}>
            {serviceData.overDueInterests.requestYn === "y"
              ? "청구"
              : "청구하지 않음"}
          </Descriptions.Item>
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.description
              ? serviceData.description.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
          <Descriptions.Item label="프로모션" labelStyle={labelStyle}>
            {serviceData.promotionCode}
          </Descriptions.Item>
        </Descriptions>

        <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">첨부 파일</div>
          </div>
          {serviceData.attachments.map((attachment) => (
            <Image
              key={attachment}
              width={75}
              height={75}
              src={attachment}
              preview={{ zIndex: 10000 }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default LendCertificationRequest;
