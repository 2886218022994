import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const ComplaintRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const evidenceList = serviceData.evidence.map((item) => {
    if (item === "cctv") return <li>CCTV/현장사진/영상물</li>;
    else if (item === "offenderRecord")
      return <li>가해자의 범행 관련 대화 내용 (녹음파일, 메시지 등)</li>;
    else if (item === "witness") return <li>목격자 혹은 제3자의 진술</li>;
    else if (item === "other") return <li>{serviceData.otherEvidence}</li>;
  });

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        성범죄 고소장 작성
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="사건 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="사건 분류" labelStyle={labelStyle}>
            {serviceData.case === "rape" && "강간(성폭행)"}
            {serviceData.case === "indecentAssault" && "강제추행(성추행)"}
            {serviceData.case === "publicIndecentAssault" && "공중장소추행"}
            {serviceData.case === "digitalSexCrime" &&
              "디지털 성범죄(몰카, 음란물, 통매음 등)"}
            {serviceData.case === "sexualHarrassment" && "성희롱"}
            {serviceData.case === "prostitution" && "성매매"}
            {serviceData.case === "obscenity" && "공연음란"}
            {serviceData.case === "other" && "기타"}
          </Descriptions.Item>
          <Descriptions.Item label="증거자료" labelStyle={labelStyle}>
            <ul style={{ paddingInlineStart: 5 }}>{evidenceList}</ul>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.description
              ? serviceData.description.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
        </Descriptions>

        {/* <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">첨부 파일</div>
          </div>
          {serviceData.attachments.map((attachment) => (
            <Image
              key={attachment}
              width={75}
              height={75}
              src={attachment}
              preview={{ zIndex: 10000 }}
            />
          ))}
        </div> */}
      </div>
    </>
  );
};

export default ComplaintRequest;
