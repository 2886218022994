import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";
import { getServiceTypeTitle } from "components/mypage/MyCaseList";

const { Title } = Typography;

const To3Request = ({ serviceData, requestData, serviceType }) => {
  console.log("serviceData", requestData);
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const getTitle = () => {
    if (requestData === undefined) {
      return getServiceTypeTitle(serviceType);
    } else {
      return getServiceTypeTitle(requestData.serviceType);
    }
  }
  const service_type = requestData === undefined ? serviceType : requestData.serviceType;


  const getBillingAmount = () => {
    if (service_type === "3o3_loan") {
      return <>
        <Descriptions.Item
          label="돌려 받아야 할 금액"
          labelStyle={labelStyle}
        >
          {serviceData.billingAmount}
        </Descriptions.Item>
      </>
    } else if (service_type === "3o3_pilates") {
      return <>
        <Descriptions.Item
          label="환불 받을 금액"
          labelStyle={labelStyle}
        >
          {serviceData.billingAmount}
        </Descriptions.Item>
      </>
    } else if (service_type === "3o3_pay") {
      return <>
        <Descriptions.Item
          label="받아야 할 금액"
          labelStyle={labelStyle}
        >
          {serviceData.billingAmount}
        </Descriptions.Item>
      </>
    }
  }

  const getOpponetName = () => {
    if (service_type === "3o3_loan") {
      return <>
        <Descriptions.Item
          label="빌린 분의 성함"
          labelStyle={labelStyle}
        >
          {serviceData.opponentName}
        </Descriptions.Item>
      </>
    } else {
      return <>
        <Descriptions.Item
          label="업체명"
          labelStyle={labelStyle}
        >
          {serviceData.opponentName}
        </Descriptions.Item>
      </>
    }
  }

  const getOpponetAddress = () => {
    if (service_type === "3o3_loan") {
      return <>
        <Descriptions.Item
          label="빌린 분의 주소"
          labelStyle={labelStyle}
        >
          {serviceData.opponentAddress}
        </Descriptions.Item>
      </>
    } else {
      return <>
        <Descriptions.Item
          label="업체 주소"
          labelStyle={labelStyle}
        >
          {serviceData.opponentAddress}
        </Descriptions.Item>
      </>
    }
  }

  const getOpponetPhone = () => {
    if (service_type === "3o3_loan") {
      return <>
        <Descriptions.Item
          label="빌린 분의 연락처"
          labelStyle={labelStyle}
        >
          {serviceData.opponentPhone}
        </Descriptions.Item>
      </>
    } else {
      return <>
        <Descriptions.Item
          label="업체 연락처"
          labelStyle={labelStyle}
        >
          {serviceData.opponentPhone}
        </Descriptions.Item>
      </>
    }
  }

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        {getTitle()}
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="기타 법률 관련 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >

          {serviceData.needType &&
            <>
              <Descriptions.Item
                label="내용증명이 필요한 분야"
                labelStyle={labelStyle}
              >
                {serviceData.needType}
              </Descriptions.Item>

              {serviceData.needType.includes("그 외") ? (
                <Descriptions.Item label="기타 분야" labelStyle={labelStyle}>
                  {serviceData.etcNeedType}
                </Descriptions.Item>
              ) : null}
            </>
          }

          {serviceData.solutionType &&
            <>
              <Descriptions.Item
                label="의뢰인이 원하는 해결방향"
                labelStyle={labelStyle}
              >
                {serviceData.solutionType}
              </Descriptions.Item>

              {serviceData.solutionType.includes("직접 입력") ? (
                <Descriptions.Item label="기타 이유" labelStyle={labelStyle}>
                  {serviceData.etcSolution}
                </Descriptions.Item>
              ) : null}
            </>
          }

          {serviceData.desiredResult &&
            <>
              <Descriptions.Item
                label="의뢰인이 원하는 결과"
                labelStyle={labelStyle}
              >
                {serviceData.desiredResult.join(", ")}
              </Descriptions.Item>

              {serviceData.desiredResult.includes("직접 입력") ? (
                <Descriptions.Item label="기타 이유" labelStyle={labelStyle}>
                  {serviceData.etcDesiredResult}
                </Descriptions.Item>
              ) : null}
            </>
          }

          {(serviceData.opponentName) &&
            getOpponetName()
          }

          {(serviceData.opponentAddress) &&
            getOpponetAddress()
          }

          {(serviceData.opponentPhone) &&
            getOpponetPhone()
          }

          {(serviceData.billingAmount) &&
            getBillingAmount()
          }

        </Descriptions>

        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.desc
              ? serviceData.desc.split("\n").map((line, idx) => {
                return (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                );
              })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
          <Descriptions.Item label="프로모션" labelStyle={labelStyle}>
            {serviceData.promotionCode}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};

export default To3Request;
