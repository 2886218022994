import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import backButtonBlack from "../../../assets/img/backButtonBlack.png";
import IcNotSubmitQuestion from "../../../assets/svg/IcNotSubmitQuestion.svg";
import Loading from "../../../util/Loading";
import Modal from "react-modal";
import { ConfrimLawyerDocModalStyle } from "util/ModalStyle";
import axios from "axios";
import { 
    ModalContainer,
    ModalContentContainer,
    ModalHeader,
    ModalHeaderBack,
    ModalHeaderBackWrapper,
    ModalHeaderTitle,
    NotSubMittedContentContainer,
    NotSubMittedContainer,
    NotSubMittedContentWrapper,
    NotSubMittedTopContainer,
    NotSubMittedSuppertWrapper,
    NotSubMittedSuppertImg,
    NotSubMittedSuppertText,
    NotSubMittedDataContentDivider,
    NotSubMittedDataContainer,
    NotSubMittedDataTitle,
    NotSubmittedUploadWrapper,
    NotSubMittedDataDesc,
    PreviewSlider
 } from "./CaseDataNotSubmitPreview.style";
import SuccessUploadDocModal from "../SuccessUploadDocModal";
import { sendClientUploadWebhook } from "../repository/CaseSubmissionRepository";
import { useRecoilState } from "recoil";
import { currentLawyerCaseState, currentClientNotSubmitPreviewState } from "states/atoms";


function CaseDataNotSubmitPreview({ onClickCloseButton, onClickFileUpload }) {
  const history = useHistory();
  const [notSubmitData, setNotSubmitData] = useRecoilState(currentClientNotSubmitPreviewState);
  const [lawyerCase, setLaywerCase] = useRecoilState(currentLawyerCaseState);
  
  const [dataList, setDataList] = useState(notSubmitData.docDataList);
  const [idx, setIdx] = useState(notSubmitData.currentIdx);
  const fileInputRef = useRef(null);
  const [selectedNotSubmitted, setSelectedNotSubmitted] = useState(null);
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessUploadDocModal, setShowSuccessUploadDocModal] = useState(false);

  useEffect(() => {
  }, [dataList, selectedNotSubmitted]);

  const handleGoBack = () => {
    onClickCloseButton();
  };

  const handleInputClick = (property) => {
    setSelectedNotSubmitted(property);
    fileInputRef.current.click();
  };

  const handleFileSelect = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const result = {
      "file": file,
      "data": selectedNotSubmitted
    }
    console.log(result);
    if (isMobile) {
      const id = selectedNotSubmitted.id;
      const name = selectedNotSubmitted.title;
      const desc = selectedNotSubmitted.desc;
      await fileUpload(file, id, name, desc);
      await sendClientUploadWebhook(lawyerCase, name);
      setShowSuccessUploadDocModal(true);        
    } else {
      onClickFileUpload(result);
    }      
  }
  
  const fileUpload = async (file, id, name, desc) => {
    // setSelectedFile(file);
    setIsLoading(true);
    const formData = new FormData();
    formData.append('image', file);
    formData.append('id', lawyerCase.id);
    formData.append('reqId', id);
    formData.append('content', name);
    formData.append('desc', desc);
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_UPLOAD_CLIENT_FILE_URL
    try {
      const { data } = await axios.post(host, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("response??", data);
      setIsLoading(false);
      return data;
    } catch (e) {
      console.log("handleFileSelect err", e);
      console.error(e);
      setIsLoading(false);
      return [];
    }
  }

  const onClickModalCloseButton = () => {
    setShowSuccessUploadDocModal(false);
    history.goBack();
  };


  const renderUI = (obj, index) => {
    return (
    <NotSubMittedContainer>
        <NotSubMittedContentWrapper>
          <NotSubMittedContentContainer>
            <NotSubMittedTopContainer>
              <NotSubMittedSuppertWrapper>
                <NotSubMittedSuppertImg src={IcNotSubmitQuestion}/>
                <NotSubMittedSuppertText>
                  현재 제출 미완료 상태입니다.
                  <br />
                  사건서류를 업로드 해주세요.
                </NotSubMittedSuppertText>
              </NotSubMittedSuppertWrapper>
              <NotSubMittedDataContentDivider/>
              <NotSubMittedDataContainer>
                <NotSubMittedDataTitle>
                  {obj.title}
                </NotSubMittedDataTitle>
                <NotSubMittedDataDesc>
                {obj.desc.split('\n').map((line, index) => (
                  <>
                    {line}
                    <br />
                  </>
                  ))
                  }
                </NotSubMittedDataDesc>
              </NotSubMittedDataContainer>
            </NotSubMittedTopContainer>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileSelect}
            />
            <NotSubmittedUploadWrapper onClick={() => handleInputClick(obj)}>
              + 파일 업로드 하기
            </NotSubmittedUploadWrapper>
          </NotSubMittedContentContainer>            
        </NotSubMittedContentWrapper>
    </NotSubMittedContainer>
    )
  };

  const settings = {
    speed: 500,
    slidesToShow: 1,
    infnite: true,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => {
      switch (newIndex) {
        default:
          setIdx(newIndex + 1);
          break;
      }
    },
    initialSlide: idx - 1,
  };

  if (!dataList) return null;

  if (isLoading) return <Loading loading={isLoading}/>; // loading screen

  return (
    <>
      <Modal
      isOpen={showSuccessUploadDocModal}
      onRequestClose={() => setShowSuccessUploadDocModal(false)}
      style={ConfrimLawyerDocModalStyle}
      >
      <SuccessUploadDocModal
        onClikcConfirmButton={onClickModalCloseButton}
      />
      </Modal>
      <ModalContainer>
        <ModalHeader>
        <ModalHeaderBackWrapper onClick={handleGoBack}>
          <ModalHeaderBack src={backButtonBlack}/>
        </ModalHeaderBackWrapper>     
        <ModalHeaderTitle>
          {dataList[idx - 1].title}
        </ModalHeaderTitle>
        <ModalHeaderBackWrapper />
        </ModalHeader>
        <ModalContentContainer>
        <PreviewSlider {...settings}>
            {dataList.map((file, index) => renderUI(file, index))}
          </PreviewSlider>
          <div
            style={{
              position: "absolute",
              bottom: 50,
              width: "100%",
              textAlign: "center",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
          {idx} / {dataList.length}
          </div>
        </ModalContentContainer>
      </ModalContainer>
    </>
  );
}
  
export default CaseDataNotSubmitPreview;