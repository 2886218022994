import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Popover,
  Radio,
  Row,
  message,
} from "antd";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,
  CaseDetailWrapper,
} from "../../../Form.style";
import TermsOfService from "components/form/commons/TermsOfService";

import { useEffect, useState } from "react";
const { TextArea } = Input;

const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const key = "updatable";

  const onFinish = async (values) => {
    console.log("values:", values);
    await setData(values);
    setSubmitting(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    if (submitting) {
        message.success({
            content: "제출 완료!",
            key,
            duration: 2,
        });
        onNext();
        }
    }, [submitting]);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
          <Title>명도소송</Title>

          <div style={{ marginTop: 10 }}>
            <SubText style={{ textAlign: "left" }} type="secondary">
            명도소송 관련하여 아래 내용을 작성하세요.
            </SubText>
          </div>

          <Divider style={{ marginTop: 10 }} />
          <CaseDetailWrapper style={{ height: "90%" }}>
            <Form
              {...itemLayout.form}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={data}
              form={form}
              style={{
                marginTop: 10,
                marginBottom: 20,
                width: "100%",
                textAlign: "left",
              }}
              requiredMark={false}
            >

              <Form.Item
                label="명도소송 사유"
                name={["vacatehouse", "reason"]}
                rules={[
                  {
                    required: true,
                    message: "명도소송 사유를 선택해주세요",
                  },
                ]}
              >
                <Checkbox.Group style={{ width: "100%" }}>
                  <Row>
                    <Col span={12}>
                      <Checkbox value="월세미납">
                        월세미납
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="계약만료">계약만료</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="계약위반">
                        계약위반
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="불법점유">
                        불법점유
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="기타사유">기타사유</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                label="내용증명 발송 여부"
                name={["vacatehouse", "certification"]}
                rules={[{ required: true, message: "" }]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Radio.Group>
                  <Radio.Button value="y">예</Radio.Button>
                  <Radio.Button value="n">아니오</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label={
                  <>
                    기타내용
                    <Popover
                      content={
                        <div>
                          검토 시 필요한 기타 사항을 500자 내로 작성해주세요
                        </div>
                      }
                      placement="bottom"
                      trigger="click"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  </>
                }
                name={["description"]}
              >
                <TextArea rows={10} maxLength={500} />
              </Form.Item>

              <Form.Item
                label="연락처"
                name={["contact"]}
                rules={[
                  {
                    required: true,
                    message: "연락처를 입력해주세요",
                  },
                ]}
              >
                <Input placeholder="검토 결과 알림을 받으실 연락처를 입력해주세요" />
              </Form.Item>

              <Form.Item label="프로모션 코드" name={["promotionCode"]}>
                <Input />
              </Form.Item>
              <TermsOfService />

              <Row justify="space-between">
                <Col span={6} offset={18} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    {submitting ? "제출 중" : "제출"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </CaseDetailWrapper>
        </ContentContainer>
      </MyCaseSection>
    </Container>
  );
};

export default Step1;
