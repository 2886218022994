import { LockOutlined } from "@ant-design/icons";
import { Result } from "antd";
import { authService, dbService, firebaseInstance } from "fbase";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { redirectUri, redirectEtcUri } from '../states/atoms.js'
import { userData } from '../states/atoms.js'
import axios from "axios";
import ChannelService from "components/ChannelService";
import { collection, query, where, getDocs, getDoc, deleteDoc, doc } from "firebase/firestore";
import { getServiceTypeTitle } from "components/mypage/MyCaseList.js";
import * as Sentry from "@sentry/react";

const qs = require("qs");
const { Kakao } = window;

const OAuth = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const [path, setPath] = useRecoilState(redirectUri);
  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);
  const [userInfo, setUserInfo] = useRecoilState(userData);
  useEffect(() => {
    auth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const kakaoAuthConfig = {
    clientId: process.env.REACT_APP_KAKAO_REST_API_KEY,
    clientSecret: process.env.REACT_APP_KAKAO_CLIENT_SECRET,
    redirectUrl: process.env.REACT_APP_KAKAO_REDIRECT_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  if (!params.get("code")) {
    history.push("/");
  }

  const getKakaoAccessToken = async (code) => {
    const res = await fetch("https://kauth.kakao.com/oauth/token", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      body: qs.stringify({
        grant_type: "authorization_code",
        client_id: kakaoAuthConfig.clientId,
        client_secret: kakaoAuthConfig.clientSecret,
        redirect_url: kakaoAuthConfig.redirectUrl,
        code: code,
      }),
    });

    return await res.json();
  };

  const getKakaoUserInfo = async (accessToken) => {
    const res = await fetch("https://kapi.kakao.com/v2/user/me", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
    return await res.json();
  };

  const getFirebaseToken = async (kakaoUserInfo) => {
    const res = await fetch(
      process.env.REACT_APP_FIREBASE_FUNCTION_URL,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(kakaoUserInfo),
      }
    );

    return await res.json();
  };

  const upsertUserData = async (kakaoUserInfoData, firebaseAuthId) => {
    try {
      await dbService
        .collection("users")
        .doc(String(firebaseAuthId))
        .set({ ...kakaoUserInfoData, userId: firebaseAuthId }, { merge: true });
    } catch (err) { }
  };

  const update3o3UserData = async (phoneNumber, firebaseAuthId) => {
    const q = query(collection(dbService, "to3o3_requests"), where("phone", "==", phoneNumber));
    const querySnapshot = await getDocs(q);
    const dataList = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    })
    for (const data of dataList) {
      const serviceId = data.serviceId
      const tempDataQuery = query(collection(dbService, "requests_temp"), where("serviceId", "==", serviceId));
      const docSnaps = await getDocs(tempDataQuery);
      if (docSnaps.empty) {
        try {
          const lawyerProposalList = data.lawyer_proposal_id_list ?? []
          console.log("lawyerProposalList", lawyerProposalList, "lawyerProposalList.length", lawyerProposalList.length);
          // 데이터 추가.
          await dbService.collection("requests_temp").add({
            serviceType: data.serviceType,
            serviceId: data.serviceId,
            createdAt: Date.now(),
            service_data: data.service_data,
            userId: firebaseAuthId,
            clientName: data.name,
            lawyer_proposal_id_list: lawyerProposalList,
            status: data.status,
          })
            .then(async docRef => {
              // 변호사의 제안이 있다면 변호사 
              if (lawyerProposalList.length !== 0) {
                for (const lawyerProposal of lawyerProposalList) {
                  await updateProposal(lawyerProposal.id, docRef.id);
                }
              }
              try {
                // 저장된 데이터 제거.
                await deleteDoc(doc(dbService, "to3o3_requests", data.id));
              } catch (err) {
                Sentry.captureException(`[3o3][OAuth][sync]  name : ${data.name} phone: ${data.phone} delete doc error : `, err);
              }
              await sendWebhook(data.serviceType, docRef.id, data.name, data.phone);
            })
        } catch (err) {
          Sentry.captureException(`[3o3][OAuth][sync] ${phoneNumber} error : `, err);
        }
      }
    }
  };

  async function updateProposal(proposalId, client_request_id) {
    console.log("[PROPOSAL] proposalId ", proposalId, "client_request_id", client_request_id);
    if (proposalId === undefined) {
      return;
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_UPDATE_PROPOSAL_CLIENT_ID_URL
    const body = {
      proposalId: proposalId,
      client_request_id: client_request_id
    };
    const url = host;
    try {
      const { data } = await axios.post(url, body);
      // setLawyer(data);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async function sendWebhook(serviceType, client_req_id, name, phone) {
    const webhookUrl = process.env.REACT_APP_DISCORD_TO3_SYNC_WEBHOOK_DOMAIN;
    const serviceTypeTitle = getServiceTypeTitle(serviceType)
    console.log("serviceType", serviceType);
    const message = {
      content: `🔥삼쩜삼 법대리 싱크 완료🔥
      사건 타입: ${serviceTypeTitle}
      유저: ${name} 
      전화번호: ${phone}
      client_req_id(법대리 사건id): ${client_req_id}`
    };

    try {
      const { response } = await axios.post(webhookUrl, message);
    } catch (e) {
    }
  }

  const auth = async () => {
    try {
      const kakaoAccessTokenData = await getKakaoAccessToken(
        params.get("code")
      );
      const kakaoUserInfoData = await getKakaoUserInfo(
        kakaoAccessTokenData.access_token
      );
      setUserInfo({ name: kakaoUserInfoData.properties.nickname, phoneNumber: kakaoUserInfoData.kakao_account.phone_number })
      Kakao.Auth.setAccessToken(kakaoAccessTokenData.access_token);
      const firebaseTokenData = await getFirebaseToken(kakaoUserInfoData);
      await authService.setPersistence(
        firebaseInstance.auth.Auth.Persistence.LOCAL
      );
      authService
        .setPersistence(firebaseInstance.auth.Auth.Persistence.LOCAL)
        .then(() => {
          authService.setPersistence(
            firebaseInstance.auth.Auth.Persistence.LOCAL,
            { sessionDuration: 100 }
          );
        })
        .catch((error) => { });
      const userCredential = await authService.signInWithCustomToken(
        firebaseTokenData.firebaseToken
      );

      await upsertUserData(kakaoUserInfoData, userCredential.user.uid);
      await update3o3UserData(kakaoUserInfoData.kakao_account.phone_number, userCredential.user.uid);
      await ChannelService.deleteOnShowMessage()
      if (etcPath !== "") {
        const pth = etcPath;
        setEtcPath("");
        history.push(pth);
      } else {
        history.push(path);
      }
    } catch (err) {
      if (etcPath !== "") {
        const pth = etcPath;
        setEtcPath("");
        history.push(pth);
      } else {
        history.push(path);
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginBottom: 10,
        borderRadius: 5,
        minHeight: "100%",
      }}
    >
      <Result
        style={{ whiteSpace: "pre-line" }}
        icon={<LockOutlined />}
        subTitle={
          "개인정보 암호화를 통해\n안전하게 로그인 중입니다..\n잠시만 기다려주세요."
        }
      />
    </div>
  );
};
export default OAuth;
