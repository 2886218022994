import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";
// import Step2 from "./Step2";

const { useState } = require("react");
const { useHistory } = require("react-router-dom");
const { default: Step1 } = require("./Step1");

const Certification = ({ loggedInUser }) => {
  const [page, setPage] = useState(0);
  const userInfo = useRecoilValue(userData);
  const history = useHistory();

  const itemLayout = {
    form: {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    },
    button: {
      wrapperCol: { span: 24, offset: 0 },
    },
  };

  const [step1Data, setStep1Data] = useState({
    inheritGiveup: {
      date: null,
      number: null,
      isUsed: "n",
      description: null
    },
  });

  const onPrev = () => {
    setPage(page - 1);
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const onSubmit = async () => {    
    console.log("step1Datastep1Data",step1Data);
    const formattedStep1Data = {
      inheritGiveup: {
        ...step1Data.inheritGiveup,
        date: step1Data.inheritGiveup.date
          ? moment(step1Data.inheritGiveup.date).format("YYYY-MM-DD")
          : null,
        },
    };
    const propertyDepositData = {
      ...formattedStep1Data,
      userId: loggedInUser.uid,
    };
    
    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "inherit_limited",
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: propertyDepositData,
        userId: loggedInUser.uid,
        clientName: userInfo.name, 
        status: "submitted",
      });
      history.push("/submit/success");
    } catch (err) {
      history.push("/submit/fail");
    }
  };

  const steps = [
    {
      title: "한정승인",
      content: (
        <Step1
          data={step1Data}
          setData={setStep1Data}
          onNext={onSubmit}
          itemLayout={itemLayout}
        />
      ),
    },
  ];

  return (
    <>
      <div className="steps-content">{steps[page].content}</div>
    </>
  );
};

export default Certification;
