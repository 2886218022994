import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Fragment, useEffect } from "react";
import Collapse from "components/common/Collapse";
import { useHistory } from "react-router-dom";

import IcCheck from "../assets/svg/IcCheck2.svg";
import IcClip from "../assets/img/IcClip.png";
import IcClipMobile from "../assets/img/IcClipMobile.png";
import IcEstate from "../assets/svg/IcEstateNew_1.svg";
import IcEstateMobile from "../assets/img/IcEstate.png";
import IcEstateOwner from "../assets/svg/IcEstateNew_2.svg";
import IcEstateOwnerMobile from "../assets/img/IcEstateOwner.png";
import IcDumbbellSmall from "../assets/img/IcDumbbellSmall.png";
import IcDumbbellSmallMobile from "../assets/img/IcDumbbellSmall.png";
import IcComplaint from "../assets/svg/IcComplaint.svg";
import IcComplaintMobile from "../assets/img/IcComplaint.png";
import IcCert from "../assets/svg/IcCert.svg";
import IcCertMobile from "../assets/img/IcCert.png";
import IcMoney from "../assets/svg/IcMoneyNew_1.svg";
import IcMoneyMobile from "../assets/img/IcMoney.png";
import IcHome from "../assets/svg/IcHome.svg";
import IcQuote from "../assets/svg/IcQuote.svg";
import ImgIntroduction1 from "../assets/img/ImgIntroduction1.png";
import ImgIntroduction2 from "../assets/svg/ImgIntroduction2.svg";
import ImgIntroduction3 from "../assets/svg/ImgIntroduction3.svg";
import ImgIntroduction4 from "../assets/svg/ImgIntroduction4.svg";
import ImgIntroduction5 from "../assets/svg/ImgIntroduction5.svg";
import ImgPriceSection from "../assets/svg/ImgPriceSection.svg";
import ImgPriceSectionMobile from "../assets/svg/ImgPriceSectionMobile.svg";
import ImgIntroduction1Mobile from "../assets/img/ImgIntroduction1Mobile.png";
import ImgIntroduction2Mobile from "../assets/svg/ImgIntroduction2Mobile.svg";
import ImgIntroduction3Mobile from "../assets/svg/ImgIntroduction3Mobile.svg";
import ImgIntroduction4Mobile from "../assets/svg/ImgIntroduction4Mobile.svg";
import ImgIntroduction5Mobile from "../assets/svg/ImgIntroduction5Mobile.svg";
import IcLandingCheckMark from "../assets/svg/IcLandingCheckMark.svg";
import IcCarouselHome from "../assets/svg/IcCarouselHome.svg";
import IcCarouselDumbbell from "../assets/svg/IcCarouselDumbbell.svg";
import IcCarouselCrimininal from "../assets/svg/IcCarouselCrimininal.svg";
import IcNoise from "../assets/svg/IcNoise.svg";

import IcLandingEasy1 from "../assets/svg/IcLandingEasy1.svg";
import IcLandingEasy2 from "../assets/svg/IcLandingEasy2.svg";
import IcLandingEasy3 from "../assets/svg/IcLandingEasy3.svg";
import IcLandingEasy4 from "../assets/svg/IcLandingEasy4.svg";
import IcLandingPress1 from "../assets/img/IcLandingPress1.png";
import IcLandingPress2 from "../assets/img/IcLandingPress2.png";

import Footer from "components/Footer";

import {
  CarouselCard,
  CarouselCardTopImg,
  CarouselCardTopTextWrapper,
  CarouselCardTopWrapper,
  CarouselDescWrapper,
  CarouselSlider,
  CarouselSliderWrapper,
  CollapseWrapper,
  Container,
  ContentContainer,
  DescContainer,
  DescItem,
  DescItemWrapper,
  DescSpan,
  DescWrapper,
  ExternalLinkWrapper,
  ExternalLinkImgWrapper,
  ExternalLinkImg,
  LandingDesc,
  LandingCardMainImg,
  LandingSoughtAfterCardText,
  LandingSoughtAfterServiceCard,
  LandingServiceRowWrapper,
  LandingOtherNewServiceCard,
  LandingOtherNewServiceCardText,
  ReversedServiceCard,
  SectionSecondDesc,
  SectionSecondFooterWrapper,
  SectionSecondFooterImg,
  SectionSecondFooterText,
  SectionSecondTitle,
  SecondSectionFooter,
  SectionSubTitle,
  SectionTitle,
  ServiceCard,
  ServiceCardDesc,
  ServiceCardImg,
  ServiceCardImgWrapper,
  ServiceCardNumTitle,
  ServiceCardNumTitleWrapper,
  ServiceCardPicture,
  ServiceCardRow,
  ServiceCardTitle,
  ServiceCardTitleDescWrapper,
  ServiceCardTitleDescWrapperReversed,
  SelectDescWrapper,
  SelectDescText,
  SelectDescImg,
  Title,
  TitleSpan,
  SectionFirst,
  SectionPrice,
  PriceWrapper,
  PriceContainer,
  PriceContentWrapper,
  PriceContentText,
  PriceContentTextSpan,
  PriceContentImg,
  PriceContentDesc,
  SectionSecond,
  SectionThird,
  NextArrowWrapper,
  PrevArrowWrapper,
  SectionFourth,
  SectionFifth,
  SectionEasy,
  LandingEasyWrapper,
  LandingEasyText,
  LandingEasyRowWrapper,
  LandingEasyCard,
  LandingEasyCardImg,
  LandingEasyCardText,
  SectionPress,
  LandingPressWrapper,
  LandingPressText,
  LandingPressRowWrapper,
  LandingPressCard,
  LandingPressCardImg,
  LandingPressCardTextWrapper,
  LandingPressCardTitle,
  LandingPressCardDesc,
  LandingOtherNewServiceEmptyCard,
} from "./Landing.style";

const settings = {
  infinite: true,
  slidesToShow: 1,
  // arrows: false,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
const Landing = () => {
  const history = useHistory();
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sectionThirdTitle = () => {
    const text = isMobile
      ? "이런 법률 문제가 있는\n사람에게 정확한 솔루션을\n제공합니다"
      : "이런 법률 문제가 있는 사람에게\n정확한 솔루션을 제공합니다";
    return removeBrTag(text);
  };

  const sectionPriceContent = () => {
    return <PriceContentText>
      비용 부풀리기 없는,<br /><PriceContentTextSpan>투명한 가격</PriceContentTextSpan> 정책
    </PriceContentText>
  };
  const saughtEstate2Desc = () => {
    const text = isMobile
      ? "전월세\n보증금 받기"
      : "전월세 보증금\n돌려받기";

    return removeBrTag(text);
  };

  const saughtIncomeDesc = () => {
    const text = isMobile
      ? "월급, 퇴직금\n또는 권고사직"
      : "밀린 월급,퇴직금\n권고사직 대응하기";

    return removeBrTag(text);
  };

  const saughtEstateDesc = () => {
    const text = isMobile
      ? "임차권 등기"
      : "임차권 등기명령";

    return removeBrTag(text);
  };

  const saughtLoanDesc = () => {
    const text = isMobile
      ? "빌려준 돈 받기"
      : "빌려준 돈 돌려받기";

    return removeBrTag(text);
  };

  const sectionThirdDesc = () => {
    const text = "법대리는 과도한 상담, 부담되는 비용 때문에\n변호사를 찾지 못한 고객님을 위해 탄생하였습니다.\n합리적인 비용으로 1:1 맞춤 상담을 약속드립니다."
    return removeBrTag(text);
  };

  const carouselFirstDesc = () => {
    const text = isMobile
      ? "전세 계약 만료일이 되었음에도 보증금을 돌려줄 돈이 없다고 뻔뻔하게 나오던 집주인 때문에 스트레스를 받고 있었습니다. 법대리 서비스를 통해 변호사 이름으로 내용증명을 발송했더니 집주인이 압박을 느꼈는지 바로 연락이 왔고 이틀 뒤에 바로 전세금을 돌려 받았습니다."
      : "전세 계약 만료일이 되었음에도 보증금을 돌려줄 돈이 없다고\n뻔뻔하게 나오던 집주인 때문에 스트레스를 받고 있었습니다.\n법대리 서비스를 통해 변호사 이름으로 내용증명을 발송했더니\n집주인이 압박을 느꼈는지 바로 연락이 왔고 이틀 뒤에 바로 전세금을 돌려 받았습니다.";

    return removeBrTag(text);
  };

  const carouselSecondDesc = () => {
    const text = isMobile
      ? "전세금을 못 돌려 받아 HUG 보증보험 이행청구를 해야하는 상황이었습니다. 내용증명도 보내고 임차권 등기명령도 신청해야 했는데, 직장을 다니며 혼자 진행하기 너무 어렵더라고요. 법대리를 통해 다른 법무사보다 훨씬 저렴한 가격으로 해결할 수 있어서 좋았습니다."
      : "전세금을 못 돌려 받아 HUG 보증보험 이행청구를 해야하는\n상황이었습니다. 내용증명도 보내고 임차권 등기명령도\n신청해야 했는데, 직장을 다니며 혼자 진행하기\n너무 어렵더라고요. 법대리를 통해 다른 법무사보다\n훨씬 저렴한 가격으로 해결할 수 있어서 좋았습니다.";
    return removeBrTag(text);
  };

  const carouselThirdDesc = () => {
    const text = isMobile
      ? "20회에 150만원인 PT를 결제했다가, 개인 사정으로 헬스장 측에 환불을 요청했습니다. 그런데 정책 상 환불이 안 된다고 하여 큰 스트레스를 받고 있었죠. 다행히 법대리 법률 서비스로 무사히 환불을 받아 문제를 해결할 수 있었네요. 제 돈을 지킬 수 있어 한시름 덜었습니다."
      : "20회에 150만원인 PT를 결제했다가, 개인 사정으로 헬스장\n측에 환불을 요청했습니다. 그런데 정책 상 환불이 안 된다고\n 하여 큰 스트레스를 받고 있었죠. 다행히 법대리 법률 서비스로\n 무사히 환불을 받아 문제를 해결할 수 있었네요. 제 돈을 지킬 수 있어 한시름 덜었습니다.";
    return removeBrTag(text);
  };

  const carousel4thDesc = () => {
    const text = isMobile
      ? "형편상 과한 처벌을 받게 될 경우, 생계에 큰 타격을 입는 상황이었습니다. 법대리에서 제 사정에 맞는 양형자료와 의견서 준비를 도와주셨고, 다행히 기소유예 처분을 받았습니다. 다시는 이러한 과오를 저지르지 않도록 평생 뉘우치며 살도록 하겠습니다."
      : "형편상 과한 처벌을 받게 될 경우, 생계에 큰 타격을 입는\n상황이었습니다. 법대리에서 제 사정에 맞는 양형자료와\n의견서 준비를 도와주셨고, 다행히 기소유예 처분을 받았습니다. 다시는 이러한 과오를 저지르지 않도록 평생 뉘우치며\n살도록 하겠습니다.";
    return removeBrTag(text);
  };

  const sectionSecondDescText = () => {
    const text = isMobile
      ? "최단시간에, 최저가로 변호사를 선임해서\n법률문제를 해결할 수 있어요"
      : "최단시간에, 최저가로 변호사를 선임해서 법률문제를 해결할 수 있어요";
    return removeBrTag(text);
  };

  const carouselFirstFooterText = () => {
    const text = isMobile
      ? "법대리에서 전세사기 사건을 해결한 \n김** 고객의 후기"
      : "법대리에서 전세사기 사건을 해결한 김** 고객의 후기";
    return removeBrTag(text);
  };

  const carouselSecondFooterText = () => {
    const text = isMobile
      ? "법대리에서 전세사기 사건을 해결한 \n박** 고객의 후기"
      : "법대리에서 전세사기 사건을 해결한 박** 고객의 후기";
    return removeBrTag(text);
  };

  const carouselThirdFooterText = () => {
    const text = isMobile
      ? "법대리에서 헬스/필라테스 환불 사건을 해결한 \n최** 고객의 후기"
      : "법대리에서 헬스/필라테스 환불 사건을 해결한 최** 고객의 후기";
    return removeBrTag(text);
  };

  const carousel4thFooterText = () => {
    const text = isMobile
      ? "법대리에서 음주운전 사건을 해결한 \n이** 고객의 후기"
      : "법대리에서 음주운전 사건을 해결한 이** 고객의 후기";
    return removeBrTag(text);
  };

  const landingEasyTitle = () => {
    return <PriceContentText>
      간편하게 <PriceContentTextSpan>스마트폰</PriceContentTextSpan>으로 <br />모든 서비스를
    </PriceContentText>
  };

  const frequentText2 = () => {
    const text = isMobile
      ? "법대리는 왜\n타 서비스 대비 저렴한가요?"
      : "법대리는 왜 타 서비스 대비 저렴한가요?";
    return removeBrTag(text);
  };

  const secondFooterText = () => {
    const text = isMobile
      ? "법대리는 전국 어디서나\n즉시 이용할 수 있습니다.\n비대면으로 지역 상관 없이 신청하세요!"
      : "법대리는 전국 어디서나 즉시 이용할 수 있습니다.\n비대면으로 지역 상관 없이 신청하세요!";
    return removeBrTag(text);
  };


  const onClickClipBoard = () => {
    window.fn_sendFB("clip");
  };

  return (
    <Container>
      <SectionFirst>
        <ContentContainer>
          <Title>
            가장 <TitleSpan>쉬운 </TitleSpan>법률 서비스,
            <br />법대리로 시작하세요
          </Title>
          <LandingDesc>{sectionThirdDesc()}</LandingDesc>
          <DescContainer>
            <DescWrapper>
              <DescItemWrapper>
                <img src={IcCheck} />
                <DescItem>법률 검토 비용 <DescSpan>0원</DescSpan></DescItem>
              </DescItemWrapper>
              <DescItemWrapper>
                <img src={IcCheck} />
                <DescItem><DescSpan>3분</DescSpan>이면 끝나는 신청</DescItem>
              </DescItemWrapper>
              <DescItemWrapper>
                <img src={IcCheck} />
                <DescItem><DescSpan>담당 변호사</DescSpan>가 직접 해결</DescItem>
              </DescItemWrapper>
            </DescWrapper>
          </DescContainer>
          <SelectDescWrapper>
            <SelectDescText>
              많이 찾는 서비스
            </SelectDescText>
            <LandingServiceRowWrapper>
              <LandingSoughtAfterServiceCard onClick={() => history.push("/services/housing_protect")}>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={IcEstateMobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={IcEstate}
                  />
                  <LandingCardMainImg fixedHeight={"30.17px"} src={IcEstate} />
                </picture>
                <LandingSoughtAfterCardText>
                  {(saughtEstateDesc())}
                </LandingSoughtAfterCardText>
              </LandingSoughtAfterServiceCard>
              <LandingSoughtAfterServiceCard onClick={() => history.push("/services/estate_all")}>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={IcEstateOwnerMobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={IcEstateOwner}
                  />
                  <LandingCardMainImg fixedHeight={"28.5px"} src={IcEstateOwner} />
                </picture>
                <LandingSoughtAfterCardText>
                  {saughtEstate2Desc()}
                </LandingSoughtAfterCardText>
              </LandingSoughtAfterServiceCard>
            </LandingServiceRowWrapper>
            <LandingServiceRowWrapper>
              <LandingSoughtAfterServiceCard onClick={() => history.push("/services/pilates")}>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={IcDumbbellSmallMobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={IcDumbbellSmall}
                  />
                  <LandingCardMainImg fixedHeight={"30px"} src={IcDumbbellSmall} />
                </picture>
                <LandingSoughtAfterCardText>
                  헬스, 필라테스<br />환불받기
                </LandingSoughtAfterCardText>
              </LandingSoughtAfterServiceCard>
              <LandingSoughtAfterServiceCard onClick={() => history.push("/services/loan")}>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={IcMoneyMobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={IcMoney}
                  />
                  <LandingCardMainImg fixedHeight={"30px"} src={IcMoney} />
                </picture>
                <LandingSoughtAfterCardText>
                  {(saughtLoanDesc())}
                </LandingSoughtAfterCardText>
              </LandingSoughtAfterServiceCard>
            </LandingServiceRowWrapper>
            <LandingServiceRowWrapper>
              <LandingSoughtAfterServiceCard customGap={true} onClick={() => history.push("/services/certification")}>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={IcCertMobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={IcCert}
                  />
                  <LandingCardMainImg fixedHeight={"30px"} src={IcCert} />
                </picture>
                <LandingSoughtAfterCardText>
                  내용 증명 보내기
                </LandingSoughtAfterCardText>
              </LandingSoughtAfterServiceCard>
              <LandingSoughtAfterServiceCard customGap={true} onClick={() => history.push("/services/complaint")}>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={IcComplaintMobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={IcComplaint}
                  />
                  <LandingCardMainImg fixedHeight={"30px"} src={IcComplaint} />
                </picture>
                <LandingSoughtAfterCardText>
                  고소장 작성하기
                </LandingSoughtAfterCardText>
              </LandingSoughtAfterServiceCard>
            </LandingServiceRowWrapper>
            {/* <LandingServiceRowWrapper>
              <LandingSoughtAfterServiceCard onClick={() => history.push("/services/pay")}>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={IcIncomeMobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={IcInComeNew}
                  />
                  <LandingCardMainImg src={IcInComeNew} />
                </picture>
                <LandingSoughtAfterCardText>
                  {saughtIncomeDesc()}
                </LandingSoughtAfterCardText>
              </LandingSoughtAfterServiceCard>
              <LandingSoughtAfterServiceCard onClick={() => history.push("/services/online_scam")}>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={IcTradeMobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={IcTrade}
                  />
                  <LandingCardMainImg src={IcTrade} />
                </picture>
                <LandingSoughtAfterCardText>
                  중고거래, 사기<br />고소하기
                </LandingSoughtAfterCardText>
              </LandingSoughtAfterServiceCard>
            </LandingServiceRowWrapper> */}
          </SelectDescWrapper>
          <SelectDescWrapper>
            <SelectDescText>
              다른 서비스 보기
            </SelectDescText>
            <LandingServiceRowWrapper>
              <LandingOtherNewServiceCard onClick={() => history.push("/services/contract")}>
                <LandingOtherNewServiceCardText>
                  계약서 검토하기
                </LandingOtherNewServiceCardText>
              </LandingOtherNewServiceCard>
              <LandingOtherNewServiceCard onClick={() => history.push("/services/agreement")}>
                <LandingOtherNewServiceCardText>
                  합의서 작성하기
                </LandingOtherNewServiceCardText>
              </LandingOtherNewServiceCard>
            </LandingServiceRowWrapper>
            <LandingServiceRowWrapper>
              <LandingOtherNewServiceCard onClick={() => history.push("/services/opinion")}>
                <LandingOtherNewServiceCardText>
                  의견서 작성하기
                </LandingOtherNewServiceCardText>
              </LandingOtherNewServiceCard>
              <LandingOtherNewServiceCard onClick={() => history.push("/services/loan")}>
                <LandingOtherNewServiceCardText>
                  지급 명령 접수하기
                </LandingOtherNewServiceCardText>
              </LandingOtherNewServiceCard>
            </LandingServiceRowWrapper>
          </SelectDescWrapper>
          <SelectDescWrapper>
            <SelectDescText>
              찾으시는 항목이 없나요?
            </SelectDescText>
            <LandingServiceRowWrapper>
              <LandingOtherNewServiceCard onClick={() => history.push("/services/etc_law")}>
                <LandingOtherNewServiceCardText>
                  기타 법률 문의하기
                </LandingOtherNewServiceCardText>
              </LandingOtherNewServiceCard>
            </LandingServiceRowWrapper>
          </SelectDescWrapper>
        </ContentContainer>
      </SectionFirst>
      <SectionPrice>
        <ContentContainer>
          <PriceWrapper>
            <PriceContainer>
              <PriceContentWrapper>
                {sectionPriceContent()}
                <ServiceCardPicture>
                  <PriceContentImg src={ImgPriceSection} />
                </ServiceCardPicture>
              </PriceContentWrapper>
            </PriceContainer>
          </PriceWrapper>
          <LandingEasyWrapper>
            <LandingEasyText>
              {landingEasyTitle()}
            </LandingEasyText>
            <LandingEasyRowWrapper>
              <LandingEasyCard>
                <LandingEasyCardImg src={IcLandingEasy1} alt="" />
                <LandingEasyCardText>
                  지역상관 없이<br />전국 어디서나
                </LandingEasyCardText>
              </LandingEasyCard>
              <LandingEasyCard>
                <LandingEasyCardImg src={IcLandingEasy2} alt="" />
                <LandingEasyCardText>
                  24시간<br />무료 법률진단
                </LandingEasyCardText>
              </LandingEasyCard>
            </LandingEasyRowWrapper>
            <LandingEasyRowWrapper>
              <LandingEasyCard>
                <LandingEasyCardImg src={IcLandingEasy3} alt="" />
                <LandingEasyCardText>
                  각 분야<br />전문 변호사 배치
                </LandingEasyCardText>
              </LandingEasyCard>
              <LandingEasyCard>
                <LandingEasyCardImg src={IcLandingEasy4} alt="" />
                <LandingEasyCardText>
                  사건 진행현황<br />지속 안내
                </LandingEasyCardText>
              </LandingEasyCard>
            </LandingEasyRowWrapper>
          </LandingEasyWrapper>
        </ContentContainer>
      </SectionPrice>
      <SectionPress>
        <LandingPressWrapper>
          <LandingPressText>
            언론 속 법대리
          </LandingPressText>
          <LandingPressRowWrapper>
            <LandingPressCard href="https://www.besuccess.com/비대면-변호사-서비스-법대리-전세금-미반환-사/">
              <LandingPressCardImg src={IcLandingPress1} alt="" />
              <LandingPressCardTextWrapper>
                <LandingPressCardTitle>
                  2023.10.23
                </LandingPressCardTitle>
                <LandingPressCardDesc>
                  비대면 법률 서비스 법대리,<br />‘전세금 미반환’ 사건 누적 해결 보증금<br />5천억 돌파
                </LandingPressCardDesc>
              </LandingPressCardTextWrapper>
            </LandingPressCard>
            <LandingPressCard href="http://www.shinailbo.co.kr/news/articleView.html?idxno=1771401">
              <LandingPressCardImg src={IcLandingPress2} alt="" />
              <LandingPressCardTextWrapper>
                <LandingPressCardTitle>
                  2023.10.15
                </LandingPressCardTitle>
                <LandingPressCardDesc>
                  간편 생활 법률 서비스 법대리,<br />‘형사ㆍ민사 재판’ 변호사 선임 서비스 출시
                </LandingPressCardDesc>
              </LandingPressCardTextWrapper>
            </LandingPressCard>
          </LandingPressRowWrapper>
        </LandingPressWrapper>
      </SectionPress>
      <SectionSecond>
        <ContentContainer>
          <SectionSecondTitle>법대리 이용 가이드</SectionSecondTitle>
          <SectionSecondDesc>{sectionSecondDescText()}</SectionSecondDesc>
          <ServiceCardRow>
            <ReversedServiceCard>
              <ServiceCardImgWrapper>
                <ServiceCardPicture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={ImgIntroduction1Mobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={ImgIntroduction1}
                  />
                  <ServiceCardImg src={ImgIntroduction1} />
                </ServiceCardPicture>
              </ServiceCardImgWrapper>
              <ServiceCardTitleDescWrapper>
                <ServiceCardNumTitleWrapper>
                  <ServiceCardNumTitle>1</ServiceCardNumTitle>
                  <ServiceCardTitle>원하는 법률 서비스 선택</ServiceCardTitle>
                </ServiceCardNumTitleWrapper>
              </ServiceCardTitleDescWrapper>
            </ReversedServiceCard>
            <ServiceCard>
              <ServiceCardImgWrapper>
                <ServiceCardPicture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={ImgIntroduction2Mobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={ImgIntroduction2}
                  />
                  <ServiceCardImg src={ImgIntroduction2} />
                </ServiceCardPicture>
              </ServiceCardImgWrapper>
              <ServiceCardTitleDescWrapperReversed>
                <ServiceCardNumTitleWrapper>
                  <ServiceCardNumTitle>2</ServiceCardNumTitle>
                  <ServiceCardTitle>신청서 작성</ServiceCardTitle>
                </ServiceCardNumTitleWrapper>
                <ServiceCardDesc>
                  복잡한 법률 지식이나 용어를 모르셔도 됩니다. <br />법적 조치에 꼭 필요한 최소 정보만 작성하시면 끝납니다.
                </ServiceCardDesc>
              </ServiceCardTitleDescWrapperReversed>
            </ServiceCard>
            <ReversedServiceCard style={{ marginRight: 0 }}>
              <ServiceCardImgWrapper>
                <ServiceCardPicture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={ImgIntroduction3Mobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={ImgIntroduction3}
                  />
                  <ServiceCardImg src={ImgIntroduction3} />
                </ServiceCardPicture>
              </ServiceCardImgWrapper>
              <ServiceCardTitleDescWrapper>
                <ServiceCardNumTitleWrapper>
                  <ServiceCardNumTitle>3</ServiceCardNumTitle>
                  <ServiceCardTitle>전문 변호사와 1:1 매칭</ServiceCardTitle>
                </ServiceCardNumTitleWrapper>
              </ServiceCardTitleDescWrapper>
            </ReversedServiceCard>
            <ServiceCard>
              <ServiceCardImgWrapper>
                <ServiceCardPicture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={ImgIntroduction4Mobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={ImgIntroduction4}
                  />
                  <ServiceCardImg src={ImgIntroduction4} />
                </ServiceCardPicture>
              </ServiceCardImgWrapper>
              <ServiceCardTitleDescWrapperReversed leftValue={true}>
                <ServiceCardNumTitleWrapper>
                  <ServiceCardNumTitle>4</ServiceCardNumTitle>
                  <ServiceCardTitle>
                    법대리 인증<br />전문 변호사가 법률 사건을<br />끝까지 전담하고 해결!
                  </ServiceCardTitle>
                </ServiceCardNumTitleWrapper>
                <ServiceCardDesc>
                  내용증명, 공시송달 등 어려운 용어에<br />두려워 마세요. 법대리 인증 전문 변호사가<br />법률 사건 전부를 대신 해결해줘요.
                </ServiceCardDesc>
              </ServiceCardTitleDescWrapperReversed>
            </ServiceCard>
            <ReversedServiceCard style={{ marginRight: 0, marginBottom: 0 }}>
              <ServiceCardImgWrapper>
                <ServiceCardPicture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={ImgIntroduction5Mobile}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={ImgIntroduction5}
                  />
                  <ServiceCardImg src={ImgIntroduction5} />
                </ServiceCardPicture>
              </ServiceCardImgWrapper>
              <ServiceCardTitleDescWrapper leftValue={true}>
                <ServiceCardNumTitleWrapper>
                  <ServiceCardNumTitle>5</ServiceCardNumTitle>
                  <ServiceCardTitle>
                    고객은 법률 사건 진행 상황을
                    <br />
                    법대리에서 상시 확인 가능
                  </ServiceCardTitle>
                </ServiceCardNumTitleWrapper>
                <ServiceCardDesc>
                  이제 언제 어디서든지<br />법대리를 통해 온라인으로 법률 해결 과정을<br />확인할 수 있습니다.
                </ServiceCardDesc>
              </ServiceCardTitleDescWrapper>
            </ReversedServiceCard>
          </ServiceCardRow>
        </ContentContainer>
      </SectionSecond>
      <SecondSectionFooter>
        <SectionSecondFooterWrapper>
          <SectionSecondFooterImg src={IcLandingCheckMark} />
          <SectionSecondFooterText>
            {secondFooterText()}
          </SectionSecondFooterText>
        </SectionSecondFooterWrapper>
      </SecondSectionFooter>
      <SectionThird>
        <ContentContainer>
          <SectionTitle>{sectionThirdTitle()}</SectionTitle>
          <CarouselSliderWrapper>
            <CarouselSlider {...settings}>
              <CarouselCard>
                <CarouselCardTopWrapper>
                  <CarouselCardTopTextWrapper>
                    <img src={IcQuote} style={{ marginBottom: 10 }} />
                    {carouselFirstDesc()}
                  </CarouselCardTopTextWrapper>
                  <CarouselCardTopImg src={IcHome} />
                </CarouselCardTopWrapper>
                <CarouselDescWrapper>
                  {carouselFirstFooterText()}
                </CarouselDescWrapper>
              </CarouselCard>
              <CarouselCard>
                <CarouselCardTopWrapper>
                  <CarouselCardTopTextWrapper>
                    <img src={IcQuote} style={{ marginBottom: 10 }} />
                    {carouselSecondDesc()}
                  </CarouselCardTopTextWrapper>
                  <CarouselCardTopImg src={IcCarouselHome} />
                </CarouselCardTopWrapper>
                <CarouselDescWrapper>
                  {carouselSecondFooterText()}
                </CarouselDescWrapper>
              </CarouselCard>
              <CarouselCard>
                <CarouselCardTopWrapper>
                  <CarouselCardTopTextWrapper>
                    <img src={IcQuote} style={{ marginBottom: 10 }} />
                    {carouselThirdDesc()}
                  </CarouselCardTopTextWrapper>
                  <CarouselCardTopImg src={IcCarouselDumbbell} />
                </CarouselCardTopWrapper>
                <CarouselDescWrapper>
                  {carouselThirdFooterText()}
                </CarouselDescWrapper>
              </CarouselCard>
              <CarouselCard>
                <CarouselCardTopWrapper>
                  <CarouselCardTopTextWrapper>
                    <img src={IcQuote} style={{ marginBottom: 10 }} />
                    {carousel4thDesc()}
                  </CarouselCardTopTextWrapper>
                  <CarouselCardTopImg src={IcCarouselCrimininal} />
                </CarouselCardTopWrapper>
                <CarouselDescWrapper>
                  {carousel4thFooterText()}
                </CarouselDescWrapper>
              </CarouselCard>
            </CarouselSlider>
          </CarouselSliderWrapper>
        </ContentContainer>
      </SectionThird>
      <SectionFourth>
        <ContentContainer>
          <SectionTitle>자주 묻는 질문</SectionTitle>
          <CollapseWrapper>
            <Collapse
              title={"법대리는 어떤 서비스인가요?"}
              desc={
                "법대리는 의뢰인에게 가장 적절한 법률 솔루션을 합리적인 가격으로 제공해주는 서비스입니다. 앱을 통해 사건의 상황을 작성하여 제출하시면 제휴 변호사가 무료로 의견을 드리고 있으며, 필요시 제안받은 금액만큼만 결제하여 서비스를 제공 받으실 수 있습니다."
              }
            />
            <Collapse
              title={frequentText2()}
              desc={
                "저희 법대리는 불필요한 비용과 시간이 소모되는 전화/방문 상담을 없애고 사건해결에 꼭 필요한 과정만을 안내드리고 있습니다. 이를 통해 기존의 법률 서비스 대비 상대적으로 저렴한 금액으로 법률 서비스를 이용할 수 있습니다."
              }
            />
            <Collapse
              title={"무료 법률상담을 받고 싶어요"}
              desc={
                "법대리는 제휴 변호사를 연결시켜주는 역할을 도와드리고 있습니다. 고객님의 상황에 맞는 사건 상황을 제출해주시면 무료로 변호사 의견을 받아보실 수 있습니다."
              }
            />
            <Collapse
              title={"신청 후 답변까지 얼마나 걸리나요?"}
              desc={
                "영업일 기준 보통 1~2일 내로 답변드리고 있습니다. 신청량이 많을 경우에는 답변이 다소 늦을 수도 있으니 참고 부탁드립니다."
              }
            />
            <Collapse
              title={"결제한 다음 얼마나 걸릴까요?"}
              desc={
                "결제 후 우선 필요한 서류들을 요청드리고 있습니다. 필요 서류를 모두 전달해 주신 날로부터 보통 영업일 2~3 일 소요됩니다."
              }
            />
            <Collapse
              title={"변호사를 선임하고 싶어요"}
              desc={
                "법대리를 통해서 변호사 선임을 원하시면, 아래 1:1 채팅으로 문의부탁드립니다. "
              }
            />
          </CollapseWrapper>
        </ContentContainer>
      </SectionFourth>
      <SectionFifth>
        <ContentContainer>
          <SectionSubTitle>
            법률 문제로 어려움을 겪고 있는
            <br />
            가족, 친구에게 법대리를 알려주세요!
          </SectionSubTitle>
          <ExternalLinkWrapper>
            <ExternalLinkImgWrapper
              style={{ marginRight: 0 }}
              onClick={onClickClipBoard}
            >
              <picture>
                <source media="(max-width: 768px)" srcSet={IcClipMobile} />
                <source media="(min-width: 768px)" srcSet={IcClip} />
                <ExternalLinkImg src={IcClip} />
              </picture>
            </ExternalLinkImgWrapper>
          </ExternalLinkWrapper>
        </ContentContainer>
      </SectionFifth>
      <Footer />
    </Container>
  );
};

export default Landing;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <NextArrowWrapper
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <RightOutlined />
    </NextArrowWrapper>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <PrevArrowWrapper
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <LeftOutlined />
    </PrevArrowWrapper>
  );
}

export function removeBrTag(text) {
  return text.split("\n").map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
}