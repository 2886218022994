import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const CommonOrderRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        못받은 돈 돌려받기(지급명령)
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="지급 명령 요청 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="필요한 분야" labelStyle={labelStyle}>
            {serviceData.field}
          </Descriptions.Item>
          {serviceData.etcField ? (
            <>
              <Descriptions.Item label="그 외 필요한 분야" labelStyle={labelStyle}>
                {serviceData.etcField}
              </Descriptions.Item>
            </>
          ) : null}
          <Descriptions.Item label="상대의 주민등록번호 혹은 주소" labelStyle={labelStyle}>
            {serviceData.knowPrivateInfo}
          </Descriptions.Item>
          <Descriptions.Item label="받아야할 금액 일부 반환 여부" labelStyle={labelStyle}>
            {serviceData.partialReturn}
          </Descriptions.Item>
          {serviceData.partialReturnAmount ? (
            <>
              <Descriptions.Item label="일부 반환 받은 금액 " labelStyle={labelStyle}>
                {serviceData.partialReturnAmount}
              </Descriptions.Item>
            </>
          ) : null}
          <Descriptions.Item label="앞으로 받아야할 금액" labelStyle={labelStyle}>
            {serviceData.partialReturnAmount}
          </Descriptions.Item>
          <Descriptions.Item label="받아야할 날 부터 며칠이 지났는지" labelStyle={labelStyle}>
            {serviceData.dayAfterNotReturn}일
          </Descriptions.Item>
          <Descriptions.Item label="받아야 할 금액을 증빙할 자료" labelStyle={labelStyle}>
            {serviceData.proof}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.caseDesc
              ? serviceData.caseDesc.split("\n").map((line, idx) => {
                return (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                );
              })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
        </Descriptions>

        {/* <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">첨부 파일</div>
          </div>
          {serviceData.attachments.map((attachment) => (
            <Image
              key={attachment}
              width={75}
              height={75}
              src={attachment}
              preview={{ zIndex: 10000 }}
            />
          ))}
        </div> */}
      </div>
    </>
  );
};

export default CommonOrderRequest;
