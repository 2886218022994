import { Checkbox } from "components/home/CommonCriminal/CheckBox"
import { EventSubmitBtn, EventSubmitContainer, EventSubmitDesc, EventSubmitDescContainer, EventSubmitSection, EventSubmitTextContainer, EventSubmitTitle } from "./EventSubmit.style"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "fbase";
import { getServiceTypeTitle } from "components/mypage/MyCaseList";
import axios from "axios";
import ReactLoading from 'react-loading';
import { LoadingWrapper } from "components/mypage/MyCase.style";

export const EventSubmit = (props) => {
  const history = useHistory();
  const [isCheck, setIsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

  }, [isCheck])

  const handleCheckboxChange = (checkedValues) => {
    setIsCheck(checkedValues);
  };

  const onSubmit = async () => {
    const serviceData = props.eventServiceData;
    const serviceType = props.serviceType;
    const name = props.name;
    var phoneRule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
    const phoneRegex = phoneRule.test(props.phone);
    if (phoneRegex === false) {
      alert('유효한 핸드폰 번호를 입력해주세요');
      return
    }
    const phone = formatPhoneNumber(props.phone);
    const uuid = uuidv4();
    setIsLoading(true);
    try {
      await dbService.collection("to3o3_requests").add({
        serviceType: serviceType,
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: serviceData,
        name: name,
        phone: phone,
        status: "submitted"
      });
      await sendWebhook(serviceType, uuid, name, phone)
      setIsLoading(false);
      history.push('/3o3/success');
    } catch (err) {
      // history.push("/submit/fail");
      console.log(err);
      alert('다시 시도해주세요');
    }
  };

  async function sendWebhook(serviceType, servieId, name, phone) {
    const webhookUrl = process.env.REACT_APP_DISCORD_TO3_SUBMIT_WEBHOOK_DOMAIN;
    const serviceTypeTitle = getServiceTypeTitle(serviceType)
    console.log("serviceType", serviceType);
    const message = {
      content: `🔥삼쩜삼 신청 완료🔥
      사건 타입: ${serviceTypeTitle}
      유저: ${name} 
      전화번호: ${phone}
      serviceId(확인용): ${servieId}`
    };

    try {
      const { response } = await axios.post(webhookUrl, message);
    } catch (e) {
    }
  }

  function formatPhoneNumber(phoneNumber) {
    // 하이픈 제거
    const cleanNumber = phoneNumber.replace(/-/g, '');

    // 번호 형식 확인 및 변환
    if (cleanNumber.startsWith('010')) {
      const mainPart = cleanNumber.substring(3); // "010" 제외 나머지 숫자
      return `+82 10-${mainPart.slice(0, 4)}-${mainPart.slice(4)}`;
    }

    // 변환할 수 없는 형식이면 원본 반환
    return phoneNumber;
  }

  const isAllReady = () => {
    return props.active && isCheck
  }

  if (isLoading) return <LoadingWrapper> <ReactLoading type={"spin"} color={"#3a7efc"} height={'10%'} width={'10%'}/> </LoadingWrapper>; // loading screen

  return (
    <EventSubmitSection active={isAllReady()}>
      <EventSubmitContainer>
        <EventSubmitTextContainer>
          <EventSubmitTitle>
            위 내용으로<br />제출할까요?
          </EventSubmitTitle>
          <EventSubmitDescContainer>
            <EventSubmitDesc>
              작성해주신 정보는 전문 변호사에게 공유되며, 모든 비용은 변호사에게 지급됩니다.
              법대리는 중개 서비스로 자문 내용에 책임이 없음을 확인했습니다.
            </EventSubmitDesc>
            <Checkbox
              customStyle={{
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                width: "20px",
                height: "20px",
              }}
              onChange={handleCheckboxChange} />
          </EventSubmitDescContainer>
        </EventSubmitTextContainer>
        <EventSubmitBtn disabled={!isAllReady()} active={isAllReady()} onClick={onSubmit}>
          제출하기
        </EventSubmitBtn>
      </EventSubmitContainer>
    </EventSubmitSection>
  )
}