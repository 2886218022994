import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Radio,
  Popover,
  Divider,
  Row,
  Col,
  Checkbox,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper,
} from "../../Form.style";

const Step2 = ({ data, setData, onPrev, onNext, itemLayout }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async (values) => {
    // console.log(values);
    setData(values);
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        고소장 작성 및 상세내용
      </Title>

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          입력한 내용을 바탕으로 고소가 가능한지
          <br />
          여부 확인 및 변호사가 고소장 작성을 진행합니다
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper style={{height:`90%`}}>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          form={form}
          style={{
            marginTop: 10,
            marginBottom: 20,
            width:"100%",
            textAlign:"left"
          }}
          requiredMark={false}
        >
          <Form.Item
            label={
              <>
                가해자 정보
                <Popover
                  content={
                    <div>
                      가해자에 대해 알고있는 정보를 모두 선택해주세요.
                      <br />
                      없으면 선택하지 않으시면 됩니다.
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["offender", "info"]}
          >
            <Checkbox.Group>
              <Row>
                <Col span={20}>
                  <Checkbox
                    value="name"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    이름 알고있음
                  </Checkbox>
                </Col>
                <Col span={20}>
                  <Checkbox
                    value="id"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    ID(캐릭터명) 알고 있음
                  </Checkbox>
                </Col>
                <Col span={20}>
                  <Checkbox
                    value="address"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    주소 알고있음
                  </Checkbox>
                </Col>
                <Col span={20}>
                  <Checkbox
                    value="phone"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    전화번호 알고있음
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            label="가해자의 모욕 횟수"
            name={["offender", "insultCount"]}
            rules={[
              {
                required: true,
                message: "가해자의 모욕 횟수를 선택해주세요",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="one">1회</Radio.Button>
              <Radio.Button value="many">2회 이상</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                가해자의 모욕 발언
                <Popover
                  content={
                    <div>
                      가장 모욕성이 심한 발언을 500자 이내로 작성해주세요
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["offender", "insult"]}
            rules={[
              {
                required: true,
                message: "가해자의 모욕 발언을 작성해주세요",
              },
            ]}
          >
            <TextArea rows={10} maxLength={500} />
          </Form.Item>

          <Row justify="space-between" style={{ marginTop: 20 }}>
            <Col span={6} offset={0}>
              <Button
                type="primary"
                onClick={onPrev}
                style={{ width: "100%", maxWidth: "100px" }}
              >
                이전
              </Button>
            </Col>

            <Col span={6} offset={12} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                다음
              </Button>
            </Col>
          </Row>
        </Form>
        </CaseDetailWrapper>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step2;
