import Collapse from "components/common/Collapse";
import { useState } from "react";
import { SERVICE_QNA } from "../../service-constants";
import IcHeadset from "../../assets/svg/IcHeadset.svg";
import IcCivilLarge from "../../assets/svg/IcCivilLarge.svg";
import IcCivilStep1 from "../../assets/svg/IcCivilStep1.svg";
import IcCivilStep2 from "../../assets/svg/IcCivilStep2.svg";

import {
  Container,
  BtnConsult,
  CollapseWrapper,
  ContentContainer,
  NoiseServiceCenterSection,
  DetailImg,
  DetailRightBtn,
  DetailRightDesc,
  DetailRightTitle,
  DetailRightWrapper,
  DetailWrapper,
  SubTitle,
  Title,
  TopWrapper,
  SectionTop,
  TopTitle,
  TopDesc,
  TopImg,
  DetailRightLabel,
  DetailRightLabelMobile,
  DetailRightTitleMobile,
} from "../Service.style";
import { SerivceCenterConsultingSection } from "./ServiceCenter.style";
import Footer from "components/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import ChannelService from "../ChannelService";

const CivilServiceCenter = ({loggedInUser}) => {
  const [tab, setTab] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <SectionTop style={{ height: 380 }}>
        <ContentContainer style={{ width: "768px" }}>
          <TopWrapper>
            <TopTitle>민사 재판</TopTitle>
            <TopDesc>
            타 법률사무소 대비 1/3 수준의 저렴한 금액으로
            <br/>
            전문 변호사의 조력을 받아 민사 소송을 해결하세요
            </TopDesc>
          </TopWrapper>
          <TopImg src={IcCivilLarge} />
        </ContentContainer>
      </SectionTop>
      <NoiseServiceCenterSection>
        <ContentContainer>
          <DetailWrapper>
            <DetailRightLabelMobile>SIDE</DetailRightLabelMobile>
            <DetailRightTitleMobile>
              민사소송 원고
            </DetailRightTitleMobile>
            <DetailImg src={IcCivilStep1} />
            <DetailRightWrapper>
              <DetailRightLabel>SIDE</DetailRightLabel>
              <DetailRightTitle>민사소송 원고</DetailRightTitle>
              <DetailRightDesc>
              소송을 제기하고 싶은 법률 사건이 있나요?<br/>
              법대리에서 사건에 알맞은 합리적인 가격에<br/>
              문제를 해결해드립니다.
              </DetailRightDesc>
              <Link to="/civil/defendant">
                <DetailRightBtn>신청하기</DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          <DetailWrapper>
            <DetailRightLabelMobile>SIDE</DetailRightLabelMobile>
            <DetailRightTitleMobile>
              민사 소송 피고
            </DetailRightTitleMobile>
            <DetailImg src={IcCivilStep2} />
            <DetailRightWrapper>
              <DetailRightLabel>SIDE</DetailRightLabel>
              <DetailRightTitle>민사 소송 피고</DetailRightTitle>
              <DetailRightDesc>
              소송을 당해 곤란한 상황에 처해 있나요?<br/>
              법대리에서 사건에 알맞은 합리적인 가격에<br/>
              문제를 해결해드립니다.
              </DetailRightDesc>
              <Link to="/civil/plaintiff">
                <DetailRightBtn>신청하기</DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          <CollapseWrapper>
            {SERVICE_QNA.civil.map(({ title, desc }, idx) => (
              <Collapse
                key={`civil-service$-${idx}`}
                title={title}
                desc={desc}
              />
            ))}
          </CollapseWrapper>
        </ContentContainer>
      </NoiseServiceCenterSection>
      <SerivceCenterConsultingSection>
        <ContentContainer>
          <Title style={{ marginBottom: 10, marginTop: 0 }}>
            기타 민사소송 상담
          </Title>
          <SubTitle style={{ marginBottom: 0 }}>
            복잡한 법률 용어, 지식은 모르셔도 됩니다.
            <br />
            법대리가 알려드리는 항목들만 작성 및 첨부해 주시면 법대리의 전문
            변호사단이 검토 후 의견을 드립니다.
          </SubTitle>
          <BtnConsult
            onClick={() => {
              // LandingToChannelTalk();
              if(!loggedInUser){
                ChannelService.loadScript();
                ChannelService.boot({
                  "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
                })
              }
              ChannelService.showMessenger();
            }}
          >
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </BtnConsult>
        </ContentContainer>
      </SerivceCenterConsultingSection>
      <Footer />
    </Container>
  );
};

export default CivilServiceCenter;
