import styled from "@emotion/styled/macro";
import Slider from "react-slick";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
`;

export const ContentContainer = styled.div`
  max-width: 1072px;
  margin: 0 auto;
  width: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const SectionFirst = styled.section`
  height: auto;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 50px;
  @media screen and (max-width: 768px) {
    height: auto;
    padding-left: 17.5px;
    padding-right: 17.5px;
    padding-top: 44px;
    padding-bottom: 25px;
  }
`;
export const SectionSecond = styled.section`
  width: 100%;
  background: white;
  height: auto;
  padding-top: 150px;
  padding-bottom: 150px;
  @media screen and (max-width: 768px) {
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const SecondSectionFooter = styled.section`
  width: 100%;
  height: auto;
  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

export const SectionPress = styled.section`
  width: 100%;
  height: auto;
  padding-top: 150px;
  padding-bottom: 150px;
  padding-left: 0px;
  padding-right: 0px;
  background: #F5F7F8;
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 50px 16px;
  }
`;

export const LandingPressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 46px;
  @media screen and (max-width: 768px) {
    gap: 25px;
  }
`

export const LandingPressText = styled.div`
  color: #1F252E;
  text-align: center;
  font-family: Pretendard;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 135% */
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 38px;
  }
`

export const LandingPressRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 641px;
  gap: 13px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const LandingPressCard = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;
`

export const LandingPressCardImg = styled.img`
  width: 100%;
  height: auto;
`

export const LandingPressCardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;  
`

export const LandingPressCardTitle = styled.div`
  color: #8F8F8F;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const LandingPressCardDesc = styled.div`
  color: #1F252E;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
`

export const SectionEasy = styled.section`
  width: 100%;
  height: auto;
  padding-top: 150px;
  padding-bottom: 150px;
  background: #3B4044;
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 50px 16px;
  }
`;

export const LandingEasyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 46px;
  @media screen and (max-width: 768px) {
    gap: 25px;
  }
`

export const LandingEasyText = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 135% */
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 38px;
  }  
`

export const LandingEasyRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
  }  
`

export const LandingEasyCard = styled.div`
  display: flex;
  width: 230px;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 16px;
  background: #FFF;
  @media screen and (max-width: 768px) {
    flex: 1 0 20%;
    padding: 10px 10px;
    flex-basis: 40%; /* 2개의 플렉스 아이템을 50%의 너비로 설정합니다. */
    height: 160px;
  }
`

export const LandingEasyCardImg = styled.img`
  width: 54px;
  height: 54px;
`

export const LandingEasyCardText = styled.div`
  color: #121212;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  @media screen and (max-width: 768px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
  }
`


export const SectionPrice = styled.section`
  width: 100%;
  height: auto;
  padding-top: 120px;
  padding-bottom: 120px;
  background: #3B4044;
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 50px 16px;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 120px;
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`

export const PriceContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  @media screen and (max-width: 768px) {
    gap: 25px;
  }
`

export const PriceContentText = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 139.13% */
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 38px; /* 135% */    
  }
`

export const PriceContentDesc = styled.div`
  color: #AAA;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`

export const PriceContentTextSpan = styled.span`
  color: #3A7EFC;
`

export const PriceContentImg = styled.img`
  width: 100%;
  height: auto;
`

export const SectionThird = styled.section`
  width: 100%;
  background: #eceff2;
  padding-top: 150px;
  padding-bottom: 150px;
  @media screen and (max-width: 768px) {
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
`;

export const CarouselSliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CarouselSlider = styled(Slider)`
  max-width: 800px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const SectionFourth = styled.section`
  width: 100%;
  background: white;
  padding-top: 150px;
  padding-bottom: 150px;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const SectionFifth = styled.section`
  width: 100%;
  height: 330px;
  background: #4489F9;
  padding-top: 71px;
  padding-bottom: 71px;
  @media screen and (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const Title = styled.h1`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  color: #1F252E;
  text-align: center;
  line-height: 68px;
  @media screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 42px;
  }
`;

export const LandingDesc = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  color: #1F252E;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 34px;
    margin-bottom: 34px;
  }
`;

export const LandingDescDivider = styled.div`
  background: #86D7FF;
  height: 1px;
  width: 100%;
  margin-bottom: 40px;  
  @media screen and (max-width: 768px) {
    margin-bottom: 22px;
  }
`;

export const SelectDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
  }
`

export const SelectDescText = styled.div`
color: #1F252E;
text-align: center;
font-family: Pretendard;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 0% */
height: 50px;
`

export const SelectDescImg = styled.img`
  width: 27px;
  height: 27px;
`

export const LandingCardRowWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 7px;
  justify-content: flex-start;
  margin-bottom: 7px;
  flex-wrap: wrap;
  width: 693px;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    width: auto;
  }
`

export const LandingServiceRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
`

export const LandingSoughtAfterServiceCard = styled.div`    
  display: flex;
  width: 100%;
  height: 71px;
  padding: 14px 4px 14px 20px;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #3A7EFC;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    flex: 1 0 30%;
    width: 100%;
    flex-basis: 40%; /* 2개의 플렉스 아이템을 50%의 너비로 설정합니다. */
    gap: ${(props) => (props.customGap ? "2px" : "8px")};
    padding: 14px 4px 14px 20px;
  }
`

export const LandingSoughtAfterCardText = styled.div`
  color: #FFF;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
`

export const LandingOtherNewServiceCard = styled.div`    
  display: flex;
  width: 100%;
  height: 71px;
  padding: 14px 4px 14px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #F5F5F5;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    flex: 1 0 50%;
    width: 100%;
    flex-basis: 40%; /* 2개의 플렉스 아이템을 50%의 너비로 설정합니다. */
  }
`

export const LandingOtherNewServiceEmptyCard = styled.div`  
  display: flex;
  width: 100%;
  height: 71px;
  @media screen and (max-width: 768px) {
    flex: 1 0 50%;
    width: 100%;
    flex-basis: 40%; /* 2개의 플렉스 아이템을 50%의 너비로 설정합니다. */
  }
`

export const LandingOtherNewServiceCardText = styled.div`
  color: #1F252E;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
`

export const LandingCardBottomSpace = styled.div`
  margin-bottom: 38px;
  @media screen and (max-width: 768px) {
    margin-bottom: 27px;
}
`

export const LandingCardText = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
`

export const LandingCard = styled.div`  
  flex-basis: 168px;
  display: flex;
  padding: 14px 4px 14px 10px;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 4px 4px 8px 2px rgba(0, 121, 233, 0.05);
  cursor: pointer;
  &:hover {
    background: #57A4FF;
    /* 자식 요소의 스타일 변경 */
  }

  &:hover > ${LandingCardText} {
    color: #fff;
  }
 
  @media screen and (max-width: 768px) {
    flex: 1 0 20%;
    height: 70px;
    flex-basis: 40%; /* 2개의 플렉스 아이템을 50%의 너비로 설정합니다. */
  }
`

export const LandingCardOne = styled(LandingCard)`  
  flex-basis: 168px;
  display: flex;
  padding: 14px 10px;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 4px 4px 8px 2px rgba(0, 121, 233, 0.05);
  cursor: pointer;
  &:hover {
    background: #57A4FF;
    /* 자식 요소의 스타일 변경 */
  }

  &:hover > ${LandingCardText} {
    color: #fff;
  }
 
  @media screen and (max-width: 768px) {
    flex: 0;
    height: 70px;
    flex-basis: 49.5%; /* 2개의 플렉스 아이템을 50%의 너비로 설정합니다. */
  }
`

export const LandingCardImg = styled.img`
  width: 44px;
  height: 36px;
`

export const LandingCardMainImg = styled.img`
  width: auto;
  height: ${(props) => (props.fixedHeight)};
`

export const TitleSpan = styled.span`
  color: #3a7efc;
`;

export const LandingOtherServiceCardRowWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 7px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const LandingOtherServiceCardText = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
`

export const LandingOtherServiceCardDesc = styled(LandingOtherServiceCardText)`
  color: #434343;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
`

export const LandingOtherServiceCardTextWrapper = styled.div`
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  &:hover {
    background: #57A4FF;
    /* 자식 요소의 스타일 변경 */
  }
`

export const LandingOtherServiceCard = styled.div`
  flex: 1;
  display: flex;
  height: 70px;
  padding: 14px 10px;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 4px 4px 8px 2px rgba(0, 121, 233, 0.05);
  cursor: pointer;
  &:hover {
    background: #57A4FF;
    /* 자식 요소의 스타일 변경 */
  }

  &:hover > ${LandingOtherServiceCardTextWrapper} > ${LandingOtherServiceCardText} {
    color: #fff;
  }

  &:hover > ${LandingOtherServiceCardTextWrapper} > ${LandingOtherServiceCardDesc} {
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const DescContainer = styled.div`
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
  margin-bottom: 63.5px;
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`

export const DescSpan = styled.span`
  color: #3A7EFC;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;  
  gap: 10px;  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DescItemWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 15px;
  @media screen and (max-width: 768px) {
    margin: 0 !important;
  }
`;

export const DescItem = styled.div`
  color: #1F252E;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const BtnFreeEstimate = styled.button`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  color: #ffffff;
  background: #3a7efc;
  border-radius: 6px;
  outline: none;
  border: none;
  padding: 27px 56px;
  display: flex;
  width: 350px;
  height: 80px;
  align-items: center;
  margin: 0 auto;
  margin-top: 58px;
  margin-bottom: 136px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 216px;
    height: 52px;
    padding: 15px 31px;
    gap: 0px;    
    margin-top: 34px;
    margin-bottom: 65px;
    font-size: 16px;
  }
`;

export const CardRowWrapper = styled.div`
  display: flex;
  margin-bottom: 22px;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    margin-bottom: 0px;
    padding-bottom: 65px;
  }
`;



export const Card = styled.div`
  flex: 1 0 30%; /* 초기 상태에서 3개의 플렉스 아이템을 33.33%의 너비로 설정합니다. */
  background: #ffffff;
  border-radius: 16px;
  padding: 30px 36px 31px 25px;
  width: 342px;
  height: 262px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: end;
  cursor: pointer;
  margin-bottom: 22px;
  @media screen and (max-width: 768px) {
    width: 35%;
    height: auto;
    padding-right: 0px;
    padding-left: 10px;
    padding-bottom: 0px;
    padding-top: 15px;
    flex-basis: 40%; /* 2개의 플렉스 아이템을 50%의 너비로 설정합니다. */
    width: 152px;
    height: 188px;
    margin-bottom: 11px;
    :nth-child(odd) {
      margin-right: 11px !important;
    }

    :nth-child(even) {
      margin-right: 0px !important;
    }
  }
`;

export const CardImg = styled.img`  
  max-width: 160px;
  @media screen and (max-width: 768px) {
    max-width: 48px;
  }
`;

export const CardImgBig = styled.img`  
  max-width: 160px;
  @media screen and (max-width: 768px) {
    max-width: 60px;
  }
`;

export const CardTitleDescWrapper = styled.div`
  width: 100%;
  background-color: #e9f1f8;
`

export const CardTitle = styled.div`
  padding: 0 0 16px 0;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  width: 100%;
  color: #1f2e3d;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: normal;
  }
`;

export const CardDesc = styled.div`
  padding: 0 0 75px 0;
  line-height: 28px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  width: 100%;
  color: #434343;
  text-align: left;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CardLinkImgContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
    position: absolute;
    bottom: 18px;
    right: 11px;
    width: 100%;
    display: none;
  }
`;

export const CardImgContainerMobileWrapper = styled.div`
  width: 100%;
  display: none;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 36px;
    display: flex;
    jusify-content: flex-start;
    margin-bottom: 10px;
  }
`;

export const CardShortDesc = styled.div`
  // position: absolute;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  width: 100%;
  color: #434343;
  text-align: left;
  line-height: 28px;
  display: none;
  @media screen and (max-width: 768px) {
    margin-top: 7px;
    display: block;
    font-size: 14px;
    padding-right: 10px;
    line-height: 19px;
    margin-bottom: 30px;
  }
`;

export const CardLinkText = styled.div`
  position: absolute;
  bottom: 6px;
  left: 25px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3A7EFC;
  margin-left: 0;
  margin-bottom: 17px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    left: 10px;
    bottom: 0px;
  }
`;

export const SectionTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  text-align: center;
  color: #1F252E;
  line-height: 64px;
  margin: 0 auto;
  margin-bottom: 110px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 48px;
  }
`;

export const SectionSecondTitle = styled.div`
  color: #1F252E;
  text-align: center;
  font-family: Pretendard;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  margin-bottom: 32px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 16px;
  }
`

export const SectionSecondDesc = styled.div`
  color: #666666;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 170% */
  margin-bottom: 128px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 50px;
  }
`

export const SectionSecondFooterWrapper = styled.div`  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  padding-top: 80px;
  padding-bottom: 80px;
  background: #3B4044;
  @media screen and (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

export const SectionSecondFooterImg = styled.img`
  width: 46px;
  height: 46px;
  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`

export const SectionSecondFooterText = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 155.556% */
  @media screen and (max-width: 768px) {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }
`

export const ServiceCardRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const ServiceCard = styled.div`
  flex: 1;
  width: 100%;
  min-height: 346px;
  margin-bottom: 54px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-bottom: 37px;
    min-height: 274px;
  }
`;

export const ReversedServiceCard = styled(ServiceCard)`
  flex-direction: row-reverse;
  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`;

export const ServiceCardTitleDescWrapper = styled.div`
  flex: 1;
  margin-left: ${(props) => (props.leftValue ? "73px" : "97px")};
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 14px;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
  }
`;

export const ServiceCardTitleDescWrapperReversed = styled.div`
  flex: 1;
  margin-left: ${(props) => (props.leftValue ? "80px" : "120px")};
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 14px;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 30px;
    width: 100%;
    gap: 10px;
  }
`;

export const ServiceCardNumTitleWrapper = styled.div`
  display: flex;
`

export const ServiceCardNumTitle = styled.div`
  justify-content: center;
  align-items: center;
  background-color: #121212;
  width: 28px;
  height: 28px;
  color: #FFF;
  padding-top: 3.5px;
  margin-top: 1.75px;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 50%;
  @media screen and (max-width: 768px) {
    width: 22px;
    height: 22px;
    font-size: 14px;
    margin-top: 2.2px;
    margin-left: 0px;
  }
`

export const ServiceCardNumWrapper = styled.div`
  display: flex;
`

export const ServiceCardTitle = styled.div`
  color: #121212;
  font-family: Pretendard;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
export const ServiceCardDesc = styled.div`
  color: #666;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  text-align: left;
  margin-left: 44px;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 22px;
    margin-left: 34px;
  }
`;
export const ServiceCardImgWrapper = styled.div`
  flex: 1;
  background-color: #e9f1f8;
  border-radius: 16px;
  width: 100%;
  // margin-right: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-right: 0px;
    width: 100%;
    flex: none;
    height: auto;
  }
`;

export const ServiceCardImg = styled.img`
  border-radius: 16px;
  width: 508px;
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const ServiceCardPicture = styled.picture`
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-fit: cover;
  }
`;

export const CarouselCard = styled.div`
  border-radius: 16px;
  // width: 800px !important;
  width: 100%;
  height: 333px;
  background: #ffffff;
  padding: 32px 65px;
  @media screen and (max-width: 768px) {
    padding-left: 18px;
    padding-right: 18px;
    width: auto;
    height: auto;
  }
`

export const CarouselCardTopWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const CarouselCardTopTextWrapper = styled.div`
  flex: 1;
  line-height: 28px;
  color: #1F2E3D;
  font-size: 20px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  line-height: 31px;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    font-size: 16px;
  }
`;

export const CarouselCardTopImg = styled.img`
  margin-left: 20px;
  @media screen and (max-width: 768px) {
    max-width: 100px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
`;

export const CarouselDescWrapper = styled.div`
  color: #1F2E3D;
  font-size: 18px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  border-top: 1px solid #e9e9e9;
  margin-top: 39px;
  padding-top: 23px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const CollapseWrapper = styled.div`
  margin-top: 120px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;
export const PrevArrowWrapper = styled.div`
  @media screen and (max-width: 768px) {
    position: absolute;
    left: auto;
    right: 80px;
    top: 33px;
    display: none;
  }
`;
export const NextArrowWrapper = styled.div`
  @media screen and (max-width: 768px) {
    position: absolute;
    right: 33px;
    top: 33px;    
  }
`;

export const SectionSubTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const ExternalLinkWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ExternalLinkImgWrapper = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 75px;
    height: 75px;
  }
`;

export const ExternalLinkImg = styled.img`
  width: 70px;
  height: 70px;
  // background-color: #ffffff;
`;
