import CaseConversation from "components/caseDetail/CaseConversation";
import CaseProgress from "components/caseDetail/CaseProgress";
import { useEffect, useMemo, useState } from "react";
import { Container, Tab, TabWrapper, Title, CaseDetailSection, ContentContainer } from "./CaseDetail.style";
import CaseSubmission from "components/caseDetail/CaseSubmission";
import axios from "axios";
import { EventBannerMobileImg } from "./MyCaseList.style";
import EventBannerMobile from "assets/svg/EventBannerMobile.svg";
import Footer from "components/Footer";
import { useRecoilState } from "recoil";
import { currentLawyerCaseState, currentMyCaseState, selectOpinionState } from "states/atoms";

const CaseDetail = ({ loggedInUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState(0);

  const [lawyerCase, setLaywerCase] = useRecoilState(currentLawyerCaseState);
  const [selectedOpinion, setSelectedOpinion] = useRecoilState(selectOpinionState);
  const [currentMyCase, setCurrentMyCase] = useRecoilState(currentMyCaseState);  
  // fetch my cases
  useEffect(() => {
    try {
      getLawyerProposal(currentMyCase.accepted.proposal_id);
      getLawyerCase(currentMyCase.accepted.lawyer_case_id);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  async function getLawyerProposal(proposalId) {
    if (proposalId === undefined) {
      return;
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_LAYWER_PROPOSAL_URL
    const query = "?proposalId=" + proposalId;
    const url = host + query;
    try {
      const { data } = await axios.get(url);
      console.log("proposal", data);
      setSelectedOpinion(data);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async function getLawyerCase(requestId) {
    if (requestId === undefined) {
      return;
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_LAYWERCASE_URL
    const query = "?requestId=" + requestId;
    const url = host + query;
    try {
      const { data } = await axios.get(url);
      setLaywerCase(data);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  const TabContent = useMemo(() => {
    if (tab === 0) {
      return <CaseProgress loggedInUser={loggedInUser} fromAdmin={false}/>;
    } else if (tab === 1) {
      return <CaseSubmission loggedInUser={loggedInUser} fromAdmin={false}/>;
    } else if (tab === 2) {
      return <CaseConversation loggedInUser={loggedInUser} fromAdmin={false}/>;
    }
    return null;
  }, [tab, selectedOpinion]);

  if (isLoading) return null;

  return (
    <Container style={{ textAlign: "left" }}>
      <CaseDetailSection>
        <ContentContainer>
          <Title>사건 상세</Title>
          <TabWrapper>
            <Tab
              active={tab === 0}
              onClick={() => setTab(0)}
              style={{ borderRight: "1px solid #E2E2E2" }}
            >
              사건 진행 상황
            </Tab>            
            <Tab active={tab === 1} onClick={() => setTab(1)}>
              서류 제출하기
            </Tab>
            <Tab
              active={tab === 2}
              onClick={() => setTab(2)}
              style={{ borderRight: "1px solid #E2E2E2" }}
            >
              법대리 요청사항 확인
            </Tab>
          </TabWrapper>
          {TabContent}      
          <EventBannerMobileImg src={EventBannerMobile} />
        </ContentContainer>
      </CaseDetailSection>
      <Footer />
    </Container>
  );
};

export default CaseDetail;
