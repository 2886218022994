import Step1 from "components/form/crime/mediaObscenity/Step1";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "fbase";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

const MediaObscenity = ({ loggedInUser }) => {
  const [page, setPage] = useState(0);
  const userInfo = useRecoilValue(userData);
  const history = useHistory();
  const key = "updatable";

  const defaultValues = {
    history: {
      isFirst: "y",
      cnt: null,
      lastCrimeYear: null,
    },
    dept: null,
    description: null,
    promotionCode: null,
  };

  const itemLayout = {
    form: {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 16,
      },
    },
    button: {
      wrapperCol: { span: 24, offset: 0 },
    },
  };

  const [step1Data, setStep1Data] = useState(defaultValues);

  const [dbUpdate, setDbUpdate] = useState(false);

  const onSubmit = async () => {
    const drugData = {
      ...step1Data,
      userId: loggedInUser.uid,
    };

    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "media_obscenity",
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: drugData,
        userId: loggedInUser.uid,
        clientName: userInfo.name, 
        status: "submitted",
      });
      history.push("/submit/success");
      message.success({
        content: "제출 완료!",
        key,
        duration: 2,
      });
    } catch (err) {
      history.push("/submit/fail");
      message.error({
        content: "제출 실패!",
        key,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    if (dbUpdate) {
      onSubmit();
    }
  }, [dbUpdate]);

  const steps = [
    {
      title: "통매음",
      content: (
        <Step1
          data={step1Data}
          setData={setStep1Data}
          // onNext={onSubmit}
          setDbUpdate={setDbUpdate}
          itemLayout={itemLayout}
        />
      ),
    },
  ];

  return (
    <>
      <div className="steps-content">{steps[page].content}</div>
    </>
  );
};

export default MediaObscenity;
