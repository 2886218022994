import { Checkbox } from "components/home/CommonCriminal/CheckBox"
import { EventFormEachContainer, EventFormEachTitle, EventFormNamePhoneTextContainer, EventFormNamePhoneTitle, EventFormPhoneDesc, EventModalSubmitContainer, EventModalSubmitTitle, EventSubmitBtn, EventSubmitContainer, EventSubmitDesc, EventSubmitDescContainer, EventSubmitSection, EventSubmitTextContainer, EventSubmitTitle, EventModalSubmitBtn, EventModalSubmitBtnContainer } from "./EventSubmit.style"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "fbase";
import { getServiceTypeTitle } from "components/mypage/MyCaseList";
import axios from "axios";
import ReactLoading from 'react-loading';
import { LoadingWrapper } from "components/mypage/MyCase.style";
import { InputText } from "components/home/CommonCriminal/CommonCriminal.style";

export const EventSubmitNew = (props) => {
  const history = useHistory();
  const [isCheck, setIsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    setName(inputValue)
  }

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    setPhone(inputValue)
  }

  useEffect(() => {

  }, [isCheck])

  const handleCheckboxChange = (checkedValues) => {
    setIsCheck(checkedValues);
  };

  const onSubmit = async () => {
    const serviceData = props.eventServiceData;
    const serviceType = props.serviceType;
    var phoneRule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
    const phoneRegex = phoneRule.test(phone);
    if (phoneRegex === false) {
      alert('유효한 핸드폰 번호를 입력해주세요');
      return
    }
    const formattedPhone = formatPhoneNumber(phone);
    const uuid = uuidv4();
    setIsLoading(true);
    try {
      await dbService.collection("to3o3_requests").add({
        serviceType: serviceType,
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: serviceData,
        name: name,
        phone: formattedPhone,
        status: "submitted"
      });
      await sendWebhook(serviceType, uuid, name, formattedPhone)
      setIsLoading(false);
      history.push('/3o3/success');
    } catch (err) {
      // history.push("/submit/fail");
      console.log(err);
      alert('다시 시도해주세요');
    }
  };

  async function sendWebhook(serviceType, servieId, name, phone) {
    const webhookUrl = process.env.REACT_APP_DISCORD_TO3_SUBMIT_WEBHOOK_DOMAIN;
    const serviceTypeTitle = getServiceTypeTitle(serviceType)
    console.log("serviceType", serviceType);
    const message = {
      content: `🔥삼쩜삼 신청 완료🔥
      사건 타입: ${serviceTypeTitle}
      유저: ${name} 
      전화번호: ${phone}
      serviceId(확인용): ${servieId}`
    };

    try {
      const { response } = await axios.post(webhookUrl, message);
    } catch (e) {
    }
  }

  function formatPhoneNumber(phoneNumber) {
    // 하이픈 제거
    const cleanNumber = phoneNumber.replace(/-/g, '');

    // 번호 형식 확인 및 변환
    if (cleanNumber.startsWith('010')) {
      const mainPart = cleanNumber.substring(3); // "010" 제외 나머지 숫자
      return `+82 10-${mainPart.slice(0, 4)}-${mainPart.slice(4)}`;
    }

    // 변환할 수 없는 형식이면 원본 반환
    return phoneNumber;
  }

  const isAllReady = () => {
    return props.eventServiceData && name !== "" && phone !== ""
  }

  return (
    <EventModalSubmitContainer>
      <EventModalSubmitTitle>

      </EventModalSubmitTitle>
      <EventFormEachContainer>
        <EventFormEachTitle>
          전문 변호사에게 검토 받으실 <br />
          정보를 입력해 주세요
        </EventFormEachTitle>
        <EventFormNamePhoneTextContainer>
          <EventFormNamePhoneTitle style={{ fontWeight: 700 }}>
            성함
          </EventFormNamePhoneTitle>
          <InputText value={name} onChange={handleNameChange} placeholder="홍길동" />
        </EventFormNamePhoneTextContainer>
        <EventFormNamePhoneTextContainer>
          <EventFormNamePhoneTitle style={{ fontWeight: 700 }}>
            연락처
          </EventFormNamePhoneTitle>
          <InputText
            value={phone}
            onChange={handlePhoneChange}
            placeholder="010 1234 1234"
            type="number"
            inputMode="numeric" minLength={11} />
          <EventFormPhoneDesc> 정확히 입력했는지 확인해 주세요</EventFormPhoneDesc>
        </EventFormNamePhoneTextContainer>
      </EventFormEachContainer>
      <EventModalSubmitBtnContainer>
        {(isLoading) && <LoadingWrapper style={{marginTop: 0}}> <ReactLoading type={"spin"} color={"#3a7efc"} height={'10%'} width={'10%'} /> </LoadingWrapper>}
        {(!isLoading) && <EventModalSubmitBtn disabled={!isAllReady()} active={isAllReady()} onClick={onSubmit}>
          신청 완료하기
        </EventModalSubmitBtn>}
      </EventModalSubmitBtnContainer>
    </EventModalSubmitContainer>
  )
}