import { createContext } from "react";
import { useContext } from "react";
import {
  CheckBoxGroupTitle,
  CheckboxGroupWrapper,
  CheckboxListWrapper,
  CheckboxText,
  CheckboxInput
} from "./CheckBox.style";



const CheckboxContext = createContext();

export function Checkbox({ children, disabled, value, checked, onChange, customStyle }) {
  const context = useContext(CheckboxContext);

  if (!context) {
    return (
      <CheckboxText>
        <CheckboxInput
          style={customStyle}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={({ target: { checked } }) => onChange(checked)}
        />
        {children}
      </CheckboxText>
    );
  }

  const { isDisabled, isChecked, toggleValue } = context;

  return (
    <CheckboxText>
      <CheckboxInput
        type="checkbox"
        disabled={isDisabled(disabled)}
        checked={isChecked(value)}
        onChange={({ target: { checked } }) => toggleValue({ checked, value })}
      />
      {children}
    </CheckboxText>
  );
}


export function CheckboxGroup({
  label,
  children,
  disabled: groupDisabled,
  values,
  onChange
}) {
  const isDisabled = (disabled) => disabled || groupDisabled;

  const isChecked = (value) => values.includes(value);

  const toggleValue = ({ checked, value }) => {
    if (checked) {
      onChange(values.concat(value));
    } else {
      onChange(values.filter((v) => v !== value));
    }
  };

  return (
    <CheckboxGroupWrapper>
      {label !== "" &&
        <CheckBoxGroupTitle>{label}</CheckBoxGroupTitle>
      }
      <CheckboxContext.Provider value={{ isDisabled, isChecked, toggleValue }}>
        <CheckboxListWrapper>
          {children}
        </CheckboxListWrapper>
      </CheckboxContext.Provider>
    </CheckboxGroupWrapper>
  );
}

