import IcHeadset from "../../assets/svg/IcHeadset.svg";
import {
  Container,
  BtnConsult,
  ContentContainer,
  SubTitle,  
  Title,
  SectionTop,
  TopTitle,
} from "../Service.style";
import { SerivceCenterConsultingSection } from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import InheritServiceDetail from "./InheritServiceDetail";
import { CriminalDriveDrugServiceCenterSection,
  CriminalTopWrapper, } from "./CommonCriminal/CommonCriminal.style";

const InheritServiceCenter = ({loggedInUser}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderUI = () => {
    return <InheritServiceDetail loggedInUser={loggedInUser} />
  }
  
  return (
    <Container>
      <SectionTop style={{ height: 170 }}>
        <ContentContainer style={{ width: "768px" }}>
          <CriminalTopWrapper>
            <TopTitle>상속포기 · 한정승인</TopTitle>
          </CriminalTopWrapper>
        </ContentContainer>
      </SectionTop>
      <CriminalDriveDrugServiceCenterSection>
        <ContentContainer>
          {renderUI()}
          {/* <CriminalDriveCollapseWrapper>
            {SERVICE_QNA.criminal.map(({ title, desc }, idx) => (
              <Collapse
                key={`criminal-service$-${idx}`}
                title={title}
                desc={desc}
              />
            ))}
          </CriminalDriveCollapseWrapper> */}
        </ContentContainer>
      </CriminalDriveDrugServiceCenterSection>
      <SerivceCenterConsultingSection>
        <ContentContainer>
          <Title style={{ marginBottom: 10, marginTop: 0 }}>
            기타 법률 상담
          </Title>
          <SubTitle style={{ marginBottom: 0 }}>
          간편 법률 서비스에 원하는 서비스가 없으신가요?<br/>기타 법률 서비스에서 현재 상황을 알려주세요!
          </SubTitle>
          <BtnConsult onClick={()=> {
            //  LandingToChannelTalk();
            if(!loggedInUser){
              ChannelService.loadScript();
              ChannelService.boot({
                "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
              })
            }
            ChannelService.showMessenger();
          }}>
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </BtnConsult>
        </ContentContainer>
      </SerivceCenterConsultingSection>
      <Footer/>
    </Container>
  );
};

export default InheritServiceCenter;
