import React,{useEffect} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dbService } from "fbase";
import { Redirect } from "react-router-dom";
import LoanOrder from "components/form/loan/order/LoanOrder";
import CommonLayout from "../components/CommonLayout";
import Landing from "routes/Landing";
import Request from "components/request/Request";
import KakaoLogin from "./KakaoLogin";
import ChanneltalkLogin from "./ChanneltalkLogin"
import OAuth from "./OAuth";
import Logout from "./Logout";
import SubmitSuccess from "components/submit/SubmitSuccess";
import SubmitFail from "components/submit/SubmitFail";
import Admin from "components/admin/Admin";
import Pay from "components/pay/Pay";
import PaySuccess from "components/pay/PaySuccess";
import PayFail from "components/pay/PayFail";
import HousingOrder from "components/form/housing/order/Order";
import AdminHome from "components/admin/AdminHome";
import Protection from "components/form/housing/protection/Protection";
import Certification from "components/form/housing/certification/Certification";
import NoiseCertification from "components/form/noise/certification/Certification";
import PilatesCertification from "components/form/pilates/certification/Certification";
import PilatesOrder from "components/form/pilates/order/Order";
import GolfTennisCertification from "components/form/golfTennis/certification/Certification";
import GolfTennisOrder from "components/form/golfTennis/order/Order";
import CivilDefendantCertification from "components/form/civil/Defendant/certification/Certification";
import CivilPlaintiffCertification from "components/form/civil/Plaintiff/certification/Certification";
import InheritGiveup from "components/form/inherit/giveup/Certification";
import InheritLimited from "components/form/inherit/limited/Certification";
import CancelCertification from "components/form/housing/host/cancel/Certification";
import ChargeCertification from "components/form/housing/host/charge/Certification";
import NotAllowedCertification from "components/form/housing/host/notallowed/Certification";
import VacateHouse from "components/form/housing/host/vacatehouse/Certification";
import Injunction from "components/form/housing/host/injunction/Certification";

import DrunkDriving from "components/form/crime/drunkDriving/DrunkDriving";
import Drug from "components/form/crime/drug/Drug";
import MediaObscenity from "components/form/crime/mediaObscenity/MediaObscenity";
import User from "components/user/User";
import Sample from "components/sample/Sample";
import LoanCertification from "components/form/loan/certification/LoanCertification";
import Seizure from "components/form/housing/seizure/Seizure";
import PublicConveyance from "components/form/housing/publicConveyance/PublicConveyance";
import Insult from "components/form/plaint/insult/Insult";
import EstateServiceCenter from "../components/home/EstateServiceCenter";
import InheritServiceCenter from "../components/home/InheritServiceCenter";
import VacateHouseServiceCenter from "../components/home/VacateHouseServiceCenter";

import EstateHostServiceCenter from "../components/home/EstateHostServiceCenter";
import PilatesServiceCenter from "../components/home/PilatesServiceCenter";
import CriminalServiceCenter from "../components/home/CriminalServiceCenter";
import AccusationServiceCenter from "../components/home/AccusationServiceCenter";
import LoanServiceCenter from "../components/home/LoanServiceCenter";
import NoiseServiceCenter from "../components/home/NoiseServiceCenter";
import SexualServiceCenter from "../components/home/SexualServiceCenter";
import EtcServiceCenter from "../components/home/EtcServiceCenter";
import CertificationEtcServiceCenter from "../components/home/CertificationEtcServiceCenter";
import Signup from "./Signup";
// v2
import MyCaseList from "components/mypage/MyCaseList";
import MyCase from "components/mypage/MyCase";
import LawyerOpinion from "components/mypage/LawyerOpinion";
import CaseDetail from "components/mypage/CaseDetail";

import ServiceIntroduction from "components/introduction/ServiceIntroduction";
import CaseDataFilePreview from "components/caseDetail/CaseDataFilePreview";
import AddLawyerDocOpinion from "components/caseDetail/AddLawyerDocOpinion";
import ConfirmMyDocOpinion from "components/caseDetail/ConfirmMyDocOpinion";

import CaseDataNotSubmitPreview from "components/caseDetail/preview/CaseDataNotSubmitPreview";
import CaseDataSubmitPreview from "components/caseDetail/preview/CaseDataSubmitPreview";
import HowToUse from "../components/howToUse/HowToUse";

import ChannelService from "components/ChannelService";
import { userData } from '../states/atoms.js'
import { useRecoilState } from 'recoil';
import AdminCaseDetail from "components/mypage/AdminCaseDetail";
import NotiList from "components/common/Noti";
import CivilServiceCenter from "components/home/CivilServiceCenter";
import CommonCriminalServiceCenter from "components/home/CommonCriminalServiceCenter";
import LivingLaw from "components/livingLaw/LivingLaw";
import LivingLawDetail from "components/livingLaw/LivingLawDetail";
import GolfTennisServiceCenter from "components/home/GolfTennisServiceCenter";
import CertificationServiceCenter from "components/home/CertificationServiceCenter";
import ComplaintServiceCenter from "components/home/ComplaintServiceCenter";
import ContractServiceCenter from "components/home/ContractServiceCenter";
import AgreementServiceCenter from "components/home/AgreementServiceCenter";
import OpinionServiceCenter from "components/home/OpinionServiceCenter";
import OnlineScamServiceCenter from "components/home/OnlineScamServiceCenter";
import PayServiceCenter from "components/home/PayServiceCenter";
import ApplyLoanService from "components/form/new/applyService/ApplyLoanService";
import ApplyCertificationService from "components/form/new/applyService/ApplyCertificationService";
import ApplyAgreementService from "components/form/new/applyService/ApplyAgreementService";
import ApplyComplaintService from "components/form/new/applyService/ApplyComplaintService";
import ApplyContractService from "components/form/new/applyService/ApplyContractService";
import ApplyOnlineScamService from "components/form/new/applyService/ApplyOnlineScamService";
import ApplyPayService from "components/form/new/applyService/ApplyPayService";
import ApplyOpinionService from "components/form/new/applyService/ApplyOpinionService";
import EventPilatesServiceCenter from "components/event/3o3/EventPilatesServiceCenter";
import EventEstateServiceCenter from "components/event/3o3/EventEstateServiceCenter";
import EventGolfTennisServiceCenter from "components/event/3o3/EventGolfTennisServiceCenter";
import EventNoiseServiceCenter from "components/event/3o3/EventNoiseServiceCenter";
import EventLoanServiceCenter from "components/event/3o3/EventLoanServiceCenter";
import EventEtcServiceCenter from "components/event/3o3/EventEtcServiceCenter";
import EventPilatesCertificate from "components/event/3o3/order/EventPilatesCertificate";
import EventEstateCertificate from "components/event/3o3/order/EventEstateCertificate";
import EventGolfTennisCertificate from "components/event/3o3/order/EventGolfTennisCertificate";
import EventNoiseCertificate from "components/event/3o3/order/EventNoiseCertificate";
import EventLoanCertificate from "components/event/3o3/order/EventLoanCertificate";
import EventEtcCertificate from "components/event/3o3/order/EventEtcCertificate";
import { EventSuccess } from "components/event/3o3/order/EventSuccess";
import HealthPilatesServiceCenter from "components/home/HealthPilatesServiceCenter";
import ApplyHealthPilatesCertificateService from "components/form/new/applyService/ApplyHealthPilatesCertificateService";
import ApplyHealthPilatesOrderService from "components/form/new/applyService/ApplyHealthPilatesOrderService";
import HousingProtectServiceCenter from "components/home/HousingProtectServiceCenter";
import ApplyHousingProtectService from "components/form/new/applyService/ApplyHousingProtectService";
import EstateAllServiceCenter from "components/home/EstateAllServiceCenter";
import ApplyMonthlyPayCertService from "components/form/new/applyService/ApplyMonthlyPayCertService";
import ApplyMonthlyPayPublicNoticeService from "components/form/new/applyService/ApplyMonthlyPayPublicNoticeService";
import ApplyMonthlyPaySeizureService from "components/form/new/applyService/ApplyMonthlyPaySeizureService";
import ApplyMonthlyPayOrderService from "components/form/new/applyService/ApplyMonthlyPayOrderService";
import EtcNewServiceCenter from "components/home/EtcNewServiceCenter";
import ApplyEtcService from "components/form/new/applyService/ApplyEtcService";
import EventPayServiceCenter from "components/event/3o3/EventPayServiceCenter";
import EventPayCertificate from "components/event/3o3/order/EventPayCertificate";

const AppRouter = ({ user }) => {
  const [userInfo, setUserInfo] = useRecoilState(userData);

  useEffect(() => {
    const fetchUserDataAndBootChannelSerivce = async (user) => {
      let name = userInfo.name;
      let phone = userInfo.phoneNumber;
      if (name === "") {
        let userName = "";
        const getUserDataResult = await dbService
        .collection("users")
        .where("__name__", "==", user.uid)
        .get()
        if (getUserDataResult.size > 0) {
          const userData = getUserDataResult.docs[0].data();
          userName = userData.properties.nickname;
          let phoneNumber = ""
          if (userData.kakao_account.phone_number !== undefined) {
            phoneNumber = userData.kakao_account.phone_number          
          }
          name = userName;
          phone = phoneNumber;
          setUserInfo({name: userData.properties.nickname ,phoneNumber: phoneNumber})
        }
      }
      ChannelService.loadScript();
      ChannelService.boot({
        "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495",
        "memberId":user.multiFactor.user.uid,
        "profile":{
          "email":user.multiFactor.user.email,
          "name":name,
          "mobileNumber":phone
        }
      })
    }

    if(user){      
      fetchUserDataAndBootChannelSerivce(user);      
    }
  }, [user]);
  
  return (
    <Router>
      <CommonLayout isLoggedIn={Boolean(user)} loggedInUser={user}>
        {Boolean(user) ? (
          <Switch>
            <Route exact path="/">
              <Landing loggedInUser={user} />
            </Route>
            <Route exact path="/confirmMyOpinion">
              <ConfirmMyDocOpinion loggedInUser={user} />
            </Route>
            <Route exact path="/preview_file">
              <CaseDataFilePreview loggedInUser={user} />
            </Route>
            <Route exact path="/preview_client_file">
              <CaseDataSubmitPreview loggedInUser={user} />
            </Route>            
            <Route exact path="/add_lawyer_file_opinion">
              <AddLawyerDocOpinion loggedInUser={user} />
            </Route>
            <Route exact path="/my_case">
              <MyCase loggedInUser={user} />
            </Route>
            <Route exact path="/lawyer_opinion">
              <LawyerOpinion loggedInUser={user} />
            </Route>
            <Route exact path="/case_detail">
              <CaseDetail loggedInUser={user} />
            </Route>
            <Route exact path="/case_not_submit_preview">
              <CaseDataNotSubmitPreview loggedInUser={user} />
            </Route>            
            <Route exact path="/mypage">
              <MyCaseList loggedInUser={user} />
            </Route>
            <Route exact path="/noti">
              <NotiList loggedInUser={user} />
            </Route>
            <Route exact path="/signup">
              <Signup loggedInUser={user} />
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>

            <Route exact path="/admin/caseDetail/:requestId">
              <AdminCaseDetail loggedInUser={user} />
            </Route>

            <Route exact path="/services/estate">
              <EstateServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/inherit">
              <InheritServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/vacatehouse">
              <VacateHouseServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/estateHost">
              <EstateHostServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/criminal">
              <CriminalServiceCenter loggedInUser={user}/>
            </Route>
            <Route exact path="/services/pilates">
              <HealthPilatesServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/golfTennis">
              <GolfTennisServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/accusation">
              <AccusationServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/loan">
              <LoanServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/sexual">
              <SexualServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/noise">
              <NoiseServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/civil">
              <CivilServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/commonCriminal">
              <CommonCriminalServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/etc">
              <EtcServiceCenter loggedInUser={user}/>
            </Route>
            <Route exact path="/services/certificationETC">
              <CertificationEtcServiceCenter loggedInUser={user} />
            </Route>

            <Route exact path="/apply/loanService">
              <ApplyLoanService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/certificationService">
              <ApplyCertificationService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/agreementService">
              <ApplyAgreementService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/complaintService">
              <ApplyComplaintService loggedInUser={user} />
            </Route>
            
            <Route exact path="/apply/contractService">
              <ApplyContractService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/onlineScamService">
              <ApplyOnlineScamService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/payService">
              <ApplyPayService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/opinionService">
              <ApplyOpinionService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/healthPilatesCertService">
              <ApplyHealthPilatesCertificateService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/healthPilatesOrderService">
              <ApplyHealthPilatesOrderService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/housingProtectService">
              <ApplyHousingProtectService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/monthlyPayCertService">
              <ApplyMonthlyPayCertService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/monthlyPayPublicNoticeService">
              <ApplyMonthlyPayPublicNoticeService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/monthlyPaySeizureService">
              <ApplyMonthlyPaySeizureService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/monthlyPayOrderService">
              <ApplyMonthlyPayOrderService loggedInUser={user} />
            </Route>

            <Route exact path="/apply/etcService">
              <ApplyEtcService loggedInUser={user} />
            </Route>

            <Route exact path="/introduction">
              <ServiceIntroduction />
            </Route>
            <Route exact path="/loan/order">
              <LoanOrder loggedInUser={user} />
            </Route>
            <Route exact path="/loan/certification">
              <LoanCertification loggedInUser={user} />
            </Route>
            <Route exact path="/housing/certification">
              <Certification loggedInUser={user} />
            </Route>
            <Route exact path="/housing/protection">
              <Protection loggedInUser={user} />
            </Route>
            <Route exact path="/housing/order">
              <HousingOrder loggedInUser={user} />
            </Route>
            <Route exact path="/housing/seizure">
              <Seizure loggedInUser={user} />
            </Route>
            <Route exact path="/housing/public_conveyance">
              <PublicConveyance loggedInUser={user} />
            </Route>

            <Route exact path="/housing/host/cancelcertification">
              <CancelCertification loggedInUser={user} />
            </Route>
            <Route exact path="/housing/host/chargecertification">
              <ChargeCertification loggedInUser={user} />
            </Route>
            <Route exact path="/housing/host/notallowedcertification">
              <NotAllowedCertification loggedInUser={user} />
            </Route>
            <Route exact path="/housing/host/vacatehouse">
              <VacateHouse loggedInUser={user} />
            </Route>
            <Route exact path="/housing/host/injunction">
              <Injunction loggedInUser={user} />
            </Route>

            <Route exact path="/noise/certification">
              <NoiseCertification loggedInUser={user} />
            </Route>

            <Route exact path="/pilates/certification">
              <PilatesCertification loggedInUser={user} />
            </Route>
            <Route exact path="/pilates/order">
              <PilatesOrder loggedInUser={user} />
            </Route>

            <Route exact path="/golfTennis/certification">
              <GolfTennisCertification loggedInUser={user} />
            </Route>
            <Route exact path="/golfTennis/order">
              <GolfTennisOrder loggedInUser={user} />
            </Route>

            <Route exact path="/civil/defendant">
              <CivilDefendantCertification loggedInUser={user} />
            </Route>
            <Route exact path="/civil/plaintiff">
              <CivilPlaintiffCertification loggedInUser={user} />
            </Route>

            <Route exact path="/services/certification">
              <CertificationServiceCenter loggedInUser={user} />
            </Route>

            <Route exact path="/services/complaint">
              <ComplaintServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/contract">
              <ContractServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/agreement">
              <AgreementServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/opinion">
              <OpinionServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/online_scam">
              <OnlineScamServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/pay">
              <PayServiceCenter loggedInUser={user} />
            </Route>
            <Route exact path="/services/housing_protect">
              <HousingProtectServiceCenter loggedInUser={user} />
            </Route>

            <Route exact path="/services/estate_all">
              <EstateAllServiceCenter loggedInUser={user} />
            </Route>

            <Route exact path="/services/etc_law">
              <EtcNewServiceCenter loggedInUser={user} />
            </Route>

            <Route exact path="/inherit/giveup">
              <InheritGiveup loggedInUser={user} />
            </Route>
            <Route exact path="/inherit/limited">
              <InheritLimited loggedInUser={user} />
            </Route>

            <Route exact path="/crime/drunk_driving">
              <DrunkDriving loggedInUser={user} />
            </Route>
            <Route exact path="/crime/drug">
              <Drug loggedInUser={user} />
            </Route>
            <Route exact path="/crime/media_obscenity">
              <MediaObscenity loggedInUser={user} />
            </Route>
            <Route exact path="/crime/media_obscenity">
              <MediaObscenity loggedInUser={user} />
            </Route>

            <Route exact path="/plaint/insult">
              <Insult loggedInUser={user} />
            </Route>
            {/* <Route exact path="/sexual_crime/complaint">
              <Complaint loggedInUser={user} />
            </Route> */}

            {/* <Route exact path="/corporation/incorporation">
              <Incorporation loggedInUser={user} />
            </Route> */}

            <Route exact path="/request/:requestId">
              <Request loggedInUser={user} isAdminRequest={false} />
            </Route>

            <Route exact path="/submit/success">
              <SubmitSuccess loggedInUser={user} />
            </Route>
            <Route exact path="/submit/fail">
              <SubmitFail loggedInUser={user} />
            </Route>
            <Route exact path="/admin">
              <AdminHome loggedInUser={user} />
            </Route>
            <Route exact path="/admin/:status">
              <Admin loggedInUser={user} />
              {/* ) : ( */}
              {/* <Redirect from="*" to="/" /> */}
              {/* )} */}
            </Route>
            <Route exact path="/admin/request/:requestId">
              <Request loggedInUser={user} isAdminRequest={true} />
            </Route>
            <Route exact path="/pay/success/:requestId">
              <PaySuccess loggedInUser={user} />
            </Route>
            <Route exact path="/pay/fail/:requestId">
              <PayFail loggedInUser={user} />
            </Route>
            <Route exact path="/pay/:requestId">
              <Pay loggedInUser={user} />
            </Route>

            <Route exact path="/admin/user/:userId">
              <User loggedInUser={user} />
            </Route>

            <Route exact path="/sample/:requestId">
              <Sample loggedInUser={user} />
            </Route>
            {/* <Route exact path="/request/:requestId/documents">
              <RequestDocuments />
            </Route> */}
            <Route exact path="/introduction">
              <ServiceIntroduction />
            </Route>
            <Route exact path="/livingLaw">
              <LivingLaw loggedInUser={user}/>
            </Route>
            <Route exact path="/livingLawDetail/:detailId" component={LivingLawDetail} loggedInUser={user}/>
            <Route exact path="/howToUse">
              <HowToUse />
            </Route>

            <Route exact path="/3o3/pilates">
              <EventPilatesServiceCenter />
            </Route>

            <Route exact path="/3o3/estate">
              <EventEstateServiceCenter />
            </Route>

            <Route exact path="/3o3/golfTennis">
              <EventGolfTennisServiceCenter />
            </Route>

            <Route exact path="/3o3/noise">
              <EventNoiseServiceCenter />
            </Route>

            <Route exact path="/3o3/loan">
              <EventLoanServiceCenter />
            </Route>

            <Route exact path="/3o3/etc">
              <EventEtcServiceCenter />
            </Route>

            <Route exact path="/3o3/pay">
              <EventPayServiceCenter />
            </Route>

            <Route exact path="/3o3/pilates/certificate">
              <EventPilatesCertificate />
            </Route>

            <Route exact path="/3o3/estate/certificate">
              <EventEstateCertificate />
            </Route>

            <Route exact path="/3o3/golfTennis/certificate">
              <EventGolfTennisCertificate />
            </Route>

            <Route exact path="/3o3/noise/certificate">
              <EventNoiseCertificate />
            </Route>

            <Route exact path="/3o3/loan/certificate">
              <EventLoanCertificate />
            </Route>

            <Route exact path="/3o3/etc/certificate">
              <EventEtcCertificate />
            </Route>

            <Route exact path="/3o3/pay/certificate">
              <EventPayCertificate />
            </Route>

            <Route exact path="/3o3/success">
              <EventSuccess />
            </Route>

            <Redirect from="*" to="/" />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>

            <Route exact path="/introduction">
              <ServiceIntroduction />
            </Route>
            <Route exact path="/livingLaw">
              <LivingLaw />
            </Route>
            <Route exact path="/livingLawDetail/:detailId" component={LivingLawDetail} />

            <Route exact path="/services/inherit">
              <InheritServiceCenter />
            </Route>
            <Route exact path="/services/vacatehouse">
              <VacateHouseServiceCenter />
            </Route>
            <Route exact path="/services/estate">
              <EstateServiceCenter />
            </Route>
            <Route exact path="/services/estateHost">
              <EstateHostServiceCenter />
            </Route>
            <Route exact path="/services/criminal">
              <CriminalServiceCenter />
            </Route>
            <Route exact path="/services/accusation">
              <AccusationServiceCenter />
            </Route>
            <Route exact path="/services/pilates">
              <HealthPilatesServiceCenter />
            </Route>
            <Route exact path="/services/golfTennis">
              <GolfTennisServiceCenter />
            </Route>
            <Route exact path="/services/loan">
              <LoanServiceCenter />
            </Route>
            <Route exact path="/services/sexual">
              <SexualServiceCenter />
            </Route>
            <Route exact path="/services/noise">
              <NoiseServiceCenter />
            </Route>
            <Route exact path="/services/civil">
              <CivilServiceCenter />
            </Route>
            <Route exact path="/introduction">
              <ServiceIntroduction />
            </Route>
            <Route exact path="/services/etc">
              <EtcServiceCenter/>
            </Route>
            <Route exact path="/services/certification">
              <CertificationServiceCenter/>
            </Route>
            <Route exact path="/services/complaint">
              <ComplaintServiceCenter/>
            </Route>
            <Route exact path="/services/contract">
              <ContractServiceCenter/>
            </Route>
            <Route exact path="/services/agreement">
              <AgreementServiceCenter/>
            </Route>
            <Route exact path="/services/opinion">
              <OpinionServiceCenter/>
            </Route>
            <Route exact path="/services/online_scam">
              <OnlineScamServiceCenter/>
            </Route>
            <Route exact path="/services/pay">
              <PayServiceCenter/>
            </Route>
            <Route exact path="/services/housing_protect">
              <HousingProtectServiceCenter/>
            </Route>
            <Route exact path="/services/estate_all">
              <EstateAllServiceCenter/>
            </Route>
            <Route exact path="/services/etc_law">
              <EtcNewServiceCenter/>
            </Route>
            <Route exact path="/services/certificationETC">
              <CertificationEtcServiceCenter/>
            </Route>
            <Route exact path="/howToUse">
              <HowToUse />
            </Route>
            <Route exact path="/channeltalklogin">
              <ChanneltalkLogin />
            </Route>

            {/* <Route exact path="/test">
              <TestLogin />
            </Route> */}
            {/* <Route exact path="/kakao">
              <KakaoLogin />
            </Route> */}
            <Route exact path="/oauth">
              <OAuth />
            </Route>

            <Route exact path="/3o3/pilates">
              <EventPilatesServiceCenter />
            </Route>

            <Route exact path="/3o3/estate">
              <EventEstateServiceCenter />
            </Route>

            <Route exact path="/3o3/golfTennis">
              <EventGolfTennisServiceCenter />
            </Route>

            <Route exact path="/3o3/noise">
              <EventNoiseServiceCenter />
            </Route>

            <Route exact path="/3o3/loan">
              <EventLoanServiceCenter />
            </Route>

            <Route exact path="/3o3/etc">
              <EventEtcServiceCenter />
            </Route>

            <Route exact path="/3o3/pay">
              <EventPayServiceCenter />
            </Route>

            <Route exact path="/3o3/pilates/certificate">
              <EventPilatesCertificate />
            </Route>

            <Route exact path="/3o3/estate/certificate">
              <EventEstateCertificate />
            </Route>

            <Route exact path="/3o3/golfTennis/certificate">
              <EventGolfTennisCertificate />
            </Route>

            <Route exact path="/3o3/noise/certificate">
              <EventNoiseCertificate />
            </Route>

            <Route exact path="/3o3/loan/certificate">
              <EventLoanCertificate />
            </Route>

            <Route exact path="/3o3/etc/certificate">
              <EventEtcCertificate />
            </Route>

            <Route exact path="/3o3/pay/certificate">
              <EventPayCertificate />
            </Route>

            <Route exact path="/3o3/success">
              <EventSuccess />
            </Route>

            <Route exact path="*">
              <KakaoLogin />
            </Route>
            {/* <Route exact path="/request/:requestId/documents">
              <RequestDocuments />
            </Route> */}

            <Redirect from="*" to="/" />
          </Switch>
        )}
      </CommonLayout>
    </Router>
  );
};

export default AppRouter;
