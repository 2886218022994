import { useHistory } from "react-router-dom";

import IcServiceMain from "assets/svg/Ic3.3Noise.svg";

import {
  Container,
} from "components/Service.style";

import { useEffect } from "react";
import { removeBrTag } from "routes/Landing";
import { EventHeaderContainer, EventHeaderDesc, EventHeaderDescSpan, EventHeaderImg, EventHeaderSection, EventHeaderSubTitle, EventHeaderTitle } from "../EventStyle.style";
import { EventDesc } from "../EventDesc";
import { EventDesc2 } from "../EventDesc2";
import { EventDetailSubmit } from "../EventDetailSubmit";
import { EventDetailFooter } from "../EventDetailFooter";

const EventNoiseServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <EventHeaderSection>
        <EventHeaderContainer>
          <EventHeaderImg src={IcServiceMain} />
          <EventHeaderTitle>
            층간 소음
          </EventHeaderTitle>
          <EventHeaderSubTitle>
            내용 증명으로 해결하기
          </EventHeaderSubTitle>
          <EventHeaderDesc>
            지속적인 층간 소음으로 인한 피해를 입었을 경우 피해 사실에 대한 증거가 있다면, 상대방에게 민사 소송을 통해 손해배상을 청구할 수 있습니다.
            하지만 소송 절차는 비용이 많이 들고 절차가 복잡하기 때문에, 소송까지 가지 않고 쉽게 층간소음 문제가 해결이 가능한 변호사 명의의 내용증명 발송을 통해 문제를 해결할 수 있습니다.
            전문 변호사가 발송하는 <EventHeaderDescSpan>내용 증명</EventHeaderDescSpan>을 통해 손쉽게 해결해 보세요.
          </EventHeaderDesc>
        </EventHeaderContainer>
      </EventHeaderSection>
      <EventDesc />
      <EventDesc2 />
      <EventDetailSubmit landingPath="/3o3/noise/certificate"/>
      <EventDetailFooter />
    </Container>
  )
};

export default EventNoiseServiceCenter;