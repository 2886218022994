import styled from "@emotion/styled";
import { Modal } from "antd";

export const MobileMenuImg = styled.img`
  width: 20px;
  height: 16px;
//   margin: 0 auto;
  margin-bottom: 8px;
`;


export const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #000;
  font-size: 16px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 600;
`;

export const HeaderModal = styled(Modal)`
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    bottom: 0;
    .ant-modal-body {
        padding-left: 14px;
        padding-top: 17.5px;
    }
`
export const Separator = styled.div`
  width: 100%;
  margin-top: 30px;
  border-bottom: 1px solid #ccc;
  margin: 30px 0;
`

export const HeaderModalTitle = styled.div`
  font-size: 16px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #000000;
  text-align: left;
  margin-top: 30px;
`;

export const NotiWrapper = styled.div`
    width: 44px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      margin-right: 5px;
    }
`

export const NotiImg = styled.img`
  width: 24px;
  height: 24px;  
  @media screen and (max-width: 768px) {
    margin-bottom: 5px;
  }
`

export const HeaderNotiWrapper = styled.div`
    position: absolute;
    width: 343px;
    margin: 0;
    padding: 0;
    right: 10px;
    top: 64px;
    min-height: 140px;
    max-height: 397px;
    overflow: auto;
    border-radius: 2px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
`

export const HeaderNotiContainer = styled.div`
  display: flex;
  width: 343px;
  padding: 20px 10px 20px 4px;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 4px;
`

export const HeaderNotiBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background: #FFF;
`

export const HeaderNotiDivider = styled.div`
  width: 318px;
  height: 1px;
  background: #EBEBEB;
`

export const HeaderNotiTextWrapper = styled.div`
  display: flex;
  width: 279px;
  padding: 16px 29px 16px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  background: #FFF;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`

export const HeaderNotiContent = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`

export const HeaderNotiDate = styled.div`
  color: #838383;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const HeaderNotiArrowWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #FFF;
`

export const HeaderNotiArrow = styled.img`
  width: 5.5px;
  height: 11px;
`

export const MobileHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`

export const MobileHeaderRightWrapper = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
`

export const HeaderImgWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 56px;
`

export const HeaderImg = styled.img``