import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

const { useState } = require("react");
const { useHistory } = require("react-router-dom");
const { default: Step1 } = require("./Step1");

const Certification = ({ loggedInUser }) => {
  const [page, setPage] = useState(0);
  const history = useHistory();
  const userInfo = useRecoilValue(userData);

  const itemLayout = {
    form: {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    },
    button: {
      wrapperCol: { span: 24, offset: 0 },
    },
  };

  const [step1Data, setStep1Data] = useState({
    rentClaim: {
      overdueRent: null,
      period: null,
      otherAmounts : null,
      delayedInterest: "n",
      dueDate: null,
      specialDamages: null,
      isTermination:"n",
      terminationDate:null,
      isAccount: "n",
      name: null,
      bank: null,
      accountNumber: null,
    },
  });

  const [step2Data, setStep2Data] = useState({
    rentInfo: {
      contractDate: null,
      startDate: null,
      endDate: null,
      address1: null,
      address2: null,
      deposit: null,
      rent: null,
      paymentDate:null,
    },
  });

  const [step3Data, setStep3Data] = useState({
    user: {
      information: {
        sender: null,
        senderAddress1: null,
        senderAddress2: null,
        senderPhoneNumber: null,
        receiver: null,
        receiverAddress1: null,
        receiverAddress2: null,
        receiverPhoneNumber: null,
      },
    },
    description: null,
    contact: null,
    promotionCode: null,
  });

  const onPrev = () => {
    setPage(page - 1);
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const onSubmit = async () => {
    const formattedStep1Data = {
      rentClaim: {
        ...step1Data.rentClaim,
        dueDate: step1Data.rentClaim.dueDate
          ? moment(step1Data.rentClaim.dueDate).format("YYYY-MM-DD")
          : null,
        terminationDate: step1Data.rentClaim.terminationDate
          ? moment(step1Data.rentClaim.terminationDate).format("YYYY-MM-DD")
          : null,
      },
    };
    const formattedStep2Data = {
      rentInfo: {
        ...step2Data.rentInfo,
        contractDate: step2Data.rentInfo.contractDate
          ? moment(step2Data.rentInfo.contractDate).format("YYYY-MM-DD")
          : null,
        startDate: step2Data.rentInfo.startDate
          ? moment(step2Data.rentInfo.startDate).format("YYYY-MM-DD")
          : null,
        endDate: step2Data.rentInfo.endDate
          ? moment(step2Data.rentInfo.endDate).format("YYYY-MM-DD")
          : null,
      },
    };

    const propertyDepositData = {
      ...formattedStep1Data,
      ...formattedStep2Data,
      ...step3Data,
      userId: loggedInUser.uid,
    };
    console.log(propertyDepositData)
    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "host_charge_certification",
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: propertyDepositData,
        userId: loggedInUser.uid,
        clientName: userInfo.name,
        status: "submitted",
      });
      history.push("/submit/success");
    } catch (err) {
      history.push("/submit/fail");
    }
  };

  const steps = [
    {
      title: "월세 청구 관련 정보",
      content: (
        <Step1
          data={step1Data}
          setData={setStep1Data}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "월세 청구 관련 정보",
      content: (
        <Step2
          data={step2Data}
          setData={setStep2Data}
          onPrev={onPrev}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "월세 청구 관련 정보",
      content: (
        <Step3
          data={step3Data}
          setData={setStep3Data}
          onPrev={onPrev}
          onNext={onSubmit}
          itemLayout={itemLayout}
        />
      ),
    },
  ];

  return (
    <>
      <div className="steps-content">{steps[page].content}</div>
    </>
  );
};

export default Certification;
