import {
  Button,
  Descriptions,
  Divider,
  Input,
  List,
  message,
} from "antd";
import { dbService } from "fbase";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getTitleFromRequestType } from "components/admin/Admin";

const User = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState();
  const [userRequestData, setUserRequestData] = useState();
  const [channelTalkUrl, setChannelTalkUrl] = useState();

  const [editChannelTalk, setEditChannelTalk] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const history = useHistory();

  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  useEffect(() => {
    dbService
      .collection("users")
      .where("__name__", "==", userId)
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          const userData = snapshot.docs[0].data();
          setUserData({ id: userId, ...userData });
          //   console.log("userData:", userData);
          dbService
            .collection("requests_temp")
            .where("userId", "==", userId)
            .get()
            .then((snapshot) => {
              let userRequestData = [];
              snapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log("request:", doc.id, " => ", doc.data());
                userRequestData.push({ id: doc.id, ...doc.data() });
              });
              //   console.log("userRequestData:", userRequestData);
              setUserRequestData(userRequestData);
            });
          setIsLoaded(true);
          setChannelTalkUrl(
            userData.channelTalkUrl ? userData.channelTalkUrl : null
          );
        } else {
          // console.log("h");
          message.error("잘못된 요청입니다.");
          history.push("/");
        }
      })
      .catch((error) => {
        console.log("error");
        message.error("잘못된 요청입니다.");
        history.push("/");
      });
  }, []);

  const onChannelTalkEditClick = async () => {
    if (editChannelTalk) {
      // save
      try {
        await dbService
          .collection("users")
          .doc(userId)
          .set({ ...userData, channelTalkUrl: channelTalkUrl });
      } catch (err) {
        // console.log(err);
      }
      setEditChannelTalk(false);
    } else {
      // edit mode
      setEditChannelTalk(true);
    }
  };

  const onClickSync = async () => {
    if (editChannelTalk) {
      // save
      try {
        await dbService
          .collection("users")
          .doc(userId)
          .set({ ...userData, channelTalkUrl: channelTalkUrl });
      } catch (err) {
        // console.log(err);
      }
      setEditChannelTalk(false);
    } else {
      // edit mode
      setEditChannelTalk(true);
    }
  };

  return isLoaded ? (
    <>
      <Descriptions
        title="유저정보"
        bordered
        size={"small"}
        column={reactiveColumn}
      >
        <Descriptions.Item label="카카오 이름">
          {userData.kakao_account.profile &&
          userData.kakao_account.profile.nickname
            ? userData.kakao_account.profile.nickname
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="연락처">
          {userData.kakao_account.phone_number
            ? userData.kakao_account.phone_number
            : null}
        </Descriptions.Item>
        <Descriptions.Item label="이메일">
          {userData.kakao_account.email ? userData.kakao_account.email : null}
        </Descriptions.Item>
        <Descriptions.Item label="채널톡">
          <div>
            {editChannelTalk ? (
              <Input
                value={channelTalkUrl}
                onChange={(e) => setChannelTalkUrl(e.target.value)}
              />
            ) : (
              <a href={channelTalkUrl} target="_blank">
                {channelTalkUrl ? "연결" : "없음"}
              </a>
            )}

            <Button style={{ marginLeft: 10 }} onClick={onChannelTalkEditClick}>
              {editChannelTalk ? "저장" : "수정"}
            </Button>
          </div>
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <div className="ant-descriptions-header">
        <div className="ant-descriptions-title">제출내역</div>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={userRequestData}
        renderItem={(request) => (
          <div
            style={{
              backgroundColor: "white",
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "5px",
              paddingBottom: "5px",
              marginBottom: 10,
              borderRadius: 5,
            }}
          >
            <List.Item>
              <List.Item.Meta
                title={
                  <Link to={"/admin/request/" + request.id}>
                    {getTitleFromRequestType(request.serviceType)}
                  </Link>
                }
                description={new Date(request.createdAt).toLocaleDateString(
                  "ko-KR"
                )}
              />

              <div>
                {request.status === "submitted"
                  ? "제출완료"
                  : request.status === "replied"
                  ? "답변완료"
                  : request.status === "paid"
                  ? "결제완료"
                  : request.status === "requested"
                  ? "서류요청"
                  : request.status === "processing"
                  ? "요청 처리중"
                  : request.status === "done"
                  ? "처리완료"
                  : "제출완료"}
              </div>
            </List.Item>
          </div>
        )}
      />
    </>
  ) : null;
};

export default User;
