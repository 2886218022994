import IcNaverBlog from "../assets/img/IcNaverBlog.png";
import {
  FooterBorder,
  FooterDesc,
  FooterFirstTitle,
  FooterFirstTitleImgWrapper,
  FooterImg,
  FooterSection,
  FooterWrapper,
  FooterTitle,
  FooterTitleImgWrapper,
} from "./Footer.style";
import { openInNewTab } from "util/Handler";
import { ContentContainer } from "routes/Landing.style";
import { removeBrTag } from "routes/Landing";


const Footer = () => {

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const fooerBlogText = () => {
    const text = isMobile
      ? "더 많은 법률 이야기는\n블로그에서 확인해주세요!"
      : "더 많은 법률 이야기는 블로그에서 확인해주세요!";
    return removeBrTag(text);
  };

  return (
    <FooterSection>
      <ContentContainer>
        <FooterWrapper>
          <FooterFirstTitleImgWrapper
            onClick={() => {
              openInNewTab("https://blog.naver.com/by-law");
            }}
          >
            <FooterImg src={IcNaverBlog} />
            <FooterFirstTitle>
              {fooerBlogText()}
            </FooterFirstTitle>
            <a herf="https://m.blog.naver.com/by-law/223231563121"/>
          </FooterFirstTitleImgWrapper>
          <FooterBorder />
          <FooterTitleImgWrapper>
            <FooterTitle>(주) 유스티티아</FooterTitle>
          </FooterTitleImgWrapper>
          <FooterDesc>
            대표자 : 우효경 | 개인정보보호책임자 : 오승준
            <br />
            사업자등록번호 : 825-86-02513 | 통신판매업 신고번호 : 제
            2022-서울강남-05674호
            <br />
            서울특별시 강남구 테헤란로 82길 15 12층(대치동, 디아이타워)
            <br />
            Copyright © Justitia Co.Ltd. All Rights Reserved
          </FooterDesc>
        </FooterWrapper>
      </ContentContainer>
    </FooterSection>
  );
};

export default Footer;
