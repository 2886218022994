import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Popover,
  Radio,
  Row,
} from "antd";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,
  CaseDetailWrapper,
} from "../../Form.style";

import { useEffect, useState } from "react";

const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values);
    setData(values);
    onNext();
  };

  const onDueDateUnknownChanged = (name) => {
    const { loans } = form.getFieldsValue();
    loans[name].dueDate.value = null;
    form.setFieldsValue({ loans });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
          <Title>헬스/필라테스 환불 내용증명</Title>

          <div style={{ marginTop: 10 }}>
            <SubText style={{ textAlign: "left" }} type="secondary">
              헬스/필라테스 계약에 관련하여 아래 내용을 작성하세요.
            </SubText>
          </div>

          <Divider style={{ marginTop: 10 }} />
          <CaseDetailWrapper style={{ height: "80%" }}>
            <Form
              {...itemLayout.form}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={data}
              form={form}
              style={{
                marginTop: 10,
                marginBottom: 20,
                width: "100%",
                textAlign: "left",
              }}
              requiredMark={false}
            >
              <Form.Item
                label={
                  <>
                    양도 유무
                    <Popover
                      content={
                        <div>
                          양도를 받은 계약의 경우 예를 선택하시고,
                          <br />
                          아래 날짜 및 금액은 양도 전 최초 계약서 
                          <br />
                          기준으로 작성해주세요.
                        </div>
                      }
                      placement="bottom"
                      trigger="click"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  </>
                }
                name={["pilates", "hangover"]}
                rules={[{ required: true, message: "" }]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Radio.Group>
                  <Radio.Button value="y">예</Radio.Button>
                  <Radio.Button value="n">아니오</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="계약 체결 일자"
                name={["pilates", "contarctDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>

              <Form.Item
                label="계약 시작 일자"
                name={["pilates", "startDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
             
              <Form.Item
                label="계약 종료 일자"
                name={["pilates", "endDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>

              <Form.Item
                      label="결제 금액"
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name={["pilates", "amount"]}
                        style={{
                          width: "80%",
                          // maxWidth: 150,
                          display: "inline-block",
                        }}
                        rules={
                          form.getFieldValue([
                            "noise",
                            "compensation",
                            "exist",
                          ]) === "n"
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "금액을 입력해주세요",
                                },
                              ]
                        }
                      >
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          addonBefore="금"
                          addonAfter="원"
                          controls={false}
                        />
                      </Form.Item>           
              </Form.Item>

              <Row justify="space-between">
                <Col span={6} offset={18} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    다음
                  </Button>
                </Col>
              </Row>
            </Form>
          </CaseDetailWrapper>
        </ContentContainer>
      </MyCaseSection>
    </Container>
  );
};

export default Step1;
