import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

const { useState } = require("react");
const { useHistory } = require("react-router-dom");
const { default: Step1 } = require("./Step1");

const Protection = ({ loggedInUser }) => {
  const [page, setPage] = useState(0);
  const history = useHistory();
  const userInfo = useRecoilValue(userData);

  const defaultValues = {};

  const itemLayout = {
    form: {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    },
    button: {
      wrapperCol: { span: 24, offset: 0 },
    },
  };

  const [step1Data, setStep1Data] = useState({
    contract: {
      date: null,
      deposit: {
        date: null,
        value: null,
      },
      monthlyRent: {
        exist: "n",
        amount: null,
        date: null,
      },
    },
  });
  const [step2Data, setStep2Data] = useState({
    termination: {
      date: null,
      reason: null,
      notification: {
        exist: "n",
        date: null,
        recordYn: null,
      },
    },
  });

  const [step3Data, setStep3Data] = useState({
    exclusions: [],
  });

  const [step4Data, setStep4Data] = useState({
    insurance: {
      registYn: null,
    },
    depositHistory: {
      exist: null,
    },
    lendlord: {
      addressYn: null,
      ssnYn: null,
    },
    description: null,
    contact: null,
    promotionCode: null,
  });

  const onPrev = () => {
    setPage(page - 1);
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const onSubmit = async () => {
    const formattedStep1Data = {
      contract: {
        ...step1Data.contract,
        date: step1Data.contract.date
          ? moment(step1Data.contract.date).format("YYYY-MM-DD")
          : null,
        deposit: {
          ...step1Data.contract.deposit,
          date: step1Data.contract.deposit.date
            ? moment(step1Data.contract.deposit.date).format("YYYY-MM-DD")
            : null,
        },
      },
    };

    const formattedStep2Data = {
      termination: {
        ...step2Data.termination,
        date: step2Data.termination.date
          ? moment(step2Data.termination.date).format("YYYY-MM-DD")
          : null,
        notification: {
          ...step2Data.termination.notification,
          date: step2Data.termination.notification.date
            ? moment(step2Data.termination.notification.date).format(
                "YYYY-MM-DD"
              )
            : null,
        },
      },
    };

    const propertyDepositData = {
      ...formattedStep1Data,
      ...formattedStep2Data,
      ...step3Data,
      ...step4Data,
      userId: loggedInUser.uid,
    };

    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "housing_protection",
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: propertyDepositData,
        userId: loggedInUser.uid,
        clientName: userInfo.name,
        status: "submitted",
      });
      history.push("/submit/success");
    } catch (err) {
      history.push("/submit/fail");
    }
  };

  const steps = [
    {
      title: "임대차 계약 정보",
      content: (
        <Step1
          data={step1Data}
          setData={setStep1Data}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "임대차 계약 종료 정보",
      content: (
        <Step2
          data={step2Data}
          setData={setStep2Data}
          onPrev={onPrev}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "전세금 반환 정보",
      content: (
        <Step3
          data={step3Data}
          setData={setStep3Data}
          onPrev={onPrev}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "전세금 반환 정보",
      content: (
        <Step4
          data={step4Data}
          setData={setStep4Data}
          onPrev={onPrev}
          onNext={onSubmit}
          itemLayout={itemLayout}
        />
      ),
    },
  ];

  return (
    <>
      <div className="steps-content">{steps[page].content}</div>
    </>
  );
};

export default Protection;
