import { useHistory } from "react-router-dom";

import IcEstateAllServiceMain from "assets/svg/IcEstateAllServiceMain.svg";
import IcEstateAllServiceMainMobile from "assets/svg/IcEstateAllServiceMainMobile.svg";
import IcEstateAllServiceBody from "assets/svg/IcEstateAllServiceBody.svg";
import IcEstateAllServiceBodyMobile from "assets/svg/IcEstateAllServiceBodyMobile.svg";
import IcEstateAllStep1 from "assets/svg/IcEstateAllStep1.svg";
import IcEstateAllStep2 from "assets/svg/IcEstateAllStep2.svg";
import IcEstateAllStep3 from "assets/svg/IcEstateAllStep3.svg";
import IcEstateAllStep4 from "assets/svg/IcEstateAllStep4.svg";
import IcEstateAllStep5 from "assets/svg/IcEstateAllStep5.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterEstateContainer,
  ServiceCenterEstateSection,
  ServiceCenterEstateStepButton,
  ServiceCenterEstateStepButtonImg,
  ServiceCenterEstateStepButtonTitle,
  ServiceCenterEstateStepContainer,
  ServiceCenterEstateStepTextContainer,
  ServiceCenterEstateStepTextDesc,
  ServiceCenterEstateStepTextStep,
  ServiceCenterEstateStepTextTitle,
  ServiceCenterEstateTitle,
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
  ServiceCenterFooterBtn,
  ServiceCenterFooterContainer,
  ServiceCenterFooterDesc,
  ServiceCenterFooterSection,
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle,
  ServiceCenterSecondContainer,
  ServiceCenterSecondDesc,
  ServiceCenterSecondImg,
  ServiceCenterSecondSection,
  ServiceCenterSecondSubTitle,
  ServiceCenterSecondTextContainer,
  ServiceCenterSecondTitle,
  ServiceCenterThirdContainer,
  ServiceCenterThirdDesc,
  ServiceCenterThirdDescSpan,
  ServiceCenterThirdImg,
  ServiceCenterThirdImgMobile,
  ServiceCenterThirdSection,
  ServiceCenterThirdTextContainer,
  ServiceCenterThirdTitle,
  ServiceEstateAllCenterSecondContainer
} from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const EstateAllServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = "전･월세 보증금\n돌려 받기";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = isMobile
      ? "돌려 받기 위한\n다양한 법률 단계"
      : "돌려 받기 위한 다양한 법률 단계";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = "상황에 맞는 법률 서비스 제공";
    return removeBrTag(text);
  };

  const body2Title = () => {
    const text = isMobile
      ? "부동산 사건 전문\n법대리에서!"
      : "부동산 사건 전문 법대리에서!";
    return removeBrTag(text);
  };

  const footerTitle = () => {
    const text = isMobile
      ? "부동산 관련 도움이\n필요하신가요?"
      : "부동산 관련 도움이 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcEstateAllServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcEstateAllServiceMain}
            />
            <ServiceCenterFirstImg src={IcEstateAllServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceEstateAllCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
              {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              전･월세 보증금 미반환 사건의 경우 임대차 계약 종료 전 내용증명 발송을
              시작으로 정확한 절차에 따라 대응을 하셔야 안전하게 보증금을 지킬 수 있습니다.
              전･월세 보증금 미반환 사건은 법대리가 가장 자신 있는 분야이며, 최상의 고객 만족도를 자랑합니다.
              수많은 전세금 반환 문제를 해결한 전문 변호사에게 무료로 검토 받아 보세요.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcEstateAllServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcEstateAllServiceBody}
            />
            <ServiceCenterSecondImg src={IcEstateAllServiceBody} />
          </picture>
        </ServiceEstateAllCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterEstateSection>
        <ServiceCenterEstateContainer>
          <ServiceCenterEstateTitle>
            {body2Title()}
          </ServiceCenterEstateTitle>
          <ServiceCenterEstateStepContainer>
            <ServiceCenterEstateStepTextContainer>
              <ServiceCenterEstateStepTextStep>
                Step 1
              </ServiceCenterEstateStepTextStep>
              <ServiceCenterEstateStepTextTitle>
                전세금 반환 내용 증명 보내기
              </ServiceCenterEstateStepTextTitle>
              <ServiceCenterEstateStepTextDesc>
                계약 종료 전 임대인의 보증금 반환 의사가 불분명한 경우,
                변호사가 직접 작성한 내용증명을 통해 빈틈없이 조치를 취하세요.
                내용증명은 집주인을 압박하여 전세금을 원만하게 돌려 받을 수 있는 가장 간단한 방법입니다.
              </ServiceCenterEstateStepTextDesc>
            </ServiceCenterEstateStepTextContainer>
            <ServiceCenterEstateStepButton onClick={() => history.push("/apply/monthlyPayCertService")}>
              <ServiceCenterEstateStepButtonTitle>
                내용 증명 보내기
              </ServiceCenterEstateStepButtonTitle>
              <ServiceCenterEstateStepButtonImg src={IcEstateAllStep1} />
            </ServiceCenterEstateStepButton>
          </ServiceCenterEstateStepContainer>
          <ServiceCenterEstateStepContainer>
            <ServiceCenterEstateStepTextContainer>
              <ServiceCenterEstateStepTextStep>
                Step 2
              </ServiceCenterEstateStepTextStep>
              <ServiceCenterEstateStepTextTitle>
                내용 증명 공시송달
              </ServiceCenterEstateStepTextTitle>
              <ServiceCenterEstateStepTextDesc>
                계약 종료 전 임대인에게 계약해지 의사 전달이 되지 않을 경우
                변호사가 직접 신청하는 공시송달을 통해 계약해지 의사 표시를 진행하세요.
              </ServiceCenterEstateStepTextDesc>
            </ServiceCenterEstateStepTextContainer>
            <ServiceCenterEstateStepButton onClick={() => history.push("/apply/monthlyPayPublicNoticeService")}>
              <ServiceCenterEstateStepButtonTitle>
                공시송달 보내기
              </ServiceCenterEstateStepButtonTitle>
              <ServiceCenterEstateStepButtonImg src={IcEstateAllStep2} />
            </ServiceCenterEstateStepButton>
          </ServiceCenterEstateStepContainer>
          <ServiceCenterEstateStepContainer>
            <ServiceCenterEstateStepTextContainer>
              <ServiceCenterEstateStepTextStep>
                Step 3
              </ServiceCenterEstateStepTextStep>
              <ServiceCenterEstateStepTextTitle>
                임차권 등기명령
              </ServiceCenterEstateStepTextTitle>
              <ServiceCenterEstateStepTextDesc>
                전세금을 반환받지 못했으나 다른 곳으로 이사를 가야할 때,
                변호사를 통해 임차권 등기명령을 신청하여 대항력과 우선변제권을 유지하세요.
              </ServiceCenterEstateStepTextDesc>
            </ServiceCenterEstateStepTextContainer>
            <ServiceCenterEstateStepButton onClick={() => history.push("/apply/housingProtectService")}>
              <ServiceCenterEstateStepButtonTitle>
                등기명령 신청하기
              </ServiceCenterEstateStepButtonTitle>
              <ServiceCenterEstateStepButtonImg src={IcEstateAllStep3} />
            </ServiceCenterEstateStepButton>
          </ServiceCenterEstateStepContainer>
          <ServiceCenterEstateStepContainer>
            <ServiceCenterEstateStepTextContainer>
              <ServiceCenterEstateStepTextStep>
                Step 4
              </ServiceCenterEstateStepTextStep>
              <ServiceCenterEstateStepTextTitle>
                부동산 가압류
              </ServiceCenterEstateStepTextTitle>
              <ServiceCenterEstateStepTextDesc>
                계약 기간 종료 이후에도 전세금을 반환받지 못했으나 다른 곳으로 이사를 가야할 때,
                변호사를 통해 부동산 가압류를 신청하여 임대인을 압박하세요.
              </ServiceCenterEstateStepTextDesc>
            </ServiceCenterEstateStepTextContainer>
            <ServiceCenterEstateStepButton onClick={() => history.push("/apply/monthlyPaySeizureService")}>
              <ServiceCenterEstateStepButtonTitle>
                가압류 신청하기
              </ServiceCenterEstateStepButtonTitle>
              <ServiceCenterEstateStepButtonImg src={IcEstateAllStep4} />
            </ServiceCenterEstateStepButton>
          </ServiceCenterEstateStepContainer>
          <ServiceCenterEstateStepContainer>
            <ServiceCenterEstateStepTextContainer>
              <ServiceCenterEstateStepTextStep>
                Step 5
              </ServiceCenterEstateStepTextStep>
              <ServiceCenterEstateStepTextTitle>
                보증금 반환 지급 명령
              </ServiceCenterEstateStepTextTitle>
              <ServiceCenterEstateStepTextDesc>
                계약 기간 종료 후에도 보증금 안 돌려주는 임대인에게 변호사가 직접 작성한
                지급 명령 소송서를 통해 법적으로 해결하세요. 모든 소송 관련 비용은 임대인에게 청구할 수 있습니다.
              </ServiceCenterEstateStepTextDesc>
            </ServiceCenterEstateStepTextContainer>
            <ServiceCenterEstateStepButton onClick={() => history.push("/apply/monthlyPayOrderService")}>
              <ServiceCenterEstateStepButtonTitle>
                지급 명령 보내기
              </ServiceCenterEstateStepButtonTitle>
              <ServiceCenterEstateStepButtonImg src={IcEstateAllStep5} />
            </ServiceCenterEstateStepButton>
          </ServiceCenterEstateStepContainer>
        </ServiceCenterEstateContainer>
      </ServiceCenterEstateSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
              {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면
              분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtn onClick={() => {
            if (!loggedInUser) {
              ChannelService.loadScript();
              ChannelService.boot({
                "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
              })
            }
            ChannelService.showMessenger();
          }}>
            간편 상담 받기
          </ServiceCenterFooterBtn>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default EstateAllServiceCenter;
