import {
    QuestionCircleOutlined,
  } from "@ant-design/icons";
  import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Popover,
    Row,
    message,
  } from "antd";
  
  import {
    Title,
    SubText,
    ContentContainer,
    MyCaseSection,
    Container,
    CaseDetailWrapper,
  } from "../../../Form.style";
  
  import { useEffect, useState } from "react";
  const { TextArea } = Input;
  
  const Step1 = ({ data, setData, onNext, itemLayout }) => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showCustomInput, setShowCustomInput] = useState(false); // state to control the visibility of the input box
    const key = "updatable";
    const [etc, setEtc] = useState("");

    const handleCheckboxChange = (checkedValues) => {
        if (checkedValues.includes("기타")) {
          setShowCustomInput(true); // show the input box if "other" checkbox is selected
        } else {
          setShowCustomInput(false); // hide the input box if "other" checkbox is not selected
          setEtc("");
        }
        form.setFieldsValue({ evidence: checkedValues }); // set the selected checkbox values
    };

    const handleCustomValueChange = (event) => {
        setEtc(event.target.value);
    };
  
    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const handleAddressSelect = (data) => {
      const selectedAddress = data.address;
      form.setFieldsValue({
        civilDefendant: { information: { address1: selectedAddress } },
      });
      setIsModalOpen(false);
    };
  
    const onFinish = (values) => {
      // values = { ...values, otherEvidence: otherEvidence };
      console.log("values.civilDefendant",values.civilDefendant);
      values.civilDefendant.etc = etc;
      console.log(values);
      setSubmitting(true);
      setData(values);
    };
  
    useEffect(() => {
    if (submitting) {
        message.success({
            content: "제출 완료!",
            key,
            duration: 2,
        });
        onNext();
        }
    }, [submitting]);
  
    return (
      <Container style={{ textAlign: "left" }}>
        <MyCaseSection>
          <ContentContainer>
            <Title>민사 소송 원고</Title>
  
            <div style={{ marginTop: 10 }}>
              <SubText style={{ textAlign: "left" }} type="secondary">
                사건에 관련하여 아래 내용을 작성하세요.
              </SubText>
            </div>
  
            <Divider style={{ marginTop: 10 }} />
            <CaseDetailWrapper style={{ height: "90%" }}>
              <Form
                {...itemLayout.form}
                layout="horizontal"
                onFinish={onFinish}
                initialValues={data}
                form={form}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  width: "100%",
                  textAlign: "left",
                }}
                requiredMark={false}
              >
                <Form.Item
                  label="사건의 종류"
                  name={["civilDefendant", "caseType"]}
                  rules={[
                    {
                      required: true,
                      message: "사건의 종류",
                    },
                  ]}
                >
                  <Checkbox.Group style={{ width: "100%" }} onChange={handleCheckboxChange}>
                    <Row>
                      <Col span={12}>
                        <Checkbox value="대여금">
                          대여금
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox value="매매대금">매매대금</Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox value="공사대금">
                          공사대금
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox value="임금 등">
                          임금 등
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox value="손해배상">손해배상</Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox value="기타">기타                        
                        </Checkbox>
                      </Col>
                    </Row>
                    <br />
                        {showCustomInput && (
                          <Input
                            style={{ width: "100%" }}
                            onChange={handleCustomValueChange}
                          />
                        )}
                  </Checkbox.Group>
                </Form.Item>
                
                <Form.Item
                    label="채권액"
                    name={["civilDefendant", "dept", "amount"]}
                    rules={[
                    {
                        required: true,
                        message: "청구하는 채권액을 입력해주세요.",
                    },
                    ]}
                    // style={{ width: "calc(85% - 8px)" }}
                >
                    <InputNumber
                    formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    addonBefore="금"
                    addonAfter="원"
                    controls={false}
                    />
                </Form.Item>

                <Form.Item
                  label="상대방의 채무 인정 여부"
                  name={["civilDefendant", "dept", "recognized"]}
                  rules={[
                    {
                      required: true,
                      message: "채무 인정 여부",
                    },
                  ]}
                >
                  <Checkbox.Group style={{ width: "100%" }}>
                    <Row>
                      <Col span={12}>
                        <Checkbox value="예">
                          예
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox value="아니요">아니요</Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox value="알 수 없음">
                        알 수 없음
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>             
  
                <Form.Item
                    label={
                    <>
                        상세내용
                        <Popover
                        content={
                            <div>
                            기타 입력하신 내용 외 특이사항이 있을 경우 작성해 주시면
                            변호사가 검토시 참고할 예정입니다.
                            </div>
                        }
                        placement="bottom"
                        trigger="click"
                        >
                        <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                        </Popover>
                    </>
                    }
                    name={"description"}
                >
                    <TextArea rows={10} />
                </Form.Item>
  
                <Row justify="space-between">
                  <Col span={6} offset={18} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%", maxWidth: "100px" }}
                    >
                      {submitting ? "제출 중" : "제출"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CaseDetailWrapper>
          </ContentContainer>
        </MyCaseSection>
      </Container>
    );
  };
  
  export default Step1;
  