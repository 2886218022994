import dayjs from "dayjs";

export function getFormattedDate(dateString) {
    if (dateString === undefined) {
      return;
    }
    
    let date = Date();
    if (dateString._seconds) {
      date = dayjs.unix(dateString._seconds).format('YYYY-MM-DD')
    } else if (dateString.seconds) {
        date = dayjs.unix(dateString.seconds).format('YYYY-MM-DD')
    } else {
      date = dayjs(dateString).format('YYYY-MM-DD')
    }
    return date;  
  }

  export function getFormattedCaseDate(dateString) {
    if (dateString === undefined) {
      return;
    }
    
    let date = Date();
    if (dateString._seconds) {
      date = dayjs.unix(dateString._seconds).format('YYYY.MM.DD')
    } else if (dateString.seconds) {
        date = dayjs.unix(dateString.seconds).format('YYYY.MM.DD')
    } else {
      date = dayjs(dateString).format('YYYY.MM.DD')
    }
    return date;  
  }