import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Row,
  Modal,
} from "antd";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,
  CaseDetailWrapper,
} from "../../../Form.style";
import DaumPostcode from "react-daum-postcode";

import { useEffect, useState } from "react";

const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddressSelect = (data) => {
    const selectedAddress = data.address;
    form.setFieldsValue({
      cancel: { address1: selectedAddress },
    });
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    console.log(values);
    setData(values);
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
          <Modal
            title="주소 검색"
            open={isModalOpen}
            onCancel={closeModal}
            footer={null}
          >
            <DaumPostcode onComplete={handleAddressSelect} />
          </Modal>
          <Title>부동산 계약해지 내용증명</Title>

          <div style={{ marginTop: 10 }}>
            <SubText style={{ textAlign: "left" }} type="secondary">
            부동산 계약해지 관련하여 아래 내용을 작성하세요.
            </SubText>
          </div>

          <Divider style={{ marginTop: 10 }} />
          <CaseDetailWrapper style={{ height: "90%" }}>
            <Form
              {...itemLayout.form}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={data}
              form={form}
              style={{
                marginTop: 10,
                marginBottom: 20,
                width: "100%",
                textAlign: "left",
              }}
              requiredMark={false}
            >
              <Form.Item
                label="임대차 계약 일자"
                name={["cancel", "contractDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              {/* <Form.Item
                label="임대한 대상 주소"
                name={["cancel", "address1"]}
                rules={[
                  {
                    required: true,
                    message: "임대한 대상 주소를 입력해주세요",
                  },
                ]}
                onClick={showModal}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="클릭하여 주소를 입력해주세요"
                />
              </Form.Item>
              <Form.Item
                label="상세 주소"
                name={["cancel", "address2"]}
                rules={[
                  {
                    required: true,
                    message: "임대한 대상 곳 주소를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="상세 주소를 입력해주세요"
                />
              </Form.Item> */}
              <Form.Item
                label="임대 시작 일자"
                name={["cancel", "startDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              
              <Form.Item
                label="임대 종료 일자"
                name={["cancel", "endDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              <Form.Item
                label="보증금"
                name={["cancel", "deposit"]}
                rules={[
                  {
                    required: true,
                    message: "보증금을 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addonAfter="원"
                  controls={false}
                />
              </Form.Item>

              <Form.Item
                label="임대료"
                name={["cancel", "rent"]}
                rules={[
                  {
                    required: true,
                    message: "임대료를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addonBefore="월"
                  addonAfter="원"
                  controls={false}
                />
              </Form.Item>

              <Form.Item
                label="임대료 지급일"
                name={["cancel", "paymentDate"]}
                rules={[
                  {
                    required: true,
                    message: "임대료 지급일을 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  placeholder="매달 25일"
                  controls={false}
                />
              </Form.Item>
              

              <Form.Item
                label={
                  <>
                    계약을 해지하려는 이유
                    <Popover
                      content={
                        <div>
                          상가는 세번의, 그 외는 두번의 임대료에 해당하는
                          <br/>
                          금액 이상을 연체한 경우 특별한 사정이 없으면
                          <br/>
                          계약을 해지할 수 있습니다.
                        </div>
                      }
                      placement="bottom"
                      trigger="click"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  </>
                }
                name={["cancel", "reason"]}
                rules={[
                  {
                    required: true,
                    message: "계약을 해지하려는 이유를 선택해주세요",
                  },
                ]}
              >
                <Checkbox.Group style={{ width: "100%" }}>
                  <Row>
                    <Col span={12}>
                      <Checkbox value="임대료 연체">
                        임대료 연체
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="임대 대상물 무단변경">
                        임대 대상물 무단변경
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="무단 전대차">
                        무단 전대차
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="무단 임차권 양도">
                        무단 임차권 양도
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="임대기간 만료">
                        임대기간 만료
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="기타">
                        기타
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                label={
                  <>
                    세입자에게 요구할 사항
                    <Popover
                      content={
                        <div>
                          부동산 명도(인도)란, 부동산의 적법한 점유권한 있는
                          <br/>
                          자가 현재 부동산을 점유하고 있는 상대방에 대하여
                          <br/>
                          부동산을 넘겨줄 것을 청구하는 것입니다.
                        </div>
                      }
                      placement="bottom"
                      trigger="click"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  </>
                }
                name={["cancel", "request"]}
                rules={[
                  {
                    required: true,
                    message: "세입자에게 요구할 사항을 선택해주세요",
                  },
                ]}
              >
                <Checkbox.Group style={{ width: "100%" }}>
                  <Row>
                    <Col span={12}>
                      <Checkbox value="임대 대상물 명도조치">
                        임대 대상물 명도조치
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="특별 손해 고지">
                        특별 손해 고지
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Row justify="space-between">
                <Col span={6} offset={18} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    다음
                  </Button>
                </Col>
              </Row>
            </Form>
          </CaseDetailWrapper>
        </ContentContainer>
      </MyCaseSection>
    </Container>
  );
};

export default Step1;
