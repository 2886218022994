import ChannelService from "components/ChannelService";
import { authService } from "fbase";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    ChannelService.hideChannelButton();
    // Kakao.Auth.logout();
    authService.signOut();
    history.push("/");
  }, []);
};

export default Logout;
