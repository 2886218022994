import IcEstimate from "../../assets/svg/IcEstimate.svg";
import IcShieldCheck from "../../assets/svg/IcShieldCheck.svg";
import ImgServiceIntroduction1 from "../../assets/svg/ImgServiceIntroduction1.svg";
import ImgServiceIntroduction2 from "../../assets/svg/ImgServiceIntroduction2.svg";
import ImgServiceIntroduction3 from "../../assets/svg/ImgServiceIntroduction3.svg";
import ImgServiceIntroduction4 from "../../assets/svg/ImgServiceIntroduction4.svg";
import LogoImage from "../../assets/svg/LogoImage.svg";
import {
  Container,
  ContentContainer
} from "../Service.style";
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import Footer from "components/Footer";

import {
  AfterDesc,
  AfterTitle,
  AfterWrapper,
  BeforeAfterWrapper,
  BeforeAfterSection,
  BeforeAfterTitle,
  BeforeDesc,
  BeforeTitle,
  BeforeWrapper,
  IntroCard,
  IntroCardDesc,
  IntroCardDescMobile,
  IntroCardDescReversed,
  IntroCardImg,
  IntroCardImgWrapper,
  IntroCardRevered,
  IntroCardRow,
  IntroCardTitle,
  IntroCardTitleDescWrapper,
  IntroCenterTitle,
  IntroDesc,
  IntroSectionFourth,
  IntroHeaderWrapper,
  IntroImg,
  IntroImgWrapper,
  IntroSectionFirst,
  IntroSupportCard,
  IntroSupportCardDesc,
  IntroSupportCardRowWrapper,
  IntroSupportCardSecionTitle,
  IntroSupportCardTitle,
  IntroShieldImg,
  IntroSubTitle,
  IntroSubTitle1,
  IntroTitle,
  IntroTitleDescWrapper,
  ServiceBtnFreeEstimate,
  IntroCardTitlReversed,
  BottomContainer
} from "./ServiceIntroduction.style";

import { Fragment } from "react";

const ServiceIntroduction = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    
    window.scrollTo(0, 0);
    
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const beforeAfterTitle = (() => {
    const text = isMobile ? "나홀로 소송,\n법대리와 함께하세요!" : "나홀로 소송, 법대리와 함께하세요!"
    return text.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ));
  })

  const beforeDesc = (() => {
    const text = isMobile ? 
    "혼자서 법률 소송을 준비하느라\n 불안감에 잠 못 이루셨나요?\n불안만 가중시키는 법률 서비스에 지치셨나요?" : 
    "혼자서 법률 소송을 준비하느라 불안감에 잠 못 이루셨나요?\n불안만 가중시키는 법률 서비스에 지치셨나요?"
    return text.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ));
  })

  const afterDesc = (() => {
    const text = isMobile ?
     "소송시 발생하는 비용과 시간을\n충분히 세이브해서\n평화로운 일상과 마음의 여유를 되찾으세요." : 
    "소송시 발생하는 비용과 시간을 충분히 세이브해서\n평화로운 일상과 마음의 여유를 되찾으세요."
    return text.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ));
  });

  const titleDesc = (() => {
    const text = isMobile ?
     "오직 의뢰인만을 위해\n만들어진 가장 쉽고\n합리적인 법률 서비스" : 
    "오직 의뢰인만을 위해 만들어진\n가장 쉽고 합리적인 법률 서비스"
    return text.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ));
  });

  const descText = (() => {
    const text = isMobile ?
     "“의뢰인 친화적 법률 플랫폼” 을 통해\n법률 서비스 대중화를\n목표로 합니다." : 
    "“의뢰인 친화적 법률 플랫폼” 을 통해 법률 서비스 대중화를\n목표로 합니다."
    return text.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ));
  });
  
  return (
    <Container>
      <IntroSectionFirst>
        <ContentContainer style={{ display: "flex", justifyContent: "center", maxWidth: "1080px", height: '100%' }}>
          <IntroHeaderWrapper>
            <IntroTitleDescWrapper>
              <IntroTitle style={{ color: "#fff" }}>
                {titleDesc()}
              </IntroTitle>
              <IntroDesc style={{ color: "#A4A4A4" }}>
                {descText()}
              </IntroDesc>
            </IntroTitleDescWrapper>
            <IntroImg windowWidth={windowWidth} src={LogoImage} />
          </IntroHeaderWrapper>
        </ContentContainer>
      </IntroSectionFirst>
      <section
        style={{
          width: "100%",
          background: "#F6F6F6",
          height: "auto",
          padding: "12px 0 162px 0",
        }}
      >
        <ContentContainer
          style={{
            maxWidth: "1080px",
          }}
        >
          <IntroSubTitle1>
            이런 법률 고민, 있으신가요?
          </IntroSubTitle1>
          <IntroSupportCardRowWrapper>
            <IntroSupportCard style={{ marginRight: 22 }}>
              <IntroSupportCardSecionTitle>고민 1</IntroSupportCardSecionTitle>
              <IntroSupportCardTitle>
                법적절차와 용어가
                <br />
                너무 어려워요
              </IntroSupportCardTitle>
              <IntroSupportCardDesc>
                일반인들에게 법률 용어와 법적 절차 과정은
                <br />
                굉장히 생소하고 낯설기만 합니다.
              </IntroSupportCardDesc>
            </IntroSupportCard>
            <IntroSupportCard>
              <IntroSupportCardSecionTitle>고민 2</IntroSupportCardSecionTitle>
              <IntroSupportCardTitle>
                번호사 비용이 너무
                <br />
                부담돼요
              </IntroSupportCardTitle>
              <IntroSupportCardDesc>
                변호사는 선임만을 제안하며, 선임하는데 드는 비용은
                <br />
                평균 300만원 이상입니다.
              </IntroSupportCardDesc>
            </IntroSupportCard>
          </IntroSupportCardRowWrapper>
          <IntroSupportCardRowWrapper>
            <IntroSupportCard style={{ marginRight: 22 }}>
              <IntroSupportCardSecionTitle>고민 3</IntroSupportCardSecionTitle>
              <IntroSupportCardTitle>
                법적조치를 어디서부터 어떻게
                <br />
                시작해야 할지 모르겠어요
              </IntroSupportCardTitle>
              <IntroSupportCardDesc>
                내 상황에 딱 맞는 법적 조치를 어떻게
                <br />
                취할 수 있는지 판단하기 어렵습니다
              </IntroSupportCardDesc>
            </IntroSupportCard>
            <IntroSupportCard>
              <IntroSupportCardSecionTitle>고민 4</IntroSupportCardSecionTitle>
              <IntroSupportCardTitle>
                잘못된 법률정보로
                <br />큰 손해를 입었어요
              </IntroSupportCardTitle>
              <IntroSupportCardDesc>
                개개인의 상황을 고려하지 않은 잘못된 법률정보로
                <br />
                손해를 볼 수도 있습니다
              </IntroSupportCardDesc>
            </IntroSupportCard>
          </IntroSupportCardRowWrapper>
        </ContentContainer>
      </section>
      <BeforeAfterSection>
        <ContentContainer
          style={{
            maxWidth: "977px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <IntroImgWrapper>
            <IntroShieldImg
              src={IcShieldCheck}
            />
          </IntroImgWrapper>
          <BeforeAfterTitle>
          {beforeAfterTitle()}
          </BeforeAfterTitle>
          <BeforeAfterWrapper>
            <BeforeWrapper>
              <BeforeTitle>BEFORE</BeforeTitle>
              <BeforeDesc>
                {beforeDesc()}
              </BeforeDesc>
            </BeforeWrapper>
            <AfterWrapper>
              <AfterTitle>AFTER</AfterTitle>
              <AfterDesc>
               {afterDesc()}
              </AfterDesc>
            </AfterWrapper>
          </BeforeAfterWrapper>
        </ContentContainer>
      </BeforeAfterSection>
      <IntroSectionFourth>
        <ContentContainer style={{
            maxWidth: "1080px",
          }}>
          <IntroSubTitle>
            법대리는 꼭 필요한 서비스만
            <br />
            합리적인 가격에 제공합니다
          </IntroSubTitle>
          <IntroCardRow>
            <IntroCard>
              <IntroCardTitleDescWrapper>
                <IntroCardTitle>
                  내 상황에 딱 맞는
                  <br />
                  법률 솔루션 패키지
                </IntroCardTitle>
                <IntroCardDesc>
                  변호사 선임 뿐만 아니라, 내 상황에 꼭 필요한 부분만
                  <br />
                  제안하는 법대리의 합리적인 법률 솔루션
                </IntroCardDesc>
              </IntroCardTitleDescWrapper>
              <IntroCardImgWrapper style={{ backgroundColor: "#ffffff"}}>
                <IntroCardImg src={ImgServiceIntroduction1} />
              </IntroCardImgWrapper>
              <IntroCardDescMobile>
                변호사 선임 뿐만 아니라, 
                <br />
                내 상황에 꼭 필요한 부분만
                <br />
                제안하는 법대리의 합리적인 법률 솔루션
              </IntroCardDescMobile>
            </IntroCard>
            <IntroCardRevered>
              <IntroCardTitleDescWrapper>
                <IntroCardTitlReversed>무료 검토 및 제안</IntroCardTitlReversed>
                <IntroCardDescReversed>
                  귀찮고 비용이 발생하던 기존 대면상담 대신
                  <br />
                  온라인으로 3분만에 무료 법률 검토 및 솔루션을
                  <br />
                  제안 받으세요
                </IntroCardDescReversed>
              </IntroCardTitleDescWrapper>
              <IntroCardImgWrapper
                style={{ backgroundColor: "#ffffff"}}
              >
                <IntroCardImg src={ImgServiceIntroduction2} />
              </IntroCardImgWrapper>
              <IntroCardDescMobile>
                귀찮고 비용이 발생하던 기존 대면상담 대신
                <br />
                온라인으로 3분만에 무료 법률 검토 및 솔루션을
                <br />
                제안 받으세요
              </IntroCardDescMobile>
            </IntroCardRevered>
            <IntroCard>
              <IntroCardTitleDescWrapper>
                <IntroCardTitle>
                  기존 법무사/변호사 비용 대비
                  <br />
                  평균 50% 저렴한 가격
                </IntroCardTitle>
                <IntroCardDesc>
                  법률 업무 간소화로 같은 품질의 법률 서비스에
                  <br />
                  가격은 더 저렴하게(대면/전화 상담 대신 온라인으로)
                </IntroCardDesc>
              </IntroCardTitleDescWrapper>
              <IntroCardImgWrapper style={{ backgroundColor: "#ffffff"}}>
                <IntroCardImg src={ImgServiceIntroduction3} />
              </IntroCardImgWrapper>
              <IntroCardDescMobile>
                  법률 업무 간소화로
                  <br />
                  같은 품질의 법률 서비스에 가격은 더 저렴하게
                  <br />
                  (대면/전화 상담 대신 온라인으로)
              </IntroCardDescMobile>
            </IntroCard>
            <IntroCardRevered>
              <IntroCardTitleDescWrapper>
                <IntroCardTitlReversed>
                  언제 어디서나 편리하게,
                  <br />
                  All-in-one 법률 관리 시스템
                </IntroCardTitlReversed>
                <IntroCardDescReversed>
                  법대리의 법률 솔루션을 통해 의뢰인들은
                  <br />
                  사건의 진행상황, 알림, 처리과정, 변호사 상담을
                  <br />한 번에 받아볼 수 있습니다.
                </IntroCardDescReversed>
              </IntroCardTitleDescWrapper>
              <IntroCardImgWrapper
                style={{ backgroundColor: "#ffffff"}}
              >
                <IntroCardImg src={ImgServiceIntroduction4} />
              </IntroCardImgWrapper>
              <IntroCardDescMobile>
                법대리의 법률 솔루션을 통해 의뢰인들은
                <br />
                사건의 진행상황, 알림, 처리과정, 변호사 상담을
                <br />
                한 번에 받아볼 수 있습니다.
              </IntroCardDescMobile>
            </IntroCardRevered>
          </IntroCardRow>
        </ContentContainer>
      </IntroSectionFourth>
      <section
        style={{
          width: "100%",
          height: "421px",
          background: "#000000",
          paddingTop: 60,
          paddingBottom: 60,
        }}
      >
        <BottomContainer>
          <IntroCenterTitle>
            지금 법대리에서
            <br />
            법률 서비스를 받아보세요
          </IntroCenterTitle>
          <Link to={"/"} >
            <ServiceBtnFreeEstimate>
              <img src={IcEstimate} style={{ marginRight: 8 }} />
              무료 법률 검토 신청
            </ServiceBtnFreeEstimate>
          </Link>
        </BottomContainer>
      </section>
      <Footer/>
    </Container>
  );
};

export default ServiceIntroduction;