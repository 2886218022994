import styled from "@emotion/styled";
import Slider from "react-slick";
const isMobile = window.matchMedia('(max-width: 768px)').matches;

export const LivingLawContentSection = styled.section`
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
`
export const LivingLawWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  @media screen and (max-width: 768px) {
    gap: 50px;
  }
`

export const LivingLawTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 768px) {
    gap: 16px;
  }
`

export const LivingLawTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`

export const LivingLawDesc = styled.div`
  color: #3D3D3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 147.059% */
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 23px; /* 147.059% */
  }
`

export const CarouselSlider = styled(Slider)`
  width: 674px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .slick-slide {
    padding: 0 2px;
  }
  .slick-track {
    display: flex;
}
`
export const LivingLawCardList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    gap: 0px;
  }
`

export const LivingLawCardRowWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: block;
    height: auto;
    margin: 0 !important;
  }
`

export const LivingLawCardListWrapper = styled.div`
  width: 100%;
  height: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const LivingLawCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 325px;
  width: 325px;
  height: 194px;
  align-items: flex-start;
  padding: 20px;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #D7D7D7;
  background: #FFF;
  cursor: pointer;
  margin-bottom: 0px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

export const LivingLawCardContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`

export const LivingLawCardContentDate = styled.div`
  color: #8F8F8F;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const LivingLawCardContentTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
`

export const LivingLawCardContentDetail = styled.div`
  color: #3A7EFC;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
`

export const LivingLawFooterBannerWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 6px;
  background: #3A7EFC;
  cursor: pointer;
`

export const LivingLawFooterBannerImg = styled.img`
  width: 100%;
  height: auto;
`

export const LivingLawHelpSection = styled.section`
  display: flex;
  width: 100%;
  padding: 63px 23px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const LivingLawHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`

export const LivingLawHelpTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const LivingLawHelpDesc = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 166.667% */
`

export const LivingLawHelpConsult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  padding: 16px 34px;
  margin: 0 auto;
  border-radius: 6px;
  background: #3A7EFC;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-top: 23px;
`;

export const LivingLawDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  @media screen and (max-width: 768px) {
    gap: 40px;
  }
`

export const LivingLawDetailContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  @media screen and (max-width: 768px) {
    gap: 40px;
  }
`

export const LivingLawDetailBackListWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    // gap: 40px;
  }
`

export const LivingLawDetailBackListButtonWrapper = styled.div`
display: flex;
padding: 10px 0px;
justify-content: center;
align-items: center;
gap: 6px;
`

export const LivingLawDetailBackListButtonImg = styled.img`
  width: 20px;
  height: 20px;
`

export const LivingLawDetailBackListButtonTitle = styled.div`
color: #000;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 128.571% */
letter-spacing: 0.203px;
`

export const LivingLawDetailContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
`

export const LivingLawDetailDateTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`

export const LivingLawDetailDate = styled.div`
  color: #8F8F8F;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const LivingLawDetailMainTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2em; /* 150% */
`

export const LivingLawDetailTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
`

export const LivingLawDetailContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`

export const LivingLawDetailContentDesc = styled.div`
  color: #121212;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
`

export const LivingLawDetailContentHeader = styled.h2`
  color: #121212;
  font-family: Pretendard;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  line-height: 1.2em;
  font-weight: 600;
  text-transform: none;
`
export const LivingLawDetailContentImg = styled.img`
  width: 100%;
  height: auto;
`

export const LivingLawDetailMoveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
`

export const LivingLawDetailMoveTopBorder = styled.div`
  width: 100%;
  height: 1px;
  background: #D9D9D9;
`

export const LivingLawDetailMoveContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const LivingLawDetailMove = styled.div`
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 34px;
  width: 100%;
  cursor: pointer;
  &:hover {
    border-radius: 4px;
    background: #EDEDED;
  }
`

export const LivingLawDetailMovePrevWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const LivingLawDetailMoveImg = styled.img`
  width: 20px;
  height: 20px;  
`

export const LivingLawDetailMoveHead = styled.div`
  color: #121212;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const LivingLawDetailMoveTitle = styled.div`
  color: #121212;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`