import { useHistory } from "react-router-dom";

import IcLoanServiceMain from "../../assets/svg/IcLoanServiceMain.svg";
import IcLoanServiceMainMobile from "../../assets/svg/IcLoanServiceMainMobile.svg";
import IcLoanServiceBody from "../../assets/svg/IcLoanServiceBody.svg";
import IcLoanServiceBodyMobile from "../../assets/svg/IcLoanServiceBodyMobile.svg";
import IcLoanServiceBody2 from "../../assets/svg/IcLoanServiceBody2.svg";
import IcLoanServiceBody2Mobile from "../../assets/svg/IcLoanServiceBody2Mobile.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg, 
  ServiceCenterFirstSection, 
  ServiceCenterFirstTitle, 
  ServiceCenterFooterBtn,
  ServiceCenterFooterContainer, 
  ServiceCenterFooterDesc, 
  ServiceCenterFooterSection, 
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle, 
  ServiceCenterSecondContainer, 
  ServiceCenterSecondDesc, 
  ServiceCenterSecondImg,
  ServiceCenterSecondSection, 
  ServiceCenterSecondSubTitle, 
  ServiceCenterSecondTextContainer, 
  ServiceCenterSecondTitle,
  ServiceCenterThirdContainer, 
  ServiceCenterThirdDesc, 
  ServiceCenterThirdDescSpan,
  ServiceCenterThirdImg, 
  ServiceCenterThirdImgMobile, 
  ServiceCenterThirdSection, 
  ServiceCenterThirdTextContainer, 
  ServiceCenterThirdTitle
} from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const LoanServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = isMobile
      ? "빌려준 돈 받기"
      : "빌려준 돈 돌려받기";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = isMobile
      ? "빌려준 돈,\n확실하게 받으려면?"
      : "빌려준 돈, 확실하게 받으려면?";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = isMobile
      ? "지급 명령을 통한 방법이\n가장 좋습니다."
      : "지급 명령을 통한 방법이 가장 좋습니다.";
    return removeBrTag(text);
  };

  const body2Title = () => {
    const text = isMobile
      ? "지급 명령의\n강력한 법적 효력"
      : "지급 명령의 강력한 법적 효력";
    return removeBrTag(text);
  };
  
  const footerTitle = () => {
    const text = isMobile
      ? "지급 명령이\n필요하신가요?"
      : "지급 명령이 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcLoanServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcLoanServiceMain}
            />
            <ServiceCenterFirstImg src={IcLoanServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
            {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              빌려준 돈, 매매대금, 용역대금, 양육비, 임금 등  받아야 하는 돈을 못 받고 있는
              상태라면, 지급 명령을 통해 가장 빠르고 확실하게 해결할 수 있습니다. 지급 명령
              은 일반 민사 소송의 10% 비용으로 동일한 법원 판결을 받을 수 있는 매우 효과적인 소송 방식입니다.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcLoanServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcLoanServiceBody}
            />
            <ServiceCenterSecondImg src={IcLoanServiceBody} />
          </picture>
        </ServiceCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterThirdSection>
        <ServiceCenterThirdContainer>
          <ServiceCenterThirdImg src={IcLoanServiceBody2} />
          <ServiceCenterThirdTextContainer>
            <ServiceCenterThirdTitle>
            {body2Title()}
            </ServiceCenterThirdTitle>
            <ServiceCenterThirdDesc>
              일반 민사 소송의 경우 최소 3-4개월 이상이 걸리지만, 지급 명령 절차의 경우
              <ServiceCenterThirdDescSpan> 2-4주면 법원 판결</ServiceCenterThirdDescSpan>이 내려지기 때문에 시간을 지체하지 않고
              빠르게 상대방에게 받아야 할 돈을 받아낼 수 있는 방법입니다.<br />
              법원에서 지급명령 확정 판결이 내려지면 해당 판결문을 통해 채무자의 재산을 강제 집행 하는 등 빠르게 돈을 돌려 받을 수 있는
              <ServiceCenterThirdDescSpan> 간단하지만 매우 강력한</ServiceCenterThirdDescSpan> 소송 방법입니다.
            </ServiceCenterThirdDesc>
          </ServiceCenterThirdTextContainer>
          <ServiceCenterThirdImgMobile src={IcLoanServiceBody2Mobile} />
        </ServiceCenterThirdContainer>
      </ServiceCenterThirdSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
            {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              법대리 지급 명령 서비스는 의뢰인들의 각 상황에 맞는 각 분야 전문 변호사를 매칭해드리고 있으며,
              가장 확실하게  받지 못하는 돈을 받을 수 있게 해드리고 있습니다.
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 신청서로 제출 해주시면 각 분야별로 가장 경험이 많고
              실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtn onClick={() => history.push("/apply/loanService")}>
            지급 명령 보내기
          </ServiceCenterFooterBtn>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default LoanServiceCenter;
