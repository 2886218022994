import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Step2 from "./Step2";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

const { useState } = require("react");
const { useHistory } = require("react-router-dom");
const { default: Step1 } = require("./Step1");

const Certification = ({ loggedInUser }) => {
  const [page, setPage] = useState(0);
  const history = useHistory();
  const userInfo = useRecoilValue(userData);

  const itemLayout = {
    form: {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    },
    button: {
      wrapperCol: { span: 24, offset: 0 },
    },
  };

  const [step1Data, setStep1Data] = useState({
    noise: {
      date: null,
      information: {
        address1: null,
        address2: null,
        noiseType: null,
      },
      compensation: {
        exist: "n",
        reason: null,
        amount: null,
        date: null,
        isAccount: "n",
        name: null,
        bank: null,
        accountNumber: null,
      },
    },
  });

  const [step2Data, setStep2Data] = useState({
    user: {
      information: {
        sender: null,
        senderAddress1: null,
        senderAddress2: null,
        senderPhoneNumber: null,
        receiver: "n",
        receiverAddress1: null,
        receiverAddress2: null,
        receiverPhoneNumber: "n",
      },
    },
    description: null,
    contact: null,
    promotionCode: null,
  });

  const onPrev = () => {
    setPage(page - 1);
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const onSubmit = async () => {
    const formattedStep1Data = {
      noise: {
        ...step1Data.noise,
        date: step1Data.noise.date
          ? moment(step1Data.noise.date).format("YYYY-MM-DD")
          : null,
        compensation: {
          ...step1Data.noise.compensation,
          date: step1Data.noise.compensation.date
            ? moment(step1Data.noise.date).format("YYYY-MM-DD")
            : null,
        },
      },
    };

    const propertyDepositData = {
      ...formattedStep1Data,
      ...step2Data,
      userId: loggedInUser.uid,
    };

    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "noise_certification",
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: propertyDepositData,
        userId: loggedInUser.uid,
        clientName: userInfo.name,
        status: "submitted",
      });
      history.push("/submit/success");
    } catch (err) {
      history.push("/submit/fail");
    }
  };

  const steps = [
    {
      title: "층간 소음 관련 정보",
      content: (
        <Step1
          data={step1Data}
          setData={setStep1Data}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "층간 소음 관련 정보",
      content: (
        <Step2
          data={step2Data}
          setData={setStep2Data}
          onPrev={onPrev}
          onNext={onSubmit}
          itemLayout={itemLayout}
        />
      ),
    },
  ];

  return (
    <>
      <div className="steps-content">{steps[page].content}</div>
    </>
  );
};

export default Certification;
