import { useHistory } from "react-router-dom";

import IcOpinionServiceMain from "../../assets/svg/IcOpinionServiceMain.svg";
import IcOpinionServiceMainMobile from "../../assets/svg/IcOpinionServiceMainMobile.svg";
import IcOpinionServiceBody from "../../assets/svg/IcOpinionServiceBody.svg";
import IcOpinionServiceBodyMobile from "../../assets/svg/IcOpinionServiceBodyMobile.svg";
import IcOpinionServiceBody2 from "../../assets/svg/IcOpinionServiceBody2.svg";
import IcOpinionServiceBody2Mobile from "../../assets/svg/IcOpinionServiceBody2Mobile.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
  ServiceCenterFooterBtn,
  ServiceCenterFooterContainer,
  ServiceCenterFooterDesc,
  ServiceCenterFooterSection,
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle,
  ServiceCenterSecondContainer,
  ServiceCenterSecondDesc,
  ServiceCenterSecondImg,
  ServiceCenterSecondSection,
  ServiceCenterSecondSubTitle,
  ServiceCenterSecondTextContainer,
  ServiceCenterSecondTitle,
  ServiceCenterThirdContainer,
  ServiceCenterThirdDesc,
  ServiceCenterThirdDescSpan,
  ServiceCenterThirdImg,
  ServiceCenterThirdImgMobile,
  ServiceCenterThirdSection,
  ServiceCenterThirdTextContainer,
  ServiceCenterThirdTitle
} from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const OpinionServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = "의견서 작성하기";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = isMobile
      ? "의견서를 잘\n작성하려면?"
      : "의견서를 잘 작성하려면?";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = "전문가에게 맡기는 것이 올바른 선택";
    return removeBrTag(text);
  };

  const body2Title = () => {
    const text = isMobile
      ? "법대리 의견서 작성\n서비스 이용하기"
      : "법대리 의견서 작성 서비스 이용하기";
    return removeBrTag(text);
  };

  const footerTitle = () => {
    const text = isMobile
      ? "의견서 작성이\n필요하신가요?"
      : "의견서 작성이 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcOpinionServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcOpinionServiceMain}
            />
            <ServiceCenterFirstImg src={IcOpinionServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
              {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              형사 사건에서 상대방의 주장을 반박하거나, 음주운전, 마약, 폭행 사건처럼 감형을 받아야 하는 상황에서 필수적으로 제출해야 하는 것이 변호인 의견서입니다.
              변호인 의견서에는 본인이 주장하는 법률적 의견과 법적으로 감형 사유가 되는 내용들이 체계적으로 들어가야 하는데,
              법적 지식이 없는 일반인이 직접 유의미한 의견서를 쓰는 것은 불가능한 일입니다.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcOpinionServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcOpinionServiceBody}
            />
            <ServiceCenterSecondImg src={IcOpinionServiceBody} />
          </picture>
        </ServiceCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterThirdSection>
        <ServiceCenterThirdContainer>
          <ServiceCenterThirdImg src={IcOpinionServiceBody2} />
          <ServiceCenterThirdTextContainer>
            <ServiceCenterThirdTitle>
              {body2Title()}
            </ServiceCenterThirdTitle>
            <ServiceCenterThirdDesc>
              법대리의 전문 변호사 의견서 작성 서비스는 기존 큰 비용이 드는 변호사 선임의 부담을 없애고,
              가장 저렴한 가격에 각 분야별 전문 변호사가 직접 쓴 양질의 변호사 의견서를 받아볼 수 있는 서비스를 제공하고 있습니다.
            </ServiceCenterThirdDesc>
          </ServiceCenterThirdTextContainer>
          <ServiceCenterThirdImgMobile src={IcOpinionServiceBody2Mobile} />
        </ServiceCenterThirdContainer>
      </ServiceCenterThirdSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
              {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면
              분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtn onClick={() => history.push("/apply/opinionService")}>
            의견서 작성하기
          </ServiceCenterFooterBtn>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default OpinionServiceCenter;
