import { useEffect, useState } from "react";
import IcMain from "../../../../assets/svg/IcOpinionServiceMain.svg";
import IcMainMobile from "../../../../assets/svg/IcOpinionServiceMainMobile.svg";


import { useRecoilValue } from "recoil";
import { userData, redirectEtcUri } from "../../../../states/atoms";

import {
  Container,
} from "../../../Service.style";
import {
  ApplyServiceDescContainer,
  ApplyServiceDescSection,
  ApplyServiceDescText,
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
} from "../../../home/ServiceCenter.style";

import { RadioGroup, Radio } from "../../../home/CommonCriminal/RadioBox";
import { CheckboxGroup, Checkbox } from "../../../home/CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { ApplyServiceInputText, ApplyServiceSubmitDataContainer, ApplyServiceSubmitDataInfoContainer, ApplyServiceSubmitDataInfoDetailContainer, ApplyServiceSubmitDataInfoDetailTitle, ApplyServiceSubmitDataSection } from "./ApplyService.style";
import { EventFormEachDesc, EventInputTextArea, EventSubmitFooterSection, EventSubmitFooterText, EventSubmitFooterTextContainer } from "components/event/3o3/order/EventSubmit.style";

import { useRecoilState } from 'recoil';

import {
  Input,
} from "antd";

import { removeBrTag } from "routes/Landing";
import { ApplyServiceSubmit } from "../ApplyServiceSubmit";

const { useHistory } = require("react-router-dom");

const ApplyOpinionService = ({ loggedInUser }) => {
  // 의견서 작성이 필요한 분야
  const [field, setField] = useState("");
  const [etcField, setEtcField] = useState("");

  function handleChange(checkedValues) {
    setField(checkedValues);
  }

  const handleEtcFieldChange = (e) => {
    const inputValue = e.target.value;
    setEtcField(inputValue)
  }

  const fieldList = [
    "음주 운전 변호사 의견서", "마약 사건 변호사  의견서", "폭행 사건 변호사  의견서",
    "성범죄, 청소년 음란물 사건 변호사 의견서", "형사 사건 피의자 답변서",
    "민사 사건 피의자 답변서", "그 외"
  ]

  // 의뢰인이 원하는 결과
  const [desiredResult, setDesiredResult] = useState("");
  const [etcDesiredResult, setEtcDesiredResult] = useState("");

  const handleEtcDesiredResultChange = (e) => {
    const inputValue = e.target.value;
    setEtcDesiredResult(inputValue)
  }

  // 현재 사건 진행상황
  const [progressStatus, setProgressStatus] = useState("");
  const [etcProgressStatus, setEtcProgressStatus] = useState("");

  const handleEtcProgressStatusChange = (e) => {
    const inputValue = e.target.value;
    setEtcProgressStatus(inputValue);
  }

  // 현재 주장하는 입장
  const [claimPosition, setClaimPosition] = useState("");
  const [etcClaimPosition, setEtcClaimPosition] = useState("");

  const handleEtcClaimPositionChange = (e) => {
    const inputValue = e.target.value;
    setEtcClaimPosition(inputValue);
  }

  // 추가적인 도움이 필요
  const [needMoreHelp, setNeedMoreHelp] = useState("");
  const [etcNeedMoreHelp, setEtcNeedMoreHelp] = useState("");

  const handleEtcNeedMoreHelpChange = (e) => {
    const inputValue = e.target.value;
    setEtcNeedMoreHelp(inputValue);
  }


  const footerText = () => {
    const text = isSubmitReady()
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄져요. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "모든 항목에 답변해 주세요.\n답변이 완료되면 제출할 수 있어요.";
    return removeBrTag(text);
  }

  const serviceData = () => {
    const serviceData = {
      // 전화번호
      contact: "",
      // 첨부파일
      attachments: [],
      field: field,
      etcField: field === "그 외" ? etcField : null,
      desiredResult: desiredResult,
      etcDesiredResult: desiredResult.includes("그 외") ? etcDesiredResult : "",
      progressStatus: progressStatus,
      etcProgressStatus: progressStatus.includes("그 외") ? etcProgressStatus : "",
      claimPosition: claimPosition,
      etcClaimPosition: claimPosition.includes("그 외") ? etcClaimPosition : "",
      needMoreHelp: needMoreHelp === "네" ? "y" : "n",
      etcNeedMoreHelp: needMoreHelp.includes("네") ? etcNeedMoreHelp : "",
      userId: loggedInUser.uid
    };
    return serviceData;
  }

  const isSubmitReady = () => {
    if (field !== "" && desiredResult !== "" && progressStatus !== ""
      && claimPosition !== "" && needMoreHelp !== "") {
      return true
    }
    return false
  }

  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  const title = () => {
    const text = isMobile
      ? "의견서 작성하기"
      : "의견서 작성하기";
    return removeBrTag(text);
  };

  const applyServiceDescTitle = () => {
    const text = isMobile
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄집니다. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "신청서 제출 후, 전문 변호사의 검토가 이뤄집니다.\n카카오 알림톡으로 검토 결과를 알려드릴게요.";
    return removeBrTag(text);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (checkedValues) => {
    console.log(checkedValues)
  };

  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcMain}
            />
            <ServiceCenterFirstImg src={IcMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ApplyServiceSubmitDataSection>
        <ApplyServiceSubmitDataContainer>
          <ApplyServiceSubmitDataInfoContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>의견서 작성이 필요한 분야를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup label="" value={field} style={{ width: "100%" }} onChange={handleChange}>
                {fieldList.map((value) =>
                  <Radio value={value} >{value}</Radio>
                )}
                {field.includes("그 외") && <>
                  <ApplyServiceInputText style={{ width: "100%" }} value={etcField} onChange={handleEtcFieldChange} placeholder="내용을 입력해주세요." />
                </>}
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                원하시는 결과를 선택해 주세요.
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={desiredResult}
                onChange={setDesiredResult}
              >
                <Radio value="무죄,무혐의">무죄,무혐의</Radio>
                <Radio value="기소유예">기소유예</Radio>
                <Radio value="실형 회피">실형 회피</Radio>
                <Radio value="형량 최소화">형량 최소화</Radio>
                <Radio value="그 외">그 외</Radio>
                {desiredResult.includes("그 외") && <>
                  <ApplyServiceInputText value={etcDesiredResult} onChange={handleEtcDesiredResultChange} placeholder="내용을 입력해주세요." />
                </>}
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                현재 사건 진행상황을 선택해 주세요.
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={progressStatus}
                onChange={setProgressStatus}
              >
                <Radio value="고소 또는 수사 전">고소 또는 수사 전</Radio>
                <Radio value="경찰 또는 검찰 조사중">경찰 또는 검찰 조사중</Radio>
                <Radio value="조사 후 재판 진행중">조사 후 재판 진행중</Radio>
                <Radio value="해당 없음">해당 없음</Radio>
                <Radio value="그 외">그 외</Radio>
                {progressStatus.includes("그 외") && <>
                  <ApplyServiceInputText value={etcProgressStatus} onChange={handleEtcProgressStatusChange} placeholder="내용을 입력해주세요." />
                </>}
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                현재 주장하시는 입장을 선택해 주세요.
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={claimPosition}
                onChange={setClaimPosition}
              >
                <Radio value="무죄 주장(사실과 다른 부분이 있음)">무죄 주장(사실과 다른 부분이 있음)</Radio>
                <Radio value="자백(사실 모두 인정함)">자백(사실 모두 인정함)</Radio>
                <Radio value="그 외">그 외</Radio>
                {claimPosition.includes("그 외") && <>
                  <ApplyServiceInputText value={etcClaimPosition} onChange={handleEtcClaimPositionChange} placeholder="내용을 입력해주세요." />
                </>}
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                의견서 작성 이후, 추가적인 도움이 필요하신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>경찰 조사 동행, 변호사 선임, 합의 대행 등</EventFormEachDesc>
              <RadioGroup
                label=""
                value={needMoreHelp}
                onChange={setNeedMoreHelp}
              >
                <Radio value="네">네</Radio>
                {needMoreHelp.includes("네") && <>
                  <ApplyServiceInputText value={etcNeedMoreHelp} onChange={handleEtcNeedMoreHelpChange} placeholder="내용을 입력해주세요." />
                </>}
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
          </ApplyServiceSubmitDataInfoContainer>
        </ApplyServiceSubmitDataContainer>
      </ApplyServiceSubmitDataSection>
      <EventSubmitFooterSection active={isSubmitReady()}>
        <EventSubmitFooterTextContainer>
          <EventSubmitFooterText active={isSubmitReady()}>
            {footerText()}
          </EventSubmitFooterText>
        </EventSubmitFooterTextContainer>
      </EventSubmitFooterSection>
      <ApplyServiceSubmit
        active={isSubmitReady()}
        serviceData={serviceData()}
        serviceType={"opinion"}
        uid={loggedInUser.uid}
      />
    </Container>
  )
};

export default ApplyOpinionService;
