import React, { useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import backButtonBlack from "../../../assets/img/backButtonBlack.png";
import downLoadButton from "../../../assets/img/downLoadButton.png";
import { 
    ModalContainer,
    ModalContentContainer,
    ModalHeader,
    ModalHeaderBack,
    ModalHeaderTitle,
    PreviewSlider,
    SubMittedContentDivider,
    DownModalHeaderBackWrapper,
    ModalHeaderDownloadImg,
    ModalHeaderDownloadWrapper,
    ModalHeaderDownloadTitle,
 } from "./CaseDataNotSubmitPreview.style";

import { 
  DocxPreviewContainer,
  DocxPreviewWrapper,
  DocxPreviewDownloadWrapper,
  DocxPreviewDownloadImg,
  DocxPreviewDownloadTitle,
  DocxPreviewDesc,
} from "../CaseDetailComp.style";
import { useRecoilState } from "recoil";
import { currentClientSubmitPreviewState } from "states/atoms";


function CaseDataSubmitPreview({ onClickCloseButton }) { 
    const [submitData, setSubmitData] = useRecoilState(currentClientSubmitPreviewState);
    const [dataList, setDataList] = useState(submitData.docDataList);
    const [idx, setIdx] = useState(submitData.currentIdx);
  
    const handleGoBack = () => {
      onClickCloseButton();
    };

    const renderFile = (fileObj, index) => {
      const url = fileObj.url;      
      const name = fileObj.name;
      return <DocxPreviewContainer>
              <DocxPreviewContainer>
                <DocxPreviewWrapper>
                <DocxPreviewDownloadWrapper onClick={() => handleDownloadFile()}>
                  <DocxPreviewDownloadTitle>
                    다운로드
                  </DocxPreviewDownloadTitle>
                  <DocxPreviewDownloadImg src={downLoadButton}/>
                </DocxPreviewDownloadWrapper>
                <DocxPreviewDesc>
                해당 파일은 다운로드하여<br/>확인하시길 바랍니다.
                </DocxPreviewDesc>
                </DocxPreviewWrapper>
              </DocxPreviewContainer>
            </DocxPreviewContainer>
    };

    const handleDownloadFile = async () => {
      try {
        const fileObj = dataList[idx - 1];
        const response = await fetch(fileObj.url);
        const blob = await response.blob();
        const filename = fileObj.name; // 저장할 파일명
  
        // 파일 다운로드
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE 및 Microsoft Edge의 경우
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          link.click();
        }
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };
  
    const settings = {
      speed: 500,
      slidesToShow: 1,
      infnite: true,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (oldIndex, newIndex) => {
        switch (newIndex) {
          default:
            setIdx(newIndex + 1);
            break;
        }
      },
      initialSlide: idx - 1,
    };

    if (!dataList) return null;

    return (
      <>
        <ModalContainer>
          <ModalHeader style={{backgroundColor: "#fff"}}>
          <DownModalHeaderBackWrapper onClick={handleGoBack}>
            <ModalHeaderBack src={backButtonBlack}/>
          </DownModalHeaderBackWrapper>     
          <ModalHeaderTitle>
            {submitData.contentList[idx - 1].content}
          </ModalHeaderTitle>
          <ModalHeaderDownloadWrapper onClick={handleDownloadFile}>
            <ModalHeaderDownloadTitle>
              다운로드
            </ModalHeaderDownloadTitle>
            <ModalHeaderDownloadImg src={downLoadButton}/>
          </ModalHeaderDownloadWrapper>
          </ModalHeader>
          <SubMittedContentDivider />
          <ModalContentContainer style={{height: "796px"}}>
            <PreviewSlider {...settings}>
                {dataList.map((file, index) => renderFile(file, index))}
            </PreviewSlider>
          </ModalContentContainer>
        </ModalContainer>
      </>
    );
  }
  
  export default CaseDataSubmitPreview;