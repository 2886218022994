import { QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Row,
  Col,
  Upload,
  Modal,
  Divider,
  Input,
  message,
  Radio,
  Popover,
} from "antd";
import { storageService } from "fbase";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import TermsOfService from "components/form/commons/TermsOfService";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper,
} from "../../Form.style";
const { TextArea } = Input;

const Step3 = ({ data, setData, onPrev, onNext, itemLayout }) => {
  const [fileList, setFileList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewTitle, setPreviewTitle] = useState(false);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const key = "updatable";

  useEffect(() => {
    if (data.attachments) {
      message.success({
        content: "제출 완료!",
        key,
        duration: 2,
      });
      onNext();
    }
  }, [data]);

  const onFinish = async (values) => {
    setSubmitting(true);
    message.loading({
      content: "제출 중입니다.",
      key,
      duration: 0,
    });

    let attachments = [];
    await Promise.all(
      fileList.map(async (file) => {
        const fileRef = storageService
          .ref()
          .child(`lend_money/images/${uuidv4()}`);
        const response = await fileRef.put(file.originFileObj);
        const imgUrl = await response.ref.getDownloadURL();
        attachments.push(imgUrl);
      })
    ).then(() => {
      setData({ attachments: attachments, ...values });
    });
  };

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onChange = ({ fileList }) => {
    setFileList(fileList.filter((file) => file.status !== "error"));
  };

  const onRemove = async (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };

  const onCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        증거 자료 및 상세 내용
      </Title>
      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          상대방의 주소 또는 주민등록번호 두가지 중 <br />
          한가지는 알고있어야 지급명령 신청이 가능합니다.
          <br />
          두가지 다 모르는 경우, 민사소송 진행을 통해 <br /> 상대방의 정보를
          확인할 수 있습니다. <br />
          민사소송의 경우 챗봇을 통해 문의부탁드립니다.
        </SubText>
      </div>
      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper style={{height:`90%`}}>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          style={{
            marginTop: 10,
            marginBottom: 20,
            width:"100%",
            textAlign:"left"
          }}
          requiredMark={false}
        >
          <Form.Item
            label="빌려준 사람(채권자) 정보"
            name={["lender", "type"]}
            rules={[{ required: true, message: "채권자 정보를 선택해주세요." }]}
          >
            <Radio.Group>
              <Radio.Button value="indv">개인/개인사업자</Radio.Button>
              <Radio.Button value="corp">법인</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="빌린 사람(채무자) 정보"
            name={["loaner", "type"]}
            rules={[{ required: true, message: "채무자 정보를 선택해주세요." }]}
          >
            <Radio.Group>
              <Radio.Button value="indv">개인/개인사업자</Radio.Button>
              <Radio.Button value="corp">법인</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="상대방의 주소를 알고 있나요?"
            name={["loaner", "addressYn"]}
            rules={[{ required: true, message: "주소 유무를 선택해주세요." }]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="상대방의 주민등록번호를 알고 있나요?"
            name={["loaner", "ssnYn"]}
            rules={[
              { required: true, message: "주민등록번호 유무를 선택해주세요" },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                연체 이자 청구
                <Popover
                  content={
                    <div>
                      연체이자를 청구할 경우 지급명령 결정이 된 이후부터
                      청구금액에 연 12%의 지연이자가 붙습니다. <br />
                      (지급명령 결정 이전의 이자와 별도)
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["overDueInterests", "requestYn"]}
            rules={[
              { required: true, message: "연체이자 청구 유무를 선택해주세요." },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                상세내용
                <Popover
                  content={
                    <div>
                      기타 입력하신 내용 외 특이사항이 있을 경우 작성해 주시면
                      변호사가 검토시 참고할 예정입니다.
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={"description"}
          >
            <TextArea rows={10} />
          </Form.Item>
          <Form.Item
            label="연락처"
            name={["contact"]}
            rules={[
              {
                required: true,
                message: "연락처를 입력해주세요",
              },
            ]}
          >
            <Input placeholder="검토 결과 알림을 받으실 연락처를 입력해주세요" />
          </Form.Item>
          <Form.Item label="프로모션 코드" name={["promotionCode"]}>
            <Input />
          </Form.Item>

          <div>
            <label>
              <>
                첨부파일
                <Popover
                  content={
                    <div>
                      상대방에게 돈을 빌려줬음을 증명할 수 있는 자료
                      <br /> (송금내역, 카카오톡 대화)를 전부 첨부하세요
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            </label>
            <div style={{ marginTop: 5, marginBottom: 15 }}>
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                onPreview={onPreview}
                onChange={onChange}
                onRemove={onRemove}
                fileList={fileList}
                maxCount={10}
              >
                <Button icon={<UploadOutlined />}>증거자료 첨부</Button>
              </Upload>
            </div>
          </div>
          <TermsOfService />
          <Row justify="space-between" style={{ marginTop: 20 }}>
            <Col span={6}>
              <Button
                type="primary"
                onClick={onPrev}
                style={{ width: "100%", maxWidth: "100px" }}
              >
                이전
              </Button>
            </Col>
            <Col span={6} offset={10} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                {submitting ? "제출 중" : "제출"}
              </Button>
            </Col>
          </Row>
        </Form>
        </CaseDetailWrapper>

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={onCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step3;
