import { Descriptions, Divider } from "antd";
 import { Typography } from "antd";
 import { labelStyle } from "../RequestUtil";

 const { Title } = Typography;

 const CommonCriminalRepresentRequest = ({ serviceData, requestData }) => {
   const reactiveColumn = {
     xxl: 1,
     xl: 1,
     lg: 1,
     md: 1,
     sm: 1,
     xs: 1,
   };
   
   return (
     <>
       <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
       형사 사건 고소대리
       </Title>
       <Divider style={{ marginTop: 10 }} />
       <div
         style={{
           backgroundColor: "white",
           padding: 15,
           paddingTop: 5,
           marginBottom: 20,
           borderRadius: 5,
         }}
       >
         <Descriptions
           title="형사 사건 고소대리 관련 정보"
           bordered
           column={reactiveColumn}
           size={"small"}
           style={{ marginTop: 20, marginBottom: 15 }}
         >

           <Descriptions.Item
             label="사건명"
             labelStyle={labelStyle}
           >
             {serviceData.criminalCaseType}
           </Descriptions.Item>

           <Descriptions.Item
             label="상세 주소"
             labelStyle={labelStyle}
           >
             {serviceData.address}
           </Descriptions.Item>
         </Descriptions>

         <Descriptions
           title="상세 내용 및 연락처"
           bordered
           column={reactiveColumn}
           size={"small"}
           style={{ marginTop: 20, marginBottom: 15 }}
         >           
           <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
             {serviceData.desc
               ? serviceData.desc.split("\n").map((line, idx) => {
                   return (
                     <span key={idx}>
                       {line}
                       <br />
                     </span>
                   );
                 })
               : ""}
           </Descriptions.Item>
           <Descriptions.Item label="연락처" labelStyle={labelStyle}>
             {serviceData.contact}
           </Descriptions.Item>
           <Descriptions.Item label="프로모션" labelStyle={labelStyle}>
             {serviceData.promotionCode}
           </Descriptions.Item>
         </Descriptions>
       </div>
     </>
   );
 };

 export default CommonCriminalRepresentRequest;