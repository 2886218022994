import Collapse from "components/common/Collapse";
import { SERVICE_QNA } from "../../service-constants";
import IcHeadset from "../../assets/svg/IcHeadset.svg";
import ImgServiceEstateStep1 from "../../assets/svg/ImgServiceEstateStep1.svg";
import ImgServiceEstateStep2 from "../../assets/svg/ImgServiceEstateStep2.svg";
import ImgServiceEstateStep3 from "../../assets/svg/ImgServiceEstateStep3.svg";
import ImgServiceEstateStep4 from "../../assets/svg/ImgServiceEstateStep4.svg";
import ImgServiceEstateStep5 from "../../assets/svg/ImgServiceEstateStep5.svg";
import ImgServiceEstate from "../../assets/svg/ImgServiceEstate.svg";
import {
  BtnConsult,
  CollapseWrapper,
  Container,
  ContentContainer,
  DetailImg,
  DetailRightBtn,
  DetailRightDesc,
  DetailRightTitle,
  DetailRightWrapper,
  DetailWrapper,
  EstateServiceCenterSection,
  SubTitle,
  Title,
  SectionTop,
  TopDesc,
  TopTitle,
  TopWrapper,
  TopImg,
  DetailRightLabel,
  DetailRightLabelMobile,
  DetailRightTitleMobile,
} from "../Service.style";
import { SerivceCenterConsultingSection } from "./ServiceCenter.style";
import useMediaQuery from "hooks/useMediaQuery";
import Footer from "components/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import ChannelService from "../ChannelService";

const EstateServiceCenter = ({loggedInUser}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <SectionTop>
        <ContentContainer style={{ width: "768px" }}>
          <TopWrapper>
            <TopTitle>전세 계약</TopTitle>
            <TopDesc>
            전세 사건은 법대리가 가장 자신 있는 분야이며, 최상의 고객 만족도를 자랑합니다.
            <br />
            수많은 전세금 반환 문제를 해결한 전문 변호사에게 무료로 검토 받아 보세요.              
            </TopDesc>
          </TopWrapper>
          <TopImg src={ImgServiceEstate} />
        </ContentContainer>
      </SectionTop>
      <EstateServiceCenterSection>
        <ContentContainer>
          {/* step1 */}
          <DetailWrapper>
            <DetailRightLabelMobile>STEP 1</DetailRightLabelMobile>
            <DetailRightTitleMobile>전세금 반환 내용증명 보내기</DetailRightTitleMobile>
            <DetailImg src={ImgServiceEstateStep1} />
            <DetailRightWrapper>
              <DetailRightLabel>STEP 1</DetailRightLabel>
              <DetailRightTitle>전세금 반환 내용증명 보내기</DetailRightTitle>
              <DetailRightDesc>
                계약 종료 전 임대인의 보증금 반환 의사가 불분명한 경우,
                <br />
                변호사가 직접 작성한 내용증명을 통해 빈틈없이 조치를 취하세요.
                <br />
                내용증명은 집주인을 압박하여 전세금을 원만하게 돌려받을 수 있는
                <br />
                가장 간단한 방법입니다.
              </DetailRightDesc>
              <Link to="/housing/certification">
                <DetailRightBtn>
                  신청하기
                </DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          {/* step2 */}
          <DetailWrapper>
            <DetailRightLabelMobile>STEP 2</DetailRightLabelMobile>
            <DetailRightTitleMobile>내용증명 공시송달</DetailRightTitleMobile>
            <DetailImg src={ImgServiceEstateStep2} />
            <DetailRightWrapper>
              <DetailRightLabel>STEP 2</DetailRightLabel>
              <DetailRightTitle>내용증명 공시송달</DetailRightTitle>
              <DetailRightDesc>
              계약 종료 전 임대인에게 계약해지 의사 전달이 되지 않을 경우
              <br />
              변호사가 직접 신청하는 공시송달을 통해
              <br />
              계약해지 의사 표시를 진행하세요.                
              </DetailRightDesc>
              <Link to="/housing/public_conveyance">
                <DetailRightBtn>
                  신청하기
                </DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          {/* step3 */}
          <DetailWrapper>
            <DetailRightLabelMobile>STEP 3</DetailRightLabelMobile>
            <DetailRightTitleMobile>임차권등기명령 신청하기</DetailRightTitleMobile>
            <DetailImg src={ImgServiceEstateStep3} />
            <DetailRightWrapper>
              <DetailRightLabel>STEP 3</DetailRightLabel>
              <DetailRightTitle>
                임차권등기명령 신청하기
              </DetailRightTitle>
              <DetailRightDesc>
              전세금을 반환받지 못했으나 다른 곳으로 이사를 가야할 때,
              <br />
              변호사를 통해 임차권 등기명령을 신청하여
              <br />
              대항력과 우선변제권을 유지하세요.                
              </DetailRightDesc>
              <Link to="/housing/protection">
                <DetailRightBtn>
                신청하기
                </DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          {/* step4 */}
          <DetailWrapper>
            <DetailRightLabelMobile>STEP 4</DetailRightLabelMobile>
            <DetailRightTitleMobile>부동산 가압류</DetailRightTitleMobile>
            <DetailImg src={ImgServiceEstateStep4} />
            <DetailRightWrapper>
              <DetailRightLabel>STEP 4</DetailRightLabel>
              <DetailRightTitle>부동산 가압류</DetailRightTitle>
              <DetailRightDesc>
                계약 기간 종료 이후에도 전세금을 반환받지 못했으나
                <br />
                다른 곳으로 이사를 가야할 때, 변호사를 통해
                <br />
                부동산 가압류를 신청하여 임대인을 압박하세요
              </DetailRightDesc>
              <Link to="/housing/seizure">
                <DetailRightBtn>
                  신청하기
                </DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          {/* step5 */}
          <DetailWrapper>
            <DetailRightLabelMobile>STEP 5</DetailRightLabelMobile>
            <DetailRightTitleMobile>전세금 반환 지급명령</DetailRightTitleMobile>
            <DetailImg src={ImgServiceEstateStep5} />
            <DetailRightWrapper>
              <DetailRightLabel>STEP 5</DetailRightLabel>
              <DetailRightTitle>전세금 반환 지급명령</DetailRightTitle>
              <DetailRightDesc>
                계약 기간 종료 이후에도 전세금 안 돌려주는 임대인에게
                <br />
                변호사가 직접 작성한 지급명령 소송서를 통해 법적으로 해결하세요.
                <br />
                모든 소송 관련 비용은 임대인에게 청구할 수 있습니다.
              </DetailRightDesc>
              <Link to="/housing/order">
                <DetailRightBtn>
                신청하기
                </DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          <CollapseWrapper>
            {SERVICE_QNA.estate.map(({ title, desc }, idx) => (
              <Collapse
                key={`estate-service$-${idx}`}
                title={title}
                desc={desc}
              />
            ))}
          </CollapseWrapper>
        </ContentContainer>
      </EstateServiceCenterSection>
      <SerivceCenterConsultingSection>
        <ContentContainer>
          <Title style={{ marginBottom: 10, marginTop: 0 }}>
            기타 부동산 사건 상담
          </Title>
          <SubTitle style={{ marginBottom: 0 }}>
            복잡한 법률 용어, 지식은 모르셔도 됩니다.
            <br />
            법대리가 알려드리는 항목들만 작성 및 첨부해 주시면 법대리의 전문
            변호사단이 검토 후 의견을 드립니다.
          </SubTitle>
          <BtnConsult onClick={()=> {
            //  LandingToChannelTalk();
            if(!loggedInUser){
              ChannelService.loadScript();
              ChannelService.boot({
                "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
              })
            }
            ChannelService.showMessenger();
          }}>
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </BtnConsult>
        </ContentContainer>
      </SerivceCenterConsultingSection>
      <Footer/>
    </Container>
  );
};

export default EstateServiceCenter;
