import Step1 from "./Step1";
import Step2 from "./Step2";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "fbase";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import moment from "moment";
import Step3 from "./Step3";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

const Insult = ({ loggedInUser }) => {
  const [page, setPage] = useState(0);
  const history = useHistory();
  const key = "updatable";
  const userInfo = useRecoilValue(userData);

  const step1DefaultValues = {
    accident: {
      date: null,
      time: null,
      place: null,
    },
  };

  const step2DefaultValues = {
    offender: {
      info: null,
      insultCount: null,
      insult: null,
    },
  };

  const step3DefaultValues = {
    thirdparty: {
      exist: "y",
      name: null,
    },
    evidence: {
      exist: null,
    },
  };

  const itemLayout = {
    form: {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 16,
      },
    },
    button: {
      wrapperCol: { span: 24, offset: 0 },
    },
  };

  const [step1Data, setStep1Data] = useState(step1DefaultValues);
  const [step2Data, setStep2Data] = useState(step2DefaultValues);
  const [step3Data, setStep3Data] = useState(step3DefaultValues);

  const [dbUpdate, setDbUpdate] = useState(false);

  const onPrev = () => {
    setPage(page - 1);
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const onSubmit = async () => {
    const parsedStep1Data = {
      ...step1Data,
      accident: {
        ...step1Data.accident,
        date: step1Data.accident.date
          ? moment(step1Data.accident.date).format("YYYY-MM-DD")
          : null,
      },
    };
    const plaintInsultData = {
      ...parsedStep1Data,
      ...step2Data,
      ...step3Data,
      userId: loggedInUser.uid,
    };

    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "plaint_insult",
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: plaintInsultData,
        userId: loggedInUser.uid,
        clientName: userInfo.name,
        status: "submitted",
      });
      history.push("/submit/success");
      message.success({
        content: "제출 완료!",
        key,
        duration: 2,
      });
    } catch (err) {
      history.push("/submit/fail");
      message.error({
        content: "제출 실패!",
        key,
        duration: 2,
      });
    }
  };

  useEffect(() => {
    if (dbUpdate) {
      onSubmit();
    }
  }, [dbUpdate]);

  const steps = [
    {
      title: "음주운전",
      content: (
        <Step1
          data={step1Data}
          setData={setStep1Data}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "음주운전",
      content: (
        <Step2
          data={step2Data}
          setData={setStep2Data}
          onPrev={onPrev}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "음주운전",
      content: (
        <Step3
          data={step3Data}
          setData={setStep3Data}
          onPrev={onPrev}
          setDbUpdate={setDbUpdate}
          itemLayout={itemLayout}
        />
      ),
    },
  ];

  return (
    <>
      <div className="steps-content">{steps[page].content}</div>
    </>
  );
};

export default Insult;
