import styled from "@emotion/styled";

export const CheckBoxGroupTitle = styled.div`
    color: #000;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`


export const CheckboxGroupWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 19px;
`

export const CheckboxListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
`

export const CheckboxText = styled.div`
    color: #000;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
`

export const CheckboxInput = styled.input`
    margin-right: 8px;
    vertical-align: middle;
`

export const RadioInput = styled.input`
    margin-right: 8px;
    vertical-align: middle;
`