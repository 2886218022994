import { 
    CommonCriminalContainer,
    TitleWrapper,
    Title,
    TitleIcon,
    TitleBottomBorder,
    InputTextWrapper,
    InputText,
    InputTextArea,
    SubTitle,
    SubmitButtonWrapper,
    SubmitButton,
    SubmitButtonContainer
 } from "./CommonCriminal.style";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

 import {
  Input,
  Modal,
} from "antd";


import { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

import IcCriminalCheck from "../../../assets/svg/IcCriminalCheck.svg";
import DaumPostcode from "react-daum-postcode";
const { useHistory } = require("react-router-dom");


const CriminalRepresentCenter = ({loggedInUser}) =>  {
  const [caseType, setCaseType] = useState("");
  const [address, setAddress] = useState("");
  const [caseDesc, setCaseDesc] = useState("");
  const [contact, setContact] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const userInfo = useRecoilValue(userData);

  useEffect(() => {
  }, [caseType, contact]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddressSelect = (data) => {
    const selectedAddress = data.address;
    setAddress(selectedAddress);
    setIsModalOpen(false);
  };

  const handleDescChange = (e) => {
    const inputValue = e.target.value;
    setCaseDesc(inputValue)
  }

  const onChangeContact= (e)=>{
    setContact(e.target.value)
  }

  const onChangeCaseType= (e)=>{
    setCaseType(e.target.value)
  }

  const onSubmit = async () => {
    const servieData = {
      criminalCaseType: caseType,
      address: address,
      desc: caseDesc,
      contact: contact,
      userId: loggedInUser.uid,
    };
    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "common_criminal_represent",
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: servieData,
        userId: loggedInUser.uid,
        clientName: userInfo.name,
        status: "submitted",
      });
      history.push("/submit/success");
    } catch (err) {
      history.push("/submit/fail");
    } 
  };

  return (
      <CommonCriminalContainer>
        <Modal
            title="주소 검색"
            open={isModalOpen}
            onCancel={closeModal}
            footer={null}
          >
          <DaumPostcode onComplete={handleAddressSelect} />
        </Modal>
        <TitleWrapper>
            <TitleIcon src={IcCriminalCheck}/>
            <Title>
                간편 접수를 위해 아래 내용을 알려주세요.
            </Title> 
        </TitleWrapper>
        <TitleBottomBorder/>
        <InputTextWrapper>
          <SubTitle>사건명을 입력해주세요.</SubTitle>
          <Input style={{height: 64}} value={caseType} onChange={onChangeCaseType} placeholder="내용을 입력해주세요. (예 : 사기, 폭행, 투자리딩, 모욕, 통매음 등)" />
        </InputTextWrapper>
        <InputTextWrapper>
          <SubTitle>관할 법원 판단을 위해 의뢰인 본인의 주소를 입력해주세요. (시/군/구 단위까지)</SubTitle>
          <InputText value={address} onClick={showModal} placeholder="내용을 입력해주세요. (예 : 서울특별시 강남구)"/>
        </InputTextWrapper>
        <InputTextWrapper>
          <SubTitle>의뢰하고 싶은 상황을 구체적으로 알려주세요.</SubTitle>
        <InputTextArea value={caseDesc} onChange={handleDescChange} placeholder="내용을 입력해주세요."/>
        </InputTextWrapper>
        <InputTextWrapper>
          <SubTitle>연락 가능한 휴대폰 번호를 입력해주세요.</SubTitle>
          <Input style={{height: 64}} value={contact} onChange={onChangeContact} placeholder="0000000000 - 표기 없이 입력해주세요" />
        </InputTextWrapper>
        <SubmitButtonWrapper>
          <SubmitButtonContainer disabled={contact === ""} isActive={contact !== ""} onClick={() => onSubmit()}>
          <SubmitButton>신청하기</SubmitButton>
          </SubmitButtonContainer>
        </SubmitButtonWrapper>
      </CommonCriminalContainer>
  )

}

export default CriminalRepresentCenter;

