import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const DrunkDrivingRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        음주운전 양형자료
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="음주운전 사건정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          {serviceData.date ? (
            <Descriptions.Item label="사건 발생일" labelStyle={labelStyle}>
              {serviceData.date}
            </Descriptions.Item>
          ) : null}

          <Descriptions.Item label="혈중 알콜 농도" labelStyle={labelStyle}>
            {serviceData.bloodAlcholLevel} %
          </Descriptions.Item>

          <Descriptions.Item label="인명피해" labelStyle={labelStyle}>
            {serviceData.casualties.exist === "y" ? "있음" : "없음"}
          </Descriptions.Item>

          {serviceData.casualties.agreement ? (
            <Descriptions.Item label="인명피해 합의여부" labelStyle={labelStyle}>
              {serviceData.casualties.agreement === "y"
                ? "합의"
                : serviceData.casualties.agreement === "n"
                ? "합의안됨"
                : "기타"}
            </Descriptions.Item>
          ) : null}

          {serviceData.propertyDamage ? (
            <>
              <Descriptions.Item label="대물피해" labelStyle={labelStyle}>
                {serviceData.propertyDamage.exist === "y" ? "있음" : "없음"}
              </Descriptions.Item>
              {serviceData.propertyDamage.agreement ? (
                <Descriptions.Item label="대물피해 합의여부" labelStyle={labelStyle}>
                  {serviceData.propertyDamage.agreement === "y"
                    ? "합의"
                    : serviceData.propertyDamage.agreement === "n"
                    ? "합의안됨"
                    : "기타"}
                </Descriptions.Item>
              ) : null}
            </>
          ) : null}

          <Descriptions.Item label="수사/재판 단계" labelStyle={labelStyle}>
            {serviceData.currentStep === "police"
              ? "경찰조사"
              : serviceData.currentStep === "prosecution"
              ? "검찰조사"
              : serviceData.currentStep === "trial"
              ? "공판준비"
              : ""}
          </Descriptions.Item>

          <Descriptions.Item label="음주운전 이력" labelStyle={labelStyle}>
            {serviceData.history.isFirst === "y" ? "초범" : "재범"}
          </Descriptions.Item>
          {serviceData.history.isFirst !== "y" ? (
            <>
              <Descriptions.Item label="음주운전 횟수" labelStyle={labelStyle}>
                {serviceData.history.cnt}
              </Descriptions.Item>
              <Descriptions.Item label="마지막 처벌년도" labelStyle={labelStyle}>
                {serviceData.history.lastCrimeYear}
              </Descriptions.Item>
            </>
          ) : null}
        </Descriptions>

        <Descriptions
          title="양형자료 작성 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          {serviceData.drinkReason ? (
            <Descriptions.Item label="음주사유" labelStyle={labelStyle}>
              {serviceData.drinkReason ? serviceData.drinkReason : "모름"}
            </Descriptions.Item>
          ) : null}

          <Descriptions.Item label="차량매도" labelStyle={labelStyle}>
            {serviceData.sellingCar === "y"
              ? "가능"
              : serviceData.sellingCar === "n"
              ? "불가능"
              : "모름"}
          </Descriptions.Item>
          <Descriptions.Item label="직업" labelStyle={labelStyle}>
            {serviceData.occupation}
          </Descriptions.Item>
          <Descriptions.Item label="부양 가족 수" labelStyle={labelStyle}>
            {serviceData.familyMember ? serviceData.familyMember + "명" : ""}
          </Descriptions.Item>
          <Descriptions.Item label="가계 연간 소득액" labelStyle={labelStyle}>
            {serviceData.income
              ? serviceData.income.toLocaleString() + "원"
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="가계 부채 규모" labelStyle={labelStyle}>
            {serviceData.dept
              ? serviceData.dept.toLocaleString() + "원"
              : "없음"}
          </Descriptions.Item>
          <Descriptions.Item label="봉사활동" labelStyle={labelStyle}>
            {serviceData.volunteerRecord === "y" ? "있음" : "없음"}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.description
              ? serviceData.description.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
        </Descriptions>

        {/* <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">첨부 파일</div>
          </div>
          {serviceData.attachments.map((attachment) => (
            <Image
              key={attachment}
              width={75}
              height={75}
              src={attachment}
              preview={{ zIndex: 10000 }}
            />
          ))}
        </div> */}
      </div>
    </>
  );
};

export default DrunkDrivingRequest;
