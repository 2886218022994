import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Radio,
  InputNumber,
  Popover,
  Divider,
  Row,
  Col,
  Input,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import TermsOfService from "components/form/commons/TermsOfService";
import React, { useState, useEffect } from "react";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper2,
} from "../../Form.style";

const Step1 = ({ data, setData, setDbUpdate, itemLayout }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async (values) => {
    setSubmitting(true);
    setData({ ...values });
    setDbUpdate(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        마약사건 양형 자료 작성 정보
      </Title>

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          작성하신 내용을 바탕으로
          <br />
          어떤 양형 자료들을 작성할 지, <br />
          변호사가 직접 검토 후 의견을 드립니다.
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper2>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          form={form}
          style={{
            marginTop: 10,
            marginBottom: 20,
            width:"100%",
            textAlign:"left"
          }}
          requiredMark={false}
        >
          <Form.Item
            label="마약사건 이력"
            name={["history", "isFirst"]}
            rules={[
              {
                required: true,
                message: "과거 마약사건 이력 유무를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">초범</Radio.Button>
              <Radio.Button value="n">재범</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return (
                prevValues.history.isFirst !== currentValues.history.isFirst
              );
            }}
          >
            {() => (
              <>
                <Form.Item
                  label="과거 이력"
                  rules={
                    form.getFieldValue(["history", "isFirst"]) === "y"
                      ? []
                      : [
                          {
                            required: true,
                            message: "마약사건 처벌 횟수를 입력해주세요",
                          },
                        ]
                  }
                  hidden={form.getFieldValue(["history", "isFirst"]) === "y"}
                  name={["history", "cnt"]}
                >
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    style={{
                      width: "40%",
                      maxWidth: 120,
                    }}
                    addonAfter="회"
                    controls={false}
                  />
                </Form.Item>

                <Form.Item
                  label="마지막 처벌 년도"
                  rules={
                    form.getFieldValue(["history", "isFirst"]) === "y"
                      ? []
                      : [
                          {
                            required: true,
                            message: "마지막 처벌 년도를 입력해주세요",
                          },
                        ]
                  }
                  hidden={form.getFieldValue(["history", "isFirst"]) === "y"}
                  name={["history", "lastCrimeYear"]}
                >
                  <InputNumber
                    style={{
                      width: "40%",
                      maxWidth: 120,
                    }}
                    addonAfter="년"
                    controls={false}
                  />
                </Form.Item>
              </>
            )}
          </Form.Item>

          <Form.Item
            label="마약 판매 및 유통 여부"
            name={["sellingOrDistribution"]}
            rules={[
              {
                required: true,
                message: "마약 판매 및 유통 여부를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="마약 투약 여부"
            name={["administration"]}
            rules={[
              {
                required: true,
                message: "마약 투약 여부를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="자수 여부"
            name={["voluntary"]}
            rules={[
              {
                required: true,
                message: "자수 여부를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                주요 수사 협조 여부
                <Popover
                  content={
                    <div>
                      추가적인 마약범을 잡는데 수사를 협조한 경우 <br />
                      '예'를 선택해주세요
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["investigationCoop"]}
            rules={[
              {
                required: true,
                message: "주요 수사 협조 여부를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                직업
                <Popover
                  content={
                    <div>
                      직업에 따라 양형자료 작성 방식이 달라집니다.
                      <br /> 예) 학생, 무직, 회사원, 개인사업 등
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["occupation"]}
            rules={[
              {
                required: true,
                message: "현재 직업을 입력해주세요",
              },
            ]}
          >
            <Input
              style={{
                width: "70%",
              }}
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                부양 가족 수
                <Popover
                  content={
                    <div>
                      부양하고 있는 가족의 수를 작성해주세요.
                      <br />
                      부양가족이 없는 경우 0으로 작성해주세요.
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["familyMember"]}
            rules={[
              {
                required: true,
                message: "부양 가족 수를 입력해주세요",
              },
            ]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{
                width: "40%",
              }}
              addonAfter="명"
              controls={false}
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                가계 연간 소득액
                <Popover
                  content={
                    <div>
                      본인 및 배우자의 대략적인 연 소득 금액을 작성해주세요.
                      <br />
                      없는 경우 0으로 작성해주세요.
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["income"]}
            rules={[
              {
                required: true,
                message: "가계 연간 소득액을 입력해주세요",
              },
            ]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{
                width: "70%",
              }}
              addonAfter="원"
              controls={false}
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                가계 부채 규모
                <Popover
                  content={
                    <div>
                      본인 및 배우자에게 빚이 있는 경우 대략적인 금액을
                      작성해주세요. 없는 경우 0으로 작성해주세요.
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["dept"]}
            rules={[
              {
                required: true,
                message: "가계 부채 규모를 입력해주세요",
              },
            ]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{
                width: "70%",
              }}
              addonAfter="원"
              controls={false}
            />
          </Form.Item>

          <Form.Item
            label="봉사활동 기록유무"
            name={["volunteerRecord"]}
            rules={[
              {
                required: true,
                message: "봉사활동 기록유무를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">있음</Radio.Button>
              <Radio.Button value="n">없음</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                상세내용
                <Popover
                  content={
                    <div>
                      사건 당시의 상황, 참작할 만한 점, 반성과 피해 회복을 위한
                      노력, 가족관계, 회사 인사 규정, 본인의 신체/사회/경제적
                      상황 등을 최대한 구체적이고 상세하게 작성해주세요.
                      <br />
                      보다 더 자세한 변호사 의견을 받아보실 수 있습니다.
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={"description"}
          >
            <TextArea rows={10} />
          </Form.Item>

          <Form.Item
            label="연락처"
            name={["contact"]}
            rules={[
              {
                required: true,
                message: "연락처를 입력해주세요",
              },
            ]}
          >
            <Input placeholder="검토 결과를 받으실 연락처를 입력해주세요" />
          </Form.Item>

          <TermsOfService />
          {/* <div>
            <label>
              <>
                첨부파일
                <Popover
                  content={
                    <div>
                      집주인에게 보증금을 지급했음을 증명할 수 있는 자료
                      <br />
                      (임대차 계약서 사본, 임대차 보증금 입금내역의 계좌 사본,
                      <br />
                      임대차 보증금 이체 확인증, 계약 해지 증명 서류 등)를 전부
                      첨부하세요
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            </label>
            <div style={{ marginTop: 5 }}>
              <Upload
                accept="image/*"
                beforeUpload={() => false}
                onPreview={onPreview}
                onChange={onChange}
                onRemove={onRemove}
                fileList={fileList}
                maxCount={10}
              >
                <Button icon={<UploadOutlined />}>증거자료 첨부</Button>
              </Upload>
            </div>
          </div> */}
          <Row justify="space-between" style={{ marginTop: 20 }}>
            <Col span={6} offset={18} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                {submitting ? "제출 중" : "제출"}
              </Button>
            </Col>
          </Row>
        </Form>
        </CaseDetailWrapper2>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step1;
