import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Image,
  Input,
  List,
  message,
  Row,
  Typography,
} from "antd";
import { dbService } from "fbase";
import { useState } from "react";

const qs = require("qs");

const RequestCommon = ({ serviceData, requestData, isAdmin }) => {
  // const isMobile = () => {
  //   let check = false;
  //   (function (a) {
  //     if (
  //       // eslint-disable-next-line
  //       /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
  //         a
  //       ) || // eslint-disable-next-line
  //       /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
  //         a.substr(0, 4)
  //       )
  //     )
  //       check = true;
  //   })(navigator.userAgent || navigator.vendor || window.opera);
  //   return check;
  // };

  // const [comment, setComment] = useState("");
  const [mandator, setMandator] = useState("");
  const [updating, setUpdating] = useState(false);
  const key = "updatable";

  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  const kakaoPayConfig = {
    adminKey: process.env.REACT_APP_KAKAO_ADMIN_KEY,
    cid: process.env.REACT_APP_KAKAO_PAY_CID,
    approval_url: process.env.REACT_APP_KAKAO_PAY_APPROVAL_URL,
    fail_url: process.env.REACT_APP_KAKAO_PAY_FAIL_URL,
    cancel_url: process.env.REACT_APP_KAKAO_PAY_CANCEL_URL,
  };

  // const onPayClick = async () => {
  //   const res = await fetch("https://kapi.kakao.com/v1/payment/ready", {
  //     method: "POST",
  //     cache: "no-cache",
  //     headers: {
  //       Authorization: "KakaoAK " + kakaoPayConfig.adminKey,
  //       "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //     },
  //     body: qs.stringify({
  //       cid: kakaoPayConfig.cid,
  //       partner_order_id: requestData.id,
  //       partner_user_id: requestData.userId,
  //       item_name: "법대리",
  //       quantity: 1,
  //       total_amount: requestData.price,
  //       tax_free_amount: 0,
  //       approval_url: kakaoPayConfig.approval_url + "/" + requestData.id,
  //       fail_url: kakaoPayConfig.fail_url + "/" + requestData.id,
  //       cancel_url: kakaoPayConfig.cancel_url + "/" + requestData.id,
  //     }),
  //   });

  //   const payReadyResponse = await res.json();
  //   if (res.status === 200) {
  //     try {
  //       await dbService
  //         .collection("requests_temp")
  //         .doc(requestData.id)
  //         .update({ tid: payReadyResponse.tid });
  //     } catch (error) {
  //       message.error({
  //         content: "결제 요청 실패",
  //         duration: 2,
  //       });
  //     }

  //     if (isMobile()) {
  //       window.location.replace(payReadyResponse.next_redirect_mobile_url);
  //     } else {
  //       window.location.replace(payReadyResponse.next_redirect_pc_url);
  //     }
  //   } else {
  //     message.error({
  //       content: "결제 요청 실패",
  //       duration: 2,
  //     });
  //   }
  // };

  // const onCommentClick = async () => {
  //   message.loading({
  //     content: "댓글 작성 중입니다.",
  //     key,
  //     duration: 0,
  //   });
  //   setUpdating(true);

  //   if (!comment) {
  //     message.warning({
  //       content: "댓글을 입력해주세요",
  //       key,
  //       duration: 2,
  //     });
  //     setUpdating(false);
  //     return;
  //   }

  //   const commentsData = requestData.comments
  //     ? JSON.parse(JSON.stringify(requestData.comments))
  //     : [];
  //   commentsData.push({
  //     comment: comment,
  //     commenter: isAdmin ? "법대로" : "나",
  //   });
  //   try {
  //     await dbService.collection("requests_temp").doc(requestData.id).update({
  //       comments: commentsData,
  //     });
  //     message.success({
  //       content: "댓글 작성 완료!",
  //       key,
  //       duration: 2,
  //     });
  //   } catch (error) {
  //     message.error({
  //       content: "댓글 작성 실패",
  //       key,
  //       duration: 2,
  //     });
  //   }
  //   setUpdating(false);
  //   window.location.reload();
  // };

  // const onMandatorClick = async () => {
  //   message.loading({
  //     content: "위임장을 작성중입니다.",
  //     key,
  //     duration: 0,
  //   });
  //   setUpdating(true);

  //   if (!mandator) {
  //     message.warning({
  //       content: "위임인 성함을 입력해주세요",
  //       key,
  //       duration: 2,
  //     });
  //     setUpdating(false);
  //     return;
  //   }

  //   try {
  //     const newDate = new Date();
  //     const date = newDate.getDate();
  //     const month = newDate.getMonth() + 1;
  //     const year = newDate.getFullYear();
  //     await dbService
  //       .collection("requests_temp")
  //       .doc(requestData.id)
  //       .update({
  //         mandator: mandator,
  //         mandateDate: {
  //           year: year,
  //           month: month,
  //           date: date,
  //         },
  //       });
  //     message.success({
  //       content: "위임장 작성 완료",
  //       key,
  //       duration: 2,
  //     });
  //   } catch (error) {
  //     message.error({
  //       content: "위임장 작성 실패",
  //       key,
  //       duration: 2,
  //     });
  //   }
  //   setUpdating(false);
  //   window.location.reload();
  // };

  return <>
  </>
  // return (
  //   <>
  //     <div
  //       style={{
  //         backgroundColor: "white",
  //         padding: 15,
  //         paddingTop: 5,
  //         marginBottom: 20,
  //         borderRadius: 5,
  //       }}
  //     >
  //       <Descriptions
  //         title="변호사 의견"
  //         bordered
  //         column={reactiveColumn}
  //         size={"small"}
  //         style={{ marginTop: 20, marginBottom: 15 }}
  //       >
  //         <Descriptions.Item>
  //           {requestData.reply
  //             ? requestData.reply.split("\n").map((line, idx) => {
  //                 return (
  //                   <span key={idx}>
  //                     {line}
  //                     <br />
  //                   </span>
  //                 );
  //               })
  //             : "변호사가 검토 중 입니다."}
  //         </Descriptions.Item>
  //       </Descriptions>
  //       {requestData.price ? (
  //         <div>
  //           <Descriptions
  //             title="서비스 결제"
  //             bordered
  //             column={reactiveColumn}
  //             size={"small"}
  //             style={{ marginTop: 20, marginBottom: 15 }}
  //           >
  //             <Descriptions.Item label="이용금액">
  //               {requestData.price.toLocaleString()} 원
  //             </Descriptions.Item>
  //             <Descriptions.Item label="상태">
  //               {requestData.status === "submitted" ||
  //               requestData.status === "replied"
  //                 ? "결제요청"
  //                 : "결제완료"}
  //             </Descriptions.Item>
  //           </Descriptions>
  //           <Typography.Text type="secondary">
  //             <div style={{ padding: 10, paddingTop: 0, fontSize: 12 }}>
  //               이용 금액은 법대리 수임료 금액이며,
  //               <br /> 인지세, 송달료, 등록면허세 등은 별도로 납부해주셔야
  //               합니다.
  //             </div>
  //           </Typography.Text>

  //           {requestData.status === "submitted" ||
  //           requestData.status === "replied" ? (
  //             <>
  //               <Collapse ghost>
  //                 <Collapse.Panel header="환불 규정" key="1">
  //                   <p>
  //                     서비스를 신청하면 입력 내용을 기초로 당일 즉시 또는 1일
  //                     이내에 신청서를 작성합니다. 신청서가 즉시 작성되므로
  //                     서비스 신청 후에는 취소 및 환불이 되지 않습니다.
  //                     정보입력에 오류가 없는지 확인하시고 결제하시기 바랍니다.
  //                   </p>
  //                 </Collapse.Panel>
  //               </Collapse>

  //               <Row justify="space-between" style={{ marginTop: 20 }}>
  //                 <Col span={8} offset={16} style={{ textAlign: "right" }}>
  //                   <Button
  //                     type="primary"
  //                     style={{ width: "100%", maxWidth: "100px" }}
  //                     onClick={onPayClick}
  //                   >
  //                     결제하기
  //                   </Button>
  //                 </Col>
  //               </Row>
  //             </>
  //           ) : null}
  //         </div>
  //       ) : null}

  //       {requestData.status !== "submitted" ? (
  //         <>
  //           <Divider />
  //           <div className="ant-descriptions-header">
  //             <div className="ant-descriptions-title">위임장</div>
  //           </div>
  //           <div style={{ textAlign: "center" }}>
  //             <Image
  //               width={300}
  //               src="/images/poa.png"
  //               preview={{ zIndex: 10000 }}
  //             />
  //           </div>

  //           {requestData.mandator ? (
  //             <>
  //               <Typography.Text type="primary">
  //                 <div
  //                   style={{
  //                     padding: 10,
  //                     fontSize: 12,
  //                     textAlign: "center",
  //                   }}
  //                 >
  //                   {requestData.mandateDate.year +
  //                     ". " +
  //                     requestData.mandateDate.month +
  //                     ". " +
  //                     requestData.mandateDate.date}
  //                   <br />
  //                   위임인 {requestData.mandator}
  //                 </div>
  //               </Typography.Text>
  //             </>
  //           ) : (
  //             <Row
  //               justify="space-between"
  //               style={{ marginTop: 20, textAlign: "center" }}
  //             >
  //               <Col span={16} offset={0}>
  //                 <Input
  //                   style={{ width: "100%" }}
  //                   placeholder="위임인 성함을 입력해주세요"
  //                   value={mandator}
  //                   onChange={(e) => setMandator(e.target.value)}
  //                   disabled={updating}
  //                 />
  //               </Col>
  //               <Col span={8} offset={0}>
  //                 <Button
  //                   type="primary"
  //                   onClick={onMandatorClick}
  //                   disabled={updating}
  //                 >
  //                   위임하기
  //                 </Button>
  //               </Col>
  //             </Row>
  //           )}
  //         </>
  //       ) : null}

  //       {requestData.status === "requested" ? (
  //         <>
  //           <Divider />
  //           <div className="ant-descriptions-header">
  //             <div className="ant-descriptions-title">
  //               반성문/탄원서/서약서 템플릿 및 샘플
  //             </div>
  //           </div>
  //           <div>
  //             <Button type="primary">
  //               <a href={"/sample/" + requestData.id}>확인하기</a>
  //             </Button>
  //           </div>
  //         </>
  //       ) : null}

  //       {requestData.comments ? (
  //         <>
  //           <div className="ant-descriptions-header">
  //             <div className="ant-descriptions-title">댓글</div>
  //           </div>
  //           <List
  //             bordered
  //             itemLayout="horizontal"
  //             dataSource={requestData.comments}
  //             renderItem={(item, idx) => (
  //               <List.Item>
  //                 <List.Item.Meta
  //                   //   avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
  //                   title={item.commenter}
  //                   description={item.comment}
  //                 />
  //               </List.Item>
  //             )}
  //           />
  //           <Typography.Text type="secondary">
  //             <div style={{ padding: 10, fontSize: 12 }}>
  //               추가 문의 사항은 우측하단의 채널톡을 통해 문의 부탁드립니다.
  //             </div>
  //           </Typography.Text>
  //         </>
  //       ) : null}

  //       {/* <TextArea
  //         placeholder="추가 문의 내용을 입력해주세요."
  //         value={comment}
  //         onChange={(e) => setComment(e.target.value)}
  //         autoSize
  //       />
  //       <Col span={8} offset={16} style={{ textAlign: "right" }}>
  //         <Button type="primary" onClick={onCommentClick} disabled={updating}>
  //           댓글달기
  //         </Button>
  //       </Col> */}
  //     </div>
  //   </>
  // );
};

export default RequestCommon;
