import { createContext } from "react";
import { useContext } from "react";
import { 
    CheckBoxGroupTitle,
    CheckboxGroupWrapper,
    CheckboxListWrapper,
    CheckboxText,
    RadioInput
 } from "./CheckBox.style";

const RadioContext = createContext({});

export function RadioGroup({ label, children, ...rest }) {
    return (
      <CheckboxGroupWrapper>
        {label !== "" &&
        <CheckBoxGroupTitle>{label}</CheckBoxGroupTitle>
        }
        <RadioContext.Provider value={rest}>
          <CheckboxListWrapper>
          {children}
          </CheckboxListWrapper>
        </RadioContext.Provider>
      </CheckboxGroupWrapper>
    );
}
  
export function Radio({ children, value, name, defaultChecked, disabled }) {
    const group = useContext(RadioContext);
    return (
    <CheckboxText>
        <RadioInput
        type="radio"
        value={value}
        name={name}
        disabled={disabled || group.disabled}
        checked={group.value !== undefined ? value === group.value : undefined}
        onChange={(e) => group.onChange && group.onChange(e.target.value)}
        />
        {children}
    </CheckboxText>
    );
}