import { useHistory } from "react-router-dom";
import { EventDetailSubmitBtn, EventDetailSubmitContainer, EventDetailSubmitDesc, EventDetailSubmitSection, EventDetailSubmitTextContainer, EventDetailSubmitTitle } from "./EventStyle.style";

export const EventDetailSubmit = (props) => {
  const history = useHistory();
  return (
    <EventDetailSubmitSection>
      <EventDetailSubmitContainer>
        <EventDetailSubmitTextContainer>
          <EventDetailSubmitTitle>
            내용 증명이<br />필요하신가요?
          </EventDetailSubmitTitle>
          <EventDetailSubmitDesc>
            현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면 분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
          </EventDetailSubmitDesc>
        </EventDetailSubmitTextContainer>
        <EventDetailSubmitBtn onClick={() => history.push(props.landingPath)}>
          내용 증명 보내기
        </EventDetailSubmitBtn>
      </EventDetailSubmitContainer>
    </EventDetailSubmitSection>
  )
}