import { useState } from "react";
import styled from "@emotion/styled";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const Container = styled.div`
  padding: 22px;
  padding-bottom: 12px;
  cursor: pointer;
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const Title = styled.div`
//   flex: 1;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #919293;
  @media screen and (max-width: 768px) {
    // min-width: auto !important;
    font-size: 16px;
  }
`;

const Desc = styled.div`
  margin-top: 12px;
  flex: 1;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #919293;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const RefundCollapse = ({ title, desc }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container onClick={() => setIsOpen((v) => !v)}>
      <TopWrapper>
        <Title>{title}</Title>
        {isOpen ? <UpOutlined /> : <DownOutlined />}
      </TopWrapper>
      {isOpen && <Desc>{desc}</Desc>}
    </Container>
  );
};

export default RefundCollapse;
