import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const HostNotallowedCertificationRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };
  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
      임대차 계약연장 불허 내용증명
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="임대차 계약연장 불허 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item
            label="임대차 계약 일자"
            labelStyle={labelStyle}
          >
            {serviceData.notallowed.contractDate}
          </Descriptions.Item>

          <Descriptions.Item
            label="임대 종료 일자"
            labelStyle={labelStyle}
          >
            {serviceData.notallowed.endDate}
          </Descriptions.Item>

          {/* <Descriptions.Item
            label="임대차 계약 주소"
            labelStyle={labelStyle}
          >
            {`${serviceData.notallowed.address1} \n ${serviceData.notallowed.address1}`}
          </Descriptions.Item> */}

          <Descriptions.Item
            label="계약 연장을 청구한 일자"
            labelStyle={labelStyle}
          >
            {serviceData.notallowed.requestDate}
          </Descriptions.Item>

          {serviceData.notallowed.reason ? (
            <Descriptions.Item label="계약 연장을 거절한 이유" labelStyle={labelStyle}>
              {serviceData.notallowed.reason}
            </Descriptions.Item>
          ) : null}
          
        </Descriptions>

        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="발신인" labelStyle={labelStyle}>
            {serviceData.user.information.sender}
          </Descriptions.Item>
          {/* <Descriptions.Item label="발신인 주소" labelStyle={labelStyle}>
            {`${serviceData.user.information.senderAddress1} \n ${serviceData.user.information.senderAddress2}`}
          </Descriptions.Item> */}
          <Descriptions.Item label="발신인 전화번호" labelStyle={labelStyle}>
            {serviceData.user.information.senderPhoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="수신인" labelStyle={labelStyle}>
            {serviceData.user.information.receiver}
          </Descriptions.Item>
          {/* <Descriptions.Item label="수신인 주소" labelStyle={labelStyle}>
            {`${serviceData.user.information.receiverAddress1} \n ${serviceData.user.information.receiverAddress2}`}
          </Descriptions.Item> */}
          <Descriptions.Item label="수신인 전화번호" labelStyle={labelStyle}>
            {serviceData.user.information.receiverPhoneNumber}
          </Descriptions.Item>

          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.description
              ? serviceData.description.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
          <Descriptions.Item label="프로모션" labelStyle={labelStyle}>
            {serviceData.promotionCode}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};

export default HostNotallowedCertificationRequest;
