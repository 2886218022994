import { useHistory } from "react-router-dom";

import IcServiceMain from "assets/svg/Ic3.3Noise.svg";

import {
  Container,
} from "components/Service.style";

import { useEffect, useState } from "react";
import {
  EventHeaderContainer,
  EventHeaderImg,
  EventHeaderSection,
  EventHeaderSubTitle,
  EventHeaderTitle
} from "../../EventStyle.style";
import {
  EventInputTextArea,
  EventFormContainer,
  EventFormEachContainer,
  EventFormEachDesc,
  EventFormEachTitle,
  EventFormSection,
  EventSubmitFooterSection,
  EventSubmitFooterText,
  EventSubmitFooterTextContainer,
  EventFormNamePhoneTextContainer,
  EventFormNamePhoneTitle,
  EventFormPhoneDesc
} from "./EventSubmit.style";
import { EventSubmit } from "./EventSubmit";
import { Radio, RadioGroup } from "components/home/CommonCriminal/RadioBox";
import { InputText } from "components/home/CommonCriminal/CommonCriminal.style";
import { Checkbox, CheckboxGroup } from "components/home/CommonCriminal/CheckBox";
import { removeBrTag } from "routes/Landing";

const EventNoiseCertificate = ({ loggedInUser }) => {
  const history = useHistory();

  // 상세 설명
  const [caseDesc, setCaseDesc] = useState("");

  // 의뢰인이 원하는 해결방향
  const [solutionType, setSolutionType] = useState("");
  const [etcSolution, setEtcSolution] = useState("");

  // 의뢰인이 원하는 결과
  const [desiredResult, setDesiredResult] = useState([]);
  const [etcDesiredResult, setEtcDesiredResult] = useState("");

  const [name, setName] = useState("");

  const [phone, setPhone] = useState("");

  const eventServiceData = () => {
    const serviceData = {
      solutionType: solutionType,
      etcSolution: solutionType.includes("직접 입력") ? etcSolution : "",
      desiredResult: desiredResult,
      etcDesiredResult: desiredResult.includes("직접 입력") ? etcDesiredResult : "",
      desc: caseDesc,
    };
    return serviceData;
  }

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    setName(inputValue)
  }

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    setPhone(inputValue)
  }

  const onClickBtn = () => {
    history.push("/loan/order");
  }

  const handleDescChange = (e) => {
    const inputValue = e.target.value;
    setCaseDesc(inputValue)
  }

  const handleEtcSolutionChange = (e) => {
    const inputValue = e.target.value;
    setEtcSolution(inputValue)
  }

  const handleEtcDesiredResultChange = (e) => {
    const inputValue = e.target.value;
    setEtcDesiredResult(inputValue);
  }

  const isSubmitReady = () => {
    if (caseDesc !== "" && solutionType !== "" && desiredResult.length !== 0 && name !== "" && phone !== "") {
      return true
    }
    return false
  }

  const footerText = () => {
    const text = isSubmitReady()
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄져요. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "모든 항목에 답변해 주세요.\n답변이 완료되면 제출할 수 있어요.";
    return removeBrTag(text);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <EventHeaderSection>
        <EventHeaderContainer>
          <EventHeaderImg src={IcServiceMain} />
          <EventHeaderTitle>
            층간 소음
          </EventHeaderTitle>
          <EventHeaderSubTitle>
            내용 증명 보내기
          </EventHeaderSubTitle>
        </EventHeaderContainer>
      </EventHeaderSection>
      <EventFormSection>
        <EventFormContainer>
          <EventFormEachContainer>
            <EventFormEachTitle>
              현재 상황에 대한<br />상세 설명을 작성해주세요.
            </EventFormEachTitle>
            <EventFormEachDesc>
              상세 설명은 육하원칙에 따라 간결하게 작성해 주셔야
              변호사 검토가 빠르게 이뤄져요.
            </EventFormEachDesc>
            <
              EventInputTextArea value={caseDesc} onChange={handleDescChange} placeholder="내용을 입력해주세요." />
          </EventFormEachContainer>
          <EventFormEachContainer>
            <EventFormEachTitle>
              원하시는 해결
              방안을<br
              />선택해
              주세요.
            </EventFormEachTitle>
            <RadioGroup
              label=""
              value={solutionType}
              onChange={setSolutionType}
            >
              <Radio value="소송 없이 합의">소송 없이 합의</Radio>
              <Radio value="소송 진행">소송 진행</Radio>
              <Radio value="직접 입력">직접 입력</Radio>
              {solutionType.includes("직접 입력") && <>
                <InputText value={etcSolution} onChange={handleEtcSolutionChange} placeholder="내용을 입력해주세요." />
              </>}
            </RadioGroup>
          </EventFormEachContainer>
          <EventFormEachContainer>
            <EventFormEachTitle>
              원하시는 결과를 선택해 주세요.
              <br />(복수 선택 가능)
            </EventFormEachTitle>
            <CheckboxGroup
              label="의뢰인은 어떤 결과를 원하나요?(복수선택 가능)"
              values={desiredResult}
              onChange={setDesiredResult}
            >
              <Checkbox value="진정한 사과, 또는 처벌">진정한 사과, 또는 처벌</Checkbox>
              <Checkbox value="피해입은 부분의 금전적 보상">피해입은 부분의 금전적 보상</Checkbox>
              <Checkbox value="직접 입력">
                직접입력
              </Checkbox>
              {desiredResult.includes("직접 입력") && <>
                <InputText value={etcDesiredResult} onChange={handleEtcDesiredResultChange} placeholder="내용을 입력해주세요." />
              </>}
            </CheckboxGroup>
          </EventFormEachContainer>
          <EventFormEachContainer>
            <EventFormEachTitle>
              연락 받으실 정보를 작성해 주세요.
            </EventFormEachTitle>
            <EventFormNamePhoneTextContainer>
              <EventFormNamePhoneTitle>
                성함
              </EventFormNamePhoneTitle>
              <InputText value={name} onChange={handleNameChange} placeholder="홍길동" />
            </EventFormNamePhoneTextContainer>
            <EventFormNamePhoneTextContainer>
              <EventFormNamePhoneTitle>
                연락처
              </EventFormNamePhoneTitle>
              <InputText
                value={phone}
                onChange={handlePhoneChange}
                placeholder="010 1234 1234"
                type="number"
                inputMode="numeric" minLength={11} />
              <EventFormPhoneDesc> 정확히 입력했는지 제출전에 확인해 주세요!</EventFormPhoneDesc>
            </EventFormNamePhoneTextContainer>
          </EventFormEachContainer>
        </EventFormContainer>
      </EventFormSection>
      <EventSubmitFooterSection active={isSubmitReady()}>
        <EventSubmitFooterTextContainer>
          <EventSubmitFooterText active={isSubmitReady()}>
            {footerText()}
          </EventSubmitFooterText>
        </EventSubmitFooterTextContainer>
      </EventSubmitFooterSection>
      <EventSubmit
        active={isSubmitReady()}
        eventServiceData={eventServiceData()}
        serviceType={"3o3_noise"}
        name={name} phone={phone} />
    </Container>
  )
};

export default EventNoiseCertificate;