import styled from "@emotion/styled";
const bgColorMap = {
  submitted: "#FFF",
  replied: "#DBE8FF",
  paid: "#FFF",
  requested: "#3A7EFC",
  processing: "#3A7EFC",
  done: "#B2B2B2"
};
const borderMap = {
  submitted: "1px solid #3A7EFC",
  replied: "1px solid #3A7EFC",
  paid: "1px solid #3A7EFC",
  requested: "1px solid #3A7EFC",
  processing: "1px solid #3A7EFC",
  done: "none"
};
const textColorMap = {
  submitted: "#3A7EFC",
  replied: "#3A7EFC",
  paid: "#3A7EFC",
  requested: "#FFF",
  processing: "#FFF",
  done: "#FFF"
};

const suggestTextColorMap = {
  suggested: "#3A7EFC",
  none: "#B8B8B8"
}

const selectLawyerBtnBGColorMap = {
  suggested: "#3A7EFC",
  none: "#B2B2B2"
}


export const Container = styled.div`
  padding-top: 44px;
  padding-bottom: 0px;  
  font-family: "Pretendard";
  font-style: normal;
  text-align: center;  
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding-top: 12px; 
  }
`;

export const MyCaseListSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
  }
`;

export const ContentContainer = styled.div`
  padding-top: 44px;
  padding-bottom: 0px;
  font-family: "Pretendard";
  font-style: normal;
  max-width: 562px;
  width: 562px;
  text-align: center;  
  margin: 20px;
  margin-bottom: 120px;
  @media screen and (max-width: 768px) {
    padding-top: 12px;
    margin: 16px;
    width: 100%;
  }
`;
export const NoCaseTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 54px;
`;
export const NoCaseDesc = styled.div`
  color: #9A9CA7;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
`;

export const NoCaseDescBold = styled.span`
  color: #9A9CA7;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
  text-decoration-line: underline;
`;

export const ListTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 43px;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

export const ListCard = styled.div`
  width: 100%;
  padding: 20px 31px;
  height: 214px;
  background-color: white;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px #D3DAE4;
  cursor: pointer;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    padding: 15px 23px;
    height: 248px;
  }
`;

export const ListCardTopWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 17px;
  @media screen and (max-width: 768px) {
    padding-top: 12px;
  }
`;

export const ListCardTopLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ListCardBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 3px;
  padding: 5px 10px;
  height: 28px;
  margin-bottom: 12px;
  max-width: 105px;
  color: #3A7EFC;
  background: #DBE8FF;
`
export const ListCardBadge = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  margin-top: 2px;
`;

export const ListCardBadgeIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const ListCardTtile = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  border-bottom: 1px solid rgba(212, 212, 212, 0.60);
  padding-bottom: 32px;
  @media screen and (max-width: 768px) {
    text-align: left;
    font-size: 17px;
    padding-bottom: 26px;
  }
`;
export const ListCardDate = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;
export const ListLawyerWrapper = styled.div`
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }
`;
export const ListLawyerImg = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 15px;
`;
export const ListLawyerImgBlank = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin-right: 15px;
`;

export const ListLawyerTextBtnWrapper = styled.div`
  display: flex;  
`;

export const ListLawyerNameSuggestWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ListLawyerNameSuggestIcon = styled.img`
  width: 18px;
  height 18px;
  padding-bottom: 6px;
  margin-left: 8px;
  margin-right: 3px;
`

export const ListLawyerSuggestTitle = styled.div`
  color: ${(props) => suggestTextColorMap[props.status] || "#3a7efc"};
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 5px;
`

export const ListLawyerSelectBtnWrapper = styled.div`
  width: 173px;
  min-width: 173px;
  height: 45px;
  border-radius: 6px;
  background: ${(props) => selectLawyerBtnBGColorMap[props.status] || "#3a7efc"};
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ListLawyerSelectBtn = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const ListLawyerSelectBtnWrapperMobile = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    margin-top: 16px;
    display: block;
    width: 100%;
    height: 45px;
    border-radius: 6px;
    background: ${(props) => selectLawyerBtnBGColorMap[props.status] || "#3a7efc"};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ListLawyerSelectBtnMobile = styled.div`
  display: none;  
  @media screen and (max-width: 768px) {
    display: block;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`


export const ListLawyerTextWrapper = styled.div``;
export const ListLawyerName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 5px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: normal;
  }
`;
export const ListLawyerDesc = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 6px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

export const ListDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 100px 0;
`;

export const EventBannerMobileImg = styled.img`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 60px;
  }
`