import {
    QuestionCircleOutlined,
  } from "@ant-design/icons";
  import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Popover,
    Radio,
    Row,
    message,
  } from "antd";
  
  import {
    Title,
    SubText,
    ContentContainer,
    MyCaseSection,
    Container,
    CaseDetailWrapper,
  } from "../../Form.style";

  import TermsOfService from "components/form/commons/TermsOfService";
  
  import { useEffect, useState } from "react";
  const { TextArea } = Input;
  
  const Step1 = ({ data, setData, onNext, itemLayout }) => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showCustomInput, setShowCustomInput] = useState(false); // state to control the visibility of the input box
    const key = "updatable";

  
    const onFinish = (values) => {
      // values = { ...values, otherEvidence: otherEvidence };
      console.log(values);
      setSubmitting(true);
      setData(values);
    };
  
    useEffect(() => {
    if (submitting) {
        message.success({
            content: "제출 완료!",
            key,
            duration: 2,
        });
        onNext();
        }
    }, [submitting]);
  
    return (
      <Container style={{ textAlign: "left" }}>
        <MyCaseSection>
          <ContentContainer>
            <Title>상속포기</Title>
  
            <div style={{ marginTop: 10 }}>
              <SubText style={{ textAlign: "left" }} type="secondary">
                상속포기를 위해 아래 내용을 작성하세요.
              </SubText>
            </div>
  
            <Divider style={{ marginTop: 10 }} />
            <CaseDetailWrapper style={{ height: "90%" }}>
              <Form
                {...itemLayout.form}
                layout="horizontal"
                onFinish={onFinish}
                initialValues={data}
                form={form}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  width: "100%",
                  textAlign: "left",
                }}
                requiredMark={false}
              >

                <Form.Item  
                  label="고인의 사망 날짜"
                  name={["inheritGiveup", "date"]}
                  rules={[
                    {
                      required: true,
                      message: "날짜를 선택해주세요",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="날짜를 선택하세요"
                  />
                </Form.Item>

                <Form.Item
                  label="상속인의 수"
                  name={["inheritGiveup", "number"]}
                  rules={[
                    {
                      required: true,
                      message: "상속인의 수를 입력해주세요",
                    },
                  ]}
                  // style={{ width: "calc(85% - 8px)" }}
                >
                  <InputNumber
                    controls={false}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <>
                      상속재산 사용 여부
                      <Popover
                        content={
                          <div>
                            상속을 포기한다면, 상속포기 결정문(심판문)을 받기 전, 후<br/>
                            관계없이 상속재산을 사용하면 절대 안됩니다.
                            <br />
                            상속재산을 사용할 경우 상속포기는 무효가 됩니다.
                            <br />
                          </div>
                        }
                        placement="bottom"
                        trigger="click"
                      >
                        <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                      </Popover>
                    </>
                  }
                  name={["inheritGiveup", "isUsed"]}
                  rules={[{ required: true, message: "" }]}
                  // style={{ width: "calc(85% - 8px)" }}
                >
                  <Radio.Group>
                    <Radio.Button value="y">예</Radio.Button>
                    <Radio.Button value="n">아니오</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={
                    <>
                        상세내용
                        <Popover
                        content={
                            <div>
                            기타 입력하신 내용 외 특이사항이 있을 경우 작성해 주시면
                            변호사가 검토시 참고할 예정입니다.
                            </div>
                        }
                        placement="bottom"
                        trigger="click"
                        >
                        <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                        </Popover>
                    </>
                    }
                    name={["inheritGiveup", "description"]}
                >
                    <TextArea rows={10} />
                </Form.Item>
                <TermsOfService />
                <Row justify="space-between">
                  <Col span={6} offset={18} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%", maxWidth: "100px" }}
                    >
                      {submitting ? "제출 중" : "제출"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CaseDetailWrapper>
          </ContentContainer>
        </MyCaseSection>
      </Container>
    );
  };
  
  export default Step1;
  