import { useEffect, useState } from "react";
import IcJudge from "../../assets/svg/IcJudge.svg";
import IcCriminalCheck from "../../assets/svg/IcCriminalCheck.svg";
import IcEstate from "../../assets/svg/IcEstateNew.svg";
import IcSelectDesc from "../../assets/svg/IcSelectDesc.svg";
import IcEstateOwner from "../../assets/svg/IcEstateOwner.svg";
import IcDumbbellSmall from "../../assets/svg/IcDumbbellSmall.svg";
import IcNoise from "../../assets/svg/IcNoise.svg";
import IcMoney from "../../assets/svg/IcMoney.svg";
import IcChain from "../../assets/svg/IcChain.svg";
import IcCertification from "../../assets/svg/IcCertification.svg";
import IcLandingSearch from "../../assets/svg/IcLandingSearch.svg";
import IcCommonCriminal from "../../assets/svg/IcCommonCriminal.svg";
import IcLandingCivil from "../../assets/svg/IcLandingCivil.svg";
import IcBomb from "../../assets/svg/IcBomb.svg";
import IcGolf from "../../assets/svg/IcGolf.svg";

import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

import {
  Container,
  ContentContainer,
  EtcServiceCenterSection,
  EtcServiceLandingSection,
  SectionTop,
  TopDesc,
  TopImg,
  TopTitle,
  TopWrapper,
} from "../Service.style";

import { RadioGroup, Radio } from "./CommonCriminal/RadioBox";
import { CheckboxGroup, Checkbox } from "./CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { useRecoilState } from 'recoil';
import { redirectEtcUri } from '../../states/atoms'

import {
  Input,
} from "antd";


import { 
  CommonCriminalContainer,
  EtcTitleWrapper,
  EtcTitleText,
  EtcTitleIcon,
  TitleDescWrapper,
  EtcDescText,
  TitleBottomBorder,
  InputTextWrapper,
  InputText,
  InputTextArea,
  SubTitle,
  SubmitButtonWrapper,
  SubmitButton,
  SubmitButtonContainer,
  LoginRequireWrapper,
  LoginRequireContainer,
  LoginRequireTitle,
  LoginRequireButton
} from "./CommonCriminal/CommonCriminal.style";
import { 
  LandingCardRowWrapper,
  LandingCardImg,
  LandingCard,
  LandingCardText,
  SelectDescWrapper,
  SelectDescImg,
  SelectDescText,
  LandingOtherServiceCardRowWrapper,
  LandingOtherServiceCard,
  LandingOtherServiceCardDesc,
  LandingOtherServiceCardText,
  LandingOtherServiceCardTextWrapper,
  LandingCardBottomSpace,
 } from "routes/Landing.style";
import Footer from "components/Footer";
import { removeBrTag } from "routes/Landing";

const { useHistory } = require("react-router-dom");

const EtcServiceCenter = ({loggedInUser}) => {
  // 의뢰인이 원하는 해결방향
  const [solutionType, setSolutionType] = useState("");
  const [etcSolution, setEtcSolution] = useState("");
  
  // 의뢰인이 원하는 결과
  const [desiredResult, setDesiredResult] = useState([]);
  const [etcDesiredResult, setEtcDesiredResult] = useState("");

  // 변호사에게 도움 받고 싶은 내용
  const [desiredHelp, setDesiredHelp] = useState("");
  const [etcDesiredHelp, setEtcDesiredHelp] = useState("");

  const [caseDesc, setCaseDesc] = useState("");
  const [contact, setContact] = useState("");
  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);


  const etcTitle = () => {
    const text = isMobile
      ? "기타 법률은 법대리 간편 법률 서비스에서\n원하는 서비스를 찾지 못한\n고객님들을 위한 페이지입니다."
      : "기타 법률은 법대리 간편 법률 서비스에서\n원하는 서비스를 찾지 못한 고객님들을 위한 페이지입니다.";
    return removeBrTag(text);
  };


  const etcDesc = () => {
    const text = isMobile
      ? "* 법대리 메인 페이지에서 제공되는 서비스의 경우,\n해당 서비스 페이지에 접속하셔서\n신청서를 제출해 주셔야 해당 분야의 전문 변호사님\n으로 배정되오니 참고 부탁드립니다."
      : "* 법대리 메인 페이지에서 제공되는 서비스의 경우, 해당 서비스 페이지에 접속하셔서\n신청서를 제출해 주셔야 해당 분야의 전문 변호사님으로 배정되오니 참고 부탁드립니다.";
    return removeBrTag(text);
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async () => {
    const servieData = {
      solutionType: solutionType,
      etcSolution: solutionType.includes("기타") ? etcSolution : "",
      desiredResult: desiredResult,
      etcDesiredResult: desiredResult.includes("기타") ? etcDesiredResult : "",
      desiredHelp: desiredHelp,
      etcDesiredHelp: desiredHelp.includes("기타") ? etcDesiredHelp : "",
      desc: caseDesc,
      contact: contact,
      userId: loggedInUser.uid,
    };
    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "common_etc",
        serviceId: uuid,        
        createdAt: Date.now(),
        service_data: servieData,
        userId: loggedInUser.uid,
        clientName: userInfo.name,
        status: "submitted",
      });
      history.push("/submit/success");
    } catch (err) {
      history.push("/submit/fail");
    } 
  };

  const handleDescChange = (e) => {
    const inputValue = e.target.value;
    setCaseDesc(inputValue)
  }

  const handleEtcSolutionChange = (e) => {
    const inputValue = e.target.value;
    setEtcSolution(inputValue)
  }

  const handleEtcDesiredResultChange = (e) => {
    const inputValue = e.target.value;
    setEtcDesiredResult(inputValue);
  }

  const handleEtcDesiredHelpChange = (e) => {
    const inputValue = e.target.value;
    setEtcDesiredHelp(inputValue);
  }

  const onChangeContact= (e)=>{
    setContact(e.target.value)
  }

  const onClickLogin = () => {    
    setEtcPath("/services/etc");
    history.push("/login")    
  }

  const setLayout = () => {
    if (loggedInUser) {
      return <>
        <InputTextWrapper>
              <SubTitle>의뢰하고 싶은 상황을 구체적으로 알려주세요.</SubTitle>
            <InputTextArea value={caseDesc} onChange={handleDescChange} placeholder="내용을 입력해주세요."/>
            </InputTextWrapper>            
            <RadioGroup
              label="의뢰인이 원하는 해결방향은 무엇인가요?"
              value={solutionType}
              onChange={setSolutionType}
            >
              <Radio value="분쟁, 소송 진행하지 않고 합의로 마무리하고 싶음">분쟁, 소송 진행하지 않고 합의로 마무리하고 싶음</Radio>
              <Radio value="내용증명 발송 후 소송 진행을 원함">내용증명 발송 후 소송 진행을 원함</Radio>
              <Radio value="기타">직접 입력</Radio>
              {solutionType.includes("기타") && <>
                <InputText style={{marginTop: 20}} value={etcSolution} onChange={handleEtcSolutionChange} placeholder="내용을 입력해주세요."/>
              </>}
            </RadioGroup>
            <CheckboxGroup
              label="의뢰인은 어떤 결과를 원하나요?(복수선택 가능)"
              values={desiredResult}
              onChange={setDesiredResult}
            >
              <Checkbox value="반드시 처벌받게 하고 싶음">반드시 처벌받게 하고 싶음</Checkbox>
              <Checkbox value="피해입은 부분을 금전적으로 보상받고 싶음">피해입은 부분을 금전적으로 보상받고 싶음</Checkbox>
              <Checkbox value="기타">
                직접입력
              </Checkbox>
              {desiredResult.includes("기타") && <>
                <InputText style={{marginTop: 20}} value={etcDesiredResult} onChange={handleEtcDesiredResultChange} placeholder="내용을 입력해주세요."/>
              </>}
            </CheckboxGroup>
            <RadioGroup
              label="변호사님에게 어떤 도움을 받고 싶나요?"
              value={desiredHelp}
              onChange={setDesiredHelp}
            >
              <Radio value="서류(내용증명 등) 작성">서류(내용증명 등) 작성</Radio>
              <Radio value="전화 상담 및 수임">전화 상담 및 수임</Radio>
              <Radio value="기타">직접 입력</Radio>
              {desiredHelp.includes("기타") && <>
                <InputText style={{marginTop: 20}} value={etcDesiredHelp} onChange={handleEtcDesiredHelpChange} placeholder="내용을 입력해주세요."/>
              </>}
            </RadioGroup>
            <InputTextWrapper>
              <SubTitle>연락 가능한 휴대폰 번호를 입력해주세요.</SubTitle>
              <Input style={{height: 64}} value={contact} onChange={onChangeContact} placeholder="0000000000 - 표기 없이 입력해주세요" />
            </InputTextWrapper>
            <SubmitButtonWrapper>
            <SubmitButtonContainer disabled={contact === ""} isActive={contact !== ""} onClick={() => onSubmit()}>
              <SubmitButton>신청하기</SubmitButton>
            </SubmitButtonContainer>
        </SubmitButtonWrapper>
      </>
    } else {
      return <>
              <LoginRequireWrapper>
                <LoginRequireContainer>
                  <LoginRequireTitle>
                  법대리 로그인 후<br/>기타 법률 서비스를 신청하실 수 있습니다.
                  </LoginRequireTitle>
                  <LoginRequireButton onClick={() => onClickLogin()}>
                    법대리 로그인 하기
                  </LoginRequireButton>
                </LoginRequireContainer>
              </LoginRequireWrapper>
            </>
    }
  }

  return (
    <Container>
      <SectionTop style={{ height: 380 }}>
        <ContentContainer style={{ width: "768px" }}>
          <TopWrapper>
            <TopTitle>기타 법률</TopTitle>
            <TopDesc>
            간편 법률 서비스에 원하는 서비스가 없으신가요? <br/>
            기타 법률 서비스에서 현재 상황을 알려주세요!
            </TopDesc>
          </TopWrapper>
          <TopImg src={IcJudge} />
        </ContentContainer>
      </SectionTop>
      <EtcServiceCenterSection>
        <ContentContainer style={{width: 630}}>
          <CommonCriminalContainer>
            <EtcTitleWrapper>
                <EtcTitleIcon src={IcCriminalCheck}/>
                <TitleDescWrapper>
                  <EtcTitleText>
                  {etcTitle()}
                  </EtcTitleText> 
                  <EtcDescText>
                  {etcDesc()}
                  </EtcDescText>
                </TitleDescWrapper>                
            </EtcTitleWrapper>
            <TitleBottomBorder/>
            {setLayout()}
          </CommonCriminalContainer>
        </ContentContainer>
      </EtcServiceCenterSection>
      <EtcServiceLandingSection>
        <ContentContainer style={{width: 693}}>
        <SelectDescWrapper>
            <SelectDescImg src={IcSelectDesc}/>
            <SelectDescText>
            법대리 간편 법률 서비스
            </SelectDescText>            
          </SelectDescWrapper>
          <LandingCardRowWrapper>
            <LandingCard onClick={() => history.push("/services/estate")}>
              <LandingCardImg src={IcEstate}/>
              <LandingCardText>
              보증금 반환
              </LandingCardText>
            </LandingCard>
            <LandingCard onClick={() => history.push("/services/estateHost")}>
              <LandingCardImg src={IcEstateOwner}/>
              <LandingCardText>
              임대인 서비스
              </LandingCardText>
            </LandingCard>
            <LandingCard onClick={() => history.push("/services/pilates")}>
              <LandingCardImg src={IcDumbbellSmall}/>
              <LandingCardText>
              헬스장 · 필라테스<br/> 환불
              </LandingCardText>
            </LandingCard>
            <LandingCard onClick={() => history.push("/services/golfTennis")}>
              <LandingCardImg src={IcGolf}/>
              <LandingCardText>
              골프 · 테니스<br/> 환불
              </LandingCardText>
            </LandingCard>            
          </LandingCardRowWrapper>
          <LandingCardRowWrapper>
            <LandingCard onClick={() => history.push("/services/noise")}>
              <LandingCardImg src={IcNoise}/>
              <LandingCardText>
              층간소음
              </LandingCardText>
            </LandingCard>
            <LandingCard onClick={() => history.push("/services/loan")}>
              <LandingCardImg src={IcMoney}/>
              <LandingCardText>
              대여금 반환
              </LandingCardText>
            </LandingCard>
            <LandingCard onClick={() => history.push("/services/criminal")}>
              <LandingCardImg src={IcChain}/>
              <LandingCardText>
              음주운전 / 마약
              </LandingCardText>
            </LandingCard>
            <LandingCard onClick={() => history.push("/services/accusation")}>
              <LandingCardImg src={IcBomb}/>
              <LandingCardText>
              모욕 / 명예훼손<br/>
              고소장 작성
              </LandingCardText>
            </LandingCard>             
          </LandingCardRowWrapper>
          <LandingCardRowWrapper>
            <LandingCard onClick={() => history.push("/services/certificationETC")}>
              <LandingCardImg src={IcCertification}/>
              <LandingCardText>
              기타내용증명
              </LandingCardText>
            </LandingCard>
            <LandingCard onClick={() => history.push("/services/etc")}>
              <LandingCardImg src={IcJudge}/>
              <LandingCardText>
              기타 법률
              </LandingCardText>
            </LandingCard>
          </LandingCardRowWrapper>
          <LandingCardBottomSpace />
          <SelectDescWrapper>
            <SelectDescImg src={IcLandingSearch}/>
            <SelectDescText>
            이런 서비스도 있어요
            </SelectDescText>            
          </SelectDescWrapper>
          <LandingOtherServiceCardRowWrapper>
            <LandingOtherServiceCard onClick={() => history.push("/services/civil")}>
              <LandingCardImg src={IcLandingCivil}/>
              <LandingOtherServiceCardTextWrapper>
                <LandingOtherServiceCardText>
                  민사소송
                </LandingOtherServiceCardText>
                <LandingOtherServiceCardDesc>
                  민사재판 변호사를 선임하세요.
                </LandingOtherServiceCardDesc>
              </LandingOtherServiceCardTextWrapper>
            </LandingOtherServiceCard>
            <LandingOtherServiceCard onClick={() => history.push("/services/commonCriminal")}>
              <LandingCardImg src={IcCommonCriminal}/>
              <LandingOtherServiceCardTextWrapper>
                <LandingOtherServiceCardText>
                  형사소송
                </LandingOtherServiceCardText>
                <LandingOtherServiceCardDesc>
                  형사재판 변호사를 선임하세요.
                </LandingOtherServiceCardDesc>
              </LandingOtherServiceCardTextWrapper>
            </LandingOtherServiceCard>
          </LandingOtherServiceCardRowWrapper>
        </ContentContainer>
      </EtcServiceLandingSection>
      <Footer/>
    </Container>
  );
};

export default EtcServiceCenter;
