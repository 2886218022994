import {
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
} from "antd";
import { useEffect } from "react";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper,
} from "../../Form.style";

const defaultExclusionInfo = {
  reason: null,
  amount: null,
};

const Step3 = ({ data, setData, onPrev, onNext, itemLayout }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log("values:", values);
    setData(values);
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        보증금 반환 정보
      </Title>

      {/* <Popover
          content={
            <div>
              상대에게 돈을 빌려준 내용에 대하여 작성하세요.
              <br />
              여러번에 걸쳐 빌려준 경우 아래의 빌려준 돈 정보
              <br /> 추가하기 버튼을 사용하세요.
            </div>
          }
          placement="bottom"
          trigger="click"
        >
          <QuestionCircleTwoTone />
        </Popover> */}

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          전세 계약금 중 돌려받은 일부 금액 또는 <br />
          월세를 밀려 지불하지 않은 금액을 포함하여,
          <br />
          청구시 제외할 금액을 작성해주세요.
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper style={{height:`90%`}}>
      <Form
        {...itemLayout.form}
        layout="horizontal"
        onFinish={onFinish}
        initialValues={data}
        form={form}
        style={{
          marginTop: 10,
          marginBottom: 20,
          width:"100%",
          textAlign:"left"
        }}
        requiredMark={false}
      >
        <Form.List name="exclusions">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      backgroundColor: "white",
                      padding: "15px",
                      marginBottom: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <MinusCircleOutlined onClick={() => remove(name)} />

                    <Form.Item
                      label="보증금 중에서 일정 금액을 제외하려는 이유"
                      name={[name, "reason"]}
                      rules={[
                        { required: true, message: "사유를 입력하세요." },
                      ]}
                    >
                      <Input placeholder="사유를 입력해주세요" />
                    </Form.Item>

                    <Form.Item
                      label="제외할 금액"
                      name={[name, "amount"]}
                      rules={[{ required: true, message: "금액을 입력하세요" }]}
                    >
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        addonBefore="금"
                        addonAfter="원"
                        controls={false}
                      />
                    </Form.Item>
                  </div>
                ))}

                <Form.Item {...itemLayout.button}>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add(defaultExclusionInfo);
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    청구 제외 금액 정보 추가하기
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>

        <Row justify="space-between">
          <Col span={6}>
            <Button
              type="primary"
              onClick={onPrev}
              style={{ width: "100%", maxWidth: "100px" }}
            >
              이전
            </Button>
          </Col>
          <Col span={6} offset={10} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", maxWidth: "100px" }}
            >
              다음
            </Button>
          </Col>
        </Row>
      </Form>
      </CaseDetailWrapper>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step3;
