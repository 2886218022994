import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";
import React, { useEffect } from "react";

const itemLayout = {
  form: {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  },
  button: {
    wrapperCol: { span: 24, offset: 0 },
  },
};

const defaultPaidInfo = {
  date: {
    value: null,
  },
  amount: null,
};

const Step2 = ({ data, setData, onPrev, onNext }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log("values:", values);
    setData(values);
    onNext();
  };

  // const onPaidDateUnknownChanged = (name) => {
  //   const { repayments } = form.getFieldsValue();
  //   repayments[name].paidDate.value = null;
  //   form.setFieldsValue({ repayments: repayments });
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Typography.Title
        level={4}
        style={{
          color: "#001529",
          marginBottom: 0,
          display: "inline",
          marginRight: 5,
        }}
      >
        상환금 정보
      </Typography.Title>
      {/* <Popover
        content={
          <div>
            상대방이 갚은 돈이 일부 있는 경우,
            <br /> 갚은 돈 정보 추가하기를 누르세요.
          </div>
        }
        placement="bottom"
        trigger="click"
      >
        <QuestionCircleTwoTone />
      </Popover> */}
      <div style={{ marginTop: 10 }}>
        <Typography.Text type="secondary">
          상대방에게 빌려준 돈 일부를 돌려받은 경우,
          <br /> 아래의 갚은돈 정보를 입력하세요. <br />
          여러번에 걸쳐 돌려받은 경우,
          <br /> 아래의 갚은 돈 정보 추가하기 버튼을 사용하세요.
        </Typography.Text>
      </div>
      <Divider style={{ marginTop: 10 }} />
      <Form
        {...itemLayout.form}
        layout="horizontal"
        form={form}
        initialValues={data}
        onFinish={onFinish}
        style={{
          marginTop: 20,
        }}
        requiredMark={false}
      >
        <Form.List name="repayments">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      backgroundColor: "white",
                      padding: "15px",
                      marginBottom: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <MinusCircleOutlined onClick={() => remove(name)} />

                    <Form.Item
                      label="갚은 날짜"
                      style={{
                        marginBottom: 0,
                        width: "calc(85% - 8px)",
                      }}
                    >
                      <Form.Item
                        name={[name, "date", "value"]}
                        rules={[
                          {
                            required: true,
                            message: "날짜를 선택해주세요",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder="날짜를 선택하세요"
                        />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label="갚은 금액"
                      name={[name, "amount"]}
                      rules={[{ required: true, message: "금액을 입력하세요" }]}
                    >
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        addonBefore="금"
                        addonAfter="원"
                        controls={false}
                      />
                    </Form.Item>
                  </div>
                ))}

                <Form.Item {...itemLayout.button}>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add(defaultPaidInfo);
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    갚은 돈 정보 추가하기
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Row justify="space-between">
          <Col span={6}>
            <Button
              type="primary"
              onClick={onPrev}
              style={{ width: "100%", maxWidth: "100px" }}
            >
              이전
            </Button>
          </Col>
          <Col span={6} offset={10} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", maxWidth: "100px" }}
            >
              다음
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Step2;
