import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const ProtectionRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        임차권 등기명령
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="임대차 계약 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="계약 일자" labelStyle={labelStyle}>
            {serviceData.contract.date}
          </Descriptions.Item>
          <Descriptions.Item label="보증금" labelStyle={labelStyle}>
            {serviceData.contract.deposit.amount.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="보증금 지급 일자" labelStyle={labelStyle}>
            {serviceData.contract.deposit.date}
          </Descriptions.Item>
          <Descriptions.Item label="월세 유무" labelStyle={labelStyle}>
            {serviceData.contract.monthlyRent.exist === "n" ? "없음" : "있음"}
          </Descriptions.Item>
          {serviceData.contract.monthlyRent.amount ? (
            <Descriptions.Item label="월세 금액" labelStyle={labelStyle}>
              {serviceData.contract.monthlyRent.amount.toLocaleString()}
            </Descriptions.Item>
          ) : null}
          {serviceData.contract.monthlyRent.date ? (
            <Descriptions.Item label="월세 지급일" labelStyle={labelStyle}>
              {"매달 " + serviceData.contract.monthlyRent.date + " 일"}
            </Descriptions.Item>
          ) : null}
        </Descriptions>

        <Descriptions
          title="임대차 계약 종료 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="계약 종료 일자" labelStyle={labelStyle}>
            {serviceData.termination.date}
          </Descriptions.Item>
          <Descriptions.Item label="계약 종료 사유" labelStyle={labelStyle}>
            {serviceData.termination.reason === "expire"
              ? "계약 만료"
              : "계약 취소"}
          </Descriptions.Item>
          {serviceData.termination.reasonDesc ? (
            <>
              <Descriptions.Item label="계약 종료 사유 설명" labelStyle={labelStyle}>
                {serviceData.termination.reasonDesc}
              </Descriptions.Item>
            </>
          ) : null}
          {serviceData.termination.notification ? (
            <>
              <Descriptions.Item label="계약 종료 통보 여부" labelStyle={labelStyle}>
                {serviceData.termination.notification.exist === "y"
                  ? "예"
                  : "아니오"}
              </Descriptions.Item>
              {serviceData.termination.notification.exist === "y" ? (
                <>
                  <Descriptions.Item label="계약 종료 통보 일자" labelStyle={labelStyle}>
                    {serviceData.termination.notification.date}
                  </Descriptions.Item>
                  <Descriptions.Item label="계약 종료 기록 유무" labelStyle={labelStyle}>
                    {serviceData.termination.notification.recordYn === "y"
                      ? "예"
                      : "아니오"}
                  </Descriptions.Item>
                </>
              ) : null}
            </>
          ) : null}
        </Descriptions>

        {serviceData.exclusions.map((exclusion, idx) => (
          <Descriptions
            key={idx}
            title={idx === 0 ? "보증금 제외 금액 정보" : null}
            bordered
            column={reactiveColumn}
            size={"small"}
            style={{ marginBottom: 15 }}
          >
            {exclusion.reason &&
              <>
                <Descriptions.Item label="제외 이유" labelStyle={labelStyle}>
                  {exclusion.reason ? exclusion.reason : "없음"}
                </Descriptions.Item>
                <Descriptions.Item label="금액" labelStyle={labelStyle}>
                  {exclusion.amount.toLocaleString()}
                </Descriptions.Item>
              </>
            }
            {!exclusion.reason &&
              <>
                <Descriptions.Item label="제외 이유" labelStyle={labelStyle}>
                  {exclusion}
                </Descriptions.Item>
              </>
            }
          </Descriptions>
        ))}

        <Descriptions
          title="기타 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          {serviceData.insurance && serviceData.insurance.registYn ? (
            <Descriptions.Item label="전세보증가입 여부" labelStyle={labelStyle}>
              {serviceData.insurance.registYn === "y" ? "예" : "아니오"}
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item label="보증금 지급 기록 유무" labelStyle={labelStyle}>
            {serviceData.depositHistory.exist === "y" ? "예" : "아니오"}
          </Descriptions.Item>
          <Descriptions.Item label="집주인의 주소" labelStyle={labelStyle}>
            {serviceData.lendlord.addressYn === "y" ? "예" : "아니오"}
          </Descriptions.Item>
          <Descriptions.Item label="집주인의 주민등록번호" labelStyle={labelStyle}>
            {serviceData.lendlord.ssnYn === "y" ? "예" : "아니오"}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.description
              ? serviceData.description.split("\n").map((line, idx) => {
                return (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                );
              })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
          <Descriptions.Item label="프로모션" labelStyle={labelStyle}>
            {serviceData.promotionCode}
          </Descriptions.Item>
        </Descriptions>

        {/* <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">첨부 파일</div>
          </div>
          {serviceData.attachments.map((attachment) => (
            <Image
              key={attachment}
              width={75}
              height={75}
              src={attachment}
              preview={{ zIndex: 10000 }}
            />
          ))}
        </div> */}
      </div>
    </>
  );
};

export default ProtectionRequest;
