import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const NoiseCertificationRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };
  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        층간 소음 내용증명
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="층간 소음 관련 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="소음 시작 일자" labelStyle={labelStyle}>
            {serviceData.noise.date}
          </Descriptions.Item>
          {/* <Descriptions.Item
            label="층간소음이 일어나는 주소"
            labelStyle={labelStyle}
          >
            {`${serviceData.noise.information.address1} \n ${serviceData.noise.information.address1}`}
          </Descriptions.Item> */}
          <Descriptions.Item label="소음의 종류" labelStyle={labelStyle}>
            <ui>
              {serviceData.noise.information.noiseType.map((option, index) => (
                <li key={index}>{option}</li>
              ))}
            </ui>
          </Descriptions.Item>
          <Descriptions.Item label="손해배상 청구 유무" labelStyle={labelStyle}>
            {serviceData.noise.compensation.exist === "n"
              ? "경고로만 그침"
              : "예"}
          </Descriptions.Item>
          {serviceData.noise.compensation.reason ? (
            <Descriptions.Item label="손해배상 이유" labelStyle={labelStyle}>
              {serviceData.noise.compensation.reason}
            </Descriptions.Item>
          ) : null}
          {serviceData.noise.compensation.amount ? (
            <Descriptions.Item label="손해배상액" labelStyle={labelStyle}>
              {serviceData.noise.compensation.amount.toLocaleString()}
            </Descriptions.Item>
          ) : null}
          {serviceData.noise.compensation.date ? (
            <Descriptions.Item label="지급기한" labelStyle={labelStyle}>
              {serviceData.noise.compensation.date}
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item
            label="계좌 정보 작성 여부"
            labelStyle={labelStyle}
          >
            {serviceData.noise.compensation.isAccount === "n" ? "아니요" : "예"}
          </Descriptions.Item>
          {serviceData.noise.compensation.name ? (
            <Descriptions.Item label="예금주" labelStyle={labelStyle}>
              {serviceData.noise.compensation.name}
            </Descriptions.Item>
          ) : null}
          {serviceData.noise.compensation.bank ? (
            <Descriptions.Item label="은행명" labelStyle={labelStyle}>
              {serviceData.noise.compensation.bank}
            </Descriptions.Item>
          ) : null}
          {serviceData.noise.compensation.date ? (
            <Descriptions.Item label="계좌번호" labelStyle={labelStyle}>
              {serviceData.noise.compensation.accountNumber}
            </Descriptions.Item>
          ) : null}
        </Descriptions>

        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="발신인" labelStyle={labelStyle}>
            {serviceData.user.information.sender}
          </Descriptions.Item>
          {/* <Descriptions.Item label="발신인 주소" labelStyle={labelStyle}>
            {`${serviceData.user.information.senderAddress1} \n ${serviceData.user.information.senderAddress2}`}
          </Descriptions.Item> */}
          <Descriptions.Item label="발신인 전화번호" labelStyle={labelStyle}>
            {serviceData.user.information.senderPhoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="수신인" labelStyle={labelStyle}>
            {serviceData.user.information.receiver}
          </Descriptions.Item>
          {/* <Descriptions.Item label="수신인 주소" labelStyle={labelStyle}>
            {`${serviceData.user.information.receiverAddress1} \n ${serviceData.user.information.receiverAddress2}`}
          </Descriptions.Item> */}
          <Descriptions.Item label="수신인 전화번호" labelStyle={labelStyle}>
            {serviceData.user.information.receiverPhoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.description
              ? serviceData.description.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
          <Descriptions.Item label="프로모션" labelStyle={labelStyle}>
            {serviceData.promotionCode}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};

export default NoiseCertificationRequest;
