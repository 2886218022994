import styled from "@emotion/styled";

export const PopoverContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const PopoverContentContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: white;
    color: #ffffff;
`;

export const PopoverContentCenterIcon = styled.img`
    width: 30px;
    height: 30px;
    margin-top: 36.5px;
`

export const PopoverContentBodyText = styled.div`
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 160% */
    margin-top: 10px;
`

export const PopoverContentBodyDescText = styled.div`
    color: #AEAEAE;
    width: 256px;
    text-align: center;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 146.154% */
    margin-top: 10px;
`


export const PopoverContentBottomWrapper = styled.div`
    margin-top: 33.5px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
`;

export const PopoverContentBottomDividerWrapper = styled.div`
    width: 1px;
    height: 100%;
    background: #CACBCD;
    display: flex;
    align-items: center;
`

export const PopoverContentBottomDivider = styled.div`
    width: 1px;
    height: 24px;
    background: #B2B3B5;
`

export const PopoverContentBottomButtonWrapper = styled.div`
    flex: 1; 
    color: ${(props) => (props.isConfirm ? "#FFF" : "#000")};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: ${(props) => (props.isConfirm ? "#3A7EFC" : "#CACBCD")};
`;

export const PopoverContentBottomButton = styled.div`
    flex: 1;
    text-align: center;
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;