import { Button, Result } from "antd";
import { dbService } from "fbase";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import axios from "axios";
import { getServiceTypeTitle } from "components/mypage/MyCaseList";
import ReactLoading from 'react-loading';
import { 
  PaySuccessLoadingContainer,
  PaySuccessLoadingWrapper,
  PaySuccessLoadingDesc
} from "./Pay.style";
import { updateUserNoti } from "components/caseDetail/repository/CaseSubmissionRepository";

const PaySuccess = ({ loggedInUser }) => {
  const { requestId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const updateRequestStatus = async () => {      
      setIsLoading(true);
      const isDocExist = await isAlreadyPaid();
      if (isDocExist) {
        setIsLoading(false);
        return;
      }
      await dbService
        .collection("requests_temp")
        .doc(requestId)
        .update({ status: "paid" });

      let userName = "";

      const getUserDataResult = await dbService
      .collection("users")
      .where("__name__", "==", loggedInUser.uid)
      .get()
      
      
      if (getUserDataResult.size > 0) {
        const userData = getUserDataResult.docs[0].data();
        userName = userData.properties.nickname;
      }
      
      const requestData = await createLawyerCase(userName);
      let ammount = ""
      let caseName = ""
      let laywerName = ""
      if (requestData !== null) {
        const docRef = doc(dbService, "requests_temp", requestId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const clientData = { id: docSnap.id, ...docSnap.data()};
          const proposalData = await getLawyerProposal(clientData.pendingLawyer.proposalId);
          ammount = proposalData.suggested_amount
          laywerName = proposalData.lawyer_name
          caseName = getServiceTypeTitle(clientData.serviceType);
        }
      }
      await sendWebhook(requestId, loggedInUser.uid, userName, ammount, caseName, laywerName);
      setIsLoading(false);
    };

    updateRequestStatus();
  }, []);

  const isAlreadyPaid = async () => {
    return dbService
        .collection("requests_temp")
        .doc(requestId)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            const clientData = snapshot.data();
            if (clientData.status === "paid" || clientData.status === "processing" || 
            clientData.status === "requested"  || clientData.status === "done") {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        })
  }

  async function createLawyerCase(userName) {
    // 현재 doc ID를 가져오고
    const docRef = doc(dbService, "requests_temp", requestId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const requestData = { id: docSnap.id, ...docSnap.data()};
      console.log("requestData...", requestData);
      // 변호사에게 넣어줌.
      const host = process.env.REACT_APP_FIREBASE_FUNCTION_CREATE_LAWYER_CASE_URL      
      let memoData = []
      if (requestData.memoListData !== undefined) {
        memoData = requestData.memoListData[requestData.pendingLawyer.id] ?? [];
      }
      const url = host;
      const body = { chat_id: null,
              client_id: loggedInUser.uid,
              client_request_id: requestId,
              client_doc_list: [],
              lawyer_document_list: [],
              client_req_doc_list: [],
              lawyer_document_opinion: [],
              lawyer_id: requestData.pendingLawyer.id,
              mandateDate: new Date().getTime(),
              mandator: requestData.mandator,
              memos: memoData,
              clientName: userName,
              serviceType: requestData.serviceType,
              service_data: requestData.service_data,
              status: "accept_case"
            };
      try {
        const res = await axios.post(url, body);
        const resData = res.data;
        await dbService
        .collection("requests_temp")
        .doc(requestId)
        .update({ accepted: {
          lawyer_name: requestData.pendingLawyer.name,
          lawyer_id: requestData.pendingLawyer.id,
          chat_id: resData.chatId,
          lawyer_case_id: resData.lawyerCaseId,
          proposal_id: requestData.pendingLawyer.proposalId,
        } });

        let userName = "";
        const getUserDataResult = await dbService
        .collection("users")
        .where("__name__", "==", loggedInUser.uid)
        .get()
        
        if (getUserDataResult.size > 0) {
          const userData = getUserDataResult.docs[0].data();
          userName = userData.properties.nickname;
        }

        await updateUserNoti("paid", loggedInUser.uid, requestData.mandator, userName, 
                            requestData.pendingLawyer.id, requestData.id, resData.lawyerCaseId)
        return requestData;
      } catch (e) {
        console.error(e);
        return null;
      }      
    }  
  }

  async function sendWebhook(caseId, userId, userName, ammount, caseName, laywerName) {
    const webhookUrl = process.env.REACT_APP_DISCORD_PAY_SUCCESS_WEBHOOK_DOMAIN;
    const message = {
      content: `결제가 완료되었습니다. 
      사건 이름: ${caseName} 
      유저: ${userName} / 담당 변호사: ${laywerName}
      결제 금액: ${ammount}
      사건ID: ${caseId} userId: ${userId}`
    };

    try {
      const { response } = await axios.post(webhookUrl, message);
    } catch (e) {
    }
  }

  async function getLawyerProposal(proposalId) {
    if (proposalId === undefined) {
      return;
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_LAYWER_PROPOSAL_URL
    const query = "?proposalId=" + proposalId;
    const url = host + query;
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  if (isLoading) 
    return <PaySuccessLoadingContainer>
      <PaySuccessLoadingWrapper>
        <ReactLoading type={"spin"} color={"#3a7efc"} height={64} width={64}/>
        <PaySuccessLoadingDesc>
        결제가 완료될 때까지 기다려 주세요.<br/>
        중간에 창을 끄면<br/>
        결제가 제대로 이루어지지 않습니다.
        </PaySuccessLoadingDesc>
        <div style={{height: 64}}></div>
      </PaySuccessLoadingWrapper>
     </PaySuccessLoadingContainer>; // loading screen

  return (
    <div
      style={{
        backgroundColor: "white",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginBottom: 10,
        borderRadius: 5,
        minHeight: "100%",
      }}
    >
      <Result
        status="success"
        title="결제가 완료되었습니다!"
        //   subTitle="법대로 전문 변호사들이 검토 후 연락드리겠습니다."
        extra={[
          <Button key="home">
            <Link to="/">홈으로</Link>
          </Button>,
        ]}
      />
    </div>
  );
};

export default PaySuccess;
