import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Popover,
  Radio,
  Row,
} from "antd";
import { useEffect } from "react";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper,
} from "../../Form.style";

const Step2 = ({ data, setData, onPrev, onNext, itemLayout }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log("values:", values);
    setData(values);
    onNext();
  };

  // const onDueDateUnknownChanged = (name) => {
  //   const { loans } = form.getFieldsValue();
  //   loans[name].dueDate.value = null;
  //   form.setFieldsValue({ loans });
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        임대차 계약 종료 정보
      </Title>

      {/* <Popover
          content={
            <div>
              상대에게 돈을 빌려준 내용에 대하여 작성하세요.
              <br />
              여러번에 걸쳐 빌려준 경우 아래의 빌려준 돈 정보
              <br /> 추가하기 버튼을 사용하세요.
            </div>
          }
          placement="bottom"
          trigger="click"
        >
          <QuestionCircleTwoTone />
        </Popover> */}

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          임대차 계약이 종료된 일자와 계약종료 사유를 선택해주세요
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          form={form}
          style={{
            marginTop: 10,
            marginBottom: 20,
            width:"100%",
            textAlign:"left"
          }}
          requiredMark={false}
        >
          <Form.Item
            label="계약 종료 일자"
            name={["termination", "date"]}
            rules={[
              {
                required: true,
                message: "날짜를 선택해주세요",
              },
            ]}
            // style={{ width: "calc(85% - 8px)" }}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="날짜를 선택하세요"
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                계약 종료 사유
                <Popover
                  content={
                    <div>
                      계약 해지의 경우, 맨 마지막 페이지의 상세내용에
                      <br /> 해지 사유를 작성해주세요
                    </div>
                  }
                  placement="bottom"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["termination", "reason"]}
            rules={[
              { required: true, message: "계약 종료 사유를 선택해주세요." },
            ]}
            // style={{ width: "calc(85% - 8px)" }}
          >
            <Radio.Group>
              <Radio.Button value="expire">기간만료</Radio.Button>
              <Radio.Button value="cancel">계약해지</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={<>계약 종료 통보 여부</>}
            name={["termination", "notification", "exist"]}
            rules={[
              {
                required: true,
                message: "계약 종료 통보 여부를 선택해주세요.",
              },
            ]}
            // style={{ width: "calc(85% - 8px)" }}
          >
            <Radio.Group>
              <Radio.Button value="y">예</Radio.Button>
              <Radio.Button value="n">아니오</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return (
                prevValues.termination.notification.exist !==
                currentValues.termination.notification.exist
              );
            }}
          >
            {() => (
              <>
                <Form.Item
                  label="종료 통보 일자"
                  style={{ marginBottom: 0 }}
                  hidden={
                    form.getFieldValue([
                      "termination",
                      "notification",
                      "exist",
                    ]) === "n"
                  }
                >
                  <Form.Item
                    name={["termination", "notification", "date"]}
                    style={{
                      width: "100%",
                      // maxWidth: 150,
                      display: "inline-block",
                    }}
                    rules={
                      form.getFieldValue([
                        "termination",
                        "notification",
                        "exist",
                      ]) === "n"
                        ? []
                        : [
                            {
                              required: true,
                              message: "종료 통보 일자를 선택해주세요",
                            },
                          ]
                    }
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="날짜를 선택하세요"
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      종료 통보 기록유무
                      <Popover
                        content={
                          <div>
                            계약종료 통보내용과 임대인의 확인내용이
                            <br />
                            문자나 녹취 등으로 반드시 있어야 합니다.
                          </div>
                        }
                        placement="bottom"
                        trigger="click"
                      >
                        <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                      </Popover>
                    </>
                  }
                  style={{ marginBottom: 0 }}
                  hidden={
                    form.getFieldValue([
                      "termination",
                      "notification",
                      "exist",
                    ]) === "n"
                  }
                >
                  <Form.Item
                    name={["termination", "notification", "recordYn"]}
                    style={{
                      width: "calc(85% - 8px)",
                      // maxWidth: 150,
                      display: "inline-block",
                    }}
                    rules={
                      form.getFieldValue([
                        "termination",
                        "notification",
                        "exist",
                      ]) === "n"
                        ? []
                        : [
                            {
                              required: true,
                              message: "종료 통보 기록유무를 선택해주세요",
                            },
                          ]
                    }
                  >
                    <Radio.Group>
                      <Radio.Button value="y">있음</Radio.Button>
                      <Radio.Button value="n">없음</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Form.Item>
              </>
            )}
          </Form.Item>

          <Row justify="space-between">
            <Col span={6}>
              <Button
                type="primary"
                onClick={onPrev}
                style={{ width: "100%", maxWidth: "100px" }}
              >
                이전
              </Button>
            </Col>
            <Col span={6} offset={10} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                다음
              </Button>
            </Col>
          </Row>
        </Form>
        </CaseDetailWrapper>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step2;
