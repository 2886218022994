import styled from "@emotion/styled";
import {
  DatePicker,
} from "antd";


export const ApplyServiceSubmitDataSection = styled.section`
  display: flex;
  padding: 50px 0px 100px 0px;
  justify-content: center;
  align-items: center;
  gap: px;

  @media screen and (max-width: 768px) {
    align-items: center;
    padding: 0px 0px 50px 0px;
  }
`;

export const ApplyServiceSubmitDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  align-self: stretch;
  width: 840px;
  @media screen and (max-width: 768px) {
    align-items: center;
    width: 100%;
  }
`
export const ApplyServiceSubmitDataInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 500px;
  gap: 108px;
  @media screen and (max-width: 768px) {
    width: 100%;
    gap: 50px;
    padding: 0px 37.5px;
  }
  `

export const ApplyServiceSubmitDataInfoDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 15px;
`

export const ApplyServiceSubmitDataInfoDetailTitle = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
`

export const ApplyServiceInputText = styled.input`
    border-radius: 6px;
    border: 1px solid #D3D3D3;
    background: #FFF;    
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    width: 100%;
    background: #ffffff;
    padding: 15px 25px;
    &::placeholder {
        font-size: 18px;
        color: #b9b9b9;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 24px; /* 171.429% */
      &::placeholder {
        color: #b9b9b9;
        font-size: 14px;
    }
  }
`

export const ApplyServiceDatePicker = styled(DatePicker)`
  width: 100%;
  height: 59px;
  border-radius: 6px;
  border: 1px solid #D3D3D3;
  background: #FFF;
  font-size: 18px;
  &::placeholder {
    color: #B9B9B9;
    font-family: Pretendard;
    font-size: 18px;
  }
`