import { useHistory, useLocation } from "react-router-dom";

import IcServiceMain from "assets/svg/IcPayServiceMain.svg";

import {
  Container,
} from "components/Service.style";

import { useEffect, useState } from "react";
import {
  EventDetailDataContainer,
  EventDetailDataEachContainer,
  EventDetailDataEachContent,
  EventDetailDataEachTitle,
  EventDetailDesc,
  EventDetailDrawer,
  EventDetailFooterBtn,
  EventDetailFooterContainer,
  EventDetailFooterTitle,
  EventHeaderContainer,
  EventHeaderImg,
  EventHeaderSection,
  EventHeaderTitle
} from "../../EventStyle.style";
import { removeBrTag } from "routes/Landing";

import { EventSubmitNew } from "./EventSubmitNew";

import { useRecoilValue } from "recoil";
import { to3DataState } from "states/atoms";

const EventPayCertificate = ({ loggedInUser }) => {
  const to3Data = useRecoilValue(to3DataState);
  const history = useHistory();
  const location = useLocation();

  const [showApply, setShowApply] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  if (to3Data === null) {
    return <></>
  }

  const showDrawer = () => {
    setShowApply(true);
  };

  const onClose = () => {
    setShowApply(false);
  };

  const eventServiceData = () => {
    return to3Data;
  }

  const refundAmount = () => {
    const amount = to3Data.billingAmount
    if (amount) {
      return Number(amount).toLocaleString() + " 원"
    } else {
      return "원"
    }
  }

  const getOpponentName = () => {
    const opponentName = to3Data.opponentName
    if (opponentName) {
      return opponentName
    } else {
      return ""
    }
  }

  const getOpponentData = () => {
    const address = to3Data.opponentAddress
    const opponentPhone = to3Data.opponentPhone
    console.log("addressaddress", address, "opponentPhone", opponentPhone);
    if (address !== "" && opponentPhone !== "") {
      return `${opponentPhone} \n ${address}`
    } else if (address !== "") {
      return address
    } else if (opponentPhone !== "") {
      return opponentPhone
    } else {
      return ""
    }
  }

  return (
    <Container>
      <EventHeaderSection>
        <EventHeaderContainer>
          <EventHeaderImg src={IcServiceMain} />
          <EventHeaderTitle>
            임금체불 해결하기
          </EventHeaderTitle>
          <EventDetailDesc>
            법대리 내용증명 서비스를 이용하신 고객님들 중 90% 이상이 임금체불 문제를 해결했어요.
            법대리 내용증명을 통해 임금체불 문제를 손쉽게 해결해보세요
          </EventDetailDesc>
        </EventHeaderContainer>
      </EventHeaderSection>
      <EventDetailDataContainer>
        <EventDetailDataEachContainer>
          <EventDetailDataEachTitle>
            돌려 받을 임금 총액
          </EventDetailDataEachTitle>
          <EventDetailDataEachContent>
            {refundAmount()}
          </EventDetailDataEachContent>
        </EventDetailDataEachContainer>
        <EventDetailDataEachContainer>
          <EventDetailDataEachTitle>
            회사 또는 대표자의 이름
          </EventDetailDataEachTitle>
          <EventDetailDataEachContent>
            {getOpponentName()}
          </EventDetailDataEachContent>
        </EventDetailDataEachContainer>
        <EventDetailDataEachContainer>
          <EventDetailDataEachTitle>
            회사 또는 대표자의 <br />
            연락처 / 주소
          </EventDetailDataEachTitle>
          <EventDetailDataEachContent style={{ lineHeight: "36px" }}>
            {removeBrTag(getOpponentData())}
          </EventDetailDataEachContent>
        </EventDetailDataEachContainer>
        <EventDetailDesc>
        회사로부터 임금 체불, 퇴직금 미지급 등의 피해를 입는 근로자들이 최근 굉장히 많아지고 있어요. 
        임금 체불 관련 피해가 발생한 경우 대응 방안은 민사 소송과 형사 소송이 있고, 
        소송으로 가기 전에 전문 변호사가 발송하는 내용 증명을 통해 손쉽게 해결할 수 있어요.
        </EventDetailDesc>
      </EventDetailDataContainer>
      <EventDetailFooterContainer>
        <EventDetailFooterTitle>
          위 정보로 전문 변호사가<br />무료로 검토해 드릴게요.
        </EventDetailFooterTitle>
        <EventDetailFooterBtn onClick={() => showDrawer()}>
          무료 검토 신청하기
        </EventDetailFooterBtn>
      </EventDetailFooterContainer>
      <EventDetailDrawer
        placement={"bottom"}
        closable={false}
        onClose={onClose}
        open={showApply}
        key={"bottom"}
      >
        <EventSubmitNew
          eventServiceData={eventServiceData()}
          serviceType={"3o3_pay"} />
      </EventDetailDrawer>
    </Container>
  )
};

export default EventPayCertificate;