import styled from "@emotion/styled";

export const EventSubmitFooterSection = styled.section`
  display: flex;
  padding: 50px 0px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: ${(props) => (props.active ? "#F5F5F5" : "#FFFFFF")};
`

export const EventSubmitFooterTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const EventSubmitFooterText = styled.div`
  width: 300px;
  color: ${(props) => (props.active ? "#1F2E3D" : "#3A7EFC")};
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
`

export const EventFormSection = styled.section`
  display: flex;
  width: 100%;
  padding-bottom: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
`

export const EventFormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

export const EventFormEachContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 37.5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
`;

export const EventFormNamePhoneTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`

export const EventFormNamePhoneTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
`

export const EventFormPhoneDesc = styled.div`
  color: #3A7EFC;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

export const EventFormEachTitle = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
`

export const EventFormEachDesc = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

export const EventInputTextArea = styled.textarea`
    width: 100%;
    border-radius: 6px;
    height: 150px;
    border: 1px solid #D3D3D3;
    background: #FFF;    
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    width: 100%;
    background: #ffffff;
    padding: 15px 25px;
    &::placeholder {
        color: #b9b9b9;
    }
`


export const EventSubmitSection = styled.section`
display: flex;
width: 100%;
padding: 80px 0px;
justify-content: center;
align-items: center;
background: ${(props) => (props.active ? "#3A7EFC" : "#B9B9B9")};
@media screen and (max-width: 768px) {
  padding: 50px 0px;
}
`;

export const EventSubmitContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 50px;
`;

export const EventSubmitTextContainer = styled.div`
display: flex;
width: 710px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 15px;
@media screen and (max-width: 768px) {
  width: 100%;
}
`

export const EventSubmitTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 139.13% */
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 42px;
  }
`

export const EventSubmitDescContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 15px;
@media screen and (max-width: 768px) {
  gap: 10px;
  flex-direction: column;
}
`

export const EventSubmitDesc = styled.div`
  width: 300px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

export const EventSubmitBtn = styled.button`
display: flex;
align-items: center;
justify-content: center;
padding: 35px 98px;
margin: 0 auto;
border-radius: 20px;
background: ${(props) => (props.active ? "#ffffff" : "#D9D9D9")};
color: ${(props) => (props.active ? "#3A7EFC" : "#B9B9B9")};
text-align: center;
font-family: Pretendard;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 100% */
cursor: pointer;
border-radius: 20px;
box-shadow: ${(props) => (props.active ? "0px 10px 10px 0px rgba(0, 0, 0, 0.25)" : "none")};
border: none;
/* :disabled {
  background: gray;
} */
@media screen and (max-width: 768px) {
  padding: 23px 58.5px;
  font-size: 24px;
  line-height: 34px;
}
`

export const EventSuccessSection = styled.section`
  display: flex;
  width: 100%;
  padding: 50px 37.5px 50px 37.5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-shrink: 0;
`

export const EventSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  flex-shrink: 0;
`

export const EventSuccessImg = styled.img`
`

export const EventSuccessTitle = styled.div`
  color: #1F2E3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const EventSuccessDesc = styled.div`
color: #1F2E3D;
text-align: center;
font-family: Pretendard;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px; /* 130% */
`

export const EventSuccessSubDesc = styled.div`
color: #1F2E3D;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
`

export const EventSuccessGoBack = styled.div`
color: #B9B9B9;
text-align: center;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
cursor: pointer;
`

export const EventSection = styled.section`
   display: flex;
   width: 100%;
   height: 100vh;
   flex-direction: column;
   flex: 1;
   justify-content: space-between;
 `;

export const EventContentContainer = styled.section`
   display: flex;
   width: 100%;
   flex-direction: column;
   justify-content: center;
 `;

export const FooterContainer = styled.div`
display: flex;
flex-direction: column;
  padding: 0px 37.5px;
  padding-bottom: 50px;
  gap: 10px;
`;

export const EventFooterBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0px;
  margin: 0 auto;
  border-radius: 20px;
  background: ${(props) => (props.active ? "#3A7EFC" : "#F5F5F5")};
  color: ${(props) => (props.active ? "#FFFFFF" : "#B9B9B9")};
  text-align: center;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 100% */
  cursor: pointer;
  border-radius: 15px;
  border: none;
  width: 100%;
/* :disabled {
  background: gray;
} */
@media screen and (max-width: 768px) {
  padding: 13px 58.5px;
  font-size: 24px;
  line-height: 34px;
}
`

export const EventModalSubmitContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 50px;
`

export const EventModalSubmitTitle = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
`

export const EventModalSubmitBtnContainer = styled.div`
  padding: 0px 37.5px;
  margin-top: 32px;
`

export const EventModalSubmitBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0px;
  margin: 0 auto;
  border-radius: 20px;
  background: ${(props) => (props.active ? "#3A7EFC" : "#F5F5F5")};
  color: ${(props) => (props.active ? "#fff" : "#B9B9B9")};
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 100% */
  cursor: pointer;
  border-radius: 15px;
  border: none;
  width: 100%;
/* :disabled {
  background: gray;
} */
@media screen and (max-width: 768px) {
  /* padding: 13px 58.5px; */
  font-size: 24px;
  line-height: 34px;
}
`