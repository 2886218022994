import { Container } from "components/Service.style"
import { useEffect, useState } from "react"
import { EventFooterBtn, EventSection, EventSuccessContainer, EventSuccessDesc, EventSuccessGoBack, EventSuccessImg, EventSuccessSection, EventSuccessSubDesc, EventSuccessTitle, FooterContainer } from "./EventSubmit.style"
import Ic3o3Success from "assets/svg/Ic3o3Success.svg";
import { LandingTo3o3Page } from "util/Handler";

import { useRecoilState } from "recoil";
import { to3DataState } from "states/atoms";

export const EventSuccess = (props) => {
  const [to3Data, setTo3Data] = useRecoilState(to3DataState);
  const onClickBtn = async () => {
    const isShareSupported = () => navigator.share ?? false;
    const url = 'https://app.lab.3o3.co.kr/by-law'
    if (isShareSupported()) {
      await navigator.share({
        url: url,
      })
        .then(() => console.log('공유 성공'))
        .catch((error) => console.log('공유 실패', error));
    } else {
      const isClipboardSupprted = () => navigator.clipboard ?? false;
      if (isClipboardSupprted()) {
        try {
          await navigator.clipboard.writeText(url);
          alert("URL이 복사되었습니다.");
        } catch (e) {
          console.log("e", e);
        }
      }
    }
  }

  useEffect(() => {
    setTo3Data(null);
  }, []);

  return (
    <Container>
      <EventSection>
        <EventSuccessSection>
          <EventSuccessContainer>
            <EventSuccessImg src={Ic3o3Success} />
            <EventSuccessTitle>
              신청이 완료되었어요.
            </EventSuccessTitle>
            <EventSuccessDesc>
              법대리 전문 변호사가 꼼꼼하게<br />
              검토 후 연락 드릴게요.
            </EventSuccessDesc>
            <EventSuccessSubDesc>
              혹시 주변에 비슷한 문제로 고민중인 분이 계신가요?
              막막한 법률 문제도 쉽게 해결 가능한 법대리 서비스를 주변에 공유해 주세요.
              법대리는 고민을 가지고 계신 의뢰인들을 위해 진입 장벽을 낮추어 합리적으로 법률 서비스의 대중화에 앞장서고 있어요.
            </EventSuccessSubDesc>
          </EventSuccessContainer>
        </EventSuccessSection>
        <FooterContainer>
          <EventFooterBtn active={true} onClick={() => onClickBtn()}>
            주변에 공유하기
          </EventFooterBtn>
          <EventSuccessGoBack onClick={() => { LandingTo3o3Page() }}>
            나중에 하기
          </EventSuccessGoBack>
        </FooterContainer>
      </EventSection>
    </Container>
  )
}