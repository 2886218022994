import { useEffect, useState } from "react";
import IcMonthlyPayOrderServiceMain from "assets/svg/IcMonthlyPayOrderServiceMain.svg";
import IcMonthlyPayOrderServiceMainMobile from "assets/svg/IcMonthlyPayOrderServiceMainMobile.svg";

import { useRecoilValue } from "recoil";
import { userData, redirectEtcUri } from "../../../../states/atoms";
import moment from "moment";

import {
  Container,
} from "../../../Service.style";
import {
  ApplyServiceDescContainer,
  ApplyServiceDescSection,
  ApplyServiceDescText,
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
} from "../../../home/ServiceCenter.style";

import {
  DatePicker,
} from "antd";

import { RadioGroup, Radio } from "../../../home/CommonCriminal/RadioBox";
// import { CheckboxGroup, Checkbox } from "../../../home/CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { useRecoilState } from 'recoil';

import {
  Input,
  Row,
  // Radio
} from "antd";

import { removeBrTag } from "routes/Landing";
import { ApplyServiceSubmit } from "../ApplyServiceSubmit";
import { ApplyServiceInputText, ApplyServiceDatePicker, ApplyServiceSubmitDataContainer, ApplyServiceSubmitDataInfoContainer, ApplyServiceSubmitDataInfoDetailContainer, ApplyServiceSubmitDataInfoDetailTitle, ApplyServiceSubmitDataSection } from "./ApplyService.style";
import { EventFormEachDesc, EventInputTextArea, EventSubmitFooterSection, EventSubmitFooterText, EventSubmitFooterTextContainer } from "components/event/3o3/order/EventSubmit.style";

const { useHistory } = require("react-router-dom");

const ApplyMonthlyPayOrderService = ({ loggedInUser }) => {

  // 계약 체결한 날짜 
  const [contarctDate, setContarctDate] = useState("");

  // 보증금 금액
  const [amount, setAmount] = useState(null);

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    setAmount(inputValue)
  }

  // 계약 시작한 날짜
  const [startDate, setStartDate] = useState("");

  // 월세가 있으신가요?
  const [monthPay, setMonthPay] = useState("");

  const handleMonthPay = (e) => {
    // const inputValue = e.target.value;
    setMonthPay(e)
  }

  // 월세 금액
  const [mothlyAmount, setMothlyAmount] = useState(null);

  const handleMothlyAmountChange = (e) => {
    const inputValue = e.target.value;
    setMothlyAmount(inputValue)
  }
  // 월세 납부일
  const [monthlyPayDate, setMonthlyPayDate] = useState("");

  const handlMonthlyPayDateChange = (e) => {
    const inputValue = e.target.value;
    setMonthlyPayDate(inputValue)
  }

  // 계약 종료 날짜 
  const [endDate, setEndDate] = useState("");

  //계약 종료 사유
  const [endReason, setEndReason] = useState("");
  const [etcEndReason, setEtcEndReason] = useState("");

  const handleEtcEndReasonChange = (e) => {
    const inputValue = e.target.value;
    setEtcEndReason(inputValue)
  }

  // 계약 종료를 집주인에게 통보하였는지
  const [endNoticeHost, setEndNoticeHost] = useState("");
  // 추가적인 답.
  const [endNoticeHostDate, setEndNoticeHostDate] = useState("");

  // 계약 종료 통보 기록
  const [recordEnd, setRecordEnd] = useState("");

  // 집에서 퇴거
  const [exitHouse, setExitHouse] = useState("");

  // 집에서 퇴거날짜
  const [exitHouseDate, setExitHouseDate] = useState("");

  // 보증금에서 제외할 금액
  const [exceptAmount, setExceptAmount] = useState("");
  const [exceptAmountDesc, setExceptAmountDesc] = useState("");

  const handleExceptAmountDescChange = (e) => {
    const inputValue = e.target.value;
    setExceptAmountDesc(inputValue)
  }

  // 보증금을 지급한 기록
  const [payRecord, setPayRecord] = useState("");

  // 전세보증 보험 가입 여부
  const [insurance, setInsurance] = useState("");

  // 장기 수선 충당금
  const [longTermRepair, setLongTermRepair] = useState("");

  // 보증금 받지 못한 손해
  const [damageNotReceive, setDamageNotReceive] = useState("");

  // 연체 이자 청구
  const [overDueInterests, setOverDueInterests] = useState("");

  // 집주인의 주소
  const [hostAddress, setHostAddress] = useState("");

  // 집주인의 주민번호
  const [hostId, setHostId] = useState("");

  // 현재 상황에 대한 상세 설명
  const [extraDesc, setExtraDesc] = useState("");

  const handleExtraDescChange = (e) => {
    const inputValue = e.target.value;
    setExtraDesc(inputValue)
  }

  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  const title = () => {
    const text = isMobile
      ? "전･월세 보증금\n지급명령 보내기"
      : "전･월세 보증금\n지급명령 보내기";
    return removeBrTag(text);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const serviceData = () => {
    const serviceData = {
      // 전화번호
      contact: "",
      // 첨부파일
      attachments: [],
      contract: {
        date: moment(contarctDate).format("YYYY-MM-DD"),
        deposit: {
          amount: amount,
          date: moment(startDate).format("YYYY-MM-DD"),
        },
        monthlyRent: {
          amount: mothlyAmount,
          date: monthlyPayDate,
          exist: monthPay === "네" ? "y" : "n"
        },
      },
      depositHistory: {
        exist: payRecord === "네" ? "y" : "n"
      },
      description: extraDesc,
      exclusions: exceptAmount === "네" ? [exceptAmountDesc] : [],
      insurance: {
        registYn: insurance === "네" ? "y" : "n"
      },
      lendlord: {
        addressYn: hostAddress === "네" ? "y" : "n",
        ssnYn: hostId === "네" ? "y" : "n"
      },
      longTermRepairReserve: {
        requestYn: longTermRepair === "네" ? "y" : "n"
      },
      lossDamage: {
        exist: damageNotReceive === "네" ? "y" : "n"
      },
      overDueInterests: {
        requestYn: overDueInterests === "네" ? "y" : "n"
      },
      termination: {
        date: moment(endDate).format("YYYY-MM-DD"),
        leaving: {
          date: moment(exitHouseDate).format("YYYY-MM-DD"),
          exist: exitHouse === "네" ? "y" : "n"
        },
        notification: {
          date: moment(endNoticeHostDate).format("YYYY-MM-DD"),
          exist: endNoticeHost === "네" ? "y" : "n",
          recordYn: recordEnd === "네" ? "y" : "n"
        },
        reason: endReason === "기간 만료" ? "expire" : "cancel",
        reasonDesc: etcEndReason !== "" ? etcEndReason : null,
      },
      termsOfService: true,
      userId: loggedInUser.uid
    };
    return serviceData;
  }



  const isSubmitReady = () => {
    if (contarctDate !== "" && amount !== null, startDate !== "" && monthPay !== "" &&
      (monthPay === "네" ? (mothlyAmount !== null && monthlyPayDate !== "") : true) &&
      endDate !== "" && endReason !== "" &&
      exitHouse !== "" && (exitHouse === "네" ? (exitHouseDate !== "") : true) &&
      endNoticeHost !== "" && (endNoticeHost === "네" ? (recordEnd !== "") : true) &&
      exceptAmount !== "" && overDueInterests !== "" && payRecord !== "" && longTermRepair !== "" && insurance !== "" &&
      damageNotReceive !== "" && hostAddress !== "" && hostId !== "") {
      return true;
    }
    return false;
  }

  const footerText = () => {
    const text = isSubmitReady()
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄져요. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "모든 항목에 답변해 주세요.\n답변이 완료되면 제출할 수 있어요.";
    return removeBrTag(text);
  }

  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcMonthlyPayOrderServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcMonthlyPayOrderServiceMain}
            />
            <ServiceCenterFirstImg src={IcMonthlyPayOrderServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ApplyServiceSubmitDataSection>
        <ApplyServiceSubmitDataContainer>
          <ApplyServiceSubmitDataInfoContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약을 체결한 날짜를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={contarctDate}
                onChange={setContarctDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>보증금 금액을 적어주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceInputText
                value={amount}
                type="number"
                inputMode="numeric"
                onChange={handleAmountChange}
                placeholder="₩" />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약이 시작된 날짜를 선택해 주세요.(잔금일)</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={startDate}
                onChange={setStartDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>월세가 있으셨나요?</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup label="" value={monthPay} style={{ width: "100%" }} onChange={handleMonthPay}>
                <Radio value="네" >네</Radio>
                <Radio value="아니오" >아니오</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            {monthPay.includes("네") && <>
              <ApplyServiceSubmitDataInfoDetailContainer>
                <ApplyServiceSubmitDataInfoDetailTitle>월세 금액을 적어주세요.</ApplyServiceSubmitDataInfoDetailTitle>
                <ApplyServiceInputText
                  value={mothlyAmount}
                  type="number"
                  inputMode="numeric"
                  onChange={handleMothlyAmountChange}
                  placeholder="₩" />
              </ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailContainer>
                <ApplyServiceSubmitDataInfoDetailTitle>월세 납부일을 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
                <ApplyServiceInputText
                  value={monthlyPayDate}
                  type="number"
                  inputMode="numeric"
                  onChange={handlMonthlyPayDateChange}
                  placeholder="매월 몇일인지 적어주세요."
                />
              </ApplyServiceSubmitDataInfoDetailContainer>
            </>}
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약이 종료된 날짜를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={endDate}
                onChange={setEndDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약 종료 사유를 알려주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={endReason}
                onChange={setEndReason}
              >
                <Radio value="기간 만료">기간 만료</Radio>
                <Radio value="계약 해지">계약 해지</Radio>
              </RadioGroup>
              <ApplyServiceInputText value={etcEndReason} onChange={handleEtcEndReasonChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                계약 종료를 집주인에게 통보 하셨나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>통보 하셨다면, 통보하신 날짜를 선택해 주세요.</EventFormEachDesc>
              <RadioGroup
                label=""
                value={endNoticeHost}
                onChange={setEndNoticeHost}
              >
                <Radio value="네">네</Radio>
                {endNoticeHost.includes("네") && <>
                  <ApplyServiceDatePicker
                    style={{ width: "100%" }}
                    placeholder="년-월-일"
                    value={endNoticeHostDate}
                    onChange={setEndNoticeHostDate}
                  />
                </>}
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            {endNoticeHost === "네" && <>
              <ApplyServiceSubmitDataInfoDetailContainer>
                <ApplyServiceSubmitDataInfoDetailTitle>
                  계약 종료를 통보한 기록이 있으신가요?
                </ApplyServiceSubmitDataInfoDetailTitle>
                <EventFormEachDesc>계약종료 통보 내용과 임대인의 확인내용이<br />문자나 녹취 등의 형태로 있어야 합니다.</EventFormEachDesc>
                <RadioGroup
                  label=""
                  value={recordEnd}
                  onChange={setRecordEnd}
                >
                  <Radio value="네">네</Radio>
                  <Radio value="아니요">아니요</Radio>
                </RadioGroup>
              </ApplyServiceSubmitDataInfoDetailContainer>
            </>}
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                현재 집에서 퇴거 하셨나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>임대인에게 지연이자를 청구하시려면 퇴거 후 임대인에게
                집의 점유를 넘긴 기록이 있어야 합니다. 퇴거를 하지 않고
                지급명령을 진행하시면 지연이자를 제외한 보증금 원금에
                대해서만 청구가 가능합니다.
              </EventFormEachDesc>
              <RadioGroup
                label=""
                value={exitHouse}
                onChange={setExitHouse}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            {exitHouse.includes("네") && <>
              <ApplyServiceSubmitDataInfoDetailContainer>
                <ApplyServiceSubmitDataInfoDetailTitle>
                  퇴거 및 점유 이전 일자를 알려주세요.
                </ApplyServiceSubmitDataInfoDetailTitle>
                <EventFormEachDesc>
                  임대인에게 집의 비밀번호 및 열쇠를
                  넘겨 점유를 이전한 일자를 기입해주세요.
                  추후 소송 진행시 이전한 일자에 대한
                  증빙이 되어야 합니다.
                </EventFormEachDesc>
                <ApplyServiceDatePicker
                  style={{ width: "100%" }}
                  placeholder="년-월-일"
                  value={exitHouseDate}
                  onChange={setExitHouseDate}
                />
              </ApplyServiceSubmitDataInfoDetailContainer>
            </>}

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                보증금에서 제외할 금액이 있나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>
                전세 계약금 중 돌려받은 일부 금액 또는 월세를 밀려 지불하지 않은<br />금액을 포함하여,
                청구시 제외할 금액을 작성해주세요.
              </EventFormEachDesc>
              <RadioGroup
                label=""
                value={exceptAmount}
                onChange={setExceptAmount}
              >
                <Radio value="네">네</Radio>
                {exceptAmount.includes("네") && <>
                  <ApplyServiceInputText value={exceptAmountDesc} onChange={handleExceptAmountDescChange} placeholder="금액과 사유를 모두 적어주세요." />
                </>}
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                보증금을 지급한 기록이 있나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>집주인에게 보증금을 지급 했음을 증명할 수 있는<br />자료가 있는지 여부를 확인해주세요</EventFormEachDesc>
              <RadioGroup
                label=""
                value={payRecord}
                onChange={setPayRecord}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                전세보증 보험에 가입이 되어 있으신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={insurance}
                onChange={setInsurance}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                장기 수선 충당금 반환을 요구하시나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={longTermRepair}
                onChange={setLongTermRepair}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                보증금을 받지 못해 발생하는 손해가 있으신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={damageNotReceive}
                onChange={setDamageNotReceive}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                연체 이자를 청구하시나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>
                연체이자를 청구할 경우 지급명령 결정이 된 이후부터 청구금액에 연 12%의 지연이자가 붙습니다.
                (지급명령 결정 이전의 이자와 별도)
              </EventFormEachDesc>
              <RadioGroup
                label=""
                value={overDueInterests}
                onChange={setOverDueInterests}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                집주인의 주소를 알고 계신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={hostAddress}
                onChange={setHostAddress}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>

            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                집주인의 주민번호를 알고 계신가요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={hostId}
                onChange={setHostId}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>그 외 특이사항이 있나요?</ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>기타 입력하신 내용 외 특이사항이 있을 경우 작성해 주세요.</EventFormEachDesc>
              <EventInputTextArea value={extraDesc} onChange={handleExtraDescChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
          </ApplyServiceSubmitDataInfoContainer>
        </ApplyServiceSubmitDataContainer>
      </ApplyServiceSubmitDataSection>
      <EventSubmitFooterSection active={isSubmitReady()}>
        <EventSubmitFooterTextContainer>
          <EventSubmitFooterText active={isSubmitReady()}>
            {footerText()}
          </EventSubmitFooterText>
        </EventSubmitFooterTextContainer>
      </EventSubmitFooterSection>
      <ApplyServiceSubmit
        active={isSubmitReady()}
        serviceData={serviceData()}
        serviceType={"property_deposit"}
        uid={loggedInUser.uid}
      />
    </Container>
  )
};

export default ApplyMonthlyPayOrderService;
