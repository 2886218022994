import Collapse from "components/common/Collapse";
import { SERVICE_QNA } from "../../service-constants";
import IcHeadset from "../../assets/svg/IcHeadset.svg";
import IcSexual from "../../assets/svg/IcSexual.svg";
import ImgServiceSexualStep from "../../assets/svg/ImgServiceSexualStep.svg";
import {
  AccusationServiceCenterSection,
  BtnConsult,
  CollapseWrapper,
  Container,
  ContentContainer,
  DetailImg,
  DetailRightBtn,
  DetailRightDesc,
  DetailRightTitle,
  DetailRightTitleMobile,
  DetailRightWrapper,
  DetailWrapper,
  SectionTop,
  SubTitle,
  Title,
  TopDesc,
  TopImg,
  TopTitle,
  TopWrapper,
} from "../Service.style";
import { SerivceCenterConsultingSection } from "./ServiceCenter.style";
import { useEffect } from "react";
import Footer from "components/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ChannelService from "../ChannelService";

const SexualServiceCenter = ({loggedInUser}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <SectionTop style={{ height: 380 }}>
        <ContentContainer style={{ width: "768px" }}>
          <TopWrapper>
            <TopTitle>통신매체음란죄</TopTitle>
            <TopDesc>
              처벌이 불가피한 통매음, 전문 변호사를 통해 처벌수위를 최소화하세요
            </TopDesc>
          </TopWrapper>
          <TopImg src={IcSexual} />
        </ContentContainer>
      </SectionTop>
      <AccusationServiceCenterSection>
        <ContentContainer>
          <DetailWrapper>
            <DetailRightTitleMobile>
              통매음 양형자료 작성하기
            </DetailRightTitleMobile>
            <DetailImg src={ImgServiceSexualStep} />
            <DetailRightWrapper>
              <DetailRightTitle>통매음 양형자료 작성하기</DetailRightTitle>
              <DetailRightDesc>
                통매음 처벌을 최소화하기 위해선
                <br />
                양형자료를 제출하는 것이 필수입니다.
                <br />
                수사단계를 변호사와 함께 신중히 진행하시고
                <br />
                변호사가 안내해드리는 다양한 양형자료를 제출하여
                <br />
                처벌 수위를 최소화 하세요.
                <br />
                법대리 소속 형사 전문 변호사가 통매음 사건을
                <br />
                해결할 수 있는 양형자료를 자세히 안내해드립니다.                
              </DetailRightDesc>
              <Link to="/crime/media_obscenity">
                <DetailRightBtn>
                  신청하기
                </DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          <CollapseWrapper>
            {SERVICE_QNA.sexual.map(({ title, desc }, idx) => (
              <Collapse
                key={`sexual-service$-${idx}`}
                title={title}
                desc={desc}
              />
            ))}
          </CollapseWrapper>
        </ContentContainer>
      </AccusationServiceCenterSection>
      <SerivceCenterConsultingSection>
        <ContentContainer>
          <Title style={{ marginBottom: 10, marginTop: 0 }}>
            기타 성범죄 사건 상담
          </Title>
          <SubTitle style={{ marginBottom: 0 }}>
            복잡한 법률 용어, 지식은 모르셔도 됩니다.
            <br />
            법대리가 알려드리는 항목들만 작성 및 첨부해 주시면 법대리의 전문
            변호사단이 검토 후 의견을 드립니다.
          </SubTitle>
          <BtnConsult onClick={()=> {
            // LandingToChannelTalk();
            if(!loggedInUser){
              ChannelService.loadScript();
              ChannelService.boot({
                "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
              })
            }
            ChannelService.showMessenger();
          }}>
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </BtnConsult>
        </ContentContainer>
      </SerivceCenterConsultingSection>
      <Footer/>
    </Container>
  );
};

export default SexualServiceCenter;
