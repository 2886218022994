import React, { useState } from 'react';
import './AddLawyerDocOpinion.css';
import backButtonBlack from "../../assets/img/backButtonBlack.png";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { sendLawyerDocOpinionWebhook } from './repository/CaseSubmissionRepository';
import { useRecoilState } from "recoil";
import { currentLawyerCaseState } from "states/atoms";

function AddLawyerDocOpinion({ onClickRegister, onClikcCloseOpinion }) {
    const history = useHistory();
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const [content, setContent] = useState(''); 
    const [lawyerCase, setLaywerCase] = useRecoilState(currentLawyerCaseState);
    // const case = location.state.case;

    const handleGoBack = () => {
        onClikcCloseOpinion();
      };
    
    const handleButtonClick = async () => {
        if (isMobile) {
            // 변호사의 opinion 업데이트!            
            const opinionData = {
              createAt: new Date(),
              id: uuidv4(),
              content: content
            }
            const host = process.env.REACT_APP_FIREBASE_FUNCTION_UPLOAD_LAWYER_DOC_LIST_URL
            const body = {
              requestId: lawyerCase.id,
              content: opinionData
            };
            const url = host;
            try {
              const { data } = await axios.post(url, body);
              console.log("res??", data);
              const docStatusHost = process.env.REACT_APP_FIREBASE_FUNCTION_UPDATE_LAWYER_DOC_STATUS_URL
              const docStatusBody = {
                    requestId: lawyerCase.id,
                    status: "rejected"
                };
                const { updateStatus } = await axios.post(docStatusHost, docStatusBody);
                console.log("res???", updateStatus);
                await sendLawyerDocOpinionWebhook(lawyerCase, content);
              history.goBack();
            } catch (e) {
              console.error(e);
              history.goBack();
            }
        } else {
            // 오른쪽 버튼 클릭 동작 처리
            onClickRegister(content);
        }        
    };

    const handleContentChange = (e) => {
        console.log(e.target.value);
        const inputValue = e.target.value;
        setContent(inputValue);
    }

    return (
        <>
        <div className="popover-container">
            {/* <div className="backdrop"></div> */}
            <div className="popover-content">
                <header className='popover-header'>
                    <div className="popover-back-wrapper">
                    <img onClick={handleGoBack} className="popover-back" src={backButtonBlack}></img>
                    </div>                    
                    <div className="popover-title">검토 서류 의견 추가하기</div>
                    {isMobile && <div style={{width: 70}}></div>}
                    <button onClick={handleButtonClick} className="popover-register-button">등록하기</button>                    
                </header>                    
                <div className="popover-bottom-border"></div>
                <textarea
                type="text"
                value={content}
                className="popover-content-input"
                placeholder="내용 작성하기"
                onChange={handleContentChange}
                />
                {isMobile &&
                    <div className='popover-mobile-register-button' onClick={handleButtonClick}>
                        등록하기
                    </div>
                }                
            </div>
        </div>
        </>
    );
}
export default AddLawyerDocOpinion;