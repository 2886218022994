import axios from "axios";
import { getServiceTypeTitle } from "components/mypage/MyCaseList";
import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

export async function sendClientUploadWebhook(selectedCase, fileName) {
    const caseName = getServiceTypeTitle(selectedCase.serviceType);
    let userName = "";
    const mandator = selectedCase.mandator;
    const caseId = selectedCase.id;
    const userId = selectedCase.client_id;
    await dbService
    .collection("users")
    .where("__name__", "==", userId)
    .get()
    .then((snapshot) => {
    if (snapshot.size > 0) {
        const userData = snapshot.docs[0].data();
        userName = userData.properties.nickname;
    }
    })
    .catch((error) => {
    console.log("error", error);
    });
    const webhookUrl = process.env.REACT_APP_DISCORD_DOCUMENT_WEBHOOK_DOMAIN;
    const message = {
      content: `고객이 서류를 업로드 하였습니다. 
      사건 이름: ${caseName} 
      유저: ${userName} / 위임자 : ${mandator}
      미완료 자료 이름: ${fileName}
      변호사 페이지 사건ID: ${caseId} userId: ${userId}
      `
    };

    try {
      const { response } = await axios.post(webhookUrl, message);
    } catch (e) {
    }
}

export async function sendClientReUploadWebhook(selectedCase, fileName) {
    const caseName = getServiceTypeTitle(selectedCase.serviceType);
    let userName = "";
    const mandator = selectedCase.mandator;
    const caseId = selectedCase.id;
    const userId = selectedCase.client_id;
    await dbService
    .collection("users")
    .where("__name__", "==", userId)
    .get()
    .then((snapshot) => {
    if (snapshot.size > 0) {
        const userData = snapshot.docs[0].data();
        userName = userData.properties.nickname;
    }
    })
    .catch((error) => {
    console.log("error", error);
    });
    const webhookUrl = process.env.REACT_APP_DISCORD_DOCUMENT_WEBHOOK_DOMAIN;
    const message = {
      content: `고객이 서류를 재업로드 하였습니다. 
      사건 이름: ${caseName} 
      유저: ${userName} / 위임자 : ${mandator}
      미완료 자료 이름: ${fileName}
      변호사 페이지 사건ID: ${caseId} userId: ${userId}
      `
    };

    try {
      const { response } = await axios.post(webhookUrl, message);
    } catch (e) {
    }
}

export async function sendLawyerDocOpinionWebhook(selectedCase, content) {
    const caseName = getServiceTypeTitle(selectedCase.serviceType);
    let userName = "";
    const mandator = selectedCase.mandator;
    const caseId = selectedCase.id;
    const userId = selectedCase.client_id;
    await dbService
    .collection("users")
    .where("__name__", "==", userId)
    .get()
    .then((snapshot) => {
    if (snapshot.size > 0) {
        const userData = snapshot.docs[0].data();
        userName = userData.properties.nickname;
    }
    })
    .catch((error) => {
    console.log("error", error);
    });
    const webhookUrl = process.env.REACT_APP_DISCORD_DOCUMENT_WEBHOOK_DOMAIN;
    const message = {
        content: `고객이 변호사가 올린 서류에 대해서 의견을 작성하였습니다. 
        사건 이름: ${caseName} 
        유저: ${userName} / 위임자 : ${mandator}
        의견: ${content}
        변호사 페이지 사건ID: ${caseId} userId: ${userId}
        `
    };

    try {
        const { response } = await axios.post(webhookUrl, message);
    } catch (e) {
    }
}

export async function sendLawyerDocAcceptWebhook(selectedCase) {
    const caseName = getServiceTypeTitle(selectedCase.serviceType);
    let userName = "";
    const mandator = selectedCase.mandator;
    const caseId = selectedCase.id;
    const userId = selectedCase.client_id;
    await dbService
    .collection("users")
    .where("__name__", "==", userId)
    .get()
    .then((snapshot) => {
    if (snapshot.size > 0) {
        const userData = snapshot.docs[0].data();
        userName = userData.properties.nickname;
    }
    })
    .catch((error) => {
    console.log("error", error);
    });
    const webhookUrl = process.env.REACT_APP_DISCORD_DOCUMENT_WEBHOOK_DOMAIN;
    const message = {
        content: `고객이 변호사가 올린 서류에 최종 승인하셨습니다. 
        사건 이름: ${caseName} 
        유저: ${userName} / 위임자 : ${mandator}
        변호사 페이지 사건ID: ${caseId} userId: ${userId}
        `
    };

    try {
        const { response } = await axios.post(webhookUrl, message);
    } catch (e) {
    }
}

export async function updateUserNoti(type, clientId, mandator, nickname, lawyerId, client_request_id, lawyer_doc_id) {
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_UPDATE_LAWYER_NOTILIST_URL
    const sendAt = new Date().getTime();
    const notiData = {
        id: uuidv4(),
        type: type,
        sendAt: sendAt,
        clientId: clientId,
        lawyerId: lawyerId,
        mandator: mandator,
        nickname: nickname,
        client_request_id: client_request_id,
        lawyer_doc_id: lawyer_doc_id,
    }
    const body = {
        userId: lawyerId,
        notiData: notiData
    };

    try {
        const { data } = await axios.post(host, body);
        console.log("유저 노티 리스트 업데이트 성공. ", data);
    } catch (e) {
        console.log("유저 노티 리스트 업데이트 실패 ", e);
    }
}