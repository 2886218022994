import styled from "@emotion/styled";

export const PopoverContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const PopoverBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
`;

export const PopoverContentContainer = styled.div`
    position: relative;
    width: 658px;
    height: 780px;
    background-color: white;
    color: #ffffff;
`;

export const PopoverHeader = styled.header`
    display: flex;
    // align-items: center;
    justify-content: space-between;
    height: 62px;
    background: #ffffff;

    @media screen and (max-width: 768px) {
        height: 82px;
    }
`

export const PopoverHeaderTitle = styled.div`
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
    @media screen and (max-width: 768px) {
        margin-top: 29px;
    }
`;

export const PopoverHeaderBackBtnWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 17px;
    @media screen and (max-width: 768px) {
        width: 38px;
        margin-top: 5px;
    }
`;

export const PopoverHeaderBackBtn = styled.img`
    width: 7px;
    height: 15px;
`

export const PopoverHeaderEmtpy = styled.div`
    width: 17px;
    @media screen and (max-width: 768px) {
        width: 38px;
    }
`

export const PopoverHeaderBottomBorder = styled.div`
    width: 100%;
    height: 1.14px;
    margin-top: 1px;   
    background-color: #D9D9D9;
`

export const ClientOpinionListWrapper = styled.div`
    width: 100%;
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const ClientOpinionList = styled.div`
    width: 100%;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
`

export const ClientOpinionListCreateAt = styled.div`
    width: 100%;
    color: #000;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const ClientOpinionListContent = styled.div`
    width: 100%;
    color: #000;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    line-height: 25px;
    margin-top: 17px;
`

export const ClientOpinionListDivider = styled.div`
    width: 100%;
    height: 1.13px;
    background: #EAEAEA;
    margin-top: 26px;
    margin-bottom: 10px;
`