import {
  Button,
  Divider,
  Form,
  Input,
  Typography,
  message,
} from "antd";
import { dbService } from "fbase";
import { useHistory } from "react-router-dom";

const { Title, Text } = Typography;

const Signup = ({ loggedInUser }) => {
  const history = useHistory();
  const upsertUserData = async (signup_values) => {
    try {
      await dbService
        .collection("users")
        .doc(String(loggedInUser.uid))
        .set({ ...signup_values, signed_up: true }, { merge: true });
      return true;
    } catch (err) {
      return false;
    }
  };
  const onFinish = (values) => {
    const ret = upsertUserData(values);
    if (ret) {
      message.success({
        content: "회원 정보가 정상적으로 등록되었습니다.",
        duration: 2,
      });
      history.push("/");
    } else {
      message.success({
        content: "회원 정보 등록에 실패하였습니다. 고객센터로 문의해주세요",
        duration: 2,
      });
      history.push("/");
    }
  };
  return (
    <>
      <Title
        level={2}
        style={{
          color: "#001529",
          textAlign: "center",
          marginTop: 10,
          fontSize: 22,
        }}
      >
        간편 회원 등록
      </Title>
      <div style={{ marginTop: 10, textAlign: "center" }}>
        <Text type="secondary">
          법률 서비스 이용에 필요한 정보를 입력해주세요
        </Text>
      </div>
      <Divider />

      <div
        style={{
          backgroundColor: "white",
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingTop: "5px",
          paddingBottom: "5px",
          marginBottom: 10,
          borderRadius: 5,
        }}
      >
        <Form onFinish={onFinish}>
          <Form.Item
            label="이름"
            name="name"
            rules={[{ required: true, message: "이름을 입력해주세요" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="생년월일"
            name="birthday"
            rules={[{ required: true, message: "생년월일을 입력해주세요" }]}
          >
            <Input type="date" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ textAlign: "right" }}
            >
              저장
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Signup;
