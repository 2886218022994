import { useHistory } from "react-router-dom";

import IcContractServiceMain from "../../assets/svg/IcContractServiceMain.svg";
import IcContractServiceMainMobile from "../../assets/svg/IcContractServiceMainMobile.svg";
import IcContractServiceBody from "../../assets/svg/IcContractServiceBody.svg";
import IcContractServiceBodyMobile from "../../assets/svg/IcContractServiceBodyMobile.svg";
import IcContractServiceBody2 from "../../assets/svg/IcContractServiceBody2.svg";
import IcContractServiceBody2Mobile from "../../assets/svg/IcContractServiceBody2Mobile.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
  ServiceCenterFooterBtn,
  ServiceCenterFooterContainer,
  ServiceCenterFooterDesc,
  ServiceCenterFooterSection,
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle,
  ServiceCenterSecondContainer,
  ServiceCenterSecondDesc,
  ServiceCenterSecondImg,
  ServiceCenterSecondSection,
  ServiceCenterSecondSubTitle,
  ServiceCenterSecondTextContainer,
  ServiceCenterSecondTitle,
  ServiceCenterThirdContainer,
  ServiceCenterThirdDesc,
  ServiceCenterThirdDescSpan,
  ServiceCenterThirdImg,
  ServiceCenterThirdImgMobile,
  ServiceCenterThirdSection,
  ServiceCenterThirdTextContainer,
  ServiceCenterThirdTitle
} from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const ContractServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = "계약서 검토하기";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = "꼭 필요한 계약서 검토";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = "상황에 따라 모두 다른 양식";
    return removeBrTag(text);
  };

  const body2Title = () => {
    const text = isMobile
      ? "계약서 검토는\n법대리에게!"
      : "계약서 검토는 법대리에게!";
    return removeBrTag(text);
  };

  const footerTitle = () => {
    const text = isMobile
      ? "계약서 검토가\n필요하신가요?"
      : "계약서 검토가 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcContractServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcContractServiceMain}
            />
            <ServiceCenterFirstImg src={IcContractServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
              {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              근로계약, 프리랜서 계약, 용역 계약, 외주 계약, 동업 계약 등 일상 속에서 계약서 작성이 필요할 때가 굉장히 많이 생기게 됩니다.
              계약 체결을 해야할때 기존 사용하던 계약서가 법적으로 문제는 없는지 등 계약서 검토가 필요한 일이 많이 생기게 되는데,
              이때 단순히 인터넷에서 구할 수 있는 계약서를 사용해서 계약을 하게되면 추후 법적 문제가 크게 발생할 수도 있습니다.
              계약서를 작성 할때는 항상 구체적인 계약 사안에 따라 수정이나 검토가 꼭 필요합니다.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcContractServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcContractServiceBody}
            />
            <ServiceCenterSecondImg src={IcContractServiceBody} />
          </picture>
        </ServiceCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterThirdSection>
        <ServiceCenterThirdContainer>
          <ServiceCenterThirdImg src={IcContractServiceBody2} />
          <ServiceCenterThirdTextContainer>
            <ServiceCenterThirdTitle>
              {body2Title()}
            </ServiceCenterThirdTitle>
            <ServiceCenterThirdDesc>
              법대리의 전문 변호사 계약서 검토 서비스는 불필요한 분쟁과 추후 법적 문제가 발생하지 않도록
              각 분야별 전문 변호사가 계약서 검토를 도와드리고 있고, 계약 당사자로서 법적인 보호를 받으며 계약을 체결할 수 있도록 도움을 드리고 있습니다.
            </ServiceCenterThirdDesc>
          </ServiceCenterThirdTextContainer>
          <ServiceCenterThirdImgMobile src={IcContractServiceBody2Mobile} />
        </ServiceCenterThirdContainer>
      </ServiceCenterThirdSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
              {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면
              분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtn onClick={() => history.push("/apply/contractService")}>
            계약서 검토하기
          </ServiceCenterFooterBtn>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default ContractServiceCenter;
