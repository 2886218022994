import { useHistory } from "react-router-dom";

import IcServiceMain from "assets/svg/Ic3.3Pilates.svg";

import {
  Container,
} from "components/Service.style";

import { useEffect, useState } from "react";
import { EventHeaderContainer, EventHeaderImg, EventHeaderSection, EventHeaderTitle } from "../EventStyle.style";
import { EventContentContainer, EventFooterBtn, EventFormContainer, EventFormEachContainer, EventFormEachTitle, EventFormNamePhoneTextContainer, EventFormNamePhoneTitle, EventFormSection, EventSection, EventSubmitBtn, FooterContainer } from "./order/EventSubmit.style";
import { InputText } from "components/home/CommonCriminal/CommonCriminal.style";

import DaumPostcode from "react-daum-postcode";

import {
  Modal,
} from "antd";

import { useRecoilState } from "recoil";
import { to3DataState } from "states/atoms";

const EventPilatesServiceCenter = ({ loggedInUser }) => {
  const [to3Data, setTo3Data] = useRecoilState(to3DataState);
  const history = useHistory();

  const onClickBtn = () => {
    setTo3Data(data);
    history.push("/3o3/pilates/certificate");
  }

  const [billingAmount, setBillingAmount] = useState("");

  const handleBillingAmountChange = (e) => {
    const inputValue = e.target.value;
    setBillingAmount(inputValue)
  }

  const [opponentName, setOpponentName] = useState("");

  const handleOpponentNameChange = (e) => {
    const inputValue = e.target.value;
    setOpponentName(inputValue)
  }

  const [opponentAddress, setOpponentAddress] = useState("");

  const handleOpponentAddressChange = (e) => {
    const inputValue = e.address;
    setOpponentAddress(inputValue)
    setIsModalOpen(false);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isSubmitReady = () => {
    if (opponentAddress !== "" && billingAmount !== "" && opponentName !== "") {
      return true
    }
    return false
  }

  const data = {
    opponentName: opponentName,
    opponentAddress: opponentAddress,
    billingAmount: billingAmount
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <EventSection>
        <EventContentContainer>
          <EventHeaderSection>
            <EventHeaderContainer>
              <EventHeaderImg src={IcServiceMain} />
              <EventHeaderTitle>
                헬스･필라테스 환불
              </EventHeaderTitle>
            </EventHeaderContainer>
          </EventHeaderSection>
          <EventFormSection>
            <EventFormContainer>
              <EventFormEachContainer>
                <EventFormEachTitle>
                  환불 받을 금액은 얼마인가요?
                </EventFormEachTitle>
                <EventFormNamePhoneTextContainer>
                  <InputText
                    value={billingAmount}
                    type="number"
                    inputMode="numeric"
                    onChange={handleBillingAmountChange}
                    placeholder="환불 받을 금액" />
                </EventFormNamePhoneTextContainer>
              </EventFormEachContainer>
              <EventFormEachContainer>
                <EventFormEachTitle>
                  업체의 이름은 무엇인가요?
                </EventFormEachTitle>
                <EventFormNamePhoneTextContainer>
                  <InputText
                    value={opponentName}
                    onChange={handleOpponentNameChange}
                    placeholder="업체명" />
                </EventFormNamePhoneTextContainer>
              </EventFormEachContainer>
              <EventFormEachContainer>
                <EventFormEachTitle>
                  매장의 주소는 어디인가요?
                </EventFormEachTitle>
                <EventFormNamePhoneTextContainer>
                  <Modal
                    title="주소 검색"
                    open={isModalOpen}
                    onCancel={closeModal}
                    footer={null}
                  >
                    <DaumPostcode onComplete={handleOpponentAddressChange} />
                  </Modal>
                  <InputText value={opponentAddress} onClick={showModal} placeholder="주소 선택" />
                </EventFormNamePhoneTextContainer>
              </EventFormEachContainer>
            </EventFormContainer>
          </EventFormSection>
        </EventContentContainer>
        <FooterContainer>
          <EventFooterBtn active={isSubmitReady()} disabled={!isSubmitReady()} onClick={() => onClickBtn()}>
            다음
          </EventFooterBtn>
        </FooterContainer>
      </EventSection>
    </Container>
  )
};

export default EventPilatesServiceCenter;