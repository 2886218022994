import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const SubmitSuccess = () => (
  <div
    style={{
      backgroundColor: "white",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingTop: "5px",
      paddingBottom: "5px",
      marginBottom: 10,
      borderRadius: 5,
      minHeight: "100%",
    }}
  >
    <Result
      status="success"
      title="제출이 완료되었습니다!"
      subTitle="법대리 전문 변호사들이 검토 후 연락드리겠습니다."
      extra={[
        <Button type="primary" key="mypage">
          <Link to="/mypage">제출 내역 확인</Link>
        </Button>,
        <Button key="home">
          <Link to="/">홈으로</Link>
        </Button>,
      ]}
    />
  </div>
);

export default SubmitSuccess;
