import styled from "@emotion/styled";

const mandatorBtnBGColorMap = {
  empty: "#CCDEFF",
  active: "#3A7EFC",
  done: "#000",
}

const payBtnBGColorMap = {
  diabled: "#CCDEFF",
  active: "#3A7EFC",
}

export const LawyerOpinionSection = styled.section`
  width: 100%;
  display: flex;  
  justify-content: center;
  @media screen and (max-width: 768px) {
  }
`;

export const DelegateSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
  }
`;

export const PaySection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
  }
`;

export const Container = styled.div`
  padding-top: 0px;
  margin: 0 auto;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 65px;
  display: flex;
  height: 1500px;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    margin-left: 16px;
    margin-right: 16px;
    height: auto;
  }
`;

export const ContentContainer = styled.div`  
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 628px;
  width: 700px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 12px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const DateText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 27px;
  @media screen and (max-width: 768px) {
    margin-bottom: 34px;
  }
`;

export const DelegateDesc = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-bottom: 20px;
`

export const DelegateWrapper = styled.div`
  padding: 36px;
  background-color: white;  
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DelegateBase = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DelegateContentTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 18px;
`;

export const DelegateMandateWrapper = styled.div`
  display: flex;
  // align-items: center;
  justify-content: center;
  gap: 18px;
  margin-bottom: 28px;
`;

export const DelegateMandateTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 3px;
`;

export const DelegateMandateDesc = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
`;

export const DelegateMandateNameInput = styled.input`
  border-radius: 3px;
  border: 1px solid #E2E2E2;
  background: #F5F5F5;
  color: #000;
  width: 304px;
  height: 64px;
  margin-top: 18px;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
`;

export const OpinioWrapper = styled.div`
  padding: 30px;
  background-color: white;
  border-radius: 16px;
`;

export const OpinioHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 44px;
`;

export const LawyerProfile = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 22px;
`;
export const LawyerProfileBlank = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 22px;
  background: #d9d9d9;
`;

export const LawyerInfoWrapper = styled.div``;
export const LawyerName = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 6px;
`;
export const LawyerAffiliation = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #011629;
`;
export const ContentDivier = styled.div`
  width: 100%;
  height: 1px;
  background: #b8babf;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const OpinioContent = styled.div`
  fcolor: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const PayBaseWrapper = styled.div`
  padding: 36px 32px;
  background-color: white;
  border-radius: 16px;
  margin-top: 20px;
`

export const PayWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
`;
export const PayLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  width: 100px;
`;
export const PayValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1a8fff;
`;

export const RefundWrapper = styled.div`
  width: 100%;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 48px;
  background: rgba(243, 244, 247, 0.8);
  color: #919293;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 30px;
`;

export const RefundText = styled.div`
  width: 100%;
  padding: 20px;
  padding-top: 5px;
  background: rgba(243, 244, 247, 0.8);
  color: #919293;
  font-size: 16px;
  line-height: 23px;
`;

export const BtnWrapper = styled.div`  
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

export const BtnPay = styled.button`
  cursor: pointer;
  width: 304px;
  padding-top: 20px;
  padding-bottom: 20px;
  outline: none;
  border: none;
  border-radius: 6px;
  background:  ${(props) => payBtnBGColorMap[props.status] || "#CCDEFF"};
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
`;

export const BtnDelegate = styled(BtnPay)`
  background:  ${(props) => mandatorBtnBGColorMap[props.status] || "#CCDEFF"};
  margin-bottom: 0px;
`
