import styled from "@emotion/styled";

export const IntroSectionFirst = styled.section`
  width: 100%;
  height: 526px;
  background: linear-gradient(314deg, #1C1C1C 0%, #494949 100%);
  padding-top: 162px;
  padding-bottom: 162px;
  @media screen and (max-width: 768px) {
    padding-top: 78px;
    padding-bottom: 0;
    height: 540px;
  }
  overflow: hidden;
`;

export const IntroSectionFourth = styled.section`
  width: 100%;
  height: auto;
  background: #FFF;
  padding-top: 158px;
  padding-bottom: 155px;
  @media screen and (max-width: 768px) {
    padding-top: 90px;
    padding-bottom: 0px;
  }
`;

export const IntroHeaderWrapper = styled.div`
  max-width:1080px;
  justify-content: center;
  width: 100%;  
`;

export const IntroTitleDescWrapper = styled.div`
  // position: absolute; 
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  /* width: 585px; */
  margin-right: 79px;
  @media screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 0px;
    z-index: 10;
  }
`;

export const IntroTitle = styled.div`
  color: #2E2E2E;
  text-align: center;
  font-size: 48px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  text-align: left;
  margin-bottom: 33px;
  @media screen and (max-width: 768px) {
    font-size: 35px;
    width: 309px;
    font-weight: 600;
    margin-bottom: 22px;
    line-height: 54px;
  }
`;

export const IntroSubTitle1 = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  color: #000000;
  text-align: center;
  margin-bottom: 77px;
  margin-top: 156px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 48px;    
    margin-top: 92px;
  }
`;

export const IntroSubTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 68px;
  color: #000000;
  text-align: center;
  margin-bottom: 33px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 56px;    
  }
`;

export const IntroDesc = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const IntroImg = styled.img`
  bottom: 300px;
  left: 650px;
  @media screen and (max-width: 768px) {    
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 235px;
    transform: translate(${(props) => (props.windowWidth - 251)}px, 0);
  }
`;

export const IntroImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  padding-top: 124px;
  @media screen and (max-width: 768px) {
    padding-top: 80px;
  }
`;

export const IntroShieldImg = styled.img`
  // margin: 0 auto;
  width: 45px;
  height: 54px;
  margin-top: 54px;
  @media screen and (max-width: 768px) {
    margin-top: 80px;
  }
`;


export const IntroCardRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const IntroCard = styled.div`
  flex: 1;
  width: 100%;
  min-height: 450px;
  margin-bottom: 120px;
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 80px !important;
  }
`;

export const IntroCardRevered = styled(IntroCard)`
  flex-direction: row-reverse;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 80px !important;
  }
`;

export const IntroCardTitleDescWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    align-items: left;
  }
`;

export const IntroCardTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -2px;
  color: #000000;
  margin-bottom: 22px;
  text-align: left;
  @media screen and (max-width: 768px) {
    margin-bottom: 28px;
    font-size: 24px;
    line-height: 34px;
  }
`;

export const IntroCardTitlReversed = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -2px;
  color: #000000;
  margin-bottom: 22px;
  text-align: left;
  padding-left: 130px;
  @media screen and (max-width: 768px) {
    margin-bottom: 28px;
    padding-left: 0px;
    font-size: 24px;
    line-height: 34px;
  }
`;

export const IntroCardDesc = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  text-align: left;
  max-width: 514px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const IntroCardDescReversed = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  text-align: left;
  padding-left: 130px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const IntroCardDescMobile = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: left;
  width: 100%;
  display: none;
  margin-top: 36px;
  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

export const IntroCardImgWrapper = styled.div`
  flex: 1;
  // margin-right: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  max-width: 550px;
  @media screen and (max-width: 768px) {
    margin: 0 auto!important;
  }
`;

export const IntroCardImg = styled.img`
  width: 100%;
  // margin: 0 auto;
`;

export const TogetherImg = styled.img`
  // margin: 0 auto;
`;


export const BeforeAfterSection = styled.div`
  width: 100%;
  background: #3A7EFC;  
  height: 840px;
  @media screen and (max-width: 768px) {
    height: 739px;
  }
`;

export const BeforeAfterWrapper = styled.div`
  display: flex;
  align-items: center;  
  justify-content: space-between;
  max-width: 977px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BeforeAfterTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  margin-bottom: 40px;
  margin-top: 68px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
    margin-top: 88px;
  }
`;

export const BeforeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 611px;
  justify-content: center;
  flex-direction: column;
  background-color: #293448;
  border-radius: 18px;
  height: 196px;
  left: 0;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 190px;    
  }
`;

export const CenterWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const AfterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 611px;
  justify-content: center;
  flex-direction: column;
  background-color: #d1e1ff;
  border-radius: 18px;
  height: 196px;
  right: 0;
  transform: translateY(172px);
  margin-left: -256px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    transform: translateY(18px);
    height: 190px;
  }
`;

export const BeforeTitle = styled.div`
  color: #8a9dc0;
  text-align: center;
  font-size: 28px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 800;
  line-height: 38px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export const AfterTitle = styled.div`
  color: #3a7efc;
  text-align: center;
  font-size: 28px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 800;
  line-height: 38px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export const BeforeDesc = styled.div`
  color: #8a9dc0;
  text-align: center;
  font-size: 24px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  padding-top: 10px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    max-width: 287px;
  }
`;

export const AfterDesc = styled.div`
  ccolor: #28416d;
  text-align: center;
  font-size: 24px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  padding-top: 10px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    max-width: 287px;
  }
`;

export const IntroCenterTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 108px;
`;

export const IntroCenterTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: #fff;
  text-align: center;
  margin-top: 45px;
  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 43px;
    margin-top: 0px;
    padding-top: 33px;
  }
`;


export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;
  @media screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const IntroSupportCardRowWrapper = styled.div`
  display: flex;
  margin-bottom: 29px;
  height: 287px;
  @media screen and (max-width: 768px) {
    display: block;
    height: auto;
    margin: 0 !important;
  }
`;

export const IntroSupportCard = styled.div`
  flex: 1;
  background-color: #fff;
  width: 50% - 12.5px;
  height: 287px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    margin: 0 !important;
    margin-bottom: 15px !important;
  }
`;

export const IntroSupportCardSecionTitle = styled.div`
  color: #000000;
  width: 76px;
  height: 36px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  border-radius: 90px;
  background: #2e2e2e;
  text-align: center;
`;

export const IntroSupportCardTitle = styled.div`
  color: #000;
  text-align: center;
  font-size: 28px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  padding-top: 12px;  
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 34px;
  }
`;

export const IntroSupportCardDesc = styled.div`
  color: #2e2e2e;
  text-align: center;
  font-size: 20px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 32px;
  padding-top: 13px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`; 

export const ServiceBtnFreeEstimate = styled.button`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  color: #ffffff;
  background: #3a7efc;
  border-radius: 6px;
  outline: none;
  border: none;
  padding: 27px 56px;
  display: flex;
  width: 350px;
  height: 80px;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 29px;
  margin-bottom: 136px;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
    margin-bottom: 65px;
    font-size: 16px;
    width: auto;
  }
`;