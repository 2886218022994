import { RightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IcBook from "assets/svg/IcBook.svg";
import ProfileAcceptedDefault from "assets/svg/ProfileAcceptedDefault.svg";
import AuthBadge from "assets/svg/AuthBadge.svg";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import Modal from 'react-modal';
import {
  CaseDetailTextWrapper,
  CaseDetailWrapper,
  ContentContainer,
  Container,  
  DateText,
  LawyerCardLabel,
  LawyerCardNameLabel,
  LawyerCardTopWrapper,
  LawyerCardProfile,
  LawyerCardBottomWrapper,
  LawyerCardProposeBtn,
  LawyerCardProposeWrapper,
  LawyerCardProposeWrapperTitle,
  LawyerCardProposeWrapperValue,
  LawyerCardTextAuthWrapper,
  LawyerCardTextTitleIcon,
  LawyerCardTextTitleAuth,
  LawyerCardTextWrapper,
  LawyerCardWrapper,
  LawyerCardCenterBorder,
  LoadingWrapper,
  MyCaseSection,
  SuggestTitle,
  NoLawyerCardWrapper,
  Title,
} from "./MyCase.style";
import MyCaseDetailModal from "./MyCaseDetailModal";
import { ModalStyle } from "util/ModalStyle";
import LawyerOpinion from "./LawyerOpinion";
import { getFormattedCaseDate } from "util/util";
import { getServiceTypeTitle } from "./MyCaseList";
import ReactLoading from 'react-loading';
import { useRecoilState } from "recoil";
import { currentMyCaseState, selectOpinionState } from "states/atoms";


const MyCase = ({ loggedInUser }) => {
  const history = useHistory();

  // const selectedCase = location.state.selectedCase;
  const [isLoading, setIsLoading] = useState(false);
  const [lawyerOpinions, setLawyerOpinions] = useState([]);
  const [showCaseModal, setShowCaseModal] = useState(false);
  const [showOpinionModal, setShowOpinionModal] = useState(false);
  // const [selectedOpinion, setSelectedOpinion] = useState(null);
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const [currentMyCase, setCurrentMyCase] = useRecoilState(currentMyCaseState);
  const [selectedOpinion, setSelectedOpinion] = useRecoilState(selectOpinionState);

  useEffect(() => {
    // 목록에서 타고 오지 않으면 메인으로
    if (!currentMyCase) history.replace("/");
    try { 
      setIsLoading(true);
      if (!currentMyCase.lawyer_proposal_id_list) {
        return;
      }
      if (currentMyCase.lawyer_proposal_id_list.length === 0) {
        return
      } else {
        getLaywerList(currentMyCase.lawyer_proposal_id_list);
      }
    } catch (e) {
      console.log("what error?", e);
    } finally {
      setIsLoading(false);
    }
  }, [currentMyCase]);


  const getLaywerList = async (data) => {
    const list = await Promise.all(data.map((t) => getLawyerProposal(t.id)));
    setLawyerOpinions(list);
  }

  async function getLawyerProposal(proposalId) {
    if (proposalId === undefined) {
      return;
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_LAYWER_PROPOSAL_URL
    const query = "?proposalId=" + proposalId;
    const url = host + query;
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  const onClickLawyerOpinion = ((opinion) => {
    setSelectedOpinion(opinion);
    if (isMobile) {
      history.push({
        pathname: "/lawyer_opinion",
      })
      return;
    } else {
      setShowOpinionModal(true);
    }    
  })


  const onClickCaseDetail = (() => {
    if (isMobile) {
      history.push({
        pathname: "/admin/request/" + currentMyCase.requestId,
      })
    } else {
      setShowCaseModal(true);
    }    
  })

  if (!currentMyCase) return null;
  
  return (
    <>
    <Modal isOpen={showCaseModal} onRequestClose={() => setShowCaseModal(false)} style={ModalStyle}>
      <MyCaseDetailModal caseData={currentMyCase}/>
    </Modal>
    <Modal isOpen={showOpinionModal} onRequestClose={() => setShowOpinionModal(false)} style={ModalStyle}>
      <LawyerOpinion opinion={selectedOpinion} caseData={currentMyCase} loggedInUser={loggedInUser}/>
    </Modal>
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
          <Title>{getServiceTypeTitle(currentMyCase.serviceType)}</Title>
          <DateText>신청일 | {getFormattedCaseDate(currentMyCase.createdAt)}</DateText>          
          <CaseDetailWrapper
            onClick={() =>
              onClickCaseDetail()
            }
          >
            <CaseDetailTextWrapper>
              <img src={IcBook} style={{ marginRight: 13 }} />
              내가 작성한 내용 보러 가기
            </CaseDetailTextWrapper>
            <RightOutlined />
          </CaseDetailWrapper>
          <SuggestTitle>
            변호사 검토의견        
          </SuggestTitle>
          {isLoading && <LoadingWrapper> <ReactLoading type={"spin"} color={"#3a7efc"} height={'10%'} width={'10%'}/> </LoadingWrapper>}
          {!isLoading && lawyerOpinions.length !==0 ? lawyerOpinions.map((opinion, index) => (
            <LawyerCardWrapper
              key={"lawyer-opinion-" + index}
              onClick={() => onClickLawyerOpinion(opinion)}>
              <LawyerCardTopWrapper>
                {opinion.lawyer_img ? (
                    <LawyerCardProfile src={opinion.lawyer_img} alt={ProfileAcceptedDefault}/>
                  ) : (
                    <LawyerCardProfile src={ProfileAcceptedDefault} />
                )}
                <LawyerCardTextWrapper>
                  <LawyerCardTextAuthWrapper>
                    <LawyerCardNameLabel>{opinion.lawyer_name}</LawyerCardNameLabel>              
                    <LawyerCardTextTitleIcon  src={AuthBadge}/>
                    <LawyerCardTextTitleAuth>
                      법대리 인증
                    </LawyerCardTextTitleAuth>
                  </LawyerCardTextAuthWrapper>
                  {/* <LawyerCardDescLabel>부동산 사건 전문 변호사</LawyerCardDescLabel> */}
                  <LawyerCardLabel>답변일 | {getFormattedCaseDate(opinion.proposal_date)}</LawyerCardLabel>
                </LawyerCardTextWrapper>                
              </LawyerCardTopWrapper>
              <LawyerCardCenterBorder />
              <LawyerCardBottomWrapper>
                <LawyerCardProposeWrapper>
                  <LawyerCardProposeWrapperTitle>
                    가격제안
                  </LawyerCardProposeWrapperTitle>
                  <LawyerCardProposeWrapperValue>
                    {Number(opinion.suggested_amount).toLocaleString()}원
                  </LawyerCardProposeWrapperValue>
                </LawyerCardProposeWrapper>
                <LawyerCardProposeBtn>
                  상세보기
                </LawyerCardProposeBtn>
              </LawyerCardBottomWrapper>
            </LawyerCardWrapper>
          ))
        :
          <NoLawyerCardWrapper>
            <ExclamationCircleOutlined/>
            <br/>
            현재 변호사가 사건을 검토 중입니다.
            <br/>
            조금만 기다려 주세요!
          </NoLawyerCardWrapper>
        }
        </ContentContainer>
      </MyCaseSection>
    </Container>
    </>
    );
};

export default MyCase;