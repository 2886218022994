import { dbService } from "fbase";
import IcHeadset from "../../assets/svg/IcHeadset.svg";
import { v4 as uuidv4 } from "uuid";
import IcCertificationEtc from "../../assets/svg/IcCertificationEtc.svg";
import IcSelectDesc from "../../assets/svg/IcSelectDesc.svg";
import IcEstate from "../../assets/svg/IcEstateNew.svg";
import IcEstateOwner from "../../assets/svg/IcEstateOwner.svg";
import IcDumbbellSmall from "../../assets/svg/IcDumbbellSmall.svg";
import IcNoise from "../../assets/svg/IcNoise.svg";
import IcMoney from "../../assets/svg/IcMoney.svg";
import IcGolf from "../../assets/svg/IcGolf.svg";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";
import {
  BtnConsult,
  Container,
  ContentContainer,
  DetailRightBtn,
  Label,
  Input,
  SectionTop,
  SubTitle,
  Title,
  TopDesc,
  TopImg,
  TopTitle,
  TopWrapper,
  SubDesc,
  DescDivider,
  TextArea,
} from "../Service.style";

import { 
  LandingCardRowWrapper,
  LandingCardImg,
  LandingCard,
  LandingCardText,
  SelectDescImg,
  SelectDescText,
 } from "routes/Landing.style";

import Footer from "components/Footer";
import { useEffect,useState } from "react";
import { 
  CertEtcCenterOtherContainer, 
  CertEtcCenterOtherDescWrapper, 
  CertEtcCenterOtherNoticeText,
  CertEtcCenterSectionFirst,
  EtcSelectDescWrapper,
  SelectDescSubText,
  SelectDescSubWrapper,
  SerivceCenterConsultingSection
 } from "./ServiceCenter.style";


import { useRecoilState } from 'recoil';
import { redirectEtcUri } from '../../states/atoms'
import { LoginRequireWrapper,
  LoginRequireContainer,
  LoginRequireTitle,
  LoginRequireButton } from "./CommonCriminal/CommonCriminal.style";

const { useHistory } = require("react-router-dom");

const CertificationEtcServiceCenter = ({loggedInUser}) => {
  const history = useHistory();
  const [description,setDescription] = useState(null);
  const [contact,setContact] = useState(null);
  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);
  const userInfo = useRecoilValue(userData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeDescription = (e)=>{
    setDescription(e.target.value)
  }
  
  const onChangeContact= (e)=>{
    setContact(e.target.value)
  }

  const onSubmit = async () => {
    if(!description || !contact){
      alert('상세 내용과 전화번호를 정확하게 입력해주세요.')
      return;
    }
    const propertyDepositData = {
      description,
      contact,
      userId: loggedInUser.uid,
    };
    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "etc_certification",
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: propertyDepositData,
        userId: loggedInUser.uid,
        clientName: userInfo.name,
        status: "submitted",
      });
      history.push("/submit/success");
    } catch (err) {
      history.push("/submit/fail");
    }
  };

  const onClickLogin = () => {    
    setEtcPath("/services/certificationETC");
    history.push("/login")    
  }

  const setLayout = () => {
    if (loggedInUser) {
      return <>
          <SubDesc>
          내용증명 작성 전, 어떤 내용증명을 작성해야할지 잘 모르거나,<br/>
          변호사에게 구체적인 상담을 받은 후 내용증명을 작성하기를 원하시면 기타내용증명 서비스를 이용하세요.<br/>
          상담 후  변호사가 직접, 빠르게 내용증명을 작성해서 발송까지 해드립니다. 
          </SubDesc>
          <DescDivider />
          <Label>의뢰하고 싶은 상황을 구체적으로 알려주세요 *</Label>
          <TextArea value={description} onChange={onChangeDescription} placeholder="문제되는 상황을 자세히 적어주세요." rows={10} maxLength={2000} />
          <Label>전화번호 *</Label>
          <Input value={contact} onChange={onChangeContact} placeholder="0000000000 - 표기 없이 입력해주세요" />
          <DetailRightBtn style={{ margin: "0 0 0 auto" }} onClick={onSubmit}>
            신청하기
          </DetailRightBtn>
      </>
    } else {
      return <>
            <LoginRequireWrapper>
              <LoginRequireContainer>
                <LoginRequireTitle>
                법대리 로그인 후<br/>기타 법률 서비스를 신청하실 수 있습니다.
                </LoginRequireTitle>
                <LoginRequireButton onClick={() => onClickLogin()}>
                  법대리 로그인 하기
                </LoginRequireButton>
              </LoginRequireContainer>
            </LoginRequireWrapper>
          </>
    }
  }

  return (
    <Container>
      <SectionTop style={{ height: 380 }}>
        <ContentContainer style={{ width: "768px" }}>
          <TopWrapper>
            <TopTitle>기타 내용증명</TopTitle>
            <TopDesc>
            변호사에게 구체적인 상담을 받고 싶다면 기타 내용증명 서비스를 이용하세요.<br/>
            상담 후 변호사가 직접, 빠르게 내용증명을 작성해서 발송까지 해드립니다.
            </TopDesc>
          </TopWrapper>
          <TopImg style={{paddingBottom:"20px"}} src={IcCertificationEtc} />
        </ContentContainer>
      </SectionTop>
      <CertEtcCenterSectionFirst>
        <CertEtcCenterOtherContainer>
          <CertEtcCenterOtherDescWrapper>
            <SelectDescSubWrapper>
              <EtcSelectDescWrapper>
                <SelectDescImg src={IcSelectDesc}/>
                <SelectDescText style={{color: "#FFF"}}>
                법대리 빠른 내용증명 서비스
                </SelectDescText>            
              </EtcSelectDescWrapper>
              <SelectDescSubText>
              빠른 내용증명 서비스에 내가 원하는 분야가 있는지 먼저 확인해주세요!
              </SelectDescSubText>
            </SelectDescSubWrapper>
          </CertEtcCenterOtherDescWrapper>
          <div>          
            <LandingCardRowWrapper>
              <LandingCard onClick={() => history.push("/services/estate")}>
                <LandingCardImg src={IcEstate}/>
                <LandingCardText>
                보증금 반환
                </LandingCardText>
              </LandingCard>
              <LandingCard onClick={() => history.push("/services/estateHost")}>
                <LandingCardImg src={IcEstateOwner}/>
                <LandingCardText>
                임대인 서비스
                </LandingCardText>
              </LandingCard>
              <LandingCard onClick={() => history.push("/services/pilates")}>
                <LandingCardImg src={IcDumbbellSmall}/>
                <LandingCardText>
                헬스장 · 필라테스<br/> 환불
                </LandingCardText>
              </LandingCard>
              <LandingCard onClick={() => history.push("/services/golfTennis")}>
                <LandingCardImg src={IcGolf}/>
                <LandingCardText>
                골프 · 테니스<br/> 환불
                </LandingCardText>
              </LandingCard>            
            </LandingCardRowWrapper>
            <LandingCardRowWrapper>
              <LandingCard onClick={() => history.push("/services/noise")}>
                <LandingCardImg src={IcNoise}/>
                <LandingCardText>
                층간소음
                </LandingCardText>
              </LandingCard>
              <LandingCard onClick={() => history.push("/services/loan")}>
                <LandingCardImg src={IcMoney}/>
                <LandingCardText>
                대여금 반환
                </LandingCardText>
              </LandingCard>          
            </LandingCardRowWrapper>
          </div>
          <CertEtcCenterOtherNoticeText>
          * 빠른 내용증명 서비스를 통해 신청서를 제출해 주셔야 해당 분야의 전문 변호사님으로 <br/>
          배정되오니 참고 부탁드립니다.
          </CertEtcCenterOtherNoticeText>
        </CertEtcCenterOtherContainer>          
      </CertEtcCenterSectionFirst>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 100,
          paddingBottom: 165,
        }}
      >
        <ContentContainer style={{ maxWidth: 630, width: "100%" }}>
          {setLayout()}
        </ContentContainer>
      </section>
      <SerivceCenterConsultingSection>
        <ContentContainer>
          <Title style={{ marginBottom: 10, marginTop: 0 }}>
            기타 법률 상담
          </Title>
          <SubTitle style={{ marginBottom: 0 }}>
          간편 법률 서비스에 원하는 서비스가 없으신가요? <br/>
          기타 법률 서비스에서 현재 상황을 알려주세요!
          </SubTitle>
          <BtnConsult onClick={()=> {
            history.push('/services/etc')
          }}>
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </BtnConsult>
        </ContentContainer>
      </SerivceCenterConsultingSection>
      <Footer />
    </Container>
  );
};

export default CertificationEtcServiceCenter;
