import { Descriptions, Divider, Image } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const GolfTennisOrderRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };
  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
      골프/테니스 환불 지급명령
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="골프/테니스 계약 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="양도 여부" labelStyle={labelStyle}>
            {serviceData.golfTennis.hangover === "n"
              ? "아니오"
              : "예"}
          </Descriptions.Item>

          <Descriptions.Item
            label="계약 체결 일자"
            labelStyle={labelStyle}
          >
            {serviceData.golfTennis.contarctDate}
          </Descriptions.Item>

          <Descriptions.Item
            label="계약 시작 일자"
            labelStyle={labelStyle}
          >
            {serviceData.golfTennis.startDate}
          </Descriptions.Item>

          <Descriptions.Item
            label="계약 종료 일자"
            labelStyle={labelStyle}
          >
            {serviceData.golfTennis.endDate}
          </Descriptions.Item>

          {serviceData.golfTennis.amount ? (
            <Descriptions.Item label="결제 금액" labelStyle={labelStyle}>
              {serviceData.golfTennis.amount.toLocaleString()}
            </Descriptions.Item>
          ) : null}

          <Descriptions.Item label="계약 유형" labelStyle={labelStyle}>
            {serviceData.contract.type === "lesson"
              ? "레슨"
              : "회원권"}
          </Descriptions.Item>

          <Descriptions.Item
            label="레슨 계약 횟수"
            labelStyle={labelStyle}
          >
            {serviceData.contract.count}
          </Descriptions.Item>
          
          <Descriptions.Item
            label="레슨 사용(차감) 횟수"
            labelStyle={labelStyle}
          >
            {serviceData.contract.usedCount}
          </Descriptions.Item>

          <Descriptions.Item
            label="계약 해지 통보 일자"
            labelStyle={labelStyle}
          >
            {serviceData.contract.notiDate}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.description
              ? serviceData.description.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
          <Descriptions.Item label="프로모션" labelStyle={labelStyle}>
            {serviceData.promotionCode}
          </Descriptions.Item>
        </Descriptions>
        <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">첨부 파일</div>
          </div>
          {serviceData.attachments.map((attachment) => (
            <Image
              key={attachment}
              width={75}
              height={75}
              src={attachment}
              preview={{ zIndex: 10000 }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default GolfTennisOrderRequest;
