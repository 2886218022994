import React from "react";
import IcConfirmPopupQuestion from "../../assets/svg/IcConfirmPopupQuestion.svg";
import { 
    PopoverContentContainer,
    PopoverContentCenterIcon,
    PopoverContentBodyText,
    PopoverContentBottomButton,
    PopoverContentBottomButtonWrapper,
    PopoverContentBottomWrapper,
    PopoverContentBodyDescText
 } from "./ConfirmMyDocAccept.style";
import { isCertification } from "util/ServiceType";



function ConfirmMyDocAccept({ onClikcConfirmButton, onClickCloseButton, serviceType}) {

    const handleConfirmButton = () => {
        onClikcConfirmButton();
      };

    const handleCloseButton = () => {
        onClickCloseButton();
    };

    return (
        <>
        {/* <PopoverContainer> */}
          <PopoverContentContainer>
            <PopoverContentCenterIcon  src={IcConfirmPopupQuestion}/>
            <PopoverContentBodyText>
                사건 서류를 최종 승인하시겠습니까?
            </PopoverContentBodyText>
            {isCertification(serviceType) && 
              <PopoverContentBodyDescText>
                  법대리 내용증명 발송 서비스는 변호사 작성 및<br/>변호사 명의 발송을 진행해드리는 서비스입니다.<br/>상대방에게 미도달하여 추가 발송을 원하실 경우<br/>일부 수수료가 부과되는 점 참고바랍니다.
              </PopoverContentBodyDescText>
            }
            <PopoverContentBottomWrapper>
                <PopoverContentBottomButtonWrapper isConfirm={true} onClick={handleConfirmButton}>
                    <PopoverContentBottomButton>확인</PopoverContentBottomButton>
                </PopoverContentBottomButtonWrapper>
                <PopoverContentBottomButtonWrapper isConfirm={false} onClick={handleCloseButton}>
                    <PopoverContentBottomButton>취소</PopoverContentBottomButton>
                </PopoverContentBottomButtonWrapper>
            </PopoverContentBottomWrapper>
          </PopoverContentContainer>
        {/* </PopoverContainer> */}
        </>
    );
}

export default ConfirmMyDocAccept;