import CaseConversation from "components/caseDetail/CaseConversation";
import CaseProgress from "components/caseDetail/CaseProgress";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Tab, TabWrapper, Title, CaseDetailSection, ContentContainer } from "./CaseDetail.style";
import CaseSubmission from "components/caseDetail/CaseSubmission";
import axios from "axios";
import { EventBannerMobileImg } from "./MyCaseList.style";
import EventBannerMobile from "assets/svg/EventBannerMobile.svg";
import Footer from "components/Footer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { dbService } from "fbase";
import { message } from "antd";
import { useRecoilState } from "recoil";
import { currentLawyerCaseState, currentMyCaseState, selectOpinionState } from "states/atoms";

const AdminCaseDetail = ({ loggedInUser }) => {
    const { requestId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [tab, setTab] = useState(0);
  
    const history = useHistory();
    // const location = useLocation();    

    const [lawyerCase, setLaywerCase] = useRecoilState(currentLawyerCaseState);
    const [selectedOpinion, setSelectedOpinion] = useRecoilState(selectOpinionState);
    const [currentMyCase, setCurrentMyCase] = useRecoilState(currentMyCaseState);  
  
    // const [cases, setCases] = useState(null);
    // const [opinion, setOpinion] = useState(null);
    // const [selectedCase, setSelectedCase ] = useState(null);
    const [user, setUser ] = useState(null);
    // fetch my cases
    
    useEffect(() => {
      let query = dbService
      .collection("admins")
      .where("__name__", "==", loggedInUser.uid);

      query
        .get()
        .then((snapshot) => {
          try {
            getLawyerCase(requestId);
            
          } catch (e) {
          } finally {
            setIsLoading(false);
          }
        })
        .catch(() => {
          history.push("/");
        });        
      }, []);

    useEffect(() => {
      try {
        getLawyerProposal(currentMyCase.accepted.proposal_id);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }, [currentMyCase]);
  
    async function getLawyerProposal(proposalId) {
      if (proposalId === undefined) {
        return;
      }
      const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_LAYWER_PROPOSAL_URL
      const query = "?proposalId=" + proposalId;
      const url = host + query;
      try {
        const { data } = await axios.get(url);
        setSelectedOpinion(data);
        return data;
      } catch (e) {
        console.error(e);
        return [];
      }
    }
  
    async function getLawyerCase(requestId) {
      if (requestId === undefined) {
        return;
      }
      const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_LAYWERCASE_URL
      const query = "?requestId=" + requestId;
      const url = host + query;
      try {
        const { data } = await axios.get(url);
        console.log(data)
        setLaywerCase(data);
        await dbService
        .collection("requests_temp")
        .where("__name__", "==", data.client_request_id)
        .get()
        .then((snapshot) => {
          if (snapshot.size > 0) {            
              const data = { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
              setCurrentMyCase(data);
          }
        })
        .catch((error) => {
          message.error("잘못된 요청입니다.");
          history.push("/");
        });

        await dbService
          .collection("users")
          .where("__name__", "==", data.client_id)
          .get()
          .then((snapshot) => {
            if (snapshot.size > 0) {
                const data = snapshot.docs[0].data();
                setUser(data);
            }
          })
          .catch((error) => {
            message.error("잘못된 요청입니다.");
            history.push("/");
          });
      } catch (e) {
        console.error(e);
        return [];
      }
    }
  
    const TabContent = useMemo(() => {
      if (currentMyCase === null) {
            return null;
      }
      if (currentMyCase.accepted === undefined) {
        return null;
      }

      if (selectedOpinion === null) {
        return null;
      }
      if (tab === 0) {
        return <CaseProgress loggedInUser={user} fromAdmin={true}/>;
      } else if (tab === 1) {
        return <CaseSubmission loggedInUser={user} fromAdmin={true}/>;
      } else if (tab === 2) {
        return <CaseConversation loggedInUser={user} fromAdmin={true}/>;      
      }
      return null;
    }, [tab, selectedOpinion]);
  
    if (isLoading) return null;

    if (currentMyCase === null) return null;

    if (currentMyCase.accepted === undefined) return <div>사건이 아직 수임되지 않았음</div>;
    
  
    return (
      <Container style={{ textAlign: "left" }}>
        <CaseDetailSection>
          <ContentContainer>
            <Title>사건 상세</Title>
            <TabWrapper>
              <Tab
                active={tab === 0}
                onClick={() => setTab(0)}
                style={{ borderRight: "1px solid #E2E2E2" }}
              >
                사건 진행 상황
              </Tab>            
              <Tab active={tab === 1} onClick={() => setTab(1)}>
                서류 제출하기
              </Tab>
              <Tab
                active={tab === 2}
                onClick={() => setTab(2)}
                style={{ borderRight: "1px solid #E2E2E2" }}
              >
                법대리 요청사항 확인
              </Tab>
            </TabWrapper>
            {TabContent}      
            <EventBannerMobileImg src={EventBannerMobile} />
          </ContentContainer>
        </CaseDetailSection>
        <Footer />
      </Container>
    );
  };
  
  export default AdminCaseDetail;
  