import { useEffect, useRef, useState } from "react";
import EmptyViewIcon from "../../assets/svg/EmptyViewIcon.svg";
import EmptyViewIconSubmit from "../../assets/svg/EmptyViewIconSubmit.svg";
import EntireDownloadIcon from "../../assets/svg/EntireDownloadIcon.svg";
import EntireDownloadIconDisabled from "../../assets/svg/EntireDownloadIconDisabled.svg";
import IcReviewAddOpinion from "../../assets/svg/IcReviewAddOpinion.svg";
import IcReviewMyOpinion from "../../assets/svg/IcReviewMyOpinion.svg";
import IcSubmitNotice from "../../assets/svg/IcSubmitNotice.svg";
import IcSubmitHowToUse from "../../assets/svg/IcSubmitHowToUse.svg";
import IcFileClip from "../../assets/svg/IcFileClip.svg";
import IcUploadPlus from "../../assets/svg/IcUploadPlus.svg";
import IcCaseDoc from "../../assets/svg/IcCaseDoc.svg";
import { useHistory } from "react-router-dom";

import {
  BtnApproveBaseWrapper,
  BtnApproveDesc,
  BtnApprove,
  DataEmptyWrapper,
  DataEmptyView,
  DataEmptyIcon,
  DataEmptyTitle,
  ImgSubmissionMore,
  ImgSubmissionUploadWrapper,
  ImgSubmissionUploadTitle,
  ReviewButtonWrapper,
  ReviewButtonTitleDescWrapper,
  ReviewButtonTitle,
  ReviewButtonDesc,
  ReviewButtonIcon,
  ReviewBtnsWrapper,  
  ReviewItemWrapper,
  ReviewWrapper,
  SubButtonIcon,
  SubButtonTitle,
  SubButtonWrapper,
  SubTitle,
  SubTitleWrapper,
  SubmissionDataAcionWrapper,
  SubmissionDataAction,
  SubmissionDataIcon,
  SubmissionDataText,
  SubmissionDataWrapper,
  SubmissionGridWrapper,
  SubmissionSubTitle,
  SubmissionDesc,
  SubmitNoticeWrapper,
  SubmitNoticeTitle,
  SubmitNoticeTitleImg,
  SubmitNoticeDescWrapper,
  SubmitNoticeDesc,
  SubmitNoticeDescDash,
  SubmitNoticeTitleWrapper,
  SubmissionGuideWrapper,
  SubmissionGuideTextWrapper,
  SubmissionGuideDesc,
  SubmissionGuideImg,
  SubmissionGuideTitle,
  Title,
} from "./CaseDetailComp.style";

import axios from "axios";
import CaseDataFilePreview from "./CaseDataFilePreview";
import AddLawyerDocOpinion from "./AddLawyerDocOpinion";
import ConfirmMyDocAccept from "./ConfirmMyDocAccept";
import SuccessMyDocAccept from "./SuccessMyDocAccept";
import Modal from "react-modal";
import { ModalStyle, ConfrimLawyerDocModalStyle, CaseDocPreviewModalStyle, CaseDocSubmitPreviewModalStyle, ConfrimLawyerDocCertificationModalStyle } from "util/ModalStyle";
import { v4 as uuidv4 } from "uuid";
import { dbService, lawyerStorageService } from "fbase";
import { ref, getDownloadURL, deleteObject} from "firebase/storage";
import ConfirmMyDocOpinion from "./ConfirmMyDocOpinion";
import Loading from "../../util/Loading";
import CaseDataNotSubmitPreview from "./preview/CaseDataNotSubmitPreview";
import { CustomModal } from "./preview/CaseDataNotSubmitPreview.style";
import SuccessUploadDocModal from "./SuccessUploadDocModal";
import ConfirmDeleteMyDoc from "./ConfirmDeleteMyDoc";
import CaseDataSubmitPreview from "./preview/CaseDataSubmitPreview";
import { sendClientReUploadWebhook, sendClientUploadWebhook, sendLawyerDocAcceptWebhook, sendLawyerDocOpinionWebhook, updateUserNoti } from "./repository/CaseSubmissionRepository";
import { removeBrTag } from "routes/Landing";
import { useRecoilState } from "recoil";
import { userObj, currentClientNotSubmitPreviewState, currentClientSubmitPreviewState, currentLawyerCaseState, currentMyCaseState } from "states/atoms";
import { isCertification } from "util/ServiceType";

const CaseSubmission = ({loggedInUser, fromAdmin}) => {
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const history = useHistory();
  const lastMessageEl = useRef(null);
  const [notSubmitted, setNotSubmitted] = useState([]);  
  const [submittedDownloadUrlList, setSubmittedDownloadUrlList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showNotSubmittedPreview, setShowNotSubmittedPreview] = useState(false);
  const [showOpinionModal, setShowOpinionModal] = useState(false);
  const [showClientDocModal, setShowClientDocModal] = useState(false);
  const [showMyDocOpinionModal, setShowMyDocOpinionModal] = useState(false);
  const [showMyDocConfirmModal, setShowMyDocConfirmModal] = useState(false);
  const [showSuccessMyDocConfirmModal, setShowSuccessMyDocConfirmModal] = useState(false);
  const [showSuccessUploadDocModal, setShowSuccessUploadDocModal] = useState(false);
  const [showConfirmDeleteMyDoc, setShowConfirmDeleteMyDoc] = useState(false);
  const [selectedDeleteCandidate, setSelectedDeleteCandidate] = useState(null);

  const [selectedNotSubmitted, setSelectedNotSubmitted] = useState(null);
  const [selectedReuploadFile, setSelectedReuploadFile] = useState(null);
  const fileInputRef = useRef(null);  
  const reuploadFileInputRef = useRef(null);  

  const [lawyerCase, setLaywerCase] = useRecoilState(currentLawyerCaseState);
  const [currentMyCase, setCurrentMyCase] = useRecoilState(currentMyCaseState);
  const [userObjData, setUserObjData] = useRecoilState(userObj);
  const [clientSubmitData, setClientSubmitData] = useRecoilState(currentClientSubmitPreviewState);
  const [clientNotSubmitData, setClientNotSubmitData] = useRecoilState(currentClientNotSubmitPreviewState);
  const [submitted, setSubmitted] = useState(lawyerCase.client_doc_list);  
  useEffect(() => {
    setNotSubmitted(lawyerCase.client_req_doc_list);
    setSubmitted(lawyerCase.client_doc_list);
    const fetchList = async () => {
      const files = await fetchClientDocDownloadList();
      console.log("files",files);
      setSubmittedDownloadUrlList(files);
    }    
    fetchList();
  }, [])

  useEffect(() => {
    if (lastMessageEl && lastMessageEl.current) {
      lastMessageEl.current.scrollIntoView({ behavior: "smooth" });
    }    
  }, [selectedDeleteCandidate, submittedDownloadUrlList, submitted]);

  const notSubmitNonText = () => {
    const text = isMobile
      ? "아직 제출해야 하는 서류 목록이\n업데이트되지 않았습니다!\n변호사가 제출 서류 목록을\n전달할 때까지 기다려주세요."
      : "아직 제출해야 하는 서류 목록이 업데이트되지 않았습니다!\n변호사가 제출 서류 목록을 전달할 때까지 기다려주세요.";

    return removeBrTag(text);
  };

  const noticeDescText = () => {
    const text = isMobile
      ? "서류가 누락되거나 잘못 기재된 내용이 있을 경우,\n원활한 사건 처리가 되지 않을 수 있습니다.\n꼭 안내문구를 잘 확인한 뒤에 업로드 해주세요."
      : "서류가 누락되거나 잘못 기재된 내용이 있을 경우, 원활한 사건 처리가 되지 않을 수 있습니다.\n꼭 안내문구를 잘 확인한 뒤에 업로드 해주세요.";

    return removeBrTag(text);
  };

  const guideText = () => {
    const text = isMobile
      ? "서류 제출하는 방법은\n사용법 안내 화면에서 확인하세요!"
      : "서류 제출하는 방법은 사용법 안내 화면에서 확인하세요!";

    return removeBrTag(text);
  };  

  const handleFileSelect = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];    
    const id = selectedNotSubmitted.id;
    const name = selectedNotSubmitted.title;
    const desc = selectedNotSubmitted.desc;
    await fileUpload(file, id, name, desc);
    // await sendClientUploadWebhook(lawyerCase, name);
    await updateUserNoti("clientDoc", loggedInUser.uid, lawyerCase.mandator, userObjData.properties.nickname, 
                            currentMyCase.accepted.lawyer_id, currentMyCase.id, currentMyCase.accepted.lawyer_case_id)
    setShowSuccessUploadDocModal(true);
  };

  const fileUpload = async (file, id, name, desc) => {
    setSelectedFile(file);
    setIsLoading(true);
    const formData = new FormData();
    formData.append('image', file);
    formData.append('id', lawyerCase.id);
    formData.append('reqId', id);
    formData.append('content', name);
    formData.append('desc', desc);
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_UPLOAD_CLIENT_FILE_URL
    try {
      const { data } = await axios.post(host, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("response??", data);
      await reloadData();      
      setIsLoading(false);
      return data;
    } catch (e) {
      console.log("handleFileSelect err", e);
      console.error(e);
      setIsLoading(false);
      return [];
    }
  }

  async function getLawyerCase(requestId) {
    if (requestId === undefined) {
      return;
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_LAYWERCASE_URL
    const query = "?requestId=" + requestId;
    const url = host + query;
    try {
      const { data } = await axios.get(url);
      setLaywerCase(data);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  const fetchClientDocDownloadList = (async () => {
    const res = submitted.map(async (doc) => {
      const extension = doc.file.slice(((doc.file.lastIndexOf(".") - 1) >>> 0) + 2);
      const file =  await getDownLoadUrls(doc.file);
      return {
        url: file,
        name: doc.content + "." + extension
      }
    })
    return Promise.all(res);
  })

  const handleInputClick = (property) => {
    setSelectedNotSubmitted(property);
    fileInputRef.current.click();
  };

  const handleReuploadBtnClick = (property) => {
    setSelectedReuploadFile(property)
    reuploadFileInputRef.current.click();
  };

  const onClickCloseButton = () => {
    setShowModal(false);
    setShowOpinionModal(false);
    setShowClientDocModal(false);
    setShowMyDocOpinionModal(false);
    setShowMyDocConfirmModal(false);
    setShowSuccessMyDocConfirmModal(false);
    setShowNotSubmittedPreview(false);
    setShowSuccessUploadDocModal(false);
    setShowConfirmDeleteMyDoc(false);
  };

  const onClickSubmissionGuide = () => {
    history.push("/howToUse");
    setTimeout(() => {      
      const howtoUseSectionNode = document.getElementById('SubmissionScroll');
      howtoUseSectionNode.scrollIntoView({behavior: "smooth"});
    }, 0);
  };

  const onClickFileUpload = async (res) => {
    setShowNotSubmittedPreview(false);
    const id = res.data.id;
    const name = res.data.title;
    const desc = res.data.desc;
    await fileUpload(res.file, id, name, desc);
    await sendClientUploadWebhook(lawyerCase, name);
    await updateUserNoti("clientDoc", loggedInUser.uid, lawyerCase.mandator, userObjData.properties.nickname, 
                          currentMyCase.accepted.lawyer_id, currentMyCase.id, currentMyCase.accepted.lawyer_case_id)
    setShowSuccessUploadDocModal(true);    
  };

  const onClickConfirmLawyerDocModal = async () => {
    setShowMyDocConfirmModal(false);
    // 사건 서류들 status accept.
    const docStatusHost = process.env.REACT_APP_FIREBASE_FUNCTION_UPDATE_LAWYER_DOC_STATUS_URL
    const docStatusBody = {
          requestId: lawyerCase.id,
          status: "accept"
      };
    const { updateStatus } = await axios.post(docStatusHost, docStatusBody);
    sendLawyerDocAcceptWebhook(lawyerCase);
    await updateUserNoti("clientDocApprove", loggedInUser.uid, lawyerCase.mandator, userObjData.properties.nickname, 
                          currentMyCase.accepted.lawyer_id, currentMyCase.id, currentMyCase.accepted.lawyer_case_id)
    setShowSuccessMyDocConfirmModal(true);;    
  };


  const reloadData = async () => {
    const requestId = lawyerCase.id;
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_LAYWERCASE_URL
    const query = "?requestId=" + requestId;
    const url = host + query;
    try {
      const { data } = await axios.get(url);
      setNotSubmitted(data.client_req_doc_list);
      setSubmitted(data.client_doc_list);
      const res = await data.client_doc_list.map(async (doc) => {
        const extension = doc.file.slice(((doc.file.lastIndexOf(".") - 1) >>> 0) + 2);
        const file =  await getDownLoadUrls(doc.file);
        return {
          url: file,
          name: doc.content + "." + extension
        }
      })
      const files = await Promise.all(res);
      console.log("files",files);
      setSubmittedDownloadUrlList(files);
    } catch (e) {
      console.error(e);
    }
  };

  const onClickRegisterLawyerOpinion = async (content) => {
    console.log("content??", content);
    // 변호사의 opinion 업데이트!
    const opinionData = {
      createAt: new Date(),
      id: uuidv4(),
      content: content
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_UPLOAD_LAWYER_DOC_LIST_URL
    const body = {
      requestId: lawyerCase.id,
      content: opinionData
    };
        
    const url = host;
    setIsLoading(true);
    try {
      const { data } = await axios.post(url, body);
      console.log("res??", data);
      const docStatusHost = process.env.REACT_APP_FIREBASE_FUNCTION_UPDATE_LAWYER_DOC_STATUS_URL
      const docStatusBody = {
        requestId: lawyerCase.id,
        status: "rejected"
      };
      const { updateStatus } = await axios.post(docStatusHost, docStatusBody);
      console.log("res???", updateStatus);
      await getLawyerCase(lawyerCase.id);
      setIsLoading(false);
      setShowOpinionModal(false);      
      await sendLawyerDocOpinionWebhook(lawyerCase, content);
      await updateUserNoti("clientDocOpinion", loggedInUser.uid, lawyerCase.mandator, userObjData.properties.nickname, 
                            currentMyCase.accepted.lawyer_id, currentMyCase.id, currentMyCase.accepted.lawyer_case_id)
      return data;
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setShowOpinionModal(false);
      return [];
    }
    
  }

  const onClickAllDownloadBtn = (() => {
    submitted.forEach((client_doc) => {
      handleDownloadFile(client_doc);
    })
    // lawyerStorageService
  })

  const handleDownloadFile = async (item) => {
    try {
      const imageRef = ref(lawyerStorageService, item.file);
      const downloadURL = await getDownloadURL(imageRef);
      const response = await fetch(downloadURL);
      const blob = await response.blob();
      const filename = imageRef.name; // 저장할 파일명

      // 파일 다운로드
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE 및 Microsoft Edge의 경우
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const getDownLoadUrls = (async (url) => {
      const imageRef = ref(lawyerStorageService, url);
      const downloadURL = await getDownloadURL(imageRef);
      return downloadURL;
  });

  const onClickDeleteConfirm = async () => {
    setShowConfirmDeleteMyDoc(false);
    await deleteFile(selectedDeleteCandidate);
  };

  const deleteFile = (async (doc) => {
    setIsLoading(true);
    console.log("url",doc.file);
    const file = doc.file;
    // storage에서 삭제 및 lawyer_doc에서도 삭제해야함.
    const imageRef = ref(lawyerStorageService, doc.file);
    // Delete the file
    deleteObject(imageRef).then(async () => {
      // File deleted successfully
      console.log("delete client file success");
      await deleteClientFileFromLawyerCase(file);
      const t = submitted.filter((s) =>  s.file !== file);
      setSubmitted(t);
      const files = await fetchClientDocDownloadList();
      console.log("files",files);
      setSubmittedDownloadUrlList(files);
      setIsLoading(false);
    }).catch((error) => {
      // Uh-oh, an error occurred!
      setIsLoading(false);
      console.log("error delete file", error);
    });

  })

  const reUploadFile = (async (event) => {
    setIsLoading(true);
    console.log("reuploadFile");
    // 파일을 가져온다.
    const prevFile = selectedReuploadFile;
    // 업로드를 한다.    
    const file = event.target.files[0];    
    event.preventDefault();
    await fileReupload(file, prevFile.content, prevFile.desc, prevFile.file);
    await sendClientReUploadWebhook(lawyerCase, prevFile.content);
    await updateUserNoti("clientDocReUpload", loggedInUser.uid, lawyerCase.mandator, userObjData.properties.nickname, 
                            currentMyCase.accepted.lawyer_id, currentMyCase.id, currentMyCase.accepted.lawyer_case_id)
  })

  const fileReupload = async (file, name, desc, prevFile) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('image', file);
    formData.append('id', lawyerCase.id);
    formData.append('content', name);
    formData.append('desc', desc);
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_UPLOAD_CLIENT_FILE_URL
    try {
      const { data } = await axios.post(host, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // 이전 파일을 제거한다.
      const file = prevFile;
      // storage에서 삭제 및 lawyer_doc에서도 삭제해야함.
      const imageRef = ref(lawyerStorageService, file);
      // Delete the file      
      deleteObject(imageRef).then(async () => {
        // File deleted successfully
        console.log("delete client file success");
        await deleteClientFileFromLawyerCase(file);
        await reloadData();        
        const files = await fetchClientDocDownloadList();
        setSubmittedDownloadUrlList(files);
        setIsLoading(false);
      }).catch((error) => {
        // Uh-oh, an error occurred!
        setIsLoading(false);
        console.log("error delete file", error);
      });;
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }    
  }

  const deleteClientFileFromLawyerCase = (async (file) => {
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_REMOVE_CLIENT_DOC_LIST_URL
    const body = {
      requestId: lawyerCase.id,
      file: file,
    };
    const url = host;
    try {
      const { data } = await axios.post(url, body);
      console.log("res??", data);
    } catch (e) {
      console.error(e);
    }
  })

  const onClickLawyerFileList = (() => {
    if (isMobile) {
      history.push({
        pathname: "/preview_file"
      })
    } else {
      setShowModal(true);
    }    
  })

  const onClickClientFileList = ((index) => {
    setClientSubmitData({
      docDataList: submittedDownloadUrlList,
      contentList: submitted,
      currentIdx: index + 1,
    })
    if (isMobile) {
      history.push({
        pathname: "/preview_client_file",
      })
    } else {
      setShowClientDocModal(true);
    }    
  })

  const onClickNotSubmittedPreview = ((idx) => {
    setClientNotSubmitData({
      docDataList: notSubmitted,
      currentIdx: idx + 1,
      selectedCase: lawyerCase,
    })
    if (isMobile) {
      history.push({
        pathname: "/case_not_submit_preview",
      })    
    } else {
      setShowNotSubmittedPreview(true);
    }        
  })

  const onClickAddOpinion = (() => {
    if (isMobile) {
      history.push({
        pathname: "/add_lawyer_file_opinion",
      })
    } else {
      setShowOpinionModal(true);
    }    
  })

  const onClickShowMyOpinion = (() => {
    if (isMobile) {
      history.push({
        pathname: "/confirmMyOpinion",
        state: { contentList: lawyerCase.lawyer_document_opinion_list },
      })
    } else {
      setShowMyDocOpinionModal(true);
    }    
  })

  const setReviewWrapperPaddingBottom = (() => {
    return lawyerCase.lawyer_document_list.length === 0 ? 52.5 : 44.5
  })

  const getDownloadStatus = (() => {
    return submitted.length === 0 ? "inactive" : "active"
  })

  const setReviewUI = (() => {
    if (lawyerCase.lawyer_document_list.length === 0) {
      return <>
      <DataEmptyWrapper>
        <DataEmptyView>
          <DataEmptyIcon src={EmptyViewIcon}/>
          <DataEmptyTitle>사건 서류가 업로드 되지 않았습니다.</DataEmptyTitle>
        </DataEmptyView>
      </DataEmptyWrapper>
      </>
    } else {
      return <>
      {lawyerCase.lawyer_document_list.map((s) => {
        return (
          <ReviewItemWrapper onClick={() => onClickLawyerFileList()}>
              <img src={IcFileClip} style={{ marginRight: 10 }} />
              {s.name}
          </ReviewItemWrapper>
        );
      })}
      <ReviewBtnsWrapper isDone={lawyerCase.lawyer_request_doc_status === "accept"}>
        {lawyerCase.lawyer_request_doc_status === "accept" && 
        <ReviewButtonWrapper isDone={true} onClick={() => onClickShowMyOpinion()}>
          <ReviewButtonTitleDescWrapper>
            <ReviewButtonTitle>내가 작성한</ReviewButtonTitle>
            <ReviewButtonDesc>의견 확인하기</ReviewButtonDesc>
          </ReviewButtonTitleDescWrapper>
          <ReviewButtonIcon src={IcReviewMyOpinion} />
        </ReviewButtonWrapper>
        }
        {(lawyerCase.lawyer_request_doc_status === undefined || lawyerCase.lawyer_request_doc_status === "rejected") &&
        <>
        <ReviewButtonWrapper isDone={false} onClick={() => onClickAddOpinion()}>
          <ReviewButtonTitleDescWrapper>
            <ReviewButtonTitle>사건 서류에 대한</ReviewButtonTitle>
            <ReviewButtonDesc>나의 의견 추가하기</ReviewButtonDesc>
          </ReviewButtonTitleDescWrapper>
          <ReviewButtonIcon src={IcReviewAddOpinion} />
        </ReviewButtonWrapper>
        <ReviewButtonWrapper isDone={false} onClick={() => onClickShowMyOpinion()}>
          <ReviewButtonTitleDescWrapper>
            <ReviewButtonTitle>내가 작성한</ReviewButtonTitle>
            <ReviewButtonDesc>의견 확인하기</ReviewButtonDesc>
          </ReviewButtonTitleDescWrapper>
          <ReviewButtonIcon src={IcReviewMyOpinion} />
        </ReviewButtonWrapper>
        </>
        }
      </ReviewBtnsWrapper>      
      </>
    }
  })

  const setSubmittedUI = (() => {
    if (submitted.length === 0) {
      return <>
          <DataEmptyWrapper style={{background: "none"}}>
            <DataEmptyView>
              <DataEmptyIcon src={EmptyViewIconSubmit}/>
              <DataEmptyTitle>제출된 사건 자료가 없습니다.</DataEmptyTitle>
            </DataEmptyView>
          </DataEmptyWrapper>
      </>
    } else {
      return <>
        <SubmissionGridWrapper>
        {submitted.map((s, index) => {
            const extension = s.file.split(".")[1].toUpperCase();
            return (
              <SubmissionDataWrapper key={s.id}>
                <SubmissionDataIcon src={IcCaseDoc}/>
                <SubmissionDataText onClick={() => onClickClientFileList(index)}>{s.content}</SubmissionDataText>
                {!fromAdmin && 
                <SubmissionDataAcionWrapper>
                  <input
                  type="file"
                  ref={reuploadFileInputRef}
                  style={{ display: 'none' }}
                  onChange={reUploadFile}
                  />
                  <SubmissionDataAction onClick={() => handleReuploadBtnClick(s)}>
                    + 다시 업로드
                  </SubmissionDataAction>
                </SubmissionDataAcionWrapper>
                }                
              </SubmissionDataWrapper>
            );          
          })}
          </SubmissionGridWrapper>
        </>
    }
  })

  if (isLoading) return <Loading loading={isLoading}/>; // loading screen

  const setNotsubmittedUI = (() => {
    if (notSubmitted.length === 0) {
      return <>
          <DataEmptyWrapper style={{background: "none"}}>
            <DataEmptyView>
              <DataEmptyIcon src={EmptyViewIconSubmit}/>
              <DataEmptyTitle>{notSubmitNonText()}</DataEmptyTitle>
            </DataEmptyView>
          </DataEmptyWrapper>
      </>
    } else {
      return <>
          <SubmissionGridWrapper>
            {notSubmitted.map((s, index) => {
              return (
                <SubmissionDataWrapper key={s.id}>
                  <SubmissionDataIcon src={IcCaseDoc}/>
                  <SubmissionDataText onClick={() => onClickNotSubmittedPreview(index)}>
                    {s.title}
                  </SubmissionDataText>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                  />
                  <ImgSubmissionUploadWrapper onClick={() => handleInputClick(s)}>
                    <ImgSubmissionMore src={IcUploadPlus}/>
                    <ImgSubmissionUploadTitle>
                      업로드
                    </ImgSubmissionUploadTitle>
                  </ImgSubmissionUploadWrapper>              
                </SubmissionDataWrapper>
              );
            })}
          </SubmissionGridWrapper>
      </>
    }
  })

  return (
    <>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={ModalStyle}
        ariaHideApp={false}
      >
        <CaseDataFilePreview
          onClickCloseButton={onClickCloseButton}
        />
      </Modal>
      <Modal
        isOpen={showOpinionModal}
        onRequestClose={() => setShowOpinionModal(false)}
        style={ModalStyle}
        ariaHideApp={false}
      >
        <AddLawyerDocOpinion
          onClickRegister={onClickRegisterLawyerOpinion}
          onClikcCloseOpinion={onClickCloseButton}
        />
      </Modal>
      <Modal
        isOpen={showMyDocOpinionModal}
        onRequestClose={() => setShowMyDocOpinionModal(false)}
        style={ModalStyle}
        ariaHideApp={false}
      >
        <ConfirmMyDocOpinion
          onClikcCloseButton={onClickCloseButton}
        />
      </Modal>
      <CustomModal
        isOpen={showClientDocModal}
        onRequestClose={() => setShowClientDocModal(false)}
        style={CaseDocSubmitPreviewModalStyle}
        ariaHideApp={false}
      >
        <CaseDataSubmitPreview
          onClickCloseButton={onClickCloseButton}
        />
      </CustomModal> 
      <Modal
        isOpen={showMyDocConfirmModal}
        onRequestClose={() => setShowMyDocConfirmModal(false)}
        style={isCertification(lawyerCase.serviceType) ? ConfrimLawyerDocCertificationModalStyle : ConfrimLawyerDocModalStyle}
        ariaHideApp={false}
      >
        <ConfirmMyDocAccept
          onClikcConfirmButton={onClickConfirmLawyerDocModal}
          onClickCloseButton={onClickCloseButton}
          serviceType={lawyerCase.serviceType}
        />
      </Modal>
      <Modal
        isOpen={showConfirmDeleteMyDoc}
        onRequestClose={() => setShowConfirmDeleteMyDoc(false)}
        style={ConfrimLawyerDocModalStyle}
        ariaHideApp={false}
      >
        <ConfirmDeleteMyDoc
          onClikcConfirmButton={onClickDeleteConfirm}
          onClickCloseButton={onClickCloseButton}
        />
      </Modal>
      <Modal
        isOpen={showSuccessMyDocConfirmModal}
        onRequestClose={() => setShowSuccessMyDocConfirmModal(false)}
        style={ConfrimLawyerDocModalStyle}
        ariaHideApp={false}
      >
        <SuccessMyDocAccept
          onClikcConfirmButton={onClickCloseButton}
        />
      </Modal>
      <CustomModal
        isOpen={showNotSubmittedPreview}
        onRequestClose={() => setShowNotSubmittedPreview(false)}
        style={CaseDocPreviewModalStyle}
        ariaHideApp={false}
      >
        <CaseDataNotSubmitPreview
          onClickCloseButton={onClickCloseButton}
          onClickFileUpload={onClickFileUpload}
        />
      </CustomModal>   
      <Modal
        isOpen={showSuccessUploadDocModal}
        onRequestClose={() => setShowSuccessUploadDocModal(false)}
        style={ConfrimLawyerDocModalStyle}
        ariaHideApp={false}
      >
        <SuccessUploadDocModal
          onClikcConfirmButton={onClickCloseButton}
        />
      </Modal>
      <SubmissionGuideWrapper onClick={() => onClickSubmissionGuide()}>
        <SubmissionGuideTextWrapper>
          <SubmissionGuideTitle>
            {guideText()}
          </SubmissionGuideTitle>
          <SubmissionGuideDesc>
            사용법 안내 바로가기 >
          </SubmissionGuideDesc>
        </SubmissionGuideTextWrapper>
        <SubmissionGuideImg src={IcSubmitHowToUse}/>
      </SubmissionGuideWrapper>
      <Title style={{ marginBottom: 12 }}>변호사가 전달한 서류 검토하기</Title>
      <SubmissionSubTitle>변호사가 작성한 사건 서류를 검토하고, 서류에 대한 피드백을 주고받을 수 있습니다.</SubmissionSubTitle>
      <ReviewWrapper status={lawyerCase.lawyer_document_list.length === 0 ? "inactive" : "active"} style={{paddingBottom: {setReviewWrapperPaddingBottom}}}>
        <SubTitle style={{ marginBottom: 20 }}>서류 목록</SubTitle>
        {setReviewUI()}
      </ReviewWrapper>
      {(lawyerCase.lawyer_document_list.length !== 0 && lawyerCase.lawyer_request_doc_status !== "accept") && <BtnApproveBaseWrapper>
        <BtnApproveDesc>
        변호사가 작성한 모든 사건 서류를 검토했나요? <br />모든 서류 내용에 동의한다면 최종 승인하기 버튼을 클릭해주세요!
        </BtnApproveDesc>
        <BtnApprove onClick={() => setShowMyDocConfirmModal(true)}>
          사건 서류 최종 승인하기
        </BtnApprove>
      </BtnApproveBaseWrapper>}
      <Title style={{ marginBottom: 12 }}>내가 제출해야 하는 서류</Title>
      <SubmissionDesc>사건처리를 위해 내가 제출해야하는 서류 목록입니다.</SubmissionDesc>
      <SubTitleWrapper>
        <SubTitle>제출 완료</SubTitle>
        <SubButtonWrapper status={getDownloadStatus()} onClick={onClickAllDownloadBtn}>
          <SubButtonIcon src={getDownloadStatus() == "inactive" ? EntireDownloadIconDisabled : EntireDownloadIcon}/>
          <SubButtonTitle status={getDownloadStatus()}>
            전체 다운로드 하기
          </SubButtonTitle>
        </SubButtonWrapper>
      </SubTitleWrapper>
        {setSubmittedUI()}
      <SubTitleWrapper style={{marginTop: 30}}>
        <SubTitle>제출 미완료</SubTitle>        
      </SubTitleWrapper>
        {setNotsubmittedUI()}
      <SubmitNoticeWrapper>
        <SubmitNoticeTitleWrapper>
          <SubmitNoticeTitleImg src={IcSubmitNotice}/>
          <SubmitNoticeTitle>꼭 확인하세요!</SubmitNoticeTitle>
        </SubmitNoticeTitleWrapper>
        <SubmitNoticeDescWrapper>
          <SubmitNoticeDescDash>
            -
          </SubmitNoticeDescDash>
          <SubmitNoticeDesc>
            {noticeDescText()}
          </SubmitNoticeDesc>
        </SubmitNoticeDescWrapper>
      </SubmitNoticeWrapper>
    </>
  );
};

export default CaseSubmission;
