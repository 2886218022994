import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Modal,
} from "antd";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,
  CaseDetailWrapper,
} from "../../../Form.style";
import DaumPostcode from "react-daum-postcode";

import { useEffect, useState } from "react";

const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddressSelect = (data) => {
    const selectedAddress = data.address;
    form.setFieldsValue({
      notallowed: { address1: selectedAddress },
    });
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    console.log(values);
    setData(values);
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
          <Modal
            title="주소 검색"
            open={isModalOpen}
            onCancel={closeModal}
            footer={null}
          >
            <DaumPostcode onComplete={handleAddressSelect} />
          </Modal>
          <Title>임대차 계약연장 불허 내용증명</Title>

          <div style={{ marginTop: 10 }}>
            <SubText style={{ textAlign: "left" }} type="secondary">
            임대차 계약연장 불허 통지 관련하여 아래 내용을 작성하세요.
            </SubText>
          </div>

          <Divider style={{ marginTop: 10 }} />
          <CaseDetailWrapper style={{ height: "90%" }}>
            <Form
              {...itemLayout.form}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={data}
              form={form}
              style={{
                marginTop: 10,
                marginBottom: 20,
                width: "100%",
                textAlign: "left",
              }}
              requiredMark={false}
            >
              <Form.Item
                label="임대차 계약 일자"
                name={["notallowed", "contractDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              <Form.Item
                label="임대차 계약 종료 일자"
                name={["notallowed", "endDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              {/* <Form.Item
                label="임대한 대상 주소"
                name={["notallowed", "address1"]}
                rules={[
                  {
                    required: true,
                    message: "임대한 대상 주소를 입력해주세요",
                  },
                ]}
                onClick={showModal}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="클릭하여 주소를 입력해주세요"
                />
              </Form.Item>
              <Form.Item
                label="상세 주소"
                name={["notallowed", "address2"]}
                rules={[
                  {
                    required: true,
                    message: "임대한 대상 곳 주소를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="상세 주소를 입력해주세요"
                />
              </Form.Item> */}
              <Form.Item
                label="계약 연장을 청구한 일자"
                name={["notallowed", "requestDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              

              <Form.Item
                label="계약 연장을 거절한 이유"
                name={["notallowed", "reason"]}
                rules={[
                  {
                    required: true,
                    message: "계약 연장을 거절한 이유를 선택해주세요",
                  },
                ]}
              >
                <Checkbox.Group style={{ width: "100%" }}>
                  <Row>
                    <Col span={24}>
                      <Checkbox value="집주인 또는 집주인의 직계존, 비속이 거주예정">
                        집주인 또는 집주인의 직계존, 비속이 거주예정
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="세입자의 2회 이상 임대료 연체">
                        세입자의 2회 이상 임대료 연체
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="세입자가 집주인의 허락없이 타인에게 재임대">
                        세입자가 집주인의 허락없이 타인에게 재임대
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="임대한 대상이 재건축될 예정">
                        임대한 대상이 재건축될 예정
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="기타 세입자의 책임져야 하는 사유">
                        기타 세입자의 책임져야 하는 사유
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Row justify="space-between">
                <Col span={6} offset={18} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    다음
                  </Button>
                </Col>
              </Row>
            </Form>
          </CaseDetailWrapper>
        </ContentContainer>
      </MyCaseSection>
    </Container>
  );
};

export default Step1;
