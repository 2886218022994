import { useEffect, useState } from "react";
import IcHealthPilatesServiceMain from "assets/svg/IcHealthPilatesServiceMain.svg";
import IcHealthPilatesServiceMainMobile from "assets/svg/IcHealthPilatesServiceMainMobile.svg";


import { useRecoilValue } from "recoil";
import { userData, redirectEtcUri } from "../../../../states/atoms";
import moment from "moment";

import {
  Container,
} from "../../../Service.style";
import {
  ApplyServiceDescContainer,
  ApplyServiceDescSection,
  ApplyServiceDescText,
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
} from "../../../home/ServiceCenter.style";

import {
  DatePicker,
} from "antd";

import { RadioGroup, Radio } from "../../../home/CommonCriminal/RadioBox";
// import { CheckboxGroup, Checkbox } from "../../../home/CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { useRecoilState } from 'recoil';

import {
  Input,
  Row,
  // Radio
} from "antd";

import { removeBrTag } from "routes/Landing";
import { ApplyServiceSubmit } from "../ApplyServiceSubmit";
import { ApplyServiceInputText, ApplyServiceDatePicker, ApplyServiceSubmitDataContainer, ApplyServiceSubmitDataInfoContainer, ApplyServiceSubmitDataInfoDetailContainer, ApplyServiceSubmitDataInfoDetailTitle, ApplyServiceSubmitDataSection } from "./ApplyService.style";
import { EventFormEachDesc, EventInputTextArea, EventSubmitFooterSection, EventSubmitFooterText, EventSubmitFooterTextContainer } from "components/event/3o3/order/EventSubmit.style";

const { useHistory } = require("react-router-dom");

const ApplyHealthPilatesCertificateService = ({ loggedInUser }) => {
  // 양도 받은 회원권 유무
  const [assignment, setAssignment] = useState("");

  // 계약 체결한 날짜 
  const [contarctDate, setContarctDate] = useState("");

  // 계약 시작한 날짜
  const [startDate, setStartDate] = useState("");

  // 계약 종료 날짜 
  const [endDate, setEndDate] = useState("");

  // 계약 금액
  const [amount, setAmount] = useState(null);

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    setAmount(inputValue)
  }

  // 계약 유형
  const [contractType, setContractType] = useState("");

  const handleContractType = (e) => {
    // const inputValue = e.target.value;
    setContractType(e)
  }

  // 총 받기로 되어있는 횟수.
  const [totalCount, setTotalCount] = useState("");

  const handleTotalCountChange = (e) => {
    const inputValue = e.target.value;
    setTotalCount(inputValue)
  }
  // 실제로 받은 강습 횟수
  const [realCount, setRealCount] = useState("");

  const handleRealCountChange = (e) => {
    const inputValue = e.target.value;
    setRealCount(inputValue)
  }


  //환불 요청한 날
  const [refundDate, setRefundDate] = useState("");

  // 현재 상황에 대한 상세 설명
  const [extraDesc, setExtraDesc] = useState("");

  const handleExtraDescChange = (e) => {
    const inputValue = e.target.value;
    setExtraDesc(inputValue)
  }

  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  const title = () => {
    const text = isMobile
      ? "헬스･필라테스\n내용 증명 보내기"
      : "헬스･필라테스\n내용 증명 보내기";
    return removeBrTag(text);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const serviceData = () => {
    const serviceData = {
      // 전화번호
      contact: "",
      // 첨부파일
      attachments: [],
      contract: {
        count: totalCount,
        notiDate: moment(refundDate).format("YYYY-MM-DD"),
        type: contractType === "회원권" ? "membership" : "pt",
        usedCount: realCount
      },
      description: extraDesc,
      pilates: {
        amount: amount,
        contarctDate: moment(contarctDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        hangover: assignment === "네" ? "y" : "n",
        startDate: moment(startDate).format("YYYY-MM-DD")
      },
      termsOfService: true,
      userId: loggedInUser.uid
    };
    return serviceData;
  }


  const isSubmitReady = () => {
    if (assignment !== "" && contarctDate !== "" && startDate !== "" && endDate !== "" &&
      amount !== null && contractType !== "" && (contractType === "PT 강습권" ? (totalCount !== "" && realCount !== "") : true) &&
      refundDate !== "") {
      return true;
    }
    return false;
  }

  const footerText = () => {
    const text = isSubmitReady()
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄져요. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "모든 항목에 답변해 주세요.\n답변이 완료되면 제출할 수 있어요.";
    return removeBrTag(text);
  }

  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcHealthPilatesServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcHealthPilatesServiceMain}
            />
            <ServiceCenterFirstImg src={IcHealthPilatesServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ApplyServiceSubmitDataSection>
        <ApplyServiceSubmitDataContainer>
          <ApplyServiceSubmitDataInfoContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>양도받은 회원권 인가요?</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={assignment}
                onChange={setAssignment}
              >
                <Radio value="네">네</Radio>
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약을 체결한 날짜를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={contarctDate}
                onChange={setContarctDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약이 시작된 날짜를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={startDate}
                onChange={setStartDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약이 종료된 날짜를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={endDate}
                onChange={setEndDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약 금액을 적어주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceInputText
                value={amount}
                type="number"
                inputMode="numeric"
                onChange={handleAmountChange}
                placeholder="₩" />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약 유형을 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup label="" value={contractType} style={{ width: "100%" }} onChange={handleContractType}>
                <Radio value="회원권" >회원권</Radio>
                <Radio value="PT 강습권" >PT 강습권</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            {contractType.includes("PT 강습권") && <>
              <ApplyServiceSubmitDataInfoDetailContainer>
                <ApplyServiceSubmitDataInfoDetailTitle>총 받기로 되어있는 강습 횟수를 알려주세요.</ApplyServiceSubmitDataInfoDetailTitle>
                <ApplyServiceInputText
                  value={totalCount}
                  onChange={handleTotalCountChange}
                  placeholder="회" />
              </ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailContainer>
                <ApplyServiceSubmitDataInfoDetailTitle>실제로 받은 강습 횟수를 알려주세요.</ApplyServiceSubmitDataInfoDetailTitle>
                <ApplyServiceInputText
                  value={realCount}
                  onChange={handleRealCountChange}
                  placeholder="회" />
              </ApplyServiceSubmitDataInfoDetailContainer>
            </>}
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>환불을 요청한 날을 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <ApplyServiceDatePicker
                style={{ width: "100%" }}
                placeholder="년-월-일"
                value={refundDate}
                onChange={setRefundDate}
              />
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>그 외 특이사항이 있나요?</ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>기타 입력하신 내용 외 특이사항이 있을 경우 작성해 주세요.</EventFormEachDesc>
              <EventInputTextArea value={extraDesc} onChange={handleExtraDescChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
          </ApplyServiceSubmitDataInfoContainer>
        </ApplyServiceSubmitDataContainer>
      </ApplyServiceSubmitDataSection>
      <EventSubmitFooterSection active={isSubmitReady()}>
        <EventSubmitFooterTextContainer>
          <EventSubmitFooterText active={isSubmitReady()}>
            {footerText()}
          </EventSubmitFooterText>
        </EventSubmitFooterTextContainer>
      </EventSubmitFooterSection>
      <ApplyServiceSubmit
        active={isSubmitReady()}
        serviceData={serviceData()}
        serviceType={"pilates_certification"}
        uid={loggedInUser.uid}
      />
    </Container>
  )
};

export default ApplyHealthPilatesCertificateService;
