import styled from "@emotion/styled";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: white;
`;
export const ContentContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const SectionTop = styled.section`
  width: 100%;
  display: flex;
  background: #f5f9fd;
  height: 311px;
  @media screen and (max-width: 768px) {
    height: 370px;
  }
`;

export const EstateServiceCenterSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  alignitems: center;
  padding-top: 165px;
  padding-bottom: 165px;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
    padding-bottom: 0px;
  }
`;

export const CriminalServiceCenterSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  alignitems: center;
  padding-top: 175px;
  padding-bottom: 175px;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
    padding-bottom: 0px;
  }
`;

export const EtcServiceCenterSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  alignitems: center;
  padding-top: 100px;
  padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const EtcServiceLandingSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  alignitems: center;
  padding-top: 80px;
  padding-bottom: 80px;
  @media screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  background: #BDE9FF;
`;

export const PilatesServiceCenterSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  alignitems: center;
  padding-top: 100px;
  padding-bottom: 140px;
  @media screen and (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

export const NoiseServiceCenterSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  alignitems: center;
  padding-top: 175px;
  padding-bottom: 175px;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
    padding-bottom: 0px;
  }
`;

export const AccusationServiceCenterSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  alignitems: center;
  padding-top: 100px;
  padding-bottom: 140px;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
    padding-bottom: 0px;
  }
`;

export const TopWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    margin-top: 77px;
  }
`;

export const TopTitle = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: #1f2e3d;
  margin-bottom: 18px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: normal;
  }
`;
export const TopDesc = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1f2e3d;
  max-width: 634px;
`;

export const DescDivider = styled.div`
  background: #E8E8E8;
  height: 1px;
  width: 100%;
  margin-bottom: 40px;  
  @media screen and (max-width: 768px) {
    margin-bottom: 22px;
  }
`;

export const SubDesc = styled.div`
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 28px;
  margin-bottom: 50px;
  color: #1f2e3d;
  max-width: 634px;
`;
export const TopImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 206px;
  @media screen and (max-width: 768px) {
    right: 15px;
  }
`;

export const CriminalTopImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 108.5px;
  @media screen and (max-width: 768px) {
    width: 62px;
    right: 42px;
  }
`
export const TopImg2 = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 206px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const TopImgMobile = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const Title = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 28px;
`;
export const SubTitle = styled(Title)`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 118px;
`;
export const CollapseWrapper = styled.div`
  margin-top: 120px;
  max-width: 768px;
  @media screen and (max-width: 768px) {
    margin-top: 80px;
    margin-bottom: 86px;
  }
`;
export const DetailWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 130px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 70px;
  }
`;
export const DetailImg = styled.img`
  margin-right: 40px;
  width: 300px;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    margin-right: 0px;
    margin-bottom: 30px;
  }
`;
export const DetailRightWrapper = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const DetailRightLabel = styled.div`
  color: #4091f7;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
    margin-bottom: 5px;
  }
`;
export const DetailRightTitle = styled.div`
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const DetailRightLabelMobile = styled(DetailRightLabel)`
  align-self: flex-start;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
export const DetailRightTitleMobile = styled(DetailRightTitle)`
  align-self: flex-start;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
export const DetailRightDesc = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 30px;
`;
export const DetailRightBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 185px;
  padding: 12px 44px;
  background: #4091f7;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
  border-radius: 8px;
`;

export const BtnConsult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  padding: 16px 34px;
  margin: 0 auto;
  border-radius: 6px;
  background: #3A7EFC;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-top: 23px;
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 85px;
`;
export const Tab = styled.div`
  flex: 1;
  text-align: center;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? "#CBE6FF" : "#F7FBFF")};
  color: ${(props) => (props.isActive ? "#3A7EFC" : "#657584")};
  border: ${(props) =>
    props.isActive ? "1px solid #E7EDF2" : "1px solid #ACD1FF"};
`;
export const TabDivider = styled.div`
  width: 1px;
  height: 22px;
  background-color: black;
`;

export const DetailTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
`;
export const DetailTitle = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  margin-left: 5px;
`;
export const DetailDesc = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 50px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #000000;
  opacity: 0.15;
  margin-bottom: 100px;
`;

export const Label = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 25px;
`;

export const Input = styled.input`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 15px 25px;
  &::placeholder {
    color: #b9b9b9;
  }
  margin-bottom: 40px;
`;

export const TextArea = styled.textarea`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 15px 25px;
  &::placeholder {
    color: #b9b9b9;
  }
  margin-bottom: 40px;
`;
