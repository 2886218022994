import { useEffect, useState } from "react";
import AppRouter from "routes/Router";
import { authService } from "fbase";
import "./App.css";
import axios from "axios";
import Loading from "util/Loading";
import { RecoilRoot } from 'recoil';
import { Helmet } from 'react-helmet-async';

function App() {
  const [init, setInit] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    authService.onAuthStateChanged((user) => {
      setUser(user);
      setInit(true);
      // let sessionTimeout = null;
      // if (user === null) {
      //   // User is logged out.
      //   // Clear the session timeout.
      //   sessionTimeout && clearTimeout(sessionTimeout);
      //   sessionTimeout = null;
      // } else {
      //   // User is logged in.
      //   // Fetch the decoded ID token and create a session timeout which signs the user out.
      //   user.getIdTokenResult().then((idTokenResult) => {
      //     // Make sure all the times are in milliseconds!
      //     const authTime = idTokenResult.claims.auth_time * 1000;
      //     // const sessionDuration = 1000 * 60 * 60 * 24 * 30;
      //     const sessionDuration = 1000 * 0 * 60 * 24;
      //     const millisecondsUntilExpiration =
      //       sessionDuration - (Date.now() - authTime);
      //     sessionTimeout = setTimeout(() => {
      //       authService.signOut();
      //     }, millisecondsUntilExpiration);
      //   });
      // }

      // if (user) {
      //   dbService
      //     .collection("admins")
      //     .doc(user.uid)
      //     .get()
      //     .then(() => {
      //       setIsAdmin(true);
      //     })
      //     .catch(() => {
      //       setIsAdmin(false);
      //     });
      // } else {
      //   setIsAdmin(false);
      // }
    });

    axios.interceptors.request.use(function (config) {
      if(config.url.includes('detection')){
      setIsLoading(true)
      }
      return config
    }, function (error) {
      setIsLoading(false)
        return Promise.reject(error);
    });
    //axios 호출 종료시 인터셉트
    axios.interceptors.response.use(function (response) {      
        setIsLoading(false)
        return response;
    }, function (error) {
        setIsLoading(false)
        return Promise.reject(error);
    });

    // const handleTabClose = () => {
    //   authService.signOut();
    // };

    // window.addEventListener("beforeunload", handleTabClose);
  }, []);

  // useEffect(() => {
  //   console.log("is admin?", isAdmin);
  // }, [isAdmin]);
  return <>{init ? 
    <RecoilRoot>
      <Helmet>
        <title>법대리</title>
        <meta name="description" content="쉽고 간편한 법률 서비스, 법대리" /> 
        <meta property="og:title" content="법대리" />
        <meta property="og:description" content="쉽고 간편한 법률 서비스, 법대리" />
        <meta property="og:url" content="https://by-law.in/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <AppRouter user={user} />
    </RecoilRoot>
     : 
    <RecoilRoot>
      <Helmet>
        <title>법대리</title>
        <meta name="description" content="쉽고 간편한 법률 서비스, 법대리" /> 
        <meta property="og:title" content="법대리" />
        <meta property="og:description" content="쉽고 간편한 법률 서비스, 법대리" />
        <meta property="og:url" content="https://by-law.in/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Loading loading={isLoading}/>
    </RecoilRoot>
  }</>;
}

export default App;
