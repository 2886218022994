import Collapse from "components/common/Collapse";
import { useState } from "react";
import { SERVICE_QNA } from "../../service-constants";
import IcHeadset from "../../assets/svg/IcHeadset.svg";
import ImgServiceLoanStep1 from "../../assets/svg/ImgServiceLoanStep1.svg";
import ImgServiceEstateStep5 from "../../assets/svg/ImgServiceEstateStep5.svg";
import IcGolf from "../../assets/svg/IcGolf.svg";

import {
  Container,
  BtnConsult,
  CollapseWrapper,
  ContentContainer,
  PilatesServiceCenterSection,
  DetailImg,
  DetailRightBtn,
  DetailRightDesc,
  DetailRightTitle,
  DetailRightWrapper,
  DetailWrapper,
  SubTitle,
  Title,
  TopWrapper,
  SectionTop,
  TopTitle,
  TopDesc,
  TopImg2,
  TopImgMobile,
  DetailRightLabel,
  DetailRightLabelMobile,
  DetailRightTitleMobile,
} from "../Service.style";
import { SerivceCenterConsultingSection } from "./ServiceCenter.style";
import Footer from "components/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import ChannelService from "../ChannelService";

const GolfTennisServiceCenter = ({loggedInUser}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <SectionTop style={{ height: 380 }}>
        <ContentContainer style={{ width: "768px" }}>
          <TopWrapper>
            <TopTitle>골프/테니스 환불</TopTitle>
            <TopDesc>
              불공정 계약(할인가 결제등)으로 인해 골프/테니스 환불에 어려움을 겪고 있다면
              <br/>
              법대리 골프/테니스 환불 전문 변호사를 통해 해결해보세요.
            </TopDesc>
          </TopWrapper>
          <TopImg2 style={{"width":"8.5rem","padding-bottom":"1.69rem"}} src={IcGolf} />
          <TopImgMobile style={{"width":"7rem","padding-bottom":"2rem","padding-right":"1.5rem"}} src={IcGolf} />
        </ContentContainer>
      </SectionTop>
      <PilatesServiceCenterSection>
        <ContentContainer>
          <DetailWrapper>
            <DetailRightLabelMobile>STEP 1</DetailRightLabelMobile>
            <DetailRightTitleMobile>
              환불 내용증명 발송하기
            </DetailRightTitleMobile>
            <DetailImg src={ImgServiceLoanStep1} />
            <DetailRightWrapper>
              <DetailRightLabel>STEP 1</DetailRightLabel>
              <DetailRightTitle>환불 내용증명 발송하기</DetailRightTitle>
              <DetailRightDesc>
              골프/테니스 계약은 주로 업체에 유리한 내용으로
              <br />
              작성되어 있습니다.
              <br />
              하지만 계약서에 작성된 내용이라고 해도 소비자 보호법을 위반하거나
              <br />
              공정한 거래를 방해하는 경우 무효화 될 수 있습니다.
              <br />
              법대리 환불 전문 변호사가 직접 작성해서 법무법인 명의로 발송하는
              <br />
              내용증명을 통해 손쉽게 환불 문제를 해결해 보세요.
              </DetailRightDesc>
              <Link to="/golfTennis/certification">
                <DetailRightBtn >
                  신청하기
                </DetailRightBtn>
              </Link>
              
            </DetailRightWrapper>
          </DetailWrapper>
          <DetailWrapper>
            <DetailRightLabelMobile>STEP 2</DetailRightLabelMobile>
            <DetailRightTitleMobile>환불금 지급명령</DetailRightTitleMobile>
            <DetailImg src={ImgServiceEstateStep5} />
            <DetailRightWrapper>
              <DetailRightLabel>STEP 2</DetailRightLabel>
              <DetailRightTitle>환불금 지급명령</DetailRightTitle>
              <DetailRightDesc>
                내용증명 발송 이후에도 환불금을 안 돌려주는 업체에게
                <br />
                변호사가 직접 작성한 지급명령 소송서를 통해 법적으로 해결하세요.
                <br />
                모든 소송 관련 비용은 업체에게 청구할 수 있습니다.
              </DetailRightDesc>
              <Link to="/golfTennis/order">
                <DetailRightBtn>
                신청하기
                </DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          <CollapseWrapper>
            {SERVICE_QNA.pilates.map(({ title, desc }, idx) => (
              <Collapse
                key={`pilates-service$-${idx}`}
                title={title}
                desc={desc}
              />
            ))}
          </CollapseWrapper>
        </ContentContainer>
      </PilatesServiceCenterSection>
      <SerivceCenterConsultingSection>
        <ContentContainer>
          <Title style={{ marginBottom: 10, marginTop: 0 }}>
            기타 골프/테니스 환불 상담
          </Title>
          <SubTitle style={{ marginBottom: 0 }}>
            복잡한 법률 용어, 지식은 모르셔도 됩니다.
            <br />
            법대리가 알려드리는 항목들만 작성 및 첨부해 주시면 법대리의 전문
            변호사단이 검토 후 의견을 드립니다.
          </SubTitle>
          <BtnConsult onClick={()=> {
            //  LandingToChannelTalk();
            if(!loggedInUser){
              ChannelService.loadScript();
              ChannelService.boot({
                "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
              })
            }
            ChannelService.showMessenger();
          }}>
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </BtnConsult>
        </ContentContainer>
      </SerivceCenterConsultingSection>
      <Footer/>
    </Container>
  );
};

export default GolfTennisServiceCenter;
