import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Radio,
  InputNumber,
  Popover,
  Divider,
  Row,
  Col,
  Input,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import TermsOfService from "components/form/commons/TermsOfService";
import React, { useEffect, useState } from "react";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper2,
} from "../../Form.style";

const Step2 = ({ data, setData, onPrev, setDbUpdate, itemLayout }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async (values) => {
    setSubmitting(true);
    setData({ ...values });
    setDbUpdate(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        음주운전 양형자료 작성 정보
      </Title>

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          작성하신 내용을 바탕으로
          <br />
          어떤 양형 자료들을 작성할 지, <br />
          변호사가 직접 검토 후 의견을 드립니다.
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper2>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          form={form}
          style={{
            marginTop: 10,
            marginBottom: 20,
            width:"100%",
            textAlign:"left"
          }}
          requiredMark={false}
        >
          <Form.Item
            label={
              <>
                음주 사유
                <Popover
                  content={
                    <div>
                      사건 당시 부득이하게 음주운전을 하게 된 사유를 적어주세요.
                      <br /> 예) 회식, 대리무응답, 기상악화 등
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["drinkReason"]}
          >
            <Input
              style={{
                width: "100%",
                // maxWidth: 120,
              }}
            />
          </Form.Item>

          <Form.Item
            label="차량매도 가능여부"
            name={["sellingCar"]}
            rules={[
              {
                required: true,
                message: "차량매도 가능여부를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">가능</Radio.Button>
              <Radio.Button value="n">불가능</Radio.Button>
              <Radio.Button value="unknown">모름</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                직업
                <Popover
                  content={
                    <div>
                      직업에 따라 양형자료 작성 방식이 달라집니다.
                      <br /> 예) 학생, 무직, 회사원, 개인사업 등
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["occupation"]}
            rules={[
              {
                required: true,
                message: "현재 직업을 입력해주세요",
              },
            ]}
          >
            <Input
              style={{
                width: "70%",
              }}
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                부양 가족 수
                <Popover
                  content={
                    <div>
                      부양하고 있는 가족의 수를 작성해주세요.
                      <br />
                      부양가족이 없는 경우 0으로 작성해주세요.
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["familyMember"]}
            rules={[
              {
                required: true,
                message: "부양 가족 수를 입력해주세요",
              },
            ]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{
                width: "40%",
              }}
              addonAfter="명"
              controls={false}
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                가계 연간 소득액
                <Popover
                  content={
                    <div>
                      본인 및 배우자의 대략적인 연 소득 금액을 작성해주세요.
                      <br />
                      없는 경우 0으로 작성해주세요.
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["income"]}
            rules={[
              {
                required: true,
                message: "가계 연간 소득액을 입력해주세요",
              },
            ]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{
                width: "70%",
              }}
              addonAfter="원"
              controls={false}
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                가계 부채 규모
                <Popover
                  content={
                    <div>
                      본인 및 배우자에게 빚이 있는 경우 대략적인 금액을
                      작성해주세요. 없는 경우 0으로 작성해주세요.
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["dept"]}
            rules={[
              {
                required: true,
                message: "가계 부채 규모를 입력해주세요",
              },
            ]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{
                width: "70%",
              }}
              addonAfter="원"
              controls={false}
            />
          </Form.Item>

          <Form.Item
            label="봉사활동 기록유무"
            name={["volunteerRecord"]}
            rules={[
              {
                required: true,
                message: "봉사활동 기록유무를 선택해주세요.",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="y">있음</Radio.Button>
              <Radio.Button value="n">없음</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={
              <>
                상세내용
                <Popover
                  content={
                    <div>
                      사건 당시의 상황, 참작할 만한 점, 반성과 피해 회복을 위한
                      노력, 가족관계, 회사 인사 규정, 본인의 신체/사회/경제적
                      상황 등을 최대한 구체적이고 상세하게 작성해주세요.
                      <br />
                      보다 더 자세한 변호사 의견을 받아보실 수 있습니다.
                    </div>
                  }
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={"description"}
          >
            <TextArea rows={10} />
          </Form.Item>

          <Form.Item
            label="연락처"
            name={["contact"]}
            rules={[
              {
                required: true,
                message: "연락처를 입력해주세요",
              },
            ]}
          >
            <Input placeholder="검토 결과를 받으실 연락처를 입력해주세요" />
          </Form.Item>

          <TermsOfService />
          <Row justify="space-between" style={{ marginTop: 20 }}>
            <Col span={6} offset={0} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                onClick={onPrev}
                style={{ width: "100%", maxWidth: "100px" }}
              >
                이전
              </Button>
            </Col>
            <Col span={6} offset={12} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                {submitting ? "제출 중" : "제출"}
              </Button>
            </Col>
          </Row>
        </Form>
        </CaseDetailWrapper2>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step2;
