import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const InheritLimitedRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        한정승인
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="상속포기"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="고인의 사망 날짜" labelStyle={labelStyle}>
            {serviceData.inheritGiveup.date}
          </Descriptions.Item>
          <Descriptions.Item label="상속인수" labelStyle={labelStyle}>
            {serviceData.inheritGiveup.number.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="재산 사용 여부" labelStyle={labelStyle}>
            {serviceData.inheritGiveup.isUsed === "n" ? "없음" : "있음"}
          </Descriptions.Item>
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.inheritGiveup.description
              ? serviceData.inheritGiveup.description.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })
              : ""}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};

export default InheritLimitedRequest;
