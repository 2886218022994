import Collapse from "components/common/Collapse";
import { SERVICE_QNA } from "../../service-constants";
import IcHeadset from "../../assets/svg/IcHeadset.svg";
import EstateHostService1 from "../../assets/svg/EstateHostService1.svg";
import EstateHostService2 from "../../assets/svg/EstateHostService2.svg";
import EstateHostService3 from "../../assets/svg/EstateHostService3.svg";
import ImgServiceEstate from "../../assets/svg/ImgServiceEstate.svg";
import {
  BtnConsult,
  CollapseWrapper,
  Container,
  ContentContainer,
  DetailImg,
  DetailRightBtn,
  DetailRightDesc,
  DetailRightTitle,
  DetailRightWrapper,
  DetailWrapper,
  EstateServiceCenterSection,
  SubTitle,
  Title,
  SectionTop,
  TopDesc,
  TopTitle,
  TopWrapper,
  TopImg,
  DetailRightTitleMobile,
} from "../Service.style";
import { SerivceCenterConsultingSection } from "./ServiceCenter.style";
import useMediaQuery from "hooks/useMediaQuery";
import Footer from "components/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import ChannelService from "../ChannelService";

const EstateHostServiceCenter = ({loggedInUser}) => {
  const isTablet = useMediaQuery(1024);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <SectionTop>
        <ContentContainer style={{ width: "768px" }}>
          <TopWrapper>
            <TopTitle>집주인(임대인) 계약</TopTitle>
            <TopDesc>
            부동산 사건은 법대리가 가장 자신 있는 분야이며, 최상의 고객 만족도를 자랑합니다.
            <br />
            수많은 임대차 문제를 해결한 전문 변호사에게 무료로 검토 받아 보세요.              
            </TopDesc>
          </TopWrapper>
          <TopImg src={ImgServiceEstate} />
        </ContentContainer>
      </SectionTop>
      <EstateServiceCenterSection>
        <ContentContainer>
          {/* step1 */}
          <DetailWrapper>
            <DetailRightTitleMobile>임대차 계약해지 내용증명</DetailRightTitleMobile>
            <DetailImg src={EstateHostService1} />
            <DetailRightWrapper>
              <DetailRightTitle>임대차 계약해지 내용증명</DetailRightTitle>
              <DetailRightDesc>
                임대차 계약을 갱신하지 않고 세입자게에 계약 해지 통보를 하고 싶을때
                <br />
                변호사가 직접 작성한 내용증명을 통해 명확하게 해지 통보를 하세요.
                <br />
                내용증명은 추후 법적 분쟁이 일어나지 않도록
                <br />
                정확하게 계약을 해지 통보 할 수 있는 방법입니다.
              </DetailRightDesc>
              <Link to="/housing/host/cancelcertification">
                <DetailRightBtn>
                  신청하기
                </DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          {/* step2 */}
          <DetailWrapper>
            <DetailRightTitleMobile>월세 미납 청구 내용증명</DetailRightTitleMobile>
            <DetailImg src={EstateHostService2} />
            <DetailRightWrapper>
              <DetailRightTitle>월세 미납 청구 내용증명</DetailRightTitle>
              <DetailRightDesc>
              세입자가 월세, 공과금 및 관리비를 납부하고 있지 않을 경우
              <br />
              미납 대금 청구에 대한 내용증명을 보내 빠르게 조치를 취하세요.
              <br />
              미납 청구 내용증명은 세입자의 월세 납부를 촉구할 뿐만 아니라 
              <br />
              추후 분쟁이 생겼을때 문제가 없도록 하는 방법입니다.
              </DetailRightDesc>
              <Link to="/housing/host/chargecertification">
                <DetailRightBtn>
                  신청하기
                </DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          {/* step3 */}
          <DetailWrapper>
            <DetailRightTitleMobile>임대차 계약연장 불허통지 내용증명</DetailRightTitleMobile>
            <DetailImg src={EstateHostService3} />
            <DetailRightWrapper>
              <DetailRightTitle>
                임대차 계약연장 불허통지 내용증명
              </DetailRightTitle>
              <DetailRightDesc>
              임차인의 임대계약 갱신 요구를 거절하고 싶은 경우
              <br />
              변호사가 직접 작성한 내용증명을 통해 빈틈없이 조치를 취하세요.
              <br />
              내용증명은 임대계약 갱신을 일정 조건 하에 거절할 수 있는   
              <br />
              가장 간단한 방법입니다.         
              </DetailRightDesc>
              <Link to="/housing/host/notallowedcertification">
                <DetailRightBtn>
                신청하기
                </DetailRightBtn>
              </Link>
            </DetailRightWrapper>
          </DetailWrapper>
          <CollapseWrapper>
            {SERVICE_QNA.estateHost.map(({ title, desc }, idx) => (
              <Collapse
                key={`estate-service$-${idx}`}
                title={title}
                desc={desc}
              />
            ))}
          </CollapseWrapper>
        </ContentContainer>
      </EstateServiceCenterSection>
      <SerivceCenterConsultingSection>
        <ContentContainer>
          <Title style={{ marginBottom: 10, marginTop: 0 }}>
            기타 부동산 사건 상담
          </Title>
          <SubTitle style={{ marginBottom: 0 }}>
            복잡한 법률 용어, 지식은 모르셔도 됩니다.
            <br />
            법대리가 알려드리는 항목들만 작성 및 첨부해 주시면 법대리의 전문
            변호사단이 검토 후 의견을 드립니다.
          </SubTitle>
          <BtnConsult onClick={()=> {
            //  LandingToChannelTalk();
            if(!loggedInUser){
              ChannelService.loadScript();
              ChannelService.boot({
                "pluginKey": "1ed57e7b-e6db-499a-85e9-c555c6e5b495"
              })
            }
            ChannelService.showMessenger();
          }}>
            <img src={IcHeadset} style={{ marginRight: 10 }} />
            지금 바로 무료 상담받기
          </BtnConsult>
        </ContentContainer>
      </SerivceCenterConsultingSection>
      <Footer/>
    </Container>
  );
};

export default EstateHostServiceCenter;
