import styled from "@emotion/styled";

export const CommonCriminalContainer = styled.div`
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
    @media screen and (max-width: 768px) {
      gap: 30px;
    }
`

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const EtcTitleWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
`;

export const TitleDescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
`;

export const EtcDescText = styled.div`
    color: #3A7EFC;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 164.286% */
`

export const EtcTitleText = styled.div`
    color: #000;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
`

export const EtcTitleIcon = styled.img`
    margin-top: 3px;
    width: 20px;
    height: 20px;
`;

export const TitleIcon = styled.img`
    width: 20px;
    height: 20px;
`;

export const Title = styled.div`
    color: #000;
    font-family: Pretendard;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px; /* 150% */
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
`

export const TitleBottomBorder = styled.div`
    width: 100%;
    height: 1px;
    background: #E8E8E8;
`

export const InputTextWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
`

export const InputText = styled.input`
    border-radius: 6px;
    border: 1px solid #D3D3D3;
    background: #FFF;    
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    width: 100%;
    background: #ffffff;
    padding: 15px 25px;
    &::placeholder {
        color: #b9b9b9;
    }
`

export const InputTextArea = styled.textarea`
    width: 100%;
    border-radius: 6px;
    height: 214px;
    border: 1px solid #D3D3D3;
    background: #FFF;    
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    width: 100%;
    background: #ffffff;
    padding: 15px 25px;
    &::placeholder {
        color: #b9b9b9;
    }
`

export const SubTitle = styled.div`
    color: #000;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`

export const SubmitButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 11px;
`

export const SubmitButtonContainer = styled.button`
    width: 200px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 74px;
    line-height: 24px; /* 160% */
    border-radius: 6px;
    outline: none;
    border: none;
    background: ${(props) => (props.isActive ? "#3A7EFC" : "#CCDEFF")};
`

export const SubmitButton = styled.div`    
    color: #FFF;
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;    
`

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 85px;
  border-radius: 6px;
  border: 1px solid #E7EDF2;
  background: #F6FBFF;
`;
export const Tab = styled.div`
  flex: 1;
  text-align: center;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? "#CBE6FF" : "#F6FBFF")};
  color: ${(props) => (props.isActive ? "#3A7EFC" : "#657584")};
  border: ${(props) =>
    props.isActive ? "1px solid #ACD1FF" : "none"};
`;

export const LoginRequireWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 50px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #FAFBFC;
`

export const LoginRequireContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const LoginRequireTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 166.667% */
`

export const LoginRequireButton = styled.button`
padding: 16px 34px;
border-radius: 6px;
background: #3A7EFC;
color: #FFF;
text-align: center;
font-family: Pretendard;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
outline: none;
border: none;
cursor: pointer;
`

export const CriminalDriveServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 70px;
  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`

export const CriminalDriveServiceFirstWrapper = styled(CriminalDriveServiceWrapper)`
  gap: 30px;
`

export const CriminalDriveServiceTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 141.667% */
`

export const CriminalDriveServiceDesc = styled(CriminalDriveServiceTitle)`
  font-size: 15px;
  font-family: Pretendard;
  font-weight: 400;
  line-height: 27px; /* 166.667% */
`
export const CriminalDriveServiceBtn = styled.button`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px 34px;
  border-radius: 6px;
  background: #3A7EFC;
  outline: none;
  border: none;
  cursor: pointer;
`
export const CriminalDriveServiceBorder = styled.div`
  width: 100%;
  height: 1px;
  background: #EDEDED;
`

export const CriminalDriveServiceSecondWrapper = styled(CriminalDriveServiceWrapper)`
  width: 100%;
  gap: 60px;
  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`
export const CriminalDriveServiceSecondTitleWrapper = styled(CriminalDriveServiceWrapper)`
  width: 100%;
  gap: 18px;
`

export const CriminalDriveServiceSecondContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 56px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
export const DetailRightBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 185px;
  padding: 1rem 2.125rem;
  background: #4091f7;
  font-family: "Pretendard";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-top:10px;
  border-radius: 8px;
`;




export const CriminalDriveServiceSubTitle = styled(CriminalDriveServiceTitle)`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
`
export const CriminalDriveServiceSecondContentCard = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const CriminalDriveServiceSecondContentImg = styled.img`
`

export const CriminalDriveServiceSecondContentCardDescWrapper = styled(CriminalDriveServiceWrapper)`
  gap: 1.25rem;
`

export const CriminalDriveServiceThirdWrapper = styled(CriminalDriveServiceWrapper)`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 20px;
  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`

export const CriminalDriveServiceContentSection = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const CriminalDriveServiceSection = styled.div`
  flex: 1;
  text-align: center;
  width: 220px;
  padding: 30px;
`

export const CriminalDriveServiceDivider =  styled.div`
  border-left: 2px solid #E2E2E2;
  height: auto;
  margin-top: 60px;
  margin-bottom: 50px;
`

export const CriminalDriveServiceThirdTitleContentWrapper = styled(CriminalDriveServiceWrapper)`
  gap: 40px;
  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`

export const CriminalDriveServiceThirdContentWrapper = styled(CriminalDriveServiceWrapper)`
  gap: 50px;
  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`

export const CriminalDriveServiceThirdContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const CriminalDriveServiceThirdContentRowDescWrapper = styled(CriminalDriveServiceWrapper)`
  gap: 12px;
`

export const CriminalDriveServiceThirdHelpWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #F2F4F6;
  @media screen and (max-width: 768px) {
    gap: 30px;
  }
`

export const CriminalDriveServiceThirdHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`

export const CriminalDriveServiceThirdHelpContentContainer = styled(CriminalDriveServiceThirdHelpContainer)`
  gap: 14px;
`

export const CriminalDriveServiceCenterDesc = styled(CriminalDriveServiceDesc)`
  text-align: center;
`

export const CriminalDriveCollapseWrapper = styled.div`
  margin-top: 110px;
  max-width: 768px;
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 86px;
  }
`;

export const CriminalDriveDrugServiceCenterSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  alignitems: center;
  padding-top: 0px;
  padding-bottom: 140px;
  @media screen and (max-width: 768px) {
    padding-top: 0px;
    padding-bottom: 50px;
  }
`;

export const CommonTabWrapperContainer = styled.div`
  display: flex;
  // align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
`;


export const CommonTabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 38px;
  width: 190px;
  height: 66px;
  // margin: 0 auto;
  margin-bottom: 100px;
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`;
export const CommonTab = styled.div`
  flex: 1;
  text-align: center;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: ${(props) => (props.isActive ? "700" : "500")};
  font-size: 16px;
  line-height: normal;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#000" : "#909090")};
  border-bottom: ${(props) =>
    props.isActive ? "1px solid #000" : "none"};
`;

export const CriminalTopWrapper = styled.div`
width: 100%;
height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`
export const Strong = styled.strong`line-height: 34px;`;
export const Blue = styled.span`
  background-color: #BDE9FF;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Pretendard;
  background-color: #F1F1F1;
  border-radius: 0.625rem;
  width: 150px;
  height: 100px;
  background-color: #E0E0E0;
  margin: 8px;

  @media (max-width: 768px) { 
    margin: 8px 4px;
    width: calc(50% - 16px);
  }
`;

export const Step = styled.div`
  color: #3A7EFC;
  font-weight: bold;
`;

export const Description = styled.div`
  text-align: center;
`;

export const Arrow = styled.div`
  font-size: 24px;
  color: #B1B1B1;
`;

export const ProcessDescibeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 40px;
`

export const ProcessDescibeTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 14px;
`

export const ProcessDescibeTitleText = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 141.667% */
`

export const ProcessDescibeTitleDesc = styled(ProcessDescibeTitleText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
`
export const ProcessDescibeCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 14px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const ProcessDescibeCardRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  @media screen and (max-width: 768px) {
    display: flex;
    height: auto;
    flex-wrap: wrap;
    margin: 0 !important;
    width: 100%;
  }
`
export const ProcessDescibeCard = styled.div`
  display: flex;
  flex: 1 0 20%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  border-radius: 10px;
  background: #F1F1F1;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const ProcessDescibeCardTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`
export const ProcessDescibeCardTitleText = styled.div`
  color: #3A7EFC;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 147.059% */
`
export const ProcessDescibeCardBorder = styled.div`
  width: 272px;
  height: 1px;
  background: #D7D7D7;
`
export const ProcessDescibeCardDesc = styled.div`
  color: #555;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
`

export const ProcessDescibeCardInterRowWrapper = styled.div`
  width: 100%;
  height: 14px;
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: center;
    display: none;
  }
`

export const ProcessDescibeCardInterRowEachWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProcessDescibeCardInterRowIcon = styled.img`
  width: auto;
  height: auto;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const ProcessDescibeCardArrowIconMobile = styled.img`
  width: 14px;
  height: 7px;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`

export const ProcessDescibeCardInterRowMobileWrapper = styled.div`
  width: 100%;
  height: 14px;
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`