import IcDesc2 from "assets/svg/Ic3.3Desc2.svg";
import { EventBodyContainer, EventBodyDesc, EventBodyDescSpan, EventBodyImg, EventBodySection, EventBodyTextContainer, EventBodyTitle, EventDescContainer, EventDescDesc, EventDescSection, EventDescTitle } from "./EventStyle.style"

export const EventDesc2 = () => {
  return (
    <EventBodySection>
      <EventBodyContainer>
        <EventBodyImg src={IcDesc2} />
        <EventBodyTextContainer>
          <EventBodyTitle>
            내용 증명은 법대리에게!
          </EventBodyTitle>
          <EventBodyDesc>
            이런 문제를 가장 빠르고 확실하게 해결할 수 있는 수단이 바로 내용 증명이에요.
            언제, 누가, 무슨 내용을 보냈는지 국가가 확인해주는 매우 강력한 수단이죠.
            소송까지 가지 않고 <EventBodyDescSpan>내용증명 만으로 상대를 압박</EventBodyDescSpan>할 수도 있고
            소송에서 <EventBodyDescSpan>유리한 지위를 갖출 수</EventBodyDescSpan>도 있게 해요.
          </EventBodyDesc>
          <EventBodyDesc>
            내용 증명 보내기 서비스는 법대리에서 <EventBodyDescSpan>가장 많이 찾는 서비스</EventBodyDescSpan> 중 하나이며,
            <EventBodyDescSpan>만족도가 가장 높은 서비스</EventBodyDescSpan> 에요.
          </EventBodyDesc>
        </EventBodyTextContainer>
      </EventBodyContainer>
    </EventBodySection>
  )
}