import { useHistory } from "react-router-dom";

import IcHousingProtectServiceMain from "../../assets/svg/IcHousingProtectServiceMain.svg";
import IcHousingProtectServiceMainMobile from "../../assets/svg/IcHousingProtectServiceMainMobile.svg";
import IcHousingProtectServiceBody from "../../assets/svg/IcHousingProtectServiceBody.svg";
import IcHousingProtectServiceBodyMobile from "../../assets/svg/IcHousingProtectServiceBodyMobile.svg";
import IcHousingProtectServiceBody2 from "../../assets/svg/IcHousingProtectServiceBody2.svg";
import IcHousingProtectServiceBody2Mobile from "../../assets/svg/IcHousingProtectServiceBody2Mobile.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
  ServiceCenterFooterBtn,
  ServiceCenterFooterContainer,
  ServiceCenterFooterDesc,
  ServiceCenterFooterSection,
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle,
  ServiceCenterSecondContainer,
  ServiceCenterSecondDesc,
  ServiceCenterSecondImg,
  ServiceCenterSecondSection,
  ServiceCenterSecondSubTitle,
  ServiceCenterSecondTextContainer,
  ServiceCenterSecondTitle,
  ServiceCenterThirdContainer,
  ServiceCenterThirdDesc,
  ServiceCenterThirdDescSpan,
  ServiceCenterThirdImg,
  ServiceCenterThirdImgMobile,
  ServiceCenterThirdSection,
  ServiceCenterThirdTextContainer,
  ServiceCenterThirdTitle
} from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const HousingProtectServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = "임차권 등기명령";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = isMobile
      ? "임차권 등기명령 이란?"
      : "임차권 등기명령 이란?";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = isMobile
      ? "보증금을 받지 못할 때\n필수로 해야하는 절차"
      : "보증금을 받지 못할 때 필수로 해야하는 절차";
    return removeBrTag(text);
  };

  const body2Title = () => {
    const text = isMobile
      ? "법대리가 가장 잘 하는\n임차권등기명령"
      : "법대리가 가장 잘 하는 임차권등기명령";
    return removeBrTag(text);
  };

  const footerTitle = () => {
    const text = isMobile
      ? "보증금 관련\n도움이 필요하신가요?"
      : "보증금 관련 도움이 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcHousingProtectServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcHousingProtectServiceMain}
            />
            <ServiceCenterFirstImg src={IcHousingProtectServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
              {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              임차권등기명령이란 임대차 종료 후 보증금이 반환되지 않는 경우에 임차인이 임차주택의 관할법원에
              임차권등기명령을 신청할 수 있는 절차입니다.  임차권등기명령 신청이 완료되면 임차인은 전출을 하여도
              대항력과 우선변제권을 가질 수 있습니다. 보증금을 돌려받지 못한 상황에서 이사를 가야 할 경우
              필수로 진행을 해야하는 절차입니다.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcHousingProtectServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcHousingProtectServiceBody}
            />
            <ServiceCenterSecondImg src={IcHousingProtectServiceBody} />
          </picture>
        </ServiceCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterThirdSection>
        <ServiceCenterThirdContainer>
          <ServiceCenterThirdImg src={IcHousingProtectServiceBody2} />
          <ServiceCenterThirdTextContainer>
            <ServiceCenterThirdTitle>
              {body2Title()}
            </ServiceCenterThirdTitle>
            <ServiceCenterThirdDesc>
              법대리는 전국에서 가장 많은 임차권등기명령 사건을 처리하고 있습니다.
              임대차 계약 형태는 모두 다르기 때문에 가장 임차권등기 사건을 많이 처리한 전문 변호사를 통해 신청하는 것이
              보증금을 지킬 수 있는 가장 안전한 방법입니다.  임차권등기명령은 임대인이 보증금을 반환하게 하는 가장 강력한 방법이며,
              보증보험 청구를 위해서도 필수로 진행해야 하는 절차입니다.
            </ServiceCenterThirdDesc>
          </ServiceCenterThirdTextContainer>
          <ServiceCenterThirdImgMobile src={IcHousingProtectServiceBody2Mobile} />
        </ServiceCenterThirdContainer>
      </ServiceCenterThirdSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
              {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면
              분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtn onClick={() => history.push("/apply/housingProtectService")}>
            등기명령 신청하기
          </ServiceCenterFooterBtn>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default HousingProtectServiceCenter;
