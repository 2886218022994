import { useEffect, useState } from "react";
import IcMain from "../../../../assets/svg/IcOnlineScamServiceMain.svg";
import IcMainMobile from "../../../../assets/svg/IcOnlineScamServiceMainMobile.svg";


import { useRecoilValue } from "recoil";
import { userData, redirectEtcUri } from "../../../../states/atoms";

import {
  Container,
} from "../../../Service.style";
import {
  ApplyServiceDescContainer,
  ApplyServiceDescSection,
  ApplyServiceDescText,
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
} from "../../../home/ServiceCenter.style";

import { RadioGroup, Radio } from "../../../home/CommonCriminal/RadioBox";
import { CheckboxGroup, Checkbox } from "../../../home/CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { useRecoilState } from 'recoil';

import {
  Input,
} from "antd";

import { removeBrTag } from "routes/Landing";
import { ApplyServiceSubmit } from "../ApplyServiceSubmit";

const { useHistory } = require("react-router-dom");

const ApplyOnlineScamService = ({ loggedInUser }) => {
  // 지급명령이 필요한 분야
  const [field, setField] = useState("");

  // 상대방 주민번호 혹은 주소 아는지. 
  const [knowPrivateInfo, setKnowPrivateInfo] = useState("");

  // 받아야할 금액의 일부를 반환 받은 여부
  const [partialReturn, setPartialReturn] = useState("");
  const [partialReturnAmount, setPartialReturnAmount] = useState("");

  // 앞으로 받아야할 금액
  const [returnAmount, setReturnAmount] = useState("");

  // 받아야 할 날로부터 며칠이 지났는지.
  const [dayAfterNotReturn, setDayAfterNotReturn] = useState("");

  // 받야아할 금액을 증빙할 자료
  const [proof, setProof] = useState("");

  // 현재 상황에 대한 상세 설명
  const [caseDesc, setCaseDesc] = useState("");


  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  const title = () => {
    const text = isMobile
      ? "중고거래 사기 고소하기"
      : "중고거래 사기 고소하기";
    return removeBrTag(text);
  };

  const applyServiceDescTitle = () => {
    const text = isMobile
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄집니다. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "신청서 제출 후, 전문 변호사의 검토가 이뤄집니다.\n카카오 알림톡으로 검토 결과를 알려드릴게요.";
    return removeBrTag(text);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (checkedValues) => {
    console.log(checkedValues)
  };

  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcMain}
            />
            <ServiceCenterFirstImg src={IcMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ApplyServiceDescSection>
        <ApplyServiceDescContainer>
          <ApplyServiceDescText>
            {applyServiceDescTitle()}
          </ApplyServiceDescText>
        </ApplyServiceDescContainer>
      </ApplyServiceDescSection>
      <ApplyServiceSubmit />
    </Container>
  )
};

export default ApplyOnlineScamService;
