import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const HostChargeCertificationRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };
  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
      월세 청구 내용증명
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="월세 청구 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >

          <Descriptions.Item label="총 연체된 임대료" labelStyle={labelStyle}>
            {serviceData.rentClaim.overdueRent.toLocaleString()}
          </Descriptions.Item>

          <Descriptions.Item
            label="임대표 받지 못한 기간"
            labelStyle={labelStyle}
          >
            {serviceData.rentClaim.period}
          </Descriptions.Item>

          {serviceData.rentClaim.otherAmounts ? (
            <Descriptions.Item label="받지 못한 기타 금액" labelStyle={labelStyle}>
              {serviceData.rentClaim.otherAmounts}
            </Descriptions.Item>
          ) : null}

          <Descriptions.Item
            label="지연이자 청구 여부"
            labelStyle={labelStyle}
          >
            {serviceData.rentClaim.delayedInterest === "n" ? "아니요" : "예"}
          </Descriptions.Item>

          <Descriptions.Item
            label="밀린 임대표 등 지급 기한"
            labelStyle={labelStyle}
          >
            {serviceData.rentClaim.dueDate}
          </Descriptions.Item>

          {/* <Descriptions.Item
            label="계좌 정보 작성 여부"
            labelStyle={labelStyle}
          >
            {serviceData.rentClaim.isAccount === "n" ? "아니요" : "예"}
          </Descriptions.Item>
          {serviceData.rentClaim.name ? (
            <Descriptions.Item label="예금주" labelStyle={labelStyle}>
              {serviceData.rentClaim.name}
            </Descriptions.Item>
          ) : null}
          {serviceData.rentClaim.bank ? (
            <Descriptions.Item label="은행명" labelStyle={labelStyle}>
              {serviceData.rentClaim.bank}
            </Descriptions.Item>
          ) : null}
          {serviceData.rentClaim.date ? (
            <Descriptions.Item label="계좌번호" labelStyle={labelStyle}>
              {serviceData.rentClaim.accountNumber}
            </Descriptions.Item>
          ) : null} */}

          <Descriptions.Item
            label="계약해지 및 명도 요구"
            labelStyle={labelStyle}
          >
            {serviceData.rentClaim.isTermination === "n" ? "아니요" : "예"}
          </Descriptions.Item>
          {serviceData.rentClaim.terminationDate ? (
            <Descriptions.Item label="명도 기한" labelStyle={labelStyle}>
              {serviceData.rentClaim.terminationDate}
            </Descriptions.Item>
          ) : null}

          <Descriptions.Item
            label="임대차 계약 일자"
            labelStyle={labelStyle}
          >
            {serviceData.rentInfo.contractDate}
          </Descriptions.Item>

          {/* <Descriptions.Item
            label="임대차 계약 주소"
            labelStyle={labelStyle}
          >
            {`${serviceData.rentInfo.address1} \n ${serviceData.rentInfo.address1}`}
          </Descriptions.Item> */}

          <Descriptions.Item
            label="임대 시작 일자"
            labelStyle={labelStyle}
          >
            {serviceData.rentInfo.startDate}
          </Descriptions.Item>

          <Descriptions.Item
            label="임대 종료 일자"
            labelStyle={labelStyle}
          >
            {serviceData.rentInfo.endDate}
          </Descriptions.Item>
          
          <Descriptions.Item label="보증금" labelStyle={labelStyle}>
            {serviceData.rentInfo.deposit.toLocaleString()}
          </Descriptions.Item>
          
          <Descriptions.Item label="임대료" labelStyle={labelStyle}>
            {serviceData.rentInfo.rent.toLocaleString()}
          </Descriptions.Item>

          <Descriptions.Item
            label="임대료 지금일"
            labelStyle={labelStyle}
          >
            {serviceData.rentInfo.paymentDate}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="발신인" labelStyle={labelStyle}>
            {serviceData.user.information.sender}
          </Descriptions.Item>
          {/* <Descriptions.Item label="발신인 주소" labelStyle={labelStyle}>
            {`${serviceData.user.information.senderAddress1} \n ${serviceData.user.information.senderAddress2}`}
          </Descriptions.Item> */}
          <Descriptions.Item label="발신인 전화번호" labelStyle={labelStyle}>
            {serviceData.user.information.senderPhoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="수신인" labelStyle={labelStyle}>
            {serviceData.user.information.receiver}
          </Descriptions.Item>
          {/* <Descriptions.Item label="수신인 주소" labelStyle={labelStyle}>
            {`${serviceData.user.information.receiverAddress1} \n ${serviceData.user.information.receiverAddress2}`}
          </Descriptions.Item> */}
          <Descriptions.Item label="수신인 전화번호" labelStyle={labelStyle}>
            {serviceData.user.information.receiverPhoneNumber}
          </Descriptions.Item>

          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.description
              ? serviceData.description.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
          <Descriptions.Item label="프로모션" labelStyle={labelStyle}>
            {serviceData.promotionCode}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};

export default HostChargeCertificationRequest;
