import styled from "@emotion/styled";
import { Drawer } from "antd";

// Header 부분.
export const EventHeaderSection = styled.section`
   display: flex;
   width: 100%;
   padding: 75px 37.5px 50px 37.5px;
   justify-content: center;
 `;

export const EventHeaderContainer = styled.div`
   display: flex;
   width: 100%;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   gap: 30px;
 `;

export const EventHeaderImg = styled.img`
  width: 88px;
  height: auto;
 `

export const EventHeaderTitle = styled.div`
   color: #1F2E3D;
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
 `

export const EventHeaderSubTitle = styled.div`
  color: #1F2E3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
`

export const EventHeaderDesc = styled.div`
  color: #1F2E3D;
  width: 300px;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`

export const EventHeaderDescSpan = styled.span`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`

export const EventDescSection = styled.section`
  display: flex;
  width: 100%;
  padding: 50px 10px;
  justify-content: center;
  align-items: center;
  background: #F5F5F5;
`

export const EventDescContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`

export const EventDescTitle = styled.div`
  color: #1F2E3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
`

export const EventDescDesc = styled.div`
  color: #3A7EFC;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
`

export const EventBodySection = styled.section`
   display: flex;
  width: 100%;
  padding: 50px 0px;
  justify-content: center;
 `;

export const EventBodyContainer = styled.div`
   display: flex;
   width: 100%;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   gap: 30px;
 `;

export const EventBodyImg = styled.img`
 `

export const EventBodyTextContainer = styled.div`
   display: flex;
   width: 300px;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   gap: 15px;
 `;
export const EventBodyTitle = styled.div`
  color: #1F2E3D;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
 `

export const EventBodyDesc = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`

export const EventBodyDescSpan = styled.span`
color: #1F2E3D;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px;
`


export const EventDetailSubmitSection = styled.section`
  display: flex;
  width: 100%;
  padding: 50px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #3A7EFC;
`;

export const EventDetailSubmitContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const EventDetailSubmitTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`

export const EventDetailSubmitTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px; /* 131.25% */
`

export const EventDetailSubmitDesc = styled.div`
  width: 300px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`

export const EventDetailSubmitBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 33px 52px;
  margin: 0 auto;
  border-radius: 20px;
  background: #fff;
  color: #3A7EFC;
  text-align: center;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 100% */
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 768px) {
    padding: 23px 21.5px;
    font-size: 24px;
    line-height: 34px;
  }
`

export const EventDetailFooterSection = styled.section`
  display: flex;
  padding: 50px 0px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: linear-gradient(180deg, #8F8F8F 0%, #B9B9B9 9.96%);
`

export const EventDetailFooterTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const EventDetailFooterText = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

export const EventDetailFooterTextHref = styled.div`
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: inline;
  text-decoration-line: underline;
  cursor: pointer;
`

export const EventDetailDataContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 37.5px;
  padding-right: 37.5px;
  padding-bottom: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
`

export const EventDetailDataEachContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
`

export const EventDetailDataEachTitle = styled.div`
  color: #1F2E3D;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
`

export const EventDetailDataEachContent = styled.div`
  color: #3A7EFC;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
`

export const EventDetailFooterContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 50px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background: #3A7EFC;
`

export const EventDetailFooterTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
`


export const EventDetailFooterBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0px;
  margin: 0 auto;
  border-radius: 20px;
  background: #fff;
  color: #3A7EFC;;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 100% */
  cursor: pointer;
  border-radius: 15px;
  border: none;
  width: 100%;
/* :disabled {
  background: gray;
} */
@media screen and (max-width: 768px) {
  padding: 13px 58.5px;
  font-size: 24px;
  line-height: 34px;
}
`

export const EventDetailDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    height: 447px !important;
  }
  .ant-drawer-body {
    padding-top: 29px;
    padding-left: 0px;
    padding-right: 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .ant-drawer-content {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
`

export const EventDetailDesc = styled.div`
  color: #1F2E3D;
  width: 100%;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`