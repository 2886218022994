import { UserOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Link, useLocation, useHistory } from "react-router-dom";
import IcMobileMenu from "../assets/svg/IcMobileMenu.svg";
import IcNotiOff from "../assets/svg/IcNotiOff.svg";
import IcNotiOn from "../assets/svg/IcNotiOn.svg";
import IcRightArrow from "../assets/svg/IcRightArrow.svg";
import IcLogo from "assets/img/IcLogo.png";
import Ic3o3And from "../assets/svg/Ic3o3And.svg";
import Ic3o3Logo from "assets/img/Ic3o3Logo.png";
import { useEffect, useState } from "react";
import { userObj, currentMyCaseState } from '../states/atoms';
import { useRecoilState } from 'recoil';
import { dbService } from "fbase";

import {
  HeaderContentWrapper,
  HeaderModal,
  HeaderNotiContainer,
  HeaderNotiWrapper,
  HeaderModalTitle,
  MobileMenuImg,
  HeaderNotiBox,
  NotiWrapper,
  NotiImg,
  HeaderNotiTextWrapper,
  HeaderNotiContent,
  HeaderNotiDate,
  HeaderNotiArrowWrapper,
  HeaderNotiArrow,
  Separator,
  MobileHeaderWrapper,
  MobileHeaderRightWrapper,
  HeaderImgWrapper,
  HeaderImg
} from "./CommonLayout.style";
import { getFormattedDate } from "util/util";

const { Header, Content } = Layout;

const bgMap = {
  "/login": "white",
  "/oauth": "white",
};

const borderColorMap = {
  "/": "#E5E8EB",
  "/login": "transparent",
  "/oauth": "transparent",
};

const LoggedMenu = (
  <Menu
    style={{ marginTop: 5 }}
    items={[
      {
        key: "mypage",
        label: (
          <Link style={{ fontSize: 15, margin: 5 }} to="/mypage">
            나의 제출내역
          </Link>
        ),
      },
      {
        key: "logout",
        label: (
          <Link style={{ fontSize: 15, margin: 5 }} to="/logout">
            로그아웃
          </Link>
        ),
      },
    ]}
  />
);

const NotLoggedMenu = (
  <Menu
    style={{ marginTop: 5 }}
    items={[
      {
        key: "login",
        label: (
          <Link style={{ fontSize: 15, margin: 5 }} to="/login">
            로그인
          </Link>
        ),
      },
    ]}
  />
);

const CommonLayout = (props) => {
  const location = useLocation();
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [openNoti, setOpenNoti] = useState(true);

  const [userObjData, setUserObjData] = useRecoilState(userObj);
  const [currentMyCase, setCurrentMyCase] = useRecoilState(currentMyCaseState);

  const threeToThreeUrlsDict = {
    "/3o3/pilates": true, "/3o3/estate": true,
    "/3o3/golfTennis": true, "/3o3/noise": true,
    "/3o3/loan": true, "/3o3/etc": true, "/3o3/pay": true, 
    "/3o3/pilates/": true, "/3o3/estate/": true,
    "/3o3/golfTennis/": true, "/3o3/noise/": true,
    "/3o3/loan/": true, "/3o3/etc/": true, "/3o3/pay/": true, 
    "/3o3/pilates/certificate": true,
    "/3o3/estate/certificate": true,
    "/3o3/golfTennis/certificate": true,
    "/3o3/noise/certificate": true,
    "/3o3/loan/certificate": true,
    "/3o3/etc/certificate": true,
    "/3o3/pay/certificate": true,
    "/3o3/success": true,
  }
  const currentPath = location.pathname

  function is3o3Urls() {
    return threeToThreeUrlsDict[currentPath]
  }


  useEffect(() => {
    closeAllModal();
    if (props.loggedInUser === null) {
      return
    }

    if (props.loggedInUser.uid === null) {
      return
    }
    fetchData(props.loggedInUser.uid)
  }, []);

  const fetchData = async (id) => {
    await dbService
      .collection("users")
      .where("firebaseAuthId", "==", id)
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          const requests = snapshot.docs.map((doc) => {
            return {
              requestId: doc.id,
              id: doc.id,
              ...doc.data(),
            };
          });
          setUserObjData(requests[0]);
        }
      })
      .catch((error) => { });
  };

  const onClickLogoTitle = () => {
    closeAllModal()
    history.push("/");
  }

  const LogoTitle = () => {
    if (is3o3Urls()) {
      return <HeaderImgWrapper>
        <HeaderImg style={{width: 67.623}} src={Ic3o3Logo}/>
        <HeaderImg src={Ic3o3And}/>
        <HeaderImg style={{width: 70}} src={IcLogo}/>
      </HeaderImgWrapper>
    } else {
      return <HeaderImg style={{width: 70}} onClick={() => onClickLogoTitle()} src={IcLogo}/>
    }
  };

  const onClickServiceIntro = () => {
    closeAllModal();
    history.push("/introduction");
  };

  const onClickLivingLaw = () => {
    closeAllModal();
    history.push("/livingLaw");
  };

  const onClickHowtoUse = () => {
    closeAllModal();
    history.push("/howToUse");
  };
  const onClickLogout = () => {
    closeAllModal();
    history.push("/logout");
  };

  const onClickMypage = () => {
    closeAllModal();
    history.push("/mypage");
  };

  const onClickLogin = () => {
    closeAllModal();
    history.push("/login");
  };

  const closeAllModal = () => {
    setOpen(false);
    setOpenNoti(false);
  }

  const onClickNoti = () => {
    if (isMobile) {
      history.push("/noti");
    } else {
      setOpenNoti(!openNoti)
    }
  };

  const NotiIcon = () => {
    return IcNotiOff
    if (userObjData === null) {
      return IcNotiOff
    }
    return userObjData.notiList === undefined ? IcNotiOff : IcNotiOn
  }

  const NotiDataTitle = (dataType) => {
    switch (dataType) {
      case "chat":
        return "변호사가 1:1 문의의 답변을 하였습니다."
      case "clientNotSubmit":
        return "변호사가 제출해야할 사건 자료를 요청하였습니다."
      case "lawyerDoc":
        return "변호사가 사건 서류를 업로드 하였습니다."
      case "proposal":
        return "변호사가 사건에 대한 답변을 완료하였습니다."
      default: return ""
    }
  }

  const onClickNotiMessage = async (notiData) => {
    closeAllModal()
    switch (notiData.type) {
      case "chat":
      case "clientNotSubmit":
      case "lawyerDoc":
        await dbService
          .collection("requests_temp")
          .where("__name__", "==", notiData.client_request_id)
          .get()
          .then((snapshot) => {
            if (snapshot.size > 0) {
              const data = { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
              setCurrentMyCase(data);
              history.push({
                pathname: "/case_detail",
              });
            }
          })
          .catch((error) => {
            history.push("/");
          });
        return
      case "proposal":
        await dbService
          .collection("requests_temp")
          .where("__name__", "==", notiData.client_request_id)
          .get()
          .then((snapshot) => {
            if (snapshot.size > 0) {
              const data = { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
              setCurrentMyCase(data);
              history.push({
                pathname: "/my_case",
              });
            }
          })
          .catch((error) => {
            history.push("/");
          });
        return
      default: return ""
    }
  }


  const RenderNotidata = () => {
    if (userObjData === null) {
      return <></>
    }
    if (userObjData.notiList === undefined) {
      return <></>
    }

    if (userObjData.notiList === null) {
      return <></>
    }
    return <>
      {userObjData.notiList.map((notiData, idx) => {
        return (
          <HeaderNotiBox onClick={() => { onClickNotiMessage(notiData) }}>
            <HeaderNotiTextWrapper>
              <HeaderNotiContent>
                {NotiDataTitle(notiData.type)}
              </HeaderNotiContent>
              <HeaderNotiDate>
                {getFormattedDate(notiData.sendAt)}
              </HeaderNotiDate>
            </HeaderNotiTextWrapper>
            <HeaderNotiArrowWrapper>
              <HeaderNotiArrow src={IcRightArrow} />
            </HeaderNotiArrowWrapper>
          </HeaderNotiBox>
        )
      })}
    </>
  }

  const MenuLayout = () => {
    if (isMobile) {
      return (
        <>
          <MobileHeaderWrapper style={{ justifyContent: is3o3Urls() ? "center" : "space-between"}}>
            <LogoTitle />
            {(!is3o3Urls()) &&
              <MobileHeaderRightWrapper>
                {(props.isLoggedIn) && <>
                  <NotiWrapper onClick={() => onClickNoti()}>
                    <NotiImg className="modal-noti" src={NotiIcon()} />
                  </NotiWrapper>
                </>
                }
                <MobileMenuImg onClick={() => setOpen(true)} src={IcMobileMenu} />
              </MobileHeaderRightWrapper>
            }
          </MobileHeaderWrapper>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <LogoTitle />
            <Link
              to="/introduction"
              onClick={closeAllModal}
              style={{
                color: "#000",
                fontFamily: "Pretendard",
                marginLeft: 50,
              }}
            >
              서비스 소개
            </Link>
            <Link
              to="/livingLaw"
              onClick={closeAllModal}
              style={{
                color: "#000",
                fontFamily: "Pretendard",
                marginLeft: 30,
              }}
            >
              생활법률 이야기
            </Link>
            <Link
              to="/howToUse"
              onClick={closeAllModal}
              style={{
                color: "#000",
                fontFamily: "Pretendard",
                marginLeft: 30,
              }}
            >
              사용법 안내
            </Link>
            <>
              <div
                style={{
                  flex: "1",
                  top: "25%",
                }}
              >
                {props.isLoggedIn ? (
                  <>
                    <div
                      style={{
                        float: "right",
                        color: "#cccccc",
                        display: "flex"
                      }}
                    >
                      {(openNoti) && <>

                        <HeaderNotiWrapper>
                          <HeaderNotiContainer>
                            {RenderNotidata()}
                          </HeaderNotiContainer>
                        </HeaderNotiWrapper>
                      </>}
                      <NotiWrapper onClick={() => onClickNoti()}>
                        <NotiImg className="modal-noti" src={NotiIcon()} />
                      </NotiWrapper>
                      <Link
                        to="/mypage"
                        style={{
                          color: "#000",

                          marginRight: 10,
                        }}
                        onClick={closeAllModal}
                      >
                        <UserOutlined
                          style={{ color: "#000", fontSize: 20, marginRight: 7 }}
                        />
                        나의 제출 목록
                      </Link>
                      |
                      <Link
                        to="/logout"
                        style={{
                          color: "#000",

                          marginLeft: 10,
                        }}
                      >
                        로그아웃
                      </Link>
                    </div>
                  </>
                ) : (
                  <Link
                    to="/login"
                    style={{
                      color: "#000",

                      marginLeft: 30,
                      float: "right",
                    }}
                  >
                    로그인
                  </Link>
                )}
              </div>
            </>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <Layout
        style={{
          minHeight: "100%",
          background: props.isLoggedIn
            ? "#F1F2F6"
            : bgMap[location.pathname] || "#fff",
        }}
      >
        <Header
          style={{
            position: "fixed",
            width: "100%",
            padding: isMobile ? "0 14px" : "0 72px",
            zIndex: 25,
            background: is3o3Urls() ? "#fff" : "#F5F5F5",
            borderBottom: is3o3Urls() ? "none" : `1px solid ${borderColorMap[location.pathname] || "rgba(0,0,0,0.15)"
              }`,
          }}
        >
          <HeaderContentWrapper>
            <MenuLayout />
          </HeaderContentWrapper>
        </Header>
        <HeaderModal
          open={open}
          onCancel={() => setOpen(false)}
          footer={null}
          bodyStyle={{ height: props.isLoggedIn ? "400px" : "320px" }}
          transitionName=""
        >
          <LogoTitle />
          <HeaderModalTitle onClick={onClickServiceIntro}>
            서비스 소개
          </HeaderModalTitle>
          <HeaderModalTitle onClick={onClickLivingLaw}>
            생활법률 이야기
          </HeaderModalTitle>
          <HeaderModalTitle onClick={onClickHowtoUse}>
            사용법 안내
          </HeaderModalTitle>

          {props.isLoggedIn ? (
            <>
              <Separator />
              <HeaderModalTitle onClick={onClickMypage}>
                <UserOutlined
                  style={{ color: "#000", fontSize: 20, marginRight: 7 }}
                />
                나의 제출 목록
              </HeaderModalTitle>
              <Separator />
              <HeaderModalTitle onClick={onClickLogout}>
                로그아웃
              </HeaderModalTitle>
            </>
          ) : (
            <>
              <Separator />
              <HeaderModalTitle onClick={onClickLogin}>로그인</HeaderModalTitle>
            </>
          )}
        </HeaderModal>
        <Content
          style={{
            marginTop: 64,
          }}
        >
          <div>{props.children}</div>
        </Content>
      </Layout>
    </>
  );
};

export default CommonLayout;
