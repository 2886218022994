import { Checkbox } from "components/home/CommonCriminal/CheckBox"
import { ApplyServiceSubmitBtn, ApplyServiceSubmitContainer, ApplyServiceSubmitDesc, ApplyServiceSubmitDescContainer, ApplyServiceSubmitSection, ApplyServiceSubmitTextContainer, ApplyServiceSubmitTitle } from "components/home/ServiceCenter.style"
import { useEffect, useState } from "react"
import { userData } from "states/atoms";
import { removeBrTag } from "routes/Landing";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "fbase";
import { useRecoilValue } from "recoil";
const { useHistory } = require("react-router-dom");

export const ApplyServiceSubmit = (props) => {
  const [isCheck, setIsCheck] = useState(false);
  const history = useHistory();
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  useEffect(() => {

  }, [isCheck])

  const handleCheckboxChange = (checkedValues) => {
    setIsCheck(checkedValues);
  };

  const submitTitle = () => {
    const text = isMobile
      ? "위 내용으로\n제출할까요?"
      : "위 내용으로 제출할까요?";
    return removeBrTag(text);
  };

  const onSubmit = async () => {
    const serviceData = props.serviceData;
    const serviceType = props.serviceType;
    const userId = props.uid;
    console.log("serviceData", serviceData, "serviceType", serviceType, "userId: ", userId);
    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: serviceType,
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: serviceData,
        userId: userId,
        clientName: userInfo.name,
        status: "submitted",
      });
      history.push("/submit/success");
    } catch (err) {
      console.log("errerrerr", err);
      history.push("/submit/fail");
    }
  };

  const isAllReady = () => {
    return props.active && isCheck
  }

  return (
    <ApplyServiceSubmitSection active={isAllReady()}>
      <ApplyServiceSubmitContainer>
        <ApplyServiceSubmitTextContainer>
          <ApplyServiceSubmitTitle>
            {submitTitle()}
          </ApplyServiceSubmitTitle>
          <ApplyServiceSubmitDescContainer>
            <Checkbox
              customStyle={{
                filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                width: "20px",
                height: "20px",
              }}
              onChange={handleCheckboxChange} />
            <ApplyServiceSubmitDesc>
              작성해주신 정보는 전문 변호사에게 공유되며, 모든 비용은 변호사에게 지급됩니다.
              법대리는 중개 서비스로 자문 내용에 책임이 없음을 확인했습니다.
            </ApplyServiceSubmitDesc>
          </ApplyServiceSubmitDescContainer>
        </ApplyServiceSubmitTextContainer>
        <ApplyServiceSubmitBtn disabled={!isAllReady()} active={isAllReady()} onClick={onSubmit}>
          제출하기
        </ApplyServiceSubmitBtn>
      </ApplyServiceSubmitContainer>
    </ApplyServiceSubmitSection>
  )
}