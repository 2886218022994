import { Descriptions, Divider } from "antd";
import { Typography } from "antd";
import { labelStyle } from "../RequestUtil";

const { Title } = Typography;

const AgreementRequest = ({ serviceData, requestData }) => {
  const reactiveColumn = {
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  };

  return (
    <>
      <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
        합의서 작성하기
      </Title>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          backgroundColor: "white",
          padding: 15,
          paddingTop: 5,
          marginBottom: 20,
          borderRadius: 5,
        }}
      >
        <Descriptions
          title="합의서 작성 관련 검토 정보"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="필요한 분야" labelStyle={labelStyle}>
            {serviceData.field}
          </Descriptions.Item>
          
          <Descriptions.Item
            label="기작성된 합의서 여부"
            labelStyle={labelStyle}
          >
            {serviceData.prevAgreement}
          </Descriptions.Item>

          {serviceData.prevAgreement.includes("y") ? (
            <Descriptions.Item label="총 장수" labelStyle={labelStyle}>
              {serviceData.numPrevAgreement}
            </Descriptions.Item>
          ) : null}
        </Descriptions>
        <Descriptions
          title="상세 내용 및 연락처"
          bordered
          column={reactiveColumn}
          size={"small"}
          style={{ marginTop: 20, marginBottom: 15 }}
        >
          <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
            {serviceData.caseDesc
              ? serviceData.caseDesc.split("\n").map((line, idx) => {
                return (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                );
              })
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="연락처" labelStyle={labelStyle}>
            {serviceData.contact}
          </Descriptions.Item>
        </Descriptions>

        {/* <div>
          <div className="ant-descriptions-header">
            <div className="ant-descriptions-title">첨부 파일</div>
          </div>
          {serviceData.attachments.map((attachment) => (
            <Image
              key={attachment}
              width={75}
              height={75}
              src={attachment}
              preview={{ zIndex: 10000 }}
            />
          ))}
        </div> */}
      </div>
    </>
  );
};

export default AgreementRequest;
