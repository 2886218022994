import { 
    CommonCriminalContainer,
    TitleWrapper,
    Title,
    TitleIcon,
    TitleBottomBorder,
    InputTextWrapper,
    InputText,
    InputTextArea,
    SubTitle,
    SubmitButtonWrapper,
    SubmitButton,
    SubmitButtonContainer
 } from "./CommonCriminal.style";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

 import {
  Input,
  Modal,
} from "antd";


import { useEffect, useState } from "react";

import IcCriminalCheck from "../../../assets/svg/IcCriminalCheck.svg";
import { CheckboxGroup, Checkbox } from "./CheckBox";
import { RadioGroup, Radio } from "./RadioBox";
import DaumPostcode from "react-daum-postcode";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

const { useHistory } = require("react-router-dom");


const CriminalSexualCenter = ({loggedInUser}) =>  {
  const [caseType, setCaseType] = useState([]);
  const [clientType, setClientType] = useState("");
  const [address, setAddress] = useState("");
  const [caseDesc, setCaseDesc] = useState("");
  const [etcCaseDesc, setEtcCaseDesc] = useState("");
  const [contact, setContact] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offenderResult, setOffenderResult] = useState("");
  const [etcOffenderResult, setEtcOffenderResult] = useState("");
  const [defenderResult, setDefenderResult] = useState([]);
  const [etcDefenderResult, setEtcDefenderResult] = useState("");
  const history = useHistory();
  const userInfo = useRecoilValue(userData);

  useEffect(() => {
  }, [caseType, contact, etcOffenderResult, etcDefenderResult, etcCaseDesc]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddressSelect = (data) => {
    const selectedAddress = data.address;
    setAddress(selectedAddress);
    setIsModalOpen(false);
  };

  const handleDescChange = (e) => {
    const inputValue = e.target.value;
    setCaseDesc(inputValue)
  }

  const handleEtcCaseDescChange = (e) => {
    const inputValue = e.target.value;
    setEtcCaseDesc(inputValue)
  }

  const handleEtcOffenderReulstChange = (e) => {
    const inputValue = e.target.value;
    setEtcOffenderResult(inputValue)
  }

  const handleEtcDefenderReulstChange = (e) => {
    const inputValue = e.target.value;
    setEtcDefenderResult(inputValue)
  }

  const onChangeContact= (e)=>{
    setContact(e.target.value)
  }

  const checkContainEtcCaseType = () => {
    return caseType.includes("기타");
  }

  const onSubmit = async () => {
    if (clientType === "가해자") {
      const servieData = {
        criminalCaseType: caseType,
        criminalEtcCaseType: checkContainEtcCaseType ? etcCaseDesc : "",
        clientType: clientType,
        expectResult: offenderResult,
        expectOffenderEtcResult: offenderResult.includes("기타") ? etcOffenderResult : "",
        address: address,
        desc: caseDesc,
        contact: contact,
        userId: loggedInUser.uid,
      };
      const uuid = uuidv4();
      try {
        await dbService.collection("requests_temp").add({
          serviceType: "common_criminal_sexual",
          serviceId: uuid,
          createdAt: Date.now(),
          service_data: servieData,
          userId: loggedInUser.uid,
          clientName: userInfo.name,
          status: "submitted",
        });
        history.push("/submit/success");
      } catch (err) {
        history.push("/submit/fail");
      }
    } else if (clientType === "피해자") {
      const servieData = {
        criminalCaseType: caseType,
        criminalEtcCaseType: checkContainEtcCaseType ? etcCaseDesc : "",
        clientType: clientType,
        expectResult: defenderResult,
        expectDefenderEtcResult: defenderResult.includes("기타") ? etcDefenderResult : "",
        address: address,
        desc: caseDesc,
        contact: contact,
        userId: loggedInUser.uid,
      };
      const uuid = uuidv4();
      try {
        await dbService.collection("requests_temp").add({
          serviceType: "common_criminal_sexual",
          serviceId: uuid,
          createdAt: Date.now(),
          service_data: servieData,
          userId: loggedInUser.uid,
          clientName: userInfo.name,
          status: "submitted",
        });
        history.push("/submit/success");
      } catch (err) {
        history.push("/submit/fail");
      }
    } else {
      alert('의뢰인의 입장을 입력해주세요.');
      return;
    }    
  };

  const clientTypeUI = () => {
    if (clientType === "가해자") {
      return <>
        <RadioGroup
          label="의뢰인은 어떤 결과를 원하나요?"
          value={offenderResult}
          onChange={setOffenderResult}
          >
            <Radio value="양형(낮은 형량 주장)">양형(낮은 형량 주장)</Radio>
            <Radio value="무죄 주장">무죄 주장</Radio>
            <Radio value="기타">직접 입력</Radio>
            {offenderResult.includes("기타") && <>
            <InputText style={{marginTop: 20}} value={etcOffenderResult} onChange={handleEtcOffenderReulstChange} placeholder="내용을 입력해주세요."/>
          </>}
        </RadioGroup>
      </>
    } else if (clientType === "피해자") {
      return <>
      <CheckboxGroup
          label="의뢰인은 어떤 결과를 원하나요? (복수선택 가능) "
          values={defenderResult}
          onChange={setDefenderResult}
        >
          <Checkbox value="반드시 처벌받게 하고 싶음">반드시 처벌받게 하고 싶음</Checkbox>
          <Checkbox value="합의금을 받고 마무리하고 싶음">합의금을 받고 마무리하고 싶음</Checkbox>
          <Checkbox value="피해입은 부분을 금전적으로 보상받고 싶음">피해입은 부분을 금전적으로 보상받고 싶음</Checkbox>
          <Checkbox value="기타">
            기타
          </Checkbox>
          {defenderResult.includes("기타") && <>
            <InputText style={{marginTop: 20}} value={etcDefenderResult} onChange={handleEtcDefenderReulstChange} placeholder="내용을 입력해주세요."/>
          </>}
          </CheckboxGroup>
      </>
    }
    return <></>;
  }

  return (
      <CommonCriminalContainer>
          <Modal
              title="주소 검색"
              open={isModalOpen}
              onCancel={closeModal}
              footer={null}
            >
            <DaumPostcode onComplete={handleAddressSelect} />
          </Modal>
          <TitleWrapper>
              <TitleIcon src={IcCriminalCheck}/>
              <Title>
                  간편 접수를 위해 아래 내용을 알려주세요.
              </Title> 
          </TitleWrapper>
          <TitleBottomBorder/>
          <CheckboxGroup
          label="사건명을 선택해주세요. (복수선택 가능)"
          values={caseType}
          onChange={setCaseType}
        >
          <Checkbox value="통신매체이용음란(채팅, 댓글, 사진 또는 url전송)">통신매체이용음란(채팅, 댓글, 사진 또는 url전송)</Checkbox>
          <Checkbox value="카메라 등 기기 이용 촬영(몰카 촬영, 배포)">카메라 등 기기 이용 촬영(몰카 촬영, 배포)</Checkbox>
          <Checkbox value="성희롱">성희롱</Checkbox>
          <Checkbox value="강제추행(성추행)">강제추행(성추행)</Checkbox>
          <Checkbox value="강간(성폭행)">강간(성폭행)</Checkbox>
          <Checkbox value="기타">
            기타 (직접입력)
          </Checkbox>
          {checkContainEtcCaseType() && <>
            <InputText style={{marginTop: 20}} value={etcCaseDesc} onChange={handleEtcCaseDescChange} placeholder="내용을 입력해주세요."/>
          </>}
        </CheckboxGroup>
        <RadioGroup
        label="의뢰인은 어떤 입장에 놓여있나요??"
        value={clientType}
        onChange={setClientType}
        >
          <Radio value="가해자">가해자</Radio>
          <Radio value="피해자">피해자</Radio>
        </RadioGroup>
        {clientTypeUI()}
        <InputTextWrapper>
        <SubTitle>관할 법원 판단을 위해 의뢰인 본인의 주소를 입력해주세요. (시/군/구 단위까지)</SubTitle>
        <InputText value={address} onClick={showModal} placeholder="내용을 입력해주세요. (예 : 서울특별시 강남구)"/>
        </InputTextWrapper>
        <InputTextWrapper>
        <SubTitle>의뢰하고 싶은 상황을 구체적으로 알려주세요.</SubTitle>
        <InputTextArea value={caseDesc} onChange={handleDescChange} placeholder="내용을 입력해주세요."/>
        </InputTextWrapper>
        <InputTextWrapper>
          <SubTitle>연락 가능한 휴대폰 번호를 입력해주세요.</SubTitle>
          <Input style={{height: 64}} value={contact} onChange={onChangeContact} placeholder="0000000000 - 표기 없이 입력해주세요" />
        </InputTextWrapper>
        <SubmitButtonWrapper>
          <SubmitButtonContainer disabled={contact === ""} isActive={contact !== ""} onClick={() => onSubmit()}>
          <SubmitButton>신청하기</SubmitButton>
          </SubmitButtonContainer>
        </SubmitButtonWrapper>
      </CommonCriminalContainer>
  )

}

export default CriminalSexualCenter;

