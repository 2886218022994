import styled from "@emotion/styled";
import Slider from "react-slick";

export const HowToUseTitleSection = styled.section`
  width: 100%;
  padding-top: 114px;
  padding-bottom: 8px;
  @media screen and (max-width: 768px) {
    padding-top: 82px;
    padding-bottom: 0px;
  }
`

export const HowToUseTitleSectionBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  width: 100%;
`

export const HowToUseTitleSectionTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const HowToUseTitleSectionDescRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`

export const HowToUseTitleSectionDescWrapper = styled.div`
  display: flex;    
  padding: 18px 16px;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  background: #F0F1F6;
  width: 100%;
  cursor: pointer;
`
export const HowToUseTitleSectionDescText = styled.div`
    color: #000;
    font-family: Pretendard;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 129.412% */
`

export const HowToUseFirstSection = styled.section`
    width: 100%;
    margin-top: 80px;
    @media screen and (max-width: 768px) {
      margin-top: 90px;
    }
`

export const HowToUseSectionBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 80px;
`

export const HowToUseBodyRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`

export const HowToUseFirstSectionBodyRowHeaderWrapper = styled.div`
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: flex-start;
`

export const HowToUseFirstSectionBodyRowHeaderTitle = styled.div`
  margin: 8px;
  margin-left: 0px;
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 100% */
`

export const HowToUseFirstSectionBodyRowHeaderDescWrapper = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
`

export const HowToUseFirstSectionBodyRowHeaderDescSupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // justify-content: flex-start;
  // align-items: center;
`

export const HowToUseFirstSectionBodyRowHeaderDesc = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  padding-top: 3px;
`

export const HowToUseFirstSectionBodyRowHeaderDescSupport = styled.div`
  color: #3A7EFC;  
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  padding-left: 35px;
`

export const HowToUseFirstSectionBodyRowImgWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`

export const HowToUseFirstSectionBodyRowImg = styled.img`
  width: 100%;
  height: auto;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
`

export const HowToUseFirstSectionBodyImgWrapper = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  background: #F1F2F6;
  justify-content: center;
`

export const HowToUseFirstSectionBodyImgThirdWrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: center;
  padding-top: 45.52px;
  padding-bottom: 45.52px;
`

export const HowToUseFirstSectionBodyImgFourth1Wrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: flext-end;
  padding-top: 49px;
  padding-bottom: 0px;
`

export const HowToUseFirstSectionBodyImgFourth2Wrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: center;
  padding-top: 34px;
  padding-bottom: 23px;
`

export const HowToUseSecondSectionBodyImgFirstWrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: center;
  padding-top: 34px;
  padding-bottom: 23px;
`

export const HowToUseDocSectionBodyImgFirstWrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: center;
  padding-top: 34px;
  padding-bottom: 31px;
`

export const HowToUseDocSectionBodyImgThirdWrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 20px;
`

export const HowToUseDocSectionBodyImgFourth1Wrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: center;
  padding-top: 17px;
  padding-bottom: 17px;
`

export const HowToUseDocSectionBodyImgFourth2Wrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 15px;
`

export const HowToUseDocSectionBodyImgFifthWrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 16.5px;
`

export const HowToUseDocSectionBodyImgSixthWrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: flex-end;
  padding-top: 33px;
  padding-bottom: 0px;
`


export const HowToUseSecondSectionBodyImgSecondWrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: center;
  padding-top: 26px;
  padding-bottom: 31px;
`

export const HowToUseSecondSectionBodyImgThirdWrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: flext-end;
  padding-top: 49px;
  padding-bottom: 0px;
`

export const HowToUseSecondSectionBodyImgThird2Wrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: center;
  padding-top: 26px;
  padding-bottom: 26px;
`

export const HowToUseSecondSectionBodyImgFourthWrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 37px;
`

export const HowToUseSecondSectionBodyImgFourth2Wrapper = styled(HowToUseFirstSectionBodyImgWrapper)`  
  align-items: flex-end;
  padding-top: 17px;
  padding-bottom: 0px;
`

export const HowToUseFirstSectionBodyImg = styled.img`
  width: 260px;
  height: auto;
  flex-shrink: 0;
`

export const HowToUseSecondSection = styled.section`
    width: 100%;
    margin-top: 90px;
`

export const RoundedNumText = styled.div`
  background: #3A7EFC;
  padding-top: 2px;
  width: 19px;
  height: 19px;
  -webkit-border-radius: 9.5px;
  -moz-border-radius: 9.5px;
  border-radius: 9.5px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const CarouselSliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 367px;
  @media screen and (max-width: 768px) {
    height: 467px;
  }
`

export const CarouselSlider = styled(Slider)`
  max-width: 700px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .slick-slide {
    padding: 0 2px;
  }
  .slick-track {
    display: flex;
}
`
export const CarouselCard = styled.div`
  display: flex;
  width: 230px;
  height: 367px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #F1F2F6;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 467px;
  }
`

export const CarouselCardBottom = styled(CarouselCard)`
  align-items: flex-end;
  width: 348px;
  padding-bottom: 0px;
  @media screen and (max-width: 768px) {
    width: auto;
    height: 100%;
  }
`

export const CarouselCardLast = styled(CarouselCard)`
  width: 348px;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: auto;
    height: 100%;
  }
`

export const CarouselCardImg = styled.img`
  width: 210px;  
  @media screen and (max-width: 768px) {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
`

export const CarouselCardWrapper = styled.div`
  width: 230px;
  height: 367px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const CarouselCardBigWrapper = styled(CarouselCardWrapper)`
  width: 348px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 467px;
    background: #F1F2F6;
  }
`

export const HowToUseDocSection = styled.section`
    width: 100%;
    margin-bottom: 30px;
`

export const HowToUseDocContainer = styled.div`
  display: flex;
  height: 241px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
`


export const HowToUseThirdSection = styled.section`
    width: 100%;
    margin-bottom: 30px;
`

export const HowToUseThirdContainer = styled.div`
  display: flex;
  height: 241px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
`

export const HowToUseThirdImg = styled.img`
  width: 30px;
  height: 36px;
`

export const HowToUseThirdText = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 176.471% */
`

export const HowToUseDocTitleDescWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 14px 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background: #F6F6F6;
`
export const HowToUseDocTitleWrapper = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const HowToUseDocTitle = styled.div`
  color: #3A7EFC;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 100% */
`

export const HowToUseDocTitleImg = styled.img`
  width: 24px;
  height: 24px;
`

export const HowToUseDocDescWrapper = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const HowToUseDocDesc = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
`