import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popover,
  Row,
  Modal,
} from "antd";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,
  CaseDetailWrapper,
} from "../../../Form.style";
import DaumPostcode from "react-daum-postcode";
import TermsOfService from "components/form/commons/TermsOfService";

import { useEffect, useState } from "react";
const { TextArea } = Input;

const Step2 = ({ data, setData, onPrev, onNext, itemLayout }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isModalSenderOpen, setIsModalSenderOpen] = useState(false);
  const [isModalReceiverOpen, setIsModalReceiverOpen] = useState(false);

  const showModal = (type) => {
    if (type === 1) {
      setIsModalSenderOpen(true);
    } else {
      setIsModalReceiverOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalSenderOpen(false);
    setIsModalReceiverOpen(false);
  };

  const handleSenderAddressSelect = (data) => {
    const selectedAddress = data.address;
    form.setFieldsValue({
      user: { information: { senderAddress1: selectedAddress } },
    });
    setIsModalSenderOpen(false);
  };
  const handleReceiverAddressSelect = (data) => {
    const selectedAddress = data.address;
    form.setFieldsValue({
      user: { information: { receiverAddress1: selectedAddress } },
    });
    setIsModalReceiverOpen(false);
  };

  const onFinish = async (values) => {
    console.log("values:", values);
    await setData(values);
  };

  useEffect(() => {
    if (data.user.information.receiver) {
      onNext();
    }
  }, [data]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <Modal
          title="주소 검색"
          open={isModalSenderOpen}
          onCancel={closeModal}
          footer={null}
        >
          <DaumPostcode onComplete={handleSenderAddressSelect} />
        </Modal>
        <Modal
          title="주소 검색"
          open={isModalReceiverOpen}
          onCancel={closeModal}
          footer={null}
        >
          <DaumPostcode onComplete={handleReceiverAddressSelect} />
        </Modal>
        <ContentContainer>
          <Title>임대차 계약연장 불허 내용증명</Title>
          <div style={{ marginTop: 10 }}>
            <SubText style={{ textAlign: "left" }} type="secondary">
             임대차 계약연장 불허 통지 관련하여 아래 내용을 작성하세요.
            </SubText>
          </div>

          <Divider style={{ marginTop: 10 }} />
          <CaseDetailWrapper style={{ height: "90%" }}>
            <Form
              {...itemLayout.form}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={data}
              form={form}
              style={{
                marginTop: 10,
                marginBottom: 20,
                width: "100%",
                textAlign: "left",
              }}
              requiredMark={false}
            >
              <Form.Item
                label="발신인 성명"
                name={["user", "information", "sender"]}
                rules={[
                  {
                    required: true,
                    message: "발신인 성명을 입력해주세요",
                  },
                ]}

                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  controls={false}
                  placeholder="김발신"
                />
              </Form.Item>

              {/* <Form.Item
                label="발신인 주소"
                name={["user", "information", "senderAddress1"]}
                rules={[
                  {
                    required: true,
                    message: "발신인 주소를 입력해주세요",
                  },
                ]}
                onClick={() => {
                  showModal(1);
                }}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  controls={false}
                  placeholder="클릭하여 주소를 입력해주세요"
                />
              </Form.Item>
              <Form.Item
                label="상세 주소"
                name={["user", "information", "senderAddress2"]}
                rules={[
                  {
                    required: true,
                    message: "발신인 상세 주소를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="상세 주소를 입력해주세요"
                />
              </Form.Item> */}

              <Form.Item
                label="발신인 전화번호"
                name={["user", "information", "senderPhoneNumber"]}
                rules={[
                  {
                    required: true,
                    message: "발신인 전화번호를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  controls={false}
                  placeholder="010-1111-2222"
                />
              </Form.Item>

              <Form.Item
                label="수신인 성명"
                name={["user", "information", "receiver"]}
                rules={[
                  {
                    required: true,
                    message: "수신인 성명을 입력해주세요",
                  },
                ]}

                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  controls={false}
                  placeholder="김수신"
                />
              </Form.Item>

              {/* <Form.Item
                label="수신인 주소"
                name={["user", "information", "receiverAddress1"]}
                rules={[
                  {
                    required: true,
                    message: "수신인 주소를 입력해주세요",
                  },
                ]}
                onClick={() => {
                  showModal(2);
                }}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  controls={false}
                  placeholder="클릭하여 주소를 입력해주세요"
                />
              </Form.Item>
              <Form.Item
                label="상세 주소"
                name={["user", "information", "receiverAddress2"]}
                rules={[
                  {
                    required: true,
                    message: "수신인 상세 주소를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="상세 주소를 입력해주세요"
                />
              </Form.Item> */}

              <Form.Item
                label="수신인 전화번호"
                name={["user", "information", "receiverPhoneNumber"]}
                rules={[
                  {
                    required: true,
                    message: "수신인 전화번호를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  controls={false}
                  placeholder="010-2222-3333"
                />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    기타내용
                    <Popover
                      content={
                        <div>
                          검토 시 필요한 기타 사항을 500자 내로 작성해주세요
                        </div>
                      }
                      placement="bottom"
                      trigger="click"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  </>
                }
                name={["description"]}
              >
                <TextArea rows={10} maxLength={500} />
              </Form.Item>

              <Form.Item
                label="연락처"
                name={["contact"]}
                rules={[
                  {
                    required: true,
                    message: "연락처를 입력해주세요",
                  },
                ]}
              >
                <Input placeholder="검토 결과 알림을 받으실 연락처를 입력해주세요" />
              </Form.Item>

              <Form.Item label="프로모션 코드" name={["promotionCode"]}>
                <Input />
              </Form.Item>
              <TermsOfService />

              <Row justify="space-between">
                <Col span={6}>
                  <Button
                    type="primary"
                    onClick={onPrev}
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    이전
                  </Button>
                </Col>
                <Col span={6} offset={10} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    {submitting ? "제출 중" : "제출"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </CaseDetailWrapper>
        </ContentContainer>
      </MyCaseSection>
    </Container>
  );
};

export default Step2;
