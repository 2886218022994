import { useState } from "react";

const { Form, Checkbox, Modal, Typography } = require("antd");

const TermsOfService = () => {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState("");
  const modalConstant = {
    aboutLawyerService: {
      title: "변호사 위임 서비스",
      contents:
        "작성하신 내용을 기반으로 변호사에게 위임하는 서비스입니다.\n" +
        "법대리는 제휴한 변호사 전체에게 본 작성 내용을 발송하여 특정 변호사를 소개 및 알선하지 않으며, 그 대가로 중개 수수료 등을 받지 않습니다.\n" +
        "법대리는 변호사 서비스 내용에 대하여 관여하지 않으며 법적 책임을 지지 않습니다.",
    },
    detail: {
      title: "개인정보 동의 및 면책공고",
      contents:
        "본 회사(주식회사 유스티티아) 법대리에서 이루어지는 법률 위임 서비스와 관련하여, 본 회사는 변호사 회원과 의뢰인인 일반 회원 사이의 원활한 서비스가 이루어지도록, 시스템과 프로그램을 제공하고 있습니다. 따라서 본 회사(유스티티아), 법대리는 변호사 회원과 일반 회원 사이에 이루어지는 법률 서비스 내용에 대해 일체 관여나 특정 변호사를 위한 소개나 중개를 하지 아니하고, 변호사 회원으로부터 소개나 중개의 대가로 어떠한 이익이나 금품을 수수하지 아니합니다. 또한 본 회사(유스티티아), 법대리는 변호사 회원의 변호사 고유 업무와 관련해 변호사로부터 보수를 받거나 이익을 분배 받지 않고 있습니다. 유스티티아는 변호사 회원과 일반 회원 사이의 법률 서비스 이용 등에 대한 어떠한 보증이나 책임을 지지 않습니다.\n" +
        "법률 서비스가 원활하게 진행되기 위해서 일반 회원이 작성한 내용들과 의뢰하신 분의 특정을 위해 이에 관련된 일부 개인정보가 변호사 회원님에게 제공되어야만 합니다. 이러한 내용들을 고려하고 일반 회원님이 작성된 내용이 변호사 회원에게 제공되는 것에 동의하신 것을 확인합니다.",
    },
  };
  const openModal = (type) => {
    if (type === "aboutLawyerService") {
      setModalData(modalConstant.aboutLawyerService);
    } else if (type === "detail") {
      setModalData(modalConstant.detail);
    }

    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Form.Item
        name={["termsOfService"]}
        valuePropName="checked"
        wrapperCol={{
          offset: isMobile? 0:4,
          span: 14,
        }}
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(
                    new Error("변호사 검토 서비스 내용을 확인해주세요.")
                  ),
          },
        ]}
      >
        <Checkbox>
          {/* <Typography.Link
            href="#/"
            onClick={(e) => {
              e.preventDefault();
              openModal("aboutLawyerService");
            }}
          > */}
          변호사들에게 회원님의 관련정보가 제공되며, 법대리는 변호사 서비스 비용
          일체를 변호사에게 전달하여 자문 내용에 책임이 없는 점을 확인합니다.{" "}
          <Typography.Link
            href="#/"
            onClick={(e) => {
              e.preventDefault();
              openModal("detail");
            }}
          >
            자세히 보기
          </Typography.Link>
        </Checkbox>
      </Form.Item>

      <Modal
        title={<b>{modalData.title}</b>}
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[]}
      >
        <p>{modalData.contents}</p>
      </Modal>
    </>
  );
};

export default TermsOfService;
