import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Popover,
  Divider,
  Row,
  Col,
  Input,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,  
  CaseDetailWrapper,
} from "../../Form.style";

const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    // console.log(values);
    setData(values);
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
      <Title>
        고소장 작성 및 상세내용
      </Title>

      <div style={{ marginTop: 10 }}>
        <SubText type="secondary">
          입력한 내용을 바탕으로 고소가 가능한지
          <br />
          여부 확인 및 변호사가 고소장 작성을 진행합니다
        </SubText>
      </div>

      <Divider style={{ marginTop: 10 }} />
      <CaseDetailWrapper style={{height:`90%`}}>
        <Form
          {...itemLayout.form}
          layout="horizontal"
          onFinish={onFinish}
          initialValues={data}
          form={form}
          style={{
            marginTop: 10,
            marginBottom: 20,
            width:"100%",
            textAlign:"left"
          }}
          requiredMark={false}
        >
          <Form.Item
            label="사건 발생일"
            name={["accident", "date"]}
            rules={[
              {
                required: true,
                message: "사건 발생일을 선택해주세요",
              },
            ]}
          >
            <DatePicker
              style={{ width: "70%" }}
              placeholder="날짜를 선택하세요"
            />
          </Form.Item>

          <Form.Item
            label="사건 발생 시각"
            name={["accident", "time"]}
            rules={[
              {
                required: true,
                message: "사건 발생 시각을 입력해주세요",
              },
            ]}
          >
            <Input
              style={{
                width: "70%",
              }}
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                사건이 발생한 곳
                <Popover
                  content={<div>인스타그램, 롤, 네이버 카페, 오프라인 등</div>}
                  placement="bottomLeft"
                  trigger="click"
                >
                  <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </>
            }
            name={["accident", "place"]}
            rules={[
              {
                required: true,
                message: "사건이 발생한 곳을 입력해주세요",
              },
            ]}
          >
            <Input
              style={{
                width: "70%",
              }}
            />
          </Form.Item>
          <Row justify="space-between" style={{ marginTop: 20 }}>
            <Col span={6} offset={18} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", maxWidth: "100px" }}
              >
                다음
              </Button>
            </Col>
          </Row>
        </Form>
        </CaseDetailWrapper>
      </ContentContainer>
    </MyCaseSection>
  </Container>
  );
};

export default Step1;
