import React from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import './static/fonts/pretendard.css';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://7d95ab11b2eebefc52a8d65bb73575c3@o4507224392531968.ingest.us.sentry.io/4507224393580544",
    release: "0.1.0",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/by-law\.in/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <HelmetProvider>
        <App />
    </HelmetProvider>
);
