import { useHistory } from "react-router-dom";

import IcServiceMain from "assets/svg/Ic3.3Etc.svg";

import {
  Container,
} from "components/Service.style";

import { useEffect } from "react";
import { removeBrTag } from "routes/Landing";
import { EventHeaderContainer, EventHeaderDesc, EventHeaderDescSpan, EventHeaderImg, EventHeaderSection, EventHeaderSubTitle, EventHeaderTitle } from "../EventStyle.style";
import { EventDesc } from "../EventDesc";
import { EventDesc2 } from "../EventDesc2";
import { EventDetailSubmit } from "../EventDetailSubmit";
import { EventDetailFooter } from "../EventDetailFooter";

const EventEtcServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <EventHeaderSection>
        <EventHeaderContainer>
          <EventHeaderImg src={IcServiceMain} />
          <EventHeaderTitle>
            기타 법률 문제
          </EventHeaderTitle>
          <EventHeaderSubTitle>
            내용 증명으로 시작하기
          </EventHeaderSubTitle>
          <EventHeaderDesc>
            법대리는 <EventHeaderDescSpan>모든 법률 분야의 전문 변호</EventHeaderDescSpan>사가 사건을 해결해드리고 있습니다.
            어떤 문제든지 내용증명 발송이 필요할 때 신청서를 제출 해주시면 전문 변호사가 문제를 해결해 드립니다.
          </EventHeaderDesc>
        </EventHeaderContainer>
      </EventHeaderSection>
      <EventDesc />
      <EventDesc2 />
      <EventDetailSubmit landingPath="/3o3/etc/certificate"/>
      <EventDetailFooter />
    </Container>
  )
};

export default EventEtcServiceCenter;