import styled from "@emotion/styled";

export const PaySuccessLoadingContainer = styled.div`
    position: absolute;
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    background-color: #000000;    
`

export const PaySuccessLoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 22px;
    flex-direction: column;    
    width: 100%;
    height: 100%;
    background-color: #fff;
`

export const PaySuccessLoadingDesc = styled.div`
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
`