import { useEffect, useState } from "react";
import IcMain from "../../../../assets/svg/IcContractServiceMain.svg";
import IcMainMobile from "../../../../assets/svg/IcContractServiceMainMobile.svg";


import { useRecoilValue } from "recoil";
import { userData, redirectEtcUri } from "../../../../states/atoms";

import {
  Container,
} from "../../../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
} from "../../../home/ServiceCenter.style";

import { RadioGroup, Radio } from "../../../home/CommonCriminal/RadioBox";
import { CheckboxGroup, Checkbox } from "../../../home/CommonCriminal/CheckBox";

import { dbService } from "fbase";
import { v4 as uuidv4 } from "uuid";

import { ApplyServiceInputText, ApplyServiceSubmitDataContainer, ApplyServiceSubmitDataInfoContainer, ApplyServiceSubmitDataInfoDetailContainer, ApplyServiceSubmitDataInfoDetailTitle, ApplyServiceSubmitDataSection } from "./ApplyService.style";
import { EventFormEachDesc, EventInputTextArea, EventSubmitFooterSection, EventSubmitFooterText, EventSubmitFooterTextContainer } from "components/event/3o3/order/EventSubmit.style";

import { useRecoilState } from 'recoil';

import {
  Input,
} from "antd";

import { removeBrTag } from "routes/Landing";
import { ApplyServiceSubmit } from "../ApplyServiceSubmit";

const { useHistory } = require("react-router-dom");

const ApplyContractService = ({ loggedInUser }) => {
  // 계약서 검토가 필요한 분야
  const [field, setField] = useState("");
  const [etcField, setEtcField] = useState("");

  function handleChange(checkedValues) {
    setField(checkedValues);
  }

  const handleEtcFieldChange = (e) => {
    const inputValue = e.target.value;
    setEtcField(inputValue)
  }

  const fieldList = [
    "물건 거래 관련 계약서 (납품, 구매, 대여 등)", "지적재산권 거래 계약서 (특허권 양도, 상표권, 저작권 등)",
    "금전 거래 계약서 (차용증, 주식양수도, 증여, 채무이행 등)", "부동산 관련 계약서 (매매, 전세, 전대차, 분양 등)",
    "근로 계약서 (계약직, 프리랜서, 일용직, 연봉 등)", "용역 관련 계약서 (공사, 하도급, 인테리어 등)", "그 외"
  ]

  // 검토를 받고 싶은 내용 작성.
  const [caseDesc, setCaseDesc] = useState("");

  const handleDescChange = (e) => {
    const inputValue = e.target.value;
    setCaseDesc(inputValue)
  }

  // 기작성된 계약서 여부
  const [prevContract, setPrevContract] = useState("");
  const [numPrevContract, setNumPrevContract] = useState("");

  const handleNumPrevContractChange = (e) => {
    const inputValue = e.target.value;
    setNumPrevContract(inputValue)
  }


  const footerText = () => {
    const text = isSubmitReady()
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄져요. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "모든 항목에 답변해 주세요.\n답변이 완료되면 제출할 수 있어요.";
    return removeBrTag(text);
  }

  const serviceData = () => {
    const serviceData = {
      // 전화번호
      contact: "",
      // 첨부파일
      attachments: [],
      field: field,
      etcField: field === "그 외" ? etcField : null,
      prevContract: prevContract === "네" ? "y" : "n",
      numPrevContract: prevContract === "네" ? numPrevContract : null,
      caseDesc: caseDesc,
      userId: loggedInUser.uid
    };
    return serviceData;
  }

  const isSubmitReady = () => {
    if (field !== "" && caseDesc !== "" && prevContract !== "") {
      return true
    }
    return false
  }

  const history = useHistory();

  const [etcPath, setEtcPath] = useRecoilState(redirectEtcUri);

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const userInfo = useRecoilValue(userData);

  const title = () => {
    const text = isMobile
      ? "계약서 검토하기"
      : "계약서 검토하기";
    return removeBrTag(text);
  };

  const applyServiceDescTitle = () => {
    const text = isMobile
      ? "신청서 제출 후, 전문 변호사의 검토가\n이뤄집니다. 카카오 알림톡으로\n검토 결과를 알려드릴게요."
      : "신청서 제출 후, 전문 변호사의 검토가 이뤄집니다.\n카카오 알림톡으로 검토 결과를 알려드릴게요.";
    return removeBrTag(text);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (checkedValues) => {
    console.log(checkedValues)
  };

  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcMain}
            />
            <ServiceCenterFirstImg src={IcMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ApplyServiceSubmitDataSection>
        <ApplyServiceSubmitDataContainer>
          <ApplyServiceSubmitDataInfoContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>계약서 검토가 필요한 분야를 선택해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup label="" value={field} style={{ width: "100%" }} onChange={handleChange}>
                {fieldList.map((value) =>
                  <Radio value={value} >{value}</Radio>
                )}
                {field.includes("그 외") && <>
                  <ApplyServiceInputText style={{ width: "100%" }} value={etcField} onChange={handleEtcFieldChange} placeholder="내용을 입력해주세요." />
                </>}
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>
                이미 작성된 계약서가 있나요?
              </ApplyServiceSubmitDataInfoDetailTitle>
              <RadioGroup
                label=""
                value={prevContract}
                onChange={setPrevContract}
              >
                <Radio value="네">네</Radio>
                {prevContract.includes("네") && <>
                  <ApplyServiceInputText
                    style={{ width: "100%" }}
                    value={numPrevContract} onChange={handleNumPrevContractChange} placeholder="총 몇장으로 구성된 계약서 인가요?" />
                </>}
                <Radio value="아니요">아니요</Radio>
              </RadioGroup>
            </ApplyServiceSubmitDataInfoDetailContainer>
            <ApplyServiceSubmitDataInfoDetailContainer>
              <ApplyServiceSubmitDataInfoDetailTitle>검토를 받고 싶은 내용을 작성해 주세요.</ApplyServiceSubmitDataInfoDetailTitle>
              <EventFormEachDesc>상세 설명은 육하원칙에 따라 간결하게 작성해 주셔야 변호사 검토가 빠르게 이뤄집니다.</EventFormEachDesc>
              <EventInputTextArea value={caseDesc} onChange={handleDescChange} placeholder="내용을 입력해주세요." />
            </ApplyServiceSubmitDataInfoDetailContainer>
          </ApplyServiceSubmitDataInfoContainer>
        </ApplyServiceSubmitDataContainer>
      </ApplyServiceSubmitDataSection>
      <EventSubmitFooterSection active={isSubmitReady()}>
        <EventSubmitFooterTextContainer>
          <EventSubmitFooterText active={isSubmitReady()}>
            {footerText()}
          </EventSubmitFooterText>
        </EventSubmitFooterTextContainer>
      </EventSubmitFooterSection>
      <ApplyServiceSubmit
        active={isSubmitReady()}
        serviceData={serviceData()}
        serviceType={"contract"}
        uid={loggedInUser.uid}
      />
    </Container>
  )
};

export default ApplyContractService;
