import styled from "@emotion/styled";

export const Container = styled.div`
  padding-top: 44px;
  padding-bottom: 0px;  
  font-family: "Pretendard";
  font-style: normal;
  text-align: center;  
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding-top: 12px; 
  }
`;

export const CaseDetailSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
  }
`;

export const ContentContainer = styled.div`
  padding-top: 44px;
  padding-bottom: 0px;
  font-family: "Pretendard";
  font-style: normal;
  max-width: 700px;
  width: 700px;
  text-align: center;  
  margin: 20px;
  margin-bottom: 120px;
  @media screen and (max-width: 768px) {
    padding-top: 12px;
    margin: 16px;
    width: 100%;
  }
`;

export const Title = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 43px;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: normal;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #E4E6EE;
  margin-bottom: 0px;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

export const Tab = styled.div`
  flex: 1;
  background-color: ${(props) => (props.active ? "#FFFFFF" : "#E4E6EE")};
  color: ${(props) => (props.active ? "#3A7EFC" : "#64687B")};
  border-radius: 10px;
  box-shadow: ${(props) => (props.active ? "0 0 0 1px #3A7EFC inset" : "none")};
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;
