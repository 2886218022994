import React, { useEffect} from 'react'
import ReactLoading from 'react-loading';

const Loading = (props) => {
  const check = props.loading == null ? false : props.loading

  return (
    check &&
    <div className='axios-loading' style={{display:props.loading===true? 'block':'none', margin: '0 auto', width: "100%",
    height: "100%"}}>
        <div className='axios-loading-indicator' style={{
        position:"absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}>
            <ReactLoading type={"spin"} color={"#3a7efc"} height={'10%'} width={'10%'}/>
        </div>
    </div>
  )

}

export default Loading