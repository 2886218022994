import { openInNewTab } from "util/Handler"
import { EventDetailFooterSection, EventDetailFooterText, EventDetailFooterTextContainer, EventDetailFooterTextHref } from "./EventStyle.style"

export const EventDetailFooter = () => {
  return (
    <EventDetailFooterSection>
      <EventDetailFooterTextContainer>
        <EventDetailFooterText>
        이미 내용 증명을 보내고도<br/>해결이 안됐다면,<br/><br/>
        <EventDetailFooterTextHref onClick={() => openInNewTab("https://by-law.in/")}
        >법대리 홈페이지
        </EventDetailFooterTextHref>에서
        <br/>추가 상담 받아보세요.
        </EventDetailFooterText>
      </EventDetailFooterTextContainer>
    </EventDetailFooterSection>
  )
}