import { useRecoilState } from 'recoil';
import { redirectUri } from '../states/atoms.js'
import { 
  CommonCriminalContainer,
  LoginRequireWrapper,
  LoginRequireContainer,
  LoginRequireTitle,
  LoginRequireButton
} from "../components/home/CommonCriminal/CommonCriminal.style";
import {
  ContentContainer,
} from "../components/Service.style";



const { useHistory } = require("react-router-dom");

const ChanneltalkLogin = () => {
  const [path, setPath] = useRecoilState(redirectUri);
  const history = useHistory();
  const onClickLogin = () => {    
    setPath("/");
    history.push("/login")    
  }

    
  return (
    <>
      <ContentContainer style={{marginTop:150,width: 630}}>
        <CommonCriminalContainer>
          <LoginRequireWrapper>
            <LoginRequireContainer>
              <LoginRequireTitle>
              법대리 로그인 후<br/>상담 서비스를 사용하실 수 있습니다.
              </LoginRequireTitle>
              <LoginRequireButton onClick={() => onClickLogin()}>
                법대리 로그인 하기
              </LoginRequireButton>
            </LoginRequireContainer>
          </LoginRequireWrapper>
        </CommonCriminalContainer>
      </ContentContainer>
    </>
  );
}

export default ChanneltalkLogin;
