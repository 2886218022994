import styled from "@emotion/styled";

export const Container = styled.div`
  padding-bottom: 0px;  
  font-family: "Pretendard";
  font-style: normal;
  text-align: center;  
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding-top: 0px; 
  }
`;

export const MyCaseSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
  }
`;

export const ContentContainer = styled.div`
  padding-top: 44px;
  padding-bottom: 0px;
  font-family: "Pretendard";
  font-style: normal;
  max-width: 562px;
  width: 562px;
  text-align: center;  
  margin: 20px;
  margin-bottom: 120px;
  @media screen and (max-width: 768px) {
    padding-top: 12px;
    margin: 16px;
    width: 100%;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 17px;
  text-align: left;
`;

export const SuggestTitle = styled(Title)`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 36px;
  text-align: left;
`;

export const DateText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #767C87;
  margin-bottom: 35px;
  text-align: left;
`;

export const CaseDetailWrapper = styled.div`
  padding: 20px 30px;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 0px #D3DAE4;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  cursor: pointer;
  margin-bottom: 64px;
`;

export const CaseDetailTextWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export const LawyerCardWrapper = styled.div`
  padding: 26px 30px 20px 30px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 0px #D3DAE4;
  margin-bottom: 14px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    padding: 26px 30px 20px 30px;
    height: auto;
  }
`;
export const NoLawyerCardWrapper= styled.div`
  font-size: 24px;
  color: #999; 
  padding: 0 10px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;


export const LawyerCardTopWrapper = styled.div`
  width: 100%;
  /* padding: 25px 30px; */
  height: 60px;
  display: flex;
  // align-items: stretch;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    height: 60px;
  }
`;
export const LawyerCardProfile = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;
export const LawyerCardProfileBlank = styled.div`
  width: 99px;
  height: 99px;
  border-radius: 50%;
  background: #d9d9d9;
`;
export const LawyerCardTextWrapper = styled.div`
  flex: 1;
  margin-left: 27px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const LawyerCardTextAuthWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LawyerCardTextTitleIcon = styled.img`
  width: 11.7px;
  height: 14px;
  margin-left: 8px;
  margin-right: 4px;
`;

export const LawyerCardTextTitleAuth = styled.div`
  color: #00C968;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const LawyerCardNameLabel = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

export const LawyerCardDescLabel = styled(LawyerCardNameLabel)`
  font-size: 13px;
  font-weight: 400;
  margin-top: 2px;
  @media screen and (max-width: 768px) {
    font-size: 13px;
    margin-top: 2px;
  }
`;

export const LawyerCardLabel = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.203px;
  color: #000000;
  width: 133px;
  text-align: left;
  margin-top: 2px;
  @media screen and (max-width: 768px) {
    font-size: 13px;
    margin-top: 2px;
  }
`;

export const LawyerCardBottomWrapper = styled.div`
  width: 100%;
  height: 31px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    height: 31px;
  }
`;

export const LawyerCardProposeWrapper = styled.div`
  display: flex;
  gap: 26px;
  margin-bottom: 5.5px;
`;

export const LawyerCardProposeWrapperTitle = styled.div`
  color: #676767;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    font-weight: 500;
  }
`;

export const LawyerCardProposeWrapperValue = styled.div`
  color: var(--color-primary, #3A7EFC);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

export const LawyerCardProposeBtn = styled.div`
  width: 99px;
  padding: 13px 20px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
    padding: 7px 10px;
    height: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  background: var(--color-primary, #3A7EFC);
  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 7px 10px;
    height: 31px;
  }
`;

export const LawyerCardCenterBorder = styled.div`
  width: 100%;
  background: rgba(212, 212, 212, 0.60);
  height: 1px;
  margin-top: 28px;
  margin-bottom: 14px;
  @media screen and (max-width: 768px) {
    margin-top: 28px;
    margin-bottom: 14px;
  }
`;

export const LawyerTag = styled.span`
  background: #f85b46;
  border-radius: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  padding: 4px 6px;
  margin-left: 12px;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`