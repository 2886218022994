import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Radio,
  Row,
  Modal,
} from "antd";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,
  CaseDetailWrapper,
} from "../../Form.style";
import DaumPostcode from "react-daum-postcode";

import { useEffect, useState } from "react";
const { TextArea } = Input;

const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddressSelect = (data) => {
    const selectedAddress = data.address;
    form.setFieldsValue({
      noise: { information: { address1: selectedAddress } },
    });
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    console.log(values);
    setData(values);
    onNext();
  };

  const onDueDateUnknownChanged = (name) => {
    const { loans } = form.getFieldsValue();
    loans[name].dueDate.value = null;
    form.setFieldsValue({ loans });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
          <Modal
            title="주소 검색"
            open={isModalOpen}
            onCancel={closeModal}
            footer={null}
          >
            <DaumPostcode onComplete={handleAddressSelect} />
          </Modal>
          <Title>층간 소음 내용증명</Title>

          <div style={{ marginTop: 10 }}>
            <SubText style={{ textAlign: "left" }} type="secondary">
              층간 소음에 관련하여 아래 내용을 작성하세요.
            </SubText>
          </div>

          <Divider style={{ marginTop: 10 }} />
          <CaseDetailWrapper style={{ height: "90%" }}>
            <Form
              {...itemLayout.form}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={data}
              form={form}
              style={{
                marginTop: 10,
                marginBottom: 20,
                width: "100%",
                textAlign: "left",
              }}
              requiredMark={false}
            >
              <Form.Item
                label="소음 시작 일자"
                name={["noise", "date"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              {/* <Form.Item
                label="층간소음이 일어나는 곳 주소"
                name={["noise", "information", "address1"]}
                rules={[
                  {
                    required: true,
                    message: "층간소음이 일어나는 곳 주소를 입력해주세요",
                  },
                ]}
                onClick={showModal}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="클릭하여 주소를 입력해주세요"
                />
              </Form.Item>
              <Form.Item
                label="상세 주소"
                name={["noise", "information", "address2"]}
                rules={[
                  {
                    required: true,
                    message: "층간소음이 일어나는 곳 주소를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="상세 주소를 입력해주세요"
                />
              </Form.Item> */}

              <Form.Item
                label="소음의 종류"
                name={["noise", "information", "noiseType"]}
                rules={[
                  {
                    required: true,
                    message: "소음의 종류를 선택해주세요",
                  },
                ]}
              >
                <Checkbox.Group style={{ width: "100%" }}>
                  <Row>
                    <Col span={12}>
                      <Checkbox value="쿵쿵거리는 발소리">
                        쿵쿵거리는 발소리
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="고성방가·괴성">고성방가·괴성</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="TV·악기·노래소리">
                        TV·악기·노래소리
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="아이·애완견으로 인한 소음">
                        아이·애완견으로 인한 소음
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="보수 공사 등">보수 공사 등</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="기타">기타</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                label={
                  <>
                    손해 배상 청구 유무
                    <Popover
                      content={
                        <div>
                          - 치료비 20만 원
                          <br />
                          - 위자료 100만 원
                          <br />
                          위와 같은 손해 배상을 청구하려면
                          <br />
                          '예' 를 눌러 작성해주세요.
                          <br />
                        </div>
                      }
                      placement="bottom"
                      trigger="click"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  </>
                }
                name={["noise", "compensation", "exist"]}
                rules={[{ required: true, message: "" }]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Radio.Group>
                  <Radio.Button value="y">예</Radio.Button>
                  <Radio.Button value="n">경고로만 그침</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (
                    prevValues.noise.compensation.exist !==
                    currentValues.noise.compensation.exist
                  );
                }}
              >
                {() => (
                  <>
                    <Form.Item
                      label="손해배상 이유"
                      style={{ marginBottom: 0 }}
                      hidden={
                        form.getFieldValue([
                          "noise",
                          "compensation",
                          "exist",
                        ]) === "n"
                      }
                    >
                      <Form.Item
                        name={["noise", "compensation", "reason"]}
                        rules={
                          form.getFieldValue([
                            "noise",
                            "compensation",
                            "exist",
                          ]) === "n"
                            ? []
                            : [
                                {
                                  required: true,
                                  message:
                                    "손해가 발생하게 된 이유와 금액을 입력해주세요",
                                },
                              ]
                        }
                        // style={{ width: "calc(85% - 8px)" }}
                      >
                        <Input style={{ width: "100%" }} controls={false} />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      label="손해배상액 합계"
                      style={{ marginBottom: 0 }}
                      hidden={
                        form.getFieldValue([
                          "noise",
                          "compensation",
                          "exist",
                        ]) === "n"
                      }
                    >
                      <Form.Item
                        name={["noise", "compensation", "amount"]}
                        style={{
                          width: "80%",
                          // maxWidth: 150,
                          display: "inline-block",
                        }}
                        rules={
                          form.getFieldValue([
                            "noise",
                            "compensation",
                            "exist",
                          ]) === "n"
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "금액을 입력해주세요",
                                },
                              ]
                        }
                      >
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          addonBefore="금"
                          addonAfter="원"
                          controls={false}
                        />
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label="지급기한"
                      name={["noise", "compensation", "date"]}
                      hidden={
                        form.getFieldValue([
                          "noise",
                          "compensation",
                          "exist",
                        ]) === "n"
                      }
                      rules={
                        form.getFieldValue([
                          "noise",
                          "compensation",
                          "exist",
                        ]) === "n"
                          ? []
                          : [
                              {
                                required: true,
                                message: "날짜를 선택해주세요",
                              },
                            ]
                      }
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="날짜를 선택하세요"
                      />
                    </Form.Item>

                    <Form.Item
                      hidden={
                        form.getFieldValue([
                          "noise",
                          "compensation",
                          "exist",
                        ]) === "n"
                      }
                      label="계좌 정보 작성 여부"
                      name={["noise", "compensation", "isAccount"]}
                      rules={[{ required: true, message: "" }]}
                      // style={{ width: "calc(85% - 8px)" }}
                    >
                      <Radio.Group>
                        <Radio.Button value="y">예</Radio.Button>
                        <Radio.Button value="n">아니요</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => {
                        return (
                          prevValues.noise.compensation.isAccount !==
                          currentValues.noise.compensation.isAccount
                        );
                      }}
                    >
                      {() => (
                        <>
                          <Form.Item
                            label="예금주"
                            style={{ marginBottom: 0 }}
                            hidden={
                              form.getFieldValue([
                                "noise",
                                "compensation",
                                "isAccount",
                              ]) === "n" ||
                              form.getFieldValue([
                                "noise",
                                "compensation",
                                "exist",
                              ]) === "n"
                            }
                          >
                            <Form.Item
                              name={["noise", "compensation", "name"]}
                              rules={
                                form.getFieldValue([
                                  "noise",
                                  "compensation",
                                  "isAccount",
                                ]) === "n"
                                  ? []
                                  : [
                                      {
                                        required: true,
                                        message: "예금주를 입력해주세요",
                                      },
                                    ]
                              }
                              // style={{ width: "calc(85% - 8px)" }}
                            >
                              <Input
                                placeholder="김예금"
                                style={{ width: "100%" }}
                                controls={false}
                              />
                            </Form.Item>
                          </Form.Item>
                          <Form.Item
                            label="은행명"
                            style={{ marginBottom: 0 }}
                            hidden={
                              form.getFieldValue([
                                "noise",
                                "compensation",
                                "isAccount",
                              ]) === "n" ||
                              form.getFieldValue([
                                "noise",
                                "compensation",
                                "exist",
                              ]) === "n"
                            }
                          >
                            <Form.Item
                              name={["noise", "compensation", "bank"]}
                              rules={
                                form.getFieldValue([
                                  "noise",
                                  "compensation",
                                  "isAccount",
                                ]) === "n"
                                  ? []
                                  : [
                                      {
                                        required: true,
                                        message: "은행명을 입력해주세요",
                                      },
                                    ]
                              }
                              // style={{ width: "calc(85% - 8px)" }}
                            >
                              <Input
                                style={{ width: "100%" }}
                                placeholder="국민은행"
                                controls={false}
                              />
                            </Form.Item>
                          </Form.Item>
                          <Form.Item
                            label="계좌번호"
                            style={{ marginBottom: 0 }}
                            hidden={
                              form.getFieldValue([
                                "noise",
                                "compensation",
                                "isAccount",
                              ]) === "n" ||
                              form.getFieldValue([
                                "noise",
                                "compensation",
                                "exist",
                              ]) === "n"
                            }
                          >
                            <Form.Item
                              name={["noise", "compensation", "accountNumber"]}
                              rules={
                                form.getFieldValue([
                                  "noise",
                                  "compensation",
                                  "isAccount",
                                ]) === "n"
                                  ? []
                                  : [
                                      {
                                        required: true,
                                        message: "계좌번호를 입력해주세요",
                                      },
                                    ]
                              }
                              // style={{ width: "calc(85% - 8px)" }}
                            >
                              <Input
                                placeholder="111-2222-333333"
                                style={{ width: "100%" }}
                                controls={false}
                              />
                            </Form.Item>
                          </Form.Item>
                        </>
                      )}
                    </Form.Item>
                  </>
                )}
              </Form.Item>

              <Row justify="space-between">
                <Col span={6} offset={18} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    다음
                  </Button>
                </Col>
              </Row>
            </Form>
          </CaseDetailWrapper>
        </ContentContainer>
      </MyCaseSection>
    </Container>
  );
};

export default Step1;
