import { useHistory } from "react-router-dom";

import IcOnlineScamServiceMain from "../../assets/svg/IcOnlineScamServiceMain.svg";
import IcOnlineScamServiceMainMobile from "../../assets/svg/IcOnlineScamServiceMainMobile.svg";
import IcOnlineScamServiceBody from "../../assets/svg/IcOnlineScamServiceBody.svg";
import IcOnlineScamServiceBodyMobile from "../../assets/svg/IcOnlineScamServiceBodyMobile.svg";
import IcOnlineScamServiceBody2 from "../../assets/svg/IcOnlineScamServiceBody2.svg";
import IcOnlineScamServiceBody2Mobile from "../../assets/svg/IcOnlineScamServiceBody2Mobile.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
  ServiceCenterFooterBtn,
  ServiceCenterFooterContainer,
  ServiceCenterFooterDesc,
  ServiceCenterFooterSection,
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle,
  ServiceCenterSecondContainer,
  ServiceCenterSecondDesc,
  ServiceCenterSecondImg,
  ServiceCenterSecondSection,
  ServiceCenterSecondSubTitle,
  ServiceCenterSecondTextContainer,
  ServiceCenterSecondTitle,
  ServiceCenterThirdContainer,
  ServiceCenterThirdDesc,
  ServiceCenterThirdDescSpan,
  ServiceCenterThirdImg,
  ServiceCenterThirdImgMobile,
  ServiceCenterThirdSection,
  ServiceCenterThirdTextContainer,
  ServiceCenterThirdTitle
} from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const OnlineScamServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = "중고거래 사기 고소하기";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = isMobile
      ? "알뜰함을 이용한\n중고거래 사기"
      : "알뜰함을 이용한 중고거래 사기";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = "손해 배상과 처벌을 확실하게";
    return removeBrTag(text);
  };

  const body2Title = () => {
    const text = isMobile
      ? "가장 확실하고 저렴하게,\n법대리에서"
      : "가장 확실하고 저렴하게, 법대리에서";
    return removeBrTag(text);
  };

  const footerTitle = () => {
    const text = isMobile
      ? "중고거래 관련 도움이\n필요하신가요?"
      : "중고거래 관련 도움이 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcOnlineScamServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcOnlineScamServiceMain}
            />
            <ServiceCenterFirstImg src={IcOnlineScamServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
              {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              당*마켓, 중*나라 등 중고거래 플랫폼이 활성화 되면서 많은 분들이 소소한 생활 물품부터 시작해 명품, 귀금속 등 고가의 물품까지 중고거래로 거래를 하고 있습니다.
              중고거래 사기는 돈만 받고 물건을 보내주지 않거나, 정상적으로 사용이 불가능한 물건을 보내주는 방식부터 시작해서, 정품이 아닌 가품을 파는 등 다양한 형태의 중고거래 사기가 발생하고 있습니다.
              최근들어 3자 거래 방식을 이용하여 오히려 중고 사기 피해자가 피의자로 오인 당해 계좌가 정지되는 등 추가적인 피해가 발생하는 경우도 있습니다.
              이러한 중고거래 사기를 당했을때는 꼭 관련 사건 경험이 풍부한 변호사와 상담을 통해 상대방을 처벌하고 입은 손해에 대한 배상을 청구하는 등 대처를 하셔야 합니다.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcOnlineScamServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcOnlineScamServiceBody}
            />
            <ServiceCenterSecondImg src={IcOnlineScamServiceBody} />
          </picture>
        </ServiceCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterThirdSection>
        <ServiceCenterThirdContainer>
          <ServiceCenterThirdImg src={IcOnlineScamServiceBody2} />
          <ServiceCenterThirdTextContainer>
            <ServiceCenterThirdTitle>
              {body2Title()}
            </ServiceCenterThirdTitle>
            <ServiceCenterThirdDesc>
              법대리의 전문 변호사 중고거래 고소장 작성 서비스는 기존 큰 비용이 드는 <ServiceCenterThirdDescSpan>변호사 선임의 부담을 없애고,</ServiceCenterThirdDescSpan>
              가장 저렴한 가격에 중고거래 사기 피해 전문 변호사가 직접 쓴 고소장을 받아볼 수 있는 서비스를 제공하고 있습니다.
              현재 겪고 계신 상황과 상세 내용을 신청서로 제출 해주시면 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterThirdDesc>
          </ServiceCenterThirdTextContainer>
          <ServiceCenterThirdImgMobile src={IcOnlineScamServiceBody2Mobile} />
        </ServiceCenterThirdContainer>
      </ServiceCenterThirdSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
              {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면
              분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtn onClick={() => history.push("/apply/onlineScamService")}>
            중고거래 도움받기
          </ServiceCenterFooterBtn>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default OnlineScamServiceCenter;
