import { Button, Divider, Image, Typography } from "antd";
const { Title, Text } = Typography;

const DrunkDrivingSample = () => {
  return (
    <>
      <div>
        <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
          반성문/탄원서/서약서 템플릿 및 샘플
        </Title>
        <div style={{ marginTop: 10 }}>
          <Text type="secondary">
            아래 파일들을 참고하여 반성문/탄원서를 작성해주세요.
            <br />
            모든 파일들은 고유 트래킹 넘버로 조회가 가능하오니,
            <br />
            <b>절대 무단 유출하시지 않기 바랍니다.</b>
          </Text>
        </div>

        <Divider />

        <Title level={5} style={{ color: "#001529", marginBottom: 10 }}>
          양식 다운로드
        </Title>
        <div>
          <Button type="primary" style={{ marginRight: 5 }}>
            <a href="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%80%E1%85%AA%E1%84%8B%E1%85%B3%E1%86%B7%E1%84%80%E1%85%B3%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%AF%E1%84%89%E1%85%B5%E1%86%AF%E1%84%8E%E1%85%A5%E1%86%AB%E1%84%89%E1%85%A5%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%89%E1%85%A5%20%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.docx?alt=media&token=e45561c1-bdc8-4e6f-90c4-1e03c31c7dd2">
              과음근절서약서
            </a>
          </Button>
          <Button type="primary" style={{ marginRight: 5 }}>
            <a href="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%87%E1%85%A1%E1%86%AB%E1%84%89%E1%85%A5%E1%86%BC%E1%84%86%E1%85%AE%E1%86%AB%20%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.docx?alt=media&token=cdffa2f0-a35a-445f-9eeb-dbc4650b87f4">
              반성문
            </a>
          </Button>
          <Button type="primary" style={{ marginRight: 5 }}>
            <a href="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%90%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A5%20%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.docx?alt=media&token=c079fff6-4f4a-4208-8878-a5d5bdba228c">
              탄원서
            </a>
          </Button>
        </div>

        <Divider />
        <Title level={5} style={{ color: "#001529", marginBottom: 10 }}>
          과음근절서약서 샘플
        </Title>
        <div>
          <Image
            width={100}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%80%E1%85%AA%E1%84%8B%E1%85%B3%E1%86%B7%E1%84%80%E1%85%B3%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%AF%E1%84%89%E1%85%B5%E1%86%AF%E1%84%8E%E1%85%A5%E1%86%AB%E1%84%89%E1%85%A5%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%89%E1%85%A5_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF.jpg?alt=media&token=71dc8559-c1f2-4045-9c51-33fd9d275fb8"
            preview={{ zIndex: 10000 }}
          />
        </div>
        <Divider />
        <Title level={5} style={{ color: "#001529", marginBottom: 10 }}>
          반성문 샘플
        </Title>
        <div>
          <Image
            width={100}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%87%E1%85%A1%E1%86%AB%E1%84%89%E1%85%A5%E1%86%BC%E1%84%86%E1%85%AE%E1%86%AB_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF_1.jpg?alt=media&token=14ea065c-fe70-44cc-b04c-90a2315e27fd"
            preview={{ zIndex: 10000 }}
          />
          <Image
            width={100}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%87%E1%85%A1%E1%86%AB%E1%84%89%E1%85%A5%E1%86%BC%E1%84%86%E1%85%AE%E1%86%AB_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF_2.jpg?alt=media&token=2c2cfe1a-c895-4c20-b23f-f850422feaa5"
            preview={{ zIndex: 10000 }}
          />
          <Image
            width={100}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%87%E1%85%A1%E1%86%AB%E1%84%89%E1%85%A5%E1%86%BC%E1%84%86%E1%85%AE%E1%86%AB_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF_3.jpg?alt=media&token=684040b1-6660-41a0-b05c-a7d3a02ccb73"
            preview={{ zIndex: 10000 }}
          />
        </div>
        {/* <Divider />
        <Title level={5} style={{ color: "#001529", marginBottom: 10 }}>
          탄원서(아내) 샘플
        </Title>
        <div>
          <Image
            width={85}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%90%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A5_%E1%84%8B%E1%85%A1%E1%84%82%E1%85%A2_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF_1.jpg?alt=media&token=79158352-f42d-45d6-9967-1172f4f1993e"
            preview={{ zIndex: 10000 }}
          />
          <Image
            width={85}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%90%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A5_%E1%84%8B%E1%85%A1%E1%84%82%E1%85%A2_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF_2.jpg?alt=media&token=6a87ac6e-b4c6-48b6-ac5c-eae0a12cbbe2"
            preview={{ zIndex: 10000 }}
          />
          <Image
            width={85}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%90%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A5_%E1%84%8B%E1%85%A1%E1%84%82%E1%85%A2_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF_3.jpg.jpg?alt=media&token=48d24be6-54ce-43e6-b0db-0bcadca1ea03"
            preview={{ zIndex: 10000 }}
          />
          <Image
            width={85}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%90%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A5_%E1%84%89%E1%85%B5%E1%86%AB%E1%84%87%E1%85%AE%E1%86%AB%E1%84%8C%E1%85%B3%E1%86%BC.jpg?alt=media&token=d927516b-e38d-479a-8683-785f1f565179"
            preview={{ zIndex: 10000 }}
          />
        </div> */}
        <Divider />
        <Title level={5} style={{ color: "#001529", marginBottom: 10 }}>
          탄원서(지인) 샘플
        </Title>
        <div>
          <Image
            width={85}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%90%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A5_%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%B5%E1%86%AB_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF_1.jpg?alt=media&token=35307f03-7889-494c-86e2-9c0a85138695"
            preview={{ zIndex: 10000 }}
          />
          <Image
            width={85}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%90%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A5_%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%B5%E1%86%AB_%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF_2.jpg?alt=media&token=7b7cfa9e-b18e-4dd9-a71b-170ed2dd1ed3"
            preview={{ zIndex: 10000 }}
          />
          <Image
            width={85}
            src="https://firebasestorage.googleapis.com/v0/b/lawlaw-c045d.appspot.com/o/templates%2Fdrunk_driving%2F%E1%84%90%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A5_%E1%84%89%E1%85%B5%E1%86%AB%E1%84%87%E1%85%AE%E1%86%AB%E1%84%8C%E1%85%B3%E1%86%BC.jpg?alt=media&token=d927516b-e38d-479a-8683-785f1f565179"
            preview={{ zIndex: 10000 }}
          />
        </div>
      </div>
    </>
  );
};

export default DrunkDrivingSample;
