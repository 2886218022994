import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Modal,
} from "antd";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,
  CaseDetailWrapper,
} from "../../../Form.style";
import DaumPostcode from "react-daum-postcode";

import { useEffect, useState } from "react";

const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddressSelect = (data) => {
    const selectedAddress = data.address;
    form.setFieldsValue({
      rentInfo: { address1: selectedAddress },
    });
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    console.log(values);
    setData(values);
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
          <Modal
            title="주소 검색"
            open={isModalOpen}
            onCancel={closeModal}
            footer={null}
          >
            <DaumPostcode onComplete={handleAddressSelect} />
          </Modal>
          <Title>월세 청구 내용증명</Title>

          <div style={{ marginTop: 10 }}>
            <SubText style={{ textAlign: "left" }} type="secondary">
            월세 청구 관련하여 아래 내용을 작성하세요.
            </SubText>
          </div>

          <Divider style={{ marginTop: 10 }} />
          <CaseDetailWrapper style={{ height: "90%" }}>
            <Form
              {...itemLayout.form}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={data}
              form={form}
              style={{
                marginTop: 10,
                marginBottom: 20,
                width: "100%",
                textAlign: "left",
              }}
              requiredMark={false}
            >
              <Form.Item
                label="임대차 계약 일자"
                name={["rentInfo", "contractDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              {/* <Form.Item
                label="임대한 대상 주소"
                name={["rentInfo", "address1"]}
                rules={[
                  {
                    required: true,
                    message: "임대한 대상 주소를 입력해주세요",
                  },
                ]}
                onClick={showModal}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="클릭하여 주소를 입력해주세요"
                />
              </Form.Item>
              <Form.Item
                label="상세 주소"
                name={["rentInfo", "address2"]}
                rules={[
                  {
                    required: true,
                    message: "임대한 대상 곳 주소를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="상세 주소를 입력해주세요"
                />
              </Form.Item> */}
              <Form.Item
                label="임대 시작 일자"
                name={["rentInfo", "startDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              
              <Form.Item
                label="임대 종료 일자"
                name={["rentInfo", "endDate"]}
                rules={[
                  {
                    required: true,
                    message: "날짜를 선택해주세요",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              <Form.Item
                label="보증금"
                name={["rentInfo", "deposit"]}
                rules={[
                  {
                    required: true,
                    message: "보증금을 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addonAfter="원"
                  controls={false}
                />
              </Form.Item>

              <Form.Item
                label="임대료"
                name={["rentInfo", "rent"]}
                rules={[
                  {
                    required: true,
                    message: "임대료를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addonBefore="월"
                  addonAfter="원"
                  controls={false}
                />
              </Form.Item>

              <Form.Item
                label="임대료 지급일"
                name={["rentInfo", "paymentDate"]}
                rules={[
                  {
                    required: true,
                    message: "임대료 지급일을 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  placeholder="매달 25일"
                  controls={false}
                />
              </Form.Item>
              <Row justify="space-between">
                <Col span={6} offset={18} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    다음
                  </Button>
                </Col>
              </Row>
            </Form>
          </CaseDetailWrapper>
        </ContentContainer>
      </MyCaseSection>
    </Container>
  );
};

export default Step1;
