import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Radio,
  Row,
  Modal,
} from "antd";

import {
  Title,
  SubText,
  ContentContainer,
  MyCaseSection,
  Container,
  CaseDetailWrapper,
} from "../../../Form.style";
import DaumPostcode from "react-daum-postcode";

import { useEffect, useState } from "react";
const { TextArea } = Input;

const Step1 = ({ data, setData, onNext, itemLayout }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddressSelect = (data) => {
    const selectedAddress = data.address;
    form.setFieldsValue({
      cancel: { address1: selectedAddress },
    });
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    console.log(values);
    setData(values);
    onNext();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container style={{ textAlign: "left" }}>
      <MyCaseSection>
        <ContentContainer>
          <Modal
            title="주소 검색"
            open={isModalOpen}
            onCancel={closeModal}
            footer={null}
          >
            <DaumPostcode onComplete={handleAddressSelect} />
          </Modal>
          <Title>월세 청구 내용증명</Title>

          <div style={{ marginTop: 10 }}>
            <SubText style={{ textAlign: "left" }} type="secondary">
            월세 청구 관련하여 아래 내용을 작성하세요.
            </SubText>
          </div>

          <Divider style={{ marginTop: 10 }} />
          <CaseDetailWrapper style={{ height: "90%" }}>
            <Form
              {...itemLayout.form}
              layout="horizontal"
              onFinish={onFinish}
              initialValues={data}
              form={form}
              style={{
                marginTop: 10,
                marginBottom: 20,
                width: "100%",
                textAlign: "left",
              }}
              requiredMark={false}
            >
              <Form.Item
                label="총 연체된 임대료"
                name={["rentClaim", "overdueRent"]}
                rules={[
                  {
                    required: true,
                    message: "총 연체된 임대료를 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addonAfter="원"
                  controls={false}
                />
              </Form.Item>

              <Form.Item
                label="임대료를 받지 못한 기간"
                name={["rentClaim", "period"]}
                rules={[
                  {
                    required: true,
                    message: "임대료를 받지 못한 기간을 입력해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Input
                  placeholder="2023년 1월, 2023년 2월"
                  controls={false}
                />
              </Form.Item>

              <Form.Item
                label={
                  <>
                    받지 못한 기타 금액
                    <Popover
                      content={
                        <div>
                          임차인에게 받지 못한 기타비용이
                          <br/>
                          있는 경우에만 작성해주세요
                        </div>
                      }
                      placement="bottom"
                      trigger="click"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  </>
                }
                name={["rentClaim", "otherAmounts"]}
              >
                <Checkbox.Group style={{ width: "100%" }}>
                  <Row>
                    <Col span={24}>
                      <Checkbox value="관리비">
                        관리비
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="공과금(전기, 수도, 가스요금 등)">
                        공과금(전기, 수도, 가스요금 등)
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="물건 파손에 대한 수리 비용">
                        물건 파손에 대한 수리 비용
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="기타 발생한 비용">
                        기타 발생한 비용
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                label={
                  <>
                    지연이자 청구 여부
                    <Popover
                      content={
                        <div>
                          지연이자를 요구하면, 월세 지급일 이후
                          <br />
                          날짜 이자(지연이자)까지 자동으로 청구할 수 있습니다.
                          <br />
                          주택임대차의 경우 5%, 상가임대차의 경우
                          <br />
                          6%로 청구가 가능하고, 별도로 이자를
                          <br />
                          약정했다면 약정이자율을 청구 할 수 있습니다.
                          <br />
                        </div>
                      }
                      placement="bottom"
                      trigger="click"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  </>
                }
                name={["rentClaim", "delayedInterest"]}
                rules={[{ required: true, message: "" }]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Radio.Group>
                  <Radio.Button value="y">예</Radio.Button>
                  <Radio.Button value="n">아니오</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="밀린 임대료 등 지급 기한"
                name={["rentClaim", "dueDate"]}
                rules={[
                  {
                    required: true,
                    message: "지급 기한을 선택해주세요",
                  },
                ]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="날짜를 선택하세요"
                />
              </Form.Item>
              {/* 계좌정보 삭제
              <Form.Item
                hidden={
                  form.getFieldValue([
                    "rentClaim",
                    "isAccount",
                  ]) === "n"
                }
                label="계좌 정보 작성 여부"
                name={["rentClaim", "isAccount"]}
                rules={[{ required: true, message: "" }]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Radio.Group>
                  <Radio.Button value="y">예</Radio.Button>
                  <Radio.Button value="n">아니요</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (
                    prevValues.rentClaim.isAccount !==
                    currentValues.rentClaim.isAccount
                  );
                }}
              >
                {() => (
                  <>
                    <Form.Item
                      label="예금주"
                      style={{ marginBottom: 0 }}
                      hidden={
                        form.getFieldValue([
                          "rentClaim",
                          "isAccount",
                        ]) === "n" ||
                        form.getFieldValue([
                          "rentClaim",
                          "exist",
                        ]) === "n"
                      }
                    >
                      <Form.Item
                        name={["rentClaim", "name"]}
                        rules={
                          form.getFieldValue([
                            "rentClaim",
                            "isAccount",
                          ]) === "n"
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "예금주를 입력해주세요",
                                },
                              ]
                        }
                        // style={{ width: "calc(85% - 8px)" }}
                      >
                        <Input
                          placeholder="김예금"
                          style={{ width: "100%" }}
                          controls={false}
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      label="은행명"
                      style={{ marginBottom: 0 }}
                      hidden={
                        form.getFieldValue([
                          "rentClaim",
                          "isAccount",
                        ]) === "n" ||
                        form.getFieldValue([
                          "rentClaim",
                          "exist",
                        ]) === "n"
                      }
                    >
                      <Form.Item
                        name={["rentClaim", "bank"]}
                        rules={
                          form.getFieldValue([
                            "rentClaim",
                            "isAccount",
                          ]) === "n"
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "은행명을 입력해주세요",
                                },
                              ]
                        }
                        // style={{ width: "calc(85% - 8px)" }}
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder="국민은행"
                          controls={false}
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      label="계좌번호"
                      style={{ marginBottom: 0 }}
                      hidden={
                        form.getFieldValue([
                          "rentClaim",
                          "isAccount",
                        ]) === "n" ||
                        form.getFieldValue([
                          "rentClaim",
                          "exist",
                        ]) === "n"
                      }
                    >
                      <Form.Item
                        name={["rentClaim", "accountNumber"]}
                        rules={
                          form.getFieldValue([
                            "rentClaim",
                            "isAccount",
                          ]) === "n"
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "계좌번호를 입력해주세요",
                                },
                              ]
                        }
                        // style={{ width: "calc(85% - 8px)" }}
                      >
                        <Input
                          placeholder="111-2222-333333"
                          style={{ width: "100%" }}
                          controls={false}
                        />
                      </Form.Item>
                    </Form.Item>
                  </>
                )}
              </Form.Item> */}
              <Form.Item
                hidden={
                  form.getFieldValue([
                    "rentClaim",
                    "isTermination",
                  ]) === "n"
                }
                label={
                  <>
                    계약해지 및 명도 요구 여부
                    <Popover
                      content={
                        <div>
                          부동산 명도(인도)란, 부동산의 적법한 점유권한
                          <br />
                          있는 자가 현재 부동산을 점유하고 있는 상대방에
                          <br />
                          대하여 부동산을 넘겨줄 것을 청구하는 것입니다.
                          <br />
                        </div>
                      }
                      placement="bottom"
                      trigger="click"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  </>
                }
                name={["rentClaim", "isTermination"]}
                rules={[{ required: true, message: "" }]}
                // style={{ width: "calc(85% - 8px)" }}
              >
                <Radio.Group>
                  <Radio.Button value="y">예</Radio.Button>
                  <Radio.Button value="n">아니요</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return (
                    prevValues.rentClaim.isTermination !==
                    currentValues.rentClaim.isTermination
                  );
                }}
              >
                {() => (
                  <>
                    <Form.Item
                      label="명도 기한"
                      style={{ marginBottom: 0 }}
                      hidden={
                        form.getFieldValue([
                          "rentClaim",
                          "isTermination",
                        ]) === "n" ||
                        form.getFieldValue([
                          "rentClaim",
                          "exist",
                        ]) === "n"
                      }
                    >
                      <Form.Item
                        name={["rentClaim", "terminationDate"]}
                        rules={
                          form.getFieldValue([
                            "rentClaim",
                            "isTermination",
                          ]) === "n"
                            ? []
                            : [
                                {
                                  required: true,
                                  message: "날짜를 선택해주세요",
                                },
                              ]
                        }
                        // style={{ width: "calc(85% - 8px)" }}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder="날짜를 선택하세요"
                        />
                      </Form.Item>
                    </Form.Item>
                  </>
                )}
              </Form.Item>
              <Row justify="space-between">
                <Col span={6} offset={18} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", maxWidth: "100px" }}
                  >
                    다음
                  </Button>
                </Col>
              </Row>
            </Form>
          </CaseDetailWrapper>
        </ContentContainer>
      </MyCaseSection>
    </Container>
  );
};

export default Step1;
