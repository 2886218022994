import { Descriptions, Divider } from "antd";
 import { Typography } from "antd";
 import { labelStyle } from "../RequestUtil";

 const { Title } = Typography;

 const CommonEtcRequest = ({ serviceData, requestData }) => {
   const reactiveColumn = {
     xxl: 1,
     xl: 1,
     lg: 1,
     md: 1,
     sm: 1,
     xs: 1,
   };
   
   return (
     <>
       <Title level={4} style={{ color: "#001529", marginBottom: 0 }}>
       기타 법률
       </Title>
       <Divider style={{ marginTop: 10 }} />
       <div
         style={{
           backgroundColor: "white",
           padding: 15,
           paddingTop: 5,
           marginBottom: 20,
           borderRadius: 5,
         }}
       >
         <Descriptions
           title="기타 법률 관련 정보"
           bordered
           column={reactiveColumn}
           size={"small"}
           style={{ marginTop: 20, marginBottom: 15 }}
         >

          <Descriptions.Item
             label="의뢰인이 원하는 해결방향"
             labelStyle={labelStyle}
           >
             {serviceData.solutionType}
           </Descriptions.Item>

           {serviceData.solutionType.includes("기타") ? (
             <Descriptions.Item label="기타 이유" labelStyle={labelStyle}>
               {serviceData.etcSolution}
             </Descriptions.Item>
           ) : null}

          <Descriptions.Item
             label="의뢰인이 원하는 결과"
             labelStyle={labelStyle}
           >
             {serviceData.desiredResult.join(", ")}
           </Descriptions.Item>

           {serviceData.desiredResult.includes("기타") ? (
             <Descriptions.Item label="기타 이유" labelStyle={labelStyle}>
               {serviceData.etcDesiredResult}
             </Descriptions.Item>
           ) : null}

          <Descriptions.Item
             label="변호사님에게 도움 받고 싶은 내용"
             labelStyle={labelStyle}
           >
             {serviceData.desiredHelp}
           </Descriptions.Item>

           {serviceData.desiredHelp.includes("기타") ? (
             <Descriptions.Item label="기타 이유" labelStyle={labelStyle}>
               {serviceData.etcDesiredHelp}
             </Descriptions.Item>
           ) : null}

         </Descriptions>

         <Descriptions
           title="상세 내용 및 연락처"
           bordered
           column={reactiveColumn}
           size={"small"}
           style={{ marginTop: 20, marginBottom: 15 }}
         >           
           <Descriptions.Item label="상세 내용" labelStyle={labelStyle}>
             {serviceData.desc
               ? serviceData.desc.split("\n").map((line, idx) => {
                   return (
                     <span key={idx}>
                       {line}
                       <br />
                     </span>
                   );
                 })
               : ""}
           </Descriptions.Item>
           <Descriptions.Item label="연락처" labelStyle={labelStyle}>
             {serviceData.contact}
           </Descriptions.Item>
           <Descriptions.Item label="프로모션" labelStyle={labelStyle}>
             {serviceData.promotionCode}
           </Descriptions.Item>
         </Descriptions>
       </div>
     </>
   );
 };

 export default CommonEtcRequest;