import React, { useEffect } from "react";
import IcRightArrow from "../../assets/svg/IcRightArrow.svg";
import backButtonBlack from "../../assets/img/backButtonBlack.png";
import { 
    ModalContainer,
    ModalHeader,
    ModalHeaderBack,
    ModalHeaderTitle,
    DownModalHeaderBackWrapper,
 } from "./Noti.style";

 import {
    HeaderNotiBox,
    HeaderNotiTextWrapper,
    HeaderNotiContent,
    HeaderNotiDate,
    HeaderNotiArrowWrapper,
    HeaderNotiArrow,
  } from "../CommonLayout.style";

import { userObj, currentMyCaseState } from '../../states/atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dbService } from "fbase";
import { getFormattedDate } from "util/util";
import { useHistory } from "react-router-dom";

function NotiList({ }) { 
    const history = useHistory();
    const userObjData = useRecoilValue(userObj);
    const [currentMyCase, setCurrentMyCase] = useRecoilState(currentMyCaseState);

    useEffect(() => {
    }, []);

    const handleGoBack = () => {
      history.goback();
    };

    const onClickNotiMessage = async (notiData) => {
        switch (notiData.type) {
          case "chat":
          case "clientNotSubmit":
          case "lawyerDoc":
            await dbService
            .collection("requests_temp")
            .where("__name__", "==", notiData.client_request_id)
            .get()
            .then((snapshot) => {
              if (snapshot.size > 0) {            
                  const data = { id: snapshot.docs[0].id, requestId: snapshot.docs[0].id, ...snapshot.docs[0].data() };
                  setCurrentMyCase(data);
                  history.push({
                    pathname: "/case_detail",
                  });
              }
            })
            .catch((error) => {
              history.push("/");
            });
            return    
          case "proposal":
            await dbService
            .collection("requests_temp")
            .where("__name__", "==", notiData.client_request_id)
            .get()
            .then((snapshot) => {
              if (snapshot.size > 0) {            
                  const data = { id: snapshot.docs[0].id, requestId: snapshot.docs[0].id, ...snapshot.docs[0].data() };
                  setCurrentMyCase(data);
                  history.push({
                    pathname: "/my_case",
                  });
              }
            })
            .catch((error) => {
              history.push("/");
            }); 
            return
          default: return ""
        }    
      }

    const NotiDataTitle = (dataType) => {
      switch (dataType) {
        case "chat":
          return "변호사가 1:1 문의의 답변을 하였습니다."
        case "clientNotSubmit":
          return "변호사가 제출해야할 사건 자료를 요청하였습니다."
        case "lawyerDoc":
          return "변호사가 사건 서류를 업로드 하였습니다."
        case "proposal":
          return "변호사가 사건에 대한 답변을 완료하였습니다."
        default: return ""
      }
    }
    

    const RenderNotidata = () => {
      if (userObjData === null) {
        return <></>
      }
      if (userObjData.notiList === undefined) {
        return <></>
      }
  
      if (userObjData.notiList === null) {
        return <></>
      }
      return <>
      {userObjData.notiList.map((notiData, idx) => {
        return (
          <HeaderNotiBox onClick={() => {onClickNotiMessage(notiData)}}>
            <HeaderNotiTextWrapper>
              <HeaderNotiContent>
              {NotiDataTitle(notiData.type)} 
              </HeaderNotiContent>
              <HeaderNotiDate>
                {getFormattedDate(notiData.sendAt)}
              </HeaderNotiDate>
            </HeaderNotiTextWrapper>
            <HeaderNotiArrowWrapper>
              <HeaderNotiArrow src={IcRightArrow}/>
            </HeaderNotiArrowWrapper>
          </HeaderNotiBox>
        )
      })}
      </>
    }

    return (
        <>
          <ModalContainer>
            <ModalHeader style={{backgroundColor: "#fff"}}>
            <DownModalHeaderBackWrapper onClick={handleGoBack}>
              <ModalHeaderBack src={backButtonBlack}/>
            </DownModalHeaderBackWrapper>     
            <ModalHeaderTitle>
              알림
            </ModalHeaderTitle>
            <div style={{width: 100}}></div>
            </ModalHeader>
            {RenderNotidata()}
          </ModalContainer>
        </>
      );
}

export default NotiList;