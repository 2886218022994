import { useHistory } from "react-router-dom";

import IcAgreementServiceMain from "../../assets/svg/IcAgreementServiceMain.svg";
import IcAgreementServiceMainMobile from "../../assets/svg/IcAgreementServiceMainMobile.svg";
import IcAgreementServiceBody from "../../assets/svg/IcAgreementServiceBody.svg";
import IcAgreementServiceBodyMobile from "../../assets/svg/IcAgreementServiceBodyMobile.svg";
import IcAgreementServiceBody2 from "../../assets/svg/IcAgreementServiceBody2.svg";
import IcAgreementServiceBody2Mobile from "../../assets/svg/IcAgreementServiceBody2Mobile.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
  ServiceCenterFooterBtn,
  ServiceCenterFooterContainer,
  ServiceCenterFooterDesc,
  ServiceCenterFooterSection,
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle,
  ServiceCenterSecondContainer,
  ServiceCenterSecondDesc,
  ServiceCenterSecondImg,
  ServiceCenterSecondSection,
  ServiceCenterSecondSubTitle,
  ServiceCenterSecondTextContainer,
  ServiceCenterSecondTitle,
  ServiceCenterThirdContainer,
  ServiceCenterThirdDesc,
  ServiceCenterThirdDescSpan,
  ServiceCenterThirdImg,
  ServiceCenterThirdImgMobile,
  ServiceCenterThirdSection,
  ServiceCenterThirdTextContainer,
  ServiceCenterThirdTitle
} from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const AgreementServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = "합의서 작성하기";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = isMobile
      ? "합의서를 잘\n작성하려면?"
      : "합의서를 잘 작성하려면?";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = "구체적으로 적기";
    return removeBrTag(text);
  };

  const body2Title = () => {
    const text = isMobile
      ? "법대리 합의서 작성\n서비스 이용하기"
      : "법대리 합의서 작성 서비스 이용하기";
    return removeBrTag(text);
  };

  const footerTitle = () => {
    const text = isMobile
      ? "합의서 작성이\n필요하신가요?"
      : "합의서 작성이 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcAgreementServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcAgreementServiceMain}
            />
            <ServiceCenterFirstImg src={IcAgreementServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
              {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              합의서는 서로 합의하에 법률 사건을 마무리하는 문서이기 때문에 구체적인 내용이 작성되어야 하며 추후 문제가 발생하지 않도록 빈틈이 없어야 합니다.
              하지만 일반인 입장에서는 어떤 내용이 구체적으로 들어가야 하는지, 어떤 내용이 있어야 추후 문제가 발생하지 않고 내가 보호받을 수 있는지 알기 어려운 경우가 많습니다.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcAgreementServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcAgreementServiceBody}
            />
            <ServiceCenterSecondImg src={IcAgreementServiceBody} />
          </picture>
        </ServiceCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterThirdSection>
        <ServiceCenterThirdContainer>
          <ServiceCenterThirdImg src={IcAgreementServiceBody2} />
          <ServiceCenterThirdTextContainer>
            <ServiceCenterThirdTitle>
              {body2Title()}
            </ServiceCenterThirdTitle>
            <ServiceCenterThirdDesc>
              법대리는 합의 전문 변호사가 세세한 내용까지 검토해서 완벽한 합의서를 대신 작성해 드립니다.
              법대리의 전문 변호사 합의서 작성 서비스는 기존 큰 비용이 드는 변호사 선임의 부담을 없애고,
              가장 저렴한 가격에 각 분야별 전문 변호사가 작성해주는 변호사 합의서를 받아볼 수 있는 서비스를 제공하고 있습니다.
            </ServiceCenterThirdDesc>
          </ServiceCenterThirdTextContainer>
          <ServiceCenterThirdImgMobile src={IcAgreementServiceBody2Mobile} />
        </ServiceCenterThirdContainer>
      </ServiceCenterThirdSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
              {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면
              분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtn onClick={() => history.push("/apply/agreementService")}>
            합의서 작성하기
          </ServiceCenterFooterBtn>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default AgreementServiceCenter;
