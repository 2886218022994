import styled from "@emotion/styled";

export const ModalContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #red;
`;

export const ModalContentContainer = styled.div`
  margin: 0 auto;
  background: #E9E9E9;
  height: 751px;
`;

export const ModalHeader = styled.header`
    height: 73px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ModalHeaderBackWrapper = styled.div`
    // margin-left: 6px;
    width: 48px;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const DownModalHeaderBackWrapper = styled.div`
    padding-left: 25px;
    width: 100px;
    height: 73px;
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const ModalHeaderBack = styled.img`
    width: 7px;
    height: 17px;
`

export const ModalHeaderTitle = styled.div`
    color: #000;
    text-align: center;
    font-family: Pretendard;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`

export const ModalHeaderDownloadTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const ModalHeaderDownloadImg = styled.img`
  width: 18px;
  height: 17px;
`