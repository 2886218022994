import styled from "@emotion/styled";
const bgColorMap = {
  PDF: "#FAEAEB",
  AVI: "#E7EFFA",
};
const borderMap = {
  PDF: "1px solid #D6363E",
  AVI: "1px solid #1164CA",
};

const progressStatusTitleColorMap = {
  active: "#3A7EFC",
  inactive: "#D2D5DD",
}

const progressStatusValueColorMap = {
  active: "#000",
  inactive: "#D2D5DD",  
}

const downloadButtonColorMap = {
  active: "#000",
  inactive: "#B9BBBF"
}

const downloadButtonBorderColorMap = {
  active: "1px solid #000",
  inactive: "1px solid #B9BBBF",
}

const ReviewWrapperBottomMarginMap = {
  active: "44px",
  inactive: "69px",
}

export const Container = styled.div`
  padding-top: 132px;
  padding-bottom: 150px;
  font-family: "Pretendard";
  font-style: normal;
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
`;
export const CaseInfoSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 28px;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
`

export const CaseStatusSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 22px;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
  }
`

export const CaseProgressGuideWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 14px 18px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  background: #D7DDE5;
  margin-top: 20px;
`

export const CaseProgressGuideText = styled.div`
  color: #717683;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 164.286% */
  text-align: left;
`

export const LaywerInfoSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    
  }
`

export const RequestServiceSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 95px;
  @media screen and (max-width: 768px) {
  }
`

export const RequestServiceSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 95px;
  gap: 14px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 14px;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 17px;
    line-height: normal;
    margin-bottom: 12px;
  }
`;

export const SubmissionDesc = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 35px;
  text-align: left;
  @media screen and (max-width: 768px) {
    margin-bottom: 28px;
  }
`;

export const SubmissionSubTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
  text-align: left;
  @media screen and (max-width: 768px) {
  }
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;
export const SubTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  text-align: left;
`;
export const SubButtonWrapper = styled.button`
  padding: 6px 20px;
  outline: none;
  background: #f1f2f6;
  border:  ${(props) => downloadButtonBorderColorMap[props.status] || "1px solid #B9BBBF"};
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  cursor: pointer;
`;

export const SubButtonIcon = styled.img`
  width: 14px;
  height: 14px;
`;

export const SubButtonTitle = styled.div`
  color:  ${(props) => downloadButtonColorMap[props.status] || "#B9BBBF"};
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;


export const SubmissionGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 50px;
  gap: 8px;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const SubmissionDataWrapper = styled.div`
  border-radius: 6px;
  background: #FFF;
  padding: 12px;
  display: flex;
  align-items: center;
  height: 48px;
`;

export const SubmissionDataImgWrapper = styled.div`
  margin-right: 13px;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #faeaeb;
  border: 1px solid #d6363e; */
  background-color: ${(props) => bgColorMap[props.extension] || "#faeaeb"};
  border: ${(props) => borderMap[props.extension] || "1px solid #d6363e"};
`;

export const SubmissionDataIcon = styled.img`
  width: 12px;
  height: 15.6px;
  margin-right: 13px;
`;

export const SubmissionDataText = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: #000000;
  margin-right: 10px;
  text-align: left;
  cursor: pointer;
`;

export const SubmissionDataAcionWrapper = styled.div`
  display: flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 89px;
  background: #D2D9E5;
`;

export const SubmissionDataAcionDivider = styled.div`
  width: 1px;
  height: 12px;
  background: #D9D9D9;
`;

export const SubmissionDataAction = styled.div`
  color: #777;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const ImgSubmissionMore = styled.img``;

export const ImgSubmissionMoreWrapper = styled.div`
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgSubmissionUploadWrapper = styled.div`
  display: flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 89px;
  background: #000;
  cursor: pointer;
`;

export const ImgSubmissionUploadTitle = styled.div`
  color: #FFF;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DateTextWrapper = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

export const DateText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #767C87;
  text-align: left;
  width: 67px;
`;

export const DateTextValue = styled(DateText)`
  width: auto;
`;


export const RequestServiceWrapper = styled.div`
  flex: 1;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px #D3DAE4;
`;

export const RequestServiceTextWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export const RequestServiceBtn = styled.div`
  width: 69px;
  padding: 7px 10px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  background: var(--color-primary, #3A7EFC);
  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 7px 10px;
    height: 31px;
  }
`;


export const LawyerCardBaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 25px 30px 25px 30px;
  /* card2 */
  box-shadow: 0px 0px 4px 0px #D3DAE4;
  background: #ffffff;
`

export const LawyerCardWrapper = styled.div`
  // padding: 25px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;  
`;

export const LawyerCardLeftWrapper = styled.div`
  flex: 1;
  /* padding: 25px 30px; */
  display: flex;
  align-items: stretch;
  @media screen and (max-width: 768px) {
    font-size: 17px;
    line-height: normal;
  }
`;

export const LawyerCardProposeWrapper = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 4px;
  @media screen and (max-width: 768px) {
    margin-top: 4px;
  }
`;

export const LawyerCardTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 13px;
    font-weight: 400;
  }
`;

export const LawyerCardProposeWrapperValue = styled.div`
  color: var(--color-primary, #3A7EFC);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

export const LawyerCardProfile = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;
export const LawyerCardProfileBlank = styled.div`
  width: 99px;
  height: 99px;
  border-radius: 50%;
  background: #d9d9d9;
`;
export const LawyerCardTextWrapper = styled.div`
  flex: 1;
  margin-left: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const LawyerCardLabel = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;  
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: normal;
  }
`;
export const LawyerCardValue = styled(LawyerCardLabel)`
  font-weight: 400;
`;
export const LawyerCardText = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    align-items: start;
    justify-content: start;
  }
`;
export const LawyerCardRightWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const LawyerCardBottomBorder = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    background: rgba(217, 217, 217, 0.40);
    margin-left: 19px;
    margin-right: 19px;
    height: 2px;
  }
`;

export const LawyerCardBottomWrapper = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: end;
    color: #000;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 13px;
    padding-left: 19px;
    padding-right: 19px;
    margin-bottom: 15px;
  }
`;

export const LawyerTag = styled.span`
  background: #3a7efc;
  border-radius: 2px;
  padding: 4px 6px;
  margin-left: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  padding: 40px 0px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;  
  background: #FFF;
  box-shadow: 0px 0px 4px 0px #D3DAE4;
  gap: 9px;
`;

export const ProgressStateWrapper = styled.div`
  width: 63px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const ProgressStateTitle = styled.div`
  color: ${(props) => progressStatusTitleColorMap[props.status] || "#C6CAD6"};;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const ProgressStateValue = styled(ProgressStateTitle)`
  color:  ${(props) => progressStatusValueColorMap[props.status] || "#C6CAD6"};;
`

export const ProgressStateIcon = styled.img`
  width: 29px;
  height: 29px;
  border-radius: 50%;
`;

export const ProgressStateBorder = styled.div`
  width: 1px;
  height: 80px;
  background: #EFEFEF;
`

export const ProgressBallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 10;
`;

export const ProgressBallText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #000000;
`;

export const ConversationWrapper = styled.div`
  padding: 30px;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: scroll;
`;

export const MessageWrapper = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  border-radius: 12px;
  max-width: 80%;
  padding: 13px 20px;
  margin-bottom: 15px;
`;
export const MessageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;
export const Name = styled.div`
  font-weight: 600;
`;
export const MessageContent = styled.div`
  text-align: left;
`;

export const MessageImg = styled.img`
  margin-top: 14px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
`;

export const MessagePdf = styled.iframe`
  margin-top: 14px;
  margin-bottom: 20px;
  width: 100%;
  height: 710px;
`

export const MessageFormWrapper = styled.div`
  background: #fafafa;
  border: 1px solid #e2e2e2;
  position: relative;
  margin-bottom: 44px;  
`;
export const MessageForm = styled.textarea`
  padding: 30px;
  padding-bottom: 0px;
  border: none;
  resize: none;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  background: #fafafa;
  &::placeholder {
    color: #cacaca;
  }
`;
export const BtnSend = styled.button`
  outline: none;
  border: none;
  background: #000000;
  color: white;
  padding: 10px 20px;
`;

export const PayDesc = styled.div`
  padding: 30px;
  width: 100%;
  background-color: #dcdfe9;
`;

export const ReviewWrapper = styled.div`
  padding: 28.5px 28px 52.5px 28px;
  background-color: white;
  margin-bottom: ${(props) => ReviewWrapperBottomMarginMap[props.status] || "69px"};
  border-radius: 16px;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 46px 17.5px 49px 17.5px;
  }
`;

export const DataEmptyWrapper = styled.div`
  padding: 18px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 165px;
  background-color: #F9F9F9;
`;

export const DataEmptyView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-direction: column;
`;

export const DataEmptyIcon = styled.img`
  width: 31px;
  height: 31px;
`;

export const DataEmptyTitle = styled.div`
  color: #B7B7B7;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 164.286% */
`;

export const ReviewItemWrapper = styled.div`
  padding: 18px 20px;
  border-radius: 6px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ReviewBtnsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isDone ? "flex-end" : "center")};
  width: 100%;
  margin-top: 40px;
  gap: 14px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const ReviewButtonWrapper = styled.div`
  flex:  ${(props) => (props.isDone ? "0.5" : "1")};
  padding: 13px 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  width: 100%;
  cursor: pointer;
`;

export const ReviewButtonTitleDescWrapper = styled.div`
  padding-top: 9.5px;
  padding-bottom: 9.5px;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const ReviewButtonTitle = styled.div`
  color: #18191B;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
`;

export const ReviewButtonDesc = styled.div`
  color: #18191B;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
`;

export const ReviewButtonIcon = styled.img`
  width: 34px;
  height: 32px;
  margin-right: 19px;
  @media screen and (max-width: 768px) {
    margin-right: 9px;
  }
`;

export const BtnApproveBaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;  
  border-bottom: 1px solid rgba(212, 212, 212, 0.60);
  padding-bottom: 66px;
  margin-bottom: 66px;
  @media screen and (max-width: 768px) {
    padding-bottom: 48px;
    margin-bottom: 48px;
  }
`;

export const BtnApproveDesc = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

export const BtnApprove = styled.div`
  outline: none;
  border-radius: 6px;
  background: #3A7EFC;
  color: white;
  padding: 15px 20px; 
  cursor: pointer;
`;

export const PreviewPDFContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 710px;
  background: #000;
`

export const PreviewPDFContentScrollableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const PreviewScrollableImg = styled.img`
  width: 100%;
  height: auto;
`

export const DocxPreviewContainer = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;  
  width: 100%;
  height: 100%;
`

export const DocxPreviewWrapper = styled.div`
  display: inline-flex;
  padding: 52px 100px 38px 100px;  
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 16px;
  width: auto;
  background: #000;
  margin-left: 0px;
  margin-right: 0px;
  @media screen and (max-width: 768px) {
    margin-left: 37px;
    margin-right: 37px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
`

export const DocxPreviewDownloadWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 7px;
  background: #FFF;
  cursor: pointer;
`

export const DocxPreviewDownloadTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const DocxPreviewDownloadImg = styled.img`
  width: 18px;
  height: 17px;
`

export const DocxPreviewDesc = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 133.333% */
`

export const SubmitNoticeWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 4px;
  background: #DEE2E8;
`

export const SubmitNoticeTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const SubmitNoticeTitleImg = styled.img`
  width: 27px;
  height: 24px;
`

export const SubmitNoticeTitle = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 120% */
`

export const SubmitNoticeDescWrapper = styled.div`
  display: flex;
  padding-left: 0px;
  align-items: flex-start;
  gap: 10px;
`

export const SubmitNoticeDescDash = styled.div`
  color: #696969;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
`

export const SubmitNoticeDesc = styled.div`
  color: #7F848E;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  text-align: left;
`

export const SubmissionGuideWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 113px;
  padding: 25px 30px;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  border-radius: 16px;
  background: #3A7EFC;
  margin-bottom: 56px;
  /* card2 */
  box-shadow: 0px 0px 4px 0px #D3DAE4;
  cursor: pointer;
  margin-top: 56px;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }
`

export const SubmissionGuideTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`

export const SubmissionGuideTitle = styled.div`
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 150% */
  text-align: left;
`

export const SubmissionGuideDesc = styled.div`
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const SubmissionGuideImg = styled.img`
  width: 60px;
  height: 60px;
`

export const ConversationNoticeWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 4px;
  background: #FFF;  
  margin-top: 30px;
  margin-bottom: 30px;
`

export const ConversationNoticeDescWrapper = styled.div`
  display: flex;
  padding-left: 3px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`

export const ConversationNoticeDesc1 = styled.div`
  color: #7F848E;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  text-align: left;
`

export const ConversationNoticeDesc2 = styled.div`
  color: #7F848E;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 164.286% */
  text-align: left;
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`