import { message } from "antd";
import { dbService } from "fbase";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DrunkDrivingSample from "./drunkDriving/DrunkDrivingSample";

const Sample = ({ loggedInUser }) => {
  const { requestId } = useParams();
  const history = useHistory();
  const [serviceType, setServiceType] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    dbService
      .collection("requests_temp")
      .where("__name__", "==", requestId)
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          const requestData = snapshot.docs[0].data();
          setServiceType(requestData.serviceType);

          dbService
            .collection("admins")
            .doc(loggedInUser.uid)
            .get()
            .then(() => {
              setIsValid(true);
            })
            .catch(() => {
              if (
                requestData.userId === loggedInUser.uid &&
                (requestData.status === "paid" ||
                  requestData.status === "requested")
              ) {
                setIsValid(true);
              } else {
                message.error("잘못된 요청입니다.");
                history.push("/");
              }
            });
        } else {
          message.error("잘못된 요청입니다.");
          history.push("/");
        }
      })
      .catch((error) => {
        message.error("잘못된 요청입니다.");
        history.push("/");
      });
  }, []);

  return (
    <>
      {isValid && serviceType === "drunk_driving" ? (
        <DrunkDrivingSample />
      ) : null}
    </>
  );
};

export default Sample;
