import { Divider } from "antd";
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { redirectUri } from '../states/atoms.js'
import { Container } from "components/Service.style.js";
import { KakaoLoginImg, LoginFirstSection, LoginFirstSectionDesc, LoginFirstSectionTitle, LoginSecondSection } from "./KakaoLogin.style.js";
import IcKakaoLoginBanner from "../assets/svg/IcKakaoLoginBanner.svg"
import IcKakaoLoginBannerMobile from "../assets/svg/IcKakaoLoginBannerMobile.svg"

const { Kakao } = window;

const KakaoLogin = () => {
  const location = useLocation();
  const currentURI = location.pathname;
  const [path, setPath] = useRecoilState(redirectUri);
  setPath(currentURI);
  console.log(path)

  const login = () => {
    Kakao.Auth.authorize({
      redirectUri: process.env.REACT_APP_KAKAO_REDIRECT_URL,
      prompts: "login",
    });
  };

  return (
    <Container>
      <LoginFirstSection>
        <LoginFirstSectionTitle>
          안전한 법률상담을<br />위해 로그인 해주세요
        </LoginFirstSectionTitle>
        <LoginFirstSectionDesc>
          법대리는 고객님의 개인정보를 안전하게 처리하기 위해<br />모든 정보를 암호화 하고 있어요.
        </LoginFirstSectionDesc>
      </LoginFirstSection>
      <LoginSecondSection>
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={IcKakaoLoginBannerMobile}
          />
          <source
            media="(min-width: 768px)"
            srcSet={IcKakaoLoginBanner}
          />
          <KakaoLoginImg onClick={login} src={IcKakaoLoginBanner} />
        </picture>        
      </LoginSecondSection>
    </Container>
  )
};

export default KakaoLogin;
