import { useHistory } from "react-router-dom";
import IcHowToUseFirstSectionFirst from "../../assets/img/IcHowToUseFirstSectionFirst.png";
import IcHowToUseFirstSectionFirstMobile from "../../assets/img/IcHowToUseFirstSectionFirstMobile.png";
import IcHowToUseFirstSectionSecond1 from "../../assets/img/IcHowToUseFirstSectionSecond1.png";
import IcHowToUseFirstSectionSecond2 from "../../assets/img/IcHowToUseFirstSectionSecond2.png";
import IcHowToUseFirstSectionSecond3 from "../../assets/img/IcHowToUseFirstSectionSecond3.png";
import IcHowToUseFirstSectionSecond4 from "../../assets/img/IcHowToUseFirstSectionSecond4.png";
import IcHowToUseFirstSectionSecond5 from "../../assets/img/IcHowToUseFirstSectionSecond5.png";
import IcHowToUseFirstSectionThird from "../../assets/img/IcHowToUseFirstSectionThird.png";
import IcHowToUseFirstSectionFourth1 from "../../assets/img/IcHowToUseFirstSectionFourth1.png";
import IcHowToUseFirstSectionFourth2 from "../../assets/img/IcHowToUseFirstSectionFourth2.png";
import IcHowToUseSecondSectionFirst from "../../assets/img/IcHowToUseSecondSectionFirst.png";
import IcHowToUseSecondSectionSecond from "../../assets/img/IcHowToUseSecondSectionSecond.png";
import IcHowToUseSecondSectionThird from "../../assets/img/IcHowToUseSecondSectionThird.png";
import IcHowToUseSecondSectionThird2 from "../../assets/img/IcHowToUseSecondSectionThird2.png";
import IcHowToUseSecondSectionFourth1 from "../../assets/img/IcHowToUseSecondSectionFourth1.png";
import IcHowToUseSecondSectionFourth2 from "../../assets/img/IcHowToUseSecondSectionFourth2.png";
import IcHowToUseDocSectionFirst1 from "../../assets/img/IcHowToUseDocSectionFirst1.png";
import IcHowToUseDocSectionFirst2 from "../../assets/img/IcHowToUseDocSectionFirst2.png";
import IcHowToUseDocSectionSecond from "../../assets/img/IcHowToUseDocSectionSecond.png";
import IcHowToUseDocSectionThird from "../../assets/img/IcHowToUseDocSectionThird.png";
import IcHowToUseDocSectionFourth from "../../assets/img/IcHowToUseDocSectionFourth.png";
import IcHowToUseDocSectionFourth2 from "../../assets/img/IcHowToUseDocSectionFourth2.png";
import IcHowToUseDocSectionFifth from "../../assets/img/IcHowToUseDocSectionFifth.png";
import IcHowToUseDocSectionSixth from "../../assets/img/IcHowToUseDocSectionSixth.png";
import IcHowToUseThird from "../../assets/svg/IcHowToUseThird.svg";
import IcHowToUseDocTitle from "../../assets/svg/IcHowToUseDocTitle.svg";

import {
  Container,
  ContentContainer,
} from "../Service.style";

import { useEffect, useRef } from 'react';
import {
  CarouselCard,
  CarouselCardBigWrapper,
  CarouselCardBottom,  
  CarouselCardImg,
  CarouselCardLast,
  CarouselCardWrapper,
  CarouselSlider,
  CarouselSliderWrapper,
  HowToUseFirstSection,
  HowToUseFirstSectionBodyRowHeaderDescWrapper,
  HowToUseFirstSectionBodyRowHeaderDesc,
  HowToUseFirstSectionBodyRowHeaderDescSupport,
  HowToUseFirstSectionBodyRowHeaderDescSupportWrapper,
  HowToUseFirstSectionBodyRowHeaderWrapper,
  HowToUseFirstSectionBodyRowHeaderTitle,
  HowToUseFirstSectionBodyRowImgWrapper,
  HowToUseFirstSectionBodyRowImg,
  HowToUseBodyRowWrapper,
  HowToUseSectionBodyWrapper,
  HowToUseTitleSection,
  HowToUseTitleSectionBodyWrapper,
  HowToUseTitleSectionDescRowWrapper,
  HowToUseTitleSectionDescWrapper,
  HowToUseTitleSectionDescText,
  HowToUseTitleSectionTitle,
  HowToUseFirstSectionBodyImg,
  HowToUseSecondSection,
  HowToUseDocSection,
  HowToUseDocSectionBodyImgFirstWrapper,
  HowToUseDocSectionBodyImgThirdWrapper,
  HowToUseDocSectionBodyImgFourth1Wrapper,
  HowToUseDocSectionBodyImgFourth2Wrapper,
  HowToUseDocSectionBodyImgFifthWrapper,
  HowToUseDocSectionBodyImgSixthWrapper,
  HowToUseDocTitleDescWrapper,
  HowToUseDocTitleWrapper,
  HowToUseDocTitle,
  HowToUseDocTitleImg,
  HowToUseDocDescWrapper,
  HowToUseDocDesc,
  RoundedNumText,
  HowToUseFirstSectionBodyImgThirdWrapper,
  HowToUseFirstSectionBodyImgFourth1Wrapper,
  HowToUseFirstSectionBodyImgFourth2Wrapper,
  HowToUseSecondSectionBodyImgFirstWrapper,
  HowToUseSecondSectionBodyImgSecondWrapper,
  HowToUseSecondSectionBodyImgThirdWrapper,
  HowToUseSecondSectionBodyImgThird2Wrapper,
  HowToUseSecondSectionBodyImgFourthWrapper,
  HowToUseSecondSectionBodyImgFourth2Wrapper,
  HowToUseThirdSection,
  HowToUseThirdContainer,
  HowToUseThirdImg,
  HowToUseThirdText,
} from "./HowToUse.style";

import { removeBrTag } from "routes/Landing";
import Footer from "components/Footer";

const HowToUse = () => {
  const history = useHistory();

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const scrollTargetRefFirst = useRef(null);
  const scrollTargetRefSecond = useRef(null);
  const scrollTargetRefDoc = useRef(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const secondSectionFirstText = () => {
    const text = isMobile
      ? "내가 제출한 법률 사건에 대한\n변호사 의견서가 도착하면 제안 알림이 뜹니다.\n의견서 확인하기 버튼을 클릭해서\n변호사 검토의견 목록을 확인해주세요."
      : "내가 제출한 법률 사건에 대한 변호사 의견서가 도착하면 제안 알림이 뜹니다.\n의견서 확인하기 버튼을 클릭해서 변호사 검토의견 목록을 확인해주세요.";
    return removeBrTag(text);
  };

  const secondSectionSecondText = () => {
    const text = isMobile
      ? "변호사 검토의견 목록에서\n변호사가 사건을 전담해서 수행 완료하는데까지 드는\n비용을 확인하고, 상세보기 버튼을 누르세요."
      : "변호사 검토의견 목록에서 변호사가 사건을 전담해서 수행 완료하는데까지 드는\n비용을 확인하고, 상세보기 버튼을 누르세요.";
    return removeBrTag(text);
  };

  const secondSectionThirdText = () => {
    const text = isMobile
      ? "무료 변호사 의견서를 통해\n구체적인 해결 방법을 확인한 후,\n변호사가 법적문제를 대신 해결하는 데 동의하는 위임장에 서명합니다."
      : "무료 변호사 의견서를 통해 구체적인 해결 방법을 확인한 후,\n변호사가 법적문제를 대신 해결하는 데 동의하는 위임장에 서명합니다.";
    return removeBrTag(text);
  };

  const secondSectionLastText = () => {
    const text = isMobile
      ? "이어서 결제를 완료해주세요.\n결제하기 버튼을 클릭하면, 카카오 페이에서\n결제가 진행됩니다."
      : "이어서 결제를 완료해주세요.\n결제하기 버튼을 클릭하면, 카카오 페이에서 결제가 진행됩니다.";
    return removeBrTag(text);
  };
  

  const settings = {
    infinite: false,
    slidesToShow: isMobile ? 1 : 3,
    arrows: false,
    dots: true,
    slidesToScroll: isMobile ? 1 : 3,
    variableWidth: isMobile ? false : true
  };

  const handleButtonClick = () => {
    // 스크롤 대상으로 스크롤합니다.
    if (scrollTargetRefFirst.current) {
      const yOffset = scrollTargetRefFirst.current.getBoundingClientRect().top;
      window.scrollBy({
        top: yOffset - 80, // 원하는 위치까지의 거리를 조절하세요
        behavior: 'smooth',
      });
    }
  };

  const handleSecondButtonClick = () => {
    if (scrollTargetRefSecond.current) {
      const yOffset = scrollTargetRefSecond.current.getBoundingClientRect().top;
      window.scrollBy({
        top: yOffset - 80, // 원하는 위치까지의 거리를 조절하세요
        behavior: 'smooth',
      });
    }
  };
  
  const handleDocButtonClick = () => {
    if (scrollTargetRefDoc.current) {
      const yOffset = scrollTargetRefDoc.current.getBoundingClientRect().top;
      window.scrollBy({
        top: yOffset - 80, // 원하는 위치까지의 거리를 조절하세요
        behavior: 'smooth',
      });
    }
  };

  return (
    <Container>
      <HowToUseTitleSection>
        <ContentContainer style={{
            maxWidth: "700px",
          }}>
          <HowToUseTitleSectionBodyWrapper>
            <HowToUseTitleSectionTitle>
                법대리 사용법 안내
            </HowToUseTitleSectionTitle>
            <HowToUseTitleSectionDescRowWrapper>
              <HowToUseTitleSectionDescWrapper onClick={() => handleButtonClick()}>
                <HowToUseTitleSectionDescText>
                  신청서 제출 방법
                </HowToUseTitleSectionDescText>
              </HowToUseTitleSectionDescWrapper>
              <HowToUseTitleSectionDescWrapper onClick={() => handleSecondButtonClick()}>
                <HowToUseTitleSectionDescText>
                  변호사 의견서 확인 및 결제 방법
                </HowToUseTitleSectionDescText>
              </HowToUseTitleSectionDescWrapper>
              <HowToUseTitleSectionDescWrapper onClick={() => handleDocButtonClick()}>
                <HowToUseTitleSectionDescText>
                  변호사에게 서류 제출하는 방법
                </HowToUseTitleSectionDescText>
              </HowToUseTitleSectionDescWrapper>
            </HowToUseTitleSectionDescRowWrapper>
          </HowToUseTitleSectionBodyWrapper>
        </ContentContainer>
      </HowToUseTitleSection>
      <HowToUseFirstSection ref={scrollTargetRefFirst}>
        <ContentContainer style={{
            maxWidth: "700px",
          }}>
            <HowToUseSectionBodyWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderWrapper>
                  <HowToUseFirstSectionBodyRowHeaderTitle>
                    신청서 제출 방법
                  </HowToUseFirstSectionBodyRowHeaderTitle>
                </HowToUseFirstSectionBodyRowHeaderWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper>
                  <RoundedNumText>1</RoundedNumText>
                  <HowToUseFirstSectionBodyRowHeaderDesc>
                    법대리 홈에서 원하는 법률 서비스를 선택해주세요
                  </HowToUseFirstSectionBodyRowHeaderDesc>
                </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseFirstSectionBodyRowImgWrapper>
                    <picture>
                  <source media="(max-width: 768px)" srcSet={IcHowToUseFirstSectionFirstMobile} />
                  <source media="(min-width: 768px)" srcSet={IcHowToUseFirstSectionFirst} />
                  <HowToUseFirstSectionBodyRowImg src={IcHowToUseFirstSectionFirst} />
                  </picture>
                </HowToUseFirstSectionBodyRowImgWrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescSupportWrapper>
                  <HowToUseFirstSectionBodyRowHeaderDescWrapper>
                    <RoundedNumText>2</RoundedNumText>
                    <HowToUseFirstSectionBodyRowHeaderDesc>
                      신청서를 작성하고 제출해주세요.
                    </HowToUseFirstSectionBodyRowHeaderDesc>                    
                  </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                  <HowToUseFirstSectionBodyRowHeaderDescSupport>
                      *서명을 완료해야 위임하기 버튼이 활성화 됩니다.
                    </HowToUseFirstSectionBodyRowHeaderDescSupport>
                </HowToUseFirstSectionBodyRowHeaderDescSupportWrapper>
                <CarouselSliderWrapper>
                  <CarouselSlider {...settings}>
                    <CarouselCardWrapper>
                      <CarouselCard>
                      <CarouselCardImg src={IcHowToUseFirstSectionSecond1}></CarouselCardImg>
                      </CarouselCard>
                    </CarouselCardWrapper>
                    <CarouselCardWrapper>
                      <CarouselCard>
                        <CarouselCardImg src={IcHowToUseFirstSectionSecond2}></CarouselCardImg>
                      </CarouselCard>
                    </CarouselCardWrapper>
                    <CarouselCardWrapper>
                      <CarouselCard>
                        <CarouselCardImg src={IcHowToUseFirstSectionSecond3}></CarouselCardImg>
                      </CarouselCard>
                    </CarouselCardWrapper>
                    <CarouselCardBigWrapper>
                      <CarouselCardBottom>
                        <CarouselCardImg src={IcHowToUseFirstSectionSecond4}></CarouselCardImg>
                      </CarouselCardBottom>
                    </CarouselCardBigWrapper>
                    <CarouselCardBigWrapper>
                      <CarouselCardLast>
                        <CarouselCardImg src={IcHowToUseFirstSectionSecond5}></CarouselCardImg>
                      </CarouselCardLast>
                    </CarouselCardBigWrapper>
                    {!isMobile && <CarouselCardBigWrapper>
                      <CarouselCardLast>
                      </CarouselCardLast>
                    </CarouselCardBigWrapper>}                    
                  </CarouselSlider>
                </CarouselSliderWrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper>
                    <RoundedNumText>3</RoundedNumText>
                    <HowToUseFirstSectionBodyRowHeaderDesc>
                        제출이 완료된 것을 확인하세요.
                    </HowToUseFirstSectionBodyRowHeaderDesc>                    
                </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseFirstSectionBodyImgThirdWrapper>
                  <HowToUseFirstSectionBodyImg src={IcHowToUseFirstSectionThird}/>
                </HowToUseFirstSectionBodyImgThirdWrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper>
                  <RoundedNumText>4</RoundedNumText>
                  <HowToUseFirstSectionBodyRowHeaderDesc>
                    신청서는 ‘나의 제출 목록’ 에서 확인 가능합니다.
                  </HowToUseFirstSectionBodyRowHeaderDesc>                    
                </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseFirstSectionBodyImgFourth1Wrapper>
                  <HowToUseFirstSectionBodyImg style={{width:320}} src={IcHowToUseFirstSectionFourth1}/>
                </HowToUseFirstSectionBodyImgFourth1Wrapper>
                <HowToUseFirstSectionBodyImgFourth2Wrapper>
                  <HowToUseFirstSectionBodyImg style={{width:272}} src={IcHowToUseFirstSectionFourth2}/>
                </HowToUseFirstSectionBodyImgFourth2Wrapper>
              </HowToUseBodyRowWrapper>
            </HowToUseSectionBodyWrapper>
        </ContentContainer>
      </HowToUseFirstSection>
      <HowToUseSecondSection ref={scrollTargetRefSecond}>
        <ContentContainer style={{
              maxWidth: "700px",
            }}>
            <HowToUseSectionBodyWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderWrapper>
                  <HowToUseFirstSectionBodyRowHeaderTitle>
                    변호사 의견서 확인 및 결제 방법
                  </HowToUseFirstSectionBodyRowHeaderTitle>
                </HowToUseFirstSectionBodyRowHeaderWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>1</RoundedNumText>
                  <HowToUseFirstSectionBodyRowHeaderDesc>
                  {secondSectionFirstText()}
                  </HowToUseFirstSectionBodyRowHeaderDesc>
                </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseSecondSectionBodyImgFirstWrapper>
                  <HowToUseFirstSectionBodyImg style={{width:272}} src={IcHowToUseSecondSectionFirst}/>
                </HowToUseSecondSectionBodyImgFirstWrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>2</RoundedNumText>
                  <HowToUseFirstSectionBodyRowHeaderDesc>
                    {secondSectionSecondText()}
                  </HowToUseFirstSectionBodyRowHeaderDesc>
                </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseSecondSectionBodyImgSecondWrapper>
                  <HowToUseFirstSectionBodyImg style={{width:312}} src={IcHowToUseSecondSectionSecond}/>
                </HowToUseSecondSectionBodyImgSecondWrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>3</RoundedNumText>
                  <HowToUseFirstSectionBodyRowHeaderDesc>
                    {secondSectionThirdText()}
                  </HowToUseFirstSectionBodyRowHeaderDesc>
                </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseSecondSectionBodyImgThirdWrapper>
                  <HowToUseFirstSectionBodyImg style={{width:270}} src={IcHowToUseSecondSectionThird}/>
                </HowToUseSecondSectionBodyImgThirdWrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
              <HowToUseFirstSectionBodyRowHeaderDescSupportWrapper>
                  <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>4</RoundedNumText>
                    <HowToUseFirstSectionBodyRowHeaderDesc>
                      회색 박스에 성함을 입력해 서명을 완료한 뒤, <br/>위임하기 버튼을 클릭해주세요.
                    </HowToUseFirstSectionBodyRowHeaderDesc>                    
                  </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                  <HowToUseFirstSectionBodyRowHeaderDescSupport>
                      *서명을 완료해야 위임하기 버튼이 활성화 됩니다.
                    </HowToUseFirstSectionBodyRowHeaderDescSupport>
                </HowToUseFirstSectionBodyRowHeaderDescSupportWrapper>
                <HowToUseSecondSectionBodyImgThird2Wrapper>
                  <HowToUseFirstSectionBodyImg style={{width:270}} src={IcHowToUseSecondSectionThird2}/>
                </HowToUseSecondSectionBodyImgThird2Wrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>5</RoundedNumText>
                  <HowToUseFirstSectionBodyRowHeaderDesc>
                  {secondSectionLastText()}
                  </HowToUseFirstSectionBodyRowHeaderDesc>
                </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseSecondSectionBodyImgFourthWrapper>
                  <HowToUseFirstSectionBodyImg style={{width:270}} src={IcHowToUseSecondSectionFourth1}/>
                </HowToUseSecondSectionBodyImgFourthWrapper>
                <HowToUseSecondSectionBodyImgFourth2Wrapper style={{background: "#FFEB00"}}>
                  <HowToUseFirstSectionBodyImg style={{width:270}} src={IcHowToUseSecondSectionFourth2}/>
                </HowToUseSecondSectionBodyImgFourth2Wrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper id="SubmissionScroll"></HowToUseBodyRowWrapper>
            </HowToUseSectionBodyWrapper>
        </ContentContainer>
      </HowToUseSecondSection>
      <HowToUseDocSection ref={scrollTargetRefDoc}>
        <ContentContainer style={{
              maxWidth: "700px",
            }}>
            <HowToUseSectionBodyWrapper>
              <HowToUseDocTitleDescWrapper>
                <HowToUseDocTitleWrapper>
                  <HowToUseDocTitleImg src={IcHowToUseDocTitle}/>
                  <HowToUseDocTitle>서류 제출 관련 안내</HowToUseDocTitle>
                </HowToUseDocTitleWrapper>  
                <HowToUseDocDescWrapper>
                  <HowToUseDocDesc>
                  법대리의 안내에 따라<br/>
                  사건과 관련된 서류를 사이트에 제출해 주세요.<br/>
                  변호사가 사건을 원활하게 해결하기 위해<br/>
                  필요한 것이니 해당 절차를 꼭 지켜주시기 바랍니다.<br/>
                  </HowToUseDocDesc>
                </HowToUseDocDescWrapper>
              </HowToUseDocTitleDescWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderWrapper>
                  <HowToUseFirstSectionBodyRowHeaderTitle>
                    변호사에게 서류 제출하는 방법
                  </HowToUseFirstSectionBodyRowHeaderTitle>
                </HowToUseFirstSectionBodyRowHeaderWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>1</RoundedNumText>
                  <HowToUseFirstSectionBodyRowHeaderDesc>
                  나의 제출 목록에서 서류요청 알림을 확인 후<br/>사건 상세보기를 클릭하세요.
                  </HowToUseFirstSectionBodyRowHeaderDesc>
                </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseSecondSectionBodyImgThirdWrapper>
                  <HowToUseFirstSectionBodyImg style={{width:320}} src={IcHowToUseDocSectionFirst1}/>
                </HowToUseSecondSectionBodyImgThirdWrapper>
                <HowToUseDocSectionBodyImgFirstWrapper>
                  <HowToUseFirstSectionBodyImg style={{width:320}} src={IcHowToUseDocSectionFirst2}/>
                </HowToUseDocSectionBodyImgFirstWrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>2</RoundedNumText>
                  <HowToUseFirstSectionBodyRowHeaderDesc>
                  상단 탭 바에서 ‘서류 제출하기’를 클릭해주세요.                  
                  </HowToUseFirstSectionBodyRowHeaderDesc>
                </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseSecondSectionBodyImgThirdWrapper>
                  <HowToUseFirstSectionBodyImg style={{width:320}} src={IcHowToUseDocSectionSecond}/>
                </HowToUseSecondSectionBodyImgThirdWrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>3</RoundedNumText>
                  <HowToUseFirstSectionBodyRowHeaderDesc>
                    화면을 아래로 내려 ‘제출 미완료’ 영역에서<br/>내가 제출해야 하는 서류 목록을 확인하세요.
                  </HowToUseFirstSectionBodyRowHeaderDesc>
                </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseDocSectionBodyImgThirdWrapper>
                  <HowToUseFirstSectionBodyImg style={{width:320}} src={IcHowToUseDocSectionThird}/>
                </HowToUseDocSectionBodyImgThirdWrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>4</RoundedNumText>
                    <HowToUseFirstSectionBodyRowHeaderDesc>
                    서류 목록 중 하나를 클릭하면<br/>제출해야하는 서류에 대한 안내창이 뜹니다.
                    <br/>안내 사항을 꼼꼼히 읽고 이에 해당하는 사건 서류를<br/>+ 파일 업로드 하기 버튼을 클릭해서 업로드 해주세요.
                    </HowToUseFirstSectionBodyRowHeaderDesc>                    
                  </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                <HowToUseDocSectionBodyImgFourth1Wrapper>
                  <HowToUseFirstSectionBodyImg style={{width:320}} src={IcHowToUseDocSectionFourth}/>
                </HowToUseDocSectionBodyImgFourth1Wrapper>
                <HowToUseDocSectionBodyImgFourth2Wrapper>
                  <HowToUseFirstSectionBodyImg style={{width:320}} src={IcHowToUseDocSectionFourth2}/>
                </HowToUseDocSectionBodyImgFourth2Wrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescSupportWrapper>
                  <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>5</RoundedNumText>
                    <HowToUseFirstSectionBodyRowHeaderDesc>
                      서류가 제대로 등록된 것을 확인해주세요.
                    </HowToUseFirstSectionBodyRowHeaderDesc>                    
                  </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                  <HowToUseFirstSectionBodyRowHeaderDescSupport>
                    * 업로드 된 사건 서류는 다운로드하여 확인하실 수 있습니다.
                    </HowToUseFirstSectionBodyRowHeaderDescSupport>
                </HowToUseFirstSectionBodyRowHeaderDescSupportWrapper>
                <HowToUseDocSectionBodyImgFifthWrapper>
                  <HowToUseFirstSectionBodyImg style={{width:320}} src={IcHowToUseDocSectionFifth}/>
                </HowToUseDocSectionBodyImgFifthWrapper>
              </HowToUseBodyRowWrapper>
              <HowToUseBodyRowWrapper>
                <HowToUseFirstSectionBodyRowHeaderDescSupportWrapper>
                  <HowToUseFirstSectionBodyRowHeaderDescWrapper style={{alignItems: "flex-start"}}>
                  <RoundedNumText style={{marginTop: 4}}>6</RoundedNumText>
                    <HowToUseFirstSectionBodyRowHeaderDesc>
                      제출이 완료된 서류는 ‘제출 완료’ 영역으로 넘어갑니다.
                    </HowToUseFirstSectionBodyRowHeaderDesc>                    
                  </HowToUseFirstSectionBodyRowHeaderDescWrapper>
                  <HowToUseFirstSectionBodyRowHeaderDescSupport>
                  * 사건 서류를 다시 업로드 하려면, ‘+다시 업로드’ 버튼을 클릭해 주세요.
                    </HowToUseFirstSectionBodyRowHeaderDescSupport>
                </HowToUseFirstSectionBodyRowHeaderDescSupportWrapper>
                <HowToUseDocSectionBodyImgSixthWrapper>
                  <HowToUseFirstSectionBodyImg style={{width:320}} src={IcHowToUseDocSectionSixth}/>
                </HowToUseDocSectionBodyImgSixthWrapper>
              </HowToUseBodyRowWrapper>
            </HowToUseSectionBodyWrapper>
        </ContentContainer>
      </HowToUseDocSection>
      <HowToUseThirdSection>
        <ContentContainer style={{
              maxWidth: "700px",
            }}>
            <HowToUseThirdContainer>
              <HowToUseThirdImg src={IcHowToUseThird}/>
            <HowToUseThirdText>
              이제 법대리에서 모든 진행과정을 대신 처리해 드립니다!
              <br/>
              언제 어디서든 법대리를 통해
              <br/>
              온라인으로 법률 해결 과정을 확인할 수 있습니다.
            </HowToUseThirdText>
            </HowToUseThirdContainer>
        </ContentContainer>
      </HowToUseThirdSection>
      <Footer/>
    </Container>
  );
};

export default HowToUse;
