import { useHistory } from "react-router-dom";

import IcPayServiceMain from "../../assets/svg/IcPayServiceMain.svg";
import IcPayServiceMainMobile from "../../assets/svg/IcPayServiceMainMobile.svg";
import IcPayServiceBody from "../../assets/svg/IcPayServiceBody.svg";
import IcPayServiceBodyMobile from "../../assets/svg/IcPayServiceBodyMobile.svg";
import IcPayServiceBody2 from "../../assets/svg/IcPayServiceBody2.svg";
import IcPayServiceBody2Mobile from "../../assets/svg/IcPayServiceBody2Mobile.svg";

import {
  Container,
} from "../Service.style";
import {
  ServiceCenterFirstContainer,
  ServiceCenterFirstImg,
  ServiceCenterFirstSection,
  ServiceCenterFirstTitle,
  ServiceCenterFooterBtn,
  ServiceCenterFooterContainer,
  ServiceCenterFooterDesc,
  ServiceCenterFooterSection,
  ServiceCenterFooterTextContainer,
  ServiceCenterFooterTitle,
  ServiceCenterSecondContainer,
  ServiceCenterSecondDesc,
  ServiceCenterSecondImg,
  ServiceCenterSecondSection,
  ServiceCenterSecondSubTitle,
  ServiceCenterSecondTextContainer,
  ServiceCenterSecondTitle,
  ServiceCenterThirdContainer,
  ServiceCenterThirdDesc,
  ServiceCenterThirdDescSpan,
  ServiceCenterThirdImg,
  ServiceCenterThirdImgMobile,
  ServiceCenterThirdSection,
  ServiceCenterThirdTextContainer,
  ServiceCenterThirdTitle
} from "./ServiceCenter.style";
import Footer from "components/Footer";
import { useEffect } from "react";
import ChannelService from "../ChannelService";
import { removeBrTag } from "routes/Landing";

const PayServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const title = () => {
    const text = "밀린 월급, 퇴직금\n권고사직 대응하기";
    return removeBrTag(text);
  };

  const body1Title = () => {
    const text = isMobile
      ? "임금체불, 퇴직금\n미지급 사건"
      : "임금체불, 퇴직금 미지급 사건";
    return removeBrTag(text);
  };

  const body1Subtitle = () => {
    const text = "마땅히 받아야 할 나의 권리입니다.";
    return removeBrTag(text);
  };

  const body2Title = () => {
    const text = isMobile
      ? "임금체불 대응은\n법대리에서!"
      : "임금체불 대응은 법대리에서!";
    return removeBrTag(text);
  };

  const footerTitle = () => {
    const text = isMobile
      ? "임금체불 관련\n도움이 필요하신가요?"
      : "임금체불 관련 도움이 필요하신가요?";
    return removeBrTag(text);
  };


  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ServiceCenterFirstSection>
        <ServiceCenterFirstContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcPayServiceMainMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcPayServiceMain}
            />
            <ServiceCenterFirstImg src={IcPayServiceMain} />
          </picture>
          <ServiceCenterFirstTitle>
            {title()}
          </ServiceCenterFirstTitle>
        </ServiceCenterFirstContainer>
      </ServiceCenterFirstSection>
      <ServiceCenterSecondSection>
        <ServiceCenterSecondContainer>
          <ServiceCenterSecondTextContainer>
            <ServiceCenterSecondTitle>{body1Title()}</ServiceCenterSecondTitle>
            <ServiceCenterSecondSubTitle>
              {body1Subtitle()}
            </ServiceCenterSecondSubTitle>
            <ServiceCenterSecondDesc>
              회사로부터 임금 체불, 퇴직금 미지급 등의 피해를 입는 근로자들이 최근 굉장히 많아지고 있습니다.
              임금 체불 관련 피해가 발생한 경우 대응 방안은 민사 소송, 형사 소송이 있으며 임금 체불은 반의사불벌죄,
              즉 피해자인 근로자가 합의를 하면 고용주는 처벌을 받지 않기 때문에 형사 소송을 주로 진행하게 됩니다.
            </ServiceCenterSecondDesc>
          </ServiceCenterSecondTextContainer>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={IcPayServiceBodyMobile}
            />
            <source
              media="(min-width: 768px)"
              srcSet={IcPayServiceBody}
            />
            <ServiceCenterSecondImg src={IcPayServiceBody} />
          </picture>
        </ServiceCenterSecondContainer>
      </ServiceCenterSecondSection>
      <ServiceCenterThirdSection>
        <ServiceCenterThirdContainer>
          <ServiceCenterThirdImg src={IcPayServiceBody2} />
          <ServiceCenterThirdTextContainer>
            <ServiceCenterThirdTitle>
              {body2Title()}
            </ServiceCenterThirdTitle>
            <ServiceCenterThirdDesc>
              법대리 전문 변호사의 임금체불, 퇴직금 미지급 소송 서비스는 가장 빠르고 확실하게
              사업주를 압박하여 받지 못한 임금, 퇴직금을 받을 수 있도록 서비스를 제공하고 있습니다.
            </ServiceCenterThirdDesc>
          </ServiceCenterThirdTextContainer>
          <ServiceCenterThirdImgMobile src={IcPayServiceBody2Mobile} />
        </ServiceCenterThirdContainer>
      </ServiceCenterThirdSection>
      <ServiceCenterFooterSection>
        <ServiceCenterFooterContainer>
          <ServiceCenterFooterTextContainer>
            <ServiceCenterFooterTitle>
              {footerTitle()}
            </ServiceCenterFooterTitle>
            <ServiceCenterFooterDesc>
              현재 겪고 계신 법률 문제와 해결하고 싶은 문제를 제출 해주시면
              분야별로 가장 경험이 많고 실력있는 담당 변호사들이 검토 후 안내연락을 무료로 드립니다.
            </ServiceCenterFooterDesc>
          </ServiceCenterFooterTextContainer>
          <ServiceCenterFooterBtn onClick={() => history.push("/apply/payService")}>
            임금체불 도움받기
          </ServiceCenterFooterBtn>
        </ServiceCenterFooterContainer>
      </ServiceCenterFooterSection>
    </Container>
  )
};

export default PayServiceCenter;
