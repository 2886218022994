import { useHistory } from "react-router-dom";

import IcServiceMain from "assets/svg/Ic3.3GolfTennis.svg";

import {
  Container,
} from "components/Service.style";

import { useEffect } from "react";
import { removeBrTag } from "routes/Landing";
import { EventHeaderContainer, EventHeaderDesc, EventHeaderDescSpan, EventHeaderImg, EventHeaderSection, EventHeaderSubTitle, EventHeaderTitle } from "../EventStyle.style";
import { EventDesc } from "../EventDesc";
import { EventDesc2 } from "../EventDesc2";
import { EventDetailSubmit } from "../EventDetailSubmit";
import { EventDetailFooter } from "../EventDetailFooter";

const EventGolfTennisServiceCenter = ({ loggedInUser }) => {
  const history = useHistory();

  const onClickBtn = () => {
    history.push("/loan/order");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <EventHeaderSection>
        <EventHeaderContainer>
          <EventHeaderImg src={IcServiceMain} />
          <EventHeaderTitle>
            골프･테니스 환불
          </EventHeaderTitle>
          <EventHeaderSubTitle>
            내용 증명으로 해결하기
          </EventHeaderSubTitle>
          <EventHeaderDesc>
            골프･테니스 레슨 계약은 주로 업체에 유리한 내용으로 작성되어 있어요.
            하지만 계약서에 작성된 내용이라 해도 소비자 보호법을 위반하거나 공정한 거래를 방해하는 경우 무효화 될 수 있으며,
            업체에서 거부하더라도 법적으로 환불을 요청할 수 있어요. 전문 변호사가 발송하는 <EventHeaderDescSpan>내용 증명</EventHeaderDescSpan>을 통해 손쉽게 해결해 보세요.
          </EventHeaderDesc>
        </EventHeaderContainer>
      </EventHeaderSection>
      <EventDesc />
      <EventDesc2 />
      <EventDetailSubmit landingPath="/3o3/golfTennis/certificate"/>
      <EventDetailFooter />
    </Container>
  )
};

export default EventGolfTennisServiceCenter;