import React from 'react';
import DetailDataAdaptor from 'util/DetailDataAdaptor';


function MyCaseDetailModal({caseData}) {
    return (
        <>
        {/* <div style={{display: 'block', margin: 'auto'}}> */}
        <DetailDataAdaptor serviceData={caseData.service_data} serviceType={caseData.serviceType}/>
        {/* </div> */}
        </>
    );
}

export default MyCaseDetailModal;