import { useHistory, useLocation } from "react-router-dom";

import IcServiceMain from "assets/svg/Ic3.3Loan.svg";

import {
  Container,
} from "components/Service.style";

import { useEffect, useState } from "react";
import {
  EventDetailDataContainer,
  EventDetailDataEachContainer,
  EventDetailDataEachContent,
  EventDetailDataEachTitle,
  EventDetailDesc,
  EventDetailDrawer,
  EventDetailFooterBtn,
  EventDetailFooterContainer,
  EventDetailFooterTitle,
  EventHeaderContainer,
  EventHeaderImg,
  EventHeaderSection,
  EventHeaderTitle
} from "../../EventStyle.style";
import { removeBrTag } from "routes/Landing";

import { EventSubmitNew } from "./EventSubmitNew";

import { useRecoilValue } from "recoil";
import { to3DataState } from "states/atoms";

const EventLoanCertificate = ({ loggedInUser }) => {
  const to3Data = useRecoilValue(to3DataState);
  const history = useHistory();
  const location = useLocation();

  const [showApply, setShowApply] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  if (to3Data === null) {
    return <></>
  }

  const showDrawer = () => {
    setShowApply(true);
  };

  const onClose = () => {
    setShowApply(false);
  };

  const eventServiceData = () => {
    return to3Data;
  }

  const refundAmount = () => {
    const amount = to3Data.billingAmount
    if (amount) {
      return Number(amount).toLocaleString() + " 원"
    } else {
      return "원"
    }
  }

  const getOpponentName = () => {
    const opponentName = to3Data.opponentName
    if (opponentName) {
      return opponentName
    } else {
      return ""
    }
  }

  const getOpponentData = () => {
    const address = to3Data.opponentAddress
    const opponentPhone = to3Data.opponentPhone
    if (address !== "" && opponentPhone !== "") {
      return `${opponentPhone} \n ${address}`
    } else if (address !== "") {
      return address
    } else if (opponentPhone !== "") {
      return opponentPhone
    } else {
      return ""
    }
  }

  return (
    <Container>
      <EventHeaderSection>
        <EventHeaderContainer>
          <EventHeaderImg src={IcServiceMain} />
          <EventHeaderTitle>
            못받은 돈 받기
          </EventHeaderTitle>
          <EventDetailDesc>
            법대리 내용증명 서비스를 이용하신 고객님들 중 80% 이상이 대여금 문제를 해결했어요.
            법대리 내용증명을 통해 대여금 문제를 손쉽게 해결해보세요
          </EventDetailDesc>
        </EventHeaderContainer>
      </EventHeaderSection>
      <EventDetailDataContainer>
        <EventDetailDataEachContainer>
          <EventDetailDataEachTitle>
            돌려 받을 금액
          </EventDetailDataEachTitle>
          <EventDetailDataEachContent>
            {refundAmount()}
          </EventDetailDataEachContent>
        </EventDetailDataEachContainer>
        <EventDetailDataEachContainer>
          <EventDetailDataEachTitle>
            채무자(빌린 사람)의 이름
          </EventDetailDataEachTitle>
          <EventDetailDataEachContent>
            {getOpponentName()}
          </EventDetailDataEachContent>
        </EventDetailDataEachContainer>
        <EventDetailDataEachContainer>
          <EventDetailDataEachTitle>
            채무자(빌린 사람)의 연락처 / 주소
          </EventDetailDataEachTitle>
          <EventDetailDataEachContent style={{lineHeight: "36px"}}>
            {removeBrTag(getOpponentData())}
          </EventDetailDataEachContent>
        </EventDetailDataEachContainer>
        <EventDetailDesc>
          친구나 전 연인에게 빌려주고 못 받고 있는 돈, 소액이라 돌려받기 포기하고 있던 돈,
          법대리가 받아드릴게요. 대여금 반환의 내용증명은 상대방에게 법적 대응을 하겠다는 강력한 경고를 함으로써
          가장 빠르고 쉽게 빌려준 돈을 돌려 받을 수 있는 방법이에요.
          전문 변호사가 발송하는 내용 증명을 통해 손쉽게 해결할 수 있어요.
        </EventDetailDesc>
      </EventDetailDataContainer>
      <EventDetailFooterContainer>
        <EventDetailFooterTitle>
          위 정보로 전문 변호사가<br />무료로 검토해 드릴게요.
        </EventDetailFooterTitle>
        <EventDetailFooterBtn onClick={() => showDrawer()}>
          무료 검토 신청하기
        </EventDetailFooterBtn>
      </EventDetailFooterContainer>
      <EventDetailDrawer
        placement={"bottom"}
        closable={false}
        onClose={onClose}
        open={showApply}
        key={"bottom"}
      >
        <EventSubmitNew
          eventServiceData={eventServiceData()}
          serviceType={"3o3_loan"} />
      </EventDetailDrawer>
    </Container>
  )
};

export default EventLoanCertificate;