export function LandingToClientApp() {
    window.location.href = 'https://by-law.in';
 };

export function LandingToChannelTalk() {
    window.location.href = 'https://pf.kakao.com/_VxdACxj/chat';
}

export function LandingTo3o3Page() {
    window.location.href = 'https://app.lab.3o3.co.kr/by-law';
}


export function openInNewTab(url) {
    window.open(url, '_blank').focus();
  }