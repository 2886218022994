// atoms.js
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
    key: "sessionStorage",
    storage: sessionStorage,
  })

export const redirectUri = atom({
  key: "redirectUri",
  default: '',
  effects_UNSTABLE: [persistAtom]
});

export const redirectEtcUri = atom({
  key: "redirectEtcUri",
  default: '',
  effects_UNSTABLE: [persistAtom]
});

export const userData = atom({
  key: "userData",
  default: {name:"",phoneNumber:""},
  effects_UNSTABLE: [persistAtom]
});

// myCase Data.
export const currentMyCaseState = atom({
  key: "currentMyCaseState",
  default: null,
  effects_UNSTABLE: [persistAtom]
});

// 변호사 사건 Data.
export const currentLawyerCaseState = atom({
  key: "currentLawyerCaseState",
  default: null,
  effects_UNSTABLE: [persistAtom]
});

// 클라이언트의 제출한 Preview
export const currentClientSubmitPreviewState = atom({
  key: "currentClientSubmitPreviewState",
  default: null,
  effects_UNSTABLE: [persistAtom]
});

// 클라이언트의 미제출 Preview
export const currentClientNotSubmitPreviewState = atom({
  key: "currentClientNotSubmitPreviewState",
  default: null,
  effects_UNSTABLE: [persistAtom]
});

export const userObj = atom({
  key: "userObj",
  default: null,
  effects_UNSTABLE: [persistAtom]
});

// myCase Data.
export const selectOpinionState = atom({
  key: "selectOpinionState",
  default: null,
  effects_UNSTABLE: [persistAtom]
});

// myCase Data.
export const livingLawDetailIdxState = atom({
  key: "livingLawDetailIdxState",
  default: 0,
  effects_UNSTABLE: [persistAtom]
});

// 3o3 Data.
export const to3DataState = atom({
  key: "to3Data",
  default: null,
  effects_UNSTABLE: [persistAtom]
});