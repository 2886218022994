import Step1 from "components/form/loan/order/Step1";
import Step2 from "components/form/loan/order/Step2";
import Step3 from "components/form/loan/order/Step3";
import { v4 as uuidv4 } from "uuid";
import { dbService } from "fbase";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { userData } from "states/atoms";

const LoanOrder = ({ loggedInUser }) => {
  const [page, setPage] = useState(0);
  const history = useHistory();
  const userInfo = useRecoilValue(userData);

  const defaultValues = {
    loans: [
      {
        lendDate: {
          value: null,
        },
        dueDate: {
          value: null,
          unknown: false,
        },
        amount: null,
        interests: {
          exist: "n",
          unit: "year",
          value: null,
        },
      },
    ],
    repayments: [],
    lender: {
      type: null,
    },
    loaner: {
      type: null,
      addressYn: null,
      ssnYn: null,
    },
    overDueInterests: {
      requestYn: null,
    },
    description: null,
    attachments: null,
    promotionCode: null,
  };

  const itemLayout = {
    form: {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 16,
      },
    },
    button: {
      wrapperCol: { span: 24, offset: 0 },
    },
  };

  const [step1Data, setStep1Data] = useState({ loans: defaultValues.loans });
  const [step2Data, setStep2Data] = useState({
    repayments: defaultValues.repayments,
  });

  const [step3Data, setStep3Data] = useState({
    loaner: defaultValues.loaner,
    lender: defaultValues.lender,
    description: defaultValues.description,
    attachments: defaultValues.attachments,
    promotionCode: defaultValues.promotionCode,
  });

  const onPrev = () => {
    setPage(page - 1);
  };

  const onNext = () => {
    setPage(page + 1);
  };

  const onSubmit = async () => {
    const formattedStep1Data = {
      loans: step1Data.loans.map((loan) => {
        const lendDate = loan.lendDate.value
          ? moment(loan.lendDate.value).format("YYYY-MM-DD")
          : null;

        const dueDate = loan.dueDate.value
          ? moment(loan.dueDate.value).format("YYYY-MM-DD")
          : null;

        return {
          ...loan,
          lendDate: lendDate,
          dueDate: dueDate,
        };
      }),
    };

    const formattedStep2Data = {
      repayments: step2Data.repayments.map((repayment) => {
        const paidDate = repayment.date.value
          ? moment(repayment.date.value).format("YYYY-MM-DD")
          : null;

        return {
          ...repayment,
          date: paidDate,
        };
      }),
    };

    const moneybackData = {
      ...formattedStep1Data,
      ...formattedStep2Data,
      ...step3Data,
      userId: loggedInUser.uid,
    };

    // console.log("moneybackData", moneybackData);
    const uuid = uuidv4();
    try {
      await dbService.collection("requests_temp").add({
        serviceType: "lend_money",
        serviceId: uuid,
        createdAt: Date.now(),
        service_data: moneybackData,
        userId: loggedInUser.uid,
        clientName: userInfo.name,
        status: "submitted",
      });
      history.push("/submit/success");
    } catch (err) {
      // console.log(err);
      history.push("/submit/fail");
    }
  };

  const steps = [
    {
      title: "대여금 정보",
      content: (
        <Step1
          data={step1Data}
          setData={setStep1Data}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "상환금 정보",
      content: (
        <Step2
          data={step2Data}
          setData={setStep2Data}
          onPrev={onPrev}
          onNext={onNext}
          itemLayout={itemLayout}
        />
      ),
    },
    {
      title: "기타 자료",
      content: (
        <Step3
          data={step3Data}
          setData={setStep3Data}
          onPrev={onPrev}
          onNext={onSubmit}
          itemLayout={itemLayout}
        />
      ),
    },
  ];

  return (
    <>
      <div className="steps-content">{steps[page].content}</div>
    </>
  );
};

export default LoanOrder;
