import { useHistory } from "react-router-dom";
import IcBook from "../../assets/svg/IcBook.svg";
import {
  CaseInfoSection,
  CaseStatusSection,
  DateText,
  DateTextValue,
  DateTextWrapper,
  LawyerCardBaseWrapper,
  LawyerCardLabel,
  LawyerCardLeftWrapper,
  LawyerCardProfile,
  LawyerCardProposeWrapper,
  LawyerCardProposeWrapperValue,
  LawyerCardTextWrapper,
  LawyerCardTitle,
  LawyerCardWrapper,
  LaywerInfoSection,
  ProgressWrapper,
  ProgressStateBorder,
  ProgressStateIcon,
  ProgressStateTitle,
  ProgressStateValue,
  ProgressStateWrapper,
  RequestServiceBtn,
  RequestServiceSection,
  RequestServiceSectionContainer,
  RequestServiceWrapper,
  RequestServiceTextWrapper,
  CaseProgressGuideWrapper,
  CaseProgressGuideText,
  Title
} from "./CaseDetailComp.style";

import axios from "axios";
import Modal from 'react-modal';
import { useEffect, useState } from "react";
import { ModalStyle, LawyerOpinionModalStyle } from "util/ModalStyle";
import { getFormattedDate } from "util/util";
import MyCaseDetailModal from "components/mypage/MyCaseDetailModal";
import LawyerOpinion from "components/mypage/LawyerOpinion";
import { getServiceTypeTitle } from "components/mypage/MyCaseList";
import ProfileAcceptedDefault from "assets/svg/ProfileAcceptedDefault.svg";
import IcStatusCurrent from "assets/svg/IcStatusCurrent.svg";
import IcStatusNone from "assets/svg/IcStatusNone.svg";
import { isCaseDocument } from "util/ServiceType";
import { removeBrTag } from "routes/Landing";
import { useRecoilState } from "recoil";
import { currentMyCaseState, selectOpinionState } from "states/atoms";

const CaseProgress = ({ loggedInUser, fromAdmin }) => {
  const history = useHistory();
  const [showCaseModal, setShowCaseModal] = useState(false);
  const [showOpinionModal, setShowOpinionModal] = useState(false);
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const [lawyerCase, setLawyerCase] = useState(null);

  const [currentMyCase, setCurrentMyCase] = useRecoilState(currentMyCaseState);
  const [selectedOpinion, setSelectedOpinion] = useRecoilState(selectOpinionState);

  const guideText = () => {
    const text = isMobile
      ? "* 사건 진행 현황은 알림톡으로 안내 드리고 있으니,\n안심하고 기다려 주시기 바랍니다."
      : "* 사건 진행 현황은 알림톡으로 안내 드리고 있으니, 안심하고 기다려 주시기 바랍니다.";

    return removeBrTag(text);
  };

  useEffect(()=>{
    getLawyerCase(currentMyCase.accepted.lawyer_case_id);
  },[])

  async function getLawyerCase(requestId) {
    if (requestId === undefined) {
      return;
    }
    const host = process.env.REACT_APP_FIREBASE_FUNCTION_GET_LAYWERCASE_URL
    const query = "?requestId=" + requestId;
    const url = host + query;
    try {
      const { data } = await axios.get(url);
      setLawyerCase(data);
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  if (!currentMyCase) return null;

  if (!selectedOpinion) return null;

  const onClickCaseDetail = (() => {
    if (isMobile) {
      history.push({
        pathname: "/admin/request/" + currentMyCase.id,
      })
    } else {
      setShowCaseModal(true);
    }    
  })

  const onClickLawyerOpinion = (() => {
    if (isMobile) {
      history.push({
        pathname: "/lawyer_opinion",
      })    
    } else {
      setShowOpinionModal(true);
    }    
  })

  const setStatusIcon = ((ballStatus) => {
    if(lawyerCase){
      if (lawyerCase.status === ballStatus) {
        return IcStatusCurrent
          } else {
            return IcStatusNone
      }
    }
  });

  const isStatusActive = ((_status) => {
  if(lawyerCase){
    if (lawyerCase.status === _status) {
      return "active"
    } else {
      return "inactive"
    }
  }
  });

  const setProgressUI = () => {
    if(currentMyCase){
      if (isCaseDocument(currentMyCase.serviceType)) {
        return <>
          <ProgressStateWrapper>
            <ProgressStateTitle status={isStatusActive("accept_case")}>STEP 1</ProgressStateTitle>
            <ProgressStateIcon src={setStatusIcon("accept_case")}/>
            <ProgressStateValue status={isStatusActive("accept_case")}>수임 등록</ProgressStateValue>
          </ProgressStateWrapper>
          <ProgressStateBorder />
          <ProgressStateWrapper>
            <ProgressStateTitle status={isStatusActive("processing")}>STEP 2</ProgressStateTitle>
            <ProgressStateIcon src={setStatusIcon("processing")}/>
            <ProgressStateValue status={isStatusActive("processing")}>작업 시작</ProgressStateValue>
          </ProgressStateWrapper>
          <ProgressStateBorder />
          <ProgressStateWrapper>
            <ProgressStateTitle status={isStatusActive("requested")}>STEP 3</ProgressStateTitle>
            <ProgressStateIcon src={setStatusIcon("requested")}/>
            <ProgressStateValue status={isStatusActive("requested")}>사건처리 중</ProgressStateValue>
          </ProgressStateWrapper>
          <ProgressStateBorder />
          <ProgressStateWrapper>
            <ProgressStateTitle status={isStatusActive("done")}>STEP 4</ProgressStateTitle>
            <ProgressStateIcon src={setStatusIcon("done")}/>
            <ProgressStateValue status={isStatusActive("done")}>처리 완료</ProgressStateValue>
          </ProgressStateWrapper>
        </>
      } else {
        return <>
          <ProgressStateWrapper>
            <ProgressStateTitle status={isStatusActive("accept_case")}>STEP 1</ProgressStateTitle>
            <ProgressStateIcon src={setStatusIcon("accept_case")}/>
            <ProgressStateValue status={isStatusActive("accept_case")}>수임 등록</ProgressStateValue>
          </ProgressStateWrapper>
          <ProgressStateBorder />
          <ProgressStateWrapper>
            <ProgressStateTitle status={isStatusActive("processing")}>STEP 2</ProgressStateTitle>
            <ProgressStateIcon src={setStatusIcon("processing")}/>
            <ProgressStateValue status={isStatusActive("processing")}>사건처리 중</ProgressStateValue>
          </ProgressStateWrapper>
          <ProgressStateBorder />
          <ProgressStateWrapper>
            <ProgressStateTitle status={isStatusActive("done")}>STEP 3</ProgressStateTitle>
            <ProgressStateIcon src={setStatusIcon("done")}/>
            <ProgressStateValue status={isStatusActive("done")}>처리 완료</ProgressStateValue>
          </ProgressStateWrapper>
        </>
      }
    } else {
      return <></>;
    }    
  }
  

  return (
    <>
      <Modal isOpen={showCaseModal} onRequestClose={() => setShowCaseModal(false)} style={ModalStyle}>
        <MyCaseDetailModal caseData={currentMyCase}/>
      </Modal>
      <Modal isOpen={showOpinionModal} onRequestClose={() => setShowOpinionModal(false)} style={LawyerOpinionModalStyle}>
        <LawyerOpinion loggedInUser={loggedInUser}/>
      </Modal>
      <CaseInfoSection>
        <Title>{getServiceTypeTitle(currentMyCase.serviceType)}</Title>
        <DateTextWrapper>
          <DateText>신청일</DateText>
          <DateTextValue> | {getFormattedDate(currentMyCase.createdAt)}</DateTextValue>
        </DateTextWrapper>
      </CaseInfoSection>
      <CaseStatusSection>
        <Title>사건 처리 현황</Title>      
        <ProgressWrapper>
          {setProgressUI()}
        </ProgressWrapper>
        <CaseProgressGuideWrapper>
          <CaseProgressGuideText>
          {guideText()}
          </CaseProgressGuideText>
        </CaseProgressGuideWrapper>
      </CaseStatusSection>
      <LaywerInfoSection>
        <Title
          style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
        >
          담당 변호사 정보
        </Title>
        <LawyerCardBaseWrapper
          key={"lawyer-opinion-" + selectedOpinion.id}>
          <LawyerCardWrapper>
            <LawyerCardLeftWrapper>
              {selectedOpinion.lawyer_img ? (
                  <LawyerCardProfile src={selectedOpinion.lawyer_img} alt={ProfileAcceptedDefault}/>
                ) : (
                  <LawyerCardProfile src={ProfileAcceptedDefault} />
              )}
              <LawyerCardTextWrapper>
                <LawyerCardLabel>{selectedOpinion.lawyer_name}</LawyerCardLabel>
                {/* <LawyerCardTitle style={{ marginTop: 4 }}>부동산 사건 전문 변호사</LawyerCardTitle> */}
                <LawyerCardProposeWrapper>
                  <LawyerCardTitle>수임가격</LawyerCardTitle>
                  <LawyerCardProposeWrapperValue>{Number(selectedOpinion.suggested_amount).toLocaleString()}원</LawyerCardProposeWrapperValue>
                </LawyerCardProposeWrapper>
              </LawyerCardTextWrapper>
            </LawyerCardLeftWrapper>
          </LawyerCardWrapper>
        </LawyerCardBaseWrapper>
      </LaywerInfoSection>
      <RequestServiceSection>
        <Title>서비스 신청 내역</Title>
        <RequestServiceSectionContainer>
        <RequestServiceWrapper onClick={onClickLawyerOpinion}>
          <RequestServiceTextWrapper>
            <img src={IcBook} style={{ marginRight: 13 }} />
            담당 변호사 검토의견
          </RequestServiceTextWrapper>
          <RequestServiceBtn>
            상세보기
          </RequestServiceBtn>
        </RequestServiceWrapper> 
        <RequestServiceWrapper onClick={onClickCaseDetail}>
          <RequestServiceTextWrapper>
            <img src={IcBook} style={{ marginRight: 13 }} />
            내가 신청한 서비스 내용
          </RequestServiceTextWrapper>
          <RequestServiceBtn>
            상세보기
          </RequestServiceBtn>
        </RequestServiceWrapper>
        </RequestServiceSectionContainer>
      </RequestServiceSection>         
    </>
  );
};

export default CaseProgress;