import { BankTwoTone } from "@ant-design/icons";
import { Result } from "antd";
import { dbService } from "fbase";
import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
const qs = require("qs");

const Pay = () => {
  const { requestId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pgToken = params.get("pg_token");

  const kakaoPayConfig = {
    adminKey: process.env.REACT_APP_KAKAO_ADMIN_KEY,
    cid: process.env.REACT_APP_KAKAO_PAY_CID,
    approval_url: process.env.REACT_APP_KAKAO_PAY_APPROVAL_URL,
    fail_url: process.env.REACT_APP_KAKAO_PAY_FAIL_URL,
    cancel_url: process.env.REACT_APP_KAKAO_PAY_CANCEL_URL,
  };

  useEffect(() => {
    dbService
      .collection("requests_temp")
      .doc(requestId)
      .get()
      .then((snapshot) => {
        const requestData = snapshot.data();
        const tid = requestData.tid;
        const userId = requestData.userId;
        const orderId = requestId;

        fetch("https://kapi.kakao.com/v1/payment/approve", {
          method: "POST",
          cache: "no-cache",
          headers: {
            Authorization: "KakaoAK " + kakaoPayConfig.adminKey,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: qs.stringify({
            cid: kakaoPayConfig.cid,
            tid: tid,
            partner_order_id: orderId,
            partner_user_id: userId,
            pg_token: pgToken,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              history.push("/pay/success/" + requestId);
            } else {
              history.push("/pay/fail/" + requestId);
            }
          })
          .catch(() => {
            history.push("/pay/fail/" + requestId);
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        backgroundColor: "white",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginBottom: 10,
        borderRadius: 5,
        minHeight: "100%",
      }}
    >
      <Result icon={<BankTwoTone />} subTitle="결제 중입니다.." />
    </div>
  );
};

export default Pay;
